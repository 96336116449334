import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,GalleriaModule, InputTextModule, OverlayPanel, ContextMenu,Message,DataTable,TabView, AutoComplete, Header,Dialog,InputText,Button} from 'primeng/primeng';
import { DataViewModule } from 'primeng/dataview';
import {DragDropModule} from 'primeng/dragdrop';

import {DataService} from '../service/dataservice';
import {Product} from '../domain_old/product';


import {Retailerproduct} from '../domain_old/retailerproduct';
import {Billingcounter} from '../posdomain/billingcounter';
import {Cart} from '../posdomain/cart';
import {TabViewModule} from 'primeng/tabview';

import {Displayimage} from '../domain_old/displayimage';
import {CustomizationOption} from '../domain_old/customizationoption';
import {Router} from '@angular/router';	
import {Constants} from '../domain/constants';
import {Cartlineitem} from '../posdomain/cartlineitem';
import {Discountcaller} from '../domain_old/discountcaller';

import {HostListener} from '@angular/core';
import {ToolbarModule} from 'primeng/toolbar';
import {MenuItem, MessageService, SelectItem} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {SidebarModule} from 'primeng/sidebar';
import {GMapModule} from 'primeng/gmap';


import { PushNotificationsService} from 'ng-push';

import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../posservice/billingservice';
import { Appdataservice } from '../service/appdataservice';
import { Orderservice } from '../service/orderservice';
import { Payment } from '../domain/payment';
import { Order } from '../domain/order';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Utils } from '../service/utils';
import { Settingsservice } from '../service/settingsservice';
import { Printingservice } from '../service/printingservice';
import { Deliveryboyservice } from '../service/deliveryboyservice';

@Component({
    templateUrl: 'orderprocessing.html'
})
export class Orderprocessing implements OnInit {

      
        constructor(private confirmationService : ConfirmationService,
            public billingService : Billingservice, 
            public dataService: Appdataservice,
            public messageService : MessageService,
            public deliveryboyService : Deliveryboyservice,
            public dbService : Dynamodbservice,
            public printingService : Printingservice,
            public settingService : Settingsservice,
            private _pushNotifications: PushNotificationsService ,  
            public orderService : Orderservice,
            private _router : Router) {
            
            if(!dataService.isuserloggedin){
                this._router.navigate(['/']);
            }


        }

        paymentmode;
        buttontext;

        deliveryboys : SelectItem[];
        isRunning;

        printkot; 

        cardwidth;

        @HostListener('window:resize', ['$event'])
        onResize(event) {
           this.setCardWidth();
        }

        setCardWidth(){
            if(window.innerWidth < 500){
                this.cardwidth = window.innerWidth - 10;
            }else if(window.innerWidth < 1200){
                this.cardwidth = window.innerWidth / 3 - 10 ;
            }else{
                this.cardwidth = window.innerWidth / 4 - 10 ;
            }


        }

        showErrorToast(message){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        }
    
        async migrateOrder(){
            if(this.newlocation == this.selectedOrder.locationid){
                this.showErrorToast('New location same as old location!');
                return;
            }

            if(!this.migratereason || this.migratereason.trim() == '' ){
                this.showErrorToast('Please provide migration reason!');
                return;
            }

            //Change the location details 
            this.selectedOrder.oldlocname = this.dataService.getLocationNameById(this.selectedOrder.locationid);
            this.selectedOrder.oldlocid = this.selectedOrder.locationid;

            this.selectedOrder.locationid = this.newlocation; 
            this.selectedOrder.locationname = this.dataService.getLocationNameById(this.newlocation);
            this.selectedOrder.migratereason = this.migratereason;
            
            var retstatus = await this.orderService.updateOrder(this.selectedOrder,this.selectedOrder.orderstatus);


            this.displaymigrateDialog = false; 
        }
    
        
        cancellationreason;
        displayrejectDialog;
        selectedOrder : Order;
        displayacceptDialog;
        displaymigrateDialog; 

        migratereason; 
        locationlist;
        newlocation;

        showMigrationDialog(ord : Order){
            this.selectedOrder = this.orderService.getClone(ord);
            this.newlocation = this.dataService.locationlist[0].id;
            this.migratereason = '';

            this.displaymigrateDialog = true;
        }

        
        confirmReject(ord : Order){
            this.selectedOrder = this.orderService.getClone(ord);
            this.displayrejectDialog = true;
        }

        confirmAccept(ord: Order){
            this.selectedOrder = this.orderService.getClone(ord);
            //this.selectedOrder.addressstr  = this.selectedOrder.deliveryaddress.addressline1 + ", " + this.selectedOrder.deliveryaddress.addressline2;
            this.displayacceptDialog = true;
        }
        

        confirmDispatch(ord: Order){
            this.selectedOrder = this.orderService.getClone(ord);
            this.displayDispatchDialog = true;
        }
        

        displayDispatchDialog; 



        async dispatchOrder(){
            this.selectedOrder.deliveredby = this.deliveredby;
            var retstatus = await this.orderService.updateOrder(this.selectedOrder,Constants.OUT_FOR_DELIVERY);
            this.displayDispatchDialog= false;

            //if(this.settingService.printreceipt){
            //    this.printReceipt(this.selectedOrder,false);
            //}            

        }

        getEmailBody(){
            var emailbody  = "<HTML><head><style> .lighback{font-weight:bold; background-color: lightgrey;}   table, td, th {border: 1px solid black;padding: 5px}</style></head><BODY><br/><br/> <b>Order Details: </b> <br/><br/>";
            var table = "<table style='border-collapse: collapse;width: 50%'>" + 
                         "<tr>" + 
                         "<td class='lighback' width=30%>Order Id</td>" + 
                         "<td width=70%>" + this.selectedOrder.id + "</td>" + 
                         "</tr>" + 
                         "<tr>" + 
                         "<td class='lighback' width=30%>Order Amount</td>" + 
                         "<td width=70%>" + this.selectedOrder.cart.netpayable + "</td>" + 
                         "</tr>" + 
                         "<tr>" + 
                         "<td class='lighback' width=30%>Paid Amount</td>" + 
                         "<td width=70%>" + this.selectedOrder.cart.paidamount + "</td>" + 
                         "</tr>" + 
                         "<tr>" + 
                         "<td class='lighback' width=30%>Customer Name</td>" + 
                         "<td width=70%>" + this.selectedOrder.customername + "</td>" + 
                         "</tr>" + 
                         "<tr>" + 
                         "<td class='lighback' width=30%>Customer Mobile</td>" + 
                         "<td width=70%>" + this.selectedOrder.customermobile + "</td>" + 
                         "</tr>" +
                         "<tr>" + 
                         "<td class='lighback' width=30%>Items Ordered</td>" + 
                         "<td width=70%>" + Utils.itemsAsHTML(this.selectedOrder) + "</td>" + 
                         "</tr>" +
                         "</table> <br/><br/> Regards, <br/> FoodKart Express" ;
                         
    
            emailbody  = emailbody + table +  "</BODY></HMTL>";
            return emailbody;
        }
    
        getEmailSubject(){
            if(this.dataService.retailer.multilocation){
                return "Order accepted @ " + this.selectedOrder.locationname  + ". Order# " + this.selectedOrder.id; 
            }else
                return "Order accepted @ " + this.dataService.retailer.appconfig.brandname + ". Order# " + this.selectedOrder.id; 
        }
    
        getEmailDestinations(){
            var emailids = '';
            if(this.dataService.retailer.multilocation){
                var loc = this.dataService.getLocationForId(this.selectedOrder.locationid);
                
                if(loc && loc.notificationemail && loc.notificationemail.length > 0)
                    emailids = emailids + loc.notificationemail + ',';
            }
            emailids = emailids + this.dataService.retailer.appconfig.notificationemail;
            
            return emailids;
        }
    
    

        sendNotificationAlert(){
            //First email
            if(this.selectedOrder && this.dataService.retailer.appconfig.notificationemail && this.dataService.retailer.appconfig.notificationemail.length > 0){
                var destination = this.getEmailDestinations();
                var sourceemail = this.dataService.retailer.appconfig.sourceemail;
                var emailbody = this.getEmailBody();
                var subject = this.getEmailSubject();
                this.dataService.sendNotificationEmail(emailbody,subject,sourceemail,destination);
            }

    
        }

        
        async acceptOrder(){

            if(this.settingService.printkot){
                this.selectedOrder.kotnum = this.printingService.getNewKOTToken();   
            }

            this.selectedOrder.receiptno = this.printingService.getNewReceiptNo();

            var retstatus = await this.orderService.updateOrder(this.selectedOrder,Constants.CONFIRMED_ORDER);
            this.displayacceptDialog= false;

            if(retstatus){
                if(this.settingService.printkot){
                    this.printKot(this.selectedOrder,false);
                }   
                if(this.settingService.printreceipt){
                    this.printReceipt(this.selectedOrder,false);
                }   
                this.sendNotificationAlert();

            }

        }

        rejectOrder(){
            this.orderService.updateOrder(this.selectedOrder,Constants.ORDER_REJECTED, this.cancellationreason);
            this.displayrejectDialog = false;
        }

        logout(){
            this._router.navigate(['/']);
        }

        notify(){ //our function to be called on click

            let options = { //set options
                body: "There are new orders! Please accept!",
                icon: "assets/demo/images/alert1.gif", //adding an icon
                sound: "assets/demo/images/alertsound.wav"
              }
               this._pushNotifications.create('New Orders', options).subscribe( //creates a notification
                  res => console.log(res),
                  err => console.log(err)
              );
         }
            

        public async setOrders(){
            if(this.dataService.isuserloggedin){
                if(Constants.DEBUG_MODE) console.log('|TRACKORDER| getting new orders >> ');
                await this.orderService.setPendingOrders();
                for(var order of this.orderService.pedningorderlist){
                    order.itemshtml = Utils.itemsAsHTML(order);
                }

                this.orderService.pedningorderlist = [... this.orderService.pedningorderlist];

                if(this.orderService.neworders){
                     this.playAudio();
                     this.notify();
                }




                setTimeout(()=>{this.setOrders()},10000);
            }
       }

       
        playAudio(){
            let audio = new Audio();
            audio.src = "assets/demo/images/alertsound.wav";
            audio.load();
            audio.play();
        }


        orderid; 

        deliveredby;

        

        ngOnInit(){

            this.setOrders();
            this.setCardWidth();
            this.deliveryboys =[];
            this.locationlist = [];


            for(var boy of this.deliveryboyService.deliveryboylist){
                if(boy.active){
                    var boydetails = boy.name + ' (' + boy.mobilenumber + ') ';
                    this.deliveryboys.push({label: boydetails, value: boydetails});
                    if(this.deliveredby == null){
                        this.deliveredby = boydetails;
                    }
                }
            }

            if(this.dataService.retailer.multilocation){
                for(var loc of this.dataService.locationlist){
                    if(loc.id != this.dataService.retailer.locationid){
                        this.locationlist.push({label: loc.locationname, value: loc.id});
                    }
                }
            }

            if(this.settingService.kotprinter.printertype == Constants.NOPRINT) this.printkot = false;
            else this.printkot = true;
        }


        setKotPrinted(order: Order){
            for(var ord of this.orderService.pedningorderlist){
                if (ord.id == order.id) ord.kotprinted = true;
            }
        }


        async printReceipt(order : Order,isduplicate)
        {

            if(this.settingService.printer.printertype == Constants.BROWSER){
                document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(order,false);
                window.print(); 
            }else{
                console.log('Generating receipt format!! ');
                var receiptformat= this.printingService.generateReceiptFormat(order,isduplicate);
                var printeridentifier = this.settingService.kotprinter.printername;
                if(this.settingService.printer.printertype == Constants.LAN || this.settingService.printer.printertype == Constants.USB || this.settingService.printer.printertype == Constants.BLUETOOTH)
                    printeridentifier = this.settingService.printer.deviceaddress;
                
                var retstatus = await this.printingService.synchronousprint(receiptformat, this.settingService.kotprinter.printertype,printeridentifier);
                if(retstatus) {
                   
                }else{
                    this.showErrorToast ('Error printing receipt!');
                }        
            }
        }



        async printKot(order : Order,isduplicate)
        {

            if(this.settingService.kotprinter.printertype == Constants.BROWSER){
                document.getElementById("printable").innerHTML = this.printingService.getKOTAsHMTL(order);
                window.print(); 
                order.kotprinted = true; 
            }else{
                /*var kotformat= this.printingService.generateKOTFormat(order,isduplicate);

                var printeridentifier = this.settingService.kotprinter.printername;
            
                if(this.settingService.printer.printertype == Constants.LAN || this.settingService.printer.printertype == Constants.USB || this.settingService.printer.printertype == Constants.BLUETOOTH)
                    printeridentifier = this.settingService.printer.deviceaddress;
                
                var retstatus = await this.printingService.synchronousprint(kotformat, this.settingService.kotprinter.printertype,printeridentifier);
                if(retstatus) {
                    if(!isduplicate) this.setKotPrinted(order);
                    else order.kotprinted = true; 
                }else{
                    this.showErrorToast ('Error printing KOT!');
                }    */    
            }
        }

        goHome(){
            this.orderService.clearCart();
            this._router.navigate((['/']));
        }
    
        goBack(){
            this._router.navigate((['/viewcart']));
        }

    
}


