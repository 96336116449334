import {Cartlineitem} from './cartlineitem';
import { Appliedtaxcharge } from './appliedtaxcharge';
import { Product } from './Product';
import { Utils } from '../service/utils';
import { Customeraddress } from './customeraddress';
import { Consumercart } from './consumercart';
import { Constants } from './constants';
import { Payment } from './payment';
import { Billingcounter } from './billingcounter';
import { timestamp } from 'rxjs/operators';
import { UPMessage } from '../updomain/upmessage';


export class Order{

    posid; //posid; 
    id; //ORDER ID 
    createdon; //Created on .. current date time 
    updatedon; //updated on .. current date time 
    
    customername; //Customer name  
    customermobile; //Customer mobile number
    customeremail; //Email id 
    customerid; //Nothing but customer mobile 
    locationid; //Order location  

    deladdrline1;
    deladdrline2;
    deladdrline3; 
    landmark; 

    cart : Consumercart;    
    orderchannel;
    orderstatus; 
    rejectreason;
    cancelreason;
    cancelremraks;
    foodprep;
    cancelby;

    deliveredby; 
    
    acceptedon;   //When the order was accpted
    foodreadyon;  //When food marked ready 
    dispatchedon; //When dispatched 
    completedon;  //When compelted 
    rejectedon;   //When rejected 



    //For report
    orderitemsstr;
    statuscolor;
    statusstr;

    addressstr;
    itemshtml;
    
    kotprinted;
    kotnum;
    kotcount;
    receiptno;

    createdonstr;
    acceptedonstr;
    foodreadyonstr;
    dispatchedonstr;
    completedonstr;
    rejectedonstr;
    businessdatestr;
    sectionnamestr;

    paidincash;
    paidonline;
    pymtreference; 
    paymentstring;

    
    locationname; 
    oldlocid;
    oldlocname;
    migratereason;
    paymentmodestr;
    cashbackearned;


    urlsource;
    pgrequesttran;
    
    counterid;
    countername; 
    countertype;
    countertypestr;
    guesttype; 
    numguests;
    ordertype; 
    ispizzapan;
    issfcplus; 
    
    rcptnodisplay; 
    //For aggregator orders 
    aggrid; //Id of aggregator 
    aggrref; //Reference of aggregator 

    orderremarks;
    businessdate; 
    deliveryon;
    billedby; 
    servedby;
    settledby;
    voidby;
    voidbystr;
    ordfrom;
    
    servedbystr;
    
    billedbystr;
    ccagentstr; 
    settledbystr;

    checkedout;
    deliveredbystr;

    weekdaystr;
    hourofdaystr;
    reprintcount;
    dlvrdone;
    dlvrstatus;
    updatedonstr; 
    empcode;

    //Payment details 
    pymtcash;
    pymtcard;
    tips;
    pymtonline; 
    pymtforex;
    pymtcustwallet;
    pymtaggregator; 
    pymtcomplimentary;
    pymtcoupon;
    pymtzomatopro;
    pymtothercredit; 

    delsource;
    delreference; 
    nationality; 
    details;
    aggrname;

    fooddisc;
    bevdisc;
    misdisc;
    tobdisc;

    vatamt;

    sessionid;
    ver;

    extensionno;
    callsessionid;
    ccordid;
    chkouttimestr;
    chkouttime;
    firstkoton; 
    firstkotstr;
    uproderid; //Urbanpiper order id 
    externaldiscount; //External discount

    brandid;

    driverdetails;
    deliverytype; 

    ridername;
    riderno;
    rideraltno;

    

  
    public static updateOrderPostProforma(order : Order, counter : Billingcounter){
        order.deladdrline1 = counter.deladdrline1;
        order.deladdrline2 = counter.deladdrline2;
        
        
        if(counter.customer){
          order.customeremail = counter.customer.emailaddress;
          order.customermobile =  counter.customer.mobileno;
          order.customername = counter.customer.name;
        }else{
          order.customeremail = '';
          order.customermobile = '' ;
          order.customername = '';
        }

        order.cart = counter.cart;
        order.updatedon = new Date().getTime();
        
        order.orderremarks = counter.remarks;
      
    }


    /*
      Factory method to create a new customer
    */
   public static createNewOrder(posid,locid,locname,userid, counter  : Billingcounter, businessdate,ispizzapan)  : Order{
      var order = new Order();
      order.posid = posid;
      order.deladdrline1 = counter.deladdrline1;
      order.deladdrline2 = counter.deladdrline2;
      
      order.ver = 926;

      order.deliveredby = counter.deliveryby;
      order.deliveryon = counter.deliveryon;
      order.businessdate = businessdate;
      order.kotnum = counter.kottokens;
      order.guesttype = counter.guesttype;
      order.numguests = counter.numguests;
      order.ispizzapan = ispizzapan;
      //Add session id on the order
      if(counter.sessionid && counter.sessionid > 0){
          order.sessionid = counter.sessionid;
      }
      
      if(counter.customer && counter.customer.mobileno && counter.customer.mobileno.length > 0){
        order.customeremail = counter.customer.emailaddress;
        order.customermobile = counter.customer.mobileno;
        order.customername = counter.customer.name;
      }else{
        order.customeremail = '';
        order.customermobile = '';
        order.customername = '';
      }
      order.cart = counter.cart;
      
      order.createdon = new Date().getTime();
      order.id = new Date().getTime();
      order.updatedon = new Date().getTime();
      order.locationid = locid; 
      order.locationname = locname;
      order.counterid = counter.id;
      order.countername = counter.countername;
      order.countertype = counter.countertype;
      order.aggrid = counter.aggregatorid;
    
      order.aggrref = counter.aggrref;
      order.orderremarks = counter.remarks;
      
      order.servedby  = counter.servedby;
      order.servedbystr = counter.servedbystr;
      order.billedby = userid;

      order.cashbackearned = 0;
      order.orderstatus  = 0;
      return order;
  }

 
    /*
      Factory method to create a new customer
    */
    public static createInitialOnlineOrder(posid,locid,locname,userid, curtimpestamp, counter  : Billingcounter, businessdate, uporder : UPMessage)  : Order
    {
        var order = new Order();
        order.posid = posid;
        order.deladdrline1 = counter.deladdrline1;
        order.deladdrline2 = counter.deladdrline2;
  
        order.ver = 926;
        order.businessdate = businessdate;
        order.sessionid =counter.id + "|" + curtimpestamp;
        order.customeremail = uporder.customer.email;
        order.customermobile = uporder.customer.phone;
        order.customername = uporder.customer.name;
        order.deladdrline1 = uporder.customer.address.line_1;
        order.deladdrline2 = uporder.customer.address.line_2;
        order.deladdrline3 = 'Landmark: ' + uporder.customer.address.landmark;
        order.ispizzapan = uporder.ispizzpan;
        order.issfcplus = uporder.issfcplus;

        if(uporder.customer.address.sub_locality ){
          order.deladdrline3 = order.deladdrline3 +  ', Locality: ' + uporder.customer.address.sub_locality;
        }
        if(uporder.customer.address.city ){
          order.deladdrline3 = order.deladdrline3 +  ',' + uporder.customer.address.city;
        }
        if(uporder.customer.address.pin ){
          order.deladdrline3 = order.deladdrline3 +  ',' + uporder.customer.address.pin;
        }
        

        order.cart = new Consumercart();
        order.cart.initCart(counter.taxoverride);



        order.createdon = curtimpestamp;
        order.id = curtimpestamp ;
        order.updatedon = curtimpestamp;
        
        order.locationid = locid; 
        order.locationname = locname;
        order.counterid = counter.id;
        order.countername = counter.countername;
        order.countertype = counter.countertype;
        order.aggrid = counter.aggregatorid;
      
        order.aggrref = uporder.order.details.ext_platforms[0].id;
        order.orderremarks = uporder.order.details.instructions;
        order.billedby = userid;

        order.externaldiscount = uporder.order.details.total_external_discount;
  
        order.cashbackearned = 0;
        order.orderstatus  = 0;
        return order;
    }
  

 


}