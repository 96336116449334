import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule}    from '@angular/http';
import {BrowserModule} from '@angular/platform-browser';
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {AppRoutes} from './app.routes';
import {DatePipe} from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import {DragDropModule} from 'primeng/dragdrop';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PushNotificationsModule } from 'ng-push'; //import the module


import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import {DataGridModule, ConfirmationService, MessageService, GMapModule, ProgressSpinnerModule} from 'primeng/primeng';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { DataTableModule } from 'primeng/primeng';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';


import {AppComponent} from './app.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';







import {LoginCmp} from './view/login';
import {Billingservice} from './service/billingservice';
import {Callcenterservice} from './service/callcenterservice';
import {SidebarModule} from 'primeng/sidebar';

import { Appdataservice } from './service/appdataservice';
import { Orderservice } from './service/orderservice';
import { Dynamodbservice } from './service/dynamodbservice';
import { Orderprocessing } from './view/orderprocessing';
import { Toolbar } from './view/toolbar';
import { Settingsservice } from './service/settingsservice';
import { Printingservice } from './service/printingservice';
import { Setaggregatorstatus } from './view/setaggregatorstatus';
import { Deliveryboys } from './view/deliveryboys';
import { Deliveryboyservice } from './service/deliveryboyservice';
import { Onlineordersreport } from './view/onlineordersreport';
import { Customerlist } from './view/customerlist';
import { Couchdbservice } from './service/couchdbservice';
import { Kitchenservice } from './service/kitchenservice';
import { Printers } from './view/printers';
import { Printerservice } from './service/printerservice';
import { Kitchens } from './view/kitchens';
import { Billingcounters } from './view/billingcounters';
import { Billingcounterservice } from './service/billingcounterservice';
import { Privilegeservice } from './service/privilegeservice';
import { Usergroupservice } from './service/usergroupservice';
import { Usergroups } from './view/usergroups';
import { Userservice } from './service/userservice';
import { Users } from './view/users';
import { Billingscreen } from './view/billingscreen';
import { Billdialog } from './view/billdialog';
import { Categorymenucomponent } from './view/categorymenucomponent';
import { Instructionsdialog } from './view/instructionsdialog';
import { Discountcomponent } from './view/discountcomponent';
import { Customizationdialog } from './view/customizationdialog';
import { Kotcancellationdialog } from './service/kotcancellationdialog';
import { Paymentdialog } from './view/paymentdialog';
import { Customerservice } from './service/customerservice';
import { Customerdialog } from './view/customerdialog';
import { Itemcancellationdialog } from './view/itemcancellationdialog';
import { Transferdialog } from './view/transferdialog';
import { Splitdialog } from './view/splitdialog';
import { Kotdetails } from './view/reports/kotreports/kotdetails';
import { Kotcancellations } from './view/reports/kotreports/kotcancellations';
import { Kotitemcancellations } from './view/reports/kotreports/kotitemcancellations';
import { Deliverydialog } from './view/deliverydialog';
import { Orderdetails } from './view/reports/salesreports/orderdetails';
import { Paymentdetails } from './view/reports/paymentreports/paymentdetails';

import { Aggregatorservice } from './service/aggregatorservice';
import { Reauthdialog } from './view/reauthdialog';
import { Discountpartnerservice } from './service/discountpartnerservice';
import { Paymentsummary } from './view/reports/paymentreports/paymentsummary';
import { Salessummary } from './view/reports/salesreports/salessummary';
import { Itemsalessummary } from './view/reports/salesreports/itemsalesssummary';
import { Ordermanagement } from './view/ordermanagement';
import { Ordercancellationdialog } from './view/ordercancellationdialog';
import { Alertreport } from './view/reports/alertreport';
import { Shiftendservice } from './service/shiftendservice';
import { Cashierchange } from './view/cashierchange';
import { Tillreport } from './view/reports/tillreport';
import { Processeod } from './view/processeod';
import { Dashboard } from './view/reports/dashboard';
import { Mobilebilldialog } from './view/mobilebilldialog';
import { Kitchendisplay } from './view/kitchendisplay';
import { Counterview } from './view/counterview';
import { Deliverymanagement } from './view/deliverymanagement';
import { Businesssummaryreport } from './view/reports/businesssummaryreport';
import { Schemeitemdetails } from './view/reports/salesreports/schemeitemdetails';
import { Datewisedineinanalysis } from './view/reports/salesreports/datewisedineinanalysis';
import { Qrorderservice } from './service/qrorderservice';
import { Qrprocessing } from './view/qrprocessing';
import { Orderswithscheme } from './view/reports/salesreports/orderswithscheme';
import { Buildsummary } from './view/reports/buildsummary';
import { Customizationreport } from './view/reports/salesreports/customizationreport';
import { Focustransdetail } from './view/reports/salesreports/focustransdetail';
import { Reprintdialog } from './view/reprintdialog';
import { Migratetocouch } from './view/migratetocouch';
import { Syncfromcouch } from './view/syncfromcouch';
import { Carddialog } from './view/carddialog';
import { Shortsummaryreport } from './view/reports/shortsummaryreport';
import { Taxdetails } from './view/reports/salesreports/taxdetails';
import { Menuanalysis } from './view/reports/salesreports/menuanalysis';
import { Kotbillreco } from './view/reports/kotreports/kotbillreco';
import { Customerfeedback } from './view/reports/customerfeedback';
import { Serverperformance } from './view/reports/serverperformance';
import { Pendingorderlist } from './view/pendingorderlist';
import { Branddashboard } from './view/branddashboard';
import { Reprocesseod } from './view/reprocesseod';
//import { QRCodeModule } from 'angular2-qrcode';

import { Urbanpiperservice } from './service/urbanpiperservice';
import { Onlineorderlist } from './view/onlineorderlist';
import { Menuanalysisdaterange } from './view/reports/salesreports/menuanalysisdaterange';

export class MyHammerConfig extends HammerGestureConfig  {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false }
        //'swipe': {velocity: 0.4, threshold: 20} // override default settings
    }
  }





@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        HttpModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CheckboxModule,
        ConfirmDialogModule,
        ChartModule,
        DataViewModule,
        DialogModule,
        SlideMenuModule,
        ToolbarModule,
        TooltipModule,
        GrowlModule,
        DropdownModule,
        CalendarModule,
        MenuModule,
        SidebarModule,
        GalleriaModule,
        GMapModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        TableModule,
        ProgressBarModule,
        DataTableModule,
        MessageModule,
        MessagesModule,
        OverlayPanelModule,
        ColorPickerModule,
        MultiSelectModule,
        PanelModule,
        PasswordModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        DataGridModule,
        ProgressSpinnerModule,
        PushNotificationsModule,
        QRCodeModule,
        ToastModule
    ],
    declarations: [
        AppComponent,
        LoginCmp,
        Toolbar,
        Orderprocessing,
        Deliveryboys,
        Printers,
        Kitchens,
        Onlineordersreport,
        Billingcounters,
        Customerlist,
        Usergroups,
        Categorymenucomponent,
        Users,
        Billingscreen,
        Billdialog,
        Instructionsdialog,
        Discountcomponent,
        Customizationdialog,
        Kotcancellationdialog,
        Itemcancellationdialog,
        Transferdialog,
        Splitdialog,
        Customerdialog,
        Paymentdialog,
        Setaggregatorstatus,
        Kotdetails,
        Kotcancellations,
        Kotitemcancellations,
        Deliverydialog,
        Orderdetails,
        Paymentdetails,
        Paymentsummary,
        Reauthdialog,
        Salessummary,
        Itemsalessummary,
        Ordermanagement,
        Ordercancellationdialog,
        Alertreport,
        Cashierchange,
        Tillreport,
        Processeod,
        Dashboard,
        Kitchendisplay,
        Mobilebilldialog,
        Deliverymanagement,
        Counterview,
        Businesssummaryreport,
        Branddashboard,
        Schemeitemdetails,
        Datewisedineinanalysis,
        Qrprocessing,
        Orderswithscheme,
        Buildsummary,
        Customizationreport,
        Focustransdetail,
        Reprintdialog,
        Migratetocouch,
        Syncfromcouch,
        Carddialog,
        Shortsummaryreport,
        Taxdetails,
        Menuanalysis,
        Kotbillreco,
        Customerfeedback,
        Serverperformance,
        Reprocesseod,
        Pendingorderlist,
        Onlineorderlist,
        Menuanalysisdaterange
        		
        
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
        MessageService,
        Billingservice,
        Appdataservice,
        Orderservice,
        Couchdbservice,
        ConfirmationService,
        Billingcounterservice,
        Privilegeservice,
        Usergroupservice,
        Userservice,
        Dynamodbservice,
        Settingsservice,
        Printingservice,
        Deliveryboyservice,
        Printerservice,
        Kitchenservice,
        Customerservice,
        Callcenterservice,
        Aggregatorservice,
        Discountpartnerservice,
        Shiftendservice,
        Qrorderservice,
        Urbanpiperservice,
        DatePipe    
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
