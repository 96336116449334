import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,Message,DataTable,Header,Dialog,InputText,Button, MessageService, SelectItem} from 'primeng/primeng';
import {TableModule} from 'primeng/table'
import {DataService} from '../service/dataservice';
import {Usergroupservice} from '../service/usergroupservice';

import {Usergroup} from '../domain/usergroup';
import {Router} from '@angular/router';	
import { Appdataservice } from '../service/appdataservice';
import { Printerservice } from '../service/printerservice';
import { Constants } from '../domain/constants';
import { Privilegeservice } from '../service/privilegeservice';


@Component({
    templateUrl: 'usergroups.html'
})
export class Usergroups implements OnInit {
    usergroups : Usergroup[];
    msgs: Message[] = [];
    isRunning : boolean; 
    
    msgssummary : Message[] = [];    

    selectbillingprivs : SelectItem[];
    selectreportprivs : SelectItem[];

    
    constructor(public dataService : Appdataservice,
        public messageService : MessageService,
        public privilegeService : Privilegeservice,
        public printerService : Printerservice,
        public usergroupService : Usergroupservice, private _router : Router) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }
    
    ngOnInit() {
        this.usergroups = this.usergroupService.usergrouplist;

        this.selectbillingprivs = this.privilegeService.getBillingPrivOptions();
        this.selectreportprivs = this.privilegeService.getReportingPrivilegesOptions();
    }
    
    selectedCounter : Usergroup;
    usergroup : Usergroup;
    displayAddEditDialog : boolean = false;
    enableEditButton : boolean = false;
    isForEdit : boolean = true;
    
    
    showAddEditDialog(fIsforEdit : boolean){
        this.msgs = [];
        this.msgssummary = [];
        this.isForEdit = fIsforEdit;
        
        if(fIsforEdit){
            this.usergroup = this.cloneusergroup(this.selectedCounter);
        }else
            this.usergroup = <Usergroup>{};
        
        this.displayAddEditDialog = true;
    }
    
    
    
    cloneusergroup(fusergroup: Usergroup): Usergroup
    {
        var usergroup = <Usergroup>{};
        for(let prop in fusergroup) {
            usergroup[prop] = fusergroup[prop];
        }
        return usergroup; 
     }
     
    onRowDoubleClick(event){
        this.showAddEditDialog(true);
    }
    
    onRowSelect(event) {
        this.enableEditButton =true;
    }
    
    onRowUnselect(event) {
        this.enableEditButton = false;
    }
    
    findselectedCounterIndex(): number {
        return this.usergroupService.usergrouplist.indexOf(this.selectedCounter);
    }
    
    getDialogWidth() : number{
       return 400;
    }
    
    getDialogHeight() : number{
        return 250;
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    
    async refreshData(){
        this.isRunning = true;
        var ret = this.usergroupService.initUsergroups();
        this.isRunning = false;
        if(ret){
            this.showSuccessToast('Data refreshed successfully!')
        }else{
            this.showSuccessToast('Error in refreshing data, please try again.')
        }
    }

    async save(dt : DataTable) 
    {
        this.msgs =[];
        this.isRunning = true;
        
        if(!this.usergroup.name  || this.usergroup.name.trim()  == ""){
            this.showErrorToast('Usergroup name can not be blank.')
            this.isRunning = false;
            return;
        }
        
        
        if(this.usergroupService.checkDuplicate(this.usergroup)){
            this.showErrorToast('Usergroup name already exists.')
            this.isRunning = false;
            return;
        }

        
        if(!this.isForEdit) this.usergroup.id = this.usergroupService.getNewId();
        this.usergroup.posid = this.dataService.getPosId();
        if(this.dataService.retailer.multilocation) this.usergroup.posid = this.dataService.getPosWithLocationId();

        
        var retstatus = await this.usergroupService.save(this.usergroup);
        
        //If true --> Coutner Added 
        if(retstatus != "ERROR" && retstatus != "CONFLICT"){
            this.isRunning = false;

            this.displayAddEditDialog = false; 
            this.enableEditButton = false;
            this.isRunning = false;
            this.showSuccessToast('Usergroup saved successfully.'); 
        }else{
            if(retstatus == 'ERROR') this.showErrorToast('Error, please try again.'); 
            else this.showErrorToast('You seem to be having an old copy, please refresh data!'); 
            this.isRunning = false;
        }
    
            
    }
    
}


