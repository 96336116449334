import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,Message,DataTable,Header,Dialog,InputText,Button, MessageService, SelectItem} from 'primeng/primeng';
import {TableModule} from 'primeng/table'
import {DataService} from '../service/dataservice';
import {Printerservice} from '../service/printerservice';

import {Printer} from '../domain/printer';
import {Router} from '@angular/router';	
import { Appdataservice } from '../service/appdataservice';
import { Constants } from '../domain/constants';


@Component({
    templateUrl: 'printers.html'
})
export class Printers implements OnInit {
    printers : Printer[];
    msgs: Message[] = [];
    isRunning : boolean; 
    
    msgssummary : Message[] = [];    

    constructor(public dataService : Appdataservice,
        public messageService : MessageService,
        public printerService : Printerservice, private _router : Router) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    printertypes : SelectItem[];
    printingformats : SelectItem[];
    paperwidths : SelectItem[];

    ngOnInit() {
        
        this.printers = this.printerService.printerlist;
        this.printertypes = [];
        this.printertypes.push({label:'System', value: Constants.SYSTEM});
        this.printertypes.push({label:'LAN', value: Constants.LAN});
        this.printertypes.push({label:'Browser', value: Constants.BROWSER});

        this.printingformats = [];
        this.printingformats.push({label:'Text', value: Constants.PRINT_TEXT});
        this.printingformats.push({label:'HTML', value: Constants.PRINT_HTML});
        
        this.paperwidths = [];
        this.paperwidths.push({label:'3 Inch/48 Col', value: Constants.THREE_INCH_PAPER});
        this.paperwidths.push({label:'3 Inch/42 Col', value: Constants.COL42_PRINTER});
        this.paperwidths.push({label:'3 Inch/40 Col', value: Constants.COL40_PRINTER});
        this.paperwidths.push({label:'2 Inch/32 Col', value: Constants.TWO_INCH_PAPER});
        this.paperwidths.push({label:'A4 Size', value: Constants.A4});
        
    }

    
    selectedCounter : Printer;
    printer : Printer;
    displayAddEditDialog : boolean = false;
    enableEditButton : boolean = false;
    isForEdit : boolean = true;
    
    
    showAddEditDialog(fIsforEdit : boolean){
        this.msgs = [];
        this.msgssummary = [];
        this.isForEdit = fIsforEdit;
        
        if(fIsforEdit){
            this.printer = this.cloneprinter(this.selectedCounter);
        }else{
            this.printer = <Printer>{};
            this.printer.paperwidth = Constants.THREE_INCH_PAPER;
            this.printer.printingformat = Constants.PRINT_TEXT;
            this.printer.printertype = Constants.SYSTEM;
        }
        
        this.displayAddEditDialog = true;
    }
    
    
    
    cloneprinter(fprinter: Printer): Printer
    {
        var printer = <Printer>{};
        for(let prop in fprinter) {
            printer[prop] = fprinter[prop];
        }
        return printer; 
     }
     
    onRowDoubleClick(event){
        this.showAddEditDialog(true);
    }
    
    onRowSelect(event) {
        this.enableEditButton =true;
    }
    
    onRowUnselect(event) {
        this.enableEditButton = false;
    }
    
    findselectedCounterIndex(): number {
        return this.printerService.printerlist.indexOf(this.selectedCounter);
    }
    
    getDialogWidth() : number{
       return 400;
    }
    
    getDialogHeight() : number{
        return 250;
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    
    async refreshData(){
        this.isRunning = true;
        var ret = this.printerService.initPrinters();
        this.isRunning = false;
        if(ret){
            this.showSuccessToast('Data refreshed successfully!')
        }else{
            this.showSuccessToast('Error in refreshing data, please try again.')
        }
    }

    async save(dt : DataTable) 
    {
        this.msgs =[];
        this.isRunning = true;
            
        if(!this.printer.printername  || this.printer.printername  == ""){
            this.showErrorToast('Printer name can not be blank.')
            this.isRunning = false;
            return;
        }
        
        if(this.printer.printertype  == Constants.LAN){
           if(!this.printer.deviceaddress  || this.printer.deviceaddress  == ""){
                this.showErrorToast('Please provide IP address.')
                this.isRunning = false;
                return;
            }
        }
        
        
        if(this.printerService.checkDuplicate(this.printer)){
            this.showErrorToast('Printer name already exists.')
            this.isRunning = false;
            return;
        }


        this.printer.strformat = Constants.getPrintFormatStr(this.printer.printingformat);
        this.printer.strpaperwidth = Constants.getPaperWidthStr(this.printer.paperwidth);
        this.printer.strptype = Constants.getPrinterTypeStr(this.printer.printertype);
        
        
        if(!this.isForEdit) this.printer.id = this.printerService.getNewId();
        
        this.printer.posid = this.dataService.getPosId();
        if(this.dataService.retailer.multilocation) this.printer.posid = this.dataService.getPosWithLocationId();

        
        var retstatus = await this.printerService.save(this.printer);
        
        //If true --> Coutner Added 
        if(retstatus != "ERROR" && retstatus != "CONFLICT"){
            this.isRunning = false;

            this.displayAddEditDialog = false; 
            this.enableEditButton = false;
            this.isRunning = false;
            this.showSuccessToast('Printer saved successfully.'); 
        }else{
            if(retstatus == 'ERROR') this.showErrorToast('Error, please try again.'); 
            else this.showErrorToast('You seem to be having an old copy, please refresh data!'); 
            this.isRunning = false;
        }
    
            
    }
    
}


