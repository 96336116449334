import {Billingcounter} from '../posdomain/billingcounter'
import {Injectable,Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';

import {Constants} from '../domain_old/constants'
import {Cart} from '../posdomain/cart'
import { Cartservice } from './cartservice';
import {Cartlineitem} from '../posdomain/cartlineitem';
import {Messages,Growl,Column,GalleriaModule, InputTextModule, OverlayPanel, ContextMenu,Message,DataTable,AutoComplete, Header,Dialog,InputText,Button, MessageService} from 'primeng/primeng';
import {DataService} from '../service/dataservice';

import { Utilfunctions } from '../domain_old/utilfunctions';

import { Router } from '@angular/router';
import { runInThisContext } from 'vm';
import { Productsubcategory } from '../domain_old/productsubcategory';
import { Retailerproduct } from '../domain_old/retailerproduct';
import { Product } from '../domain/product';



@Injectable()
export class Billingservice  {
    
    /** Work flow vars */
    public isloggedin: boolean = false; 
    public displaymenu : boolean = false;
    public setMap : boolean = false;
    public loggedinuser : string = 'Satish Goriani';
    public defaultaddress : string = '123, abc enclave, xyz road, Mumbai ...';
    public ischeckoutinprogress : boolean = false; 
    public isaddressset: boolean = false;
    public firstaddres: string =  '52, 5th Floor, ABC Encalve, Ghatkopar , Mumbai 400 001';
    public firstaddrestag : string ;
    productdetailsvisible : boolean = false; 
    loginpanelvisible : boolean = false;
    addresspanelvisible : boolean = false; 
    productlist : Retailerproduct[];
    selectedSubcat : Productsubcategory;
    selectedProduct : Product;
    /** Work flow vars */

    
    constructor(
        public dataService : DataService,
        public _router : Router, 
        public messageService : MessageService)
    {
        if(Constants.DEBUG_MODE) console.log('in constructor of biling service ..');
        var ctr = <Billingcounter>{};
        ctr.aggregatorid = Constants.SELF;
        ctr.countertype = Constants.ONLINE;
        ctr.applyscheme = true; 
        ctr.gstoverride = false; 
        ctr.charge = 0; 
        ctr.chargeinpercent = false;
        ctr.countername = 'Test'; 

        
        this.dataService.selectedcounter = ctr;
        Cartservice.createInitialCart(ctr);
        if(Constants.DEBUG_MODE) console.log('in constructor of biling service ..' + this.dataService.selectedcounter.countername);
        
        this.productlist = [];
        
    }



    displayonlinebar : boolean;
    displaybookingalertbar : boolean; 

    
   
    //Make category list visible 
    makesubcatlistvisible : boolean = false;



 
    
}