import {Consumercart} from './consumercart';
import {Constants} from '../domain/constants';
import { Cartservice } from '../posservice/cartservice';
import { Customer } from './customer';
import { Payment } from './payment';
import { Cartlineitem } from './cartlineitem';


export class Billingcounter 
{
    id;
    countername;
    shortname;
    countertype;
    typename; 

    hotkey;
    colorcode;
    whitetext; 
    textcolor;

    sectionname;
    active;
    charge;
    chargeinpercent; 
    chargelabel;
    aggregatorid; 
    aggregatorname;

    peoplecapacity;
    cart : Consumercart;

    customer : Customer;
    
    operatorid; //Operator id on the counter

    remarks; //Remarks for checkout  

    defaultpaymentmode; // set payment by default 
    onbillstart; 
    onbillend;
    
    validatecustomer; 
    validatefullpayment; 
    validatedeliveryinfo;
    validateproforma;

    validateservedby;
    validateguestdetails;
    
    //sync features 
    updatedon; //timestamp 
    lockedon; //timestamp
    synced; //boolean flag if it is synced
    posid; // Comination of retailerid and locationid  
    _id; //Couch Id 
    _rev; //Couch revision

    tempshare;
    createdfor; 

    proformaid;
    termorderid;
    termid; 
    sessionid;
    callsessionid;  

    kotwithreceipt; 
    printreceipt;
    keepopenonchkout;

    applyscheme;
    singlekot;

    kottoserver;
    statuscolor; 
    displayname; 
    countercaption;

    paymentstring;
    billingsince; 

    taxoverride; 

    receipton; 
    dupreceipton;
    koton;
    dupkoton;
    
    dupkotconsolidated;
    dupkotkitchenspecific;
    dupkotkitchenid;
    
    numguests; 
    occupiedon;
    guesttype;
    servedby;
    servedbystr;

    deliveryby;
    deliveryon;
    deladdrline1;
    deladdrline2;
    

    aggrref;
    arrselecteditems ; //for marking ready

    paymentlist : Payment[];
    kottokens; 
    unsentitems;
    alias; 

    readyalert;
    qrlocked;
    custentry; 

    ccorderid; //If this is from call center order


    static getClone(ctr : Billingcounter){
        var newctr = <Billingcounter>{};
        for(let prop in ctr) {
            newctr[prop] = ctr[prop];
        }
        return newctr; 
    }


    



}
