import {Injectable} from '@angular/core';

import {DataService} from '../service/dataservice'
import * as AWS from 'aws-sdk';
import {Constants} from '../domain/constants';
import { Printer } from '../domain/printer';
import { DatePipe } from '@angular/common';

@Injectable()
export class Settingsservice {
    printer : Printer;
    kotprinter : Printer;

    constructor(){}

    printkot; 
    printreceipt; 

    saveSettings(){
        localStorage.setItem('printer', JSON.stringify(this.printer));
        localStorage.setItem('kotprinter', JSON.stringify(this.kotprinter));

        if(this.kotprinter.printertype == Constants.NOPRINT) this.printkot = false; 
        else this.printkot = true;

        if(this.printer.printertype == Constants.NOPRINT) this.printreceipt = false; 
        else this.printreceipt = true;

    }
    
    initService(){
        var printerjson = localStorage.getItem('printer');
        var kotprinterjson = localStorage.getItem('kotprinter');
	
        if(printerjson){
            this.printer = JSON.parse(printerjson);
        }else{
            this.printer = <Printer>{}
            this.printer.printertype = Constants.SYSTEM;
            console.log('**setting default printer type as 1');
        }

        if(kotprinterjson){
            this.kotprinter = JSON.parse(kotprinterjson);
        }else{
            this.kotprinter = <Printer>{}
            this.kotprinter.printertype = Constants.SYSTEM;
            console.log('**setting KOT default printer type as 1');
        }

        if(this.kotprinter.printertype == Constants.NOPRINT) this.printkot = false; 
        else this.printkot = true;

        if(this.printer.printertype == Constants.NOPRINT) this.printreceipt = false; 
        else this.printreceipt = true;

    }

    



}