import {Cartlineitem} from './cartlineitem';
import { Appliedtaxcharge } from './appliedtaxcharge';
import { Product } from './Product';
import { Utils } from '../service/utils';
import { Constants } from './constants';
import { Payment } from './payment';
import { Discountdetail } from './discountdetail';
import { Retailerscheme } from '../domain/retailerscheme';


export class Consumercart{
    //The cart will have a number of cart line items 
    lineitems : Cartlineitem[] = [];
    
    sumitemamount = 0.0; //Item rate 
    sumitemgross = 0.0;//Pretax amount 
    sumitemtax = 0.0;  //Total of item tax, incl + exclusive 
    sumitemnet = 0.0;  //NET SUM -- Item amount 
    sumitemtaxable = 0.0; //Pretax - discount; 
    sumitemcount = 0.0; //Total Number of items in the cart  
    sumitemcharge = 0.0; //Sum total of item level charge
    sumordertaxes = 0.0; //Total of order level taxes; 
    sumordercharges = 0.0; //Total of order level charges 
    pckgingcharges = 0.0; //Total of order level charges 
    deliverycharges = 0.0; //Delivery charge
    sumsc = 0.0;
    sumtfee=0.0;
    summfee = 0.0;
    sumvat = 0.0;

    //servicecharges = 0.0; //Service charge
    //scper = 0.0;
    
    sumitemdiscount = 0.0; //Total discount at item level 
    nettaxes = 0.0; //Order level + Item level tax
    netcharges = 0.0; //Order level  + item level charges 
    couponcode = '';
    couponvalue = 0.0;

    //Discount section 
    orderleveldiscount = 0.0; //Discount @ order level 
    totaldiscount = 0.0; //sumitemdiscount + orderdiscount
    discountdetail  : Discountdetail;

    orderschemecode = '';
    splinstructions = '';
    
    appliedschemecodes : string[]; //Array of scheme codes applied, at Order/item level
    appliedcoupons : string[]; //Array of coupon codes applied, at Order/item level

    //This would RoundOf(sumitemnet + ordertaxes + ordercharges - orderleveldiscount)
    netpayable = 0.0; 
    roundingamount = 0.0; //Rounding delta above 
    
    //Amount paid, for consumer app this wud be  0(COD) or fully paid (PG Payment)
    paidamount = 0.0;  
    pendingamount = 0.0; 
    totalsavings = 0.0;
    taxbehavior  = 0;

    itemtaxdetails : Appliedtaxcharge[];
    ordertaxdetails : Appliedtaxcharge[];
    itemchargedetails : Appliedtaxcharge[];
    orderchargedetails : Appliedtaxcharge[];
    paymentlist : Payment[];

    kottokens; 
     

    public initCart(taxbehavior){
        this.lineitems = [];
        this.appliedschemecodes = [];
        this.appliedcoupons = [];
        this.itemtaxdetails = [];
        this.ordertaxdetails= [];
        this.itemchargedetails = [];
        this.orderchargedetails = [];
        this.paymentlist = [];
        this.splinstructions = '';
        this.discountdetail = null;
        this.taxbehavior = taxbehavior;
        this.setItemTotalsToZero();
        this.setCartTotalsToZero();
    }

    

    
    //Compute taxes and charges @ cart level 
    //**TODO
    public computeTaxesAndCharges(){
        this.ordertaxdetails = [];
        //this.sumordertaxes = 0.0;
        /*if(this.scper && this.scper > 0){
            
            this.servicecharges = Utils.round(this.sumitemtaxable * this.scper / 100,3);
            console.log('SCHARGE' + this.servicecharges + 'PER ' + this.scper);
        }else{
            this.servicecharges = 0;
        }*/
    }


    setPaidAndRemainingAmount(){
        var paidamount = 0;
        for (var pymt of this.paymentlist){
            paidamount = paidamount + pymt.paidamount;
        } 

        this.paidamount = paidamount;
        this.pendingamount = this.netpayable - this.paidamount;

    }
    
    
    public setItemTotalsToZero(){
        this.sumitemgross = 0.0; 
        this.sumitemcount = 0.0; 
        this.sumitemtaxable = 0.0;
        this.sumitemtax = 0.0; 
        this.sumitemcharge = 0.0; 
        this.sumitemdiscount = 0.0;
        this.sumitemnet = 0.0; 
        this.sumsc = 0.0;
        this.sumvat = 0.0;
        this.sumtfee = 0.0;
        this.summfee = 0.0;
        this.sumitemamount = 0.0;
    }

    public setCartTotalsToZero(){
        this.orderleveldiscount = 0.0; 
        this.totaldiscount = 0.0;
        this.sumordertaxes = 0.0; //Total of order level taxes; 
        this.pckgingcharges = 0.0; // Total of packaging charges
        this.deliverycharges = 0.0; //Delivery charges 
        this.sumordercharges = 0.0; //Total of order level charges 
        this.nettaxes = 0.0; //Order level + Item level tax
        this.netcharges = 0.0; //Order level  + item level charges 
        this.netpayable = 0.0; 
        this.roundingamount = 0.0; //Rounding delta above 
        this.paidamount = 0.0;  
        this.pendingamount = 0.0; 
        this.couponvalue = 0.0;
        
        this.couponcode = '';
    }


    public setItemTotals(){
        this.setItemTotalsToZero();
        for(var item of this.lineitems){
            if(item.kitstatus != Constants.ITEM_CANCELLED && item.kitstatus != Constants.CANT_BE_PREPARED){
                this.sumitemgross = Utils.round(this.sumitemgross + item.grossamt,3); 
                this.sumitemtaxable = Utils.round(this.sumitemtaxable + item.taxableamt,3); 
                this.sumitemcount =  Utils.round(this.sumitemcount + item.quantity ,3);
                this.sumitemamount = Utils.round(this.sumitemamount + item.amount,3);
                this.sumitemtax = Utils.round(this.sumitemtax + item.itemtax,3); 
                this.sumitemcharge = Utils.round(this.sumitemcharge + item.itemcharge,3);
                this.sumitemdiscount = Utils.round(this.sumitemdiscount + item.discount,3);
                this.sumitemnet = Utils.round(this.sumitemnet + item.itemamount,3);
                this.sumvat = Utils.round(this.sumvat + item.vat,3);

                if(item.sc && item.sc > 0) this.sumsc = Utils.round(this.sumsc + item.sc,3);
                if(item.tfee && item.tfee > 0) this.sumtfee = Utils.round(this.sumtfee + item.tfee,3);
                if(item.mfee && item.mfee > 0) this.summfee = Utils.round(this.summfee + item.mfee,3);
                
            }
        }
    }

    
    
    public setCartTotals(){
        this.setItemTotals();

        if(!this.deliverycharges) this.deliverycharges  =0;
        
         
        this.sumordercharges = this.pckgingcharges + this.deliverycharges ;
        this.nettaxes = this.sumitemtax + this.sumordertaxes;
        this.netcharges = this.sumitemcharge + this.sumordercharges; 
        this.totaldiscount = this.orderleveldiscount + this.sumitemdiscount;

        
        var unroundedpayable = this.sumitemnet + this.sumordertaxes + this.sumordercharges - this.orderleveldiscount - this.couponvalue;  
        
        console.log('|CARTCOMP| UNROUND PAYABLE' + unroundedpayable);
        //this.netpayable = Math.round(unroundedpayable);

        //For 49ers..
        if(this.ashrounding){
            this.netpayable = Math.round(unroundedpayable);
        }else{
            this.netpayable = Utils.round(Utils.roundTo25Fraction(unroundedpayable),2);
        }
        console.log('|CARTCOMP|' + this.netpayable);

        
        this.roundingamount = Utils.round(this.netpayable - unroundedpayable, 2);

        this.totalsavings = this.totaldiscount + this.couponvalue;
        this.setPaidAndRemainingAmount();
        
    }


    //This will basically mean that the discount is applied @ bill level and not at item level 
    public applyBillDiscountScheme(schcode, discountval : number, ispercentage : boolean ){
        var applieddiscount = discountval;
        if(ispercentage)
            applieddiscount  = Math.round(this.sumitemgross * discountval / 100.0);

         if(applieddiscount > this.orderleveldiscount){
            this.orderleveldiscount = applieddiscount;
            this.orderschemecode = schcode;

            //Recompute the cart: 
            this.recomputeCart();

         }   

    }



    /*
    This method returns a line item for a given product and customization 
    */
    public getLineItem(product,customization,instructions) : Cartlineitem{
        for(var item of this.lineitems){
            if(item.productid == product.id && item.customization == customization && item.instructions == instructions && item.kitstatus == Constants.UNSENT) return item;               
        }
        return null;
    }

   
    /*
    This method checks if a product WITH a given customization exists in the cart
    */
    public doesItemExist(productid,customization){
        for(var item of this.lineitems){
            if(item.productid == productid && item.customization == customization) return true;               
        }
        return false;
    }

    /*
    This method checks if a product WITH or WITHOUT a given customization exists in the cart
    */
    public doesProductExist(productid){
        for(var item of this.lineitems){
            if(item.productid == productid) return true;               
        }
        return false;
    }

    public async recomputeCart(){
        this.setItemTotals();
        this.computeTaxesAndCharges();
        this.setCartTotals();
        
        //await this.lineitems.sort((a:Cartlineitem, b: Cartlineitem)=> (a.createdon)  < (b.createdon)  ? -1 : 1 );
        this.lineitems = [... this.lineitems];
    }

    ashrounding; 

    

    /*
    This method adds 
    */
    public addToCart(prod : Product, countertype, quantity, customization,customizationrate,instructions, custinv, scheme : Retailerscheme,qrid,scper,tfeeper,mfeeper,locationcode){
        
        var existingitem = this.getLineItem(prod,customization,instructions);
        if(existingitem) existingitem.setQuantity(prod,existingitem.quantity + quantity);
        else {
            var newitem =  Cartlineitem.createNewItem(prod, countertype,quantity,customization,customizationrate,instructions,this.taxbehavior,custinv,scheme,qrid,tfeeper,mfeeper,scper,locationcode);
            this.lineitems.unshift(newitem);
        }
        
        this.recomputeCart();

    }

    
    /*
    This method removes 
    */
   public removeItemFromCart(item : Cartlineitem){
        var pos= this.lineitems.indexOf(item);
        this.lineitems.splice(pos,1);
        
        this.recomputeCart();
    
    }


   public removeFromCart(prod : Product, quantity, customization,instructions){
        var existingitem = this.getLineItem(prod,customization,instructions);
        if(existingitem){
            var newquantity = existingitem.quantity - quantity;
            if(newquantity <= 0){
                var pos= this.lineitems.indexOf(existingitem);
                this.lineitems.splice(pos,1);
            }else 
                existingitem.setQuantity(prod, existingitem.quantity - quantity);
            
            this.recomputeCart();
        }
    }

    setQuantity(prod : Product, item : Cartlineitem , newquantity ){
        if(newquantity <= 0){
            var pos= this.lineitems.indexOf(item);
            this.lineitems.splice(pos,1);
        }else
            item.setQuantity(prod, newquantity);
        
        this.recomputeCart();
    
    }

    public incremenQuantity(prod : Product, item : Cartlineitem){
        item.setQuantity(prod, item.quantity + 1);
        this.recomputeCart();
    }

    public decrementQuantity(prod : Product, item : Cartlineitem){
        var newquantity = item.quantity - 1;
        if(newquantity <= 0){
            var pos= this.lineitems.indexOf(item);
            this.lineitems.splice(pos,1);
        }else 
            item.setQuantity(prod,item.quantity - 1);

        this.recomputeCart();
        
    }


    public applyItemDiscount(prod : Product, item : Cartlineitem,discountval : number){
        item.applyDiscount(prod,discountval);
        this.recomputeCart();
    }


    //Clear schemes from cart 
    public clearBillLevelScheme(){
        this.orderleveldiscount = 0;
        this.orderschemecode = '';
    }

    public setDeliveryCharges(charges : number){
        this.deliverycharges = charges; 
        this.recomputeCart();
    }

}   