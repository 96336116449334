import {Cartlineitem} from './cartlineitem';
import { Appliedtaxcharge } from './appliedtaxcharge';
import { Product } from './Product';
import { Utils } from '../service/utils';
import { Customeraddress } from './customeraddress';
import { Productquantity } from './productquantity';
import { Discountvoucher } from './discountvoucher';
import { Orderfeedback } from './orderfeedback';


export class Customer{
    posid; //posid; 
    id; //Here for now lets keep the mobile number 
    createdon; //Created on .. current date time 
    createdate; //Business date on which customer is created 
    updatedon; //updated on .. current date time 
    mobileno; //again mobile number 
    numtype; //1 - mobile, 2 - landline 
    alternateno;
    alternateno2;
    

    name; //customer name
    emailaddress; // Email  
    walletbal; //Current wallet balance in PoS? this will be different from Matam? 
    
    addresslist : Customeraddress[]; //This is only in Matam, on PoS only one address
    toppurchases : Productquantity[];
    numorders;     
    coupons : Discountvoucher[];
    fblist : Orderfeedback[];
    referredby; 
    
    signupreward;
    numreferrals; 
    referralrewards;
    createdonstr; 
    numfeedback;
    numaddresses; 
    toppurchasestr; 
    avgrating;
    addressstr;
    cumulativepurchase;
    
    dob;
    anniversary;

    addressline1; //PoS address
    addressline2; //PoS address
    _id; //Couch Id 
    _rev; //Couch revision


    /*
        Factory method to create a new customer
    */
   public static createNewCustomer(mobilenumber,name, emailaddress)  : Customer{
        var cust = new Customer();
        cust.id = mobilenumber;
        cust.mobileno = mobilenumber;
        cust.name = name;
        cust.emailaddress = emailaddress;
        cust.updatedon = new Date().getTime();
        cust.createdon = new Date().getTime();
        cust.addresslist = [];
        cust.walletbal = 0;
        
        return cust;
   }

    
}