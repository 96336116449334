import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {DataService}  from '../../../service/dataservice';
import {Constants}    from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Orderservice } from 'src/app/service/orderservice';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/service/utils';
import { Order } from 'src/app/domain/order';
import { Table } from 'primeng/table';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Payment } from 'src/app/domain/payment';
import { Shiftendrecord } from 'src/app/domain/shiftendrecord';
import { Kot } from 'src/app/domain/kot';
import { Shiftendservice } from 'src/app/service/shiftendservice';
import { Printingservice } from 'src/app/service/printingservice';
import { Product } from 'src/app/domain/product';
import { CustomizationOption } from 'src/app/domain/customizationoption';
import { Optionchoice } from 'src/app/domain/optionchoice';

@Component({
    templateUrl: 'customizationreport.html',
    providers: [ConfirmationService]
})
export class Customizationreport implements OnInit 
{
    
    fromdate          : Date;
    todate            : Date;
    summarylist       : Summaryrecord[];
    
    isRunning = false; 

    getWidthClass(){
        return "Wid90Center";
    }

    
    constructor(public dataService: Appdataservice,private _router : Router,
        private salesorderService: Orderservice,
        private datePipe : DatePipe,
        private counterService : Billingcounterservice,
        private userService : Userservice,
        private orderService : Orderservice,
        private shiftEndService : Shiftendservice,
        private kitchenService : Kitchenservice,
        private messageService : MessageService,
        public printingService : Printingservice,
        private dboyService : Deliveryboyservice
        ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        
        this.cols = [
            { field: 'description', header: 'Product Name' },
            { field: 'id', header: 'Product PLU' },
            
            { field: 'summaryval1', header: 'Subcategory' },
            { field: 'summaryval2', header: 'Option Header' },
            { field: 'summaryval3', header: 'Customization Option' },
            { field: 'summaryval4', header: 'PLU Code' },
            { field: 'summaryval5', header: 'Cost' },
        ];
 
        this.summarylist = [];
        
        this.setCustomizationData();

    }

    generateCustRec(prod : Product, opt : CustomizationOption, choice : Optionchoice ){
        var sumrec = <Summaryrecord>{};
        sumrec.id= prod.code;
        sumrec.description = prod.name;
        var subcat = this.dataService.getSubcategoryForId(prod.subcategoryid);
        if(subcat) sumrec.summaryval1 = subcat.name;
        sumrec.summaryval2 = opt.optionheader;
        sumrec.summaryval3 = choice.description;
        sumrec.summaryval4 = choice.inventory;
        sumrec.summaryval5 = Utils.round(choice.cost,1);

        return sumrec;
        
    }


    setCustomizationData(){
        this.isRunning = true;
        this.summarylist = [];
        for(var prod of this.dataService.productsforsale){
            if(prod.customization && prod.customization.optionlist && prod.customization.optionlist.length > 0){
                for(var opt of prod.customization.optionlist){
                    for(var choice of opt.choicelist){
                        this.summarylist.push(this.generateCustRec(prod,opt,choice));
                    }
                }
            }
        }

        this.summarylist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1 + a.description)  < (b.summaryval1 + b.description)  ? -1 : 1 );

        this.isRunning = false;
    }


       

}


