import {Kitchen} from '../domain/kitchen'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from './dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { Billingcounter } from '../domain/billingcounter';
import { Consumercart } from '../domain/consumercart';
import { Order } from '../domain/order';
import { Cartlineitem } from '../domain/cartlineitem';
import { Kot } from '../domain/kot';
import { Kottoken } from '../domain/kottoken';
import { Orderservice } from './orderservice';

import { Billingcounterservice } from './billingcounterservice';
import { DatePipe } from '@angular/common';
import { Printingservice } from './printingservice';
import { Tableorder } from '../domain/tableorder';
import { Tablerequest } from '../domain/tablerequest';
import { Kitchenservice } from './kitchenservice';
import { Userservice } from './userservice';
import { Qrtablemap } from '../domain/qrtablemap';
import { Utils } from './utils';
import { Aggregatorservice } from './aggregatorservice';
import { UPMessage } from '../updomain/upmessage';
import { MessageService } from 'primeng/api';

@Injectable()
export class Urbanpiperservice  {
     constructor(
         private dataService : Appdataservice,
         private dynamoDBService : Dynamodbservice,
         private messageService : MessageService,
         private orderService : Orderservice,
         private aggregatorService : Aggregatorservice,
         private billingcounterService : Billingcounterservice,
         private datePipe : DatePipe
     ){
         this.uporderinprocess = [];
     }
    
    uporderinprocess : UPMessage[];
    
    async fetchPendingorders(){
        try{
            var  posid = this.dataService.getPosId();
            //if (posid == "1343") posid = "1329";

            var conditionexpression = "posid  = :v_posid and orderstatus < :v_orderstatus";  
            var attribparams= {":v_posid" : posid , ":v_orderstatus" : Constants.ORDER_EXECUTED };
            var retlist  = <UPMessage[]> await this.dynamoDBService.queryOnIndex("uponlineorders", "posid-orderstatus-index", conditionexpression,attribparams);
            return retlist;
        }catch(err){
            console.error('Error fetching Orders ' + err);
        }
    }

    refreshTimer;

    areNewOrders(){
        for(var order of this.uporderinprocess){
            console.log('*** ORDER STATUS ' + order.orderstatus);
            if(order.orderstatus == Constants.NEW_ORDER){
                return true; 
            }
        }
        return false; 
    }

    async updateStatusOnUrbanPiper(orderid, orderstatus, message, ispizzapan?,issfcplus?){
        var url = 'https://api.urbanpiper.com/external/api/v1/orders/' +  orderid + '/status/';   // Get sales orders
        
        if(this.dataService.retailer.id == 1343){
            url = 'https://pos-int.urbanpiper.com/external/api/v1/orders/' +  orderid + '/status/';   // Get sales orders
        }

        let body = {new_status : orderstatus, message : message};
        
        var strauth = 'ApiKey ' +  this.dataService.retailer.urbanpipercredentials;
        
        if(ispizzapan)
            strauth = 'ApiKey ' + 'biz_adm_UovqDlrzLobc:5d04385488e71c25538b50e77daabd80d7342778';
        
        if(issfcplus)
            strauth = 'ApiKey ' + 'biz_adm_TyHYPnaIUsPR:3e3dbe4ebd57a7eb843b0b26fc81231af9d964bc';
            
        if(Constants.DEBUG_MODE) console.log('|ONLINEORDERS| SENDING MESSAGE ' + JSON.stringify(body)  + ' to url  ' + url + ' Auth ' + strauth);

        try{
            var retjson =  await this.dataService.syncHttp(url,body,null,"PUT",strauth);
            //if(Constants.DEBUG_MODE) console.log('|ONLINEORDERS| Message returned from urbanpiper ' + JSON.stringify(retpromise.json())  + ' Status text ' + retjson.status );
            if(retjson.status === 'success' )
                return true;

        }catch(err){
            console.error('|ONLINEORDERS|API ERR --> ' + err);
        }
        return false;

        //let options = new RequestOptions({ headers: headers });
    
    }


    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    setStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
    
        
         //Get status 
         ord.statusstr = Utils.getStatusStr(ord);
         ord.orderitemsstr = Utils.itemsAsString(ord);
         ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
         if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
            ord.details = Utils.getOrderDetails(ord);
        }

    }

    getFreeOnlineCounter(uporder : UPMessage){
        var aggrid=  this.aggregatorService.getAggregatorFromUpOrder(uporder);
        for(var counter of this.billingcounterService.activelist){
            if(counter.countertype == Constants.ONLINE && aggrid == counter.aggregatorid){
                return counter;    
            }
        }
        return null;
    }
 

    getPosOrderFromCurrentList(uporder : UPMessage){
        for(var upord of this.uporderinprocess){
            if(upord.id== uporder.id){
                return upord.posorder;
            } 
        }  
        
        if(uporder.posorder && uporder.posorder.id > 0) return uporder.posorder;
        return null;
    }

    setFoodReadyAlert(ord: UPMessage){
        ord.foodreadyalert = false; 
        if(ord.orderstatus == 1){
            var readytime = ( this.dynamoDBService.getSessionId() - ord.receivedon ) / (1000*60);
            //console.log('FOODREADY|ORD ID' + ord.id + ' TIME ' + readytime + 'TOTAL ' + ord.order.details.order_total);
            if(ord.order.details.order_total > 100 ){
                if(readytime > 10) ord.foodreadyalert = true; 
            }else{
                if(readytime > 8) ord.foodreadyalert = true; 
            } 

        }


    }


    generatePoSOrdersFromUPOrders(uporderlist : UPMessage[]){
        //var foodkartorderlist : Order[] = [];
        
        var i = 0;
        for(var ord of uporderlist){
            i++;
            //console.log('|ONCANCEL| Getting for id ' + ord.id);
            //ord.posorder = this.getPosOrderFromCurrentList(ord);

            //Check if up order has pos order along with receipt no 
            if(ord.posorder && ord.posorder.receiptno && ord.posorder.receiptno.length > 0){
                //Do nothing, i already have a PoS Order with a receipt no generated. so this will be NOW my pos order
            }else{
                ord.posorder = this.getPosOrderFromCurrentList(ord);
                if(!ord.posorder){
                    console.log('|ONCANCEL| POS ORDER NOT OBTAINED');
            
                    //First get the aggregator and assign counter  
                    var counter = this.getFreeOnlineCounter(ord);
                    if(counter){
                        var order = this.orderService.getOrderFromUPOrder(ord,counter,i);
                        this.setStrings(order); 
                        ord.posorder = order;
                    }else{
                        this.showErrorToast('Counter not found for Online Order Id: ' + ord.id + ', Channel: ' +ord.order.details.channel + ', Delivery type ' + ord.order.details.ext_platforms[0].delivery_type);
                    }
                }
            }

            this.setFoodReadyAlert(ord);
          
            var posorder = ord.posorder;
            posorder.ridername = ord.ridername;
            posorder.riderno = ord.riderno;
            posorder.rideraltno = ord.rideraltno;
            posorder.cancelreason = ord.cancelreason;

        }
        //return foodkartorderlist;

    }

    async setPendingOrders(){
        try{
            console.log('|UPORDERS|ORDER REFRESH');
            //this.pendingorderlist = [];
            var uporderlist = await this.fetchPendingorders();
            if(uporderlist){
                this.generatePoSOrdersFromUPOrders(uporderlist);
                this.uporderinprocess = uporderlist;
            }

            if(this.areNewOrders()){
                this.playAudio();
            }
        }
        catch(err){
            console.log('|UPORDERS|ERRORRR.. ' + err)
        }

        //if(this.dataService.isuserloggedin)
        this.refreshTimer = setTimeout(()=>{this.setPendingOrders()},5000);
    
    }



    clearTimer(){
        console.log('|BCTRTEST|LIVE REFRSH|Clearingtimeout')
        clearTimeout(this.refreshTimer);
    }
    
    lastsoundplayed  =0;

    playAudio(){
        try{
            this.lastsoundplayed = new Date().getTime();
            let audio = new Audio();
            audio.src = "assets/demo/images/readyalert.mp3";
            audio.load();
            audio.play();
        }catch(err){
            console.log('|SOUND|Error playing sound ' + err);
        }
    }


    
    
    


     

}