import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { Kot } from 'src/app/domain/kot';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Printingservice } from 'src/app/service/printingservice';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Utils } from 'src/app/service/utils';
import { Constants } from 'src/app/domain/constants';

@Component({
    templateUrl: 'kotcancellations.html',
    providers: [ConfirmationService]
})
export class Kotcancellations implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    kotlist       : Kot[];

    isRunning = false; 
    statuses : SelectItem[];

    totalamount = 0;

    selectedPayment;
    cols : any[];
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private datePipe : DatePipe,
        public messageService : MessageService,
        public kitchenService : Kitchenservice,
        public counterService : Billingcounterservice,
        public printingService : Printingservice
        ) {
        
    }
   
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));

        /*this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());*/


        this.cols = [
            { field: 'kottimestr', header: 'Created on '},
            { field: 'countername', header: 'Counter name' },
            { field: 'kitchenname', header: 'Kitchen name' },
            
            { field: 'kottoken', header: 'KOT Token' },
            { field: 'itemcount', header: 'Item count' },
            
            { field: 'lineitemsstr', header: 'Item list' },
            { field: 'cancellationreason', header: 'Cancellation reason' },
            { field: 'foodpreparedstr', header: 'Food Prepared' },
            
        ];

    }

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    selectedKOT; 

    
    async setData(){

        this.totalamount =0;

        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        
        }
        
        this.isRunning = true; 
        if(Constants.DEBUG_MODE) console.log ('Date From ' + new Date(fromdate) +  ' Date to  ' + new Date(todate));
        
        this.kotlist = [];
        //Get the KOTs from running counters FIRST 
        var kotlist =  <Kot[]> await this.kitchenService.getKotList(fromdate,todate);

        if(kotlist){
            kotlist.sort((a:Kot,b:Kot)=> a.id < b.id  ? -1 : 1 );
            for(var kot of kotlist){
                if(kot.iscancelled){
                    this.kitchenService.setStringVarsinKot(kot);
                    this.kotlist.push(kot);
                    if(kot.foodprepared) kot.foodpreparedstr = 'Yes'; else kot.foodpreparedstr = 'No';
                }

            }

            this.kotlist = [... this.kotlist];
        }
        this.isRunning = false;

    }


    async reprintKot(){
        //await this.printingService.printKOT(null,this.selectedKOT,this.counterService.getCounterForId(this.selectedKOT.counterid),false,'',false, true);
    }



}


