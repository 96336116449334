import { openDB, deleteDB, wrap, unwrap } from 'idb';
import {Injectable} from '@angular/core';
import {OnInit} from '@angular/core';

import * as AWS from 'aws-sdk';
import { DataService } from '../service/dataservice';
import { DatePipe } from '@angular/common';
import { Http } from '@angular/http';
import { Constants } from '../domain/constants';
import { Customer } from '../domain/customer';
import { Appdataservice } from './appdataservice';
import { Order } from '../domain/order';


@Injectable()
export class Dynamodbservice implements OnInit{

    ngOnInit(){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';
    }

    static serviceinstance : Dynamodbservice; 

    constructor(
         private dataService : Appdataservice,
         
         private datePipe : DatePipe,
         private http: Http){
        Dynamodbservice.serviceinstance  = this;
    }
    

    async scanTable(tablename){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';
        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});

        if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Fetching data from  :' + tablename ) ;

        var params = {"TableName": tablename}

        var retpromise= await dynamoDB.scan(params).promise();

        if(retpromise.$response.data){
            return retpromise.$response.data.Items;
        }else{
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename ) ;
            console.error(retpromise.$response.error); 
            return null; 
        }
    }

    async putItem(tablename, item){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';

        item.updatedon = new Date().getTime() + this.timeoffset;

        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true });
        var params = {
            TableName: tablename,
            Item: item
        };

        try{
            var retpromise= await dynamoDB.put(params).promise();
            if(retpromise.$response.data){
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Item put in :' + tablename);
                return 'DONE'; 
            }else{
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR putting item in  :' + tablename);
                console.error(retpromise.$response.error);
                return 'ERROR'; 
            }
        }catch(err){
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| EXCEPTION putting item in  :' + tablename + err );
            
            return 'ERROR'; 
        }

    
    }

    public timeoffset = 0;  

    getSessionId(){
        return new Date().getTime() + this.timeoffset;
    }

    async getItem(tablename, key){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';

        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});
        var params = {
            TableName: tablename,
                Key:key
        };

        var retpromise= await dynamoDB.get(params).promise();
        if(retpromise.$response.data  ){
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Item obtained from :' + tablename + 'Item: ' + JSON.stringify(retpromise.$response.data.Item));
            return retpromise.$response.data.Item; 
        }else{
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR putting item in  :' + tablename);
            console.error(retpromise.$response.error);
            return null; 
        }

    
    }



    
    //** Synchronously puts item and returns status */
    async updateItem(tablename, updateexpression, conditionexpression,attribparams,key){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';

        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});
        var params = {
            TableName: tablename,
            Key :key,
            "UpdateExpression" : updateexpression,
            "ConditionExpression": conditionexpression,
            "ExpressionAttributeValues" : attribparams
        };

        try{
            var retpromise= await dynamoDB.update(params).promise();
            if(retpromise.$response.data){
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Item put in :' + tablename);
                return 'DONE'; 
            }else{
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR putting item in  :' + tablename);
                console.error(retpromise.$response.error);
                return 'ERROR'; 
            }
        }catch(err){
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| EXCEPTION putting item in  :' + JSON.stringify(err));
            return 'ERROR';
            
        }

    
    }

    async findCustomers(mobilenumber) {
        var posid =  this.dataService.getPosId();
        if(posid == "1337" || posid == "1338" || posid == "1350") posid = "1329";
        
        var conditionexpression = "posid = :v_retailerid and id = :v_custid";  
        var attribparams= {":v_retailerid" : posid , ":v_custid" : parseInt(mobilenumber)};
        //console.log ('** ATTRIBS ' + JSON.stringify(attribparams));
        return await this.queryOnSortKey("poscustomer", conditionexpression,attribparams);
        
    }

    async downloadEOMRecords(posid){
        if(Constants.DEBUG_MODE) console.log('|DWNLDSALES| ** Downloading Sales ORDER txns which are not synced ');
        var conditionexpression = "posid = :v_retailerid";  
        var attribparams= {":v_retailerid" : posid};
        return await this.queryOnSortKey("monthlysummary",conditionexpression,attribparams);
 
    }



    //** Synchronously puts item and returns status */
    async deleteItem(tablename, key){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';

        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});
        var params = {
            TableName: tablename,
            Key: key
        };

        var retpromise= await dynamoDB.delete(params).promise();
        if(retpromise.$response.data){
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Item deleted from :' + tablename);
            return true; 
        }else if (retpromise.$response.error){
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR deleting item from  :' + tablename);
            console.error(retpromise.$response.error);
            return false; 
        }

    }



    async queryOnSortKey(tablename, keyconditionexpression, expressionattributvalues){
        
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';
        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});
       
        /*if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Fetching data from  :' + tablename + ' Key Condtion ' + keyconditionexpression + ' Values ' + expressionattributvalues ) ;

        var params = {
            "TableName": tablename,
            "KeyConditionExpression": keyconditionexpression,
            "ExpressionAttributeValues": expressionattributvalues
        }

        var retpromise= await dynamoDB.query(params).promise();

        if(retpromise.$response.data){
            return retpromise.$response.data.Items;
        }else{
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename ) ;
            console.error(retpromise.$response.error); 
            return null; 
        }*/

        var items : any[] = [];
        if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Fetching data from  :' + tablename + ' Key Condtion ' + keyconditionexpression + ' Values ' + expressionattributvalues ) ;
        var lastkey = null;
        
        while(true){

                var retpromise;

                if(lastkey != null){
                    var paramsnext = {"TableName": tablename,"KeyConditionExpression": keyconditionexpression,
                            "ExpressionAttributeValues": expressionattributvalues, "ExclusiveStartKey" : lastkey};
                    
                    console.log('LAST KEY*** ' + lastkey);
                            
                    retpromise= await dynamoDB.query(paramsnext).promise();

                }else{
                    var params = {"TableName": tablename,"KeyConditionExpression": keyconditionexpression,
                            "ExpressionAttributeValues": expressionattributvalues};
                
                    retpromise= await dynamoDB.query(params).promise();
                }

                if(retpromise.$response.data){
                    if(retpromise.$response.data.LastEvaluatedKey && retpromise.$response.data.LastEvaluatedKey != null){
                        lastkey = retpromise.$response.data.LastEvaluatedKey;
                        items = items.concat(retpromise.$response.data.Items);
                    }else{
                        items = items.concat(retpromise.$response.data.Items);
                        return items; 
                    } 
                }else{
                    if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename ) ;
                    console.error(retpromise.$response.error); 
                    return null; 
                }
        }

    }



    async queryOnIndex(tablename, indexname,keyconditionexpression, expressionattributvalues){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        
        AWS.config.region='ap-south-1';
        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});
       
        /*var params = {
            "TableName": tablename,
            "IndexName": indexname,
            "KeyConditionExpression": keyconditionexpression,
            "ExpressionAttributeValues": expressionattributvalues,
            "ReturnConsumedCapacity" : "TOTAL"
        }

        //if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE|Fetching data for params:' + keyconditionexpression);

        try{
            var retpromise= await dynamoDB.query(params).promise();

            if(retpromise.$response.data){
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Data Fetched from:' + tablename + ' On index ' + indexname + ' Num records ' + retpromise.$response.data.Items.length );
                return retpromise.$response.data.Items;
            }else{
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename + ' On index ' + indexname) ;
                console.error(retpromise.$response.error); 
                return null; 
            }
        }catch(err){
            if(Constants.DEBUG_MODE) console.error('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename + ' On index ' + indexname + JSON.stringify(err)) ;
            return null; 
        }*/


        var items : any[] = [];
        if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Fetching data from  :' + tablename + ' Key Condtion ' + keyconditionexpression + ' Values ' + expressionattributvalues ) ;
        var lastkey = null;
        
        while(true){

                var retpromise;

                if(lastkey != null){
                    var paramsnext = {"TableName": tablename,"IndexName": indexname, "KeyConditionExpression": keyconditionexpression,
                            "ExpressionAttributeValues": expressionattributvalues, "ExclusiveStartKey" : lastkey};
                    console.log('LAST KEY*** ' + lastkey);
                    retpromise= await dynamoDB.query(paramsnext).promise();
                }else{
                    var params = {"TableName": tablename, "IndexName": indexname,"KeyConditionExpression": keyconditionexpression,
                            "ExpressionAttributeValues": expressionattributvalues};
                
                    retpromise= await dynamoDB.query(params).promise();
                }

                if(retpromise.$response.data){
                    if(retpromise.$response.data.LastEvaluatedKey && retpromise.$response.data.LastEvaluatedKey != null){
                        lastkey = retpromise.$response.data.LastEvaluatedKey;
                        items = items.concat(retpromise.$response.data.Items);
                    }else{
                        items = items.concat(retpromise.$response.data.Items);
                        return items; 
                    } 
                }else{
                    if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename ) ;
                    console.error(retpromise.$response.error); 
                    return null; 
                }
        }


    }

    async getFeedbackList(fromdate, todate){
        try{
            var conditionexpression = "posid = :v_retailerid and businessdate BETWEEN :v_start and :v_end ";  
            var attribparams= {":v_retailerid" : this.dataService.getPosId(),":v_start":  fromdate , ":v_end" : todate};
            return await this.queryOnIndex('feedback', "posid-businessdate-index", conditionexpression,attribparams);
        }catch(err){
            console.error('ERror fetching Feedback list ' + err);
            return null;
        }
    }
    


    async queryOnIndexDesc(tablename, indexname,keyconditionexpression, expressionattributvalues,limit){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';
        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true});
       
        var params = {
            "TableName": tablename,
            "IndexName": indexname,
            "KeyConditionExpression": keyconditionexpression,
            "ExpressionAttributeValues": expressionattributvalues,
            "ScanIndexForward": false, 
            "Limit": limit
        }

        try{

            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| SORT DESC  :' + JSON.stringify(params)) ;

            var retpromise= await dynamoDB.query(params).promise();

            if(retpromise.$response.data){
                return retpromise.$response.data.Items;
            }else{
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename + ' On index ' + indexname) ;
                console.error(retpromise.$response.error); 
                return null; 
            }

        }catch(err){
            if(Constants.DEBUG_MODE) console.error('|DYNAMODBSERVICE| ERROR fetching data from  :' + tablename + ' On index ' + indexname + JSON.stringify(err)) ;
            return null; 
        }

        

    }


    async getAllItemsForPosId(tablename){
        var posid =  this.dataService.getPosId();
        var conditionexpression = "posid = :v_retailerid";  
        var attribparams= {":v_retailerid" : posid };
        return await this.queryOnSortKey(tablename, conditionexpression,attribparams);

    }

    async queryCustomer(mobilenumber){
        var posid =  this.dataService.getPosId();
        var conditionexpression = "posid = :v_retailerid and id = :v_custid";  
        var attribparams= {":v_retailerid" : posid , ":v_custid" : parseInt(mobilenumber)};
        var customers = <Customer[]> await this.queryOnSortKey("poscustomer", conditionexpression,attribparams);
        
        if(customers != null && customers.length > 0 ){
            return customers[0];
        }else{
            return null;
        }

    }


    async queryOrder(id){
        try{
            var posid =  this.dataService.getPosId();
            var conditionexpression = "posid = :v_retailerid and id = :v_orderid";  
            var attribparams= {":v_retailerid" : posid , ":v_orderid" : parseInt(id)};
            var orders = <Order[]> await this.queryOnSortKey("order", conditionexpression,attribparams);
            
            if(orders != null && orders.length > 0 ){
                return orders[0];
            }else{
                return null;
            }
        }catch(err){
            return null;
        }

    }

    async getOrderHistory(mobilenumber){
        var posid =  this.dataService.getPosId();
        
        var conditionexpression = "posid = :v_retailerid and customermobile = :v_mobile";  
        var attribparams= {":v_retailerid" : posid ,":v_mobile": ''+ mobilenumber};
        var retlist  = <Order[]> await this.queryOnIndex("order", "posid-customermobile-index", conditionexpression,attribparams);

    return retlist;

    }



    async fetchPendingorders(lasttimestamp){

        try{
            var posid =  this.dataService.getPosId();

            var conditionexpression = "posid = :v_retailerid and updatedon > :v_updatedon";  
            var attribparams= {":v_retailerid" : posid ,":v_updatedon":  lasttimestamp};
            var retlist  = <Order[]> await this.queryOnIndex("order", "posid-updatedon-index", conditionexpression,attribparams);

            
            console.log('orders found from ' + new Date(lasttimestamp) +  ' -- ' + retlist.length);
            return retlist;
        }catch(err){
            console.error('Error fetching Orders ' + err);
        }


    }



    async getOrders(starttime, endtime){
        var posid =  this.dataService.getPosId();

        var conditionexpression = "posid = :v_retailerid and id BETWEEN :v_start and :v_end ";  
        var attribparams= {":v_retailerid" : posid ,":v_start":  starttime , ":v_end" : endtime};
        var retlist  = <Order[]> await this.queryOnSortKey("order", conditionexpression,attribparams);

        console.log('orders found --> '  + retlist.length);
        return retlist;

    }



    async getCustomers(){
        var posid =  this.dataService.getPosId();
        if(posid == "1337" || posid == "1338" || posid == "1350") posid = "1329";
                    
        var conditionexpression = "posid = :v_retailerid";  
        var attribparams= {":v_retailerid" : posid};
        var retlist  = <Customer[]> await this.queryOnSortKey("poscustomer", conditionexpression,attribparams);

        console.log('Customers found --> '  + retlist.length);
        return retlist;

    }




    async conditionalPutItem(tablename, item){
        AWS.config.accessKeyId = this.dataService.dynamoaccessid;
        AWS.config.secretAccessKey = this.dataService.dynamokey;
        AWS.config.region='ap-south-1';

        var curupdatedon = item.updatedon; 
        var conditionexpression = "updatedon = :updatedon" ;  
        var attribparams= {":updatedon" : curupdatedon};
            
        item.updatedon = new Date().getTime() + this.timeoffset;
        var dynamoDB = new AWS.DynamoDB.DocumentClient({region : 'ap-south-1', convertEmptyValues : true });
        var params = {
            TableName: tablename,
            Item: item,
            "ConditionExpression": conditionexpression,
            "ExpressionAttributeValues" : attribparams
        };

        try{
            var retpromise= await dynamoDB.put(params).promise();
            if(retpromise.$response.data){
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| Item put in :' + tablename);
                return 'DONE'; 
            }else{
                if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| ERROR putting item in  :' + tablename);
                console.error(retpromise.$response.error);
                item.updatedon = curupdatedon;
                return 'ERROR'; 
            }
        }catch(err){
            if(Constants.DEBUG_MODE) console.log('|DYNAMODBSERVICE| EXCEPTION putting item in  :' + tablename + err );
            item.updatedon = curupdatedon;
            return 'ERROR'; 
        }

    
    }


    //async logAuditEvent()
    


}