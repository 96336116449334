import {Component,OnInit,Input,ViewChild,ElementRef, OnDestroy, HostListener} from '@angular/core';
import { Billingcounter } from '../domain/billingcounter';
import { Billingservice } from '../service/billingservice';
import { Appdataservice } from '../service/appdataservice';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Router } from '@angular/router';
import { Kitchenservice } from '../service/kitchenservice';
import { Constants } from '../domain/constants';
import { Kot } from '../domain/kot';
import { Summaryrecord } from '../domain/summaryrecord';
import { Cartlineitem } from '../domain/cartlineitem';
import { isUndefined } from 'util';

@Component({
    templateUrl: 'counterview.html'
})
export class Counterview implements OnInit,OnDestroy{
    billingcounterlist : Billingcounter[];
    showitembar : string;
 
    appVersion : string; 
    displayContextMenu = false;
    selectedCounter : Billingcounter;
 
    constructor(
        public billingService : Billingservice, 
        public dataService: Appdataservice,
        public messageService : MessageService,
        public datePipe : DatePipe,
        public counterService : Billingcounterservice, 
        public kitchenService : Kitchenservice,
        private _router : Router) {
        
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }

    }

    
    getPanelHeight(){
        var returningheight =  (window.innerHeight * 0.85) - 60 +  "px"; 
        return returningheight;
    }

    getViewHeight(){
        var returningheight =  (window.innerHeight * 0.85) +  "px"; 
        return returningheight;
    }
    getBillingViewStyle(){
        return "{height: '550px'}";
  
    }
    
    hideScrollbars(){
        document.documentElement.style.overflow = 'auto';  // firefox, chrome
    }


    showErrorToast(message){
        console.log('**ERROR ' + message);
        this.messageService.clear();
        this.messageService.add({severity:'error',   key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    counterlist : Billingcounter[];

    getWidth(){
        if(Constants.DEBUG_MODE) console.log('Width ' + this.getScreenWidth() + ' returning ' + this.getScreenWidth() * 0.9);
        return this.getScreenWidth() * 0.9;
    }

    getHeight(){
        if(Constants.DEBUG_MODE) console.log('Height' + this.getScreenHeight());
        return this.getScreenHeight() - 20;
    }
    

    getScreenWidth(){
        return window.screen.width;
    }

    getScreenHeight(){
        return window.screen.height;
    }
 

    ngOnDestroy(){
        this.dataService.lastrefreshtime = '';
    }
    
    ngOnInit() {
        this.setCardWidth();
            
    }

    async markServed(ctr : Billingcounter){
        if(!ctr.arrselecteditems || ctr.arrselecteditems.length == 0){
            this.showErrorToast('Please select at least one item to mark as ready!');
            return;
        }
        var itemfound = false; 
        for(var item of ctr.arrselecteditems){
            if(item.kitstatus == Constants.READY_AT_KITCHEN) itemfound = true; 
        }
        
        if(!itemfound){
            this.showErrorToast('Please select at least one item to mark as ready!');
            return;
        }
        
        this.showspinner = true; 
        var ret = await this.counterService.markItemsAsServed(ctr);
        this.showspinner = false; 
        if(!ret){
            this.showErrorToast('Error marking items as served, please try again!');
        }

    }
    
    
    cardwidth;
    showspinner; 

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setCardWidth();
    }

    setCardWidth(){
        if(window.innerWidth < 600){
            this.cardwidth = window.innerWidth - 10;
        }else if(window.innerWidth < 900){
            this.cardwidth = window.innerWidth / 2 - 10 ;
        }else if(window.innerWidth < 1200){
            this.cardwidth = window.innerWidth / 3 - 10 ;
        }else{
            this.cardwidth = window.innerWidth / 4 - 10 ;
        }
    }



        

}


