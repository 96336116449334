import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from '../domain/order';
import { Appdataservice } from '../service/appdataservice';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Orderservice } from '../service/orderservice';
import { Constants } from '../domain/constants';
import { Utils } from '../service/utils';
import { Deliveryboyservice } from '../service/deliveryboyservice';
import { Aggregatorservice } from '../service/aggregatorservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Callcenterservice } from '../service/callcenterservice';
import { Userservice } from '../service/userservice';
import { Customer } from '../domain/customer';
import { Kitchenservice } from '../service/kitchenservice';
import { Billingservice } from '../service/billingservice';
import { Printingservice } from '../service/printingservice';


@Component({
    templateUrl: 'pendingorderlist.html',
    providers: [ConfirmationService]
})
export class Pendingorderlist implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    statusoptions : SelectItem[];
    selectedstatus; 
    currentviewtype;

    isRunning = false; 
    
    showpendingonly;

    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private counterService : Billingcounterservice,
        private aggregagtorService : Aggregatorservice,
        private orderService  : Orderservice,
        public ccService : Callcenterservice,
        public printingService : Printingservice,
        private userService : Userservice,
        private billingService : Billingservice,
        private kitchenService : Kitchenservice,
        private deliveryboyService : Deliveryboyservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    getTips(ord: Order){

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == Constants.PYMT_CARD){
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        try{
                            var tip = parseFloat(strtip);
                            return tip;
                        }catch(err){}
                    }
                }
        
            }
        }
        return 0;
    }

    cardwidth;
    setCardWidth(){
        if(window.innerWidth < 500){
            this.cardwidth = window.innerWidth - 10;
        }else if(window.innerWidth < 1200){
            this.cardwidth = window.innerWidth / 3 - 10 ;
        }else{
            this.cardwidth = window.innerWidth / 4 - 10 ;
        }


    }

    deliveryboys;
        
    ngOnInit(){
        //this.fromdate = new Date(new Date().setHours(0,0,0,0));
        //this.todate = new Date(new Date().setHours(0,0,0,0));
        console.log('On Init Called!');
        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
    
        this.selectedRadio = 'itemdetails';
        this.showpendingonly = false; 
        this.statusoptions = [
            { label: 'Processed', value: Constants.POS_ORDER_PROCESSED },
            { label: 'Void', value: Constants.POS_ORDER_CANCELLED}
        ];
        this.setCardWidth();
    
        this.selectedstatus = Constants.POS_ORDER_PROCESSED;
        
        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'receiptno', header: 'Invoice no' },
            { field: 'createdonstr', header: 'Created on' },
            { field: 'countertypestr', header: 'Counter type' },
            { field: 'countername', header: 'Counter name' },
            { field: 'numguests', header: 'Num Pax' },
            { field: 'customername', header: 'Customer name' },
            { field: 'customermobile', header: 'Customer mobile' },
            { field: 'orderitemsstr', header: 'Items' },
            { field: 'cart.sumitemcount', header: 'Item count' },
            { field: 'cart.sumitemamount', header: 'Item amount' },
            { field: 'cart.sumitemgross', header: 'Item gross' },
            { field: 'cart.totaldiscount', header: 'Discount' },
            { field: 'cart.sumitemtaxable', header: 'Item Taxable' },
            { field: 'cart.sumitemtax', header: 'Item Taxes' },
            { field: 'cart.sumitemnet', header: 'Item Total' },
            { field: 'cart.netcharges', header: 'Charges' },
            { field: 'cart.roundingamount', header: 'Rounding' },
            { field: 'cart.netpayable', header: 'Bill amount' },
            { field: 'cart.paidamount', header: 'Paid amount' },
            { field: 'cart.pendingamount', header: 'Pending amount' }
            
        ];

        this.cols.push({ field: 'pymtcash', header: 'Cash' });
        this.cols.push({ field: 'pymtcard', header: 'Card' });
        this.cols.push({ field: 'tips', header: 'Tips' });
        this.cols.push({ field: 'pymtonline', header: 'Online' });
        this.cols.push({ field: 'pymtcustwallet', header: 'Customer Wallet' });
        this.cols.push({ field: 'pymtaggregator', header: 'Aggregator' });
        this.cols.push({ field: 'pymtcomplimentary', header: 'Complimentary' });
        this.cols.push({ field: 'pymtcoupon', header: 'Coupon' });
        this.cols.push({ field: 'pymtzomatopro', header: 'Zomato Pro' });
        this.cols.push({ field: 'pymtothercredit', header: 'Other credit'});
        this.cols.push({ field: 'statusstr', header: 'Status' });
        
        this.cols.push({ field: 'checkedout', header: 'Checkedout' });
        this.cols.push({ field: 'details', header: 'Details' });


        //this.cols.push({ field: 'kotnum', header: 'KOTs' });
        //this.cols.push({ field: 'kotcount', header: 'KOT count' });

        this.cols.push({ field: 'fooddisc', header: 'Food Disc' });
        this.cols.push({ field: 'bevdisc', header: 'Bev Disc' });
        this.cols.push({ field: 'tobdisc', header: 'Tob Disc' });
        this.cols.push({ field: 'misdisc', header: 'Misc Disc' });


        if(this.dataService.retailer.id == 1554){
            this.cols.push({ field: 'vatamt', header: 'VAT On Amount' });
            this.cols.push({ field: 'cart.sumvat', header: 'VAT' });
    
        }
        
        this.orderlist = [];
        this.setData(null);

        this.deliveryboys =[];
            this.locationlist = [];


            for(var boy of this.deliveryboyService.deliveryboylist){
                if(boy.active){
                    var boydetails = boy.name + ' (' + boy.mobilenumber + ') ';
                    this.deliveryboys.push({label: boydetails, value: boydetails});
                    if(this.deliveredby == null){
                        this.deliveredby = boydetails;
                    }
                }
            }

            if(this.dataService.retailer.multilocation){
                for(var loc of this.dataService.locationlist){
                    if(loc.id != this.dataService.retailer.locationid){
                        this.locationlist.push({label: loc.locationname, value: loc.id});
                    }
                }
            }

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    setDeliveredBy(ord : Order){
        var dboy = this.deliveryboyService.getDeliveryboyStringForId(ord.deliveredby);
        ord.deliveredbystr = dboy;
    }

            
    first : number = 0; 

    showErrorToast(message){
        this.showspinner = false;
         
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 
    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
        //if(ord.orderstatus != Constants.POS_ORDER_CANCELLED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;
            this.totalreceivableamount = this.totalreceivableamount + ord.cart.pendingamount;
        //}
    }

    roundTotals(){
        
    }

    selectedRadio;



    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
    }

    findInArray(arrtokens,newtok){
        for(var item of arrtokens){
            if(item == newtok) return true; 
        }
        return false; 
    }
    
    setKOTTokens(ord : Order){
        var arrtoken = [];
        if( ord.cart && ord.cart.lineitems != null && ord.cart.lineitems.length > 0){
            for(var item of ord.cart.lineitems){
                if(!this.findInArray(arrtoken,item.kottoken)){
                    arrtoken.push(item.kottoken);
                }
            }

        }
        ord.kotnum = arrtoken.join("|");
        ord.kotcount = arrtoken.length;


    }
    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
            /*if(ord.countertype == Constants.ONLINE){
                var counter = this.counterService.getBillingcounterForId(ord.counterid);
                
                ord.aggrname = counter.aggregatorname;
            }*/
        
            
            ord.details = Utils.getOrderDetails(ord);
        }

        //Set mode wise payments on order 
        ord.pymtcash = Utils.getModeWisePayment(Constants.PYMT_CASH,ord);
        ord.pymtcard = Utils.getModeWisePayment(Constants.PYMT_CARD,ord);
        ord.tips = this.getTips(ord);
        ord.pymtonline = Utils.getModeWisePayment(Constants.PYMT_PG,ord);
        ord.pymtforex = Utils.getModeWisePayment(Constants.PYMT_FOREX,ord);
        ord.pymtcustwallet = Utils.getModeWisePayment(Constants.PYMT_WALLET,ord);
        ord.pymtaggregator = Utils.getModeWisePayment(Constants.PYMT_AGGREGATOR,ord);
        ord.pymtcomplimentary = Utils.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord);
        ord.pymtzomatopro = Utils.getModeWisePayment(Constants.PYMT_ZOMATO_PRO,ord);
        ord.pymtothercredit = Utils.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);
        ord.pymtcoupon = Utils.getModeWisePayment(Constants.PYMT_COUPON,ord);
        
        if (ord.receiptno && ord.receiptno.length > 0){
            if(ord.receiptno.indexOf("/") > 0){
                ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
            }else{
                ord.rcptnodisplay = ord.receiptno;
            }
        }
        

    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }



    bifurcateDiscounts ( ord : Order){
        ord.fooddisc = 0;
        ord.bevdisc = 0;
        ord.misdisc = 0;
        ord.tobdisc = 0;
         
        for(var item of ord.cart.lineitems){
            if(item.discount > 0){
                var prod = this.dataService.getProductForId(item.productid);
                if(prod){
                    var cat = this.dataService.getCategoryForId(prod.categoryid);
                    if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                        ord.bevdisc = Utils.round(ord.bevdisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                        ord.fooddisc = Utils.round(ord.fooddisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                        ord.tobdisc = Utils.round(ord.tobdisc + item.discount,3);
                    }else{
                        ord.misdisc = Utils.round(ord.misdisc + item.discount,3);
                    }
                }
            }
        } 
    }


    checkBillingPrivs(){
        if(this.userService.loggedinusergroup && this.userService.loggedinusergroup.arrbillingprivs){
            if(this.userService.checkBillingPriv(Constants.PRIV_SALES)){
                return true; 
            }
        }

        this.showErrorToast('Billing privileges not assigned to this user!');
        return false; 
    }

    showspinner;

    async reprintKOT(order : Order){
        var kotarray = this.kitchenService.getKOTArrayFromCart(order.cart);

        
        if(kotarray && kotarray.length > 0){
            
            var kotforreprint = kotarray[0];
            kotforreprint.agentname = order.billedbystr;

            this.billingService.reprinttype = 'kot';
            this.billingService.kotForReprint = kotforreprint;

            this.billingService.makeReprintDialogVisible = true;

        

        }

    }

    async migrateOrder(){
        var ord = this.orderService.getClone(this.selectedOrder);

        var newposid = this.dataService.retailer.id + "|" + this.newlocation;
        if(newposid == this.selectedOrder.posid){
            this.showErrorToast('New location same as old location!');
            return;
        }

        if(!this.migratereason || this.migratereason.trim() == '' ){
            this.showErrorToast('Please provide migration reason!');
            return;
        }

        //Change the location details 
        ord.oldlocname = this.dataService.getPosNameById(this.selectedOrder.posid);
        ord.oldlocid = this.selectedOrder.posid;

        ord.posid = newposid; 
        ord.locationname = this.dataService.getPosNameById(newposid);
        ord.migratereason = this.migratereason;
        this.showspinner = true; 
        
        var ret = await this.dynamoService.putItem('callcenterorders', ord);
        var ret1 = await this.dynamoService.deleteItem('callcenterorders', {posid : this.selectedOrder.posid , id: this.selectedOrder.id});
        
        
        
        var ret2 = this.setData(null);
        this.showspinner = false; 
        


        this.displaymigrateDialog = false; 
    }

    
    migratereason; 
    locationlist;
    newlocation;
    displaymigrateDialog;

    showMigrationDialog(ord : Order){
        this.selectedOrder = this.orderService.getClone(ord);
        this.newlocation =  this.dataService.locationlist[0].id;
        this.migratereason = '';

        this.displaymigrateDialog = true;
    }


    displayacceptDialog;

    async settlementAdvice(){
        document.getElementById("printable").innerHTML = this.printingService.printSettlementAdvice(this.selectedOrder);
        setTimeout(()=>{ window.print();}, 200);
    }

    
    confirmAccept(ord: Order){
        this.selectedOrder = this.orderService.getClone(ord);
        this.selectedOrder.addressstr  = this.selectedOrder.deladdrline1 + ", " + this.selectedOrder.deladdrline2;
        this.displayacceptDialog = true;
    }

    printReceipt(ord : Order){
        document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(ord,false);
        console.log('|HTML|' + document.getElementById("printable").innerHTML);
        setTimeout(()=>{ window.print();}, 200);
    }
    

    async markFoodReady(order : Order){
        this.showspinner = true; 

        var ord = this.orderService.getClone(order);
        ord.orderstatus = Constants.FOOD_READY;
        ord.foodreadyon = new Date().getTime();
        
        var ret = await this.dynamoService.putItem('callcenterorders', ord);
        
        if(ret == 'DONE'){
            await this.setData(null);
       
        }else{
            this.setData(null);

            this.showErrorToast("Error while updating order, please try again!");
            return;
        }

        this.showspinner = false; 
       

    }

    confirmDispatch(ord: Order){
        this.selectedOrder = this.orderService.getClone(ord);
        this.displayDispatchDialog = true;
    }
    

    displayDispatchDialog;
    deliveredby;
    
    async dispatchOrder(){
        this.selectedOrder.deliveredby = this.deliveredby;
        this.selectedOrder.deliveryon = new Date().getTime();

        this.selectedOrder.orderstatus  = Constants.OUT_FOR_DELIVERY;
        this.showspinner = true;
        var retstatus = await this.dynamoService.putItem('callcenterorders', this.selectedOrder);
        await this.setData(null);
        this.displayDispatchDialog= false;
            
    }

    completionrunning; 

    async markCompleted(){
        if(this.completionrunning ) return; 
        this.completionrunning = true;
        
        var ord = this.selectedOrder;

        var key  = {"posid": "" + ord.posid, "id" : ord.id};
        console.log('KEY ' + JSON.stringify(key));
        var ccorder  = <Order> await this.dynamoService.getItem('callcenterorders',key);
        if(ccorder){
            //If this is already completed, then just return from here 
            if(ccorder.orderstatus == Constants.ORDER_EXECUTED){
                await this.setData(null);
                this.showspinner = false;
                this.completionrunning = false;
                return;         
            }
        }


        ord.orderstatus  = Constants.ORDER_EXECUTED;
        var ispizzapan = false;
        if(ord.countertype == Constants.ONLINE){
            ispizzapan = this.aggregagtorService.isPizzaPan(ord.aggrid);
        }
        ord.ispizzapan = ispizzapan;
        this.showspinner = true; 

        var retstatus = await this.dynamoService.putItem('callcenterorders', ord);
        
        var neword  = this.orderService.getClone(ord);
        neword.createdon = new Date().getTime();
        neword.id = new Date().getTime();
        neword.updatedon = new Date().getTime();
        neword.callsessionid  = ord.callsessionid;
        neword.ccordid = ord.id;
        neword.orderstatus= Constants.POS_ORDER_PROCESSED;
        neword.checkedout = true; 

        await this.dynamoService.putItem('orders', neword);

        await this.setData(null);
        this.showspinner = false;
        this.completionrunning = false;
        

    }





    async completeOrder(ord : Order){
        this.selectedOrder  = ord;
        if(ord.cart.pendingamount > 0){
            this.showPaymentDialog();
        }else{
            this.markCompleted();
        } 
    }


    async acceptOrder(){
        var order = this.selectedOrder;
        this.showspinner = true; 
        if(!this.checkBillingPrivs()) return; 

        console.log('** counter typer : ' + order.countertype);
        var counter = this.counterService.getFreeCounter(order);
        if(counter == null){
            this.showErrorToast("No free counter found for the order!");
            return;
        }

        order.counterid= counter.id; 
        order.orderstatus = Constants.CONFIRMED_ORDER;
        order.countertype  = counter.countertype;
        order.countername = counter.countername;
        order.acceptedon = new Date().getTime();
        order.sessionid = order.counterid + "|" + order.id;


        var curuser = this.userService.loggedinuser;
        var userid= 0;
        if(curuser) userid = curuser.id;
        order.billedby = userid;

        var ret = await this.dynamoService.putItem('callcenterorders', order);

        if(ret == 'DONE'){
            var retkot = await this.kitchenService.generateCCKOTs(order,counter);
            if(retkot != 'ok'){
                order.kotprinted = false; 
            }else{
                order.kotprinted = true;
            }

            order.receiptno = await this.orderService.getNewReceiptNo();
            var ret = await this.dynamoService.putItem('callcenterorders', order);

            this.settlementAdvice();
            await this.setData(null);

        }else{
            this.showErrorToast("Error while updating order, please try again!");
            return;
        }


        this.showspinner = false;
        this.displayacceptDialog = false; 
        //this.showSuccessToast('Order accepted');


    }

    showPaymentDialog(){
        if(this.selectedOrder && this.selectedOrder.cart){
            if(!this.selectedOrder.cart.paymentlist) this.selectedOrder.cart.paymentlist = [];
            this.billingService.orderForPayment = this.selectedOrder;
            console.log('Cart Net payable ' + this.selectedOrder.cart.netpayable);

            this.billingService.ccmanagementinstance = this; 
            this.billingService.pymtfromordermanagement = false; 
            this.billingService.pymtfromcallcenterorder = true; 
            this.billingService.makepaymentdialogvisible = true;
            this.billingService.pymtfromuporder = false; 
            
        }
        
    }

    


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){
                var ord = this.orderService.getClone(item);
                console.log('Receipt no :: ' + ord.receiptno);
                
                //Get status 
                ord.statusstr = Utils.getStatusStr(ord);
                ord.orderitemsstr = Utils.itemsAsString(ord);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
                
                this.setDateStrings(ord);
                this.setPaymentString(ord);
                //this.setDeliveredBy(ord);
                //this.setKOTTokens(ord);
                this.bifurcateDiscounts(ord);
                this.filteredlist.push(ord);
                this.incrementTotals(ord);
                
                if(this.dataService.retailer.id == 1554){
                    ord.vatamt = Utils.round(ord.cart.sumitemtaxable + ord.cart.sumsc,3);
                }
        
        }
        this.filteredlist = [... this.filteredlist];
            

    }

    dunzostatus; 
    
    async setData(dt : Table){
        //this.setTotalsToZero();
      
        //this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.showspinner = true; 
        var orderlist = await this.ccService.fetchPendingorders();
        if(orderlist){
            orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            for(var ord of orderlist){
                this.ccService.setStrings(ord);
            }

            this.ccService.ccordersinprocess = orderlist; 
        }
        this.showspinner = false;

        /*if(orderlist){
            this.ccService.ccordersinprocess = orderlist;
            this.orderlist = orderlist; 
            this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
            }else{
                this.setFilterList();
            }
            if(dt) dt.first = 0;
            this.filteredlist = [... this.filteredlist];
        }*/
        
    }


    
    
}


