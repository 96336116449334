import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Userservice } from 'src/app/service/userservice';



@Component({
    templateUrl: 'paymentdetails.html',
    providers: [ConfirmationService]
})
export class Paymentdetails implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Payment[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private orderService  : Orderservice,
        private userService  : Userservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'itemdetails';
        
        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'receiptno', header: 'Receipt no' },
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'createdonstr', header: 'Created on' },
            { field: 'countertypestr', header: 'Counter type' },
            { field: 'countername', header: 'Counter name' },
            { field: 'customername', header: 'Customer name' },
            { field: 'customermobile', header: 'Customer mobile' },
            { field: 'paymentmodename', header: 'Payment mode' },
            { field: 'paidamount', header: 'Paid amount' },
            { field: 'tenderamount', header: 'Tender amount' },
            { field: 'tendercurrency', header: 'Tender currency' },
            { field: 'returnamount', header: 'Return amount' },
            { field: 'pymtreference', header: 'Txn Reference' },
            { field: 'cardno', header: 'Card no' },
            { field: 'cardtype', header: 'Card type' },
            { field: 'addlinfo', header: 'Addl Info' },
            { field: 'remarks', header: 'Remarks' }
        ];

        
        this.orderlist = [];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    
    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalpaidamount = 0;
    totaltip = 0;
   
    setTotalsToZero(){
        this.totalpaidamount = 0;
        this.totaltip = 0;
    }


    incrementTotals(pymt : Payment){
        this.totalpaidamount = this.totalpaidamount + pymt.paidamount;
        
        if(pymt.paymentmode == Constants.PYMT_CARD){
            var strtip = "" + pymt.tip;
            
            if(strtip && strtip.length > 0){
                this.totaltip = Utils.round(this.totaltip +  parseFloat(pymt.tip),2);
            }
        }
    }

    roundTotals(){
        
    }

    selectedRadio;


    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(pymt : Payment , ord : Order){
        pymt.createdonstr = this.datePipe.transform(pymt.id, "dd/MM HH:mm");
        pymt.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        pymt.orderid = ord.id; 
        pymt.receiptno =ord.receiptno;
        pymt.countername = ord.countername;
        
        if(ord.servedby && ord.servedby > 0){
            pymt.servedbystr = this.userService.getUserNameForId(ord.servedby);
        }
        if(!pymt.tip) pymt.tip =0;
     
        
    }



    setFilterList(){
        this.filteredlist = [];
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
         
                var ord = this.orderService.getClone(item);
                var countertypestr = Constants.getCounterTypeName(ord.countertype);

                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        this.setDateStrings(pymt,ord);
                        pymt.countertypestr = countertypestr;
                        this.filteredlist.push(pymt);
                        this.incrementTotals(pymt);
                    }
                }
                
            }       
        }
        this.filteredlist = [... this.filteredlist];
            

    }

    async setData(dt : Table){
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


