import {Component} from '@angular/core';
import {Billingscreen} from './billingscreen';
import {DataService} from '../service/dataservice';
import {Router} from '@angular/router';	
import {MenubarModule} from 'primeng/menubar';
import {MenuItem} from 'primeng/api';
import { OverlayPanel, Menu } from 'primeng/primeng';
import {MenuModule} from 'primeng/menu';
import { Constants } from '../domain/constants';
import { Category } from '../domain/category';
import { Billingservice } from '../service/billingservice';
import { Subcategory } from '../domain/subcat';
import { Appdataservice } from '../service/appdataservice';


@Component({
    selector: 'cat-menubar',
    templateUrl: './categorymenucomponent.html',

})
export class Categorymenucomponent {

    items: any[];

    constructor( public billingService : Billingservice, public _router : Router, public dataService : Appdataservice) {}

    showDropdown(cat : Category){
        cat.hide = false;
    }

    showSubcategories(){
        this.billingService.makesubcatlistvisible = true;
    }

    showTopItems(){
        this.billingService.showRecommendedProducts();
        this.billingService.makesubcatlistvisible = false;
    }

    showItems(subcatid,cat : Category){
        if(Constants.DEBUG_MODE) console.log('Subcat clicked --> -->' + subcatid ); //+ ' Event ' + event.label);
        this.billingService.currentSubcategory = subcatid;
        this.billingService.productfilter = '';
        this.billingService.productlist = this.dataService.getSubcategoryForId(subcatid).prodlist;
        this.billingService.productlist=[...this.billingService.productlist];
        cat.hide = true;

        this.billingService.makesubcatlistvisible = false;
    }



    getsubcats(cat : Category){
        var subcatlist: Subcategory[] = [];
        for(let i =0; i < this.dataService.subcatforsale.length; i++){
            var subcat=  this.dataService.subcatforsale[i];
            if(subcat.categoryid === cat.id){
                subcatlist.push(subcat);
            }
        }
        return subcatlist;
    }

    ngOnInit() {
    }


    
}
