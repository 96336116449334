import { Http, RequestOptions, Headers } from "@angular/http";
import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Subcategory } from "../domain/subcat";
import { Product } from "../domain/product";
import { Constants } from "../domain/constants";
import { Customer } from "../domain/customer";
import { Customeraddress } from "../domain/customeraddress";
import { Customization } from "../domain/customization";
import { Retailer } from "../domain/retailer";
import { Retailerlocation } from "../domain/retailerlocation";
import { User } from "../domain/user";
import { Billingcounter } from "../domain/billingcounter";
import { Category } from "../domain/category";
import { Currencyrates } from "../domain/currencyrates";
import { Utils } from "./utils";
import { Retailerscheme } from "../domain/retailerscheme";
import { Billdialog } from "../view/billdialog";
import { Posconfig } from "../domain/posconfig";

@Injectable()
export class Appdataservice {
    
    //Temporary Login URL .. this needs to be redone.. 
    private loginurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/retailerlogin';  // URL to web API
    private smsurl = 'https://api.brandmaster.ae/rest/smsapi/SendTextSMS';  // URL for sending SMS 
    private bulkprodurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveproductbulk';  // URL to web API

    subcatlist : Subcategory[];
    subcatforsale : Subcategory[];
    
    prodlist : Product[];
    productsforsale : Product[];
    schemelist : Retailerscheme[];
    currencyratelist : Currencyrates[];

    isdatainitdone: boolean = false;
    addresspanelvisible : boolean = false;
    locationpanelvisible : boolean = false;

    plunames : any[];

    
    isdashboardbuilding;
    loggedinuser : User;
    loggedinusername; 
    loggedincustomer : Customer;
    deliveryaddress : Customeraddress;
    
    retailer : Retailer;
    locationlist : Retailerlocation[];

    //SELECTED counter
    selectedcounter : Billingcounter;

    catlist : Category[];
    catforsale : Category[];

    showsubcatinmenu; 
    showsubcatimages; 
    showprodimages;
    showsubcatbydefault;

    getPosId(){
        if(this.retailer.multilocation)
            return "" + this.retailer.id + "|" + this.retailer.locationid;
        else
            return "" + this.retailer.id;
    }

    getPosIdForFeedback(){
        if(this.retailer.multilocation)
            return "" + this.retailer.id + "_" + this.retailer.locationid;
        else
            return "" + this.retailer.id + "_0";
    }

    getPosWithLocationId(){
        return "" + this.retailer.id + "|" + this.retailer.locationid;
    }


    getDefaultLat(){
        return 25.237009;
    }

    getDefaultLong(){
        return 55.305973;
    }



    async syncHttp(url,body,apikey?,method?,auth? )  {
        try{

            let headers = new Headers ({ 'Content-Type': 'application/json' });
            if(apikey && apikey.length > 0){
                headers = new Headers ({ 'Content-Type': 'application/json', apikey : apikey });
            }

            if(auth && auth.length > 0){
                headers = new Headers ({ 'Content-Type': 'application/json', authorization : auth });
            }


            let options = new RequestOptions({ headers: headers });
            if(Constants.DEBUG_MODE) console.log('*** HTTP POST URL: '  + url + ' Body ' + JSON.stringify(body));

            if(method && method == 'PUT'){
                const retpromise = await this.http.put(url,body,options).toPromise();
                if(Constants.DEBUG_MODE) console.log('**Return: '  + JSON.stringify(retpromise.json()));
            
                return retpromise.json();
            }

            if(method && method == 'GET'){
                const retpromise = await this.http.get(url,options).toPromise();
                return retpromise.json();
            }


            const retpromise = await this.http.post(url,body,options).toPromise();

            var datestr = JSON.stringify(retpromise.headers);
            if(Constants.DEBUG_MODE) console.log('*** DATE IN RESPONSE HTTP POST URL: '  + datestr);
 

            return retpromise.json();
        }catch(err){
            if(Constants.DEBUG_MODE) console.error('Error in fetching data from: ' + url + ' -- ' + err.toString());
            return null;
        }
    }


    
    async sendSMS(message,mobilenumber){
        let body = JSON.stringify({"Msg": message,"MsgType":0,"Sid":"GLDNDRAGON","Mobiles":mobilenumber,"Dlr":0});
        var response = await this.syncHttp(this.smsurl,body,'3525A65A-CAB1-418C-B2CB-206BF83AE98D361');
        try{
            if(response){// && response.Desc === 'Success') return true; 
              console.log('DESC ' + response.Desc);
              console.log('Response: ' + JSON.stringify(response));
            }
        }catch(err){
            console.error('ERRROR PARSING RESPONSE ' + err)
        }

        return true;

    }

    constructor (private http: Http, private datePipe : DatePipe) {
        
    }

    
    getLocationForId(id) : Retailerlocation{
        for(var loc of this.locationlist){
            if(loc.id == id) return loc;
        }
        return null;
    }

    
    async updateProductsBulk(productlist){
        try{
            let body = JSON.stringify({retailerid : this.retailer.id, productlist: productlist});
            var uploadresponse = await this.syncHttp(this.bulkprodurl,body);
            
            if(uploadresponse){
                    if(uploadresponse.status >= 0){
                        return true; 
                    }else{
                        if(Constants.DEBUG_MODE) console.error('Error in update');
                    }
            }else{
                return false; 
            }
        }catch(err){
            console.error('ERRR  ' + err)
            return false;
        }
    }

    
    /*
    TODO: Right now calling the login service to initialize, will write a separate service 
    */
    async initializeAppData(username, password){
        try{
            
            let body = JSON.stringify({username: username,userpass: password });
            var loginresponse = await this.syncHttp(this.loginurl,body);
            
            if(loginresponse){
                    if(loginresponse.status === 0){
                        this.parseServerResponse(loginresponse);

                        if(this.retailer.multilocation){
                            loginresponse.retailer.registeredemailid = username;
                            loginresponse.retailer.userpass = password;

                        }
                        
                        localStorage.setItem('loginresponse', JSON.stringify(loginresponse));

                        try{
                            var langjson = await this.getLangProducts();
                            //console.log('|LANG|Data Fetched..' + JSON.stringify(langjson));
                            this.setLocalLangDetailsOnProduct(langjson);
                        }catch(err){
                            if(Constants.DEBUG_MODE) console.error('Error in fetching Language Details '  + err);    
                        }
                        return true; 
                    }else{
                        if(Constants.DEBUG_MODE) console.error('Error in fetching data in login '  + loginresponse.statusdescription);
                    }
            }else{
                return false; 
            }
        }catch(err){
            console.error('ERRR  ' + err)
            return false;
        }
    }


    

    addToSubcatforSale(subcategoryforsale : Subcategory){
		for(var subcat of this.subcatforsale){
			if(subcat.id === subcategoryforsale.id) 
				return; 
		}

		this.subcatforsale.push(subcategoryforsale);
    }
    

    //Temp prop
    sendingtoserver;
    
    recommendedproducts : Product[];
    
    setProductDetails(){
        this.productsforsale = [];
        this.subcatforsale = [];
        this.recommendedproducts  =[];

        for(var prod of this.prodlist){
            if(prod.id == 321176) console.log('|PROD|' + JSON.stringify(prod));
            
            
            var subcat = <Subcategory> this.getSubcategoryForId(prod.subcategoryid);
            if(subcat){
                if(prod.status == Constants.YES && prod.producttype != Constants.PURCHASE_ONLY && prod.producttype != Constants.KITCHEN_PROCESSED ){
                    console.log('*** Adding subcat ' + subcat.name);
                    //Customization parse 
                    if(prod.customizationjson && prod.customizationjson.length > 0){
                        try{
                            prod.customization = <Customization> JSON.parse(prod.customizationjson);
                        }catch(ex){
                            if(Constants.DEBUG_MODE) console.log('|PARSEERR|Error parsing customization for ===> ' + prod.name + ' --> ' +  ex);
                        }
                    }
                    

                    var parentsubcat = null;
                    if(subcat.customizationjson && subcat.customizationjson.length > 0){
                        try{
                            var subcustomization = <Customization> JSON.parse(subcat.customizationjson);
                            
                            if(subcustomization.mappedcategoryid && parseInt(subcustomization.mappedcategoryid) > 0){
                                 parentsubcat = this.getSubcategoryForId(subcustomization.mappedcategoryid);       
                            }else{
                                if(prod.customization != null){
                                    prod.customization.optionlist = prod.customization.optionlist.concat(subcustomization.optionlist);
                                }else{
                                    prod.customization = subcustomization;
                                }
    
                            }

                        }catch(ex){
                            if(Constants.DEBUG_MODE) console.log('|PARSEERR|Error parsing subcat customization for ===> ' + prod.name + ' --> ' +  ex);
                        }
                    }

                    if(parentsubcat){
                        subcat = parentsubcat;
                        prod.subcategoryid = parentsubcat.id;
                        prod.subcatname = parentsubcat.name;
                    }

                    var parts = <String[]>prod.name.split(" ");
                    prod.generatedcode = '';
                    for(var part of parts){
                        prod.generatedcode = prod.generatedcode +  part.charAt(0);
                    }
          
                    this.addToSubcatforSale(subcat);
                    subcat.prodlist.push(prod);
                    this.productsforsale.push(prod);

                    if(prod.uom && prod.uom.length > 45){
                        prod.shortdesc = prod.uom.substr(0,45 ) + ' ...';
                    }else
                        prod.shortdesc = prod.uom;

                     if(prod.recommended) this.recommendedproducts.push(prod);   
                    
                }
            }
        }

    }

    getSubcategoryForId(id) : Subcategory{
        for (var subcat of this.subcatlist){
            if(subcat.id === id){
                return subcat;
            }
        }
        return null;
    }

    getSubcategoryNameForId(id) {
        for (var subcat of this.subcatlist){
            if(subcat.id === id){
                return subcat.name;
            }
        }
        return "";
    }

    getProductForId(id) : Product{
        for (var prod of this.prodlist){
            if(prod.id === id){
                return prod;
            }
        }
        return null;
    }

    currencysymbol;
    displaycurrsymbol;

    public getGuestTypeId( guesttypename) {
        if(this.retailer.appconfig.posconfig.guesttypes && this.retailer.appconfig.posconfig.guesttypes.length > 0){
            var  i =0;
            for(var guesttype of this.retailer.appconfig.posconfig.guesttypes){
                i++;
                if(guesttype.trim().toLowerCase() == guesttypename.trim().toLowerCase()){
                    return i;
                }
            }           
        }
        return 0;
    }


    setLocalLangDetailsOnProduct(langjson){

        //console.log('|LANG|Num of local prods --> ' + JSON.stringify(langjson));
        if(langjson && langjson.Sheet1 && langjson.Sheet1.length > 0){

            var arrprods = langjson.Sheet1;
            console.log('|LANG|Num of local prods --> ' + arrprods.length);
            for(var prod of arrprods){
                console.log('|LANG|Searching For --> ' + prod.pcode);

                var curprod = this.getProductForCode(prod.pcode);
                if(curprod != null){
                    console.log('|LANG|Setting local name to  --> ' + prod.localname);

                    curprod.localname = prod.localname; 
                }
            }
        }

    }

    getProductNameFromPLUCode(langjson,plucode){
        if(langjson && langjson.products && langjson.products.length > 0){
            var arrprods = langjson.products;
            for(var prod of arrprods){
                if(prod.code == plucode) return prod.name; 
            }
        }

        return "";
    }


    getProductForCode(code) : Product{
        for (var prod of this.productsforsale){
            if(prod.code == code){
                return prod;
            }
        }
        return null;
    }

    async getLangProducts(){
        var url = "https://s3.ap-south-1.amazonaws.com/foodkartiposimages/appassets/" + this.retailer.id + "localprod.json?random=" + new Date().getTime();
        const retpromise = await this.http.get(url).toPromise();
        if(Constants.DEBUG_MODE) console.log('Response of lang '  + JSON.stringify(retpromise.json()));
        return retpromise.json();
    }



    async getPLUNames(){
        var url = "https://s3.ap-south-1.amazonaws.com/foodkartiposimages/appassets/" + this.retailer.id + "plunames.json?random=" + new Date().getTime();
        const retpromise = await this.http.get(url).toPromise();
        if(Constants.DEBUG_MODE) console.log('Response of lang '  + JSON.stringify(retpromise.json()));
        return retpromise.json();
    }

    async getCustomerDataFile(filename){
        var url = "https://s3.ap-south-1.amazonaws.com/foodkartiposimages/appassets/" + filename + "?random=" + new Date().getTime();
        console.log('CUST URL '  + url);
        const retpromise = await this.http.get(url).toPromise();
        console.log('Response of customers '  + JSON.stringify(retpromise.json()));
        return retpromise.json();
    }

    async getPLUCodeNames(){
        var url = "https://s3.ap-south-1.amazonaws.com/foodkartiposimages/appassets/" + this.retailer.id + "plucodename.json?random=" + new Date().getTime();
        const retpromise = await this.http.get(url).toPromise();
        if(Constants.DEBUG_MODE) console.log('Response of PLU CODE NAME '  + JSON.stringify(retpromise.json()));
        return retpromise.json();
    }


    async getCouponCodes(){
        var url = "https://s3.ap-south-1.amazonaws.com/posme.info/couponcodes.json?random=" + new Date().getTime();
        const retpromise = await this.http.get(url).toPromise();
        if(Constants.DEBUG_MODE) console.log('Response of PLU CODE NAME '  + JSON.stringify(retpromise.json()));
        return retpromise.json();
    }
    public isRollOverEOD(){
        if(this.retailer.id == 1511 && (this.retailer.locationid == 323 || this.retailer.locationid == 325 || this.retailer.locationid == 337 || this.retailer.locationid == 326 || this.retailer.locationid == 327 || this.retailer.locationid == 328 || this.retailer.locationid == 329 || this.retailer.locationid == 330 || this.retailer.locationid == 370)){
            return true; 
        }else{
            return false; 
        }
    }

    public validateInitialization(){
        if(this.retailer.appconfig.posconfig.validateinitiallization){
            var terminalid = "" + localStorage.getItem('terminalid');
            var posid  = localStorage.getItem('posid');

            if(!terminalid || terminalid.length == 0){
                return false; 
            }

            if(posid != this.getPosId()){
                return false; 
            }

        }

        return true; 
    }

    public validateEODHours(){
        var curHour = new Date().getHours();
        console.log('ROLLOVER' + curHour);
        var minhours =this.retailer.appconfig.posconfig.mineodhours; 
        if(!minhours){
            return true;
        }
        
        
        if((!this.lasteoddate || this.lasteoddate == 0) && curHour > this.retailer.appconfig.posconfig.mineodhours)
            return true;
        
        var d = new Date();
        d.setDate(d.getDate() - 1);
        
        // This will return previous day
        var prevdate = parseInt(this.datePipe.transform(d, "yyyyMMdd")); 

        //If we are WAY in past.. return true..
        if(this.lasteoddate > 0 && this.lasteoddate < prevdate) 
            return true; 

        
        console.log('EODROLL|' + this.lasteoddate + ' PREV DATE ' + prevdate + ' CUR HOUR ' + curHour);
        if(this.lasteoddate == prevdate && curHour > 21)
            return true; 

        return false;
    
    
    }

    public validateEODTimeForRollover(){

            var curHour = new Date().getHours();
            console.log('ROLLOVER' + curHour);
            if((!this.lasteoddate || this.lasteoddate ==0) && curHour > 21)
                return true;
            
            
            var d = new Date();
            d.setDate(d.getDate() - 1);
            
            // This will return previous day
            var prevdate = parseInt(this.datePipe.transform(d, "yyyyMMdd")); 

            //If we are WAY in past.. return true..
            if(this.lasteoddate > 0 && this.lasteoddate < prevdate) 
                return true; 

            
            console.log('EODROLL|' + this.lasteoddate + ' PREV DATE ' + prevdate + ' CUR HOUR ' + curHour);
            if(this.lasteoddate == prevdate && curHour > 21)
                return true; 

            return false;
        
    }
    
    billdialoginstance : Billdialog;


    public getCurrentBusinessDate(){
        //If the EOD is for roll over and last eod date is set 
        if(this.lasteoddate > 0 && this.isRollOverEOD()){
            //If we have done the EOD, 
            var d = Utils.getDateFromBusinessdate(""+ this.lasteoddate);
            d.setDate(d.getDate() + 1);
            return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 

        }else{
            var curHour = new Date().getHours();
            
            if( this.retailer && curHour < this.retailer.endofbusinessday){
                var d = new Date();
                d.setDate(d.getDate() - 1);
                
                // This will return previous day
                return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
            }else{
                return parseInt(this.datePipe.transform(new Date(), "yyyyMMdd")); 
            }
        }
    }


    public getEODCheckDate(){
        var d = Utils.getDateFromBusinessdate(""+ this.getCurrentBusinessDate());
        d.setDate(d.getDate() - 7);

        // This will return previous day
        return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
    }


    public getPreviousBusinessDate(){
        var d = Utils.getDateFromBusinessdate(""+ this.getCurrentBusinessDate());
        d.setDate(d.getDate() - 1);

        // This will return previous day
        return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
    }


    public getNextBusinessDate(){
        var d = Utils.getDateFromBusinessdate(""+ this.getCurrentBusinessDate());
        d.setDate(d.getDate() + 1);

        // This will return previous day
        return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
    }


    getLocationNameById(locid){
        for(var loc of this.locationlist){
            if(loc.id == locid) return loc.locationname;
        }
        return '';
    }

    getPosNameById(locid){
        for(var loc of this.locationlist){
            if(this.retailer.id + "|" + loc.id ==  locid) return loc.locationname;
        }
        return '';
    }

    public notificationmailapi = 'https://ddxryeucb1.execute-api.ap-south-1.amazonaws.com/prod/sendmail';
    public notificationmailapiindia = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/a-sendmail';

    async sendNotificationEmail(emailbody, subject, source, destination){
        var emailurl = this.notificationmailapi;
        if(this.retailer.appconfig.countrycode == '+91') emailurl = this.notificationmailapiindia;
        let body = JSON.stringify({"emailbody": emailbody ,"subject": subject,"sourceemail": source,"destinationemail":destination});
        if(Constants.DEBUG_MODE) console.log('*** sending mail notification --> ' + body);

        try{
            var response = await this.syncHttp(emailurl,body,'');
            if(Constants.DEBUG_MODE) console.log('*** response ' + JSON.stringify(response));
       }catch(err){
           console.error('ERRROR PARSING RESPONSE ' + err)
           return false;
       }

    }

    ccenabled; 
    setCCEnabled(){
        this.ccenabled = false; 
        if(this.retailer.appconfig.posconfig.callcenterlocations && this.retailer.appconfig.posconfig.callcenterlocations.length > 0){
            var strloc = "|" + this.retailer.locationid + "|";
            if(this.retailer.appconfig.posconfig.callcenterlocations.indexOf(strloc)>=0)
                this.ccenabled = true;
        }
    }

    parseServerResponse(data : any ){
        this.subcatlist = <Subcategory[]> data.subcategorylist;
        for (var subcat of this.subcatlist){
            subcat.prodlist = [];
        }

        this.catlist = <Category[]> data.categorylist;
        //console.log('|CAT| **** ' + JSON.stringify(this.catlist));

        this.retailer = <Retailer> data.retailer; 

        console.log('|CAT| Before app config --> ' + this.retailer.takeawayformat);
        
        this.retailer.appconfig = JSON.parse(this.retailer.takeawayformat);
        console.log('|CAT| After app config');
        
        console.log('|LOCCODE|****location code ' + this.retailer.locationcode);
        
        if(this.retailer.multilocation ){
            this.locationlist = <Retailerlocation[]> data.retailerlocationlist;
            var arrloccode = this.retailer.locationcode.split("|");
            if(arrloccode && arrloccode.length == 2){
                this.retailer.appconfig.posconfig.receiptprefix = arrloccode[0];
                this.retailer.appconfig.posconfig.erpstoreid = arrloccode[1];
            }

        }



        
        this.currencysymbol = this.retailer.appconfig.currencysymbol;
        if(this.currencysymbol == 'Rs' ) this.currencysymbol = '₹';

        if(this.currencysymbol && this.currencysymbol.length == 1){
            this.displaycurrsymbol = this.currencysymbol;
        }else
            this.displaycurrsymbol = '';
            
        if(this.retailer.appconfig.posconfig){
           this.showprodimages = this.retailer.appconfig.posconfig.showprodimages;
           this.showsubcatimages = this.retailer.appconfig.posconfig.showsubcatimages;
           this.showsubcatinmenu = this.retailer.appconfig.posconfig.showsubcatinmenu;
           this.showsubcatbydefault = this.retailer.appconfig.posconfig.showsubcatbydefault;
        }


        this.prodlist  = <Product[]> data.productlist;	
        this.schemelist = <Retailerscheme[]> data.schemelist;
        this.currencyratelist = <Currencyrates[]> data.currencyratelist;
    
        //console.log ('SPL PRICE '  + JSON.stringify(this.specialpricelist));
        if(this.schemelist && this.schemelist.length > 0){
            for( var sch of this.schemelist){
                sch.startdate = Utils.getBusinessDateFromString(sch.startdatestr);
                sch.enddate = Utils.getBusinessDateFromString(sch.enddatestr);
            }
        }
            

        this.prodlist.sort((a:Product, b: Product)=> (a.id)  < (b.id)  ? -1 : 1 );
        this.setProductDetails();		

        if(this.retailer.locationid == 0 && this.retailer.multilocation) this.retailer.locationname  = 'Central'; 

        if( this.retailer.appconfig.posconfig.dynamoaccessid && this.retailer.appconfig.posconfig.dynamoaccessid.length > 0){
           
            this.dynamoaccessid = this.retailer.appconfig.posconfig.dynamoaccessid;
            this.dynamokey = this.retailer.appconfig.posconfig.dynamokey;
        }

        this.usedynamo =  this.retailer.appconfig.posconfig.dynamodb;
        this.setCCEnabled();

   }

   searchProductsForBilling(query){
    var resultsproduct : string[];
    resultsproduct = [];
    query = query.trim();

    if(query.length == 0) return resultsproduct;

    var isNumeric = false; 
    if(!isNaN(parseInt(query))) isNumeric = true;

    for(let i = 0; i < this.productsforsale.length; i++) {
        let lretprod = this.productsforsale[i];
        
        if(isNumeric){
            //First compare the code 
            if(lretprod.code === query)  {
                resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                return resultsproduct;
            }
        }else{
            if(query.length === 1){
                if(lretprod.name.toLowerCase().charAt(0) === query.toLowerCase().charAt(0))  {
                    resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                }
            }else if(query.length === 2){
                if(lretprod.name.length > 1){
                    if(lretprod.name.toLowerCase().substring(0,2) === query.toLowerCase() ||  lretprod.generatedcode.toLowerCase().indexOf(query.toLowerCase()) === 0 )  {
                        resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                    }
                }
            }else{
                if(lretprod.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||  lretprod.generatedcode.toLowerCase().indexOf(query.toLowerCase()) === 0 )  {
                    resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                }
            }

            
            
        }

        
    
    }

    return resultsproduct;

    }//End of search 


    public getProductIdByNamePU(productnamecode : string) : number {
        for (var product of this.productsforsale){
            var prname = product.name + ' [' + product.pu + ']';

             if(prname === productnamecode){
                 return product.id;    
             }
         }
         return 0;
     }

     public getProductByNamePU(productnamecode : string) : Product {
        for (var product of this.productsforsale){
            var prname = product.name + ' [' + product.pu + ']';

             if(prname === productnamecode){
                 return product;    
             }
         }
         return null;
     }

    public getProductIdByNameCode(productnamecode : string) : number {
        for (var product of this.productsforsale){
            var prname = product.name + ' [' + product.code + ']';

             if(prname === productnamecode){
                 return product.id;    
             }
         }
         return 0;
     }


     public getSaleProductIdByNameCode(productnamecode : string) : number {
        for (var product of this.productsforsale){
            var prname = product.name + ' [' + product.code + ']';

             if(prname === productnamecode){
                 return product.id;    
             }
         }
         return 0;
     }

     public getProductByNameCode(productnamecode : string) : Product {
        for (var product of this.productsforsale){
            var prname = product.name + ' [' + product.code + ']';

             if(prname === productnamecode){
                 return product;    
             }
         }
         return null;
     }

     public getProductByBarcode(productbarcode : string) : Product {
        for (var product of this.productsforsale){
            if(product.barcode ==  productbarcode)
                return product; 
         }
         return null;
     }

     public getCategoryForId(catid : Number) : Category{
        for (var cat of this.catlist){
            if(cat.id === catid)
                 return cat;
        }
        return null;
     }
 

     getCurrencyByCode(curcode){
        for(var cur of this.currencyratelist){
            if(cur.currencycode == curcode) return cur; 
        }
        
    }
 
    ismultiposlogin;


    isadminlogin;  //Check if its an admin login 
    ismainadmin;  //Check if its an admin login 
    
    isuserloggedin: boolean  = false;
    lasteoddate; 
    strlasteoddate; 
    curbsndate; 
    
    masterrefreshed : boolean = false; 


    //KDS Vars 
    lastrefreshtime;
    iskdslogin;

    csvdownload; 

    loginemailused;
    loginpassused;
    


    //Dynamodb stuff
    usedynamo;
    dynamoaccessid;
    dynamokey; 
}   