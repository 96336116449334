import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,GalleriaModule, InputTextModule, OverlayPanel, ContextMenu,Message,DataTable,AutoComplete, Header,Dialog,InputText,Button} from 'primeng/primeng';
import { DataViewModule } from 'primeng/dataview';
import {DragDropModule} from 'primeng/dragdrop';

import {DataService} from '../service/dataservice';
import {Product} from '../domain/product';
import {Cart} from '../posdomain/cart';

import {Router} from '@angular/router';	
import {Constants} from '../domain/constants';
import {Cartlineitem} from '../posdomain/cartlineitem';
import {HostListener} from '@angular/core';
import {ToolbarModule} from 'primeng/toolbar';
import {MenuItem, MessageService, SelectItem} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';


import * as AWS from 'aws-sdk';
import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../service/billingservice';
import { Appdataservice } from '../service/appdataservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Billingcounter } from '../domain/billingcounter';
import { Userservice } from '../service/userservice';
import { Kitchenservice } from '../service/kitchenservice';
import { EKS, IoT1ClickDevicesService } from 'aws-sdk';
import { Customerservice } from '../service/customerservice';
import { Customer } from '../domain/customer';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Shiftendservice } from '../service/shiftendservice';
import { Utils } from '../service/utils';


@Component({
    templateUrl: 'billingscreen.html'
})
export class Billingscreen implements OnInit{
    showitembar : string;

    appVersion : string; 
    displayContextMenu = false;
    displayTableOpenDialog = false;
    txttableno;
    arrguesttype : string[];
    guesttypeoptions : SelectItem[];
    servedbyoptions : SelectItem[];
    mobilecounters : Billingcounter[];

    selectedItem : Cartlineitem;
    constructor(
        private confirmationService : ConfirmationService,
        public dataService : Appdataservice,
        public counterService : Billingcounterservice,        
        public userService : Userservice,
        public customerService : Customerservice,
        public shiftendService : Shiftendservice,
        private dynamodbservice : Dynamodbservice,
        public kitchenService : Kitchenservice,
        public messageService : MessageService,
        public billingService : Billingservice,
        private _router : Router) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    guestcount;
    guesttype;
    servedby; 
    
    showguestDialog;
    selectedCounter : Billingcounter;
    
    showGuestDialog(){
        if(!this.checkBillingPrivs()) return; 

        
        var ctr  = this.counterService.selectedcounter;
        this.showguestDialog = true; 
        this.guestcount =ctr.numguests;

        if(ctr.guesttype && ctr.guesttype.length > 0)
            this.guesttype = ctr.guesttype;
        
        if(ctr.servedby && ctr.servedby > 0)
            this.servedby = ctr.servedby;
        else{
            this.servedby = this.userService.loggedinuser.id;
        }

        if(this.counterService.selectedcounter.customer && this.counterService.selectedcounter.customer.mobileno){
            this.customer = this.customerService.getClone(this.counterService.selectedcounter.customer);
        }else{
            this.customer = <Customer>{};
        }
        

        this.setScreenDimensions();
        
    }


    showDeliveryDialog(){
        if(!this.checkBillingPrivs()) return; 
        this.billingService.makederliveryDialogVisible = true; 
    }

    showContextMenu(event, ctr : Billingcounter, op : OverlayPanel){
        if(!this.checkBillingPrivs()) return; 

        this.selectedCounter = ctr;
        this.counterService.selectedcounter = ctr;
        op.show(event);
    }

    

    showTransferDialog(op : OverlayPanel){
        
        if(this.selectedCounter.proformaid > 0) {
            this.showErrorToast('Proforma already generated, cant not transfer items!');
            op.hide();
            return;
        }

        if(this.selectedCounter.cart.paymentlist && this.selectedCounter.cart.paymentlist.length > 0) {
            this.showErrorToast('Settlement details have been added, please remove the same before transferring items.');
            op.hide();
            return;
        }

        if(this.selectedCounter.cart.sumitemcount == 0) {
            this.showErrorToast('No items in the cart!');
            op.hide();
            return;
        }
        this.billingService.makeTransferDialogVisible = true;
        op.hide();
    }
    
    showSplitDialog(op : OverlayPanel){
        if(this.selectedCounter.cart.sumitemcount == 0) {
            this.showErrorToast('No items in the cart!');
            op.hide();
            return;
        }
        if(this.kitchenService.checkUnsent(this.selectedCounter.cart)){
            this.showErrorToast('KOT for one or more items is not generated!');
            op.hide();
            return;
        }
        this.billingService.makeSplitDialogVisible = true;
        op.hide();
    }
    
    checkBillingPrivs(){
        if(this.userService.loggedinusergroup && this.userService.loggedinusergroup.arrbillingprivs){
            if(this.userService.checkBillingPriv(Constants.PRIV_SALES)){
                return true; 
            }
        }
        this.showErrorToast('Billing privileges not assigned to this user!');
        return false; 
    }

    async checkEOD(){

        //Get the latest EOD Date for rollover scenario | Time has arrived to check
        if(this.dataService.isRollOverEOD() && this.dataService.validateEODTimeForRollover()){
            var lasteoddate = await this.shiftendService.getLastEOD();
            console.log('|ROLLOVER|EOD LAst date :-- ' + lasteoddate);
        
            if(lasteoddate > 0 && lasteoddate != this.dataService.lasteoddate){
                console.log('|ROLLOVER|Setting LAST EOD date :-- ' + lasteoddate);
                this.dataService.lasteoddate = lasteoddate;
                this.dataService.strlasteoddate = Utils.getBusinessDateAsString("" + lasteoddate);
				this.dataService.curbsndate = Utils.getBusinessDateAsString("" + this.dataService.getCurrentBusinessDate());
            }

        }


        if(this.dataService.lasteoddate && this.dataService.lasteoddate >= this.dataService.getCurrentBusinessDate()){
            this.showErrorToast('EOD has already been run for the current business date!');
            return false; 
        }

        if(this.dataService.lasteoddate && this.dataService.lasteoddate < this.dataService.getPreviousBusinessDate()){
            this.showErrorToast('EOD for previous days has not been completed, please complete the same!');
            return false; 
        }
        return true; 
    }

    openGuestDialog(ctr: Billingcounter){
        if(!this.checkBillingPrivs()) return; 
        if(!this.checkEOD()) return; 
        
        this.selectedCounter = ctr;
        this.counterService.selectedcounter = ctr; 
        this.showGuestDialog();

    }


    async openBillWindow(ctr : Billingcounter, confirmed : boolean){
        if(!this.checkBillingPrivs()) return; 
        if(! await this.checkEOD()) return; 
        
        this.selectedCounter = ctr;
        this.counterService.selectedcounter = ctr; 

        this.showspinner = true;



        //First check if i dont have session
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast("You did not have the latest counter data / counter is locked for update. Please try again!"); 
            return; 
        }
        var curctr=  this.counterService.selectedcounter;

        //If there is no session, create one!
        if(!curctr.sessionid || curctr.sessionid == 0){
            if(!await this.counterService.startCounterSession(curctr)){
                this.showErrorToast("Error starting a session, please try again!"); 
                this.showspinner = false;
                return;
            }
        }

        //Mismatch KOT
        var ret = await this.kitchenService.synchronizeSessionKOTs();
        if(ret != 'ok'){
            this.showErrorToast(ret); 
            this.showspinner = false;
            return;
        }
        

        this.showspinner = false;

        if((curctr.countertype == Constants.NORMAL || curctr.countertype == Constants.AC_SPECIAL) && this.dataService.retailer.appconfig.posconfig.prebilldialog && (curctr.numguests == 0 || curctr.numguests == null ) ) {
            this.showGuestDialog();
        }else if((curctr.countertype == Constants.PARCEL || curctr.countertype == Constants.ONLINE || curctr.countertype == Constants.SELFAPP) && (curctr.customer == null || curctr.customer.name == null)){
            //this.showDeliveryDialog();
            var lockexpiry  = this.dynamodbservice.getSessionId() - (180*1000); //5 mins lock max 

            if(curctr.custentry && curctr.custentry > lockexpiry  ){
                this.showErrorToast('Counter locked for customer entry being made on this counter, please choose another counter!');
                return;
            }else{
                if(await this.counterService.lockForCustomerEntry(curctr)){
                    this.showDeliveryDialog();
                }else{
                    this.showErrorToast('Counter locked for customer entry being made on this counter, please choose another counter!');
                }
                
            }
        }else{ 
            if(!this.ismobile) this.billingService.openBillWindow();
            else{
                this.billingService.showcountername = true; 
                this._router.navigate(['/mobilebilldialog']);
            }

        }
    }

    async clearGuestDetails(){

        if(this.selectedCounter.servedby > 0 && this.selectedCounter.cart.sumitemcount > 0){
            if(!this.userService.checkBillingPriv(Constants.PRIV_CHANGE_SERVED_BY)){
                this.showErrorToast('You dont have the privilege to change served by details, once the order is placed on the table!');
                return;    
            }
            
        }

        this.showspinner = true; 
        //First check if i dont have session
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast("You did not have the latest counter data / counter is locked for update. Please try again!"); 
            this.showspinner = false;
            return; 
        }

        this.selectedCounter.numguests = 0;
        this.selectedCounter.servedby = 0;
        this.selectedCounter.guesttype = '';


        if(this.selectedCounter.cart.sumitemcount == 0)
            this.selectedCounter.occupiedon = 0;

        this.selectedCounter.customer = <Customer>{};
        this.customer = <Customer> {};
        await this.counterService.saveCounter(this.selectedCounter);
        this.showguestDialog = false;
        this.showspinner = false;
        
    }
    showspinner;

    async setGuestCount(){
        
        if(this.selectedCounter.servedby > 0 && this.servedby != this.selectedCounter.servedby && this.selectedCounter.cart.sumitemcount > 0){
            if(!this.userService.checkBillingPriv(Constants.PRIV_CHANGE_SERVED_BY)){
                this.showErrorToast('You dont have the privilege to change served by details, once a order is placed on the table!');
                return;    
            }
        }

        this.showspinner = true;
        if(this.customer.mobileno && this.customer.mobileno.trim().length > 0){
            if(this.validatePhoneNumber()){
                var customer = await this.customerService.findCustomer(this.customer.mobileno);
                if(customer){

                    this.selectedCounter.customer = customer; 
                }else{
                    await this.customerService.saveCustomer(this.customer);
                    this.selectedCounter.customer = this.customer;
                }

            }else{
                this.showspinner = false;
                return;
            }

        }

        

        
        this.selectedCounter.numguests = this.guestcount;
        if(this.selectedCounter.occupiedon == 0) this.selectedCounter.occupiedon = new Date().getTime();
        this.selectedCounter.servedby = this.servedby;



        var user = this.userService.getUserForId(this.selectedCounter.servedby);
        if(user)  this.selectedCounter.servedbystr = user.name;
        this.selectedCounter.guesttype = this.guesttype;
        this.counterService.setBillingSince();
        this.showguestDialog = false;
        
        if(await this.counterService.checkAndGetLatest()){

            await this.counterService.saveCounter(this.selectedCounter);
            
            this.showspinner = false; 
            this.openBillWindow(this.selectedCounter,true);
        }else{
            this.showspinner = false; 
            this.showErrorToast('You had an older copy of the counter data or counter is locked for update, please try again!')
        }
        
    }

    async refreshMasters(){
        var email = '';
        var pass = '';

        var loginResponse = localStorage.getItem('loginresponse');
        if(loginResponse && loginResponse.length > 0){
            try{
                var jsonresp = JSON.parse(loginResponse);
                email = jsonresp.retailer.registeredemailid;
                pass = jsonresp.retailer.userpass;
            }catch(err){}
        }
        
        
        var retstatus = await this.dataService.initializeAppData(email, pass);
            if(retstatus){
                this.showSuccessToast('Master data refreshed!');
                this.dataService.masterrefreshed= true; 
            }else{
                this.showErrorToast('Error refreshing master data!');
            }
        
        
        
    }
    
    showErrorToast(message){
        console.log('**ERROR ' + message);
        this.showspinner = false;
        this.messageService.clear();
        this.messageService.add({severity:'error',   key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success', life: 1000, key: 'billingtoast', closable: true, summary: 'Success', detail: message});
    }

    guestdialogwidth;
    ismobile; 

    setScreenDimensions(){
        if(window.innerWidth < 900){ 
            this.guestdialogwidth = 350;
            this.ismobile = true;
        }else{
            this.guestdialogwidth = 600;
            this.ismobile =false; 
        }
    }

    guestcountoptions : SelectItem[];

    ngOnInit(){
        this.guestcountoptions = [];
        for(var i=0;i<50;i++){
            this.guestcountoptions.push({label:"" + i, value:i});
        }


        this.customer = <Customer>{};
        if(this.dataService.retailer.appconfig.posconfig.guesttypes && this.dataService.retailer.appconfig.posconfig.guesttypes.length > 0 ){
            this.arrguesttype = this.dataService.retailer.appconfig.posconfig.guesttypes.split(',');
        }else{
            this.arrguesttype = [];
        }

        this.guesttypeoptions = [];
        for(var str of this.arrguesttype){
            this.guesttypeoptions.push({label:str, value:str});
        }

        if(this.guesttypeoptions.length > 0){
            this.guesttype = this.guesttypeoptions[0].value;
        }

        this.servedbyoptions = [];
        for (var user of this.userService.userlist){
            if(user.active) this.servedbyoptions.push({label:user.name, value:user.id});
        }

        if(this.servedbyoptions.length > 0){
            this.servedby = this.servedbyoptions[0].value;
        }

        if(!this.dataService.masterrefreshed){
            this.refreshMasters();
        }

        this.billingService.showcountername = false; 
         this.setScreenDimensions();

         


    }

    //Alias 
    alias;
    showaliasdialog;
    @ViewChild('aliasbox') txtAliasBox : ElementRef;

    showAliasDialog(op : OverlayPanel){
        this.alias = this.selectedCounter.alias;
        this.showaliasdialog = true; 
        op.hide();
    }
    
    onAliasShow(){
        setTimeout(()=>{ this.txtAliasBox.nativeElement.focus();}, 100);
    }
    setAlias(){
        
        this.selectedCounter.alias = this.alias;
        this.counterService.saveSelectedCounter();
        this.showaliasdialog= false;
    }

    customer : Customer; 
    

    //For cutomer data collection 
    validatePhoneNumber(){
        if(!this.customer.mobileno) return false;

        this.customer.mobileno = '' + this.customer.mobileno;
        this.customer.mobileno = this.customer.mobileno.trim();

        if(this.dataService.retailer.appconfig.countrycode == '+91'){
            if(this.customer.mobileno.length != 10 ){
                this.showErrorToast('Please provide valid number!');
                return false;
            }
        }else{
            if(this.customer.mobileno.length < 9 ||  this.customer.mobileno.charAt(0) != '0'){
                this.showErrorToast('Please provide valid number. It must be in format of (0x)xxxxxxx (telephones) and (05)xxxxxxxx (mobiles)');
                return false;
            }
            
            if(this.customer.mobileno.indexOf('05') == 0){
                if(this.customer.mobileno.length != 10  ){
                    this.showErrorToast('Please provide valid mobile number. It must be in 05xxxxxxxx format.');
                    return false;
                }
            }else{
                if(this.customer.mobileno.length != 9  ){
                    this.showErrorToast('Please provide valid telephone number. It must be in (0x)xxxxxxxx format.');
                    return false;
                }
            }
        }
        return true;

    }
   




        
}


