import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from './paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Printingservice } from '../service/printingservice';
import { Billingservice } from '../service/billingservice';
import { Userservice } from '../service/userservice';
import { Kot } from '../domain/kot';
import { Kitchenservice } from '../service/kitchenservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Qrorderservice } from '../service/qrorderservice';
import { Tableorder } from '../domain/tableorder';
import { Tablerequest } from '../domain/tablerequest';
import { Billingcounter } from '../domain/billingcounter';


@Component({
    templateUrl: 'qrprocessing.html',
    providers: [ConfirmationService]
})
export class Qrprocessing implements OnInit 
{
    selectedOrders : Tableorder[];
    selectedRequests : Tablerequest[];

    isRunning = false; 
    displayrejectDialog = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        public qrorderService  : Qrorderservice,
        private kitchenService : Kitchenservice,
        private userService  : Userservice,
        private billingService : Billingservice,
        private counterService : Billingcounterservice,
        private deliveryBoyservice : Deliveryboyservice,
        private printingService : Printingservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    arrguesttype : string[];
    guesttypeoptions : SelectItem[];
    servedbyoptions : SelectItem[];
    

    ngOnInit(){
        if(this.dataService.retailer.appconfig.posconfig.guesttypes && this.dataService.retailer.appconfig.posconfig.guesttypes.length > 0 ){
            this.arrguesttype = this.dataService.retailer.appconfig.posconfig.guesttypes.split(',');
        }else{
            this.arrguesttype = [];
        }

        this.guesttypeoptions = [];
        for(var str of this.arrguesttype){
            this.guesttypeoptions.push({label:str, value:str});
        }

        if(this.guesttypeoptions.length > 0){
            this.guesttype = this.guesttypeoptions[0].value;
        }

        this.servedbyoptions = [];
        for (var user of this.userService.userlist){
            if(user.active) this.servedbyoptions.push({label:user.name, value:user.id});
        }

        if(this.servedbyoptions.length > 0){
            this.servedby = this.servedbyoptions[0].value;
        }

        this.selectedOrders = [];
        this.setScreenDimensions();
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    async acceptOrders(){
        if(!this.checkBillingPrivs()) return; 

        if(!this.selectedOrders || this.selectedOrders.length == 0){
            this.showErrorToast('Please select at least one order to accept');
            return; 
        }

        var counterid = parseInt("" + this.selectedOrders[0].counterid);
        if(counterid == null || isNaN(counterid)){
            this.showErrorToast("Invalid table in the order!");
            return;
        } 
         
        for(var order of this.selectedOrders){
            if(order.counterid != counterid){
                this.showErrorToast("Please select orders of only one table at one time!");
                return;
            }
        }

        var ctrtocheck = "" + counterid; 

        if(this.dataService.retailer.id == 1338 || this.dataService.retailer.id == 1343){
            ctrtocheck = "" + this.qrorderService.getActCounterId(counterid);
        }
        var counter = this.counterService.getBillingcounterForId(parseInt(ctrtocheck));
        if(counter == null){
            this.showErrorToast("Invalid table in the order!");
            return;
        }

        if(counter.countertype != Constants.NORMAL && counter.countertype != Constants.AC_SPECIAL){
            this.showErrorToast("Invalid table -- This is not a dine in table!");
            return;
        }
        
        if(counter.cart.paymentlist  && counter.cart.paymentlist.length  > 0 ){
            this.showErrorToast("Settlement information already added, please remove the same before changing the items!");
            return;
        }

        if((counter.countertype == Constants.NORMAL || counter.countertype == Constants.AC_SPECIAL) && this.dataService.retailer.appconfig.posconfig.prebilldialog && (counter.numguests == 0 || counter.numguests == null )) {
            this.showGuestDialog(counter);
            return;
        } 
        
        this.isRunning = true; 
        this.counterService.selectedcounter = counter; 
        if(await this.counterService.checkAndGetLatest()){
            await this.processAccept(counter);
        }else{
            this.isRunning = false; 
            this.showErrorToast('You had an older copy of the counter data, now it is refreshed, please try again!')
        }
        
    }

    async processAccept(counter : Billingcounter){
        this.isRunning = true; 
        var retstring = await this.qrorderService.processorders(counter,this.selectedOrders);
        this.isRunning = false; 
        if(retstring != 'ok'){
            this.showErrorToast(retstring);
        }

    }

    rejectreason;

    async confirmReject(){
        if(!this.rejectreason || this.rejectreason.length == 0){
            this.showErrorToast('Please enter reject reason');
            return; 
        }

        this.isRunning = true; 
        await this.qrorderService.rejectOrders(this.selectedOrders,this.rejectreason);
        this.displayrejectDialog = false; 
        this.isRunning = false; 
    }


    rejectOrders(){
        if(!this.selectedOrders || this.selectedOrders.length == 0){
            this.showErrorToast('Please select at least one order to reject');
            return; 
        }
        this.rejectreason = "";
        this.displayrejectDialog = true; 
    }


    async attendRequests(){
        if(!this.selectedRequests || this.selectedRequests.length == 0){
            this.showErrorToast('Please select at least one request to mark as attended');
            return; 
        }
        this.isRunning = true; 
        await this.qrorderService.markRequestsAttended(this.selectedRequests);
        this.isRunning = false; 
    }

    

    checkBillingPrivs(){
        if(this.userService.loggedinusergroup && this.userService.loggedinusergroup.arrbillingprivs){
            if(this.userService.checkBillingPriv(Constants.PRIV_SALES)){
                return true; 
            }
        }

        this.showErrorToast('Billing privileges not assigned to this user!');
        return false; 
    }

    displayguestDialog;
    guestcount;
    guesttype;
    servedby;
    guestdialogwidth;
    selectedCounter : Billingcounter; 

    setScreenDimensions(){
        if(window.innerWidth < 900){ 
            this.guestdialogwidth = 350;
        }else{
            this.guestdialogwidth = 450;
        }
    }

    async setGuestCount(){
        
        if(this.selectedCounter.servedby > 0 && this.servedby != this.selectedCounter.servedby && this.selectedCounter.cart.sumitemcount > 0){
            if(!this.userService.checkBillingPriv(Constants.PRIV_CHANGE_SERVED_BY)){
                this.showErrorToast('You dont have the privilege to change served by details, once a order is placed on the table!');
                return;    
            }
        }
        
        this.selectedCounter.numguests = this.guestcount;
        if(this.selectedCounter.occupiedon == 0) this.selectedCounter.occupiedon = new Date().getTime();
        this.selectedCounter.servedby = this.servedby;

        var user = this.userService.getUserForId(this.selectedCounter.servedby);
        if(user)  this.selectedCounter.servedbystr = user.name;
        this.selectedCounter.guesttype = this.guesttype;
        this.counterService.setBillingSince();
        this.displayguestDialog = false;
        
        if(await this.counterService.checkAndGetLatest()){
            await this.counterService.saveCounter(this.selectedCounter);
            await this.processAccept(this.selectedCounter);
        }else{
            this.showErrorToast('You had an older copy of the counter data, now it is refreshed, please try again!')
        }
        
    }

    

    showGuestDialog(ctr: Billingcounter ){
        this.selectedCounter = ctr;
        this.counterService.selectedcounter = ctr; 
        this.setScreenDimensions();
        this.guestcount = 0;
        this.servedby = this.userService.loggedinuser.id;
        this.displayguestDialog = true; 
        
    }


    
}


