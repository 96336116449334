import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {DataService} from '../service/dataservice';
import {Billingservice} from '../service/billingservice';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';
import { Kitchenservice } from '../service/kitchenservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Appdataservice } from '../service/appdataservice';

@Component({
    selector: 'itemcancel-dlg',
    templateUrl: './itemcancellationdialog.html',

})
export class Itemcancellationdialog {
    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private dataService : Appdataservice,
                private kitchenService : Kitchenservice,
                public counterService : Billingcounterservice) {}

    closeDialog(){
        this.billingService.makeitemcancelDialogVisible = false;
    }

    showspinner; 
    arrreasons;
    foodprepared;
    cancellationreason;
    otherdetails; 
    enableother;
    quantity; 
    customercancelled;

  
    onShow(){
        this.cancellationreason = '';

        this.foodprepared = null; 
        this.customercancelled = null;
        this.otherdetails = '';
        this.enableother = false; 
        this.quantity = this.billingService.selectLineitem.quantity;

    }


    setReason(reason){
        this.cancellationreason = reason;
        if(reason && reason.toUpperCase().indexOf('OTHER') >= 0)
            this.enableother = true; 
        else
            this.enableother = false;
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false; 
    }

    async cancelItem(){

        this.showspinner = true; 

        if (!this.cancellationreason || this.cancellationreason.length == 0){
            this.showErrorToast("Please select cancellation reason");
            return;
        }

        if(!this.quantity || isNaN(this.quantity) || isNaN(parseInt(this.quantity))){
            this.showErrorToast("Invalid cancellation quantity!");
            return;
        }


        if(parseInt(this.quantity) <= 0 || parseInt(this.quantity) > this.billingService.selectLineitem.quantity){
            this.showErrorToast("Invalid cancellation quantity!");
            return;
        }



        if(this.enableother && (!this.otherdetails || this.otherdetails.length == 0)){
            this.showErrorToast("Please enter cancellation reason");
            return;
        }

        
        if(this.foodprepared == null){
            this.showErrorToast("Please choose whether food was prepared or not");
            return;
        }
      
        if(this.customercancelled == null){
            this.showErrorToast("Please choose whether item was cancelled by customer or by outlet");
            return;
        }
        
        var reason = this.cancellationreason; 
        var remarks = this.otherdetails;
        //if(this.enableother ) reason = 'Other - ' + this.otherdetails;
        
        var cancelby = 'Outlet';
        if(this.customercancelled) 
            cancelby = 'Customer';


        var retstatus = this.kitchenService.cancelItem(this.billingService.selectLineitem,reason,remarks,this.counterService.selectedcounter,this.foodprepared, parseInt(this.quantity),cancelby);

        if(!retstatus){
            this.showErrorToast('Error in cancellation of item');
            return; 
        }

        var idx = this.counterService.selectedcounter.cart.lineitems.indexOf(this.billingService.selectLineitem);
        if(idx >= 0 ){
                //var cartitem = this.counterService.selectedcounter.cart.lineitems[idx];
                if(this.billingService.selectLineitem.quantity == this.quantity)
                    this.counterService.selectedcounter.cart.lineitems.splice(idx,1);
                else{
                    //cartitem.quantity = cartitem.quantity - parseInt(this.quantity);
                    var newquantity =  this.billingService.selectLineitem.quantity - parseInt(this.quantity);
                    this.counterService.setQuantity(this.billingService.selectLineitem,newquantity);

                }
        }

        this.counterService.selectedcounter.cart.recomputeCart();
        this.counterService.selectedcounter.cart.lineitems = [... this.counterService.selectedcounter.cart.lineitems];

                
        //Set the unsent status 
        this.counterService.selectedcounter.unsentitems = this.kitchenService.checkUnsent(this.counterService.selectedcounter.cart);
        
        //Save the counter 
        await this.counterService.saveSelectedCounter();
        
        this.showspinner = false; 
        this.billingService.makeitemcancelDialogVisible = false; 
        
    }

    ngOnInit(){
        this.arrreasons = [];
        var strreasons = this.dataService.retailer.appconfig.posconfig.cancellationreasons;
        if(strreasons != null && strreasons.length > 0){
            this.arrreasons = strreasons.split(',');
        }
        
    }


    
}
