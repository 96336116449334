import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { months } from 'moment';
import { finalize } from 'rxjs/operators';
import { templateJitUrl } from '@angular/compiler';



@Component({
    templateUrl: 'menuanalysis.html',
    providers: [ConfirmationService]
})
export class Menuanalysis implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist1       :   Order[];
    orderlist2       :   Order[];
    

    filteredlist : Summaryrecord[];
    paymentmodes : SelectItem[];
    categorylist : SelectItem[];


    firstmonthlist : SelectItem[];
    secondmonthlist : SelectItem[];

    
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;
    currentcategoryid; 

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private counterService : Billingcounterservice,
        private orderService  : Orderservice,

        private userService : Userservice, 
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    strmonth1;
    strmonth2; 
    
    strmonthoptions; 
    arrmonthlist;
        
    ngOnInit(){
        this.currentviewtype = Constants.VW_PRODUCT;
        this.currentcategoryid = 0;

        this.orderlist1 = [];
        this.orderlist2 = [];
        
        
        var curdate = new Date();
        curdate.setDate(1);

        var prevmonthdate = new Date(curdate.getTime());
        prevmonthdate.setMonth(prevmonthdate.getMonth() - 1);

        this.strmonth1 = this.datePipe.transform(curdate,"MMM yyyy");
        this.strmonth2 = this.datePipe.transform(prevmonthdate,"MMM yyyy");

        this.arrmonthlist = Utils.getMonthJson();
        this.strmonthoptions = [];
        for(var mon of this.arrmonthlist){
            this.strmonthoptions.push({label:  mon.monthname, value: mon.monthname});
            if(mon.monthname == this.strmonth1) break;
        }

        
        
        this.cols = [
            { field: 'summaryval1', header: 'Subcategory' },
            { field: 'description', header: 'Product' },
            { field: 'id', header: 'PLU Code' },
            
            { field: 'summaryval2', header: this.strmonth1 +  ' Item count' },
            { field: 'summaryval3', header: this.strmonth1 + 'Item amount' },
            { field: 'summaryval4', header: '% Contribution in Subcategory' },
            { field: 'summaryval5', header: '% Overall Contribution' },
            { field: 'summaryval6', header: this.strmonth2 +  ' Item count' },
            { field: 'summaryval7', header: this.strmonth2 + 'Item amount' },
            { field: 'summaryval8', header: '% Contribution in Subcategory' },
            { field: 'summaryval9', header: '% Overall Contribution' }
        ];

        
        

    }

    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount1 = 0;
    totalitemamount1 = 0;
    totalitemcount2 = 0;
    totalitemamount2 = 0;
    
    
    setTotalsToZero(){
        this.totalitemcount1 = 0;
        this.totalitemamount1 = 0;
        this.totalitemcount2 = 0;
        this.totalitemamount2 = 0;
    }


    incrementTotals(sumrec : Summaryrecord, monthno){
        if(monthno == 1){
            this.totalitemcount1  =  this.totalitemcount1 + sumrec.summaryval6;
            this.totalitemamount1 =  this.totalitemamount1 + sumrec.summaryval7;
        }else{
            this.totalitemcount2  = this.totalitemcount2 + sumrec.summaryval6;
            this.totalitemamount2 =  this.totalitemamount2 + sumrec.summaryval7;

        }
            
    }

    roundTotals(){
        
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);

        for(var item of ord.cart.lineitems){
            var prod = this.dataService.getProductForId(item.productid);
            
            if(prod){
                //item.pcode = this.getInventoryPLUCode(item,prod);
                item.pcode = prod.code;
                var cat  = this.dataService.getCategoryForId(prod.categoryid);
                item.occode = 1;
                if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                    item.occode= 2;
                }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                    item.occode= 1;
                }else  if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                    item.occode= 4;
                }else{
                    item.occode= 3;
                }

                item.hasvarient = false; 
                if(prod.customization && prod.customization.optionlist){
                    for(var opt of prod.customization.optionlist){
                        var optionpresent = false; 
                        var strheader = opt.optionheader;
                        
                        if(strheader.toLowerCase().indexOf('add') >= 0){
                            optionpresent = true;
                        }
                        
                        if(opt.selectone){
                            if(!opt.optional && optionpresent == false){
                                item.hasvarient = true; 
                            }
                        }else if(opt.min && opt.min > 0){
                            item.hasvarient = true;
                        }   
                    }
                }


            }
        }

        
    }

    createInitialList(){
        this.filteredlist = [];
        /*for(var prod of this.dataService.productsforsale){
            var rec = <Summaryrecord>{};
            
            rec.id =  prod.id; 
            rec.description = prod.name;
            var subcat = this.dataService.getSubcategoryForId(prod.subcategoryid);
            if(subcat){
                rec.summaryval1 = subcat.name;
            }
            rec.summaryval2 = 0;
            rec.summaryval3 = 0;
            rec.summaryval4 = 0;
            rec.summaryval5 = 0;
            rec.summaryval6 = 0;
            rec.summaryval7 = 0;
            rec.summaryval8 = 0;
            rec.summaryval9 = 0;
            
            this.filteredlist.push(rec);
    
        }*/
    }

    
    addToExistingRecord(rec :Summaryrecord, sumrec : Summaryrecord, monthno){
        if(monthno == 1){
            rec.summaryval2 =  Utils.round(rec.summaryval2 + sumrec.summaryval6,3);
            rec.summaryval3 =  Utils.round(rec.summaryval3 + sumrec.summaryval7,3);
        }else{
            rec.summaryval6 =  Utils.round(rec.summaryval6 + sumrec.summaryval6,3);
            rec.summaryval7 =  Utils.round(rec.summaryval7 + sumrec.summaryval7,3);
        }
    }


    createNewRecord(sumrec : Summaryrecord, monthno){
        var rec = <Summaryrecord>{};
            
        rec.id =  sumrec.summaryval4; 
        rec.description = sumrec.summaryval5;
        var subcat = this.dataService.getSubcategoryForId(sumrec.summaryval11);
        if(subcat){
            rec.summaryval1 = subcat.name;
        }
        rec.summaryval2 = 0;
        rec.summaryval3 = 0;
        rec.summaryval4 = 0;
        rec.summaryval5 = 0;
        rec.summaryval6 = 0;
        rec.summaryval7 = 0;
        rec.summaryval8 = 0;
        rec.summaryval9 = 0;
       // rec.summaryval11 = sumrec.summaryval11;

        if(monthno == 1){
            rec.summaryval2 =  Utils.round(rec.summaryval2 + sumrec.summaryval6,3);
            rec.summaryval3 =  Utils.round(rec.summaryval3 + sumrec.summaryval7,3);

        }else{
            rec.summaryval6 =  Utils.round(rec.summaryval6 + sumrec.summaryval6,3);
            rec.summaryval7 =  Utils.round(rec.summaryval7 + sumrec.summaryval7,3);
        }
        this.filteredlist.push(rec);
    }

    getSubcategoryTotal(subcatname, monthno){
        var totalamt = 0;
        for(var rec of this.filteredlist){
            if(rec.summaryval1 == subcatname){
                if(monthno == 1){
                    totalamt = totalamt  + rec.summaryval3;
                }else{
                    totalamt = totalamt  + rec.summaryval7;
                }
            }
        }

        return totalamt;
    }

    calculatePercentages(monthno){
        for(var rec of this.filteredlist){
            if(monthno == 1){
                rec.summaryval5 = Utils.round(rec.summaryval3/this.totalitemamount1 * 100,2);
                var subcattotal = this.getSubcategoryTotal(rec.summaryval1,1);
                rec.summaryval4 = Utils.round(rec.summaryval3/subcattotal * 100,2);
                
            }else{
                rec.summaryval9 = Utils.round(rec.summaryval3/this.totalitemamount2 * 100,2);
                var subcattotal = this.getSubcategoryTotal(rec.summaryval1,2);
                rec.summaryval8 = Utils.round(rec.summaryval7/subcattotal * 100,2);
            }
        }

    }

    plujson;
    
     setFilterList(){
        
        this.filteredlist = [];
    
        for (var item of this.orderlist1){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){

                var ord = this.orderService.getClone(item);
                this.setDetails(ord);
                
                var arritemrec = <Summaryrecord[]> Utils.getForMenuanalysisReport(ord,this.dataService.getPosId(),this.plujson, this.dataService);
                for(var rec of arritemrec){
                    
                    console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|MENU' + rec.summaryval4);

                    var currec = Utils.findInSummaryList(this.filteredlist, rec.summaryval4);
                    if(currec){
                        console.log('MENUANALYSIS|PLU|ITEM FOUND' + rec.summaryval2);
                        this.addToExistingRecord(currec,rec,1);
                    }else{
                        this.createNewRecord(rec,1);
                    }

                    this.incrementTotals(rec,1);
                }


            }
                
                
        }


        for (var item of this.orderlist2){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){

                var ord = this.orderService.getClone(item);
                this.setDetails(ord);
                    
                var arritemrec = <Summaryrecord[]> Utils.getForMenuanalysisReport(ord,this.dataService.getPosId(),this.plujson,this.dataService);
                for(var rec of arritemrec){
                    var currec = Utils.findInSummaryList(this.filteredlist, rec.summaryval4);
                    if(currec){
                        this.addToExistingRecord(currec,rec,2);
                    }else{
                        this.createNewRecord(rec,2);
                    }

                    this.incrementTotals(rec,2);
                }

            }
        }

        this.calculatePercentages(1);
        this.calculatePercentages(2);

        this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1 + '|' +  a.summaryval4)  > (b.summaryval1 + '|' +  b.summaryval4)  ? -1 : 1 );
        
        
        //this.filteredlist = [... this.filteredlist];
        this.insertCategorySummaryInList();
    }

    getMonthRecord(strmonth){
        for(var mon of this.arrmonthlist){
            if(mon.monthname == strmonth) return mon;
        }

    }


    insertCategorySummaryInList(){
        var finallist : Summaryrecord[];
        finallist = [];
        
        var cursubcat = '';

        var count1 =0;
        var amount1 =0;
        var totalcontrib1 =0;
        
        var count2 =0;
        var amount2 =0;
        var totalcontrib2 =0;
        
        
        
        for(var rec of this.filteredlist){
            if(cursubcat == '' || rec.summaryval1 == cursubcat){
                count1 =  count1 +  rec.summaryval2;
                amount1 = amount1 + rec.summaryval3;
                totalcontrib1 = totalcontrib1  + rec.summaryval5;
                count2 = count2 + rec.summaryval6;
                amount2 = amount2 + rec.summaryval7;
                totalcontrib2 = totalcontrib2 + rec.summaryval9;
                finallist.push(rec);
                cursubcat  = rec.summaryval1;
            }else{
                var subcatrec = <Summaryrecord>{};
                subcatrec.id = 'Totals';
                subcatrec.summaryval2 = count1;
                subcatrec.summaryval3 = amount1;
                subcatrec.summaryval4 = 100;
                subcatrec.summaryval5 = totalcontrib1;

                subcatrec.summaryval6 = count2;
                subcatrec.summaryval7 = amount2;
                subcatrec.summaryval8 = 100;
                subcatrec.summaryval9 = totalcontrib2;
                subcatrec.summaryval14 = 'boldRowClassSummray';
        
                finallist.push(subcatrec);
                
                count1 = rec.summaryval2;
                amount1 =rec.summaryval3;
                totalcontrib1 =rec.summaryval5;
                count2 =rec.summaryval6;
                amount2 =rec.summaryval7;
                totalcontrib2 =rec.summaryval9;
                cursubcat  = rec.summaryval1;
                finallist.push(rec);
            }
        }

        //Add total rec in the end 
        var subcatrec = <Summaryrecord>{};
        subcatrec.id = 'Totals';
        subcatrec.summaryval2 = count1;
        subcatrec.summaryval3 = amount1;
        subcatrec.summaryval4 = 100;
        subcatrec.summaryval5 = totalcontrib1;

        subcatrec.summaryval6 = count2;
        subcatrec.summaryval7 = amount2;
        subcatrec.summaryval8 = 100;
        subcatrec.summaryval9 = totalcontrib2;
        subcatrec.summaryval14 = 'boldRowClassSummray';

        finallist.push(subcatrec);

        this.filteredlist = finallist;
        this.filteredlist = [... this.filteredlist];
        
    }
    
    async setData(){
        
            var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
            var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

            if(this.strmonth1 == this.strmonth2){
                this.showErrorToast("Both months are same!");
                return;
            }

            this.isRunning = true; 
            this.createInitialList();

            this.setTotalsToZero();
            
            var mon1 = this.getMonthRecord(this.strmonth1);
            var mon2 = this.getMonthRecord(this.strmonth2);
            
            var fromdate1= mon1.startdate;
            var fromdate2= mon2.startdate;

            var todate1= mon1.enddate;
            var todate2= mon2.enddate;
            //Get plu json 
            this.plujson = await this.dataService.getPLUCodeNames();

            this.orderlist1 = await this.orderService.getOrderlist(fromdate1,todate1) ;
            this.orderlist1.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist1 == null){
                this.showErrorToast("Error fetching orders, please try again!")
                this.isRunning = false;
                return; 
            }

            this.orderlist2 = await this.orderService.getOrderlist(fromdate2,todate2) ;
            this.orderlist2.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist2 == null){
                this.showErrorToast("Error fetching orders, please try again!")
                this.isRunning = false;
                return; 
            }


            this.setFilterList();
            this.filteredlist = [... this.filteredlist];
            this.isRunning = false; 
    }


    
    
}


