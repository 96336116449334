import {Injectable} from '@angular/core';
import {Http, Response} from '@angular/http';
import {Product} from '../domain_old/product';
import {Productcategory} from '../domain_old/productcategory';
import {Productsubcategory} from '../domain_old/productsubcategory';
import {Scheme} from '../domain_old/scheme';
import {Manufacturer} from '../domain_old/manufacturer';
import { map } from "rxjs/operators";
import {Area} from '../domain_old/area';
import {RetailerArea} from '../domain_old/retailerarea';
import {Retailer} from '../domain_old/retailer';
import {Retailerlocation} from '../domain_old/retailerlocation';
import {Stockcreditnote} from '../domain_old/stockcreditnote';
import {Stockdebitnote} from '../domain_old/stockdebitnote';
import {Stocktransfernote} from '../domain_old/stocktransfernote';
import {Stockrequisitionnote} from '../domain_old/stockrequisitionnote';
import {Stockrequisitionnotedetails} from '../domain_old/stockrequisitionnotedetails';
import {Retailerscheme} from '../domain_old/retailerscheme';
import {Displayimage} from '../domain_old/displayimage';
import {Digitaldisplay} from '../domain_old/digitaldisplay';



import {State} from '../domain_old/state';
import {City} from '../domain_old/city';
import {User} from '../domain_old/user';

import {Order} from '../domain_old/order';
import {OrderLineitem} from '../domain_old/orderlineitem';
//import {PushNotificationsService} from 'angular2-notifications';
import {CustomizationOption} from '../domain_old/customizationoption';
import {Constants} from '../domain_old/constants';
import {Onlineproduct} from '../domain_old/onlineproduct';

import {Retailerproduct} from '../domain_old/retailerproduct';
import {Headers, RequestOptions} from '@angular/http';
import {Observable} from 'rxjs/Rx';
import {DatePipe} from '@angular/common';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/interval';
import { Subcategory } from '../domain_old/subcategory';
import { Recipe } from '../domain_old/recipe';
import { Customization } from '../domain_old/customization';
import { Utilfunctions } from '../domain_old/utilfunctions';
import { Inventorytransaction } from '../domain_old/inventorytransaction';
import { Billingcounter } from '../posdomain/billingcounter';
import { Cart } from '../posdomain/cart';
import { Cartservice } from '../posservice/cartservice';
import { isNumeric } from 'rxjs/internal-compatibility';
import { Poslocation } from '../domain_old/poslocation';


@Injectable()
export class DataService  {
    
    selectedcounter : Billingcounter;
    isloggedin = false;

    constructor (private http: Http, private datePipe : DatePipe) {
        this.isloggedin = false;
        this.selectedcounter = <Billingcounter> {};
        this.selectedcounter.cart = <Cart> {};
        
        //this._push.requestPermission();
    }
    
    
    subcatlist : Productsubcategory[];
    catlist : Productcategory[];
    imagelist : Displayimage[];
    displaylist : Digitaldisplay[];


    selecteddisplay : Digitaldisplay;

    
    masterdata; 
    retailerproductlist : Retailerproduct[];
    retailerschemelist : Retailerscheme[];
    retailer : Retailer;
    user : User;

    statelist : State[];
    citylist : City[];
    retailerlist : Retailer[];

    retailerlocationlist : Retailerlocation[];
    retailergrouplist : User[];
    
    usertype : String;
    
    autorefreshStopped : boolean = false;

    isfirsttime : boolean = true;    
    hidetopbar : boolean = false;

    pendingorderlist : Order[];
    receivedorderlist : Order[] = [];
    confirmedorderlist : Order[] = [];
    outfordeliveryorderlist : Order[] = [];
    
    dataObtained : boolean = false;
    constants : Constants = new Constants();
    pendingordersfetched : boolean = false;
    lastrefreshedon : Date = new Date();
    lastrefreshtime;     
    

    private urlretailer = 'https://f13gpuqxt4.execute-api.ap-south-1.amazonaws.com/prod/retailermanage';  // URL to Backoffice API --> NEW MUMBAI
    
    private urlsubcategory = 'https://f13gpuqxt4.execute-api.ap-south-1.amazonaws.com/prod/fetchnewsubcats';  // URL to Backoffice API
    private urlretailerscheme = 'https://f13gpuqxt4.execute-api.ap-south-1.amazonaws.com/prod/fetchnewretailerschemes'; //URL to etailer scheme API 
    private urlRetailerproduct = 'https://f13gpuqxt4.execute-api.ap-south-1.amazonaws.com/prod/fetchnewretailerproducts'; //URL to retailer product API 
    
    //Subcategory manage 
    private urlsubcategorymanage = 'https://f13gpuqxt4.execute-api.ap-south-1.amazonaws.com/prod/retailersubcatmanage';  // URL to Backoffice API
    private urlretprodmanage = 'https://f13gpuqxt4.execute-api.ap-south-1.amazonaws.com/prod/retailerproductmanage';  // URL to Backoffice API
    
    private urlfetchpendingorders = 'https://zb72fi71k2.execute-api.us-east-1.amazonaws.com/prod/fetchpendingorders'; //URL to fetch pending orders
    private urlupdatependingorders  = 'https://zb72fi71k2.execute-api.us-east-1.amazonaws.com/prod/updatependingorders'; //URL for updating pending order
    
    private urlinvite = 'https://zb72fi71k2.execute-api.us-east-1.amazonaws.com/prod/sendappinvite';  // URL to Backoffice API
    private urlreport = 'https://zb72fi71k2.execute-api.us-east-1.amazonaws.com/prod/getsalesdetails';  //URL for sales details
    private urlsummary = 'https://zb72fi71k2.execute-api.us-east-1.amazonaws.com/prod/getsalessummary';  //URL for sales summary
    
    
    
    /***** URLS iPOS */
    private loginurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/retailerlogin';  // URL to web API
    private adminloginurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/adminlogin';  // URL to web API
    private grouploginurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/grouplogin';  // URL to web API
    
    private caturl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveretailercategory';  // URL to web API
    private displayurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/savedisplay';  // URL to web API
    private displayimageurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/savedisplayimage';  // URL to web API
    
    private subcaturl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveretailersubcategory';  // URL to web API
    private produrl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveretailerproduct';  // URL to web API
    private bulkprodurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveproductbulk';  // URL to web API
    
    private retailerurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveretailer'; // Retiler save

    private sendalerturl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/sendalert'; // Retiler save
    private sendcustomeralerturl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/sendcustomeralert'; // Customer save

    private retailerlocationurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveretailerlocation'; // Retiler save
    private urlsavestate = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/savestate'; // Retiler save
    private urlsavecity = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/savecity'; // Retiler save
    private urlsalesordersearch = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getsalesorders';   // Get sales orders
    private urlonlineordersearch = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getonlineorders';   // Get sales orders
    
    private urlproductwisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getproductwisesales';   // Get sales orders
    private urlcategorywisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getcategorywisesales';   // Get sales orders
    private urlsubcategorywisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getsubcategorywisesales';   // Get sales orders
    
    
    
    private urlretailerwisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getretailerwisesales';   // Get sales orders
    private urllocationwisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getlocationwisesales';   // Get sales orders
    private urlaggregatorwisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getaggregatorwiseorders';   // Get sales orders
    
    private urlgroupproductwisesales = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getgroupproductwisesales';   // Get sales orders
    private urlscheme = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/saveretailerscheme'; // Retiler save
    

    /*** URL FOR URBAN PIPER */
    private urlurbanpiperinventory = 'https://api.urbanpiper.com/external/api/v1/inventory/locations/';  //URL for sales summary
    private urlurbanpiperinventorystaging = 'https://staging.urbanpiper.com/external/api/v1/inventory/locations/';  //URL for sales summary


    /*** URL FOR CLOUD INVENTORY */
    private urlsearchinventory = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/searchinventory';  //URL for sales summary
    private urlsearchcreditnotes = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getcreditnotes';  //URL for sales summary
    private urlsearchdebitnotes = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getdebitnotes';  //URL for sales summary
    private urlsearchtransfernotes = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/gettransfernotes'; //URL for sales summary
    private urlsearchrequisitionnotes = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getrequisitionnotes'; //URL for sales summary
    
    private urlprocesscreditnote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/processcreditnote';  //URL for sales summary
    private urlprocessdebitnote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/processdebitnote';  //URL for sales summary
    private urlinventorytrace = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getinventorytrace';  //URL for sales summary
    private urlinventorytracesummary = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getinventorysummarytrace';  //URL for sales summary
    
    private urlcreatetransfernote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/createtransfernote';  //URL for sales summary
    private urlinwardtransfernote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getinwardtransfernotes'; //URL for sales summary
    private urlupdatetransfernote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/updatetransfernote'; //URL for sales summary
    
    private urlcreaterequisitionnote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/createrequisitionnote';  //URL for sales summary
    private urlinwardrequisitionnote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/getinwardrequisitionnotes'; //URL for sales summary
    private urlupdaterequisitionnote = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/updaterequisitionnote'; //URL for sales summary
    


    subcategoriesforsale : Productsubcategory[];
    categoriesforsale : Productcategory[];
    productsforsale : Retailerproduct[];
    productsforpurchase : Retailerproduct[];
    

    
    


    getStateNameById(stateid : Number){
        for(var state of this.statelist){
            if(state.id === stateid)
                return state.name;
        }
        return "";
    }

    getCityNameById(cityid : Number){
        for(var city of this.citylist){
            if(city.id === cityid)
                return city.name;
        }
        return "";
    }


    getGroupNameById(groupid : Number){
        for(var user of this.retailergrouplist){
            if(user.id === groupid)
                return user.username;
        }
        return "";
    }

    doIHideTopBar(){
        if(!this.isloggedin) return true; 
        return this.hidetopbar;
    }


    saveCity(fcity  : City){
        let body = JSON.stringify(fcity);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('Sending Request-->' + body);
        return this.http.post(this.urlsavecity, body, options).map((res:Response) => res.json());
      }




    getSalesDetails(fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlsalesordersearch, body, options).map((res:Response) => res.json());
        

    }

    getSalesDetailsLocation(fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate,locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlsalesordersearch, body, options).map((res:Response) => res.json());
  
    }


    getOnlineorderdetails(fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate,locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlonlineordersearch);
        return this.http.post(this.urlonlineordersearch, body, options).map((res:Response) => res.json());
  
    }


    getProductWiseSales(fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);

        
        return this.http.post(this.urlproductwisesales, body, options).map((res:Response) => res.json());
        

    }

    getCategoryWiseSales(fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlcategorywisesales, body, options).map((res:Response) => res.json());
        

    }

    getDisplayNameById(displayid : Number){
        for(var dis of this.displaylist){
            if(dis.id === displayid)
                return dis.name;
        }
        return "";
    }

    getDisplayImageById(imageid : Number){
        for(var dis of this.imagelist){
            if(dis.id === imageid)
                return dis;
        }
        return null;
    }
    
    getSubcategoryWiseSales(fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlsubcategorywisesales, body, options).map((res:Response) => res.json());
        

    }



    getProductWiseSalesLocation(fromdate : String, todate : String, locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate, locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlproductwisesales, body, options).map((res:Response) => res.json());
        

    }


    syncProductsWithUrbanPiper(productlist : Onlineproduct[])
    {
        let body = JSON.stringify({items : productlist});
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        //headers.append('Authorization', 'ApiKey biz_adm_clients_dveDLrIKrzAH:b187974baa69d1c2e2057dfca94553f3a8f0d9ec');
        var authdetail = 'ApiKey ' + this.retailer.urbanpipercredentials;
        headers.append('Authorization', authdetail);
        
        let options = new RequestOptions({ headers: headers });

        var url = this.urlurbanpiperinventory + this.getPosId() +'/';

        if(this.retailer.id === 556)
            url = this.urlurbanpiperinventorystaging + this.getPosId() + '/';

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + url + '/');
        return this.http.post(url, body, options).map((res:Response) => res.json());
    }

    getLocationWiseSales(fromdate : String, todate : String){
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urllocationwisesales);
        return this.http.post(this.urllocationwisesales, body, options).map((res:Response) => res.json());
    }

    getAggregatorWiseSales(fromdate : String, todate : String){
        let body = JSON.stringify({retailerid : this.retailer.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urllocationwisesales);
        return this.http.post(this.urlaggregatorwisesales, body, options).map((res:Response) => res.json());
    }
  
  
    getRetailerWiseSales(fromdate : String, todate : String){
        let body = JSON.stringify({retailerid : this.user.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlretailerwisesales);
        return this.http.post(this.urlretailerwisesales, body, options).map((res:Response) => res.json());
        
    }


    getCreditnotes(fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id, fromdate: fromdate,todate : todate, locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsearchcreditnotes);
        return this.http.post(this.urlsearchcreditnotes, body, options).map((res:Response) => res.json());
    }

    getDebitnotes(fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id, fromdate: fromdate,todate : todate, locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsearchdebitnotes);
        return this.http.post(this.urlsearchdebitnotes, body, options).map((res:Response) => res.json());
    }
    
    getTransfernotes(fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id, fromdate: fromdate,todate : todate, locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsearchtransfernotes);
        return this.http.post(this.urlsearchtransfernotes, body, options).map((res:Response) => res.json());
    }

    getRequisitionnotes(fromdate : String, todate : String,locationid : number){
        let body = JSON.stringify({retailerid : this.retailer.id, fromdate: fromdate,todate : todate, locationid : locationid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsearchrequisitionnotes);
        return this.http.post(this.urlsearchrequisitionnotes, body, options).map((res:Response) => res.json());
    }

    
    getInwardTransfernotes(fromdate : String, todate : String, pending : boolean){
        let body = "";
        if(pending)
             body = JSON.stringify({retailerid : this.retailer.id, locationid : this.retailer.locationid, showpending : pending});
        else
            body = JSON.stringify({retailerid : this.retailer.id, fromdate: fromdate,todate : todate, locationid : this.retailer.locationid});

        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlinwardtransfernote);
        return this.http.post(this.urlinwardtransfernote, body, options).map((res:Response) => res.json());
    }
    
    getInwardRequisitionnotes(fromdate : String, todate : String,pending : boolean){
        
        let body = "";
        if(pending)
             body = JSON.stringify({retailerid : this.retailer.id, locationid : this.retailer.locationid, showpending : pending});
        else
            body = JSON.stringify({retailerid : this.retailer.id, fromdate: fromdate,todate : todate, locationid : this.retailer.locationid});

        
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlinwardrequisitionnote);
        return this.http.post(this.urlinwardrequisitionnote, body, options).map((res:Response) => res.json());
    }
    
    public getCurrentBusinessDate(){
        var curHour = new Date().getHours();
        
        if( this.retailer && curHour < this.retailer.endofbusinessday){
           var d = new Date();
           d.setDate(d.getDate() - 1);
           
           // This will return previous day
           return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
        }else{
           return parseInt(this.datePipe.transform(new Date(), "yyyyMMdd")); 
        }
    }

    
    
    public getPreviousBusinessDate(){
        var d = Utilfunctions.getDateFromBusinessdate(""+ this.getCurrentBusinessDate());
        d.setDate(d.getDate() - 1);

        // This will return previous day
        return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
    }


    public getNextBusinessDate(){
        var d = Utilfunctions.getDateFromBusinessdate(""+ this.getCurrentBusinessDate());
        d.setDate(d.getDate() + 1);

        // This will return previous day
        return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
    }

    getPosId(){
        return this.retailer.id  + "|" + this.retailer.locationid;
    }

    getProductsForSubcategory(subcatid) : Retailerproduct[]
    {
        var productlist: Retailerproduct[] = [];

        for(var prod of this.productsforsale){
            if(prod.subcategoryid == subcatid){
                productlist.push(prod);
            }
        }
        

        return productlist;

    }


    processCreditnote(fcredinote : Stockcreditnote){
        

        let body = JSON.stringify(fcredinote);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlprocesscreditnote);
        return this.http.post(this.urlprocesscreditnote, body, options).map((res:Response) => res.json());
    }

    processDebitnote(fdebitnote : Stockdebitnote){
        
        let body = JSON.stringify(fdebitnote);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlprocessdebitnote);
        return this.http.post(this.urlprocessdebitnote, body, options).map((res:Response) => res.json());
    }

    createTransfernote(ftransfernote : Stocktransfernote){
        
        let body = JSON.stringify(ftransfernote);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlcreatetransfernote);
        return this.http.post(this.urlcreatetransfernote, body, options).map((res:Response) => res.json());
    }

    updateTransfernote(ftransfernote : Stocktransfernote){
        
        let body = JSON.stringify(ftransfernote);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlupdatetransfernote);
        return this.http.post(this.urlupdatetransfernote, body, options).map((res:Response) => res.json());
    }


    createRequisitionnote(frequisitionnote : Stockrequisitionnote){
        let body = JSON.stringify(frequisitionnote);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlcreaterequisitionnote);
        return this.http.post(this.urlcreaterequisitionnote, body, options).map((res:Response) => res.json());
    }


    updateRequisitionnote(frequisitionnote : Stockrequisitionnote){
        
        let body = JSON.stringify(frequisitionnote);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlupdaterequisitionnote);
        return this.http.post(this.urlupdaterequisitionnote, body, options).map((res:Response) => res.json());
    }

    searchProductsForBilling(query){
            var resultsproduct : string[];
            resultsproduct = [];
            query = query.trim();

            if(query.length == 0) return resultsproduct;

            var isNumeric = false; 
            if(!isNaN(parseInt(query))) isNumeric = true;

            for(let i = 0; i < this.productsforsale.length; i++) {
                let lretprod = this.productsforsale[i];
                
                if(isNumeric){
                    //First compare the code 
                    if(lretprod.code === query)  {
                        resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                        return resultsproduct;
                    }
                }else{
                    if(query.length === 1){
                        if(lretprod.name.toLowerCase().charAt(0) === query.toLowerCase().charAt(0))  {
                            resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                        }
                    }else if(query.length === 2){
                        if(lretprod.name.length > 1){
                            if(lretprod.name.toLowerCase().substring(0,2) === query.toLowerCase() ||  lretprod.generatedcode.toLowerCase().indexOf(query.toLowerCase()) === 0 )  {
                                resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                            }
                        }
                    }else{
                        if(lretprod.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||  lretprod.generatedcode.toLowerCase().indexOf(query.toLowerCase()) === 0 )  {
                            resultsproduct.push(lretprod.name + ' [' + lretprod.code + ']');
                        }
                    }

                    
                    
                }

                
            
            }

            return resultsproduct;

    }


    getGroupProductwiseSales(fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.user.id,fromdate: fromdate,todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlgroupproductwisesales, body, options).map((res:Response) => res.json());
        
    }


    searchInventory(locationid : number, productid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id,locationid: locationid,productid : productid});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlsalesordersearch);
        return this.http.post(this.urlsearchinventory, body, options).map((res:Response) => res.json());
        
    }

    getInventorytrace( productid : number,fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.retailer.id,locationid: this.retailer.locationid,productid : productid, fromdate : fromdate, todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlinventorytrace);
        return this.http.post(this.urlinventorytrace, body, options).map((res:Response) => res.json());
        
    }

    getInventorytraceSummary(fromdate : String, todate : String){
        
        let body = JSON.stringify({retailerid : this.retailer.id,locationid: this.retailer.locationid,fromdate : fromdate, todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlinventorytracesummary);
        return this.http.post(this.urlinventorytracesummary, body, options).map((res:Response) => res.json());
        
    }

    getInventorytraceForlocation( productid : number,fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id,locationid: locationid,productid : productid, fromdate : fromdate, todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlinventorytrace);
        return this.http.post(this.urlinventorytrace, body, options).map((res:Response) => res.json());
        
    }

    getInventorySummarytraceForlocation(fromdate : String, todate : String,locationid : number){
        
        let body = JSON.stringify({retailerid : this.retailer.id,locationid: locationid, fromdate : fromdate, todate : todate});
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to ' + this.urlinventorytracesummary);
        return this.http.post(this.urlinventorytracesummary, body, options).map((res:Response) => res.json());
        
    }


    public getSubcategoryName(subcatid : Number) : string{
       for (var subcat of this.subcatlist){
           if(subcat.id === subcatid)
                return subcat.name;
       }
       return "";
    }

    public getCategoryName(catid : Number) : string{
        for (var cat of this.catlist){
            if(cat.id === catid)
                 return cat.name;
        }
        return "";
     }
 
 
    public getSubcategoryForId(subcatid : Number) : Productsubcategory{
        for (var subcat of this.subcatlist){
            if(subcat.id === subcatid)
                 return subcat;
        }
        return null;
     }
 
     
    public getCategoryForId(catid : Number) : Productcategory{
        for (var cat of this.catlist){
            if(cat.id === catid)
                 return cat;
        }
        return null;
     }
 

    public findSelectedProductIndex(retprod : Retailerproduct): number {
       for(var i=0; i<this.retailerproductlist.length;i++){
           if(this.retailerproductlist[i].id == retprod.id)
                return i;
       } 
        return -1;
    }


    public getProductForId(productid : Number) : Retailerproduct {
        for (var product of this.retailerproductlist){
             if(product.id === productid){
                 return product;    
             }
         }
         return null;
     }


     public getProductForCode(productcode) : Retailerproduct {
        for (var product of this.retailerproductlist){
             if(product.code === productcode){
                 return product;    
             }
         }
         return null;
     }
 

    public getProductName(productid : Number) : String {
       for (var product of this.retailerproductlist){
            if(product.id === productid){
                return product.name;    
            }
        }
        return "";
    }

    public getProductNameCodeById(productid : Number) : string {
        for (var product of this.retailerproductlist){
             if(product.id === productid){
                 return product.name + ' [' + product.code + ']';    
             }
         }
         return "";
     }
    
    

     public getProductIdByNamePU(productnamecode : string) : number {
        for (var product of this.retailerproductlist){
            var prname = product.name + ' [' + product.pu + ']';

             if(prname === productnamecode){
                 return product.id;    
             }
         }
         return 0;
     }

     public getProductByNamePU(productnamecode : string) : Retailerproduct {
        for (var product of this.retailerproductlist){
            var prname = product.name + ' [' + product.pu + ']';

             if(prname === productnamecode){
                 return product;    
             }
         }
         return null;
     }

    public getProductIdByNameCode(productnamecode : string) : number {
        for (var product of this.retailerproductlist){
            var prname = product.name + ' [' + product.code + ']';

             if(prname === productnamecode){
                 return product.id;    
             }
         }
         return 0;
     }


     public getSaleProductIdByNameCode(productnamecode : string) : number {
        for (var product of this.productsforsale){
            var prname = product.name + ' [' + product.code + ']';

             if(prname === productnamecode){
                 return product.id;    
             }
         }
         return 0;
     }

     public getProductByNameCode(productnamecode : string) : Retailerproduct {
        for (var product of this.retailerproductlist){
            var prname = product.name + ' [' + product.code + ']';

             if(prname === productnamecode){
                 return product;    
             }
         }
         return null;
     }

     public getProductByBarcode(productbarcode : string) : Retailerproduct {
        for (var product of this.retailerproductlist){
            if(product.barcode ==  productbarcode)
                return product; 
         }
         return null;
     }



     public getLocationIdByNameCode(locationnamecode : string) : number {
        for (var loc of this.retailerlocationlist){
            var locname = loc.locationname + ' [' + loc.locationcode + ']';

             if(locname === locationnamecode){
                 return loc.id;    
             }
         }
         return 0;
     }
     
     public getLocationNamefromId(locationid : number) : String {
        for (var loc of this.retailerlocationlist){
            
             if(loc.id === locationid){
                 return  loc.locationname + ' [' + loc.locationcode + ']';
                 
             }
         }
         return "";
     }
     

     saveSubcategory(fsubcat : Productsubcategory){
        let body = JSON.stringify(fsubcat);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.subcaturl, body, options).map((res:Response) => res.json());
     }

     saveCategory(fcat : Productcategory){
        let body = JSON.stringify(fcat);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.caturl, body, options).map((res:Response) => res.json());
     }

     saveScheme(fscheme : Retailerscheme){
         
        let body = JSON.stringify(fscheme);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.urlscheme, body, options).map((res:Response) => res.json());
     
    }


    saveDisplay(fdisplay : Digitaldisplay){
        let body = JSON.stringify(fdisplay);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.displayurl, body, options).map((res:Response) => res.json());
     }

    
     saveDisplayImage(fdisplayimage : Displayimage){
        let body = JSON.stringify(fdisplayimage);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.displayimageurl, body, options).map((res:Response) => res.json());
     }



     saveRetailerproduct(fretprod : Retailerproduct){
        
        let body = JSON.stringify(fretprod);

        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.produrl, body, options).map((res:Response) => res.json());
     }

    

     saveProductsBulk(productlist : Retailerproduct[]){
        
        let body = JSON.stringify({retailerid : this.retailer.id, productlist: productlist});
        
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.bulkprodurl, body, options).map((res:Response) => res.json());
     }
    
     
     saveRetailerProfile(fretailer : Retailer ) : Observable<any> {
            //retailer.isactive = 0;
            let body = JSON.stringify(fretailer);
            let headers = new Headers({ 'Content-Type': 'application/json'});
            let options = new RequestOptions({ headers: headers });

            if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
            return this.http.post(this.retailerurl, body, options).map((res:Response) => res.json());
      }
     
      sendRetailerAlert(alertmsg){
        
        let body = JSON.stringify({retailerid : this.retailer.id, alertmessage: alertmsg});
        
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.sendalerturl, body, options).map((res:Response) => res.json());
     }

     sendCustomerAlert(alertmsg,customermobile){
        
        let body = JSON.stringify({customermobile : customermobile, alertmessage: alertmsg});
        
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body);
        return this.http.post(this.sendcustomeralerturl, body, options).map((res:Response) => res.json());
     }
    
     
     
     
   
      saveRetailerLocation(fretailerlocation : Retailerlocation ) : Observable<any> {
        //retailer.isactive = 0;
        let body = JSON.stringify(fretailerlocation);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('sending request-->' + body + ' to url ' + this.retailerlocationurl);
        return this.http.post(this.retailerlocationurl, body, options).map((res:Response) => res.json());
        
     }
   

      login(userid: string,password: string) : Observable<string> {
        
        let body = JSON.stringify({username: userid,userpass: password });
        if(Constants.DEBUG_MODE) console.log('Sending request ' + body + ' URL ' + this.loginurl);

        let headers = new Headers({'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

                return this.http.post(this.loginurl, body, options)
                                .map(this.extractData)
                                .catch(this.handleError);
     
     }

     loginadmin(userid: string,password: string) : Observable<string> {
        let body = JSON.stringify({username: userid,userpass: password });
        if(Constants.DEBUG_MODE) console.log('Sending request ' + body + ' URL ' + this.loginurl);

        let headers = new Headers({'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

                return this.http.post(this.adminloginurl, body, options)
                                .map(this.extractData)
                                .catch(this.handleError);
     
     }
     

     logingroup(userid: string,password: string) : Observable<string> {
        let body = JSON.stringify({username: userid,userpass: password });
        if(Constants.DEBUG_MODE) console.log('Sending request ' + body + ' URL ' + this.loginurl);

        let headers = new Headers({'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

                return this.http.post(this.grouploginurl, body, options)
                                .map(this.extractData)
                                .catch(this.handleError);
     
     }
     

    private extractData(res: Response) : string {
        let serverresponse =  res.json();
        if(Constants.DEBUG_MODE) console.log('Resp obtained ==>' + JSON.stringify(serverresponse));
        return serverresponse || { };
    }
    
     private handleError (error: any) {
        let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
     }


     saveState(fstate  : State){
        let body = JSON.stringify(fstate);
        let headers = new Headers({ 'Content-Type': 'application/json'});
        let options = new RequestOptions({ headers: headers });

        if(Constants.DEBUG_MODE) console.log('Sending Request-->' + body);
        return this.http.post(this.urlsavestate, body, options).map((res:Response) => res.json());
    }


    loggedinoperatorid; 
    loggedinoperatorname; 
    loggedinusername; 
    loggedinterminalid; 
    loggedinasclient;
    logininprogress;
    serverip;
    lowstockalertmsg;

    loggedinaskitchen; 

    
    

    setDataListsForRetailerPos(data : any){
        this.masterdata = data;
		this.catlist = <Productcategory[]> data.categorylist;
        this.subcatlist = <Productsubcategory[]> data.subcategorylist;
        
        for (var subcat of this.subcatlist){
            subcat.prodlist = [];
        }

		this.retailerproductlist  = <Retailerproduct[]> data.productlist;			
		this.retailerlocationlist = <Retailerlocation[]> data.retailerlocationlist;			
        this.retailerschemelist = <Retailerscheme[]> data.schemelist;
        
        if(this.retailerschemelist && this.retailerschemelist.length > 0){
            for( var sch of this.retailerschemelist){
                sch.startdate = Utilfunctions.getBusinessDateFromString(sch.startdatestr);
                sch.enddate = Utilfunctions.getBusinessDateFromString(sch.enddatestr);
            }
        }
            
        
        Cartservice.schemelist = this.retailerschemelist;
        Cartservice.curbsndate = this.getCurrentBusinessDate();
        

		this.displaylist  = <Digitaldisplay[]> data.displaylist;
		this.imagelist  = <Displayimage[]> data.imagelist;

		if(this.imagelist  != null && this.imagelist.length > 0){
			for(var img of this.imagelist ){
				img.displayname = this.getDisplayNameById(img.displayid);
			}
		}

		this.usertype = "retailer";
		this.retailer = <Retailer> data.retailer;
		this.setProductDetails();
		

	}

    setDetailsOnProduct(prod : Retailerproduct){
        var subcategory = this.getSubcategoryForId(prod.subcategoryid);
        prod.subcatname = subcategory.name;
        
        
        if(prod.isvegeterian) 
            prod.isvegstr = 'Y';
        else
            prod.isvegstr = 'N';

        if(prod.reversecomputetax == Constants.YES) 
            prod.reversetaxstr ='Y';
        else
            prod.reversetaxstr = 'N';	
        
        if(prod.status == Constants.YES) 
            prod.statusstr ='Y';
        else
            prod.statusstr = 'N';	
        
        if(!prod.barcode){
            prod.barcode = '';
        }

         //Recipe parse 
         if(prod.recipejson && prod.recipejson.length > 0){
            prod.recipe = <Recipe> JSON.parse(prod.recipejson);
        }

        //Customization parse 
        if(prod.customizationjson && prod.customizationjson.length > 0){
            try{
                prod.customization = <Customization> JSON.parse(prod.customizationjson);
            }catch(ex){
                if(Constants.DEBUG_MODE) console.log('|PARSEERR|Error parsing customization for ===> ' + prod.name + ' --> ' +  ex);
            }
        }
        
    }

    setProductDetails(){
		
		this.subcategoriesforsale = [];
		this.productsforsale  = [];
		this.categoriesforsale = [];
		this.productsforpurchase = [];

        var i = 0; 

		for(var prod of this.retailerproductlist){
            i++;
            if(i%2 == 0)prod.tag1 = true; 
            if(i%3 == 0)prod.tag2 = true; 
            
            this.setDetailsOnProduct(prod);
            
            var subcategory = this.getSubcategoryForId(prod.subcategoryid);
			if(prod.status == Constants.YES && prod.producttype != Constants.PURCHASE_ONLY && prod.producttype != Constants.KITCHEN_PROCESSED ){
				this.addToSubcatforSale(subcategory);		
				this.addToCategoriesForSale(subcategory);

                subcategory.prodlist.push(prod);
                var parts = <String[]>prod.name.split(" ");
                prod.generatedcode = '';
				for(var part of parts){
					prod.generatedcode = prod.generatedcode +  part.charAt(0);
                }
                this.productsforsale.push(prod);
			}

			if(prod.status == Constants.YES && prod.producttype != Constants.SALE_ONLY){
				this.productsforpurchase.push(prod);		
            }


            
		}

		this.productsforsale.sort((a:Retailerproduct, b: Retailerproduct)=> a.name < b.name  ? -1 : 1 );
        this.productsforpurchase.sort((a:Retailerproduct, b: Retailerproduct)=> a.name < b.name  ? -1 : 1 );
        this.retailerproductlist.sort((a:Retailerproduct, b: Retailerproduct)=> a.code < b.code  ? -1 : 1 );
        
	}

    
    addToCategoriesForSale(subcategoryforsale : Productsubcategory){
		var category = this.getCategoryForId(subcategoryforsale.categoryid);

		for(var cat of this.categoriesforsale ){
			if(cat.id === category.id) return;
		}

		this.categoriesforsale.push(category);

	}

	addToSubcatforSale(subcategoryforsale : Productsubcategory){
		for(var subcat of this.subcategoriesforsale){
			if(subcat.id === subcategoryforsale.id) 
				return; 
		}

		this.subcategoriesforsale.push(subcategoryforsale);
	}

    
        

     isonline : boolean = true; 

     
          
    
}




