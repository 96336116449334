import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from './paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Printingservice } from '../service/printingservice';
import { Billingservice } from '../service/billingservice';
import { Cashdenomination } from '../domain/cashdenomination';
import { Shiftendrecord } from '../domain/shiftendrecord';
import { IoT1ClickDevicesService } from 'aws-sdk';
import { Userservice } from '../service/userservice';
import { Shiftendservice } from '../service/shiftendservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Summaryrecord } from '../domain/summaryrecord';
import { Payment } from '../domain/payment';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions} from '@angular/http';
import { Kot } from '../domain/kot';
import { Customer } from '../domain/customer';
import { Kitchenservice } from '../service/kitchenservice';
import { Cartlineitem } from '../domain/cartlineitem';
import { Aggregatorservice } from '../service/aggregatorservice';
import { Product } from '../domain/product';


@Component({
    templateUrl: 'processeod.html',
    providers: [ConfirmationService]
})
export class Processeod implements OnInit 
{
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];

    processedorderlist : Order[];
    cancelledorderlist : Order[];
    
    orderinsert : string[];
    iteminsert : string[];
    voidinsert : string[];
    allinsert : string[];
    deliveryinsert : string[];
    iteminsertvoid : string[];
    taxinsert : string [];

    itemsalessummary : Summaryrecord[];
    countertypesalessummary : Summaryrecord[];


    begincheck; 
    endcheck;
    begintillno;
    endtillno;


    
    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    kotlist : Kot[];
    customerlist : Customer[];
    shiftlist : Shiftendrecord[];


    currentviewtype;

    isRunning = false; 

    arrdenominations : Cashdenomination[];
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private orderService  : Orderservice,
        private http: Http,
        private shiftEndService : Shiftendservice,
        private counterService : Billingcounterservice,
        private userService  : Userservice,
        private billingService : Billingservice,
        private deliveryBoyservice : Deliveryboyservice,
        private printingService : Printingservice,
        private aggregatorService : Aggregatorservice,
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    displayconfirmation;

    eoddatestr; 

    getDenominationObject(deno){
        var obj = <Cashdenomination>{};
        obj.denomination = deno;
        obj.quantity = null;
        obj.amount = 0;
        return obj;
    }

    cancel(){
        this.displayconfirmation = false; 
    }

    setNullToZero(){
        if(!this.ar) this.ar = 0;
        if(!this.coins) this.coins = 0;
        if(!this.forex) this.forex = 0;
        
        if(!this.pettycashexp) this.pettycashexp = 0;
        if(!this.floatcash) this.floatcash = 0;
        if(!this.lowerdenomination) this.lowerdenomination = 0;
        if(!this.bankdeposit) this.bankdeposit = 0;
    }

    addToExistingRecord(rec :Summaryrecord, pymt : Payment){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + pymt.paidamount,2);
        if(pymt.paymentmode == Constants.PYMT_CARD) { 
            var strtip = "" + pymt.tip;
            if(strtip && strtip.length > 0){
                rec.summaryval2 = Utils.round(rec.summaryval2 + parseFloat(strtip),3);
            }
        }
    }
    
    createNewSummaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = "" + pymt.paymentmode;
        rec.description = pymt.paymentmodename;
        rec.summaryval2 = pymt.paidamount;

        if(pymt.paymentmode == Constants.PYMT_CARD) { 
            var strtip = "" + pymt.tip;
            rec.description = rec.description + ' (Incl Tip)';

            if(strtip && strtip.length > 0){
                rec.summaryval2 = Utils.round(rec.summaryval2 + parseFloat(strtip),3);
            }
        }
        
        return rec; 
    }

    addPaymentModeSale(pymt : Payment){
        if(!this.shiftendrecord.arrpymtmodewisesales) this.shiftendrecord.arrpymtmodewisesales = [];
        var keytocheck = "" + pymt.paymentmode;
        var currec = Utils.findInSummaryList(this.shiftendrecord.arrpymtmodewisesales,keytocheck);
        if(currec){
            this.addToExistingRecord(currec,pymt);
        }else{
            currec = this.createNewSummaryRecord(pymt);
            this.shiftendrecord.arrpymtmodewisesales.push(currec);    
        }
    }


    public async postToFocus()
    {
            var url = 'https://6y7t90xl06.execute-api.ap-south-1.amazonaws.com/prod/postrecords'; 
            let body = JSON.stringify({arrinsert : this.allinsert});
            //let body = JSON.stringify({arrinsert : ['test']});
            
            let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
            let options = new RequestOptions({ headers: headers });
        
            if(Constants.DEBUG_MODE) console.log('|POSTING|SYNC PRINT Sending request URL --> ' + url + ' Data ' + body);
        
            try{
                const retpromise=  await this.http.post(url,body,options).toPromise();
                var retjson = retpromise.json();
                if(Constants.DEBUG_MODE) console.log('|POSTING|SYNC PRINT STatus returned with http request -> ' + JSON.stringify(retpromise.json())  + ' Status text ' + retjson.status );
                if(retjson.status === 0 )
                    return true;
                
            }catch(ex){
                if(Constants.DEBUG_MODE) console.log('|POSTING| ERROR ---> ' + ex.toString());
            } 
            
            return false;
    }


    createNewItemSummary(item : Cartlineitem){
        var rec = <Summaryrecord>{};
        rec.id = item.pcode;
        rec.description = item.productname;
        rec.summaryval1 = item.pcode;
        rec.summaryval2  = item.occode;
        rec.summaryval3 = item.quantity;
        rec.summaryval4 = item.grossamt;
        
        return rec; 
    }


    addToItemSummary(rec :Summaryrecord, item : Cartlineitem){
        rec.summaryval3 =  Utils.round(rec.summaryval3 + item.quantity,2);
        rec.summaryval4 =  Utils.round(rec.summaryval4 + item.grossamt,2);
    }

    getModeWisePayment(pymtmode, ord : Order){
        

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                var cartpymt = pymt.paymentmode;

                //Set aggregator to other credit
                if(cartpymt == Constants.PYMT_AGGREGATOR) cartpymt = Constants.PYMT_OTHER_CREDIT;

                if(cartpymt == pymtmode)
                    return pymt.paidamount;
            }
        }
        return 0;
    }

    getTips(ord: Order){

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == Constants.PYMT_CARD){
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        try{
                            var tip = parseFloat(strtip);
                            return tip;
                        }catch(err){}
                    }
                }
        
            }
        }
        return 0;
    }

    createNewCountertypeSummary(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = Utils.getCountertypeCode(ord.countertype);
        rec.description = Utils.getCountertypeCode(ord.countertype);
        rec.summaryval1 = 1; //Num orders 
        rec.summaryval2 = 0;
        if(ord.numguests && ord.numguests > 0) rec.summaryval2  = ord.numguests;
        rec.summaryval3 = ord.cart.netpayable;

        rec.summaryval4 = this.getModeWisePayment(Constants.PYMT_CASH,ord);//Cash
        rec.summaryval5 = this.getModeWisePayment(Constants.PYMT_CARD,ord); //Card
        rec.summaryval6 = this.getTips(ord); //Tips
        rec.summaryval7 = this.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);//Other credit 
        rec.summaryval8 = this.getModeWisePayment(Constants.PYMT_FOREX,ord);//Forex
        rec.summaryval9 = this.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord); //Complimentory 
        rec.summaryval10 = 0;
        

        var delchargesincltax = ord.cart.deliverycharges;
        var delcharges =  Utils.round(delchargesincltax / (1 + (5/100)),3);
        var taxableamt = ord.cart.sumitemtaxable  + delcharges;
        var vatoncharges = Utils.round(delcharges * (5/100),3);
        var nettax = Utils.round(ord.cart.nettaxes + vatoncharges,3);
        console.log('|EODP|vatoncharges' + vatoncharges + ' DEL Charges' + delcharges + ' Receipt no ' + ord.receiptno);
        rec.summaryval11 = taxableamt;
        rec.summaryval12 = Utils.round(ord.cart.netpayable - ord.cart.roundingamount,2);
        rec.summaryval13 = nettax;
            
        return rec; 
    }


    addToCountertypeSummary(rec :Summaryrecord, ord : Order){
        rec.summaryval1 = rec.summaryval1 + 1; //Add order count 


        if(ord.numguests && ord.numguests > 0) rec.summaryval2  = rec.summaryval2  + ord.numguests;
        rec.summaryval3 = Utils.round(rec.summaryval3 + ord.cart.netpayable,3);

        rec.summaryval4 = rec.summaryval4 + this.getModeWisePayment(Constants.PYMT_CASH,ord);//Cash
        rec.summaryval5 = rec.summaryval5 + this.getModeWisePayment(Constants.PYMT_CARD,ord); //Card
        rec.summaryval6 = rec.summaryval6 + this.getTips(ord); //Tips
        rec.summaryval7 = rec.summaryval7 + this.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);//Other credit 
        rec.summaryval8 = rec.summaryval8 + this.getModeWisePayment(Constants.PYMT_FOREX,ord);//Forex
        rec.summaryval9 = rec.summaryval9 + this.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord); //Complimentory 

        var delchargesincltax = ord.cart.deliverycharges;
        var delcharges =  Utils.round(delchargesincltax / (1 + (5/100)),3);


        var taxableamt = ord.cart.sumitemtaxable  + delcharges;
        var vatoncharges = Utils.round(delcharges * (5/100),3);
        var nettax = Utils.round(ord.cart.nettaxes + vatoncharges,3);

        console.log('|EODP|vatoncharges' + vatoncharges + ' DEL Charges' + delcharges + ' Receipt no ' + ord.receiptno);
        
        rec.summaryval11 = rec.summaryval11 + taxableamt;
        rec.summaryval12 = rec.summaryval12 + Utils.round(ord.cart.netpayable - ord.cart.roundingamount,2);
        rec.summaryval13 = rec.summaryval13 + nettax;
        
    }

    setNationality ( ord : Order){
        ord.nationality = 0;
        if(ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL ){
            var arrguesttype = [];
            if(this.dataService.retailer.appconfig.posconfig.guesttypes && this.dataService.retailer.appconfig.posconfig.guesttypes.length > 0 ){
                arrguesttype = this.dataService.retailer.appconfig.posconfig.guesttypes.split(',');
                for(var i =0; i< arrguesttype.length; i++){
                    if(arrguesttype[i] == ord.guesttype){
                        ord.nationality = i+1;
                    }
                }
            }
            
                
        }
         
    }

    
    setDriverDetails(ord : Order){
        var str = "" + ord.deliveredby;

        if(str && str.length > 0){
            if(str.indexOf("(") > 0){
                ord.driverdetails = this.deliveryBoyservice.getDriverEmpDetailsForNameNumber(ord.deliveredby);
            }else{
                ord.driverdetails = this.deliveryBoyservice.getDriverEmpDetailsForId(ord.deliveredby);
            }
        }else{
            ord.driverdetails = "";
        }
        
    }

    bifurcateDiscounts ( ord : Order){
        ord.fooddisc = 0;
        ord.bevdisc = 0;
        ord.misdisc = 0;
        ord.tobdisc = 0;

        for(var item of ord.cart.lineitems){
            if(item.discount > 0){
                var cat = this.dataService.getCategoryForId(item.catid);
                if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                    ord.bevdisc = Utils.round(ord.bevdisc + item.discount,3);
                }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                    ord.fooddisc = Utils.round(ord.fooddisc + item.discount,3);
                }else if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                    ord.tobdisc = Utils.round(ord.tobdisc + item.discount,3);
                }else{
                    ord.misdisc = Utils.round(ord.misdisc + item.discount,3);
                }
            }
        } 
    }


    setDeliveryDetails(ord : Order){
        ord.delreference = '';
        ord.delsource = '';

        if(ord.countertype == Constants.PARCEL){
            ord.delsource = 'Normal Delivery';
            return; 
        }

        if(ord.countertype == Constants.SELFAPP){
            ord.delsource = 'Matam';
            if(ord.cart.paymentlist && ord.cart.paymentlist.length  > 0){
                var pymt = ord.cart.paymentlist[0];
                if(pymt.pymtreference && pymt.pymtreference.length > 0){
                    ord.delreference = pymt.pymtreference;
                }
            }
            return; 
        }

        if(ord.countertype == Constants.ONLINE){
            var ctr = this.counterService.getBillingcounterForId(ord.counterid);
            if(ctr){
                var aggrname = this.aggregatorService.getAggregatorNameForId(ctr.aggregatorid);
                ord.delsource = aggrname;
                ord.aggrname = aggrname; 
                
                if (aggrname == 'ZOMATO'){
                    if(ord.cart.netcharges == 0){
                        ord.delsource = ord.delsource + ' DELIVERY';
                    }
                }
                /*if(ord.cart.netcharges > 0){
                    ord.delsource = ord.delsource + ' (Self Delivery)';
                }*/
            }

            if(ord.cart.paymentlist && ord.cart.paymentlist.length  > 0){
                var pymt = ord.cart.paymentlist[0];
                if(pymt.pymtreference && pymt.pymtreference.length > 0){
                    ord.delreference = pymt.pymtreference;
                }
            }
            return; 
        }

    }

    getInventoryPLUCode(item : Cartlineitem, prod : Product){
        

        if(item.customization && item.customization.length > 0){
            if(prod.customization && prod.customization.optionlist && prod.customization.optionlist.length > 0){
                for (var option of prod.customization.optionlist){
                    if(option.choicelist && option.choicelist.length > 0){
                        for(var choice of option.choicelist){
                            if(choice.description == item.customization && choice.inventory && choice.inventory.length > 0){
                                return choice.inventory;
                            }
                        }
                    }
                }
            }
        }
        return prod.code;

    }



    async process(){

        this.shiftendrecord = <Shiftendrecord>{};
        this.setNullToZero();
        
        
        
        this.showspinner = true;
        var erpstoreid = this.dataService.retailer.appconfig.posconfig.erpstoreid;

        for(var ctr of this.counterService.billingcounterlist){
            if(ctr.active){
                if(ctr.cart.sumitemcount > 0 || ctr.occupiedon > 0){
                    this.showErrorToast( ctr.countername + ' still running/occupied, please clear them before proceeding for EOD!');
                    return; 
                }
            }
        }


        if(!this.floatcash || this.floatcash <= 0){
            this.showErrorToast('Float cash must be greater than zero!');
            return; 
        }
        
        var bsndate = this.billingService.eoddate;
        this.processedorderlist  =[];
        this.cancelledorderlist = [];
        this.orderinsert = [];
        this.iteminsert = [];
        this.voidinsert  =[];
        this.taxinsert = [];
        this.deliveryinsert = [];
        this.iteminsertvoid  = [];
        this.itemsalessummary = [];
        this.countertypesalessummary = [];
        


        var salesrecords = <Order[]> await this.orderService.getOrderlist(bsndate,bsndate);
        salesrecords.sort((a:Order, b: Order)=> a.id < b.id  ? -1 : 1 );
        

        var cashsales = 0;
        var pendingsales = 0;
        var totaltips = 0;
        if(salesrecords){


            for(var ord of salesrecords){
                
                if(ord.orderstatus == Constants.POS_ORDER_PROCESSED && ord.cart.paidamount > 0){
                    for(var pymt of ord.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_CASH || pymt.paymentmode == Constants.PYMT_FOREX){
                            cashsales = cashsales + pymt.paidamount;
                        }else{
                            this.addPaymentModeSale(pymt);
                            
                        }
                    }

                    totaltips = totaltips + this.getTips(ord);
                }
                if( ord.orderstatus == Constants.POS_ORDER_PROCESSED &&  ord.cart.pendingamount > 0){
                    pendingsales = Utils.round(pendingsales + ord.cart.pendingamount,2);
                }

                //Set to UAE time for now
                ord.createdonstr = this.datePipe.transform(ord.createdon, "yyyy-MM-dd HH:mm");
                ord.updatedonstr = this.datePipe.transform(ord.updatedon, "yyyy-MM-dd HH:mm");
                
                if(ord.ccordid && ord.ccordid > 0){
                    ord.ccagentstr = ord.billedbystr;  
                }
                
                ord.billedbystr = this.userService.getUserNameForId(ord.billedby);
                ord.servedbystr = this.userService.getUserNameForId(ord.servedby);
                var user = this.userService.getUserForId(ord.billedby);
                if(user) ord.empcode = user.empcode;
                
                
                if(ord.orderstatus == Constants.POS_ORDER_PROCESSED){
                    //Add to sales summary 
                    var summaryrec = Utils.findInSummaryList(this.countertypesalessummary, Utils.getCountertypeCode(ord.countertype));
                    if(summaryrec){
                        this.addToCountertypeSummary(summaryrec,ord);
                    }else{
                        var newsummaryrec = this.createNewCountertypeSummary(ord);
                        this.countertypesalessummary.push(newsummaryrec);       
                    }

                    //- (1.5*60*60*1000)
                    if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                        for(var pymt of ord.cart.paymentlist){
                            pymt.createdonstr = this.datePipe.transform(pymt.id ,"yyyy-MM-dd HH:mm");
                        }
                    }
                    for(var item of ord.cart.lineitems){
                        var prod = this.dataService.getProductForId(item.productid);
                        
                        if(prod){
                            //item.pcode = this.getInventoryPLUCode(item,prod);
                            item.pcode = prod.code;
                            var cat  = this.dataService.getCategoryForId(prod.categoryid);

                            //cat.name.charAt(0);
                            /*item.occode = 1;
                            if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                                item.occode= 2;
                            } */


                            item.occode = 1;
                            if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                                item.occode= 2;
                            }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                                item.occode= 1;
                            }else  if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                                item.occode= 4;
                            }else{
                                item.occode= 3;
                            }
                            

                            if(item.kottime > 0){
                                item.kottimestr = this.datePipe.transform(item.kottime, "yyyy-MM-dd HH:mm");
                            } else{
                                item.kottimestr = this.datePipe.transform(item.createdon, "yyyy-MM-dd HH:mm");
                            }

                            var currec = Utils.findInSummaryList(this.itemsalessummary,item.pcode);
                            if(currec){
                                this.addToItemSummary(currec,item);
                            }else{
                                var newrec = this.createNewItemSummary(item);
                                this.itemsalessummary.push(newrec);       
                            }
                        }
                    }


                    //Set delivery details for order
                    this.setDeliveryDetails(ord);
                    this.setNationality(ord);
                    this.bifurcateDiscounts(ord);
                    this.setDriverDetails(ord);
                    
                    var arrinsert = Utils.getFocusInsertHeader(ord,erpstoreid);

                    this.orderinsert  =  this.orderinsert.concat(arrinsert);
                    console.log('|ORDERINSERT|' + JSON.stringify(this.orderinsert));
                    this.iteminsert = this.iteminsert.concat(Utils.getFocusInsertDetail(ord,erpstoreid));
                    
                    if(ord.countertype == Constants.PARCEL){
                        var strdelinsert  = Utils.getFocusDeliveryInfo(ord,erpstoreid);
                        this.deliveryinsert.push(strdelinsert);
                    }

                    if(this.dataService.retailer.id == 1554){
                        var scharge = 0;
                        var tfee=0; 
                        var mfee =0 ;

                        if(this.dataService.retailer.appconfig.posconfig.scharge) scharge = this.dataService.retailer.appconfig.posconfig.scharge;
                        if(this.dataService.retailer.appconfig.posconfig.tfee) tfee = this.dataService.retailer.appconfig.posconfig.tfee;
                        if(this.dataService.retailer.appconfig.posconfig.mfee) mfee = this.dataService.retailer.appconfig.posconfig.mfee;

                        this.taxinsert = this.taxinsert.concat(Utils.getFocusInsertTax(ord,erpstoreid,scharge,tfee,mfee));
                    
                    }




                }else{
                    var voidinsert = Utils.getFocusVoidHeader(ord,erpstoreid);
                    for(var item of ord.cart.lineitems){
                        var prod = this.dataService.getProductForId(item.productid);
                        var cat  = this.dataService.getCategoryForId(item.catid);
                        if(prod){
                            item.pcode = prod.code;
                            cat.name.charAt(0);
                            item.occode = 1;
                            if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0) item.occode= 2;
                            if(item.kottime > 0) item.kottimestr = this.datePipe.transform(item.kottime, "yyyy-MM-dd HH:mm");

                            
                        }

                    }

                
                    this.iteminsert = this.iteminsert.concat(Utils.getFocusVoidDetail(ord,erpstoreid));
                    this.voidinsert.push(voidinsert); 


                }
            }
        }

        //for(var litem of this.iteminsert){
        //    console.log('Item insert-->' + litem);
        //}

        //Now get the KOT list 
        this.shiftlist = await this.shiftEndService.getShiftendList(bsndate,bsndate);
        this.kotlist = await this.kitchenService.getKotList(bsndate,bsndate);


        var shiftendtillno = await this.shiftEndService.getNewShiftNoWithoutUpdate();
        if(shiftendtillno == -1){
            this.showErrorToast('Error please try again!');
            return; 
        }

        //Now posting
        console.log('************** POSTING ************************');
        this.allinsert = [];
        this.allinsert = this.allinsert.concat(this.orderinsert);
        this.allinsert = this.allinsert.concat(this.voidinsert);
        this.allinsert = this.allinsert.concat(this.iteminsert);
        this.allinsert = this.allinsert.concat(this.iteminsertvoid);
        this.allinsert = this.allinsert.concat(this.deliveryinsert);
        
        if(this.dataService.retailer.id == 1554){
            this.allinsert = this.allinsert.concat(this.taxinsert);
        }
        
        //Add summary records; 

        for(var rec of this.itemsalessummary){
            var insertitem = Cartlineitem.getSummaryRecordForFocus(bsndate,erpstoreid,rec);
            this.allinsert.push(insertitem);
        }

        for(var rec of this.countertypesalessummary){
            var insertitem = Utils.getCountertypeSummaryRecordForFocus (bsndate,erpstoreid,rec);
            this.allinsert.push(insertitem);
        }

        for(var shiftrec of this.shiftlist){
            shiftrec.createdonstr = this.datePipe.transform(shiftrec.createdon,"yyyy-MM-dd HH:mm");
            var insertitem = Shiftendrecord.getFocusInsert(erpstoreid,shiftrec);
            this.allinsert.push(insertitem);
        }
        
        if(salesrecords && salesrecords.length > 0){
            this.begincheck = salesrecords[0].receiptno;
            this.endcheck = salesrecords[salesrecords.length - 1].receiptno;
        }
 
        if(this.shiftlist && this.shiftlist.length > 0){
            this.begintillno = this.shiftlist[0].shiftno;
            this.endtillno = this.shiftlist[this.shiftlist.length - 1].shiftno;
        }else{
            this.begintillno = '0';
            this.endtillno = '0';
        }
        
        


        if(pendingsales > 0){
            this.showErrorToast("There are unsettled orders for : " + Utils.getBusinessDateAsString(this.billingService.eoddate) + ", please settle the same before proceeding with EOD!");
            return false;
        }

        this.shiftendrecord.salesincash = Utils.round(cashsales,2);
        this.shiftendrecord.notesvalue = this.totaldenominationvalue;
        this.shiftendrecord.arrcashdenomination = this.arrdenominations;
        this.shiftendrecord.arrcountertypewisesales = Utils.getEODCountetypearray(this.countertypesalessummary);
        this.shiftendrecord.accountsreceivable = this.ar; 
        this.shiftendrecord.businessdate = this.billingService.eoddate;
        this.shiftendrecord.createdonstr = this.datePipe.transform(new Date(),"yyyy-MM-dd HH:mm")
        this.shiftendrecord.outgoingcashier = this.userService.loggedinuser.name;
        this.shiftendrecord.forexinaed = this.forex;
        this.shiftendrecord.pettycashexpense = this.pettycashexp;
        this.shiftendrecord.coinsvalue = this.coins;
        this.shiftendrecord.floatcash = this.floatcash;
        this.shiftendrecord.lowerdenominationvalue = this.lowerdenomination;
        this.shiftendrecord.netcash = this.netcash;
        this.shiftendrecord.totalcashinhand = Utils.round(this.netcash + this.ar - this.floatcash - this.pettycashexp,2);
        this.shiftendrecord.orderpendingamount = pendingsales;
        this.shiftendrecord.bankdeposit = this.bankdeposit;
        this.shiftendrecord.eoddate = this.billingService.eoddate;
        this.shiftendrecord.posid = this.dataService.retailer.id;
        this.shiftendrecord.totaltips = totaltips;
        this.shiftendrecord.shiftno = shiftendtillno;
        

        var discrepancy =  Utils.round(this.shiftendrecord.totalcashinhand - cashsales,2);
        this.shiftendrecord.discrepancy = discrepancy;
        if(discrepancy > 0){
            this.shiftendrecord.excessshortfall = 'Excess';
        }else if(discrepancy < 0){
            this.shiftendrecord.excessshortfall = 'Shortfall';
        }else{
            this.shiftendrecord.excessshortfall = '';
        }

        this.showspinner = false; 
        this.displayconfirmation = true; 

        
        var tillforshiftend = Shiftendrecord.getFocusInsert(erpstoreid,this.shiftendrecord);
        this.allinsert.push(tillforshiftend);
        
        //Get shift end insert 
        var shiftendinsert = Utils.getShiftendFocusInsert(erpstoreid, this.begincheck,this.endcheck,this.begintillno,shiftendtillno,this.shiftendrecord);
        this.allinsert.push(shiftendinsert);
        
        
        
    }

    async confirmProcess(){
        
        /*if(this.shiftendrecord.discrepancy != 0 ){
            if(!this.shiftendrecord.discrepancyreason || this.shiftendrecord.discrepancyreason.trim().length == 0 ){
                this.showErrorToast('Please provide reason for discrepancy!');
            }
        }*/

        if(!this.shiftendrecord.remarks || this.shiftendrecord.remarks.length == 0 ){
            this.showErrorToast('Please provide EOD Remarks!');
            return;
        }
        

        this.showspinner = true; 
        
        var lastShift = <Shiftendrecord> await this.shiftEndService.getLastShift();
        if(lastShift){
            console.log('Last shift no : ' + lastShift.shiftno);
            var changetime = this.dataService.retailer.appconfig.posconfig.shiftchangetime;
            if(!changetime) changetime = 0;
            if(new Date().getTime() - lastShift.createdon < (changetime*60*1000) ){
                this.showErrorToast('At least ' + changetime + ' minutes needed between two shift changes!');
                return;
            }
        }

        this.shiftendrecord.createdon = new Date().getTime();
        this.shiftendrecord.createdonstr = this.datePipe.transform(this.shiftendrecord.createdon,"yyyy-MM-dd HH:mm");
        
        var ret = await this.postToFocus();
        console.log('************** POSTING STATUS ************************' + ret);

        if(ret){
            var ret = await this.shiftEndService.endShift(this.shiftendrecord);
            this.showspinner = false; 
            if(ret){
                this.dataService.lasteoddate = this.shiftendrecord.eoddate;
                this.printTillReport();
                this.initialize();
            
            }else{
                this.showErrorToast('Error, please try again!');
            }
        }else{
            this.showErrorToast('Error posting to Focus, please try again!');
        }

        
    }
        
    initialize(){
        this.arrdenominations = [];
        this.arrdenominations.push(this.getDenominationObject(1000));
        this.arrdenominations.push(this.getDenominationObject(500));
        this.arrdenominations.push(this.getDenominationObject(200));
        this.arrdenominations.push(this.getDenominationObject(100));
        this.arrdenominations.push(this.getDenominationObject(50));
        this.arrdenominations.push(this.getDenominationObject(20));
        this.arrdenominations.push(this.getDenominationObject(10));
        this.arrdenominations.push(this.getDenominationObject(5));
        
        this.coins = null;
        this.forex = null;
        this.lowerdenomination = null;
        this.ar = null;
        this.floatcash = null;
        this.pettycashexp = null;
        this.bankdeposit = null;

        this.shiftendrecord = <Shiftendrecord>{};
        console.log('EOD Date: ' + this.billingService.eoddate);
        this.eoddatestr = Utils.getBusinessDateAsString("" + this.billingService.eoddate);

    }
    ngOnInit(){
        this.initialize();

    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error', life: 8000,  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner=false;
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    async printTillReport()
    {
            document.getElementById("printable").innerHTML = this.printingService.getTillReportAsHTML(this.shiftendrecord);
            this.displayconfirmation  = false; 

            setTimeout(()=>{ window.print();}, 200);
            setTimeout(() => {this._router.navigate(['/']);},500);
            
    }


    reportfrom; 
    reportto;

    showspinner; 

    selectedOrder : Order; 
    shiftendrecord : Shiftendrecord;
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        //ord.businessdatestr = this.datePipe.transform(Utils.getDateFromBusinessdate("" + ord.businessdate),"dd/MM/yy");
        
    }

    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
        }
    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }


    

    totaldenominationvalue = 0;
    coins;
    forex;
    floatcash; 
    pettycashexp;
    ar; 
    lowerdenomination;
    bankdeposit;
    netcash=0;

    denoChange(denoRecord : Cashdenomination){
        if(denoRecord.quantity && !isNaN(denoRecord.quantity) && !isNaN(parseInt(denoRecord.quantity))){
            denoRecord.amount = parseInt(denoRecord.quantity) * denoRecord.denomination;
        }else{
            denoRecord.amount = 0;
        }

        this.setTotalDenoValue();
    
    }

    setTotalDenoValue(){
        var total=0;
        for(var deno of this.arrdenominations){
            total=total+deno.amount;
        }
        this.totaldenominationvalue =total;
        this.netcash = this.totaldenominationvalue;

        if(this.coins && !isNaN(this.coins) && !isNaN(parseFloat(this.coins))){
            this.netcash = Utils.round(this.netcash + parseFloat(this.coins),2);
        }
        if(this.forex && !isNaN(this.forex) && !isNaN(parseFloat(this.forex))){
            this.netcash = Utils.round(this.netcash + parseFloat(this.forex),2);
        }
        if(this.lowerdenomination && !isNaN(this.lowerdenomination) && !isNaN(parseFloat(this.lowerdenomination))){
            this.netcash = Utils.round(this.netcash + parseFloat(this.lowerdenomination),2);
        }

    }
    
    
    
    
}


