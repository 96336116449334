import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Shiftendrecord } from 'src/app/domain/shiftendrecord';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Printingservice } from 'src/app/service/printingservice';
import { Shiftendservice } from 'src/app/service/shiftendservice';
import { Utils } from 'src/app/service/utils';
import { Cashdenomination } from 'src/app/domain/cashdenomination';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Order } from 'src/app/domain/order';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Userservice } from 'src/app/service/userservice';
import { Payment } from 'src/app/domain/payment';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions} from '@angular/http';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Aggregatorservice } from 'src/app/service/aggregatorservice';
import { Product } from 'src/app/domain/product';
import { Kot } from 'src/app/domain/kot';
import { Customer } from 'src/app/domain/customer';
import { Kitchenservice } from 'src/app/service/kitchenservice';


@Component({
    templateUrl: 'tillreport.html',
    providers: [ConfirmationService]
})
export class Tillreport implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    recordlist       :   Shiftendrecord[];
    filteredlist : Shiftendrecord[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private printingService : Printingservice,
        private http: Http,
        private counterService : Billingcounterservice,
        private shiftEndService : Shiftendservice,
        private aggregatorService : Aggregatorservice,
        private orderService : Orderservice,
        private userService : Userservice,
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'itemdetails';
        
        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'eoddatestr', header: 'EOD Date' },
            { field: 'createdonstr', header: 'Created on' },
            { field: 'notesvalue', header: 'Deno wise notes' },
            { field: 'forexinaed', header: 'Forex Value' },
            { field: 'coinsvalue', header: 'Coins Value' },
            { field: 'lowerdenominationvalue', header: 'Lower denomination' },
            { field: 'netcash', header: 'Net Cash (A)' },
            { field: 'accountsreceivable', header: 'Accounts receivable (B)' },
            { field: 'floatcash', header: 'Float Cash (C)' },
            { field: 'pettycashexpense', header: 'Petty Cash Expense (D)'  },
            { field: 'totalcashinhand', header: 'Total (A+B-C-D)' },
            { field: 'salesincash', header: 'Cash Sales' },
            { field: 'discrepancy', header: 'Discrepancy' },
            { field: 'remarks', header: 'Cashier Remarks' },
            { field: 'outgoingcashier', header: 'Outgoing Cashier' }
        ];

        this.recordlist = [];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }



    
    totaldiscrepancy = 0;

    setTotalsToZero(){
        this.totaldiscrepancy = 0;
    }


    incrementTotals(rec : Shiftendrecord){
        this.totaldiscrepancy = Utils.round(this.totaldiscrepancy + rec.discrepancy,2);
    }

    roundTotals(){
        
    }

    selectedRadio;
    showspinner;

    cancelShiftendrecord(){
    }

    showPaymentDialog(){
    }


    async reprintReport()
    {

        if(!this.selectedShiftendrecord.createdon) this.selectedShiftendrecord.createdon = this.selectedShiftendrecord.id;
        document.getElementById("printable").innerHTML = this.printingService.getTillReportAsHTML(this.selectedShiftendrecord);
        setTimeout(()=>{ window.print();}, 200);
    }

    
    reportfrom; 
    reportto;

    selectedShiftendrecord : Shiftendrecord; 
    
    setDateStrings(ord : Shiftendrecord){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
        if(ord.eoddate && ord.eoddate > 0)
            ord.eoddatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
        if(!ord.bankdeposit) ord.bankdeposit = 0;
    }

    getShiftendrecordFromList(id){
        for(var order of this.recordlist){
            if(order.id === id ) return order; 
        }
    }


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.recordlist){
                console.log('Shift no :: ' + item.shiftno);
                this.setDateStrings(item);
                this.filteredlist.push(item);
                this.incrementTotals(item);
        }
        this.filteredlist = [... this.filteredlist];

    }

    async setData(dt : Table){
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.recordlist = await this.shiftEndService.getShiftendList(fromdate,todate) ;
        this.recordlist.sort((a: Shiftendrecord, b: Shiftendrecord)=> a.id < b.id  ? -1 : 1 );

        if(this.recordlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        if(dt) dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }

    /*********************************** ALL FUNCTIONS FOR REPOSTING */

    totaldenominationvalue = 0;
    coins;
    forex;
    floatcash; 
    pettycashexp;
    ar; 
    lowerdenomination;
    bankdeposit;
    netcash=0;
    begincheck; 
    endcheck;
    begintillno;
    endtillno;

    processedorderlist : Order[];
    cancelledorderlist : Order[];
    
    orderinsert : string[];
    iteminsert : string[];
    voidinsert : string[];
    allinsert : string[];
    deliveryinsert : string[];
    iteminsertvoid : string[];
    itemsalessummary : Summaryrecord[];
    countertypesalessummary : Summaryrecord[];
    kotlist : Kot[];
    customerlist : Customer[];
    shiftlist : Shiftendrecord[];
    arrdenominations : Cashdenomination[];



    getDenominationObject(deno){
        var obj = <Cashdenomination>{};
        obj.denomination = deno;
        obj.quantity = null;
        obj.amount = 0;
        return obj;
    }

    setNullToZero(){
        if(!this.ar) this.ar = 0;
        if(!this.coins) this.coins = 0;
        if(!this.forex) this.forex = 0;
        
        if(!this.pettycashexp) this.pettycashexp = 0;
        if(!this.floatcash) this.floatcash = 0;
        if(!this.lowerdenomination) this.lowerdenomination = 0;
        if(!this.bankdeposit) this.bankdeposit = 0;
    }

    addToExistingRecord(rec :Summaryrecord, pymt : Payment){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + pymt.paidamount,2);
    }
    
    createNewSummaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = "" + pymt.paymentmode;
        rec.description = pymt.paymentmodename;
        rec.summaryval2 = pymt.paidamount;
        return rec; 
    }

    addPaymentModeSale(pymt : Payment){
        if(!this.shiftendrecord.arrpymtmodewisesales) this.shiftendrecord.arrpymtmodewisesales = [];
        var keytocheck = "" + pymt.paymentmode;
        var currec = Utils.findInSummaryList(this.shiftendrecord.arrpymtmodewisesales,keytocheck);
        if(currec){
            this.addToExistingRecord(currec,pymt);
        }else{
            currec = this.createNewSummaryRecord(pymt);
            this.shiftendrecord.arrpymtmodewisesales.push(currec);    
        }
    }

    getTips(ord: Order){

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == Constants.PYMT_CARD){
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        try{
                            var tip = parseFloat(strtip);
                            return tip;
                        }catch(err){}
                    }
                }
        
            }
        }
        return 0;
    }

    createNewItemSummary(item : Cartlineitem){
        var rec = <Summaryrecord>{};
        rec.id = item.pcode;
        rec.description = item.productname;
        rec.summaryval1 = item.pcode;
        rec.summaryval2  = item.occode;
        rec.summaryval3 = item.quantity;
        rec.summaryval4 = item.grossamt;
        
        return rec; 
    }


    addToItemSummary(rec :Summaryrecord, item : Cartlineitem){
        rec.summaryval3 =  Utils.round(rec.summaryval3 + item.quantity,2);
        rec.summaryval4 =  Utils.round(rec.summaryval4 + item.grossamt,2);
    }

    getModeWisePayment(pymtmode, ord : Order){
        

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                var cartpymt = pymt.paymentmode;

                //Set aggregator to other credit
                if(cartpymt == Constants.PYMT_AGGREGATOR) cartpymt = Constants.PYMT_OTHER_CREDIT;

                if(cartpymt == pymtmode)
                    return pymt.paidamount;
            }
        }
        return 0;
    }

    
    createNewCountertypeSummary(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = Utils.getCountertypeCode(ord.countertype);
        rec.description = Utils.getCountertypeCode(ord.countertype);
        rec.summaryval1 = 1; //Num orders 
        rec.summaryval2 = 0;
        if(ord.numguests && ord.numguests > 0) rec.summaryval2  = ord.numguests;
        rec.summaryval3 = ord.cart.netpayable;

        rec.summaryval4 = this.getModeWisePayment(Constants.PYMT_CASH,ord);//Cash
        rec.summaryval5 = this.getModeWisePayment(Constants.PYMT_CARD,ord); //Card
        rec.summaryval6 = this.getTips(ord); //Tips
        rec.summaryval7 = this.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);//Other credit 
        rec.summaryval8 = this.getModeWisePayment(Constants.PYMT_FOREX,ord);//Forex
        rec.summaryval9 = this.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord); //Complimentory 
        rec.summaryval10 = 0;
        

        var delchargesincltax = ord.cart.deliverycharges;
        var delcharges =  Utils.round(delchargesincltax / (1 + (5/100)),3);
        var taxableamt = ord.cart.sumitemtaxable  + delcharges;
        var vatoncharges = Utils.round(delcharges * (5/100),3);
        var nettax = Utils.round(ord.cart.nettaxes + vatoncharges,3);
        console.log('|EODP|vatoncharges' + vatoncharges + ' DEL Charges' + delcharges + ' Receipt no ' + ord.receiptno);
        rec.summaryval11 = taxableamt;
        rec.summaryval12 = Utils.round(ord.cart.netpayable - ord.cart.roundingamount,2);
        rec.summaryval13 = nettax;
            
        return rec; 
    }


    addToCountertypeSummary(rec :Summaryrecord, ord : Order){
        rec.summaryval1 = rec.summaryval1 + 1; //Add order count 


        if(ord.numguests && ord.numguests > 0) rec.summaryval2  = rec.summaryval2  + ord.numguests;
        rec.summaryval3 = Utils.round(rec.summaryval3 + ord.cart.netpayable,3);

        rec.summaryval4 = rec.summaryval4 + this.getModeWisePayment(Constants.PYMT_CASH,ord);//Cash
        rec.summaryval5 = rec.summaryval5 + this.getModeWisePayment(Constants.PYMT_CARD,ord); //Card
        rec.summaryval6 = rec.summaryval6 + this.getTips(ord); //Tips
        rec.summaryval7 = rec.summaryval7 + this.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);//Other credit 
        rec.summaryval8 = rec.summaryval8 + this.getModeWisePayment(Constants.PYMT_FOREX,ord);//Forex
        rec.summaryval9 = rec.summaryval9 + this.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord); //Complimentory 

        var delchargesincltax = ord.cart.deliverycharges;
        var delcharges =  Utils.round(delchargesincltax / (1 + (5/100)),3);


        var taxableamt = ord.cart.sumitemtaxable  + delcharges;
        var vatoncharges = Utils.round(delcharges * (5/100),3);
        var nettax = Utils.round(ord.cart.nettaxes + vatoncharges,3);

        console.log('|EODP|vatoncharges' + vatoncharges + ' DEL Charges' + delcharges + ' Receipt no ' + ord.receiptno);
        
        rec.summaryval11 = rec.summaryval11 + taxableamt;
        rec.summaryval12 = rec.summaryval12 + Utils.round(ord.cart.netpayable - ord.cart.roundingamount,2);
        rec.summaryval13 = rec.summaryval13 + nettax;
        
    }

    setNationality ( ord : Order){
        ord.nationality = 0;
        if(ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL ){
            var arrguesttype = [];
            if(this.dataService.retailer.appconfig.posconfig.guesttypes && this.dataService.retailer.appconfig.posconfig.guesttypes.length > 0 ){
                arrguesttype = this.dataService.retailer.appconfig.posconfig.guesttypes.split(',');
                for(var i =0; i< arrguesttype.length; i++){
                    if(arrguesttype[i] == ord.guesttype){
                        ord.nationality = i+1;
                    }
                }
            }
            
                
        }
         
    }

    setDeliveryDetails(ord : Order){
        ord.delreference = '';
        ord.delsource = '';

        if(ord.countertype == Constants.PARCEL){
            ord.delsource = 'Normal Delivery';
            return; 
        }

        if(ord.countertype == Constants.SELFAPP){
            ord.delsource = 'Matam';
            if(ord.cart.paymentlist && ord.cart.paymentlist.length  > 0){
                var pymt = ord.cart.paymentlist[0];
                if(pymt.pymtreference && pymt.pymtreference.length > 0){
                    ord.delreference = pymt.pymtreference;
                }
            }
            return; 
        }

        if(ord.countertype == Constants.ONLINE){
            var ctr = this.counterService.getBillingcounterForId(ord.counterid);
            if(ctr){
                var aggrname = this.aggregatorService.getAggregatorNameForId(ctr.aggregatorid);
                ord.delsource = aggrname;
                ord.aggrname = aggrname;
                
                if (aggrname == 'ZOMATO'){
                    if(ord.cart.netcharges == 0){
                        ord.delsource = ord.delsource + ' DELIVERY';
                    }
                }
                /*if(ord.cart.netcharges > 0){
                    ord.delsource = ord.delsource + ' (Self Delivery)';
                }*/
            }

            if(ord.cart.paymentlist && ord.cart.paymentlist.length  > 0){
                var pymt = ord.cart.paymentlist[0];
                if(pymt.pymtreference && pymt.pymtreference.length > 0){
                    ord.delreference = pymt.pymtreference;
                }
            }
            return; 
        }

    }

    getInventoryPLUCode(item : Cartlineitem, prod : Product){
        if(item.customization && item.customization.length > 0){
            if(prod.customization && prod.customization.optionlist && prod.customization.optionlist.length > 0){
                for (var option of prod.customization.optionlist){
                    if(option.choicelist && option.choicelist.length > 0){
                        for(var choice of option.choicelist){
                            if(choice.description == item.customization && choice.inventory && choice.inventory.length > 0){
                                return choice.inventory;
                            }
                        }
                    }
                }
            }
        }
        return prod.code;

    }




    public async postToFocus()
    {
            var url = 'https://6y7t90xl06.execute-api.ap-south-1.amazonaws.com/prod/postrecords'; 
            let body = JSON.stringify({arrinsert : this.allinsert});
            //let body = JSON.stringify({arrinsert : ['test']});
            
            let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
            let options = new RequestOptions({ headers: headers });
        
            if(Constants.DEBUG_MODE) console.log('SYNC PRINT Sending request URL --> ' + url + ' Data ' + body);
        
            try{
                const retpromise=  await this.http.post(url,body,options).toPromise();
                var retjson = retpromise.json();
                if(Constants.DEBUG_MODE) console.log('SYNC PRINT STatus returned with http request -> ' + JSON.stringify(retpromise.json())  + ' Status text ' + retjson.status );
                if(retjson.status === 0 )
                    return true;
                
            }catch(ex){
                if(Constants.DEBUG_MODE) console.log('Error in printing ---> ' + ex.toString());
            } 
            
            return false;
    }

    shiftendrecord : Shiftendrecord;

    async repostToFocus(){

        this.shiftendrecord = this.selectedShiftendrecord;
        this.setNullToZero();
        this.showspinner = true;
        var erpstoreid = this.dataService.retailer.appconfig.posconfig.erpstoreid;


        var bsndate = this.selectedShiftendrecord.eoddate;
        this.processedorderlist  =[];
        this.cancelledorderlist = [];
        this.orderinsert = [];
        this.iteminsert = [];
        this.voidinsert  =[];
        this.deliveryinsert = [];
        this.iteminsertvoid  = [];
        this.itemsalessummary = [];
        this.countertypesalessummary = [];
        


        var salesrecords = <Order[]> await this.orderService.getOrderlist(bsndate,bsndate);
        salesrecords.sort((a:Order, b: Order)=> a.id < b.id  ? -1 : 1 );
        

        var cashsales = 0;
        var pendingsales = 0;
        var totaltips = 0;
        if(salesrecords){


            for(var ord of salesrecords){
                
                if(ord.orderstatus == Constants.POS_ORDER_PROCESSED && ord.cart.paidamount > 0){
                    for(var pymt of ord.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_CASH || pymt.paymentmode == Constants.PYMT_FOREX){
                            cashsales = cashsales + pymt.paidamount;
                        }else{
                            this.addPaymentModeSale(pymt);
                            
                        }
                    }

                    totaltips = totaltips + this.getTips(ord);
                }
                if( ord.orderstatus == Constants.POS_ORDER_PROCESSED &&  ord.cart.pendingamount > 0){
                    pendingsales = Utils.round(pendingsales + ord.cart.pendingamount,2);
                }

                //Set to UAE time for now
                ord.createdonstr = this.datePipe.transform(ord.createdon, "yyyy-MM-dd HH:mm");
                ord.updatedonstr = this.datePipe.transform(ord.updatedon, "yyyy-MM-dd HH:mm");
                ord.billedbystr = this.userService.getUserNameForId(ord.billedby);
                ord.servedbystr = this.userService.getUserNameForId(ord.servedby);
                var user = this.userService.getUserForId(ord.billedby);
                if(user) ord.empcode = user.empcode;
                
                
                if(ord.orderstatus == Constants.POS_ORDER_PROCESSED){
                    //Add to sales summary 
                    var summaryrec = Utils.findInSummaryList(this.countertypesalessummary, Utils.getCountertypeCode(ord.countertype));
                    if(summaryrec){
                        this.addToCountertypeSummary(summaryrec,ord);
                    }else{
                        var newsummaryrec = this.createNewCountertypeSummary(ord);
                        this.countertypesalessummary.push(newsummaryrec);       
                    }

                    //- (1.5*60*60*1000)
                    if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                        for(var pymt of ord.cart.paymentlist){
                            pymt.createdonstr = this.datePipe.transform(pymt.id ,"yyyy-MM-dd HH:mm");
                        }
                    }
                    for(var item of ord.cart.lineitems){
                        var prod = this.dataService.getProductForId(item.productid);
                        var cat  = this.dataService.getCategoryForId(item.catid);

                        if(prod){
                            item.pcode = this.getInventoryPLUCode(item,prod);
                            cat.name.charAt(0);
                            item.occode = 1;
                            if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0) item.occode= 2;
                            if(item.kottime > 0){
                                item.kottimestr = this.datePipe.transform(item.kottime, "yyyy-MM-dd HH:mm");
                            } else{
                                item.kottimestr = this.datePipe.transform(item.createdon, "yyyy-MM-dd HH:mm");
                            }

                            var currec = Utils.findInSummaryList(this.itemsalessummary,item.pcode);
                            if(currec){
                                this.addToItemSummary(currec,item);
                            }else{
                                var newrec = this.createNewItemSummary(item);
                                this.itemsalessummary.push(newrec);       
                            }
                        }
                    }


                    //Set delivery details for order
                    this.setDeliveryDetails(ord);
                    this.setNationality(ord);
                    var arrinsert = Utils.getFocusInsertHeader(ord,erpstoreid);

                    this.orderinsert  =  this.orderinsert.concat(arrinsert);
                    console.log('|ORDERINSERT|' + JSON.stringify(this.orderinsert));
                    this.iteminsert = this.iteminsert.concat(Utils.getFocusInsertDetail(ord,erpstoreid));
                    
                    if(ord.countertype == Constants.PARCEL){
                        var strdelinsert  = Utils.getFocusDeliveryInfo(ord,erpstoreid);
                        this.deliveryinsert.push(strdelinsert);
                    }

                }else{
                    var voidinsert = Utils.getFocusVoidHeader(ord,erpstoreid);
                    for(var item of ord.cart.lineitems){
                        var prod = this.dataService.getProductForId(item.productid);
                        var cat  = this.dataService.getCategoryForId(item.catid);
                        if(prod){
                            item.pcode = prod.code;
                            cat.name.charAt(0);
                            item.occode = 1;
                            if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0) item.occode= 2;
                            if(item.kottime > 0) item.kottimestr = this.datePipe.transform(item.kottime, "yyyy-MM-dd HH:mm");

                            
                        }

                    }

                
                    this.iteminsert = this.iteminsert.concat(Utils.getFocusVoidDetail(ord,erpstoreid));
                    this.voidinsert.push(voidinsert); 


                }
            }
        }

       
        //Now get the KOT list 
        this.shiftlist = await this.shiftEndService.getShiftendList(bsndate,bsndate);
        this.kotlist = await this.kitchenService.getKotList(bsndate,bsndate);


        /*var shiftendtillno = await this.shiftEndService.getNewShiftNoWithoutUpdate();
        if(shiftendtillno == -1){
            this.showErrorToast('Error please try again!');
            return; 
        }*/

        //Now posting
        console.log('************** POSTING ************************');
        this.allinsert = [];
        this.allinsert = this.allinsert.concat(this.orderinsert);
        this.allinsert = this.allinsert.concat(this.voidinsert);
        this.allinsert = this.allinsert.concat(this.iteminsert);
        this.allinsert = this.allinsert.concat(this.iteminsertvoid);
        this.allinsert = this.allinsert.concat(this.deliveryinsert);
        
        //Add summary records; 

        for(var rec of this.itemsalessummary){
            var insertitem = Cartlineitem.getSummaryRecordForFocus(bsndate,erpstoreid,rec);
            this.allinsert.push(insertitem);
        }

        for(var rec of this.countertypesalessummary){
            var insertitem = Utils.getCountertypeSummaryRecordForFocus (bsndate,erpstoreid,rec);
            this.allinsert.push(insertitem);
        }

        for(var shiftrec of this.shiftlist){
            shiftrec.createdonstr = this.datePipe.transform(shiftrec.createdon,"yyyy-MM-dd HH:mm");
            var insertitem = Shiftendrecord.getFocusInsert(erpstoreid,shiftrec);
            this.allinsert.push(insertitem);
        }
        
        if(salesrecords && salesrecords.length > 0){
            this.begincheck = salesrecords[0].receiptno;
            this.endcheck = salesrecords[salesrecords.length - 1].receiptno;
        }
 
        if(this.shiftlist && this.shiftlist.length > 0){
            this.begintillno = this.shiftlist[0].shiftno;
            this.endtillno = this.shiftlist[this.shiftlist.length - 1].shiftno;
        }else{
            this.begintillno = '0';
            this.endtillno = '0';
        }
        
        
        var tillforshiftend = Shiftendrecord.getFocusInsert(erpstoreid,this.shiftendrecord);
        this.allinsert.push(tillforshiftend);
        
        //Get shift end insert 
        var shiftendinsert = Utils.getShiftendFocusInsert(erpstoreid, this.begincheck,this.endcheck,this.begintillno,this.endtillno,this.shiftendrecord);
        this.allinsert.push(shiftendinsert);
        
        for(var str of this.allinsert){
            console.log('|ALLINSERT|' +str);
        }

        var ret = await this.postToFocus();
        console.log('************** POSTING STATUS ************************' + ret);

        if(ret){
            this.showSuccessToast('Data Posted Successfully!');
        }else{
            this.showErrorToast('Error posting to Focus, please try again!');
        }


        this.showspinner = false; 
        
        
        
    }


    
    
}


