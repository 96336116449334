import {Kitchen} from '../domain/kitchen'
import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { Billingcounter } from '../domain/billingcounter';
import { Consumercart } from '../domain/consumercart';
import { Order } from '../domain/order';
import { Cartlineitem } from '../domain/cartlineitem';
import { Kot } from '../domain/kot';
import { Kottoken } from '../domain/kottoken';

import { Billingcounterservice } from './billingcounterservice';
import { DatePipe, KeyValuePipe } from '@angular/common';
import { Printingservice } from './printingservice';
import { Tableorder } from '../domain/tableorder';
import { MessageService } from 'primeng/api';
import { Userservice } from './userservice';
import { OrderListModule } from 'primeng/primeng';

@Injectable()
export class Kitchenservice  {
     kitchenlist : Kitchen[];
     
     constructor(
         private couchdbService : Couchdbservice,
         private dynamoDBService : Dynamodbservice,
         private dataService : Appdataservice,
         private printerService : Printerservice,
         private printingService : Printingservice,
         private datePipe : DatePipe,
         private userService : Userservice,
         private messageService : MessageService,
         private counterService : Billingcounterservice,
     ){}

     public getNewId(){
         var maxid = 0;
         for(var ctr  of this.kitchenlist){
             if(ctr.id > maxid)
                maxid = ctr.id;
         }

         return maxid + 1; 
     }

     
     addEditInList(kit : Kitchen){
       var found = false; 
       for(var i=0;i<this.kitchenlist.length; i++){
           if(this.kitchenlist[i].id === kit.id){
               this.kitchenlist[i] = kit;
               found = true; 
               break;
           }
       }

       if(!found){
           this.kitchenlist.push(kit);
       } 

    }


    

    async getNewKOTToken() 
    {
        var retryattempt =0;

        while(retryattempt < 3){
                if(Constants.DEBUG_MODE) console.log('*** Fetching token attempt :: ' + retryattempt);
                var kottoken ;
                var key  = {"posid": "" + this.dataService.getPosId()};

                if(this.dataService.usedynamo){
                    kottoken = <Kottoken> await this.dynamoDBService.getItem('kottokens',key);
                }else{
                    kottoken = <Kottoken> await this.couchdbService.getItem('kottokens',this.dataService.getPosId());
                }

                console.log('TOKEN OBTAINED: ----->>>>>> '  + JSON.stringify(kottoken));
                if(kottoken == null){

                }else if(kottoken.businessdate != this.dataService.getCurrentBusinessDate()){
                    console.log('HERE!!: ----->>>>>> ' );

                    var tok = <Kottoken>{};
                    tok.posid = this.dataService.getPosId();
                    tok.tokenno = 1; 
                    tok.businessdate = this.dataService.getCurrentBusinessDate();
                    tok.id = this.dataService.getPosId();
                    var status;
                    if(this.dataService.usedynamo){
                        status = await this.dynamoDBService.putItem('kottokens',tok);
                    }else{
                        if(kottoken)
                            tok._rev = kottoken._rev;
                        status = await this.couchdbService.putItem('kottokens',tok);
                    }

                    if(status == 'DONE'){
                        return '1';
                    }

                }else{

                    var curtoken = kottoken.tokenno;

                    var newtoken = kottoken.tokenno + 1;
                    kottoken.tokenno = newtoken;
                    var status;

                    if(this.dataService.usedynamo){
                        var conditionexpression = "posid = :posid  and tokenno = :oldtoken ";  
                        var attribparams= {":newtoken" : newtoken, ":oldtoken" : curtoken, ":posid" : this.dataService.getPosId()};

                        console.log('Attrib Params: ----->>>>>> ' + conditionexpression);
                        var updateexpression = "set tokenno = :newtoken";

                        status = await this.dynamoDBService.updateItem('kottokens',updateexpression,conditionexpression,attribparams,key);
                    }else{
                        status = await this.couchdbService.putItem('kottokens',kottoken);
                    }
                    if(status == 'DONE'){
                        return '' + newtoken;
                    }
                }

                retryattempt = retryattempt + 1;
        }
        
        return 'ERR';

    }


     public checkDuplicate(kit : Kitchen){
        for(var ctr  of this.kitchenlist){
            if(ctr.id != kit.id && ctr.kitchenid == kit.kitchenid)
               return true;
        }

        return false; 
    }


    getKitchenNameForId(id){
        for(var kit of this.kitchenlist){
            if(kit.id === id) return kit.name ; 
        }
        return "";
    }

    getKitchenForId(id){
        for(var kit of this.kitchenlist){
            if(kit.id === id) return kit; 
        }
        return null;
    }
    
    getKitchenIdForName(name){
        for(var kit of this.kitchenlist){
            if(kit.name === name) return kit.id; 
        }
        return 0;
    }

       
    getKitchen(name){
        for(var kit of this.kitchenlist){
            if(kit.name === name) return kit; 
        }
        return null;
    }

    
    async initKitchens(){
        this.kitchenlist = [];
        if(Constants.DEBUG_MODE) console.log('|DBOYSEVICE|Fetching from couch');

        var retlist;
        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.getAllItemsForPosId('kitchens');
            if(retlist) this.kitchenlist = <Kitchen[]> retlist;
        }else{
            retlist = await this.couchdbService.getAllItems('kitchens');
            if(retlist) this.kitchenlist = <Kitchen[]> retlist.docs;
        }

        if(retlist){
            for(var kit of this.kitchenlist){
                kit.printername = this.printerService.getPrinterNameForId(kit.printerid);
            }
            return true;
        }else{
            return false;
        }
        
     }
     
     async save(kitchen){
        if(Constants.DEBUG_MODE) console.log('|KITCHENSERVICE|Saving kitchen ' + JSON.stringify(kitchen));

        var status;
        if(this.dataService.usedynamo){
            status = await this.dynamoDBService.putItem('kitchens',kitchen);    
        }else{
            status = await this.couchdbService.putItem('kitchens',kitchen);
        }
        
        if(status != "ERROR" && status != "CONFLICT"){
            this.addEditInList(kitchen);
            this.kitchenlist = [... this.kitchenlist];
            return status;
        }else{
            return status;
        }
        
     }

    
     checkUnsent(cart : Consumercart){
        for (var item of cart.lineitems){
            if(item.kitstatus == Constants.UNSENT) return true; 
        }

        return false;
    }

    checkSent(cart : Consumercart){
        for (var item of cart.lineitems){
            if(item.kitstatus != Constants.UNSENT) return true; 
        }

        return false;
    }

    


    getIndexOfToken(tokenarray : string[], kottoken){
        for(let i =0;i<tokenarray.length;i++){
            if(tokenarray[i] === kottoken) return i;
        }

        return -1;
    }
    
    getKOTTokensFromCart(cart : Consumercart){
        var tokenarray : string[];
        tokenarray = [];

        for(var item of cart.lineitems){
            var i = this.getIndexOfToken(tokenarray, '' + item.kottoken);
            if(i == -1){
                tokenarray.push('' + item.kottoken);
            }
        }

        return tokenarray.join();

    }

    getIndexOfKOT(kotarray : Kot[], kottoken){
        for(let i =0;i<kotarray.length;i++){
            if(kotarray[i].kottoken === kottoken) return i;
        }

        return -1;
    }
    
    getKOTArrayFromCart(cart : Consumercart){
        var kotarray : Kot[];
        kotarray = [];

        for(var item of cart.lineitems){
            //If sent for KOT 
            if(item.kitstatus != Constants.UNSENT){
                var i = this.getIndexOfKOT(kotarray, item.kottoken);
                if(i >= 0){
                    var curkot = kotarray[i];
                    curkot.lineitems.push(item);
                }else{
                    var kot = <Kot>{};
                    kot.kottoken = item.kottoken;
                    kot.id = item.kottime;
                    kot.kitchenname = this.getKitchenNameForId(item.kitchenid);
                    kot.kitchenid = item.kitchenid;
                    if(item.kitstatus == Constants.DISPATCHED_TO_KITCHEN){
                        kot.printerror = true; 
                    }

                    kot.lineitems = [];
                    kot.lineitems.push(item);
                    kotarray.push(kot);
                }
            }
        }

        for(var kot of kotarray)
            this.setStringVarsinKot(kot);
            
        return kotarray;

    }

    getItemAsKOTString(item : Cartlineitem){
        var itemstring = item.productname + " (" + item.quantity + ")";
        if(item.customization && item.customization.length > 0){
            itemstring = itemstring  + ' [' + item.customization + '] ';
        }
        if(item.instructions && item.instructions.length > 0){
            itemstring = itemstring  + ' {' + item.instructions + '} ';
        }
        return itemstring;

    }
    
    setStringVarsinKot(kot : Kot){
        if(kot.id == 0) {
            kot.kottimestr = "";
        } else{
            kot.kottimestr = this.datePipe.transform(kot.id, "dd/MM HH:mm");
        }

        var productstring = "";
        var cancelstring = "";

        kot.itemcount =0;
        

        for(var item of kot.lineitems){
            if(!item.cancelled)
                productstring = productstring + this.getItemAsKOTString(item) + ", ";
             else 
                cancelstring = cancelstring + this.getItemAsKOTString(item) + ", ";


            kot.itemcount = kot.itemcount + item.quantity;

            
        }

        if(productstring.length > 2)
          productstring = productstring.substring(0, productstring.length - 2);

        kot.lineitemsstr = productstring;

        if(cancelstring.length > 2){
            cancelstring= cancelstring.substring(0, cancelstring.length - 2);
            kot.cancelstr = cancelstring;
        }

  }



    markKOTItemsAsSent(kot : Kot,cart : Consumercart, kitchenid){
        for(var lineitem of kot.lineitems){
            if(lineitem.kitchenid == kitchenid){
                lineitem.kitstatus = Constants.RECIEVED_BY_KITCHEN;
                lineitem.kottoken = kot.kottoken;
                lineitem.kottime = kot.id;
            }
        }
        if(!cart.kottokens) cart.kottokens = '';
        cart.kottokens = cart.kottokens + kot.kottoken + ', ';

        
    }


    markKOTItemsAsReceived(kot : Kot,kitchenid){

        for(var lineitem of kot.lineitems){
            if(lineitem.kitchenid == kitchenid)
            lineitem.kitstatus = Constants.RECIEVED_BY_KITCHEN;
        }
    }

    async saveKOT(kot){
        
        if(Constants.DEBUG_MODE) console.log('|KITCHENSERVICE|Saving KOT' + JSON.stringify(kot));
        var status;
        if(this.dataService.usedynamo){
            status= await this.dynamoDBService.putItem('kots',kot);
        }else{
            status= await this.couchdbService.putItem('kots',kot);
        }
        if(status != "ERROR" ){
            return true;
        }else{
            return false;
        }
     }

     async printKOTInKitchen(kot : Kot, kitchen : Kitchen, isduplicate)
     {
         if(kitchen.printerid > 0){
            var printer = this.printerService.getPrinterForId(kitchen.printerid);
            if(printer){
                var retstatus = await this.printingService.generateAndPrintKOT(kot,printer,isduplicate,false);
                if(retstatus){
                    this.markKOTItemsAsReceived(kot,kitchen.id);
                }
            }
         }

     }


     async printTestKOT(kitchen : Kitchen)
     {
         if(kitchen.printerid > 0){
            var printer = this.printerService.getPrinterForId(kitchen.printerid);
            if(printer){
                var kot = <Kot>{};
                kot.kitchenname = kitchen.name;
                kot.countername = 'Test KOT';
                kot.kottoken = 'TEST'
                kot.id =new Date().getTime();

                kot.lineitems = [];
                var item = <Cartlineitem>{};
                item.productname = 'Test Product 1';
                item.quantity = 1;
                kot.lineitems.push(item);

                item = <Cartlineitem>{};
                item.productname = 'Test Product 2';
                item.quantity = 2;
                kot.lineitems.push(item);

                var retstatus = await this.printingService.generateAndPrintKOT(kot,printer,false,true);
                return retstatus; 
            }
         }

         return false; 

     }


     async printKOTOnPrintter(kot : Kot, printerid, isduplicate)
     {
        var printer = this.printerService.getPrinterForId(printerid);
        if(printer){
            var ret = await this.printingService.generateAndPrintKOT(kot,printer,isduplicate,false);
            if(!ret){
                this.showErrorToast('Error printing kot for Kitchen: ' + kot.kitchenname);
            }
            return ret; 
        }
        return false; 
     }


     showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    
     async generateKOTs() 
     {
        var cart = this.counterService.selectedcounter.cart;
        if(!this.checkUnsent(cart)) return "No items to send to kitchen!";
        console.log('CTRREMARKS-->0' );

        if(!this.counterService.selectedcounter.sessionid || this.counterService.selectedcounter.sessionid == 0){
            return "No session generated for counter, please close the counter and open again!";
        }

        var consolidatedkot = false; 
        var curDateTime = new Date();
        var  dupkoton =  this.counterService.selectedcounter.dupkoton;
    
        var kotConsolidated = <Kot>{} //This var shall store the consolidated KOT 
        kotConsolidated.lineitems =[];
        kotConsolidated.kottoken = '';
        kotConsolidated.id = curDateTime.getTime();
        kotConsolidated.kitchenname = 'Consolidated';
        kotConsolidated.servedbystr = this.counterService.selectedcounter.servedbystr;
        kotConsolidated.printerid = 0;
        kotConsolidated.countername = this.counterService.selectedcounter.countername;
        if(this.counterService.selectedcounter.alias && this.counterService.selectedcounter.alias.length > 0){
            kotConsolidated.countername =kotConsolidated.countername + ' (' + this.counterService.selectedcounter.alias + ')';
        }

        kotConsolidated.ctrremarks = this.counterService.selectedcounter.remarks;


        if(dupkoton > 0 && this.counterService.selectedcounter.dupkotconsolidated){
            consolidatedkot = true;
        }    

        //Set the unsent items 
        var allunsentitems : Cartlineitem[] = [];
        for(var item of cart.lineitems){
            //console.log('Item kitchen id ' + item.kitchenid + ' KIT ID ' + kit.kitchenid);
            if(item.kitstatus == Constants.UNSENT){
                allunsentitems.push(item);
            }
        }

        //Now lets move through the kitchen list and generate KOT for each kitchen
        for (var kit of this.kitchenlist)
        {   
            //If any kitchen has print all, then also mark consolidated KOT as true
            if(kit.printall) consolidatedkot = true; 
            
            var kitchenunsentitems : Cartlineitem[] = [];
            for(var item of cart.lineitems){
                if(item.kitstatus == Constants.UNSENT && parseInt(item.kitchenid) == parseInt(kit.kitchenid)){
                    kitchenunsentitems.push(item);
                }
            }

        
            //Items found for kitchen 
            if(kitchenunsentitems.length  > 0){

                    var kot = <Kot>{};
                    kot.printedinkitchen = true; 
                    kot.printerip = '';
                    var newtoken = await this.getNewKOTToken();
                    if(newtoken == 'ERR'){
                        cart.lineitems = [... cart.lineitems];
                        //Set the unsent status 
                        this.counterService.selectedcounter.unsentitems = this.checkUnsent(this.counterService.selectedcounter.cart);
                        //await this.counterService.saveSelectedCounter();
                        return "Error generating KOT token, please try again!";
                    } 
                            
                    kot.kottoken = newtoken;
                    kot.id = new Date().getTime();
                    kot.lineitems = kitchenunsentitems;
                    kot.kitchenname = kit.name;
                    kot.kitchenid = kit.id;
                    kot.counterid = this.counterService.selectedcounter.id;
                    kot.countername = this.counterService.selectedcounter.countername;
                    kot.ctrremarks = this.counterService.selectedcounter.remarks;

                    console.log('CTRREMARKS-->1' + kot.ctrremarks);

                    kot.posid = this.dataService.getPosId();
                    kot.servedby = this.counterService.selectedcounter.servedby;
                    kot.servedbystr = this.counterService.selectedcounter.servedbystr;
                    
                    
                    
                    var curuser = this.userService.loggedinuser;
                    kot.optrid = 0;
                    if(curuser) kot.optrid = curuser.id;

                    kot.bsndate = this.dataService.getCurrentBusinessDate();
                    kot.sessionid = this.counterService.selectedcounter.id + "|" + this.counterService.selectedcounter.sessionid;

                            
                    var status = await this.saveKOT(kot);
                    if(status){
                        this.markKOTItemsAsSent(kot,cart,kit.id);

                        if(!kit.printall) {
                            kot.printerid = kit.printerid;
                            console.log('CTRREMARKS-->2' + kot.ctrremarks);
                            this.printKOTOnPrintter(kot,kit.printerid,false);
                        }
                        
                        if(consolidatedkot){
                            if(kotConsolidated.kottoken.length > 0) kotConsolidated.kottoken = kotConsolidated.kottoken  + ', ';
                            
                            kotConsolidated.kottoken = kotConsolidated.kottoken  + kot.kottoken;
                            for(var item of kot.lineitems){
                                kotConsolidated.lineitems.push(item);
                            }
                        }


                    }else{
                        cart.lineitems = [... cart.lineitems];
                        //Set the unsent status 
                        this.counterService.selectedcounter.unsentitems = this.checkUnsent(this.counterService.selectedcounter.cart);
                        await this.counterService.saveSelectedCounter();
                        
                        return "Error saving KOT, please try again!";
                    }
             }
        
        }

        cart.lineitems = [... cart.lineitems];
        if(consolidatedkot){
            if(dupkoton > 0 && this.counterService.selectedcounter.dupkotconsolidated){
                this.printKOTOnPrintter(kotConsolidated,dupkoton,false);
            }

            for(var kit of this.kitchenlist){
                if(kit.printall){
                    kotConsolidated.kitchenname = 'Consolidated for ' + kit.name;
                    this.printKOTOnPrintter(kotConsolidated,kit.printerid,false);
                }
            }
        }
            

        //Set the unsent status 
        this.counterService.selectedcounter.unsentitems = this.checkUnsent(this.counterService.selectedcounter.cart);
        
        var ret = await this.counterService.saveSelectedCounter();
        if(ret != 'DONE'){
            ret = await this.counterService.saveSelectedCounter();

            if(ret != 'DONE'){
                ret = await this.counterService.saveSelectedCounter();
            }   
        }
        return "ok"; 

     }


    
    async sendToKitchen()
    {
        var cart = this.counterService.selectedcounter.cart;
        if(!this.checkUnsent(cart)) return;

        var consolidatedkot = false; 
        var kotConsolidated = <Kot>{}
        
        
        var curDateTime = new Date();
        var commskitchenlist : Kitchen[] = [];
        var printedkitchenip = "";

        var koton = this.counterService.selectedcounter.koton; 
        var  dupkoton =  this.counterService.selectedcounter.dupkoton;
                        
                        
        if(dupkoton > 0 && this.counterService.selectedcounter.dupkotconsolidated){
            consolidatedkot = true;
            kotConsolidated.lineitems =[];
            kotConsolidated.kottoken = '';
            kotConsolidated.id = curDateTime.getTime();
            kotConsolidated.kitchenname = 'Consolidated';
            kotConsolidated.countername = this.counterService.selectedcounter.countername;
            if(this.counterService.selectedcounter.alias && this.counterService.selectedcounter.alias.length > 0){
                kotConsolidated.countername =kotConsolidated.countername + ' (' + this.counterService.selectedcounter.alias + ')';
            }
        }


        for(var kit of this.kitchenlist){
            commskitchenlist.push(kit);

        }

                
        //Now lets see if we can print thru that
        for (var kit of commskitchenlist)
        {
            console.log('Sending for kitchen ' + kit.name  + 'Num items ' + cart.lineitems.length);
            var kitchenunsentitems : Cartlineitem[] = [];
        
            for(var item of cart.lineitems){
                
                console.log('Item kitchen id ' + item.kitchenid + ' KIT ID ' + kit.kitchenid);

                if(item.kitstatus == Constants.UNSENT && (kit.printall == true ||  parseInt(item.kitchenid) == parseInt(kit.kitchenid))){
                    kitchenunsentitems.push(item);
                }
            }

            //Items found for kitchen 
            if(kitchenunsentitems.length  > 0){
                    
                    var kot = <Kot>{};
                    kot.printedinkitchen = true; 
                    kot.printerip = '';
                    var newtoken = await this.getNewKOTToken();
                    
                    if(newtoken == 'ERR') return false; 
                    
                    kot.kottoken = newtoken;
                    kot.id = new Date().getTime();
                    kot.lineitems = kitchenunsentitems;
                    kot.kitchenname = kit.name;
                    kot.kitchenid = kit.id;
                    kot.counterid = this.counterService.selectedcounter.id;
                    kot.countername = this.counterService.selectedcounter.countername;
                    kot.ctrremarks = this.counterService.selectedcounter.remarks;

                    if(this.counterService.selectedcounter.alias && this.counterService.selectedcounter.alias.length > 0){
                        kotConsolidated.countername =kotConsolidated.countername + ' (' + this.counterService.selectedcounter.alias + ')';
                    }
                    kot.posid = this.dataService.getPosId();
                    kot.servedby = this.counterService.selectedcounter.servedby;
                    kot.servedbystr = this.counterService.selectedcounter.servedbystr;
                    
                    kot.bsndate = this.dataService.getCurrentBusinessDate();
                    kot.sessionid = this.counterService.selectedcounter.id + "|" + this.counterService.selectedcounter.sessionid;
                    
                    var status = await this.saveKOT(kot);
                    if(status){
                        this.markKOTItemsAsSent(kot,cart,kit.id);

                        if( koton == 0)
                            await this.printKOTInKitchen(kot, kit,false);
                        else if(koton > 0)
                            this.printKOTOnPrintter(kot, koton,false);
                           
                        if(dupkoton >= 0 && consolidatedkot == false ){
                            if( dupkoton == 0)
                                this.printKOTInKitchen(kot, kit,true);
                            else if(dupkoton > 0)
                                this.printKOTOnPrintter(kot, dupkoton,true);
                            
                        }    

                        if(consolidatedkot){
                            if(kotConsolidated.kottoken.length > 0) kotConsolidated.kottoken = kotConsolidated.kottoken  + ', ';
                            
                            kotConsolidated.kottoken = kotConsolidated.kottoken  + kot.kottoken;
                            for(var item of kot.lineitems){
                                kotConsolidated.lineitems.push(item);
                            }
                        }
                        
                    }else{
                        return false; 
                    }

            }

        }

        if(consolidatedkot){
            this.printKOTOnPrintter(kotConsolidated,dupkoton,true);
        }
        
        cart.lineitems = [... cart.lineitems];

        //Set the unsent status 
        this.counterService.selectedcounter.unsentitems = this.checkUnsent(this.counterService.selectedcounter.cart);

        //Save the counter 
        await this.counterService.saveSelectedCounter();

        //this.counterService.saveSelectedCounterToOffline();
        return true;
    }




    async generateCCKOTs(order : Order,counter : Billingcounter ) 
    {
       var cart = order.cart;

       if(!this.checkUnsent(cart)) return 'ok';
       
       var consolidatedkot = false; 
       var curDateTime = new Date();
       var  dupkoton =  counter.dupkoton;
   
       var kotConsolidated = <Kot>{} //This var shall store the consolidated KOT 
       kotConsolidated.lineitems =[];
       kotConsolidated.kottoken = '';
       kotConsolidated.id = curDateTime.getTime();
       kotConsolidated.kitchenname = 'Consolidated';
       kotConsolidated.printerid = 0;
       kotConsolidated.countername = order.countername;
       kotConsolidated.countername = order.countername;
       kotConsolidated.ctrremarks = order.orderremarks;
       kotConsolidated.agentname = order.billedbystr;
       kotConsolidated.aggrref = order.aggrref;

       if(dupkoton > 0 && counter.dupkotconsolidated){
           consolidatedkot = true;
       }    

       //Set the unsent items 
       var allunsentitems : Cartlineitem[] = [];
       for(var item of cart.lineitems){
           //console.log('Item kitchen id ' + item.kitchenid + ' KIT ID ' + kit.kitchenid);
           if(item.kitstatus == Constants.UNSENT){
               allunsentitems.push(item);
           }
       }

       //Now lets move through the kitchen list and generate KOT for each kitchen
       for (var kit of this.kitchenlist)
       {   
           //If any kitchen has print all, then also mark consolidated KOT as true
           if(kit.printall) consolidatedkot = true; 
           
           var kitchenunsentitems : Cartlineitem[] = [];
           for(var item of cart.lineitems){
               if(item.kitstatus == Constants.UNSENT && parseInt(item.kitchenid) == parseInt(kit.kitchenid)){
                   kitchenunsentitems.push(item);
               }
           }

          //Items found for kitchen 
           if(kitchenunsentitems.length  > 0){

                   var kot = <Kot>{};
                   kot.printedinkitchen = true; 
                   kot.printerip = '';
                   var newtoken = await this.getNewKOTToken();
                   if(newtoken == 'ERR'){
                       cart.lineitems = [... cart.lineitems];
                       return "Error generating KOT token, please try again!";
                   } 
                           
                   kot.kottoken = newtoken;
                   kot.id = new Date().getTime();
                   kot.lineitems = kitchenunsentitems;
                   kot.kitchenname = kit.name;
                   kot.kitchenid = kit.id;
                   
                   kot.counterid = order.counterid;
                   kot.countername = order.countername;
                   kot.agentname = order.billedbystr;
                   kot.ctrremarks = order.orderremarks;
                   kot.posid = this.dataService.getPosId();
                   //kot.servedby = this.counterService.selectedcounter.servedby;
                   //kot.servedbystr = this.counterService.selectedcounter.servedbystr;
                   
                   var curuser = this.userService.loggedinuser;
                   kot.optrid = 0;
                   if(curuser) kot.optrid = curuser.id;

                   kot.bsndate = this.dataService.getCurrentBusinessDate();
                   kot.sessionid = order.counterid + "|" + order.id;

                           
                   var status = await this.saveKOT(kot);
                   if(status){
                        this.markKOTItemsAsSent(kot,cart,kit.id);
                        if(!kit.printall) {
                            kot.printerid = kit.printerid;
                            console.log('CTRREMARKS-->2' + kot.ctrremarks);
                            this.printKOTOnPrintter(kot,kit.printerid,false);
                        }
                        
                        if(consolidatedkot){
                            if(kotConsolidated.kottoken.length > 0) kotConsolidated.kottoken = kotConsolidated.kottoken  + ', ';
                            
                            kotConsolidated.kottoken = kotConsolidated.kottoken  + kot.kottoken;
                            for(var item of kot.lineitems){
                                kotConsolidated.lineitems.push(item);
                            }
                        }


                   }else{
                       return "Error saving KOT, please try again!";
                   }
            }
       
       }

       cart.lineitems = [... cart.lineitems];
       if(consolidatedkot){
           if(dupkoton > 0 && counter.dupkotconsolidated){
               this.printKOTOnPrintter(kotConsolidated,dupkoton,false);
           }

           for(var kit of this.kitchenlist){
               if(kit.printall){
                   kotConsolidated.kitchenname = 'Consolidated for ' + kit.name;
                   this.printKOTOnPrintter(kotConsolidated,kit.printerid,false);
               }
           }
       }
       return "ok"; 

    }





    playAudio(){
        try{
        let audio = new Audio();
        audio.src = "assets/demo/images/readyalert.mp3";
        audio.load();
        audio.play();
        }catch(err){
            console.log('|SOUND|Error playing sound ' + err);
        }
    }




    async cancelKot(kot : Kot, reason,remarks,  counter : Billingcounter,foodprepared,cancelby) {
        var issuccess = false;    

        var key  = {"posid": "" + this.dataService.getPosId(), "id" : kot.id};
        var retkot;
        console.log('KOT key : ' + JSON.stringify(key));

        if(this.dataService.usedynamo){
            retkot = <Kot> await this.dynamoDBService.getItem('kots',key);
        }else
            retkot = <Kot> await this.couchdbService.getItem('kots',kot.id);


        if(retkot){
            retkot.cancellationreason = reason;
            retkot.remarks = remarks;
            retkot.iscancelled = true; 
            if(counter.proformaid > 0) retkot.cancelpostproforma = true;  
            retkot.foodprepared = foodprepared;
            retkot.updatedon = new Date().getTime();
            retkot.cancelby = cancelby;

            var curuser = this.userService.loggedinuser;
            retkot.voidoptrid = 0;
            if(curuser) retkot.voidoptrid = curuser.id;

            
            var ret =await this.saveKOT(retkot);

            if(ret){
                var kit = this.getKitchenForId(kot.kitchenid);
                if(kit) this.printKOTInKitchen(retkot,kit,false);

                var printerid = counter.receipton;
                if(printerid > 0)
                    this.printKOTOnPrintter(retkot,printerid,false);

                return true; 
            }else{
                return false;
            }
        }
        return issuccess;
        

    }

    async cancelItem(item : Cartlineitem, reason, remarks, counter : Billingcounter,foodprepared,cancelqty,cancelby) {
        var issuccess = false;    
        var key  = {"posid": "" + this.dataService.getPosId(), "id" : item.kottime};
        var retkot;
        if(this.dataService.usedynamo){
            retkot = <Kot> await this.dynamoDBService.getItem('kots',key);
        }else
            retkot = <Kot> await this.couchdbService.getItem('kots',item.kottime);

        if(retkot){
            for(var litem of retkot.lineitems){
                if(litem.createdon == item.createdon){
                    litem.cancelreason = reason; 
                    litem.remarks = remarks;
                    litem.cancelby = cancelby;

                    if(litem.cancelqty && parseInt(litem.cancelqty) > 0)
                        litem.cancelqty = litem.cancelqty + cancelqty;
                    else
                        litem.cancelqty = cancelqty;
                        

                    if(litem.quantity == litem.cancelqty){ 
                        litem.cancelled = true;
                        litem.quantity = litem.quantity - cancelqty;
                    }else
                        litem.quantity = litem.quantity - cancelqty;
                    
                    var curuser = this.userService.loggedinuser;
                    litem.voidoptrid = 0;
                    if(curuser) litem.voidoptrid = curuser.id;

                    if(counter.proformaid > 0) litem.cancelledpp = true; 
                    litem.foodprepared = foodprepared;
                    retkot.updatedon = new Date().getTime();

                    var ret =await this.saveKOT(retkot);

                    if(ret){
                        var kit = this.getKitchenForId(item.kitchenid);
                        var cancelKOT = <Kot>{};
                        cancelKOT.lineitems = [];
                        cancelKOT.lineitems.push(litem);
                        cancelKOT.cancellationreason = reason;
                        cancelKOT.iscancelled = true; 
                        cancelKOT.countername = retkot.countername;
                        cancelKOT.kottoken = retkot.kottoken;

                        cancelKOT.id =  retkot.id;
                        cancelKOT.kitchenname = retkot.kitchenname;
                        

                        if(kit) this.printKOTInKitchen(cancelKOT,kit,false);

                        var printerid = counter.receipton;
                        if(printerid > 0)
                            this.printKOTOnPrintter(cancelKOT,printerid,false);

                        return true; 
                    }else{
                        return false;
                    }
                }
            }
            
        }


        return issuccess;
        

        
        
    }

    //Service for reports 
    async getKotList(fromdate, todate){
        try{
            //this.billingcounterlist = [];
            var retlist; 
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and bsndate BETWEEN :v_start and :v_end ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_start":  fromdate , ":v_end" : todate};
                return await this.dynamoDBService.queryOnIndex('kots', "posid-bsndate-index", conditionexpression,attribparams);
            }else{
                var selector =  {"bsndate": { "$gte": fromdate, "$lte" : todate}};
                retlist = await this.couchdbService.getFromIndex('kots',selector);
                if(retlist && retlist.docs){
                    return retlist.docs;
                }else 
                    return null;
            }
            
            
            
        }catch(err){
            console.error('ERror fetching KOTs ' + err);
            return null;
        }
    }

        //Service for reports 
        async getLastKotList(){
            try{
                //this.billingcounterlist = [];
                var retlist; 
                if(this.dataService.usedynamo){
                    var conditionexpression = "posid = :v_retailerid and bsndate = :v_bsndate";  
                    var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_bsndate":  this.dataService.getCurrentBusinessDate()};
                    return await this.dynamoDBService.queryOnIndexDesc('kots', "posid-bsndate-index", conditionexpression,attribparams,30);
                }else{
                    var fromdate = this.dataService.getCurrentBusinessDate();
                    var todate = this.dataService.getCurrentBusinessDate();
                    
                    var selector =  {"bsndate": { "$gte": fromdate, "$lte" : todate}};
                    var sort =  [{"id": "desc"}];
                    retlist = await this.couchdbService.getFromIndex('kots',selector,sort, 30);
                    if(retlist && retlist.docs){
                        return retlist.docs;
                    }else 
                        return null;
                }
                
                
                
            }catch(err){
                console.error('ERror fetching KOTs ' + err);
                return null;
            }
        }
    

 
    //Vars for KDS 
    kotsinprocess : Kot[];


 
    //******************* FUNCTONS FOR KDS *******************/
    lastupdatedon; //Last updated on timestamp

    startKOTPollingOnKDS(){
        try{
            //Initialize polling vars 
            if(!this.kotsinprocess) this.kotsinprocess = [];

            //start polling for last 8 hrs. 
            if(!this.lastupdatedon) this.lastupdatedon = new Date().getTime() - (8*60*60*1000);
            
            this.getUpdatedKOTs();
            
            //This refresh every 5 seconds 
            setTimeout(()=>{this.startKOTPollingOnKDS()},5000);
        }catch(err){}
    }
    
    kitchenid; 
    kitchenname;

    setKitchenName(){
        if(this.kitchenlist){
            for(var kit of this.kitchenlist){
                if(kit.id == this.kitchenid)
                    this.kitchenname = kit.name; 
            }

        }
    }
    async getUpdatedKOTs(){
        try{
            //this.billingcounterlist = [];
            //Get current KOTs with updated timestamp 

            var newlist; 
            if(this.dataService.usedynamo){
                var attribparams= {":v_retailerid" : this.dataService.getPosId(),":v_updatedon": this.lastupdatedon};
                newlist  = await this.dynamoDBService.queryOnIndex("kots", "posid-updatedon-index", "posid = :v_retailerid and updatedon > :v_updatedon ",attribparams);
            }else{
                var selector =  {"bsndate" : {"$eq": this.dataService.getCurrentBusinessDate()},  "updatedon": { "$gt": this.lastupdatedon}};
                if(Constants.DEBUG_MODE) console.log('|KDS|LIVE REFRSH|Fetching from couch from --> ' + this.lastupdatedon);
                var retlist = await this.couchdbService.getFromIndex('kots',selector);
                if(retlist && retlist.docs){
                    newlist = <Kot[]> retlist.docs;
                }
            
            }


            
            if(newlist){
                if(Constants.DEBUG_MODE) console.log('|KDS|KIT ID-->' + this.kitchenid);
                //var newlist = <Kot[]> retlist.docs;
        
                for(var kot of newlist){
                    kot.servedbystr = this.userService.getUserNameForId(kot.servedby);
                    
                    if(!kot.foodprepared && kot.kitchenid == this.kitchenid){
                        var kotfound = false; 
                        
                        for(var i=0;i<this.kotsinprocess.length;i++){
                            if(this.kotsinprocess[i].id == kot.id){
                                //update the KOT in the list 
                                this.kotsinprocess[i] = kot; 
                                kotfound = true; 
                                break;
                            }
                        }
                        
                        if(!kotfound){
                            this.kotsinprocess.push(kot);
                            this.playAudio();
                            this.playAudio();
                            
                        }
                    }

                    
                    
                    if(kot.updatedon > this.lastupdatedon) this.lastupdatedon = kot.updatedon;
                }

                return true;
            }else{
                return false;
            }


        }catch(err){

        }

     }

     checkAllReady(kot : Kot){
         for(var item of kot.lineitems){
             if(!item.cancelled && item.kitstatus != Constants.READY_AT_KITCHEN) return false; 
         }
         return true; 
     }

     removeKOT(kot :  Kot){
        if(Constants.DEBUG_MODE) console.log('**** In KOT Removal --> ' + kot.kottoken);
        for(var i = 0; i < this.kotsinprocess.length; i++){
            var curKot = this.kotsinprocess[i];
            if(curKot.id === kot.id){
                this.kotsinprocess.splice(i,1);
                this.kotsinprocess = [... this.kotsinprocess];
            }
        }
    }

    updateInProcessList(updatedKot){
        for(var i = 0; i < this.kotsinprocess.length; i++){
            var curKot = this.kotsinprocess[i];
            if(curKot.id === updatedKot.id){
                console.log('|KDS| updating kot with id ' + curKot.id + ' JSON ' + JSON.stringify(updatedKot));
                this.kotsinprocess[i] = updatedKot;
                this.kotsinprocess = [... this.kotsinprocess];
            }
        }   
    }

    async updateQROrders(kot : Kot){
        for(var item of kot.lineitems){
            console.log('|KDS|QR Ready for item ' + item.qrid);
            
            if(item.kitstatus == Constants.READY_AT_KITCHEN && item.qrid && item.qrid > 0){
                var qrorder : Tableorder;

                if(this.dataService.usedynamo){
                    var key  = {"posid": "" + this.dataService.getPosId(), "id" : item.qrid};
                    qrorder   = <Tableorder> await this.dynamoDBService.getItem('tableorders', key);
                }else
                    qrorder = <Tableorder> await this.couchdbService.getItem('tableorders', item.qrid);

                if(qrorder){
                    if(qrorder.status != Constants.QR_ORD_READY_AT_KITCHEN){

                        qrorder.status = Constants.QR_ORD_READY_AT_KITCHEN;
                        if(this.dataService.usedynamo){
                            qrorder.ordstatus = qrorder.status;
                            await this.dynamoDBService.putItem('tableorders',qrorder);
                        }else
                            await this.couchdbService.putItem('tableorders',qrorder);
                    }
                }
            }
        }
    }

     async markFoodReady(kot : Kot){
        for(var readyitem of kot.arrselecteditems){
            for(var item of kot.lineitems){
                if(!item.cancelled && item.kitstatus != Constants.READY_AT_KITCHEN && item.kitstatus != Constants.SERVED && readyitem.createdon == item.createdon){
                        item.kitstatus = Constants.READY_AT_KITCHEN;

                        var timeelapsed =  new Date().getTime() - kot.id;
                        timeelapsed = Math.round( timeelapsed / (60*1000));
                        item.preptime= timeelapsed;
                }
            }
         }

         if(this.checkAllReady(kot)) kot.foodprepared = true; 
         kot.arrselecteditems = []; //remove the selected items 
            
         var ret = await this.saveKOT(kot);
         if(ret){
             if(kot.foodprepared) this.removeKOT(kot);
             else{
                 this.updateInProcessList(kot);
             }

             //update QR Orders
             console.log('QR Procesisng: ' + this.dataService.retailer.appconfig.posconfig.qrprocessing);
             if(this.dataService.retailer.appconfig.posconfig.qrprocessing) this.updateQROrders(kot);

             //Now update the billing counter. 
             this.counterService.setKitchenUpdate(kot);

         }
         return ret; 
         
     }


     async getKotsForSession(sessionid){
        try{
            var retlist; 
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and sessionid = :v_sessionid";  
                console.log('|SYNCTEST|conditionexpression ' + conditionexpression);

                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_sessionid": sessionid};

                console.log('|SYNCTEST|attribparams ' + JSON.stringify(attribparams));

                return await this.dynamoDBService.queryOnIndex('kots', "posid-sessionid-index", conditionexpression,attribparams);
            }else{
                var selector =  {"sessionid": { "$eq": sessionid}};
                
                retlist = await this.couchdbService.getFromIndex('kots',selector);
                if(retlist && retlist.docs){
                    return retlist.docs;
                }else 
                    return null;
            }
            
        }catch(err){
            console.error('ERror fetching KOTs ' + err);
            return null;
        }
    }


    checkItemOnCounter(itemid){
        for(var item of this.counterService.selectedcounter.cart.lineitems){
            if(item.createdon == itemid)
                return true;
        }
        return false; 
    }
    

    async checkTransferItem(itemid,sessionid){

        var key  = {"posid": "" + this.dataService.getPosId(), "itemid" : itemid};
        console.log('|SYNCTEST|TRANSFER Checking for item:  ' + itemid + ' Session ' + sessionid);
        var transferitem = await this.dynamoDBService.getItem('itemtransfers',key);
        if(transferitem) console.log('|SYNCTEST|TRANSFER Found:  ' + JSON.stringify(transferitem));
        
        if(transferitem && transferitem.fromsessionid == sessionid){
            return true; 
        }else{
            console.log('|SYNCTEST|TRANSFER NOT Found:  ' );
            return false; 
        }


    }
    
    async synchronizeSessionKOTs(){

        var sessionid = this.counterService.selectedcounter.id + "|" + this.counterService.selectedcounter.sessionid;
        var sessionkots = <Kot[]> await this.getKotsForSession(sessionid);
        var mismatchfound  = false;
        if(sessionkots){
            for (var kot of sessionkots){
                if(!kot.iscancelled){
                    for (var item of kot.lineitems){

                        console.log('|SYNCTEST|KOT ' + kot.kottoken + ' Item ' + item.productname);
                        //If it has quantity, check if exists on the table
                        if(item.quantity  > 0){
                            if(!this.checkItemOnCounter(item.createdon)){
                                 item.kottime = kot.id;
                                 item.kottoken = kot.kottoken;
                                 if(item.kitstatus == Constants.UNSENT)
                                    item.kitstatus = Constants.RECIEVED_BY_KITCHEN;

                         
                                 var itemid = item.kottoken + "|" + item.createdon;
                        
                                 console.log('|SYNCTEST|Checking in transfer now ' + itemid);
                        
                                 if(!await this.checkTransferItem(itemid,sessionid)){
                                    this.counterService.selectedcounter.cart.lineitems.push(item); 
                                    this.counterService.selectedcounter.cart.recomputeCart();
                                    mismatchfound = true; 
                                 }
                            }
                        }
                    }
                }
            }

            if(mismatchfound){
                var ret = await this.counterService.saveSelectedCounter();
                if(ret != 'DONE'){
                    ret = await this.counterService.saveSelectedCounter();
                    if(ret != 'DONE'){
                        ret = await this.counterService.saveSelectedCounter();
                    }   
                }
                return "You did not have the latest counter data. Please try again!"; 
            }

            
            return "ok";

        }else{
            return "Error fetching counter data, please try again!"; 
        }



    }


    //Service for reports 
    async getUnsyncedKOTsFromCouch(){
        try{
            //this.billingcounterlist = [];
            var selector =  {"synced": { "$eq": false}};
            var retlist = await this.couchdbService.getFromIndex('kots',selector);
            if(retlist && retlist.docs){
                return retlist.docs;
            }else 
                return null;
        }catch(err){
            console.error('ERror fetching Orders ' + err);
            return null;
        }
    }


    async generateKOTFromCC(order : Order)
    {
        var cart = order.cart;
        var consolidatedkot = false; 
        var kotConsolidated = <Kot>{}
        
        
        var curDateTime = new Date();
        var commskitchenlist : Kitchen[] = [];
        var printedkitchenip = "";

        var koton = this.counterService.selectedcounter.koton; 
        var  dupkoton =  this.counterService.selectedcounter.dupkoton;
                        
                        
        if(dupkoton > 0 && this.counterService.selectedcounter.dupkotconsolidated){
            consolidatedkot = true;
            kotConsolidated.lineitems =[];
            kotConsolidated.kottoken = '';
            kotConsolidated.id = curDateTime.getTime();
            kotConsolidated.kitchenname = 'Consolidated';
            kotConsolidated.countername = this.counterService.selectedcounter.countername;
            if(this.counterService.selectedcounter.alias && this.counterService.selectedcounter.alias.length > 0){
                kotConsolidated.countername =kotConsolidated.countername + ' (' + this.counterService.selectedcounter.alias + ')';
            }
        }


        for(var kit of this.kitchenlist){
            commskitchenlist.push(kit);

        }

                
        //Now lets see if we can print thru that
        for (var kit of commskitchenlist)
        {
            console.log('Sending for kitchen ' + kit.name  + 'Num items ' + cart.lineitems.length);
            var kitchenunsentitems : Cartlineitem[] = [];
        
            for(var item of cart.lineitems){
                
                console.log('Item kitchen id ' + item.kitchenid + ' KIT ID ' + kit.kitchenid);

                if(item.kitstatus == Constants.UNSENT && (kit.printall == true ||  parseInt(item.kitchenid) == parseInt(kit.kitchenid))){
                    kitchenunsentitems.push(item);
                }
            }

            //Items found for kitchen 
            if(kitchenunsentitems.length  > 0){
                    
                    var kot = <Kot>{};
                    kot.printedinkitchen = true; 
                    kot.printerip = '';
                    var newtoken = await this.getNewKOTToken();
                    
                    if(newtoken == 'ERR') return false; 
                    
                    kot.kottoken = newtoken;
                    kot.id = new Date().getTime();
                    kot.lineitems = kitchenunsentitems;
                    kot.kitchenname = kit.name;
                    kot.kitchenid = kit.id;
                    kot.counterid = this.counterService.selectedcounter.id;
                    kot.countername = this.counterService.selectedcounter.countername;
                    kot.ctrremarks = this.counterService.selectedcounter.remarks;

                    if(this.counterService.selectedcounter.alias && this.counterService.selectedcounter.alias.length > 0){
                        kotConsolidated.countername =kotConsolidated.countername + ' (' + this.counterService.selectedcounter.alias + ')';
                    }
                    kot.posid = this.dataService.getPosId();
                    kot.servedby = this.counterService.selectedcounter.servedby;
                    kot.servedbystr = this.counterService.selectedcounter.servedbystr;
                    
                    kot.bsndate = this.dataService.getCurrentBusinessDate();
                    kot.sessionid = order.id;
                    
                    var status = await this.saveKOT(kot);
                    if(status){
                        this.markKOTItemsAsSent(kot,cart,kit.id);

                        if( koton == 0)
                            await this.printKOTInKitchen(kot, kit,false);
                        else if(koton > 0)
                            this.printKOTOnPrintter(kot, koton,false);
                           
                        if(dupkoton >= 0 && consolidatedkot == false ){
                            if( dupkoton == 0)
                                this.printKOTInKitchen(kot, kit,true);
                            else if(dupkoton > 0)
                                this.printKOTOnPrintter(kot, dupkoton,true);
                            
                        }    

                        if(consolidatedkot){
                            if(kotConsolidated.kottoken.length > 0) kotConsolidated.kottoken = kotConsolidated.kottoken  + ', ';
                            
                            kotConsolidated.kottoken = kotConsolidated.kottoken  + kot.kottoken;
                            for(var item of kot.lineitems){
                                kotConsolidated.lineitems.push(item);
                            }
                        }
                        
                    }else{
                        return false; 
                    }

            }

        }

        if(consolidatedkot){
            this.printKOTOnPrintter(kotConsolidated,dupkoton,true);
        }
        
        cart.lineitems = [... cart.lineitems];

        //Set the unsent status 
        this.counterService.selectedcounter.unsentitems = this.checkUnsent(this.counterService.selectedcounter.cart);

        //Save the counter 
        await this.counterService.saveSelectedCounter();

        //this.counterService.saveSelectedCounterToOffline();
        return true;
    }
    

 
    

}