import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Appdataservice} from '../service/appdataservice';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';

import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../service/billingservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Orderservice } from '../service/orderservice';
import { Reprintlog } from '../domain/reprintlog';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Printingservice } from '../service/printingservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Printerservice } from '../service/printerservice';

@Component({
    selector: 'reprint-dlg',
    templateUrl: './reprintdialog.html',

})
export class Reprintdialog {
    
    constructor(public billingService : Billingservice,
        private messageService: MessageService,
        public dataService : Appdataservice,
        private dynamoDBService : Dynamodbservice,
        private orderService : Orderservice,
        private printingService : Printingservice,
        private printerService : Printerservice,
        private kitchenService : Kitchenservice,
        public counterService : Billingcounterservice) 
    {

    }

    closeDialog(){
        this.billingService.makeorderDetailDialogVisible = false;
    }

    showspinner; 
    arrreasons;
    foodprepared;
    reprintreason;
    otherdetails; 
    enableother;
    dialogheader;

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false; 
    }

    printofficecopy;

    
    
   async reprint(){
        //this.billingService.setInstructions(this.txtinstructions);
        
        if (!this.reprintreason || this.reprintreason.length == 0){
            this.showErrorToast("Please select reprint reason");
            return;
        }
        

        if(this.reprintreason && this.reprintreason.toUpperCase().indexOf('OTHER') >= 0){
            if(!this.otherdetails || this.otherdetails.length == 0){
                this.showErrorToast("Please provide other reason details.");
                return;
            }
        }

        var reprintlog = <Reprintlog>{};
        reprintlog.posid = this.dataService.getPosId();
        reprintlog.id = new Date().getTime();
        reprintlog.businessdate = this.dataService.getCurrentBusinessDate();
        
        if(this.billingService.reprinttype == 'order'){
            reprintlog.printfor = 'Order';
            reprintlog.orderid = this.billingService.orderForReprint.id;
            reprintlog.reference = this.billingService.orderForReprint.receiptno;
            
            if(this.billingService.orderForReprint.checkedout)
                reprintlog.postcheckout = true;
            
            if(this.billingService.orderForReprint.cart.pendingamount == 0)
                reprintlog.postsettlement = true;
        }else{
            reprintlog.printfor = 'Kot';
            reprintlog.orderid = this.billingService.kotForReprint.id;
            reprintlog.reference = this.billingService.kotForReprint.kottoken;

            
        }

        reprintlog.reason = this.reprintreason;
        reprintlog.details = this.otherdetails;
        this.showspinner = true;
        
        var ret = await this.dynamoDBService.putItem('reprintlog', reprintlog);
        
        if(ret){

            if(ret){
                if(this.billingService.reprinttype == 'order'){
                    document.getElementById("printable").innerHTML =  this.printingService.getReceiptAsHTML(this.billingService.orderForReprint,true,this.printofficecopy);
                   // console.log('|INNER HTML|' + document.getElementById("printable").innerHTML);
                    var order =this.billingService.orderForReprint; 
                    if(order.countertype == Constants.NORMAL && this.dataService.retailer.appconfig.posconfig.fbqronbill){
                        var feedbacklink = "https://feedback.matam.ae/?ord=" + this.dataService.getPosIdForFeedback() + "_1_" + order.id;
                        this.billingService.qrfbstring = feedbacklink;
                        this.billingService.printqr = true; 

                    }else{
                        this.billingService.qrfbstring = ' ';
                        this.billingService.printqr = false; 
                        
                    }

                    /*if(order.countertype == Constants.NORMAL && this.dataService.retailer.appconfig.posconfig.fbqronbill){
                        var chart = document.createElement("img");
                        chart.onload = function () {
                            document.getElementById("printable").appendChild(chart);
                            setTimeout(()=>{ window.print();}, 50);
                        }

                        chart.onerror = function () {
                            //document.getElementById("printable").appendChild(chart);
                            setTimeout(()=>{ window.print();}, 50);
                        }

                        var feedbacklink = "https://feedback.matam.ae/?ord=" + this.dataService.getPosIdForFeedback() + "_1_" + order.id;
                        chart.src = "https://chart.googleapis.com/chart?cht=qr&chs=300x300&choe=UTF-8&chl="+ feedbacklink;
                        //chart.onload()
                        
                    }else{*/
                       // setTimeout(()=>{ window.print();}, 300);
                    //}
                    setTimeout(()=>{ window.print();}, 300);

                    //window.print();
                    

                    
                }else{

                    var kot = this.billingService.kotForReprint;

                    var kit  = this.kitchenService.getKitchen(kot.kitchenname);
                    if(kit && kit.printerid > 0){

                        if(kot.iscancelled){
                            var counter = this.counterService.getBillingcounterForId(kot.counterid);
                            if(counter)
                                await this.kitchenService.printKOTOnPrintter(kot,counter.receipton,true);
                        }
                
                        var printer = this.printerService.getPrinterForId(kit.printerid);
                        if(printer){
                            var retkot  = await this.printingService.generateAndPrintKOT(kot,printer,true,false);
                            if(!retkot) {
                                this.showErrorToast('Error in printing!');                
                            }
                        }
                    }

                }
                this.showspinner = false;
                this.billingService.makeReprintDialogVisible = false; 
            }else{
                this.showErrorToast('Error, please try again!');
            }

        }else{
            this.showErrorToast('Error, please try again!');
        }
        
    }

    setReason(reason){
        this.reprintreason = reason;
    }

    onShow(){
        if(Constants.DEBUG_MODE) console.log('Reprint Dialog shown!');
        this.arrreasons = [];
        var strreasons = this.dataService.retailer.appconfig.posconfig.reprintreasons;
        if(strreasons != null && strreasons.length > 0){
            this.arrreasons = strreasons.split(',');
        }

        if(this.billingService.reprinttype == 'order'){
            this.dialogheader = 'Reprint receipt no: ' + this.billingService.orderForReprint.receiptno;
        }else{
            this.dialogheader = 'Reprint KOT#: ' + this.billingService.kotForReprint.kottoken;
        }
        
        this.reprintreason = '';
        this.otherdetails = '';
    }

    
    ngOnInit(){
        this.printofficecopy = false; 
        
    }


    
}
