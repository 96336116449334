import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Billingservice} from '../service/billingservice';
import {MessageService, SelectItem} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';
import {HostListener} from '@angular/core';
import { Payment } from '../domain/payment';
import { Consumercart } from '../domain/consumercart';
import { Appdataservice } from '../service/appdataservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Billingcounter } from '../domain/billingcounter';
import { Utils } from '../service/utils';
import { Discountdetail } from '../domain/discountdetail';
import { Discountpartnerservice } from '../service/discountpartnerservice';
import { Orderservice } from '../service/orderservice';
import { Printerservice } from '../service/printerservice';
import { Printingservice } from '../service/printingservice';
import { Order } from '../domain/order';
import { Dynamodbservice } from '../service/dynamodbservice';

@Component({
    selector: 'payment-dlg',
    templateUrl: './paymentdialog.html',

})
export class Paymentdialog {
    
    ctr : Billingcounter;
    tenderamount = 0;
    returnamount = 0;
    

    selectedPayment : Payment;
    forexoptions : SelectItem[];
    cardtypes : SelectItem[];
    complimentarytypes : SelectItem[];
    othercredittypes : SelectItem[];
    

    chosencurrency; 
    forexPayment : Payment;
    cardPayment : Payment;

    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                public dataService : Appdataservice,
                public orderService : Orderservice,
                private printerService : Printerservice,
                private printingService : Printingservice,
                private dynamoService : Dynamodbservice,
        
                public partnerService : Discountpartnerservice,
                public counterService : Billingcounterservice) {}


    
    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        /*if(this.billingService.makecustomerdialogvisible){
            if(event.keyCode == 13  ) {
                  if(this.iscustomerset ) this.closeDialog();
                  else this.setCustomer();
            }
        }*/
    }

    async setPaymentOnCounter(){
        if(this.billingService.pymtfromuporder){
            this.billingService.orderForPayment.cart = this.cart;
            this.billingService.upmanagementinstance.markCompleted();
            this.closeDialog();
        }else if(this.billingService.pymtfromcallcenterorder){
            this.billingService.orderForPayment.cart = this.cart;
            this.billingService.ccmanagementinstance.markCompleted();
            this.closeDialog();
        }else if(this.billingService.pymtfromordermanagement){
            this.billingService.orderForPayment.cart = this.cart;
            this.showspinner = true; 
            var ret = await this.orderService.updateOrderInDB(this.billingService.orderForPayment);
            
            if(!ret){
                this.showErrorToast('Error in updating payment, please try again!')
            }else{
                if(this.billingService.orderForPayment && this.billingService.orderForPayment.cart && this.billingService.orderForPayment.cart.pendingamount <= 0){
                    this.counterService.sendSettlementToDVR(this.billingService.orderForPayment);
                }

                //This is a callcenter order.. being updated from this 
                if(this.billingService.orderForPayment.ccordid && this.billingService.orderForPayment.ccordid > 0 ){
                    var key  = {"posid": "" + this.billingService.orderForPayment.posid, "id" : this.billingService.orderForPayment.ccordid};
                    var ccorder  = <Order> await this.dynamoService.getItem('callcenterorders',key);
                    if(ccorder){
                        ccorder.cart = this.billingService.orderForPayment.cart;
                        await this.dynamoService.putItem('callcenterorders',ccorder);
                    }
                }

                await this.billingService.ordermanagementInstance.setData(null);
                this.showspinner = false; 
                this.closeDialog();
            }
            this.showspinner = false; 
        }else{  

            if(this.counterService.isFastCheckOut()){
                if(this.counterService.selectedcounter.cart.pendingamount > 0){
                    this.showErrorToast('Settlement pending for this counter, please provide full settlement!');
                    return; 
                }else{
                    this.dataService.billdialoginstance.checkout(false);
                    this.closeDialog();
                }

            }else{
                this.closeDialog();
            }
        }
    }

    
    clearTender(){
        this.tenderamount =0 ;
        this.returnamount =0 ;
    }

    closeDialog(){
        this.billingService.makepaymentdialogvisible = false;
    }

    getHeight(){
        if(Constants.DEBUG_MODE) console.log(window.screen.height - 20);
        return window.screen.height - 20;
    }

    cart;

    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');
        if(this.billingService.pymtfromordermanagement){
            this.ctr = this.counterService.getCloneCounter(this.billingService.orderForPayment.counterid,this.billingService.orderForPayment.cart);
            this.cart = this.ctr.cart;
        }else if(this.billingService.pymtfromcallcenterorder || this.billingService.pymtfromuporder){
            this.cart = this.counterService.cloneCart( this.billingService.orderForPayment.cart);
        }else{
            console.log('HEREEEEE ...');
            this.ctr = this.counterService.selectedcounter;
            this.cart = this.ctr.cart;
        }

        //if(Constants.DEBUG_MODE) console.log('Cart payable -- > ' + this.cart.netpayable + ' For counter --> ' + this.billingService.orderForPayment.counterid);
        this.tenderamount = 0;
        this.returnamount = 0;
        this.selectedPayment = null;
        
    }
    
    async deletePayment(){
        if(this.selectedPayment != null){
            var pymtIndex = this.cart.paymentlist.indexOf(this.selectedPayment);
            if(pymtIndex >= 0){
                this.showspinner = true; 
                if(this.billingService.pymtfromcallcenterorder || this.billingService.pymtfromuporder){
                    this.cart.paymentlist.splice(pymtIndex,1);
                    this.cart.paymentlist = [... this.cart.paymentlist];
                    this.cart.setPaidAndRemainingAmount();
                    this.selectedPayment = null;
                }else if(this.billingService.pymtfromordermanagement){
                    this.cart.paymentlist.splice(pymtIndex,1);
                    this.cart.paymentlist = [... this.cart.paymentlist];
                    this.cart.setPaidAndRemainingAmount();
                    this.counterService.setPaymentString(this.ctr);
                    this.selectedPayment = null;
                    
                }else{
                    if(!await this.counterService.checkAndGetLatest()){
                        this.showspinner = false; 
                        this.messageService.clear();
                        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'You did not have the updated counter data, it is refreshed now, please try again!'});
                        this.billingService.makepaymentdialogvisible = false; 
                        return;
                    }

                    this.cart.paymentlist.splice(pymtIndex,1);
                    this.cart.paymentlist = [... this.cart.paymentlist];
                    this.cart.setPaidAndRemainingAmount();
                    this.counterService.setPaymentString(this.ctr);
                    this.selectedPayment = null;
                    await this.counterService.saveCounter(this.ctr);
                }
                this.showspinner = false; 
            }
        }
    }

    displayforexdialog;
    displaycarddialog;
    displayonlinedialog; 


    calcReturn(){
        
        if(this.forexPayment.tenderamount && !isNaN(this.forexPayment.tenderamount)){
            var cur = this.dataService.getCurrencyByCode(this.forexPayment.tendercurrency);
            if(cur){
                this.forexPayment.paidamount = Utils.round(this.forexPayment.tenderamount * cur.rate,2); 
                if(this.forexPayment.paidamount > this.cart.pendingamount){
                    this.forexPayment.returnamount = Utils.format(this.forexPayment.paidamount - this.cart.pendingamount,2);
                }else{
                    this.forexPayment.returnamount =0;
                }

                this.forexPayment.currencyrate =  Utils.format(cur.rate,2);
            }else{
                this.forexPayment.returnamount =0;
            }
        }else{
            this.forexPayment.paidamount = 0;
        }
    }

    addForexPayment(){

        if(!this.forexPayment.tenderamount || isNaN(this.forexPayment.tenderamount) || this.forexPayment.tenderamount <0 ){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'Invalid tender amount!'});
            return false;

        }else{
                
            if(this.cart.pendingamount <= 0 ){
                this.messageService.clear();
                this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'The order is fully paid!'});
                return false;
            }

            var payment = <Payment>{};
            if(this.forexPayment.paidamount > this.cart.netpayable) 
                payment.paidamount = this.cart.netpayable;
            else
                payment.paidamount = this.forexPayment.paidamount;

            payment.tenderamount = this.forexPayment.tenderamount;
            payment.returnamount = this.forexPayment.returnamount;
            payment.currencyrate = this.forexPayment.currencyrate;
            payment.tendercurrency = this.forexPayment.tendercurrency;

            payment.paymentmode = Constants.PYMT_FOREX;
            payment.paymentmodename = Constants.getPymtModeName(Constants.PYMT_FOREX);
         
         
            this.addPaymentOnCounter(payment);
            this.displayforexdialog = false;
        }

    }


    addCardPayment(){
        
        if(this.cardPayment.paymentmode == Constants.PYMT_ZOMATO_PRO){
            if(this.cart.paymentlist && this.cart.paymentlist.length > 0){
                this.showErrorToast('Zomato Pro has to be only payment mode, please delete other payments!');
                return false; 
            }

            
            if(!this.cardPayment.discount || isNaN(this.cardPayment.discount)){
                this.showErrorToast('Please enter valid Zomato Pro Discount');
                return false; 
            }

            if(this.cardPayment.discount > this.cardPayment.paidamount){
                this.showErrorToast('Please enter valid Zomato Pro Discount');
                return false; 
            }
        }

        
        if(this.cardPayment.paymentmode == Constants.PYMT_CARD){
            
            var cardno = "" + this.cardPayment.cardno;

            if(!cardno || isNaN(parseInt(cardno)) || cardno.trim().length != 4){
                this.showErrorToast('Please enter last 4 digits of card no');
                return false; 
            }

            console.log('this.cardPayment.tip -->' + this.cardPayment.tip + '<-- Is NAN ' + isNaN(this.cardPayment.tip));
            var slipno = this.cardPayment.pymtreference;
            if(!slipno || slipno.trim().length == 0){
                this.showErrorToast('Please enter the card slip no');
                return false; 
            }


            var strtip = '' + this.cardPayment.tip;
            if( strtip == ''|| isNaN(parseFloat(strtip))){
                this.showErrorToast('Please enter valid tip. You can enter 0 here.');
                return false; 
            }
        }

        if(this.cardPayment.paymentmode == Constants.PYMT_OTHER_CREDIT){
            if(!this.cardPayment.remarks || this.cardPayment.remarks.trim().length == 0){
                this.showErrorToast('Please enter other credit remarks');
                return false; 
            }
        }
        



        if(!this.cardPayment.paidamount || isNaN(this.cardPayment.paidamount) || this.cardPayment.paidamount <0 ){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'Invalid paid amount!'});
            return false;

        }       

        var strpaidamount = "" + this.cardPayment.paidamount;
        this.cardPayment.paidamount = Utils.round(parseFloat(strpaidamount),2);

        if(this.cardPayment.paidamount < 0){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'Invalid paid amount!'});
            return false;
        }
        

        if(this.cart.pendingamount <= 0 ){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'The order is fully paid!'});
            return false;
        }

        if(this.cardPayment.paidamount > this.cart.pendingamount ){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'Paid amount can not be greater than payable amount: ' + this.cart.pendingamount});
            return false;
        }


        //For Zomato Pro, Settle the discount!
        if(this.cardPayment.paymentmode == Constants.PYMT_ZOMATO_PRO){
            //Reverse the discount 
            this.cardPayment.discount = Utils.round(this.cardPayment.discount/ 1.05,2);
            
            //find out the percentage 
            var discper = Utils.round(this.cardPayment.discount/this.cart.sumitemgross*100,3);

            
            //Apply now
            var len = this.cart.lineitems.length;
            for(var i =0; i<  len;i++){
                var item = this.cart.lineitems[i];
                this.counterService.applyItemDiscount(item,discper);
            }

            var discdetail = <Discountdetail>{};

            for (var partner of this.partnerService.discountpartnerlist){
                if(partner.name == 'Zomato Pro'){
                    discdetail.partnerid = partner.id;
                }
            }
            discdetail.discounttype = "2";
            discdetail.discountvalue = this.cardPayment.discount; 
            discdetail.remarks = 'Payment through Zomato Pro';
            discdetail.applicablefor = Constants.DISC_FOR_ALL_ITEMS;
            discdetail.appliedon = [];
            
            this.cart.discountdetail = discdetail;
            

            //Now find the revised paid amount 
            this.cardPayment.paidamount = this.cart.netpayable;

        }

        
        this.addPaymentOnCounter(this.cardPayment);
        this.displaycarddialog= false;

    }




    showspinner; 

    async setPayment(pymtMode)
    {
    
        for(var pymt of this.cart.paymentlist){
            if(pymt.paymentmode == pymtMode){
                this.showErrorToast('This payment mode is already added!');
                this.tenderamount = 0;
                this.returnamount = 0;
                return false;
            }
        }

        if(this.cart.pendingamount <= 0 ){
            this.showErrorToast('The order is fully paid!');
            this.tenderamount = 0;
            this.returnamount = 0;
            return false;
        }

        if(pymtMode == Constants.PYMT_FOREX){
            this.forexPayment = <Payment>{};
            if(this.dataService.currencyratelist && this.dataService.currencyratelist.length > 0)
            this.forexPayment.tendercurrency = this.dataService.currencyratelist[0].currencycode;
            this.forexPayment.returnamount =0;
            this.displayforexdialog = true; 
            return;
        }

        if(pymtMode == Constants.PYMT_CARD || pymtMode == Constants.PYMT_COMPLIMENTARY|| pymtMode == Constants.PYMT_PG || pymtMode == Constants.PYMT_UPI 
            || pymtMode == Constants.PYMT_PAYTM || pymtMode == Constants.PYMT_TP_WALLET ||  pymtMode == Constants.PYMT_CHEQUE ||  pymtMode == Constants.PYMT_AGGREGATOR ||
            pymtMode == Constants.PYMT_ZOMATO_PRO ||  pymtMode == Constants.PYMT_OTHER_CREDIT ){

            
            this.cardPayment = <Payment>{};
            this.cardPayment.paidamount = this.cart.pendingamount;
            this.cardPayment.paymentmode = pymtMode;
            this.cardPayment.paymentmodename = Constants.getPymtModeName(pymtMode);
            if(pymtMode == Constants.PYMT_CARD){
                this.cardPayment.cardtype = this.cardtypes[0].value;    
            }

            if(pymtMode == Constants.PYMT_CARD){
                this.cardPayment.cardtype = this.cardtypes[0].value;    
            }
            if(pymtMode == Constants.PYMT_COMPLIMENTARY){
                this.cardPayment.complimentarytype = this.complimentarytypes[0].value;    
            }
            
            if(pymtMode == Constants.PYMT_OTHER_CREDIT){
                this.cardPayment.othercredittype = this.othercredittypes[0].value;    
            }
            
            this.cardPayment.tip = 0;
            this.displaycarddialog = true; 
            
            return;
        }


        if(Constants.DEBUG_MODE) console.log('Pending amount ' + this.cart.pendingamount);
        var paidamount = this.cart.pendingamount;


        //If no tender, then full tender
        if(this.tenderamount == 0){
            this.tenderamount = this.cart.pendingamount;
        }

        if(this.tenderamount < this.cart.pendingamount) 
            paidamount = this.tenderamount;

        this.returnamount = this.tenderamount - paidamount;    

        var payment = <Payment>{};
        payment.paidamount = paidamount;
        payment.tenderamount = this.tenderamount;
        payment.returnamount = this.returnamount;
        payment.paymentmode = pymtMode;
        payment.paymentmodename = Constants.getPymtModeName(pymtMode);

        
        await this.addPaymentOnCounter(payment);
        
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false; 
    }

    async addPaymentOnCounter(payment : Payment){
        this.showspinner = true; 
        if(this.billingService.pymtfromcallcenterorder || this.billingService.pymtfromuporder){
            if(!payment.id ) payment.id = new Date().getTime();
            if(!this.cart.paymentlist) this.cart.paymentlist = [];
            this.cart.paymentlist.push(payment);
            this.cart.setPaidAndRemainingAmount();
            this.cart.paymentlist = [... this.cart.paymentlist];
        }else if(this.billingService.pymtfromordermanagement){
            if(!payment.id ) payment.id = new Date().getTime();
            if(!this.cart.paymentlist) this.cart.paymentlist = [];
            this.cart.paymentlist.push(payment);
            this.cart.setPaidAndRemainingAmount();
            this.cart.paymentlist = [... this.cart.paymentlist];

            if(payment.paymentmode == Constants.PYMT_CASH){
                var printer = this.printerService.getPrinterForId(this.ctr.receipton);
                if(printer){
                    this.printingService.openCashDrawer(printer);
                }
            }

        }else{
            if(!await this.counterService.checkAndGetLatest()){
                this.showErrorToast('You did not have the updated counter data, it is refreshed now, please try again!');
                this.billingService.makepaymentdialogvisible = false; 
                return;
            }

            await this.counterService.addPaymentOnCounter(this.counterService.selectedcounter, payment);

            if(payment.paymentmode == Constants.PYMT_CASH){
                var printer = this.printerService.getPrinterForId(this.counterService.selectedcounter.receipton);
                if(printer){
                    this.printingService.openCashDrawer(printer);
                }
            }

            

        }
        this.showspinner = false; 
        this.tenderamount = 0;
        this.returnamount = 0;
    
    }

    addToTender(amount){
        this.tenderamount = this.tenderamount + amount; 
        if(this.tenderamount > this.cart.pendingamount)
            this.returnamount = this.tenderamount - this.cart.pendingamount;
        else
            this.returnamount =0;
    }

ngOnInit(){

        this.forexoptions = [];
        for(var cur of this.dataService.currencyratelist){
            this.forexoptions.push({label:cur.currencycode, value:cur.currencycode});
        }

        this.cardtypes = [];
        this.cardtypes.push({label:'Visa', value:'Visa'});
        this.cardtypes.push({label:'Master', value:'Master'});
        if(this.dataService.retailer.appconfig.countrycode == '+91')
            this.cardtypes.push({label:'Rupay', value:'Rupay'});
        this.cardtypes.push({label:'Amex', value:'Amex'});
        this.cardtypes.push({label:'Others', value:'Others'});
        this.cardtypes.push({label:'Touch Points', value:'Touch Points'});
        this.cardtypes.push({label:'Diners', value:'Diners'});


        this.complimentarytypes = [];
        this.complimentarytypes.push({label:'Staff Meal', value:'Staff Meal'});
        this.complimentarytypes.push({label:'Manager Meal', value:'Manager Meal'});
        this.complimentarytypes.push({label:'Group Meal', value:'Group Meal'});
        this.complimentarytypes.push({label:'HO Staff', value:'HO Staff'});
        this.complimentarytypes.push({label:'Management Staff', value:'Management Staff'});
        this.complimentarytypes.push({label:'Maintenance Staff', value:'Maintenance Staff'});
        this.complimentarytypes.push({label:'Drivers', value:'Drivers'});
        this.complimentarytypes.push({label:'Admin & General', value:'Admin & General'});
        this.complimentarytypes.push({label:'Business Promo', value:'Business Promo'});
        this.complimentarytypes.push({label:'Promotion', value:'Promotion'});
        this.complimentarytypes.push({label:'Gift Voucher', value:'Gift Voucher'});
        this.complimentarytypes.push({label:'Guest Compliment', value:'Guest Compliment'});
        
        
        this.othercredittypes = [];
        this.othercredittypes.push({label:'Outlet Staff', value:'Outlet Staff'});
        this.othercredittypes.push({label:'Office Staff', value:'Office Staff'});
        this.othercredittypes.push({label:'Others', value:'Others'});
        this.othercredittypes.push({label:'Room Guest', value:'Room Guest'});
        this.othercredittypes.push({label:'Used Oil Credits', value:'Used Oil Credits'});
        this.othercredittypes.push({label:'Royal Arabian', value:'Royal Arabian'});
        this.othercredittypes.push({label:'58 Fagar voucher', value:'58 Fagar voucher'});
        this.othercredittypes.push({label:'59 Noon Credits', value:'59 Noon Credits'});
        this.othercredittypes.push({label:'60 Eazy Diner Credits', value:'60 Eazy Diner Credits'});
        this.othercredittypes.push({label:'56 Zomato Pro Credits', value:'56 Zomato Pro Credits'});


        
        this.forexPayment  = <Payment>{};
        this.cardPayment = <Payment> {};

        
    }


    
}
