import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Appdataservice} from '../service/appdataservice';
import {Billingservice} from '../service/billingservice';
import {MessageService, SelectItem} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';
import { Constants } from '../domain/constants';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Customerservice } from '../service/customerservice';
import { Customer } from '../domain/customer';
import { Customeraddress } from '../domain/customeraddress';
import { Deliveryboyservice } from '../service/deliveryboyservice';
import { Aggregatorservice } from '../service/aggregatorservice';
import { Order } from '../domain/order';
import { DatePipe } from '@angular/common';
import { Utils } from '../service/utils';
import { Productquantity } from '../domain/productquantity';

@Component({
    selector: 'delivery-dlg',
    templateUrl: './deliverydialog.html',

})
export class Deliverydialog {
    
    customer : Customer; 
    addressline1;
    addressline2;

    
    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                public dataService : Appdataservice,
                public datePipe : DatePipe,
                public deliveryboyService : Deliveryboyservice,
                public aggregatorService : Aggregatorservice,
                public counterService : Billingcounterservice,
                public customerService : Customerservice) {}


                
    txtcustomer; 
    txtgeneric; 
    resultsgeneric;
    resultscustomer; 

    mobilenumber;
    iscustomerset = false;
    deliveryboys : SelectItem[];
    last5orders : Order[];
        
    isfocusonmobile; 
    showorderhistory;
    
    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.billingService.makederliveryDialogVisible){
            if(event.keyCode == 13  ) {
                  this.findCustomer();
            }

            if(event.keyCode == 27){
                this.closeDialog();
            }
        }
    }

    @ViewChild('custname') txtName : ElementRef;
    @ViewChild('custmobile') txtMobile : ElementRef;

    setFocusOnName(){
        this.txtName.nativeElement.focus();
    }
    setFocusOnMobile(){
        this.txtMobile.nativeElement.focus();
        this.isfocusonmobile =true; 
    }

    focusCustomer(){
        console.log('|DELIVERY|Focus customer');
        this.isfocusonmobile = true; 
    }

    blurCustomer(){
        console.log('|DELIVERY|Blur customer');
        this.isfocusonmobile = false; 
    }
    

    validatePhoneNumber(){
        if(!this.customer.mobileno) return;

        this.customer.mobileno = '' + this.customer.mobileno;
        this.customer.mobileno = this.customer.mobileno.trim();

        if(this.dataService.retailer.appconfig.countrycode == '+91'){
            if(this.customer.mobileno.length != 10 ){
                this.showErrorToast('Please provide valid number!');
                return false;
            }
        }else{
            if(this.customer.mobileno.length < 9 ||  this.customer.mobileno.charAt(0) != '0'){
                this.showErrorToast('Please provide valid number. It must be in format of (0x)xxxxxxx (telephones) and (05)xxxxxxxx (mobiles)');
                return false;
            }
            
            if(this.customer.mobileno.indexOf('05') == 0){
                if(this.customer.mobileno.length != 10  ){
                    this.showErrorToast('Please provide valid mobile number. It must be in 05xxxxxxxx format.');
                    return false;
                }
            }else{
                if(this.customer.mobileno.length != 9  ){
                    this.showErrorToast('Please provide valid telephone number. It must be in (0x)xxxxxxxx format.');
                    return false;
                }
            }
        }
        return true;

    }
   

    /*categoryselection : SelectItem[];
    programselection : SelectItem[];
    loyaltyprogegorylist : Posloyaltyprogram[];
    custcategories : Poscustcategory[];


    setCategorySelection() {
        this.categoryselection = [];
        
        for(let i = 0; i < this.dataService.custcategories.length; i++) {
            let cat = this.dataService.custcategories[i];
            this.categoryselection.push({label:cat.catname, value:cat.id});
        }

    }

    setProgramSelection() {
        this.programselection = [];
        
        for(let i = 0; i < this.dataService.loyaltyprograms.length; i++) {
            let prog = this.dataService.loyaltyprograms[i];
            this.programselection.push({label:prog.programname, value:prog.id});
        }
        
    }*/

    showspinner; 
    enableorderhistory; 
    toppurchasestr; 

    addProduct(productid,quantity,toppurchases){

        for(var item of toppurchases){
            if(item.productid == productid){
                item.quantity = item.quantity + quantity;
                return;
            }

        } 
        var prod = this.dataService.getProductForId(productid);
        if(prod){
            var newitem = <Productquantity>{};
            newitem.productid = productid;
            newitem.quantity = quantity;
            newitem.prodname = prod.name;
            toppurchases.push(newitem);
        }
        

    }

    setTopPurchases (){
        this.customer.toppurchases = [];
        for(var ord of this.last5orders){
            for(var item of ord.cart.lineitems){
                this.addProduct(item.productid,item.quantity,this.customer.toppurchases);
            }
        }


        this.customer.toppurchases.sort((a:Productquantity, b: Productquantity)=> (a.quantity)  > (b.quantity)  ? -1 : 1 );
        if(this.customer.toppurchases.length > 5) this.customer.toppurchases.splice(5);


        this.customer.toppurchasestr = this.customerService.getTopPurchasesAsString(this.customer.toppurchases);

    }

    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        ord.orderitemsstr = Utils.itemsAsString(ord);
    }

    async findCustomer(){
        console.log ('*** customer mobile ' + this.customer.mobileno);
        if(!this.validatePhoneNumber()) return;

        this.showspinner = true; 
        this.showorderhistory = false; 
        var cust  = await this.customerService.findCustomer(this.customer.mobileno);
        if(cust){
            
            var lastorders = await this.customerService.getCustomerOrders(cust);

            if(lastorders && lastorders.length > 0){
                lastorders.sort((a:Order, b: Order)=> a.createdon > b.createdon  ? -1 : 1 );
                if(lastorders.length > 5){
                    this.last5orders = [];

                    for(var i=0;i<5;i++){
                        var ord = lastorders[i];
                        this.setDateStrings(ord);
                        this.last5orders.push(ord);
                    }
                }else{
                    for(var order of lastorders) this.setDateStrings(order);
                    this.last5orders = lastorders; 
                }
                this.enableorderhistory = true; 
            }else{

                this.last5orders = [];
                this.enableorderhistory = false; 
            } 
            console.log('|ORDERHISTORY|' + this.last5orders.length);
            
            //var mobile = "" + cust.mobileno;
            //if(mobile.length == 9) mobile = "0" + mobile; 
            this.customer= cust;
            this.setTopPurchases();
            //this.customer.mobileno = mobile;  


        }else{
            console.log('|CUST|Setting to null');
            var curmobile = this.customer.mobileno;
            this.customer = <Customer>{};
            this.customer.mobileno = curmobile;
            this.enableorderhistory = false;
        }

        this.showspinner = false; 
        
    }

    selectedOrder; 
    async saveCustomer(){
        this.showspinner = true; 
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data or the counter was locked for update, please try again!');
            return false;
        }
        if(!this.validatePhoneNumber()){
            return false;
        }

        if(!this.customer.name || this.customer.name.trim().length == 0 ){
            this.showErrorToast('Please provide customer name!');
            return false;
        }
        

        if(!this.customer.addressline1 || this.customer.addressline1.trim().length == 0 ){
            this.showErrorToast('Please provide address line 1!');
            return false;
        }

        var delcharge = "" + this.deliverycharges;
        console.log('Delcharge ' + delcharge);
        if(!delcharge || delcharge == '' || isNaN(parseInt(delcharge))){
            this.showErrorToast('Please provide delivery charges!');
            return false;
        }

        console.log('***** SAVING CUSTOMER **** ');
        if(!this.customer.createdate) this.customer.createdate = this.dataService.getCurrentBusinessDate();
        var ret = await this.customerService.saveCustomer(this.customer);
        if(ret != 'DONE'){
            this.showErrorToast('Error saving customer, please try again!');
            return false; 
        }

        ret  = await this.counterService.setCustomerDeliveryInfo(this.counterService.selectedcounter,this.customer,this.deliveredby,this.deliverycharges);
        if(ret != 'DONE'){
            this.showErrorToast('Error saving customer, please try again!');
            return false; 
        }
        
        this.showspinner  = false;
        return true; 
        
        
    }

    gotoCustomerDetails(){
        this.showorderhistory = false; 
    }
    async repeatOrder(){
        
        var ret = await this.saveCustomer();
        if(!ret) return;

        this.counterService.copyCart(this.selectedOrder.cart);
        this.closeDialog();

        console.log('***** OPENING BILL WINDOW **** ');
        this.billingService.openBillWindow();
    }

    showErrorToast(message){
        this.messageService.clear();
        this.showspinner = false; 
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    async save(){
        
        var ret = await this.saveCustomer();
        if(!ret) return;
        
        this.closeDialog();

        console.log('***** OPENING BILL WINDOW **** ');
        this.billingService.openBillWindow();

    }

    msgs;
    isRunning;

    async clear(){
        this.customer = <Customer>{};
        this.deliveredby = -1;
        this.deliverycharges = 0;
        this.showspinner = true; 
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data, it is refreshed now!');
            return;
        }
        
        await this.counterService.setCustomerDeliveryInfo(this.counterService.selectedcounter, this.customer, this.deliveredby,null);
        this.showspinner  = false;
        

        
    }

    closeDialog(){
        this.billingService.makederliveryDialogVisible = false;
    }

    isnewcustomer= false;

    setDeliveryBoyArray(){
        this.deliveryboys =[];
        if(this.counterService.selectedcounter.countertype == Constants.ONLINE){
            var aggr = this.aggregatorService.getAggregatorNameForId(this.counterService.selectedcounter.aggregatorid);
            this.deliveryboys.push({label: aggr, value: 0});
        }

        for(var boy of this.deliveryboyService.deliveryboylist){
            if(boy.active){
                var boydetails = boy.name + ' (' + boy.mobilenumber + ') ';
                this.deliveryboys.push({label: boydetails, value: boy.id});
                if(this.deliveredby == null){
                    this.deliveredby = boy.id;
                }
            }
        }

    }

    showOrderHistory(){
        this.showorderhistory = true;
    }

    async unlockCustEntry(){
        var ret= await this.counterService.unlockCustomerEntry(this.counterService.selectedcounter);
        console.log('|CUSTUNLOCK|Dialog hide !--> ' + ret);
        
    }
    onHide(){
        console.log('|CUSTLOCK|Dialog hide !');
        if(this.counterService.selectedcounter.custentry == 1){
            this.unlockCustEntry();
            
        }
    }

    onShow(){
        this.showorderhistory = false; 
        if(Constants.DEBUG_MODE) console.log('|DLVRY|Dialog shown!');
        if(this.counterService.selectedcounter.customer && this.counterService.selectedcounter.customer.mobileno){
            this.customer = this.customerService.getClone(this.counterService.selectedcounter.customer);
        }else{
            this.customer = <Customer>{};
        }
        
        /*this.setDeliveryBoyArray();
        if(this.counterService.selectedcounter.deliveryby >= 0 )
            this.deliveredby = this.counterService.selectedcounter.deliveryby;
        
        
        if(this.counterService.selectedcounter.cart.deliverycharges )
            this.deliverycharges = this.counterService.selectedcounter.cart.deliverycharges;*/
        
        if(this.counterService.selectedcounter.countertype == Constants.PARCEL){
            this.deliverycharges = this.dataService.retailer.appconfig.posconfig.normaldeliverycharge;
        }else if(this.counterService.selectedcounter.countertype == Constants.ONLINE){
            this.deliverycharges =  this.aggregatorService.getDeliveryChargeForAggregator(this.counterService.selectedcounter.aggregatorid);
        }else if(this.counterService.selectedcounter.countertype == Constants.SELFAPP){
            this.deliverycharges = this.dataService.retailer.appconfig.basedeliverycharge;
        }
        this.enableorderhistory= false; 
            
        setTimeout(()=>{ this.setFocusOnMobile();}, 100);
    }
    
    deliveredby;
    deliverycharges; 

    ngOnInit(){

    }


    
}
