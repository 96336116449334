import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Utils } from 'src/app/service/utils';
import * as Highcharts from 'highcharts';

import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Constants } from '../domain/constants';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Summaryrecord } from '../domain/summaryrecord';
import { Dayendsummary } from '../domain/dayendsummary';
import { constants } from 'os';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');


Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
    templateUrl: 'branddashboard.html',
    providers: [ConfirmationService]
})
export class Branddashboard implements OnInit 
{
    fromdate          : Date;
    todate            : Date;

    viewtypes : SelectItem[];
    currentviewtype;
    orderlist : Order[];

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private messageService : MessageService,
        private dynamodbservice : Dynamodbservice,
        private datePipe : DatePipe) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    
    public async  buildDashboard(){

        if(Constants.DEBUG_MODE) console.log('|DASHBOARD| ** building dashboard **');
        this.dataService.isdashboardbuilding = true;
        var retailer = this.dataService.retailer;
        
        var sumrecs = await this.dynamodbservice.downloadEOMRecords(this.dataService.getPosId());
        if(sumrecs != null){
            if(Constants.DEBUG_MODE) console.log('|DASHBOARD| ** NUMRECS **' + sumrecs.length);
            retailer.monthlysumdata = sumrecs;
        }
            

        this.dataService.isdashboardbuilding = false;

        this.initBsndates();
        this.buildTrends();
        this.distributionperiod = '1m';
        this.buildDistros(this.distributionperiod);
       
    }

        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.buildDashboard();
    }


    
    
    setPieChart(titletext, subtitletext,seriesname, seriesdata,containerdiv){
        var chartoptions : any;
        chartoptions  = {
            chart: 
            {
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45
                }
            },
            tooltip: {pointFormat: '{series.name}: <b>{point.y}</b>   <b>[{point.percentage:.1f}%]</b>'},
            title: {text: titletext},
            subtitle: {text: subtitletext },
            
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
        '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
            plotOptions: {
                pie: {
                    innerSize: 100,
                    depth: 45
                }
            },
            
            legend:{
               enabled: true,
               labelFormat: '<b>{name}</b>: {y} : {percentage:.1f}%'
            },
            series: [{
                name: seriesname,
                data: seriesdata
            }]
        };

        Highcharts.chart(containerdiv, chartoptions);

    }

    
    setColumnChart(titletext, subtitletext,xname,yname, xcats, seriesdata,containerdiv){
        var chartoptions : any;
        chartoptions  = {
            tooltip: {  
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>AED {point.y:.1f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            title: {text: titletext},
            subtitle: {text: subtitletext },
            yAxis: {
                min: 0,
                title: {
                    text: yname
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
            xAxis: {
                categories: xcats,
                crosshair: true
            },
            series: seriesdata
        };

        Highcharts.chart(containerdiv, chartoptions);

    }

    
    
  

    

    
    bsn1d;
    bsn1w;
    bsn1m;
    bsn3m;
    bsn6m;
    bsn1y;

    getStartMonth(monthcount){
        var curmonth =this.bsntomonth;
        for(var i =0;i<monthcount;i++){
            curmonth = Utils.getPrevMonth(curmonth);
        }
        return curmonth;
    }

    setDistStart(period){
        switch(period){
            case '1m': this.diststartmonth = this.bsntomonth; break;
            case '1y': this.diststartmonth = this.bsnfrommonth; break;
            case '3m': this.diststartmonth = this.getStartMonth(3); break;
            case '6m': this.diststartmonth = this.getStartMonth(6); break;
        }
    }

    initBsndates(){
        var bsndate = new Date();
        var curdate = new Date();

        var curbsndate = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));  
        this.bsntomonth = Utils.getPrevMonth(curbsndate);
        this.diststartmonth = this.bsntomonth; 

        bsndate.setDate(curdate.getDate() - 1);
        this.bsn1d = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));
        
        bsndate.setDate(curdate.getDate() - 8);
        this.bsn1w = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));
        
        bsndate.setDate(curdate.getDate() - 31);
        this.bsn1m = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));
        
        bsndate.setTime(curdate.getTime() - (91 * 24 * 60 * 60 * 1000));
        this.bsn3m = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));
        
        bsndate.setTime(curdate.getTime() - (182 * 24 * 60 * 60 * 1000));
        this.bsn6m = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));
        
        bsndate.setTime(curdate.getTime() - (365 * 24 * 60 * 60 * 1000));
        this.bsn1y = parseInt(this.datePipe.transform(bsndate,"yyyyMMdd"));

        
        this.monthseries = [];
        this.bsnmonths = [];
        var curmonth = this.bsntomonth;

        for(var i=0;i<12;i++){
            this.monthseries.unshift(Utils.getMonthYearAsString(curmonth));
            this.bsnmonths.unshift(curmonth);
            this.bsnfrommonth = curmonth;
            curmonth = Utils.getPrevMonth(curmonth);
        }

        console.log('TOMONTH' + this.bsntomonth + ' MOTNHS ' + JSON.stringify(this.monthseries));
        

    }

    
    /////////////////MONTHLY BRAND MONITORING /////////////////
    bsntomonth;
    bsnfrommonth; 
    monthseries :any[];
    bsnmonths : any[];
    distributionperiod; 
    diststartmonth; 

    public buildTrendsAndDistros(){
        this.buildTrends();
        this.buildDistros(this.distributionperiod);
    }
    

    //This function will start building the brand dashboard
    public buildTrends(){
        this.setMonthlyTrend(Constants.ORDER_COUNT,"Order Count", "containerordercount", "All Orders - Count");
        this.setMonthlyTrend(Constants.ORDER_VALUE,"Order Value", "containerordervalue", "All Orders - Value");
        this.setMonthlyTrend(Constants.DINEIN_ORDER_COUNT,"Order Count - Dine in & TA", "containerordercountdinein", "Order Count - Dine in ");
        this.setMonthlyTrend(Constants.DINEIN_ORDER_VALUE,"Order Value - Dine in & TA", "containerordervaluedinein", "Order Value - Dine in");
        this.setMonthlyTrend(Constants.TA_ORDER_COUNT,"Order Count - Dine in & TA", "containerordercountta", "Order Count - Takeaway ");
        this.setMonthlyTrend(Constants.TA_ORDER_VALUE,"Order Value - Dine in & TA", "containerordervalueta", "Order Value - Takeaway");
        this.setMonthlyTrend(Constants.DE_ORDER_COUNT,"Order Count - Delivaries", "containerordercountde", "Order Count - Delivaries");
        this.setMonthlyTrend(Constants.DE_ORDER_VALUE,"Order Value - Deliveries", "containerordervaluede", "Order Value - Delivaries");
        this.setMonthlyTrend(Constants.ONLINE_ORDER_COUNT,"Order Count - Dine in & TA", "containerordercountonline", "Order Count - Online ");
        this.setMonthlyTrend(Constants.ONLINE_ORDER_VALUE,"Order Value - Dine in & TA", "containerordervalueonline", "Order Value - Online");
        this.setMonthlyTrend(Constants.MATAM_ORDER_COUNT,"Order Count - Dine in & TA", "containerordercountmatam", "Order Count - Matam ");
        this.setMonthlyTrend(Constants.MATAM_ORDER_VALUE,"Order Value - Dine in & TA", "containerordervaluematam", "Order Value - Matam");
        this.setMonthlyTrend(Constants.GUEST_COUNT,"Dine in Guest Count", "containerguestcount", "Dine in Guest Count");
        this.setMonthlyTrend(Constants.AVG_VALUE_PER_GUEST,"Dine in APC", "containerapc", "Dine in APC");
        this.setMonthlyTrend(Constants.VOID_COUNT,"Void order count", "contianervoidcount", "Void order count");
        this.setMonthlyTrend(Constants.VOID_VALUE,"Void orders  (%ge business)", "containervoidvalue", "Void orders  (%ge business)");
        

    }

    public buildDistros(period){
        this.distributionperiod = period;
        this.setDistStart(period);

        this.setDistribution(Constants.VW_CATEGORY,"Order Value", "containercatdist", "Category wise sales");
        this.setDistribution(Constants.VW_SUBCATEGORY,"Order Value", "containersubcatdist", "Sub Category wise sales");
        this.setDistribution(Constants.VW_PRODUCT,"Order Value", "containerproductdist", "Product wise sales");
        this.setDistribution(Constants.VW_AGGREGATOR,"Order Value", "containeraggrdist", "Aggregator wise sales");
        this.setDistribution(Constants.VW_COUNTERTYPE,"Order Value", "containerchanneldist", "Channel wise sales");
        this.setDistribution(Constants.VW_SERVED_BY,"Order Value", "containerserverdist", "Server wise sales");
        this.setDistribution(Constants.VW_GUEST_TYPE_WISE,"Order Value", "containerguesttypedist", "Guest type wise sales");
        
        
     
    }

    setDistribution(viewtype,seriesname,containername,titletext){
        var sumrecs = Utils.getSummarizedData(this.diststartmonth, this.dataService.retailer.monthlysumdata,viewtype,this.dataService.getPosId());
        sumrecs.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval2)  > (b.summaryval2)  ? -1 : 1 );

        var distdata = [];
        var i= 0;
        for(var rec of sumrecs){
            var value = Math.round(rec.summaryval2);
            if(i < 15) distdata.push({name : rec.description , y : value});
            i++;
        }

        
        setTimeout(()=>{ this.setPieChart(titletext,'',seriesname, distdata,containername);}, 100);
        

    }


    setMonthlyTrend(valuetype,seriesname,containername,titletext){
        var seriesdata  = [];
        var momdata = [];

        var prevmonth = 0;
        for(var bsnmonth of this.bsnmonths){

            var value = 0;

            if(valuetype == Constants.AVG_VALUE_PER_GUEST){
                var guestcount =  Utils.getMonthlyValue(bsnmonth, this.dataService.retailer.monthlysumdata,Constants.GUEST_COUNT, this.dataService.getPosId());
                var totaldinein =  Utils.getMonthlyValue(bsnmonth, this.dataService.retailer.monthlysumdata,Constants.DINEIN_ORDER_VALUE, this.dataService.getPosId());
                value  = Math.round(totaldinein/guestcount);   
            }else if(valuetype == Constants.VOID_VALUE){
                var voidvalue =  Utils.getMonthlyValue(bsnmonth, this.dataService.retailer.monthlysumdata,Constants.VOID_VALUE, this.dataService.getPosId());
                var totalorders =  Utils.getMonthlyValue(bsnmonth, this.dataService.retailer.monthlysumdata,Constants.ORDER_VALUE, this.dataService.getPosId());
                value  = Utils.round(voidvalue/totalorders * 100,2);   
            }else{
                value = Utils.getMonthlyValue(bsnmonth, this.dataService.retailer.monthlysumdata,valuetype, this.dataService.getPosId());
            }
            
            if(prevmonth != 0){
                var growth = value - prevmonth;
                var pergrowth = Utils.round( growth / prevmonth * 100,2);
                momdata.push({name: '' + pergrowth , y: value});
            }else{
                momdata.push( {name: '' + pergrowth, y: value});
            }
            prevmonth = value;
            seriesdata.push(value);
        }        


        var trendseries = [{type : 'column', name: seriesname, data: seriesdata},
        {
            type: 'spline',name: 'MOM Growth', data: momdata,
            tooltip: {  
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b> {point.name:.2f}%</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            marker: {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[3],
                fillColor: 'white'
            }
        },];
        setTimeout(()=>{ this.setColumnChart(titletext,null,'Month',seriesname,this.monthseries,trendseries,containername); }, 100);
        
    }





}


