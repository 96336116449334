import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { months } from 'moment';
import { Summaryrecord } from '../../../domain/summaryrecord';
import { Billingcounterservice } from '../../../service/billingcounterservice';
import { Userservice } from '../../../service/userservice';
import { Kitchenservice } from '../../../service/kitchenservice';
import { Cartlineitem } from '../../../domain/cartlineitem';
import { Kot } from 'src/app/domain/kot';
import { Transferitem } from 'src/app/domain/transferitem';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';



@Component({
    templateUrl: 'kotbillreco.html',
    providers: [ConfirmationService]
})
export class Kotbillreco implements OnInit 
{
    businessdate          : Date;
    
    
    orderlist : Order[];
    kotlist : Kot[];
    transferlist : Transferitem[];
    
    
    filteredlist : Summaryrecord[];
    
    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;
    currentcategoryid; 

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private counterService : Billingcounterservice,
        private orderService  : Orderservice,
        private dbService : Dynamodbservice,

        private userService : Userservice, 
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    strmonth1;
    strmonth2; 
    
    strmonthoptions; 
    arrmonthlist;
        
    ngOnInit(){
        this.currentcategoryid = 0;

        this.businessdate = new Date(new Date().setHours(0,0,0,0));
        this.businessdate.setDate(this.businessdate.getDate()-1);
        

        
        this.cols = [
            { field: 'summaryval1', header: 'Subcategory' },
            { field: 'description', header: 'Product' },
            { field: 'summaryval2', header: this.strmonth1 +  ' Item count' },
            { field: 'summaryval3', header: this.strmonth1 + 'Item amount' },
            { field: 'summaryval4', header: '% Contribution in Subcategory' },
            { field: 'summaryval5', header: '% Overall Contribution' },
            { field: 'summaryval6', header: this.strmonth2 +  ' Item count' },
            { field: 'summaryval7', header: this.strmonth2 + 'Item amount' },
            { field: 'summaryval8', header: '% Contribution in Subcategory' },
            { field: 'summaryval9', header: '% Overall Contribution' }
        ];

        
        

    }

    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount1 = 0;
    totalitemamount1 = 0;
    totalitemcount2 = 0;
    totalitemamount2 = 0;
    
    
    setTotalsToZero(){
        this.totalitemcount1 = 0;
        this.totalitemamount1 = 0;
        this.totalitemcount2 = 0;
        this.totalitemamount2 = 0;
    }


    incrementTotals(ord : Order , monthno){
        if(monthno == 1){
            this.totalitemcount1  =  this.totalitemcount1 + ord.cart.sumitemcount;
            this.totalitemamount1 =  this.totalitemamount1 + ord.cart.sumitemamount;
        }else{
            this.totalitemcount2  = this.totalitemcount2 + ord.cart.sumitemcount;
            this.totalitemamount2 =  this.totalitemamount2 + ord.cart.sumitemamount;

        }
            
    }

    roundTotals(){
        
    }


    findInArray(arrtokens,newtok){
        for(var item of arrtokens){
            if(item == newtok) return true; 
        }
        return false; 
    }
    
    setKOTTokens(ord : Order){
        var arrtoken = [];
        if( ord.cart && ord.cart.lineitems != null && ord.cart.lineitems.length > 0){
            for(var item of ord.cart.lineitems){
                if(!this.findInArray(arrtoken,item.kottoken)){
                    arrtoken.push(item.kottoken);
                }
            }
        }
        arrtoken = arrtoken.reverse();
        ord.kotnum = arrtoken.join(",");
        ord.kotcount = arrtoken.length;
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    
    getOrderWithSessionId(sessionid){
        for(var order of this.orderlist){
            var ordersession = order.counterid + "|" + order.sessionid;
            if(sessionid == ordersession) return order;
        }
        return null;
    }

    createSummaryRecord(kot : Kot, order : Order, itemsTransferred){
        var rec = <Summaryrecord>{};
        rec.id = kot.id; 
        rec.description = kot.kottoken;
        rec.summaryval1 = kot.kottimestr;
        rec.summaryval2 = kot.lineitemsstr;
        if(order){
            rec.summaryval3 = order.rcptnodisplay;
            rec.summaryval4 = order.kotnum;
        }
        return rec; 
    }

    setFilterList(){

        this.filteredlist = [];

        for (var ord of this.orderlist){
            this.setKOTTokens(ord);
            if (ord.receiptno && ord.receiptno.length > 0){
                if(ord.receiptno.indexOf("/") > 0){
                    ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
                }else{
                    ord.rcptnodisplay = ord.receiptno;
                }
            }
            
        }

        for(var kot of this.kotlist){
            if(!kot.iscancelled){
                var order = this.getOrderWithSessionId(kot.sessionid);
                this.filteredlist.push(this.createSummaryRecord(kot,order,""));
            }
        }
      
        this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.id)  < (b.id)  ? -1 : 1 );
        this.filteredlist = [... this.filteredlist];

    }

    getMonthRecord(strmonth){
        for(var mon of this.arrmonthlist){
            if(mon.monthname == strmonth) return mon;
        }

    }
    
    async setData(){
        
            var businessdate =   parseInt(this.datePipe.transform(this.businessdate,"yyyyMMdd"));
            
            this.isRunning = true; 
            
            
            this.orderlist = await this.orderService.getOrderlist(businessdate,businessdate);
            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
                this.isRunning = false;
                return; 
            }

            this.kotlist = await this.kitchenService.getKotList(businessdate,businessdate);
            if(this.kotlist == null){
                this.showErrorToast("Error fetching KOTs, please try again!")
                this.isRunning = false;
                return; 
            }

            this.kotlist.sort((a:Kot,b:Kot)=> a.id < b.id  ? -1 : 1 );

            for(var kot of this.kotlist){
                this.kitchenService.setStringVarsinKot(kot);
            }


            var conditionexpression = "posid = :v_retailerid and businessdate = :v_bsndate";  
            var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_bsndate":  businessdate };
            this.transferlist = await this.dbService.queryOnIndex('itemtransfers', "posid-businessdate-index", conditionexpression,attribparams);
            if(this.transferlist == null){
                this.showErrorToast("Error fetching Transfer Items, please try again!")
                this.isRunning = false;
                return; 
            }


            this.setFilterList();
            this.filteredlist = [... this.filteredlist];
            this.isRunning = false; 
    }


    
    
}


