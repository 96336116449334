import {Aggregator} from '../domain/aggregator'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from './dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { SelectItem } from 'primeng/api';
import { UPMessage } from '../updomain/upmessage';
import { Order } from '../domain/order';

@Injectable()
export class Aggregatorservice  {
     aggregatorlist : Aggregator[];
     aggretatoroptions : SelectItem[];
     
     constructor(
         private couchdbService : Couchdbservice,
         private dynamoDBService : Dynamodbservice,
         private dataService : Appdataservice,
     ){}

     getAggregatorNameForId(aggrid){
         for(var aggr of this.aggregatorlist){
             if(aggr.id == aggrid) return aggr.name;
         }
         return "";
     }

     isPizzaPan(aggrid){
        for(var aggr of this.aggregatorlist){
            if(aggr.id == aggrid){
                if(aggr.ispizzapan) return true;
            }
        }
        return false;
    }

     getDeliveryChargeForAggregator(aggrid){
        for(var aggr of this.aggregatorlist){
            if(aggr.id == aggrid) return aggr.deliverycharge;
        }
        return 0;
    }
    
    async initAggregators(){
        this.aggregatorlist = [];
        this.aggretatoroptions = [];
        console.log('|AGGR|Fetching from couch');
        var retlist;

        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.scanTable('aggregators');
            if(retlist) this.aggregatorlist = retlist; 
        }else{
            retlist = await this.couchdbService.readGlobalStore('aggregators');
            if(retlist) this.aggregatorlist = <Aggregator[]> retlist.docs;
        }

        this.aggregatorlist.sort((a: Aggregator, b: Aggregator)=> a.name < b.name  ? -1 : 1 );

        
        if(retlist){
            if(Constants.DEBUG_MODE) console.log('|AGGR|Num aggregators-->' + JSON.stringify(retlist));
            for(var aggr of this.aggregatorlist){
                this.aggretatoroptions.push({label : aggr.name, value : aggr.id});
            }
            return true;
        }else{
            return false;
        }
        
     }

     public getAggregatorFromUpOrder(ord : UPMessage){
         console.log('Oder id ' + ord.id);
         var deltype = ord.order.details.ext_platforms[0].delivery_type;    
         
         
         switch(ord.order.details.channel){
             case "zomato": 
                 if(deltype == 'partner'){
                    if(ord.ispizzpan) return 17;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 26;
                    else return 10;
                 } 
                 else if (deltype == 'self'){
                    if(ord.ispizzpan) return 13;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 25;
                    else return 1;
                 }
                 else return 16;
             case "talabat":
                if(deltype == 'partner'){
                    if(ord.ispizzpan) return 32;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 24;
                    else return 3;
                } 
                else if (deltype == 'self'){
                    if(ord.ispizzpan) return 22;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 23;
                    else return 2;    
                } 
                else return 38;
             case "deliveroo":
                if(deltype == 'partner'){
                    if(ord.ispizzpan) return 21;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 28;
                    else return 11;
                } 
                else {
                    if(ord.ispizzpan) return 15;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 27;
                    else return 9;
                } 
             case "careem":
                if(deltype == 'partner'){
                    return 34;
                } 
                else{
                    if(ord.ispizzpan) return 18;
                    else return 4;
                }  
             case "noonfood":
                if(deltype == 'partner'){
                    if(ord.ispizzpan) return 36;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 30;
                    else return 35; 
                }else {
                    if(ord.ispizzpan) return 19;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 29;
                    else return 14;
                } 
            case "eateasy":
                if(deltype == 'partner'){
                    if(ord.ispizzpan) return 36;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 30;
                    else return 20; 
                }else {
                    if(ord.ispizzpan) return 43;
                    else if(this.dataService.retailer.id  == 1511 && !ord.issfcplus ) return 33;
                    else return 5;
                }
                
         }

         
     }

    

}