import { Injectable } from "@angular/core";
import { Consumercart } from "../domain/consumercart";
import { Cartlineitem } from "../domain/cartlineitem";
import { Order } from "../domain/order";
import { DataService } from "./dataservice";
import { Appdataservice } from "./appdataservice";
import { Constants } from "../domain/constants";
import { Utils } from "./utils";
import { Customeraddress } from "../domain/customeraddress";
import { isUndefined } from "util";
import { Dynamodbservice } from "./dynamodbservice";
import { Customer } from "../domain/customer";
import { Productquantity } from "../domain/productquantity";
import { Discountvoucher } from "../domain/discountvoucher";
import { Orderfeedback } from "../domain/orderfeedback";
import { Couchdbservice } from "./couchdbservice";

@Injectable()
export class Customerservice  {



    
    constructor (public dataService : Appdataservice,
         public couchDBService : Couchdbservice,
         public dbService : Dynamodbservice) {

    }

    isCustomerFetched; 

    public getTopPurchasesAsString(toppurchases : Productquantity[]){
        
        var retstring = "";
        var i =0;
        toppurchases.sort((a:Productquantity, b: Productquantity)=> (a.quantity)  > (b.quantity)  ? -1 : 1 );

        for(var purchase of toppurchases){
            if( i < 5){
                var prod = this.dataService.getProductForId(purchase.productid);

                if(prod){
                    retstring = retstring +  prod.name + "(" + purchase.quantity  +"), "; 
                    i++;
                }
            } 
        }
        
        if(retstring.length > 3){
            retstring = retstring.substring(0, retstring.length - 2);
        }

        return retstring;
    }


   
    addToTopPurchases (cust : Customer, productid, quantity){
        for (var purchase of cust.toppurchases){
            if(purchase.productid == productid){
                purchase.quantity = purchase.quantity + quantity;
                return;
            }
        }

        var purchase =<Productquantity> {};
        purchase.productid = productid;
        purchase.quantity = quantity; 
        cust.toppurchases.push(purchase);
     }

     
     async findInDynamo(mobileno){
         var retlist = <Customer[]> await this.dbService.findCustomers("" + mobileno);
         if(retlist && retlist.length > 0 ){
             var cust = retlist[0];
             if(cust.addresslist && cust.addresslist.length > 0){
                cust.addressline1 = cust.addresslist[0].addressline1;
                cust.addressline2 = cust.addresslist[0].addressline2;
             }
             console.log ('** RETURNING CUSTOMER FROM DYNAMO ' + JSON.stringify(cust));
             return cust; 
         }else  
            return null;
     }

     async findInPoS(mobileno){
         var customer; 
         if(this.dataService.usedynamo){
            var key  = {"posid": "" + this.dataService.getPosId() , "id" : parseInt(mobileno)};
            customer  = <Customer> await this.dbService.getItem('customers', key);
         }else
            customer = <Customer> await this.couchDBService.getItem('customers', parseInt(mobileno));

        return customer; 
     }

     async findCustomer(mobileno){
         //First find in PoS database 
         var customer : Customer; 
         customer = await this.findInPoS(mobileno);
         if(customer){
              return customer;
         }else{
             //return await this.findInDynamo(mobileno);
             return null;
         } 
         
     }
     
     async saveCustomer(cust : Customer){
        if(!cust.createdon) cust.createdon = new Date().getTime();
        if(!cust.walletbal) cust.walletbal = 0;
        if(!cust.alternateno) cust.alternateno = 0;
        if(!cust.alternateno2) cust.alternateno2 = 0;
        

        
        cust.id = parseInt(cust.mobileno);
        
        cust.posid = this.dataService.getPosId(); 
        cust.updatedon = new Date().getTime();
        console.log('Saving customer '  + JSON.stringify(cust));
        
        if(this.dataService.usedynamo)
            return await this.dbService.putItem('customers',cust);
        else
            return await this.couchDBService.putItem('customers', cust);
		
     }
 
     getClone(customer : Customer){
        var temp = new Customer();
        for(let prop in customer) {
            temp[prop] = customer[prop];
        }
        return temp;

    }


    async getCustomerOrders(customer : Customer){
        try{
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and customermobile = :v_custmobile ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_custmobile":  customer.mobileno };
                return await this.dbService.queryOnIndex('orders', "posid-customermobile-index", conditionexpression,attribparams);
            }else{
                var selector =  {"customermobile": { "$eq": customer.id}};
                if(Constants.DEBUG_MODE) console.log('|ORDERS|Fetching from couch');
                var retlist = await this.couchDBService.getFromIndex('orders',selector);
                if(retlist && retlist.docs ){
                    return retlist.docs;
                }else 
                    return null;
            }
        }catch(err){
            console.error('ERror fetching Orders ' + err);
            return null;
        }

    }




}