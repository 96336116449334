import {Discountpartner} from '../domain/discountpartner'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from './dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { SelectItem } from 'primeng/api';
import { Gulfnewscode } from '../domain/gulfnewscode';

@Injectable()
export class Discountpartnerservice  {
     discountpartnerlist : Discountpartner[];
     partnereoptions : SelectItem[];
     
     constructor(
         private couchdbService : Couchdbservice,
         private dynamoDBService : Dynamodbservice,
         private dataService : Appdataservice,
     ){}

     getDiscountpartnerNameForId(partnerid){
         for(var partner of this.discountpartnerlist){
             if(partner.id == partnerid) return partner.name;
         }
         return "";
     }
    
     getDiscountPartnerForId(partnerid){
        for(var partner of this.discountpartnerlist){
            if(partner.id == partnerid) return partner;
        }
        return null;
    }
   
    async initDiscountpartners(){
        this.discountpartnerlist = [];
        this.partnereoptions = [];
        console.log('|DISC|Fetching from DB');
        var retlist;

        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.scanTable('discountpartners');
            if(retlist) this.discountpartnerlist = retlist; 
        }else{
            retlist = await this.couchdbService.getAllItems('discountpartners');
            this.discountpartnerlist = <Discountpartner[]> retlist.docs;
        }
        
        if(retlist){
            for(var partner of this.discountpartnerlist){
                this.partnereoptions.push({label : partner.name, value : partner.id});
            }
            return true;
        }else{
            return false;
        }
        
     }

     async getGulfnewsCode(code : string  ){
        var key = {'code' : code};
        return <Gulfnewscode> await this.dynamoDBService.getItem('gulfnewscode', key);
     }

     async consumeGulfnewsCode(code : string ){
        var gulfnewscode = <Gulfnewscode> {};
        gulfnewscode.code = code; 
        gulfnewscode.usagestatus = 1;
        return await this.dynamoDBService.putItem('gulfnewscode', gulfnewscode);
     }

     async clearGulfnewsCode(code : string ){
        var gulfnewscode = <Gulfnewscode> {};
        gulfnewscode.code = code; 
        gulfnewscode.usagestatus = 0;
        return await this.dynamoDBService.putItem('gulfnewscode', gulfnewscode  );
     }
    

    

}