import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';



@Component({
    templateUrl: 'paymentsummary.html',
    providers: [ConfirmationService]
})
export class Paymentsummary implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Summaryrecord[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private orderService  : Orderservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'summarized';
        
         
        this.viewtypes = [
            {label: 'Payment mode wise', value: Constants.VW_PAYMENTMODE},              
            {label: 'Counter type wise', value: Constants.VW_COUNTERTYPE},              
            {label: 'Counter wise', value: Constants.VW_COUNTER}              
            

        ];

        this.currentviewtype = Constants.VW_PAYMENTMODE;

        this.orderlist = [];

        this.cols = [
            { field: 'summaryval1', header: 'Business date' },
            { field: 'description', header: 'Description' },
            { field: 'summaryval2', header: 'Amount' }
        ];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt,false);
    }

    changeDatewiseOption(dt : Table){
        console.log('Setting data...');
        this.setData(dt,false);
    }
    
    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalpaidamount = 0;
   
    setTotalsToZero(){
        this.totalpaidamount = 0;
    }


    incrementTotals(pymt : Payment){
        this.totalpaidamount = this.totalpaidamount + pymt.paidamount;
    }

    roundTotals(){
        
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(pymt : Payment , ord : Order){
        pymt.createdonstr = this.datePipe.transform(pymt.id, "dd/MM HH:mm");
        pymt.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        pymt.orderid = ord.id; 
        pymt.receiptno =ord.receiptno;
        pymt.countername = ord.countername;
    }

    createNewSummaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheck(pymt);
        rec.description = this.getDescritpion(pymt);
        rec.summaryval1 = pymt.businessdatestr;
        rec.summaryval2 = pymt.paidamount;
        return rec; 
    }

    addToExistingRecord(rec :Summaryrecord, pymt : Payment){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + pymt.paidamount,2);
    }

    getKeytoCheck(pymt : Payment){
        var key ="";
        if(this.selectedRadio == 'datewise')
            key = pymt.businessdatestr;

        switch(this.currentviewtype){
            case Constants.VW_PAYMENTMODE: return key + pymt.paymentmode;
            case Constants.VW_COUNTERTYPE: return key + pymt.countertypestr;
            case Constants.VW_COUNTER: return key + pymt.countername;
        }
        
    }

    getDescritpion(pymt : Payment){
        switch(this.currentviewtype){
            case Constants.VW_PAYMENTMODE: return pymt.paymentmodename;
            case Constants.VW_COUNTERTYPE: return pymt.countertypestr;
            case Constants.VW_COUNTER: return pymt.countername;
        }
        
    }

    
    setFilterList(){
        this.filteredlist = [];
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                var countertypestr = Constants.getCounterTypeName(ord.countertype);

                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        this.setDateStrings(pymt,ord);
                        pymt.countertypestr = countertypestr;
                        var key = this.getKeytoCheck(pymt);
                        var currec = Utils.findInSummaryList(this.filteredlist,key);
                        if(currec){
                            this.addToExistingRecord(currec,pymt);
                        }else{
                            currec = this.createNewSummaryRecord(pymt);
                            this.filteredlist.push(currec);    
                        }

                        
                        this.incrementTotals(pymt);
                    }
                }
            }
        }
        this.filteredlist = [... this.filteredlist];
            

    }

    
    async setData(dt : Table, refetch){
        if(refetch){
            var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
            var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

            if(todate < fromdate){
                this.showErrorToast("To date can not be less than from date!");
                return;
            }

            if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
                this.showErrorToast("The data can be searched for maximum of 31 days!");
                return;
            }

            this.isRunning = true; 
            this.setTotalsToZero();
        
            this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
            this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
            }else{
                this.setFilterList();
            }
        }else{
            this.isRunning = true; 
            this.setTotalsToZero();
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


