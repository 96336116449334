import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {DataService}  from '../../service/dataservice';
import {Constants}    from '../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Orderservice } from 'src/app/service/orderservice';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/service/utils';
import { Order } from 'src/app/domain/order';
import { Table } from 'primeng/table';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Payment } from 'src/app/domain/payment';
import { Shiftendrecord } from 'src/app/domain/shiftendrecord';
import { Kot } from 'src/app/domain/kot';
import { Shiftendservice } from 'src/app/service/shiftendservice';
import { Printingservice } from 'src/app/service/printingservice';
import { Product } from 'src/app/domain/product';

@Component({
    templateUrl: 'shortsummaryreport.html',
    providers: [ConfirmationService]
})
export class Shortsummaryreport implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    summarylist       : Summaryrecord[];
    categorywisesales : Summaryrecord[];
    /*countertypewisesales : Summaryrecord[];
    subcategorywisesales : Summaryrecord[];
    kitchenwisesales : Summaryrecord[];
    sectionwisesales : Summaryrecord[];*/
    modewisereceipts    : Summaryrecord[];
    cardtypereceipts : Summaryrecord[];
    complimentaryreceipts : Summaryrecord[];
    othercreditreceipts : Summaryrecord[];
    modewisereceiptscc    : Summaryrecord[];
    
    
    discountsummary : Summaryrecord[];
    shiftlist       :   Shiftendrecord[];
    kotlist : Kot[];
    
    


    currentviewtype;

    showcategorywisesales; 
    showsubcategorywisesales; 
    showkitchenwisesales;
    showsectionwisesales;
    showcountertypewisesales;  


    isRunning = false; 

    getWidthClass(){
        if(window.innerWidth <= 600) return "Wid90Center";
        else if(window.innerWidth <= 1000) return "Wid80Center";
        else return "Wid60Center";
    }

    
    constructor(public dataService: Appdataservice,private _router : Router,
        private salesorderService: Orderservice,
        private datePipe : DatePipe,
        private counterService : Billingcounterservice,
        private userService : Userservice,
        private orderService : Orderservice,
        private shiftEndService : Shiftendservice,
        private kitchenService : Kitchenservice,
        private messageService : MessageService,
        public printingService : Printingservice,
        private dboyService : Deliveryboyservice
        ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){

        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());

        this.showcategorywisesales = true;
        this.showsubcategorywisesales = false;
        this.showcountertypewisesales = true;
        this.showkitchenwisesales = true;
        this.showsectionwisesales = true;
         

        this.cols = [
            { field: 'description', header: 'Parameter' },
            { field: 'summaryval1', header: 'Value' }
        ];
 
        this.summarylist = [];
        this.orderlist = [];

    }


    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        var ctr = this.counterService.getBillingcounterForId(ord.counterid);
        if(ctr){
            ord.sectionnamestr = ctr.sectionname;
        }
        ord.servedbystr = this.userService.getUserNameForId(ord.servedby);
        ord.billedbystr = this.userService.getUserNameForId(ord.billedby);
        ord.weekdaystr = this.datePipe.transform (ord.createdon,"EEEE");
        ord.hourofdaystr  = Utils.getHourRangeFromHour(parseInt(this.datePipe.transform(ord.createdon,"HH")));
        ord.deliveredbystr = this.dboyService.getDeliveryboyNameForId(ord.deliveredby);

    }
    orderlist : Order[]; 


    cancelledordercount = 0;
    cancelledorderamount = 0;
    kotcancelcount = 0;
    kotcancelamount = 0;
    itemscancelledkot = 0;
    itemscancelledkotamt = 0;
    itemsreturnedcount =0;
    itemsreturnedamount =0;
    duplicatebillcount = 0;
    totaldiscountonbills =0;
    totalschemedifference= 0;
    totaldiscountonitems = 0;
    numberofbillswithdiscount =0;
    totalbills =0;
    totalitemssold =0;
    totalsalesvalue =0;
    totaltaxablesales = 0;
    totalnetsales = 0;
    totalcgst = 0;
    totalsgst = 0;
    
    totalvat = 0; 
    totalsc = 0;
    totalmfee =0 ;
    totaltfee = 0;
    totalrounding = 0;

    totaltaxes = 0;
    totalduepayments = 0;
    numreprints = 0;
    totalinwardcash =0;
    totaloutwardcash = 0;
    netcash = 0;
    totalreceipts =0;
    totalexpenses = 0;
    grossprofit= 0;
    numshifts= 0;
    totaldiscrepancy = 0;
    totalcharges = 0;
    vatoncharges = 0;
    taxonitems = 0;
    totaltips = 0;
    totalpax = 0;
    totalfooddisc =0 ;
    totalbevdisc = 0;
    totaltobdisc =0;
    totalmiscdisc = 0;
    
    callcentersales = 0;

    setTotalsToZero(){
        this.cancelledordercount = 0;
        this.cancelledorderamount = 0;
        this.itemscancelledkot = 0;
        this.itemscancelledkotamt = 0;
        this.itemsreturnedcount =0;
        this.itemsreturnedamount =0;
        this.duplicatebillcount = 0;
        this.totaldiscountonbills =0;
        this.totaldiscountonitems = 0;
        this.numberofbillswithdiscount =0;
        this.totalbills =0;
        this.totalitemssold =0;
        this.totalsalesvalue =0;
        this.totaltaxablesales = 0;
        this.totalnetsales = 0;
        this.totalcgst =0;
        this.totalsgst =0;
        this.totalvat =0;
        this.totaltaxes = 0;
        this.totalduepayments = 0;
        this.numreprints = 0;
        this.totalinwardcash =0;
        this.totaloutwardcash  =0;
        this.netcash =0;
        this.totalreceipts =0;
        this.totalexpenses = 0;
        this.grossprofit =0;
        this.numshifts =0;
        this.totaldiscrepancy=0;
        this.kotcancelamount = 0;
        this.kotcancelcount = 0;
        this.totalcharges = 0;
        this.vatoncharges = 0;
        this.taxonitems  = 0;
        this.totaltips  =0;
        this.totalpax = 0;
        this.totalschemedifference = 0;
        this.totalfooddisc =0 ;
        this.totalbevdisc = 0;
        this.totaltobdisc =0;
        this.totalmiscdisc = 0;
        this.totalsc = 0;
        this.totalmfee = 0;
        this.totaltfee = 0;
        this.totalrounding  = 0;
        this.callcentersales = 0;        
        
    }

    incrementSchemeBenefit(ord : Order){

        for(var item of ord.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){

                console.log('SCH PROD ** ' + item.productname);
                var prod = this.dataService.getProductForId(item.productid);
                var oldamount =  Utils.round((item.ograte  + item.ogcustrate) *  item.quantity, 3);       
                var oldgross = oldamount;

                //Compute the gross amount (amount before tax)
                if(item.revtax){
                    var taxpercent = prod.cgst + prod.sgst + prod.vat; 
                    oldgross = Utils.round(oldamount / (1 + (taxpercent/100)),3);
                }


                var newgross = item.grossamt;
                var difference = oldgross - newgross;

                this.totalschemedifference= Utils.round(this.totalschemedifference + difference,2);
            }
        
        }
        
    }

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    addHeaderRecord(headerstring){
        var rec = <Summaryrecord>{};
        rec.description = headerstring;
        rec.summaryval1 = "";
        rec.summaryval3 = "B";
        
        this.summarylist.push(rec);
    }

    selectedRecord;
    reportfrom;
    reportto;

    async printReport()
    {
        if(this.summarylist && this.summarylist.length > 0){
            var strfromdate = this.datePipe.transform(this.fromdate,"dd/MM/yy");
            var strtodate = this.datePipe.transform(this.todate,"dd/MM/yy");
            var daterange = strfromdate + " - " + strtodate;
            
            document.getElementById("printable").innerHTML = this.printingService.getSummaryReportAsHTML("Collection Summary Report", daterange, this.summarylist);
            setTimeout(()=>{ window.print();}, 200);

        }
    }


    async setData(dt : Table){

        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 
        this.setTotalsToZero();
        
        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        this.shiftlist = await this.shiftEndService.getShiftendList(fromdate,todate);
        this.kotlist = await this.kitchenService.getKotList(fromdate,todate);


        this.reportfrom = Utils.getBusinessDateAsString("" + fromdate);
        this.reportto = Utils.getBusinessDateAsString("" + todate);
        
        this.setTotalsToZero();

        //Set category wise sales
        this.categorywisesales = this.getSummaryListItem(Constants.VW_CATEGORY);
        this.modewisereceipts =  this.getPaymentSummaryList();
        this.cardtypereceipts = this.getCardTypeSummaryList();
        this.complimentaryreceipts = this.getComplimentarySummaryList();
        this.othercreditreceipts = this.getOtherCreditSummaryList();
        this.modewisereceiptscc =  this.getPaymentSummaryListCC();

        this.discountsummary = this.getDiscountSummaryList();

        for(var order of this.orderlist){

            if(order.orderstatus === Constants.POS_ORDER_PROCESSED){
                this.totalbills = this.totalbills + 1;
                this.totalitemssold = Utils.round(this.totalitemssold + order.cart.sumitemcount,3);
                this.totalsalesvalue = Utils.round(this.totalsalesvalue + order.cart.sumitemgross,3);
                this.totalnetsales = Utils.round(this.totalnetsales + order.cart.netpayable,3);

                if(order.cart.totaldiscount > 0 ){
                    this.numberofbillswithdiscount = this.numberofbillswithdiscount + 1;
                    this.totaldiscountonbills = Utils.round(this.totaldiscountonbills + order.cart.totaldiscount,3);
                }

                console.log('|CCOrderID|' + order.ccordid);
                if(order.ccordid && order.ccordid > 0 )
                    this.callcentersales = Utils.round(this.callcentersales + order.cart.netpayable ,3);

                
                
                
                this.totalduepayments = Utils.round(this.totalduepayments + order.cart.pendingamount,3);
                this.totalreceipts = Utils.round(this.totalreceipts + order.cart.paidamount,3);
                this.totalrounding = Utils.round(this.totalrounding + order.cart.roundingamount,3);
                var charges = order.cart.netcharges; 
                var taxablecharges =  Utils.round(charges / (1 + (5/100)),3);
                this.totalcharges = Utils.round(this.totalcharges + taxablecharges,3);

                var vatoncharges = Utils.round(taxablecharges * (5/100),3);
                this.vatoncharges = this.vatoncharges + vatoncharges;

                this.totaltaxes = this.taxonitems + this.vatoncharges;

                var vatonitems =  Utils.round(order.cart.nettaxes,3);
                if(this.dataService.retailer.id  == 1554 || this.dataService.retailer.id  == 2022){
                     vatonitems =  order.cart.sumvat;
                     this.totalsc = Utils.round(this.totalsc + order.cart.sumsc,3);
                     this.totalmfee = Utils.round(this.totalmfee + order.cart.summfee,3);
                     this.totaltfee = Utils.round(this.totaltfee + order.cart.sumtfee,3);
                     
                }

                this.totalvat = Utils.round(this.totalvat + vatonitems + vatoncharges ,3);


                this.totaltaxablesales = Utils.round(this.totaltaxablesales + order.cart.sumitemtaxable + taxablecharges,3);

                if(order.cart.paymentlist && order.cart.paymentlist.length > 0){
                    for(var pymt of order.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_CARD) { 
                            var strtip = "" + pymt.tip;
                            if(strtip && strtip.length > 0){
                                this.totaltips = Utils.round(this.totaltips + parseFloat (strtip),3);
                            }
                        }
                    }
                }
                if(order.countertype == Constants.NORMAL || order.countertype == Constants.AC_SPECIAL){
                    if(order.numguests && order.numguests > 0)
                        this.totalpax = this.totalpax + order.numguests;
                }

                //Add to scheme 
                this.incrementSchemeBenefit(order);
                 
            }else{
                this.cancelledordercount = this.cancelledordercount + 1;
                this.cancelledorderamount = Utils.round(this.cancelledorderamount + order.cart.netpayable,3);
            }

            if(order.reprintcount > 0){
                this.numreprints = this.numreprints + order.reprintcount;
            }
    
        }

       /* for(var kot of this.kotlist){
            if(kot.iscancelled){
                this.kotcancelcount = this.kotcancelcount + 1; 
                for(var item of kot.lineitems){
                    this.kotcancelamount = Utils.round(this.kotcancelamount + item.itemamount,3);
                }

            }else{
                for(var item of kot.lineitems){
                    if(item.cancelled){
                        this.itemscancelledkot = this.itemscancelledkot + 1; 
                        this.itemscancelledkotamt = Utils.round(this.itemscancelledkotamt  + item.itemamount,3);
                    }
                }
            }

            
        }

        for(var shift of this.shiftlist){
            this.numshifts = this.numshifts + 1;
            
            if(shift.eoddate && shift.eoddate > 0)
                this.totaldiscrepancy = Utils.round(this.totaldiscrepancy + shift.discrepancy,3);
        }*/

        
        //Now start filling up the list 
        this.summarylist = [];


        var totalgrosssale = 0;

        this.addHeaderRecord(" ");
        
            
        for(var sumrec of this.categorywisesales){
            var rec = <Summaryrecord>{};
            rec.description = sumrec.description.toUpperCase() + ' SALE';
            rec.summaryval1 = sumrec.summaryval1;
            totalgrosssale = totalgrosssale + sumrec.summaryval1;
            this.summarylist.push(rec);
        }
    

        this.addHeaderRecord(" ");
        rec = <Summaryrecord>{};
        rec.description = "TOTAL GROSS SALE";
        rec.summaryval1 = Utils.round( totalgrosssale,3);
        rec.summaryval3 = 'B';
        this.summarylist.push(rec);
       
        

            
        
        
        
        this.addHeaderRecord(" ");
        this.addHeaderRecord("DISCOUNTS");
        
        for(var sumrec of this.discountsummary){
            var rec = <Summaryrecord>{};
            rec.description = sumrec.description ;
            rec.summaryval1 = sumrec.summaryval1; 
            this.summarylist.push(rec);

        }

        this.addHeaderRecord(" ");
        var rec = <Summaryrecord>{};
        rec.description = "FOOD DISCOUNT";
        rec.summaryval1 = this.totalfooddisc;
        this.summarylist.push(rec);

        var rec = <Summaryrecord>{};
        rec.description = "BEV DISCOUNT";
        rec.summaryval1 = this.totalbevdisc;
        this.summarylist.push(rec);

        if(this.dataService.retailer.id == 1554 || this.dataService.retailer.id  == 2022){
            var rec = <Summaryrecord>{};
            rec.description = "TOB DISCOUNT";
            rec.summaryval1 = this.totaltobdisc;
            this.summarylist.push(rec);
                
        }
        var rec = <Summaryrecord>{};
        rec.description = "MISC DISCOUNT";
        rec.summaryval1 = this.totalmiscdisc;
        this.summarylist.push(rec);


        this.addHeaderRecord(" ");
        var rec = <Summaryrecord>{};
        rec.description = "TOTAL DISCOUNT";
        rec.summaryval1 = this.totaldiscountonbills;
        rec.summaryval3 = "B";
        this.summarylist.push(rec);

        var totalcomplimentary = 0;
        
        if(this.complimentaryreceipts && this.complimentaryreceipts.length > 0){
            this.addHeaderRecord(" ");
            this.addHeaderRecord("COMPLIMENTARY PAYMENTS");
        
            
            for(var sumrec of this.complimentaryreceipts){
                var rec = <Summaryrecord>{};
                rec.description = sumrec.description + "[" + sumrec.summaryval2 + "] ";
                rec.summaryval1 = sumrec.summaryval1;
                totalcomplimentary  = totalcomplimentary + sumrec.summaryval1;
                this.summarylist.push(rec);
            }
        }


        this.addHeaderRecord(" ");
        var rec = <Summaryrecord>{};
        rec.description = "TOTAL COMPLIMENTARY";
        rec.summaryval1 = totalcomplimentary;
        rec.summaryval3 = "B";
        this.summarylist.push(rec);


        
        this.addHeaderRecord(" ");
        this.addHeaderRecord("CHARGES");
        
        if(this.dataService.retailer.id == 1554 || this.dataService.retailer.id == 2022 ){
            rec = <Summaryrecord>{};
            rec.description = "SERVICE CHARGE";
            rec.summaryval1 = this.totalsc;
            this.summarylist.push(rec);
        }else{
            rec = <Summaryrecord>{};
            rec.description = "DELIVERY CHARGE";
            rec.summaryval1 = this.totalcharges;
            this.summarylist.push(rec);
        }


        this.addHeaderRecord(" ");
        rec = <Summaryrecord>{};
        rec.description = "ROUNDING AMOUNT";
        rec.summaryval1 = this.totalrounding;
        rec.summaryval3 = 'B';
        this.summarylist.push(rec);




        this.addHeaderRecord(" ");
      
        rec = <Summaryrecord>{};
        rec.description = "NET REVENUE";
        rec.summaryval1 = Utils.round( totalgrosssale - this.totaldiscountonbills - totalcomplimentary  +  this.totalrounding ,3);
        rec.summaryval3 = 'B';
        this.summarylist.push(rec);


        rec = <Summaryrecord>{};
        rec.description = "NET REVENUE WITH CHARGES";
        rec.summaryval1 = Utils.round( totalgrosssale - this.totaldiscountonbills - totalcomplimentary + this.totalsc  + this.totalcharges +  this.totalrounding ,3);
        rec.summaryval3 = 'B';
        this.summarylist.push(rec);


        this.addHeaderRecord(" ");
        this.addHeaderRecord("TAXES");
        
        if(this.dataService.retailer.id != 2022){
            rec = <Summaryrecord>{};
            rec.description = "VAT";
            rec.summaryval1 = this.totalvat;
            this.summarylist.push(rec);

        }else{
            this.totalvat =0;
        }
        
        if(this.dataService.retailer.id == 1554  || this.dataService.retailer.id == 2022){

            rec = <Summaryrecord>{};
            rec.description = "TOURISM FEE";
            rec.summaryval1 = this.totaltfee;
            this.summarylist.push(rec);

            rec = <Summaryrecord>{};
            rec.description = "MUNICIPALITY FEE ";
            rec.summaryval1 = this.totalmfee;
            this.summarylist.push(rec);

            rec = <Summaryrecord>{};
            rec.description = "TOTAL TAXES";
            rec.summaryval1 = Utils.round( this.totalvat + this.totaltfee +  this.totalmfee,3);
            rec.summaryval3 = "B";
            this.summarylist.push(rec);


        }



        rec = <Summaryrecord>{};
        rec.description = "TOTAL REVENUE WITH TAXES & CHARGES";
        rec.summaryval1 = Utils.round( this.totalnetsales,3);
        rec.summaryval3 = 'B';
        this.summarylist.push(rec);

        
      

        /*this.addHeaderRecord(" ");
        this.addHeaderRecord("* Net Sales incl Taxes/Charges *");
        
        rec = <Summaryrecord>{};
        rec.description = "Net sales";
        rec.summaryval1 = this.totalnetsales;
        this.summarylist.push(rec);

        rec = <Summaryrecord>{};
        rec.description = "Tips";
        rec.summaryval1 = this.totaltips;
        this.summarylist.push(rec);

        rec = <Summaryrecord>{};
        rec.description = "Net sales incl tips";
        rec.summaryval1 = Utils.round(this.totalnetsales + this.totaltips,2);
        this.summarylist.push(rec);



     
        rec = <Summaryrecord>{};
        rec.description = "Net payment received (incl tips)";
        rec.summaryval1 = Utils.round(this.totalreceipts + this.totaltips,2);
        this.summarylist.push(rec); */


        this.addHeaderRecord(" ");
        this.addHeaderRecord("PAYMENTS RECEIVED");
     
        var cardsale = 0;

        for(var sumrec of this.modewisereceipts){
            var rec = <Summaryrecord>{};
            rec.description = sumrec.description + "[" + sumrec.summaryval2 + "] ";
            rec.summaryval1 = sumrec.summaryval1;
            if(sumrec.id == Constants.PYMT_CARD) cardsale = sumrec.summaryval1;

            this.summarylist.push(rec);

        }


        if(this.complimentaryreceipts && this.complimentaryreceipts.length > 0){
        
            this.addHeaderRecord(" ");
            this.addHeaderRecord("CARD PAYMENTS");
            
            
            for(var sumrec of this.cardtypereceipts){
                var rec = <Summaryrecord>{};
                rec.description = sumrec.description + "[" + sumrec.summaryval2 + "] ";
                rec.summaryval1 = sumrec.summaryval1;
                if(sumrec.id == Constants.PYMT_CARD) cardsale = sumrec.summaryval1;

                this.summarylist.push(rec);
            }

            rec = <Summaryrecord>{};
            rec.description = "TOTAL TIPS";
            rec.summaryval1 = Utils.round( this.totaltips,3);
            rec.summaryval3 = 'B';
            this.summarylist.push(rec);

        }
            
      


        if(this.othercreditreceipts && this.othercreditreceipts.length > 0){
        
            this.addHeaderRecord(" ");
            this.addHeaderRecord("OTHER CREDITS");
            
            
            for(var sumrec of this.othercreditreceipts){
                var rec = <Summaryrecord>{};
                rec.description = sumrec.description + "[" + sumrec.summaryval2 + "] ";
                rec.summaryval1 = sumrec.summaryval1;
                if(sumrec.id == Constants.PYMT_CARD) cardsale = sumrec.summaryval1;

                this.summarylist.push(rec);

            }
        }


        if(this.dataService.ccenabled){
            rec = <Summaryrecord>{};
            rec.description = "Net Call Center Sales";
            rec.summaryval1 = Utils.round(this.callcentersales,2);
            this.summarylist.push(rec);


            this.addHeaderRecord(" ");
            this.addHeaderRecord("* Payment mode wise receipts (Call center) *");
        
            var cardsale = 0;

            for(var sumrec of this.modewisereceiptscc){
                var rec = <Summaryrecord>{};
                rec.description = sumrec.description + "[" + sumrec.summaryval2 + "] ";
                rec.summaryval1 = sumrec.summaryval1;
                this.summarylist.push(rec);
            }

        }

            
        this.summarylist = [... this.summarylist];
        this.isRunning = false; 
    }


    


    //****************** get summary lists */
    createNewSummaryRecordOrder(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheckOrder(ord);
        rec.description = this.getDescritpionOrder(ord);
        rec.summaryval1 =  ord.cart.sumitemgross;
        rec.summaryval2 = ord.cart.sumitemtaxable;
        rec.summaryval11 = 1; 
        return rec; 
    }

    addToExistingRecordOrder(rec :Summaryrecord, ord : Order){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + ord.cart.sumitemgross,2);
        rec.summaryval2 =  Utils.round(rec.summaryval2 + ord.cart.sumitemtaxable,2);
        rec.summaryval11 = rec.summaryval11 + 1; 
        
        
    }

    getKeytoCheckOrder(ord : Order){
        var key ="";
        
        switch(this.currentviewtype){
            case Constants.VW_COUNTER: return key +  ord.countername;
            case Constants.VW_COUNTERTYPE: return key +  ord.countertypestr;
            case Constants.VW_DELIVERY_BOY: return key +  ord.deliveredbystr;
            case Constants.VW_SERVED_BY: return key + ord.servedbystr;
            case Constants.VW_OPERATOR: return key + ord.billedbystr;
            case Constants.VW_GUEST_TYPE_WISE: return key +  ord.guesttype;
            case Constants.VW_SECTION: return key + ord.sectionnamestr;
            case Constants.VW_WEEKDAY: return key + ord.weekdaystr;
            case Constants.VW_HOUR_OF_DAY: return key + ord.hourofdaystr;
            
        }
        
    }

    getDescritpionOrder(ord : Order){
        switch(this.currentviewtype){
            case Constants.VW_COUNTER: return ord.countername;
            case Constants.VW_COUNTERTYPE: return ord.countertypestr;
            case Constants.VW_DELIVERY_BOY: return ord.deliveredbystr;
            case Constants.VW_SERVED_BY: return ord.servedbystr;
            case Constants.VW_OPERATOR: return ord.billedbystr;
            case Constants.VW_GUEST_TYPE_WISE: return ord.guesttype;
            case Constants.VW_SECTION: return ord.sectionnamestr;
            case Constants.VW_WEEKDAY: return ord.weekdaystr;
            case Constants.VW_HOUR_OF_DAY: return ord.hourofdaystr;
        }
        
    }

    
    getSummaryListOrder(viewtype){
        var filteredlist = [];
        this.currentviewtype = viewtype;
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);

                this.setDetails(ord);
                var key = this.getKeytoCheckOrder(ord);
                var currec = Utils.findInSummaryList(filteredlist,key);
                if(currec){
                    this.addToExistingRecordOrder(currec,ord);
                }else{
                    currec = this.createNewSummaryRecordOrder(ord);
                    filteredlist.push(currec);    
                }

            }
                
                
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }


    createNewSummaryRecordItem(item : Cartlineitem,prod : Product){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheckItem(item,prod);
        rec.description = this.getDescritpionItem(item,prod);
        rec.summaryval1 =  item.grossamt;
        rec.summaryval2 = item.taxableamt;
        
        return rec; 
    }

    addToExistingRecordItem(rec :Summaryrecord, item : Cartlineitem){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + item.grossamt,4);
        rec.summaryval2 =  Utils.round(rec.summaryval2 + item.taxableamt,4);
    }

    getKeytoCheckItem(item : Cartlineitem,prod : Product){
        var key ="";
        
        switch(this.currentviewtype){
            case Constants.VW_PRODUCT: return key +  item.productid;
            case Constants.VW_CATEGORY: return key +  item.catid;
            case Constants.VW_SUBCATEGORY: return key +  item.subcatid;
            case Constants.VW_KITCHEN: return key + item.kitchenid;
        }
    }

    getDescritpionItem(item : Cartlineitem,prod : Product){
        switch(this.currentviewtype){
            case Constants.VW_PRODUCT: return  item.productname;

            case Constants.VW_CATEGORY:
                var cat = this.dataService.getCategoryForId (prod.categoryid);
                if(cat) return cat.name; return "";
            case Constants.VW_SUBCATEGORY: 
                var subcat = this.dataService.getSubcategoryForId (prod.subcategoryid);
                if(subcat) return subcat.name; return "";
            case Constants.VW_KITCHEN: 
                return this.kitchenService.getKitchenNameForId( item.kitchenid);
            
            
        }
        
    }


    getSummaryListItem(viewtype){
        var filteredlist = [];
        this.currentviewtype = viewtype;

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
        
                var ord = this.orderService.getClone(item);
                
                for(var lineitem of ord.cart.lineitems){
                    var prod = this.dataService.getProductForId(lineitem.productid);
                    var key = this.getKeytoCheckItem(lineitem,prod);
                    var currec = Utils.findInSummaryList(filteredlist,key);

                    if(currec){
                        this.addToExistingRecordItem(currec,lineitem);
                    }else{
                        currec = this.createNewSummaryRecordItem(lineitem,prod);
                        filteredlist.push(currec);    
                    }
                }

            }
                
                
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;
    }

    getTip(pymt : Payment){
        var tip = 0;
        
        if(pymt.paymentmode == Constants.PYMT_CARD){
            var strtip = "" + pymt.tip;
            if(strtip && strtip.length > 0){
                try{
                    tip = parseFloat(strtip);
                }catch(err){}
            }
        }

        return tip;

    }

    //Payment summary 
    createNewPymtSummaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = pymt.paymentmode;
        rec.description = pymt.paymentmodename;
        rec.summaryval1 = Utils.round(pymt.paidamount,2);
        rec.summaryval2 = 1;
        return rec; 
    }

    addToExistingPymtRecord(rec :Summaryrecord, pymt : Payment){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + pymt.paidamount,2);
        rec.summaryval2 = rec.summaryval2 + 1; 
    }

     //Payment summary 
     createNewCardtypeRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = pymt.cardtype;
        rec.description = pymt.cardtype
        rec.summaryval1 = Utils.round(pymt.paidamount,2);
        rec.summaryval2 = 1;
        return rec; 
    }


     //Payment summary 
     createNewComplimentaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = pymt.complimentarytype;
        rec.description = pymt.complimentarytype;
        rec.summaryval1 = Utils.round(pymt.paidamount,2);
        rec.summaryval2 = 1;
        return rec; 
    }


    createNewOtherCreditRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = pymt.othercredittype;
        rec.description = pymt.othercredittype;
        rec.summaryval1 = Utils.round(pymt.paidamount,2);
        rec.summaryval2 = 1;
        return rec; 
    }


    getCardTypeSummaryList(){
        var filteredlist = [];
        
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_CARD){
                            var currec = Utils.findInSummaryList(filteredlist,pymt.cardtype);
                            if(currec){
                                this.addToExistingPymtRecord(currec,pymt);
                            }else{
                                currec = this.createNewCardtypeRecord(pymt);
                                filteredlist.push(currec);    
                            }
                        }
                        
                    }
                }
            }
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }

    getComplimentarySummaryList(){
        var filteredlist = [];
        
        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_COMPLIMENTARY){
                            var currec = Utils.findInSummaryList(filteredlist,pymt.complimentarytype);
                            if(currec){
                                this.addToExistingPymtRecord(currec,pymt);
                            }else{
                                currec = this.createNewComplimentaryRecord(pymt);
                                filteredlist.push(currec);    
                            }
                        }
                        
                    }
                }
            }
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }



    getOtherCreditSummaryList(){
        var filteredlist = [];
        
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_OTHER_CREDIT){
                            var currec = Utils.findInSummaryList(filteredlist,pymt.othercredittype);
                            if(currec){
                                this.addToExistingPymtRecord(currec,pymt);
                            }else{
                                currec = this.createNewOtherCreditRecord(pymt);
                                filteredlist.push(currec);    
                            }
                        }
                        
                    }
                }
            }
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }



    getPaymentSummaryList(){
        var filteredlist = [];
        
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        var currec = Utils.findInSummaryList(filteredlist,pymt.paymentmode);
                        if(currec){
                            this.addToExistingPymtRecord(currec,pymt);
                        }else{
                            currec = this.createNewPymtSummaryRecord(pymt);
                            filteredlist.push(currec);    
                        }
                        
                    }
                }
            }
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }

    getPaymentSummaryListCC(){
        var filteredlist = [];
        
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED && item.ccordid && item.ccordid > 0){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        var currec = Utils.findInSummaryList(filteredlist,pymt.paymentmode);
                        if(currec){
                            this.addToExistingPymtRecord(currec,pymt);
                        }else{
                            currec = this.createNewPymtSummaryRecord(pymt);
                            filteredlist.push(currec);    
                        }
                        
                    }
                }
            }
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }


    getDiscountRange(ord : Order){
        var discval = ord.cart.totaldiscount / ord.cart.sumitemgross * 100;
        if(discval  <= 5) return "1-5";
        if(discval  <= 10) return "6-10";
        if(discval  <= 15) return "11-15";
        if(discval  <= 20) return "16-20";
        if(discval  <= 25) return "21-25";
        if(discval  <= 30) return "26-30";
        if(discval  <= 50) return "31-50";
        return "51-100";
        
        
    }

    getInitialDiscountSummaryRecord(range){
        var rec = <Summaryrecord>{};
        rec.id = range; 
        rec.description = range + "%";
        rec.summaryval1 = 0;
        rec.summaryval2 = 0;

        return rec;
    }
    

    //Payment summary 
    createNewDiscountSummaryRecord(ord : Order){
        var rec = <Summaryrecord>{};

        var range = this.getDiscountRange(ord);
        rec.id = range; 
        rec.description = range + "%";
        /*if(ord.cart.discountdetail.discounttype == "1"){
            rec.id = ord.cart.discountdetail.discountvalue;
             rec.description = ord.cart.discountdetail.discountvalue + "%";
        }else{
            var discval = Utils.round(ord.cart.totaldiscount/ord.cart.sumitemgross * 100,0);
            if(discval == 10 || discval == 15 || discval == 20 || discval == 25 || discval == 50){
                rec.id = discval;
                rec.description = discval + "%";
            }else{
                rec.id = 0;
                rec.description = 'Amount Discount';
            }
        }*/

        rec.summaryval1 = ord.cart.totaldiscount;
        rec.summaryval2 = rec.summaryval2 + 1; 
        return rec; 
    }

    addToExistingDiscountRecord(rec :Summaryrecord, ord : Order){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + ord.cart.totaldiscount,2);
        rec.summaryval2 = rec.summaryval2 + 1;
    }

    bifurcateDiscounts ( ord : Order){
        ord.fooddisc = 0;
        ord.bevdisc = 0;
        ord.misdisc = 0;
        ord.tobdisc = 0;

        for(var item of ord.cart.lineitems){
            if(item.discount > 0){
                var cat = this.dataService.getCategoryForId(item.catid);
                if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                    ord.bevdisc = Utils.round(ord.bevdisc + item.discount,3);
                }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                    ord.fooddisc = Utils.round(ord.fooddisc + item.discount,3);
                }else if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                    ord.tobdisc = Utils.round(ord.tobdisc + item.discount,3);
                }else{
                    ord.misdisc = Utils.round(ord.misdisc + item.discount,3);
                }
            }
        } 
    }


    getDiscountSummaryList(){
        var filteredlist = [];
        filteredlist.push(this.getInitialDiscountSummaryRecord("1-5"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("6-10"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("11-15"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("16-20"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("21-25"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("26-30"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("31-50"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("51-100"));
        
        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){

                var ord = this.orderService.getClone(item);
                this.bifurcateDiscounts(ord);

                if(ord.cart.totaldiscount > 0){
                    var key  = this.getDiscountRange(ord);
                    //if(ord.cart.discountdetail.discounttype != "1") key= 0;

                    var currec = Utils.findInSummaryList(filteredlist,key);
                    if(currec){
                        this.addToExistingDiscountRecord(currec,ord);
                    }else{
                        currec = this.createNewDiscountSummaryRecord(ord);
                        filteredlist.push(currec);    
                    }
                }

                this.totalfooddisc = this.totalfooddisc + ord.fooddisc;
                this.totalbevdisc = this.totalbevdisc + ord.bevdisc;
                this.totaltobdisc = this.totaltobdisc + ord.tobdisc;
                this.totalmiscdisc = this.totalmiscdisc + ord.misdisc;
                
                
                
            }
        }

        //filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.description)  < (b.description)  ? -1 : 1 );
        return filteredlist;

    }
    

}


