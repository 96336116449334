import {Kitchen} from '../domain/kitchen'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { Billingcounter } from '../domain/billingcounter';
import { Consumercart } from '../domain/consumercart';
import { Order } from '../domain/order';
import { Cartlineitem } from '../domain/cartlineitem';
import { Kot } from '../domain/kot';
import { Kottoken } from '../domain/kottoken';

import { Billingcounterservice } from './billingcounterservice';
import { DatePipe } from '@angular/common';
import { Printingservice } from './printingservice';
import { Tableorder } from '../domain/tableorder';
import { Tablerequest } from '../domain/tablerequest';
import { Kitchenservice } from './kitchenservice';
import { Userservice } from './userservice';
import { Qrtablemap } from '../domain/qrtablemap';
import { Utils } from './utils';

@Injectable()
export class Callcenterservice  {
     constructor(
         private couchdbService : Couchdbservice,
         private dataService : Appdataservice,
         private kitchenService : Kitchenservice,
         private dynamoDBService : Dynamodbservice,
            
         private userService : Userservice,
         private printerService : Printerservice,
         private printingService : Printingservice,
         private datePipe : DatePipe,
         private counterService : Billingcounterservice,
         
     ){
         this.ccordersinprocess = [];
     }
    
    ccordersinprocess : Order[];
    
    async fetchPendingorders(){

        try{
            var conditionexpression = "posid  = :v_posid and orderstatus <= :v_orderstatus";  
            var attribparams= {":v_posid" : this.dataService.getPosId() , ":v_orderstatus" : 3 };
            var retlist  = <Order[]> await this.dynamoDBService.queryOnIndex("callcenterorders", "posid-orderstatus-index", conditionexpression,attribparams);
            return retlist;
        }catch(err){
            console.error('Error fetching Orders ' + err);
        }


    }

    refreshTimer;

    areNewOrders(){
        for(var order of this.ccordersinprocess){
            console.log('*** ORDER STATUS ' + order.orderstatus);
            if(order.orderstatus == Constants.NEW_ORDER){
                return true; 
            }
        }
        return false; 
    }

    setStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
    
         //Get status 
         ord.statusstr = Utils.getStatusStr(ord);
         ord.orderitemsstr = Utils.itemsAsString(ord);
         ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
         if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
            ord.details = Utils.getOrderDetails(ord);
        }

    }
    async setPendingOrders(){
        try{
            console.log('|CCORDERS|ORDER REFRESH');
            //this.pendingorderlist = [];
            var orderlist = await this.fetchPendingorders();
            if(orderlist){
                orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

                for(var ord of orderlist){
                    this.setStrings(ord);
                }
                this.ccordersinprocess = orderlist; 
            }
            if(this.areNewOrders()){
                this.playAudio();
            }
        
        }
        catch(err){}

        //if(this.dataService.isuserloggedin)
        this.refreshTimer = setTimeout(()=>{this.setPendingOrders()},10000);
    
    }


    clearTimer(){
        console.log('|BCTRTEST|LIVE REFRSH|Clearingtimeout')
        clearTimeout(this.refreshTimer);
    }
    
    lastsoundplayed  =0;

    playAudio(){
        try{
            this.lastsoundplayed = new Date().getTime();

            let audio = new Audio();
            audio.src = "assets/demo/images/readyalert.mp3";
            audio.load();
            audio.play();
        }catch(err){
            console.log('|SOUND|Error playing sound ' + err);
        }
    }


    
    
    


     

}