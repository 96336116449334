import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,GalleriaModule, InputTextModule, OverlayPanel, ContextMenu,Message,DataTable,TabView, AutoComplete, Header,Dialog,InputText,Button} from 'primeng/primeng';
import { DataViewModule } from 'primeng/dataview';
import {DragDropModule} from 'primeng/dragdrop';

import {DataService} from '../service/dataservice';
import {Product} from '../domain_old/product';


import {Retailerproduct} from '../domain_old/retailerproduct';
import {Billingcounter} from '../posdomain/billingcounter';
import {Cart} from '../posdomain/cart';
import {TabViewModule} from 'primeng/tabview';

import {Displayimage} from '../domain_old/displayimage';
import {CustomizationOption} from '../domain_old/customizationoption';
import {Router} from '@angular/router';	
import {Constants} from '../domain/constants';
import {Cartlineitem} from '../posdomain/cartlineitem';
import {Discountcaller} from '../domain_old/discountcaller';

import {HostListener} from '@angular/core';
import {ToolbarModule} from 'primeng/toolbar';
import {MenuItem, MessageService, SelectItem} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {SidebarModule} from 'primeng/sidebar';
import {GMapModule} from 'primeng/gmap';






import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../service/billingservice';
import { Appdataservice } from '../service/appdataservice';
import { Orderservice } from '../service/orderservice';
import { Payment } from '../domain/payment';
import { Order } from '../domain/order';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Utils } from '../service/utils';
import { Settingsservice } from '../service/settingsservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Userservice } from '../service/userservice';
import { DatePipe } from '@angular/common';
import { Qrorderservice } from '../service/qrorderservice';
import { Printerservice } from '../service/printerservice';
import { Printingservice } from '../service/printingservice';
import { Callcenterservice } from '../service/callcenterservice';
import { Urbanpiperservice } from '../service/urbanpiperservice';

@Component({
    selector: 'toolbar',
    templateUrl: 'toolbar.html'
})
export class Toolbar implements OnInit {


        printertypes : SelectItem[];
    
      
        constructor(private confirmationService : ConfirmationService,
            public billingService : Billingservice,
            public counterService : Billingcounterservice,  
            public dataService: Appdataservice,
            public messageService : MessageService,
            public settingsService : Settingsservice,
            public userService : Userservice,
            public dbService : Dynamodbservice,
            public datePipe : DatePipe,
            public ccService : Callcenterservice,
            public upService : Urbanpiperservice,
            public printerService : Printerservice,
            public printingService : Printingservice,
            public orderService : Orderservice,
            public qrService : Qrorderservice,
            private _router : Router) {
            
            if(!dataService.isuserloggedin){
                this._router.navigate(['/']);
            }

        }

        setStatus(){
            this._router.navigate(['/statusupdate']);
        }

        showPendingorders(){
            this.counterService.selectedSection = 'CC';
            this.counterService.setDisplayCounterlist();
            this._router.navigate(['/pendingorderlist']);
        }

        showOnlineorders(){
            this.counterService.selectedSection = 'AGR';
            this.counterService.setDisplayCounterlist();
            this._router.navigate(['/onlineorderlist']);
        }
        tablerowfont;
        tablerowpadding;
        scrollbarwidth;
        serviceport;
        cardlogin; 

        tablerowfontoptions : SelectItem[];
        tablerowpaddingoptions:  SelectItem[];
        scrollbarwidthoptions : SelectItem[];
        

        saveSettings(){
            this.settingsService.saveSettings();
            this.showSuccessToast('Settings saved!');
            this.displayprinterSettings = false;
            
        }
        terminalid;

        applyDisplaySettings(){

            if(!this.terminalid || this.terminalid.trim().length == 0){
                this.showErrorToast('Please provide terminal id');
                return;
            }

            let root = document.documentElement;
            root.style.setProperty('--tablerow-padding', this.tablerowpadding);
            root.style.setProperty('--tablerow-font-size', this.tablerowfont);
            root.style.setProperty('--scrollbar-width', this.scrollbarwidth);
    
            localStorage.setItem('tablerowpadding',this.tablerowpadding);
            localStorage.setItem('tablerowfont',this.tablerowfont);
            localStorage.setItem('scrollbarwidth',this.scrollbarwidth);
            if(this.cardlogin)
                localStorage.setItem('cardlogin',this.cardlogin);
            else
                localStorage.removeItem('cardlogin');
            
            //localStorage.setItem('serviceport',this.serviceport);
    
            if(this.serviceport && this.serviceport.length > 0){
                localStorage.setItem('serviceport',this.serviceport);
            }else{
                localStorage.removeItem('serviceport');
            }

            localStorage.setItem('terminalid',this.terminalid);
            localStorage.setItem('posid',this.dataService.getPosId());
            

            this.billingService.makeSettingsDialogVisible = false; 
            this.showSuccessToast('Settings updated!');
        
        }
    

        showSuccessToast(message){
            this.messageService.clear('printertoast');
            this.messageService.add({severity:'success', life: 1000,  key: 'printertoast', closable: true, summary: 'Success', detail: message});
        }
        
        showErrorToast(message){
            this.messageService.clear('printertoast');
            this.messageService.add({severity:'error', life: 8000, key: 'printertoast', closable: true, summary: 'Error', detail: message});
            this.showspinner = false; 
            this.iseodunderprocess = false; 
        }
        
        manageorders(){
            this._router.navigate(['/ordermanagement']);
        }

        managedeliveries(){
            this._router.navigate(['/deliverymanagement']);
        }
        
        showspinner; 
        iseodunderprocess; 
        editsalesallowed;

        async refreshMasters(){
            this.showspinner = true;
            
            var email = '';
            var pass = '';

            var loginResponse = localStorage.getItem('loginresponse');
            if(loginResponse && loginResponse.length > 0){
                try{
                    var jsonresp = JSON.parse(loginResponse);
                    email = jsonresp.retailer.registeredemailid;
                    pass = jsonresp.retailer.userpass;
                }catch(err){}
            }
            
            
            var retstatus = await this.dataService.initializeAppData(email, pass);
                if(retstatus){
                    this.showSuccessToast('Master data refreshed!');
                }else
                    this.showErrorToast('Error refreshing master data!');

            
            this.showspinner = false;
            
        }

        logout(){

            console.log('|TEST|*********** Logging out..' );
            this.dataService.isuserloggedin = false;
            this.dataService.isadminlogin = false; 
            this.dataService.ismainadmin = false;

            this.userService.loggedinuser = null;
            this.userService.loggedinusergroup = null;
            this.userService.processLogout();
            
            this.orderService.pedningorderlist = [];
            this.orderService.latestorderstamp = 0;
            localStorage.removeItem('email');
            localStorage.removeItem('pass');

            try{
                //Clear out the polling timer
                console.error('|BCTRTEST|LIVE REFRSH|Now clearing timer ' );
                this.counterService.clearTimer();
                this.ccService.clearTimer();
            }catch(err){
                console.error('|BCTRTEST|LIVE REFRESH|ERROR clearing timer ' + err);
            }
            this._router.navigate(['/']);


        }
        
        alerts(){
            this._router.navigate(['/alertreport']);
        }
    
        dashboard(){
            this._router.navigate(['/dashboard']);
        }
        
        isMobile(){
            if(window.innerWidth < 900) return true;
            return false;
        }

        home(){
            this._router.navigate(['/billingscreen']);
        }

        counterView(){
            this._router.navigate(['/counterview']);
        }

        public getNextEODDate(lasteoddate){
            var d = Utils.getDateFromBusinessdate(""+ lasteoddate);
            d.setDate(d.getDate() + 1);
    
            // This will return previous day
            return parseInt(this.datePipe.transform(d, "yyyyMMdd")); 
        }

        async checkPeningOrders(eoddate){
            var orderlist = <Order[]> await this.orderService.getOrderlist(eoddate,eoddate);
            if(orderlist){
                for(var order of orderlist){


                    if(order.orderstatus == Constants.POS_ORDER_PROCESSED &&  order.cart.pendingamount > 0){
                        this.showErrorToast("There are unsettled orders for : " + Utils.getBusinessDateAsString("" + eoddate) + ", please settle the same before proceeding with EOD!");
                        return false;         
                    }
                }

                return true;
            }else{
                this.showErrorToast('Error checking pending orders, please try again!');
                return false; 
            }
        }
        
        async cashierChange(){
            if(this.dataService.lasteoddate && this.dataService.lasteoddate < this.dataService.getPreviousBusinessDate()){
                this.showErrorToast('EOD for previous days has not been completed, please complete the same!');
                return false; 
            }

            //Check and open printer 
            for(var printer of this.printerService.printerlist){
                if(printer.printertype == Constants.SYSTEM){
                    this.printingService.openCashDrawer(printer);
                }
            }


            this._router.navigate(['/cashierchange']);

        }

        showSettingsPanel(){
            this.billingService.makeSettingsDialogVisible = true;
        }

        async processEOD(){

            this.showspinner = true;
            this.iseodunderprocess = true;
            for(var ctr of this.counterService.billingcounterlist){
                if(ctr.active){
                    if(ctr.cart.sumitemcount > 0 || ctr.occupiedon > 0){
                        this.showErrorToast( ctr.countername  + ', Id: ' + ctr.id + ' still running/occupied, please clear  before proceeding for EOD!');
                        return; 
                    }

                    if(ctr.proformaid && ctr.proformaid > 0){
                        this.showErrorToast('There is proforma generated on : ' + ctr.countername);
                        return; 
                    }
                }
            }

            if(this.dataService.isRollOverEOD()){
                if(!this.dataService.validateEODTimeForRollover()){
                    this.showErrorToast('All EODs till the current date have already been completed!');
                    return; 
                }
            }else{
                if(this.dataService.lasteoddate && this.dataService.lasteoddate >= this.dataService.getCurrentBusinessDate()){
                    this.showErrorToast('All EODs till the current date have already been completed!');
                    return; 
                }

                if(!this.dataService.validateEODHours()){
                    this.showErrorToast('EOD for the current date can not be run before : ' + this.dataService.retailer.appconfig.posconfig.mineodhours + ' Hours.');
                    return;
                }
            }

            var eoddate = this.dataService.getCurrentBusinessDate();
            var prevdate = this.dataService.getPreviousBusinessDate();

            if(this.dataService.lasteoddate != null && this.dataService.lasteoddate > 0){
                if(this.dataService.lasteoddate < prevdate){
                    eoddate  = this.getNextEODDate(this.dataService.lasteoddate);
                }
            }

            var ret = await this.checkPeningOrders(eoddate);
            if(!ret) return; 

            console.log('Running EOD for :: ' + eoddate);

            this.showspinner = false;
            this.iseodunderprocess = false;


            //set the EOD date 
            this.billingService.eoddate = eoddate;


            //Check and open printer 
            for(var printer of this.printerService.printerlist){
                if(printer.printertype == Constants.SYSTEM){
                    this.printingService.openCashDrawer(printer);
                }
            }

            this._router.navigate(['/processeod']);
            

        }
        
        displayprinterSettings; 

        goQROrders(){
            this.counterService.selectedSection = 'QR';
            this.counterService.setDisplayCounterlist();
            localStorage.setItem('selectedsection','QR');
            this._router.navigate(['/qrprocessing']);
        }

        showPrinterSettingsDialog(){
            this.displayprinterSettings = true;
        }
        gotoCounterSection(sectionname){
            this.counterService.selectedSection = sectionname;
            this.counterService.setDisplayCounterlist();
            localStorage.setItem('selectedsection',sectionname);
            this.home();
        }
    
        reportsmenuitems : any[] = [];
        cashiermenuitems : any[] = [];
        setupmenuitems : any[] = [];

        ngOnInit(){
            this.printertypes = [];
            this.printertypes.push({label:'System', value: Constants.SYSTEM});
            this.printertypes.push({label:'LAN', value: Constants.LAN});
            this.printertypes.push({label:'Browser', value: Constants.BROWSER});
            this.printertypes.push({label:'No Print', value: Constants.NOPRINT});

            var kotreportitems = [
                {label: 'KOT details report', routerLink: ['/kotdetails']},               
                {label: 'KOT Cancellations', routerLink: ['/kotcancellations']},
                {label: 'Item Cancellations', routerLink: ['/kotitemcancellations']},
                                                                      
            ];

            var salesreportitems = [
                {label: 'Order details report', routerLink: ['/orderdetails']},
                {label: 'Sales summary report', routerLink: ['/salessummary']},
                {label: 'Item sales summary', routerLink: ['/itemsalessummary']},
                {label: 'Menu analysis', routerLink: ['/menuanalysis']},
                {label: 'Menu analysis (Date Range)', routerLink: ['/menuanalysisdaterange']},
                
                {label: 'Scheme item details', routerLink: ['/schemeitemdetails']},
                {label: 'Orders with scheme', routerLink: ['/orderswithscheme']},
                {label: 'Dine in analysis', routerLink: ['/datewisedineinanalysis']},
                {label: 'Focus detail', routerLink: ['/focusdetail']},
                {label: 'KOT Bill Reco', routerLink: ['/kotbillreco']}
                
                
                
            ];
            
            if(this.dataService.retailer.id == 1554){
                salesreportitems.push({label: 'Tax details', routerLink: ['/taxdetails']});
            }

            var paymentreportitems = [
                {label: 'Payment details report', routerLink: ['/paymentdetails']},              
                {label: 'Payment summary report', routerLink: ['/paymentsummary']}              

            ];

            this.reportsmenuitems = [
                {label: 'KOT reports', icon: 'ui-icon-bookmark',
                        items:kotreportitems
                }];

            if(this.userService.salesreportsallowed){
                this.reportsmenuitems.push({label: 'Sales reports', icon: 'ui-icon-print',items:salesreportitems});
            }

            if(this.userService.paymentsreportsallowed){
                this.reportsmenuitems.push({label: 'Payment reports', icon: 'ui-icon-attach-money',items:paymentreportitems});
            }

            if(this.userService.cashierfunctionsallowed){
                this.reportsmenuitems.push({label: 'Till report', icon: 'ui-icon-print', routerLink: ['/tillreport']});
            }


            if(this.userService.businesssummaryallowed){
                this.reportsmenuitems.push({label: 'Collection Summary', icon: 'ui-icon-blur-linear', routerLink: ['/shortsummaryreport']});
                this.reportsmenuitems.push({label: 'Business Summary', icon: 'ui-icon-blur-linear', routerLink: ['/businesssummaryreport']});
                this.reportsmenuitems.push({label: 'Monthly Dashboard', icon: 'ui-icon-bubble-chart', routerLink: ['/branddashboard']});

                this.reportsmenuitems.push({label: 'Customer Feedback', icon: 'ui-icon-arrow-forward', routerLink: ['/customerfeedback']});
                this.reportsmenuitems.push({label: 'Server Performance', icon: 'ui-icon-arrow-forward', routerLink: ['/serverperformance']});
            }
            //this.reportsmenuitems.push({label: 'Build Summary', icon: 'ui-icon-blur-linear', routerLink: ['/buildsummary']});
            this.reportsmenuitems.push({label: 'Customization Report', icon: 'ui-icon-blur-linear', routerLink: ['/customizationreport']});


            if(this.dataService.ismainadmin){
                this.setupmenuitems = [
                    {label: 'Printers', icon: 'ui-icon-print', routerLink: ['/printers']},
                    {label: 'Billing counters', icon: 'ui-icon-apps', routerLink: ['/billingcounters']},
                    {label: 'Kitchens', icon: 'ui-icon-kitchen', routerLink: ['/kitchens']},
                    {label: 'Delivery boys', icon: 'ui-icon-motorcycle', routerLink: ['/deliveryboys']},
                    {label: 'User groups', icon: 'ui-icon-people', routerLink: ['/usergroups']},
                    {label: 'Users', icon: 'ui-icon-person', routerLink: ['/users']},
                    {label: 'Terminal Settings', icon: 'ui-icon-settings',  command: () => this.showSettingsPanel() },
                    {label: 'Copy to offline', icon: 'ui-icon-content-copy', routerLink: ['/migratetocouch']},
                    {label: 'Send offline data', icon: 'ui-icon-arrow-forward', routerLink: ['/syncfromcouch']},
                    {label: 'Reprocess EOD', icon: 'ui-icon-refresh', routerLink: ['/reprocesseod']}
                    
                ];
            }else{
                this.setupmenuitems = [];
                if(this.userService.createusersallowed){
                    this.setupmenuitems = [
                        {label: 'Users', icon: 'ui-icon-person', routerLink: ['/users']},
                        {label: 'Delivery boys', icon: 'ui-icon-motorcycle', routerLink: ['/deliveryboys']}
                    ];
                }

                if(this.userService.offlinesyncallowed){
                    this.setupmenuitems.push({label: 'Send offline data', icon: 'ui-icon-arrow-forward', routerLink: ['/syncfromcouch']});
                }

            }



            this.cashiermenuitems = [
                {label: 'Cashier change form', icon: 'ui-icon-apps', command: () => this.cashierChange() },
                {label: 'End of day', icon: 'ui-icon-apps',  command: () => this.processEOD()}
                
            ];

            //Settings
            this.tablerowfontoptions = []; 
            this.tablerowfontoptions.push({label: 'Small', value: '13px'});
            this.tablerowfontoptions.push({label: 'Medium', value: '14px'});
            this.tablerowfontoptions.push({label: 'Large', value: '16px'});

            this.tablerowpaddingoptions = []; 
            this.tablerowpaddingoptions.push({label: 'Small', value: '3px'});
            this.tablerowpaddingoptions.push({label: 'Medium', value: '5px'});
            this.tablerowpaddingoptions.push({label: 'Large', value: '7px'});

            this.scrollbarwidthoptions = []; 
            this.scrollbarwidthoptions.push({label: 'Thin', value: '5px'});
            this.scrollbarwidthoptions.push({label: 'Medium', value: '7px'});
            this.scrollbarwidthoptions.push({label: 'Thick', value: '16px'});

            this.tablerowpadding = '5px';
            this.tablerowfont = '14px';
            this.scrollbarwidth = '7px';

            if(localStorage.getItem("tablerowpadding") && localStorage.getItem("tablerowpadding").length > 0){
                this.tablerowpadding = localStorage.getItem("tablerowpadding");
            }        
            if(localStorage.getItem("tablerowfont") && localStorage.getItem("tablerowfont").length > 0){
                this.tablerowfont = localStorage.getItem("tablerowfont");
            }        
            if(localStorage.getItem("scrollbarwidth") && localStorage.getItem("scrollbarwidth").length > 0){
                this.scrollbarwidth = localStorage.getItem("scrollbarwidth");
            }
            
            
            if(localStorage.getItem("serviceport") && localStorage.getItem("serviceport").length > 0){
                this.serviceport = localStorage.getItem("serviceport");
            }


            if(localStorage.getItem("terminalid") && localStorage.getItem("terminalid").length > 0){
                this.terminalid = localStorage.getItem("terminalid");
            }
            
            if(localStorage.getItem("cardlogin")){
                this.cardlogin = true;
            }else{
                this.cardlogin = false; 
            }
        

            let root = document.documentElement;
            root.style.setProperty('--tablerow-padding', this.tablerowpadding);
            root.style.setProperty('--tablerow-font-size', this.tablerowfont);
            root.style.setProperty('--scrollbar-width', this.scrollbarwidth);
            

            //routerLink: ['/cashierchange']
        }

        
    
}


