import { Injectable } from "@angular/core";
import { Consumercart } from "../domain/consumercart";
import { Cartlineitem } from "../domain/cartlineitem";
import { Order } from "../domain/order";
import { DataService } from "./dataservice";
import { Appdataservice } from "./appdataservice";
import { Dynamodbservice } from "./dynamodbservice";
import { Constants } from "../domain/constants";
import { Billingcounter } from "../domain/billingcounter";
import { Couchdbservice } from "./couchdbservice";
import { Printingservice } from "./printingservice";
import {Receiptno} from  "../domain/receiptno";
import { Userservice } from "./userservice";
import { Aggregatorservice } from "./aggregatorservice";
import { UPMessage } from "../updomain/upmessage";
import { O_TRUNC } from "constants";
import { Payment } from "../domain/payment";
import { Utils } from './utils';

@Injectable()
export class Orderservice  {

    cart : Consumercart;
    orderfortracking : Order;
    pedningorderlist : Order[];
    latestorderstamp = 0;
    
    constructor (
            public dataService : Appdataservice,
            public dbService : Dynamodbservice,
            public userService : Userservice,
            private aggrService : Aggregatorservice,
            
            public printingService : Printingservice,
            public couchDBService : Couchdbservice,    
            public dynamoDBService : Dynamodbservice,
        ) {
        this.cart = new Consumercart();
        //this.cart.initCart();
    }


   /* addItemsToCart(prod,quantity,customization,customizationamt,instructions){
        this.cart.addToCart(prod,quantity,customization,customizationamt,instructions,null,0);
    }

    removeItemsToCart(prod,quantity,customization,customizationamt,instructions){
        this.cart.removeFromCart(prod,quantity,customization,instructions);
    }*/

     
     clearCart(){
         //this.cart.initCart();
     }

     /*repeatOrder(order : Order){
         this.clearCart();
         for (var item of order.cart.lineitems){
             var prod  = this.dataService.getProductForId(item.productid);
             if(prod){
                    var instructions = item.instructions; 
                    if(instructions == null) instructions = '';
                    this.cart.addToCart(prod,item.quantity, item.customization, item.customizationamt,instructions,null,0);
             }
         }
     }*/



     addEditInList(ord : Order){
         console.log('Adding order ' + ord.id);

        var orderfound = false; 
        for(var i=0;i<this.pedningorderlist.length; i++){
            if(this.pedningorderlist[i].id === ord.id){
                this.pedningorderlist[i] = ord;
                orderfound = true; 
                break;
            }
        }

        if(!orderfound){
            console.log('pushing in pending list');
            this.pedningorderlist.push(ord);
            console.log('pending list size ' + this.pedningorderlist.length );
        }
     }

     
     checkNewOrders(){
        for(var order of this.pedningorderlist){
            if(order.orderstatus == 0) {
                this.neworders = true;
                return;
            }
        }

        this.neworders= false;

     }
     
     neworders;

     lastorderfetched; 
    
    async setPendingOrders(){
        if(!this.latestorderstamp || this.latestorderstamp  == 0){
            this.latestorderstamp = new Date().getTime() - (24 * 60 * 60 * 1000);
            this.pedningorderlist = [];
        }

        var orderlist = await this.dbService.fetchPendingorders(this.latestorderstamp);
        console.log('|TEST|Querying at ' + this.latestorderstamp);
        
        

        if(orderlist){
            //Orders were read regardless of empty list
            this.lastorderfetched = new Date().getTime();
            console.log('|TEST|Num Orders ' + orderlist.length);
        }

        if(orderlist && orderlist.length > 0){
            orderlist.sort((a:Order, b: Order)=> a.updatedon < b.updatedon  ? -1 : 1 );

            for(var order of orderlist){
                if(order.updatedon > this.latestorderstamp){
                    this.latestorderstamp = order.updatedon;
                }

                if((order.locationid == 0 || this.dataService.retailer.locationid == 0 || this.dataService.retailer.locationid == order.locationid) && order.orderstatus != Constants.ORDER_EXECUTED && order.orderstatus != Constants.ORDER_REJECTED ) {
                    this.addEditInList(order);
                }else{
                    if(order.oldlocid && this.dataService.retailer.locationid > 0 && order.oldlocid == this.dataService.retailer.locationid){
                        this.updateInList(order);   //It will get removed 
                    }
                }

            }

            this.pedningorderlist.sort((a:Order, b: Order)=> a.orderstatus  < b.orderstatus  ? -1 : 1 );
       }
       this.checkNewOrders();

    }

    setTimestamp(order : Order, newstatus){
        switch(newstatus){
            case Constants.CONFIRMED_ORDER:
                 order.acceptedon = new Date().getTime(); return;
            case Constants.FOOD_READY:
                order.foodreadyon = new Date().getTime(); return;
            case Constants.OUT_FOR_DELIVERY:
                order.dispatchedon = new Date().getTime(); return;
            case Constants.ORDER_EXECUTED:
                order.completedon = new Date().getTime(); return;
            case Constants.ORDER_REJECTED:
                order.rejectedon = new Date().getTime(); return;
                         
        }
        

    }
    getClone (order : Order){
        var temp = new Order();
        for(let prop in order) {
            temp[prop] = order[prop];
        }
        return temp;

    }


    updateInList(updatedOrder : Order){
        for(var i=0;i<this.pedningorderlist.length;i++){
            if(this.pedningorderlist[i].id == updatedOrder.id){
                if(updatedOrder.orderstatus === Constants.ORDER_EXECUTED 
                    || updatedOrder.orderstatus === Constants.ORDER_REJECTED 
                    || (updatedOrder.oldlocid && this.dataService.retailer.locationid > 0 && updatedOrder.oldlocid == this.dataService.retailer.locationid)){
                    this.pedningorderlist.splice(i,1);
                }else{
                    this.pedningorderlist[i] = updatedOrder;
                }
                
            }
        }

        this.pedningorderlist.sort((a:Order, b: Order)=> a.orderstatus <  b.orderstatus  ? -1 : 1 );
        this.pedningorderlist = [... this.pedningorderlist];

    }

    isUpdatingOrder;

    async updateOrder(order : Order, newstatus, rejectreason?){
        this.isUpdatingOrder = true;
        var cloneOrder  = this.getClone(order);

        console.log('New Status ' + newstatus );
        cloneOrder.orderstatus = newstatus;
        cloneOrder.rejectreason = rejectreason;
        cloneOrder.updatedon = new Date().getTime();
        

        this.setTimestamp(cloneOrder,newstatus);

        var status = await  this.dbService.putItem('order', cloneOrder);
        if(status){
            console.log ('order updated!!');
            this.updateInList(cloneOrder);
        }
        this.isUpdatingOrder = false;
        this.checkNewOrders();

        return status;

    }

    
    
    async getNewReceiptNo() 
    {
        var retryattempt =0;

        while(retryattempt < 3){
                if(Constants.DEBUG_MODE) console.log('*** Fetching token attempt :: ' + retryattempt);
                //var receiptnorec = <Receiptno> await this.couchDBService.getItem('receiptnos',this.dataService.getPosId());

                var receiptnorec;
                var key  = {"posid": "" + this.dataService.getPosId()};

                if(this.dataService.usedynamo){
                    receiptnorec = <Receiptno> await this.dynamoDBService.getItem('receiptnos',key);
                }else{
                    receiptnorec = <Receiptno> await this.couchDBService.getItem('receiptnos',this.dataService.getPosId());
                }

                
                if(receiptnorec == null ){
                    /*var tok = <Receiptno>{};
                    tok.posid = this.dataService.getPosId();
                    tok.receiptno = 1; 
                    tok.id = this.dataService.getPosId();
                    
                    var status;
                    if(this.dataService.usedynamo){
                        status = await this.dynamoDBService.putItem('receiptnos',tok);
                    }else
                        status = await this.couchDBService.putItem('receiptnos',tok);

                    if(status == 'DONE'){
                        return this.dataService.retailer.appconfig.posconfig.receiptprefix +"/" + '1';
                    } */

                }else{
                    var curtoken = receiptnorec.receiptno;
                    var newtoken = receiptnorec.receiptno + 1;
                    receiptnorec.receiptno = newtoken;
                    var status;
                    if(this.dataService.usedynamo){
                        var conditionexpression = "posid = :posid  and receiptno = :oldtoken ";  
                        var attribparams= {":newtoken" : newtoken, ":oldtoken" : curtoken, ":posid" : this.dataService.getPosId()};

                        var updateexpression = "set receiptno = :newtoken";

                        status = await this.dynamoDBService.updateItem('receiptnos',updateexpression,conditionexpression,attribparams,key);
                    }else
                        status = await this.couchDBService.putItem('receiptnos',receiptnorec);

                    if(status == 'DONE'){
                        return this.dataService.retailer.appconfig.posconfig.receiptprefix + "/" +  + newtoken;
                    }
                }

                retryattempt = retryattempt + 1;
        }
        
        return 'ERR';

    }


    public  toAWS(order : Order){
        var awsorder =  <Order>{};
        for(let prop in order) {
            if(order[prop] === "" || order[prop] === null){}else{awsorder[prop] = order[prop];}
        }
     
        return awsorder;
    }

 
    //// Order functions for PoS
    async checkoutCounter(counter : Billingcounter,forproforma){
        var locid = this.dataService.retailer.locationid;
        var locname = this.dataService.retailer.locationname;

        var order : Order;
        if(counter.proformaid  == 0){
            var curuser = this.userService.loggedinuser;
            var userid= 0;
            if(curuser) userid = curuser.id;

            var ispizzapan = false;
            if(counter.countertype == Constants.ONLINE){
                ispizzapan = this.aggrService.isPizzaPan(counter.aggregatorid);
            }
            
            order = Order.createNewOrder(this.dataService.getPosId(),locid, locname,userid,counter, this.dataService.getCurrentBusinessDate(),ispizzapan);
            order.id = this.dynamoDBService.getSessionId();//Set id with offset;

            var rcptno = await this.getNewReceiptNo();
            if(rcptno == 'ERR') return null;
                order.receiptno = rcptno;
        }else{
            if(this.dataService.usedynamo){
                var key  = {"posid": "" + this.dataService.getPosId(), "id" : counter.proformaid};
                order = <Order> await this.dynamoDBService.getItem('orders',key);
            }else{
                order = await this.couchDBService.getItem('orders',counter.proformaid);
            }

            //order = await this.couchDBService.getItem('orders',counter.proformaid);
            if(!order){
                //counter.proformaid = 0 ; //This shud not longer be needed. 
                return null; 
            } 
            Order.updateOrderPostProforma(order,counter);
        }
        if(!forproforma){
            order.checkedout = true;
            order.chkouttime =  this.dynamoDBService.getSessionId();
        }  

        var status;

        if(this.dataService.usedynamo){
            console.log('AWS ORDER-->' + JSON.stringify(this.toAWS(order)));
            status= await this.dynamoDBService.putItem('orders', this.toAWS(order));
        }else{
            status= await this.couchDBService.putItem('orders',order);
        }
        
        if(status == "DONE" ){
            return order;
        }else{
            return null;
        }
    }


    async updateOrderInDB(order : Order){

        var status;

        if(this.dataService.usedynamo){
            status= await this.dynamoDBService.putItem('orders',order);
        }else{
            status= await this.couchDBService.putItem('orders',order);
        }

        //var status= await this.couchDBService.putItem('orders',order);
        if(status == "DONE" ){
            return true;
        }else{
            return false;
        }
    }

    //Service for reports 
    async getOrderlist(fromdate, todate){
        try{
            if(this.dataService.ccenabled){
                if(fromdate < 20210901 ) fromdate = 20210901;
                if(todate < fromdate) return [];
            }

            //this.billingcounterlist = [];
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and businessdate BETWEEN :v_start and :v_end ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_start":  fromdate , ":v_end" : todate};
                return await this.dynamoDBService.queryOnIndex('orders', "posid-businessdate-index", conditionexpression,attribparams);
            }else{
                var selector =  {"businessdate": { "$gte": fromdate, "$lte" : todate}};
                var retlist = await this.couchDBService.getFromIndex('orders',selector);
                if(retlist && retlist.docs){
                    return retlist.docs;
                }else 
                    return null;
            }   
            
        }catch(err){
            console.error('ERror fetching Orders ' + err);
            return null;
        }
    }
    


    //Service for reports 
    async getUnsyncedordersFromCouch(){
        try{
            //this.billingcounterlist = [];
            var selector =  {"synced": { "$eq": false}};
            var retlist = await this.couchDBService.getFromIndex('orders',selector);
            if(retlist && retlist.docs){
                return retlist.docs;
            }else 
                return null;
        }catch(err){
            console.error('ERror fetching Orders ' + err);
            return null;
        }
    }



    getOrderFromUPOrder(uporder : UPMessage,counter : Billingcounter, orderserial ){
        var locid = this.dataService.retailer.locationid;
        var locname = this.dataService.retailer.locationname;
        var posid = this.dataService.getPosId();

        var curuser = this.userService.loggedinuser;
        var userid= 0;
        if(curuser) userid = curuser.id;

        var aggrname = this.aggrService.getAggregatorNameForId(counter.aggregatorid);

        var curtimpestamp = this.dynamoDBService.getSessionId() + orderserial; 
        var order = Order.createInitialOnlineOrder(posid,locid,locname,userid,curtimpestamp,counter,this.dataService.getCurrentBusinessDate(),uporder);
        order.uproderid = uporder.order.details.id; 
        order.deliverytype = uporder.order.details.ext_platforms[0].delivery_type;

        for(var item of uporder.order.items){
            var prodid = "" + item.merchant_id;
            console.log('|UPORDERS|' + prodid);
            var product = this.dataService.getProductForId(parseInt(prodid));
            var qty = item.quantity;
            var customizationstring = ""; 
            var customizationcost = 0;
            var custinv = "";
            for(var opt of item.options_to_add){
                if(opt.quantity && opt.quantity > 1){
                    for(var i=0;i<opt.quantity;i++){
                        customizationstring = customizationstring + opt.title + ", ";
                        custinv = custinv + opt.merchant_id + "|"  + opt.price + ", ";  
                        customizationcost = customizationcost + parseInt(opt.price);
                    }
                }else{
                    customizationstring = customizationstring + opt.title + ", ";
                    custinv = custinv + opt.merchant_id + "|"  + opt.price + ", ";  
                    customizationcost = customizationcost + parseInt(opt.price);
                }
            }

            customizationstring = customizationstring.substring(0, customizationstring.length -2);
            order.cart.addToCart(product,counter.countertype, qty, customizationstring,customizationcost,item.instructions, custinv,null,0,0,0,0, this.dataService.retailer.locationid);
        }

        
        /** SET DISCOUNT */
        if(parseFloat(uporder.order.details.discount) > 0){
            var discval = parseFloat(uporder.order.details.discount);
            //find out the percentage 
            var discper = Utils.round(discval/order.cart.sumitemnet*100,3);

            //Apply now
            var len = order.cart.lineitems.length;
            for(var i =0; i<  len;i++){
                var cartitem = order.cart.lineitems[i];
                var prod = this.dataService.getProductForId(cartitem.productid);
                order.cart.applyItemDiscount(prod,cartitem,discper);
            }
        }
        

        var delcharges = uporder.order.details.total_charges;
        /** SET CHARGES */
        if(order.deliverytype == 'self')
            order.cart.setDeliveryCharges(delcharges);

        /** SET PAYMENT LIST  */
        var aggrid  = uporder.order.details.ext_platforms[0].id;
        if(uporder.order.payment && uporder.order.payment.length > 0 ){
            order.cart.paymentlist = [];
            for(var uppymt of uporder.order.payment){
                if(uppymt.option != 'cash'){
                    var fkpymt = <Payment>{};
                    fkpymt.id = new Date().getTime();
                    fkpymt.paymentmode = Constants.PYMT_AGGREGATOR;
                    fkpymt.paymentmodename = Constants.getPymtModeName(fkpymt.paymentmode);
                    
                    if(order.deliverytype != 'self' && delcharges  && delcharges > 0){
                        fkpymt.paidamount = Utils.roundTo25Fraction(parseFloat(uppymt.amount) - parseFloat(delcharges));
                    }else{
                        fkpymt.paidamount = Utils.roundTo25Fraction(parseFloat(uppymt.amount));
                    }
                    
                    fkpymt.pymtreference = aggrid; 
                    fkpymt.addlinfo = "Aggregator payment, from :  " + aggrname + ", Aggregator Reference: " + aggrid;
                    order.cart.paymentlist.push(fkpymt);
                    order.cart.setPaidAndRemainingAmount();
                }

            }
        }

        return order;       
        
        



        
    }
    


}