import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Appdataservice} from '../service/appdataservice';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';

import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../service/billingservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';

@Component({
    selector: 'instructions-dlg',
    templateUrl: './instructionsdialog.html',

})
export class Instructionsdialog {
    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private dataService : Appdataservice,
                public counterService : Billingcounterservice) {}

    
    @ViewChild('autoinstr') txtInstructionBox : AutoComplete;
    
    txtinstructions : string;
    dialogheader;

    resultsinstructions : string[];
    arrinstructions : string[];

    searchinstructions(event) {
        this.resultsinstructions = [];
        let query = event.query;   
        
        
        for(let i = 0; i < this.arrinstructions.length; i++) {
            let strinstruction = this.arrinstructions[i];

            if(strinstruction.toLowerCase().indexOf(query.toLowerCase()) >= 0)  {
                this.resultsinstructions.push(strinstruction);
            }
        }

    }


    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.txtinstructions != null && this.txtinstructions.length > 0){
            if(event.keyCode == 13  ) {
                  this.billingService.setInstructions(this.txtinstructions);
            }
        }
    }

    
    closeDialog(){
        this.billingService.makeinstructionsdialogvisible = false;
    }

    addInstructions(instr){
        if(this.txtinstructions != null && this.txtinstructions.length > 2){
            this.txtinstructions = this.txtinstructions + ", " +  instr ;
        }else{
            this.txtinstructions = this.txtinstructions + instr;
        }
    }

    save(){
        this.billingService.setInstructions(this.txtinstructions);
    }

    clear(){
        this.txtinstructions=  "";
    }


    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');
        this.arrinstructions = [];
        var strinstructions = this.dataService.retailer.appconfig.posconfig.presetinstructions;
        if(strinstructions != null && strinstructions.length > 0){
            this.arrinstructions = strinstructions.split(',');
        }
        
        this.dialogheader =  "Set instructions for: "  + this.billingService.selectLineitem.productname;
        this.txtinstructions = this.billingService.selectLineitem.instructions;
        setTimeout(()=>{ this.setFocusOnInstructions();}, 100);
    }


    setFocusOnInstructions(){
        //if(Constants.DEBUG_MODE) console.log('Showing focus on product');

        if(this.txtInstructionBox){
            this.txtInstructionBox.inputEL.nativeElement.focus();
        }
        //this.txtinstructions = '';
        //if(Constants.DEBUG_MODE) console.log('txtproduct is after setting ' + this.txtproduct );

    }
    
    
    ngOnInit(){

        
    }


    
}
