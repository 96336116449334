import { Constants } from "./constants";
import { Retailerscheme } from "./retailerscheme";
import { SwitchView } from "@angular/common/src/directives/ng_switch";

export class Utilfunctions{

    public static replacer(key, value) 
    {
        // Filtering out properties
        if (value === null) {
          return undefined;
        }
        return value;
    }
      

    
    public static stringyfy(jsonobj : any){
        return JSON.stringify(jsonobj,Utilfunctions.replacer,"\t");
    }
      

    public static roundToTwoDecimal(input){
        return Math.round(input*100) / 100;
    } 

    public static roundToThreeDecimal(input){
        return Math.round(input*1000) / 1000;
    } 

    public static convertoMultiline(inputstring,numchars){
        var strarray = inputstring.match(/(.{1,numchars})/g);
        var retstr = "";
        for(let i= 0; i < strarray.length;i++){
            retstr = retstr + strarray[i] + "\n";
        }
        return retstr;
       
    }


    public static getDayNameForDay(day){
        switch(day){
            case 0: return "Sun";
            case 1: return "Mon";
            case 2: return "Tue";
            case 3: return "Wed";
            case 4: return "Thu";
            case 5: return "Fri";
            case 6: return "Sat";
            default: return "None";
            
        }
    }


    public static checkSchemeValidity(sch : Retailerscheme, curbsndate) : boolean{
        //Check if dates match
        //if(Constants.DEBUG_MODE) console.log('|SCHEME| Start ' + sch.startdate + ' End ' + sch.enddate + ' Cur ' + curbsndate);
        if(sch.startdate <= curbsndate && sch.enddate >= curbsndate){
            var curdatetime = new Date(); 
            
            //Validate hours 
            if(sch.schemehours && sch.schemehours.length > 0){
                var arrhours = sch.schemehours.split("-");
                if(arrhours && arrhours.length == 2){
                    var starthour = parseInt(arrhours[0]);
                    var endhour = parseInt(arrhours[1]);
                    var curhour = curdatetime.getHours();

                    //if(Constants.DEBUG_MODE) console.log('|SCHEME| Cur day -->  ' + curdatetime.getDay() + ' Scheme days ' +  sch.schemedays + ' Start hours ' + starthour + ' End hour ' + endhour + ' Cur hour ' + curhour);
                    if(curhour < starthour || curhour > endhour){
                        return false; 
                    }
                }
            }

            //Vadlidate day of week
            var curdayofweek = Utilfunctions.getDayNameForDay(curdatetime.getDay());

            //if(Constants.DEBUG_MODE) console.log('|SCHEME|Curday of week: ' + curdayofweek);
            if(!sch.schemedays || !(sch.schemedays.indexOf(curdayofweek) >= 0)){
                return false;
            }
        }else{
            if(Constants.DEBUG_MODE) console.log('|SCHEME| Start end out of range');
            return false; 
        }

        return true;
    }
    

    

    public static convertoMultilineFixedLengh(inputstring,numchars,padstring){
        if(Constants.DEBUG_MODE) console.log('PREPAD ' + inputstring );
        
        if(inputstring.length <= numchars){
            if(Constants.DEBUG_MODE) console.log('POSTPAD|' + Utilfunctions.rpad(padstring,inputstring) + '|CHARNUM' + numchars);
            return Utilfunctions.rpad(padstring,inputstring);
        }else{
            var strarray = [];
          switch(numchars){
                case 13:strarray = inputstring.match(/(.{1,13})/g);break;
                case 17:strarray = inputstring.match(/(.{1,17})/g);break;
                case 18:strarray = inputstring.match(/(.{1,18})/g);break;
                case 19:strarray = inputstring.match(/(.{1,19})/g);break;
                case 20:strarray = inputstring.match(/(.{1,20})/g);break;
                case 21:strarray = inputstring.match(/(.{1,21})/g);break;
                case 22:strarray = inputstring.match(/(.{1,22})/g);break;
                case 26:strarray = inputstring.match(/(.{1,26})/g);break;
                case 27:strarray = inputstring.match(/(.{1,27})/g);break;
                case 28:strarray = inputstring.match(/(.{1,28})/g);break;
                case 29:strarray = inputstring.match(/(.{1,29})/g);break;
                case 30:strarray = inputstring.match(/(.{1,30})/g);break;
                case 34:strarray = inputstring.match(/(.{1,34})/g);break;
                case 36:strarray = inputstring.match(/(.{1,36})/g);break;
                case 42:strarray = inputstring.match(/(.{1,42})/g);break;
            }

            var retstr = "";
            for(let i= 0; i < strarray.length;i++){
                var linestr = strarray[i];
                linestr = Utilfunctions.rpad(padstring,linestr.trim());
                retstr = retstr + linestr + "<BR>";
            }

            retstr = retstr.substring(0,retstr.length - 4);
            return retstr;
        }
       
    }

    public static formatToOneDecimal(input){
        var n = Math.round(input*10) / 10;
        return n.toFixed(1);
    } 

    public static formatToTwoDecimal(input){
        var n = Math.round(input*100) / 100;
        return n.toFixed(2);
    } 

    public static formatToThreeDecimal(input){
        var n= Math.round(input*1000) / 1000;
        return n.toFixed(3);
    } 

    public static getDateAsString(orderdate : Date){
        return orderdate.getFullYear() + "-" + (orderdate.getMonth() + 1) + "-" + orderdate.getDate();
    }

    public static getDateTimeAsString(orderdate : Date){
        return orderdate.getFullYear() + "-" + (orderdate.getMonth() + 1) + "-" + orderdate.getDate() + " " + orderdate.getHours() + ":" + orderdate.getMinutes();
    }



    public static formatBusinessDate(bsndate : string){
        var year = bsndate.substring(0,4);
        var month = bsndate.substring(4,6);
        var day = bsndate.substring(6,8);
        return day + "/" + month + "/" + year;
    }

    public static formatBusinessDateGraph(bsndate : string){
        var year = bsndate.substring(0,4);
        var month = bsndate.substring(4,6);
        var day = bsndate.substring(6,8);
        return day + "/" + month;
    }


    
    public static getBusinessDateFromString(strdate : string){
        if(strdate && strdate.length === 10){
            var day = strdate.substring(0,2);
            var month = strdate.substring(3,5);
            var year = strdate.substring(6,10);
            
            var subdatestr = year + month + day; 
            if(!isNaN(parseInt(subdatestr)))
                return parseInt(subdatestr)
            else
                return 0;

          }else{
              return 0;
          }
    }


    public static getSubscriptionExpiryDate(subscriptiondatestr : string){
          if(subscriptiondatestr && subscriptiondatestr.length === 10){
            var day = subscriptiondatestr.substring(0,2);
            var month = subscriptiondatestr.substring(3,5);
            var year = subscriptiondatestr.substring(6,10);
            
            var subdatestr = year + month + day; 
            if(!isNaN(parseInt(subdatestr)))
                return parseInt(subdatestr)
            else
                return 0;

          }else{
              return 0;
          }
        
    }


    public static getDateFromBusinessdate(bsndate : string){
        if(bsndate){
          var bdate = new Date();
          bdate.setMonth (parseInt(bsndate.substring(4,6))-1);
          bdate.setFullYear(parseInt(bsndate.substring(0,4)));
          bdate.setDate(parseInt(bsndate.substring(6,8)));
          
          return bdate;
        }
    }

    public static getWeekdayNum(weekdayname : string){
        switch(weekdayname){
            case "Sunday": return 0;
            case "Monday": return 1;
            case "Tuesday": return 2;
            case "Wednesday": return 3;
            case "Thursday": return 4;
            case "Friday": return 5;
            case "Saturday": return 6;

        }
    }

    public static lpad(pad, str){
        return String(pad + str).slice(-1 * pad.length);
    } 

    public static rpad(pad : string , str : string){
        var combinedstr = str + pad; 
        return combinedstr.substring(0,pad.length);
    }
    
    public static cleanObject(jsonobject){
        
    }


    public static getAmountInWords (amount : any){
        var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ',
            'nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ',
            'eighteen ','nineteen '];
        
         var b = ['','','twenty','thirty','forty','fifty','sixty','seventy','eighty','ninety'];

         if (amount) {
            let num: any = Number(amount);
            if (num) {
              if ((num = num.toString()).length > 9)  { return 'We are not the Iron Bank, you can lower down the stakes :)'; }
              const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
              if (!n) {return ''; }
              let str = '';
              str += (Number(n[1]) !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
              str += (Number(n[2]) !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
              str += (Number(n[3]) !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
              str += (Number(n[4]) !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
              str += (Number(n[5]) !== 0) ? ((str !== '') ? 'and ' : '') +
              (a[Number(n[5])] || b[n[5][0]] + ' ' +
              a[n[5][1]]) + ' ' : '';
              return 'RUPEES ' + str.toUpperCase() + ' ONLY';
            } else {
              return '';
            }
          } else {
            return '';
          }

    }


    public static getHourRangeFromHour(hour : number){
        var curhour = "" + hour;
        if(curhour.length == 1) curhour = "0" + curhour; 

        var nexthour ="" +  (hour + 1);
        if(nexthour.length == 1) nexthour = "0" + nexthour;
        
        return curhour + "-" + nexthour; 


        
    }

    
}