import { Appliedtaxcharge } from "./appliedtaxcharge";
import { Product } from "./product";
import { Utils } from "../service/utils";
import { Constants } from "./constants";
import { ProductCodeList } from "aws-sdk/clients/ec2";
import { TagPlaceholder } from "@angular/compiler/src/i18n/i18n_ast";
import { Summaryrecord } from "./summaryrecord";
import { Retailerscheme } from "./retailerscheme";
export class Cartlineitem
{
    
    productid;  //Product id 
    catid;      //will be used for reports 
    subcatid;   //will be used for eports 
    quantity;
    rate;       //Base rate for the product 
    ograte;     //Original rate for the product
    ogcustrate; //Original customization rate
    amount;     //amount = quantity * base rate
    
    qrid; //QR id of item
    customization; //Applicable customization
    customizationrate; //Added customization rate
    customizationamt; //Added customization amount

    grossamt; //Pretax amount 
    taxableamt; //Pretax - Discount + Service Charge
    
    cgst;
    sgst;
    vat; 

    tfee;
    mfee;
    sc;

    tfeeper;
    mfeeper;
    scper;





    itemtax; //Total taxes applied on item 
    itemcharge; //Total charge applied on item NA For now
    revtax;     
    

    schcode; //Scheme code applied for line item, @ line item level, only one scheme allowed 
    discinper : boolean; //For the applied scheme or manually, whether the discount applied was in percent
    discval;    //Value of discount in percent or absolute terms 
    discount;   //Applied discount. 

    itemamount; //Amount + customizationamt + itemtax - itemtaxincl + charges - discount

    productname;  //Product name for quick reference 
    instructions;   //Preparation instructions 
    createdon; //Timestamp of item creation 

    kitchenid; //Kitchen id ;
    kitstatus; //Kitchen status
    kottoken; 
    kottime;  
    kottimestr;
    proddetails;

    cancelled; 
    cancelreason; 
    
    addedpp;
    cancelledpp;
    cancelqty;
    cancelby;
    
    remarks; 
    foodprepared;
    foodpreparedstr; 
    preptime; 
    timealert;
    pcode; 
    occode;
    custinv;
    grossrate;
    hasvarient; 

    voidoptrid;
    
    roundTaxes(){
        this.cgst  = Utils.round(this.cgst,3);
        this.sgst  = Utils.round(this.sgst,3);
        this.vat  = Utils.round(this.vat,3);
        this.itemtax = Utils.round(this.cgst + this.sgst + this.vat,3);
    }

    computeItemTaxes(prod : Product)
    {
        this.itemtax = 0;
        this.itemcharge =0; //Charges for now are applicable only at bill level NA For now 
        this.taxableamt = Utils.round(this.grossamt - this.discount,3);
        
        this.cgst =  Utils.round(prod.cgst / 100.0 * (this.taxableamt),3);
        this.sgst =  Utils.round(prod.sgst / 100.0 * (this.taxableamt),3);
        this.vat  =  Utils.round(prod.vat / 100.0 * (this.taxableamt),3);
        
        if(this.tfeeper > 0 || this.mfeeper > 0 || this.scper > 0 ){
            if(this.tfeeper > 0) this.tfee = Utils.round(this.tfeeper / 100.0 * (this.taxableamt),3); else this.tfee = 0;
            if(this.mfeeper > 0) this.mfee = Utils.round(this.mfeeper / 100.0 * (this.taxableamt),3); else this.mfee = 0;
            if(this.scper > 0) this.sc = Utils.round((this.scper / 1.05) / 100.0 * (this.taxableamt),3); else this.sc = 0;
            
            //Add VAT on service charge
            if(this.sc > 0 ) this.vat = Utils.round(this.vat + (this.sc * 0.05),3);
            this.itemtax = Utils.round(this.cgst + this.sgst + this.vat + this.tfee + this.mfee + this.sc, 3);
        }else{
            this.itemtax = Utils.round(this.cgst + this.sgst + this.vat , 3);
        }

        this.itemamount = Utils.round(this.taxableamt + this.itemtax,3);
        

    }

    private applySchemes(){
        //Dummy for now, we will have it from tax service
        /*this.schcode ='';
        this.discinper = true;
        this.discval =0;
        this.discount = 0;*/
        
    }


    applyDiscount(prod : Product, discountpercent : number){
        this.discount = Utils.round(this.grossamt * discountpercent/ 100.0,3);
        this.computeItemTaxes(prod);
    }

    setQuantity(prod : Product, newquantity){
        //Round the quantity off to 3 decimals, as per our protocol
        this.quantity = Utils.round(newquantity,3);
        this.amount =  Utils.round((this.rate  + this.customizationrate) *  this.quantity, 3);       
        this.customizationamt =  Utils.round(this.customizationrate *  this.quantity, 3);     

        

        //Compute the gross amount (amount before tax)
        if(this.revtax){
            var taxpercent = prod.cgst + prod.sgst + prod.vat; 
            if(this.mfeeper && this.mfeeper > 0) taxpercent = taxpercent + this.mfeeper;
            if(this.tfeeper && this.tfeeper > 0) taxpercent = taxpercent + this.tfeeper;
            
            if(this.scper && this.scper > 0){
                 taxpercent = taxpercent +  this.scper;
            }
            
            this.grossamt = Utils.round(this.amount / (1 + (taxpercent/100)),3);
            this.grossrate = Utils.round((this.rate  + this.customizationrate) / (1 + (taxpercent/100)),3);
        }else{
            this.grossamt = this.amount;
        }

        this.applySchemes();
        this.computeItemTaxes(prod);
    
    }

    
    incrementQty(prod : Product){
        this.setQuantity(prod, this.quantity + 1);
    }

    decrementQty(prod : Product){
        this.setQuantity(prod,this.quantity - 1);
    }

    


    /*
        Factory method to create the first item for cart, this is for consumer app 
        the first item will always be with 1 quantity. This will be called from the *Cart* class

    */
    public static createNewItem(prod : Product, countertype, quantity, customization : string,  customizationrate : number,instructions : string, taxbehavior : number, custinv, scheme : Retailerscheme,qrid,tfeeper,mfeeper,scper,locationcode) : Cartlineitem{
        var item = new Cartlineitem();
        item.rate =  Utils.getApplicableRate(prod, countertype,locationcode);
        item.customizationrate = customizationrate;

        if(scheme && scheme.discountinpercentage && scheme.discountvalue && scheme.discountvalue > 0){
            item.schcode = scheme.schemecode;
            item.rate = Utils.round(item.rate * (100 - scheme.discountvalue)/100.0,2);
            item.customizationrate = Utils.round(item.customizationrate * (100 - scheme.discountvalue)/100.0,2);
            item.ograte = prod.onlineprice;
            item.ogcustrate =  customizationrate;
        }else{
            item.schcode = '';
        }
        
        item.qrid = qrid; 
        item.customization = customization;
        item.custinv = custinv; 
        item.productname= prod.name;
        item.productid = prod.id;
        item.instructions = instructions;
        item.catid = prod.categoryid;
        item.subcatid = prod.subcategoryid;
        item.revtax = false;
        item.kottime = 0;
        item.kitstatus = Constants.UNSENT;
        item.kitchenid = prod.kitchenid;
        item.createdon = new Date().getTime();

        //Set discount to not applied 
        item.discinper = true;
        item.discval =0;
        item.discount = 0;
        
        
        if(tfeeper > 0) item.tfeeper = tfeeper; 
        if(mfeeper > 0) item.mfeeper = mfeeper; 
        if(scper > 0) item.scper = scper; 
        
        
        if(!taxbehavior) taxbehavior = Constants.SAME_AS_PRODUCT;    

        switch(taxbehavior){
            case Constants.SAME_AS_PRODUCT:
                console.log('ReV tax on product ' + prod.reversecomputetax) ;
                if(prod.reversecomputetax == Constants.YES) item.revtax = true;
                break;
            case Constants.TAX_IN_REVERSE: 
                item.revtax = true;
                break;
        }


        item.setQuantity(prod,quantity);
        return item;
    }

    public static getSummaryRecordForFocus(bsndate, posid,summaryrec : Summaryrecord ){

        summaryrec.description = summaryrec.description.replace(/'/g, ' ');
        var strinsert = "insert into export_dataitems(store_id,trn_date,plu_number,plu_name,quantity,amount,trans_ocode)values(" + posid + 
                         ",'" + bsndate + "','" + summaryrec.summaryval1 + "','" + summaryrec.description + "'," + summaryrec.summaryval3 + ","
                         + summaryrec.summaryval4 +",'" + summaryrec.summaryval2 + "')";
        return strinsert; 

    }

   

    
}
