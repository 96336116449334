import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Product } from 'src/app/domain/product';


@Component({
    templateUrl: 'orderswithscheme.html',
    providers: [ConfirmationService]
})
export class Orderswithscheme implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Summaryrecord[];
    paymentmodes : SelectItem[];
    categorylist : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;
    currentcategoryid; 

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private counterService : Billingcounterservice,
        private orderService  : Orderservice,

        private userService : Userservice, 
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'summarized';
        
        this.viewtypes = [
            { label: 'Product wise', value: Constants.VW_PRODUCT},
            { label: 'Category wise', value: Constants.VW_CATEGORY},
            { label: 'Sub category wise', value: Constants.VW_SUBCATEGORY},
            { label: 'Kitchen wise', value: Constants.VW_KITCHEN}
        ];

        this.currentviewtype = Constants.VW_PRODUCT;
        this.currentcategoryid = 0;

        this.orderlist = [];
        this.categorylist = [];
        this.categorylist.push({ label: 'All', value: 0});
        
        for(var cat of this.dataService.catlist){
            this.categorylist.push({ label: cat.name, value: cat.id});
        }

        this.cols = [
            { field: 'summaryval1', header: 'Business Date' },
            { field: 'summaryval2', header: 'Checkout Time' },
            { field: 'summaryval3', header: 'Bill no' },
            { field: 'description', header: 'Scheme Code' },
            { field: 'summaryval4', header: 'Item Description' },
            { field: 'summaryval5', header: 'Guest count' },
            { field: 'summaryval6', header: 'Scheme Benefit' }
            
        ];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt,false);
    }

    refreshReportCategory(catid, dt : Table){
        this.currentcategoryid = catid;
        this.setData(dt,false);
    }

    changeDatewiseOption(dt : Table){
        console.log('Setting data...');
        this.setData(dt,false);
    }
    
    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totaloldamount = 0;
    totalnewamount = 0;
    totalschemedifference= 0;
    totalguestcount = 0;


    
    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totaloldamount = 0;
        this.totalnewamount = 0;
        this.totalschemedifference = 0;
        this.totalguestcount = 0;
    }


    getOrderSchemeBenefit(ord : Order){

        var orderbenefit = 0;
        for(var item of ord.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){
                var prod = this.dataService.getProductForId(item.productid);
                var oldamount =  Utils.round((item.ograte  + item.ogcustrate) *  item.quantity, 3);       
                var oldgross = oldamount;

                //Compute the gross amount (amount before tax)
                if(item.revtax){
                    var taxpercent = prod.cgst + prod.sgst + prod.vat; 
                    oldgross = Utils.round(oldamount / (1 + (taxpercent/100)),3);
                }


                var newgross = item.grossamt;
                var difference = oldgross - newgross;

                orderbenefit= Utils.round(orderbenefit + difference,2);
            }
        
        }

        return orderbenefit;

            
        
    }

    roundTotals(){
        
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 

    checkSchemeOnOrder(ord : Order){
        for(var item of ord.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){
                return item.schcode;
            }
        }
        return '';
    }

    getItemDescriptions(ord : Order){
        var description = '';
        for(var item of ord.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){
                var itemstring = item.productname;
                if(item.customization && item.customization.length > 0){
                    itemstring = itemstring  + ' [' + item.customization + '] ';
                }
                itemstring = itemstring + " (" + this.datePipe.transform(item.createdon, "HH:mm") + "),";
                description = description + " " + itemstring;
            }
            
        }
        if(description && description.length > 0){
            return description.substring(0,description.length - 1);
        }else{
            return 0;
        }
    }

    
    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        if (ord.receiptno && ord.receiptno.length > 0){
            if(ord.receiptno.indexOf("/") > 0){
                ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
            }else{
                ord.rcptnodisplay = ord.receiptno;
            }
        }
        
    }

    createNewSummaryRecord(ord : Order ,schcode){
        var rec = <Summaryrecord>{};
        rec.id = ord.id;
        rec.description = schcode;
        rec.summaryval1 = ord.businessdatestr;
        rec.summaryval2 =  ord.createdonstr;
        rec.summaryval3 =  ord.rcptnodisplay;
        rec.summaryval4 =  this.getItemDescriptions(ord);
        rec.summaryval5 =  ord.numguests;
        rec.summaryval6 =  this.getOrderSchemeBenefit(ord);

        
        return rec; 
    }


    
    setFilterList(){
        this.filteredlist = [];
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
        
                var schcode = this.checkSchemeOnOrder(item);
                if(schcode && schcode.length > 0){
                    var ord = this.orderService.getClone(item);
                    this.setDetails(ord);
                    var sumrec = this.createNewSummaryRecord(ord,schcode);
                    this.totalguestcount = this.totalguestcount + sumrec.summaryval5;
                    this.totalschemedifference = Utils.round(this.totalschemedifference + sumrec.summaryval6,3);
                    this.filteredlist.push(sumrec);
                }
                
                

            }
                
                
        }

        
        this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.id)  < (b.id)  ? -1 : 1 );
        this.filteredlist = [... this.filteredlist];
            

    }

    
    async setData(dt : Table, refetch){
        if(refetch){
            var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
            var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

            if(todate < fromdate){
                this.showErrorToast("To date can not be less than from date!");
                return;
            }

            if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
                this.showErrorToast("The data can be searched for maximum of 31 days!");
                return;
            }

            this.isRunning = true; 
            this.setTotalsToZero();
        
            this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
            this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
            }else{
                this.setFilterList();
            }
        }else{
            this.isRunning = true; 
            this.setTotalsToZero();
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


