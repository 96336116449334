import { SelectItem } from "primeng/api";

export class Constants
{
    public static APP_VERSION="8.4.6";
    public static DEBUG_MODE : boolean = false;
    
    //YES NO FLAG 
    public static  YES : Number = 1;
    public static  NO : Number = 2;
    
    //Product type
    public static SALE_PURCHASE_BOTH : Number = 0;
    public static SALE_ONLY : Number = 1;
    public static PURCHASE_ONLY : Number = 2;
    public static KITCHEN_PROCESSED : Number = 3;
 
    //Product type
    public static FOODKART : Number = 0;

    //Order status 
    public static  CUST_CANCELLED : Number = -1;
    public static  NEW_ORDER : Number = 0;
    public static  CONFIRMED_ORDER : Number = 1;
    public static  FOOD_READY : Number = 2;
    public static  OUT_FOR_DELIVERY : Number = 3;
    public static  ORDER_EXECUTED : Number = 4;
    public static  ORDER_REJECTED : Number = 5;
    public static  ORDER_CANCELLED : Number = 6;
    public static  ORDER_OUT_OF_QUEUE : Number = 7;
    
    
    //PoS Order status 
    public static  POS_ORDER_PROCESSED : Number = 0;
    public static  POS_ORDER_CANCELLED : Number = 1;


    //PoS Order status 
    public static  CANCELLED_BY_OUTLET : Number = 0;
    public static  CANCELLED_BY_CUSTOMER : Number = 1;

    //CC Order status 
    public static  CC_ORDER_PLACED : Number = 0;
    public static  CC_ORDER_ACCEPTED : Number = 1;


    //printer types 
    public static SYSTEM=1;
    public static USB=2;
    public static LAN=3;
    public static BLUETOOTH=4;
    public static BROWSER=5;
    public static NOPRINT=6;

    //Printing formats
    public static PRINT_TEXT : Number = 1;
    public static PRINT_HTML : Number = 2;
    
    //Product type
    public static CASH : Number = 0;
    public static CARD_ONLINE : Number = 1;


    public static TWO_INCH_PAPER = 2;
    public static THREE_INCH_PAPER = 3;
    public static A4 = 4;
    public static COL40_PRINTER = 5;
    public static COL42_PRINTER = 6;

    public static getPaperWidthStr(paperwith){
        switch(paperwith){
            case Constants.TWO_INCH_PAPER: return "2 Inch / 32 Column";
            case Constants.COL40_PRINTER: return "2 Inch / 40 Column";
            case Constants.COL42_PRINTER: return "3 Inch / 42 Column";
            case Constants.THREE_INCH_PAPER: return "3 Inch / 48 Column";
            case Constants.A4: return "A4 size";
        }
    }
    
    public static getPrinterTypeStr(printertype){
        switch(printertype){
            case Constants.LAN: return "LAN";
            case Constants.SYSTEM: return "System";
            case Constants.BROWSER: return "Browser";
            case Constants.USB: return "USB";
            case Constants.BLUETOOTH: return "Bluetooth";
        }
    }
    
     
    public static getPrintFormatStr(printformat){
        switch(printformat){
            case Constants.PRINT_TEXT: return "Text";
            case Constants.PRINT_HTML: return "HTML";
        }
    }


    //Aggregators/
    public static ZOMATO = 1;
    public static SWIGGY = 2;
    public static FOODPANDA = 3;
    public static UBEREATS = 4;
    public static SCOOTSY = 5;
    public static MAGICPIN = 6;
    public static DUNZO = 7;
    public static TALABAT = 8;
    


    /** SCHEME CONSTANTS **/
    public static SCHEME_ON_ALL_PRODUCTS : Number = 0;
    public static SCHEME_ON_CATEGORY : Number = 1;
    public static SCHEME_ON_SUBCATEGORY : Number = 2;
    public static SCHEME_ON_PRODUCT : Number = 3;
    public static SCHEME_ON_ENTIREBILL : Number = 1;
    public static SCHEME_ON_LINEITEMS : Number = 2;
    /** SCHEME CONSTANTS **/

    
    
    public static getAggregatorName(id : Number) : string {
        switch(id){
            case this.ZOMATO:
                return "Zomato";
            case this.SWIGGY:
                return "Swiggy";
            case this.FOODPANDA:
                return "FoodPanda";
            case this.SCOOTSY:
                return "Scootsy";
            case this.UBEREATS:
                return "UberEats";
            case this.MAGICPIN:
                return "Magicpin";
            case this.DUNZO:
                return "Dunzo";
            case this.TALABAT:
                return "Talabat";
        }
        
        return "";
    }


    //Billing counter types 
    public static NORMAL=1;
    public static AC_SPECIAL=2;
    public static PARCEL=3;
    public static ONLINE=4;
    public static FREE=5;
    public static TAKEAWAY=6;
    public static SELFAPP=7;

    public static getCounterTypeName(id : Number) : string {
        switch(id){
            case this.NORMAL:
                return "Dine In";
            case this.AC_SPECIAL:
                return "AC/Special";
            case this.TAKEAWAY:
                return "Takeway";
            case this.ONLINE:
                return "Online";
            case this.FREE:
                return "Free";
            case this.PARCEL:
                return "Delivery";
            case this.SELFAPP:
                return "Online app";
        }
        
        return "";
    }


    public static getCounterTypeNameForReceipt(id : Number) : string {
        switch(id){
            case this.NORMAL:
                return "Dine In";
            case this.AC_SPECIAL:
                return "Dine In";
            case this.TAKEAWAY:
                return "Takeway";
            case this.ONLINE:
                return "Delivery";
            case this.FREE:
                return "Free";
            case this.PARCEL:
                return "Delivery";
            case this.SELFAPP:
                return "Delivery";
        }
        
        return "";
    }

    

    //Payment modes 
    public static PYMT_CASH=1;
    public static PYMT_CARD=2;
    public static PYMT_ACT=3;
    public static PYMT_UPI=4;
    public static PYMT_PAYTM=5;
    public static PYMT_WALLET=6;
    public static PYMT_CHEQUE=7;
    public static PYMT_AGGREGATOR=8;
    public static PYMT_COMPLIMENTARY=9;
    public static PYMT_PG=10;
    public static PYMT_STAFF=11;
    public static PYMT_TP_WALLET=12;
    public static PYMT_COUPON=13;
    public static PYMT_FOREX=14;
    public static PYMT_ZOMATO_PRO=15;
    public static PYMT_OTHER_CREDIT=16;
    

    
    
    
    
    public static getPymtModeName(pymtmode) : string 
    {
        switch(pymtmode)
        {
            case this.PYMT_CASH:
                return "Cash";
            case this.PYMT_CARD:
                return "Card";
            case this.PYMT_ACT:
                return "Account";
            case this.PYMT_PAYTM:
                return "Pay TM";
            case this.PYMT_UPI:
                return "UPI";
            case this.PYMT_WALLET:
                return "Customer Wallet";
            case this.PYMT_CHEQUE:
                return "Cheque";
            case this.PYMT_AGGREGATOR:
                return "Aggregator";
            case this.PYMT_COMPLIMENTARY:
                return "Complementary";
            case this.PYMT_PG:
                return "Online";
            case this.PYMT_STAFF:
                return "Staff allowance";
            case this.PYMT_TP_WALLET:
                return "Third party wallets";
            case this.PYMT_COUPON:
                return "Coupon";
            case this.PYMT_FOREX:
                return "Forex";
            case this.PYMT_OTHER_CREDIT:
                return "Other Credit";
            case this.PYMT_ZOMATO_PRO:
                return "Zomato Pro";

        }

        return "";
    }
       

    //Billing counter dialogs
    public static NO_DIALOG = 0;
    public static OPEN_CUSTOMER_DIALOG = 1;
    public static OPEN_OPERATOR_DIALOG = 2;
    public static OPEN_PAYMENT_DIALOG = 3;
    public static OPEN_DELIVERY_DIALOG = 4;
    public static OPEN_PYMT_DLVRY_DIALOG = 5;

    public static SAME_AS_PRODUCT=0;
    public static TAX_IN_FORWARD=1;
    public static TAX_IN_REVERSE=2;

    public static getDefaultColor(countertype){
        switch(countertype){
            case Constants.NORMAL: return "#70C4FF";
            case Constants.AC_SPECIAL: return "#707CFF";
            case Constants.TAKEAWAY: return "#FFF370";
            case Constants.PARCEL: return "#F5962A";
            case Constants.ONLINE: return "#FF717C";
            case Constants.FREE: return "#7CFF70";
            case Constants.SELFAPP: return "#FF717C";

        }
    }
 
    
    //Privilages 
    public static PRIV_SALES=1; 
    public static PRIV_EDIT_SALES=2; 
    public static PRIV_CANCEL_ITEM=3;
    public static PRIV_CANCEL_KOT=4;
    public static PRIV_CANCEL_BILL=5;
    public static PRIV_CHECKOUT=6;
    public static PRIV_DUPLICATE_PRINT=7;
    public static PRIV_DUPLICATE_KOT=8;
    public static PRIV_APPLY_DISCOUNT=9;
    public static PRIV_DISCOUNT_POST_BILL=10;
    public static PRIV_TRANSFER_ITEMS=11;
    public static PRIV_CASHIER_FUNCTIONS=12;
    public static PRIV_CHANGE_SERVED_BY=13;
    public static PRIV_CREATE_USERS=14;
    public static PRIV_SYNC_OFFLINE_DATA=15;
    public static PRIV_SETTLEMENT=16;
    public static PRIV_CALLCENTER=17;
    public static PRIV_DELIVERIES=18;
    
    public static FOR_LOGIN=99;

    
    

    //Reporting Privilages 
    public static PRIV_SALES_REPORTS=1; 
    public static PRIV_BUSINESS_SUMMARY=2; 
    public static PRIV_MONITORING_ALERTS=3;
    public static PRIV_COLLECTION_REPORTS=4;
    public static PRIV_SHORT_SUMMARY=5;
    public static PRIV_CSV_DOWNLOAD=6;
    
    
 
    //Kitchen statuses
    public static UNSENT = -1;
    public static DISPATCHED_TO_KITCHEN = 99;
    public static RECIEVED_BY_KITCHEN = 0;
    public static BEING_PREPARED = 1;
    public static READY_AT_KITCHEN = 2;
    public static CANT_BE_PREPARED = 3;
    public static ITEM_CANCELLED = 4;
    public static SERVED = 5;

    
    //Discount applicability; 
    public static DISC_FOR_ALL_ITEMS=1; 
    public static DISC_FOR_SELECTED_CATS=2; 
    public static DISC_FOR_SELECTED_SUBCATS=3; 
    public static DISC_FOR_SELECTED_PRODS=4; 
    
    //Reports view types
    public static VW_CATEGORY =1; 
    public static VW_SUBCATEGORY =2; 
    public static VW_PRODUCT =3;
    public static VW_OPERATOR=4;
    public static VW_COUNTERTYPE=5;
    public static VW_COUNTER=6;
    public static VW_BSNDATE=7;
    public static VW_WEEKDAY=8;
    public static VW_KITCHEN=9;
    public static VW_PAYMENTMODE=10;
    public static VW_REVENUEHEAD=11;
    public static VW_EXPENSEHEAD=12;
    public static VW_SUPPLIER=13;
    public static VW_DELIVERY_BOY=14;
    public static VW_POS_LOCATION=15;
    public static VW_HSN_NO=16;
    public static VW_SECTION=17;
    public static VW_HOUR_OF_DAY=18;
    public static VW_CUSTOMIZATION=19;
    public static VW_SERVED_BY=20;
    public static VW_GUEST_TYPE_WISE=21;
    public static VW_AGGREGATOR=22;
    public static VW_PRODUCT_CUST =23;
    

    //Report display choice 
    public static SHOW_ORDERCOUNT = 1;
    public static SHOW_BILLAMT = 2;
    public static SHOW_NUMGUESTS = 3;


    
    


    
    

        //Item statuses
        public static QR_ORD_PLACED = -1;
        public static QR_ORD_ACCEPTED = 0;
        public static QR_ORD_BEING_PREPARED = 1;
        public static QR_ORD_READY_AT_KITCHEN = 2;
        public static QR_ORD_ITEM_CANCELLED = 4;
        public static QR_ORD_SERVED = 5;
    
        public static getStatusStr(status){
            switch(status){
                 case Constants.QR_ORD_PLACED: return 'Order placed';
                 case Constants.QR_ORD_ACCEPTED: return 'Order received';
                 case Constants.QR_ORD_BEING_PREPARED: return 'Being prepared';
                 case Constants.QR_ORD_READY_AT_KITCHEN: return 'Food ready';
                 case Constants.QR_ORD_ITEM_CANCELLED: return 'Cancelled';
                 case Constants.QR_ORD_SERVED: return 'Served';
                 
    
            }
    
        }
    
           //REquest statuses
           public static NEW_REQUEST = 0;
           public static REQUEST_ATTENDED = 1;
        
           //REquest types
           public static CALL_SERVER = 1;
           public static REQUEST_CHECKOUT = 2;
           public static FEEDBACK = 3;
           
        
           public static getRequestTypeStr(reqtype){
            switch(reqtype){
                 case Constants.CALL_SERVER: return 'Call server';
                 case Constants.REQUEST_CHECKOUT: return 'Request checkout';
                 case Constants.FEEDBACK: return 'Feedback';
            }
    
        }
    
    

        //SALE data to be extracted 
        public static ORDER_VALUE=1;
        public static ORDER_COUNT=2;
        public static GUEST_COUNT=3;
        public static ITEM_COUNT=4;
        public static AVG_VALUE=5;
        public static AVG_VALUE_PER_GUEST=6;
        public static DINEIN_ORDER_COUNT=7;
        public static DINEIN_ORDER_VALUE=8;
        public static TA_ORDER_COUNT=9;
        public static TA_ORDER_VALUE=10;
        public static DE_ORDER_COUNT=11;
        public static DE_ORDER_VALUE=12;
        public static ONLINE_ORDER_COUNT=13;
        public static ONLINE_ORDER_VALUE=14;
        public static MATAM_ORDER_COUNT=15;
        public static MATAM_ORDER_VALUE=16;
        
        public static VOID_COUNT=17;
        public static VOID_VALUE=18;
        
    
        


}