import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from '../../paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Aggregatorservice } from 'src/app/service/aggregatorservice';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';


@Component({
    templateUrl: 'taxdetails.html',
    providers: [ConfirmationService]
})
export class Taxdetails implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    statusoptions : SelectItem[];
    selectedstatus; 
    currentviewtype;

    isRunning = false; 
    
    showpendingonly;

    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private counterService : Billingcounterservice,
        private aggregagtorService : Aggregatorservice,
        private orderService  : Orderservice,
        private deliveryBoyservice : Deliveryboyservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    getTips(ord: Order){

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == Constants.PYMT_CARD){
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        try{
                            var tip = parseFloat(strtip);
                            return tip;
                        }catch(err){}
                    }
                }
        
            }
        }
        return 0;
    }

        
    ngOnInit(){
        //this.fromdate = new Date(new Date().setHours(0,0,0,0));
        //this.todate = new Date(new Date().setHours(0,0,0,0));

        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
    


        this.selectedRadio = 'itemdetails';
        this.showpendingonly = false; 
        this.statusoptions = [
            { label: 'Processed', value: Constants.POS_ORDER_PROCESSED },
            { label: 'Void', value: Constants.POS_ORDER_CANCELLED}
        ];
        
        this.selectedstatus = Constants.POS_ORDER_PROCESSED;
        
        this.cols = [
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'receiptno', header: 'Bill No' },
            { field: 'createdonstr', header: 'Bill date & Time' },
            { field: 'cart.sumitemtaxable', header: 'Net Amuont' },
            { field: 'cart.sumsc', header: 'Service Chage' },
            { field: 'vatamt', header: 'Taxable Amount' },
            { field: 'cart.sumvat', header: 'VAT' },
            { field: 'cart.sumtfee', header: 'Tourism Fee' },
            { field: 'cart.summfee', header: 'Municipality Fee' },
            { field: 'cart.netpayable', header: 'Total Amount' },
            
        ];

        this.cols.push({ field: 'paymentstring', header: 'Settlement' });
        this.cols.push({ field: 'statusstr', header: 'Status' });
        
        this.orderlist = [];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    setDeliveredBy(ord : Order){
        var dboy = this.deliveryBoyservice.getDeliveryboyStringForId(ord.deliveredby);
        ord.deliveredbystr = dboy;
    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 
    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
        //if(ord.orderstatus != Constants.POS_ORDER_CANCELLED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;
            this.totalreceivableamount = this.totalreceivableamount + ord.cart.pendingamount;
        //}
    }

    roundTotals(){
        
    }

    selectedRadio;



    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM/yy HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
    }

    findInArray(arrtokens,newtok){
        for(var item of arrtokens){
            if(item == newtok) return true; 
        }
        return false; 
    }
    
    setKOTTokens(ord : Order){
        var arrtoken = [];
        if( ord.cart && ord.cart.lineitems != null && ord.cart.lineitems.length > 0){
            for(var item of ord.cart.lineitems){
                if(!this.findInArray(arrtoken,item.kottoken)){
                    arrtoken.push(item.kottoken);
                }
            }

        }
        ord.kotnum = arrtoken.join("|");
        ord.kotcount = arrtoken.length;


    }
    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
            if(ord.countertype == Constants.ONLINE){
                var counter = this.counterService.getBillingcounterForId(ord.counterid);
                
                ord.aggrname = counter.aggregatorname;
            }
        
            
            ord.details = Utils.getOrderDetails(ord);
        }

        //Set mode wise payments on order 
        /*ord.pymtcash = Utils.getModeWisePayment(Constants.PYMT_CASH,ord);
        ord.pymtcard = Utils.getModeWisePayment(Constants.PYMT_CARD,ord);
        ord.tips = this.getTips(ord);
        ord.pymtonline = Utils.getModeWisePayment(Constants.PYMT_PG,ord);
        ord.pymtforex = Utils.getModeWisePayment(Constants.PYMT_FOREX,ord);
        ord.pymtcustwallet = Utils.getModeWisePayment(Constants.PYMT_WALLET,ord);
        ord.pymtaggregator = Utils.getModeWisePayment(Constants.PYMT_AGGREGATOR,ord);
        ord.pymtcomplimentary = Utils.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord);
        ord.pymtzomatopro = Utils.getModeWisePayment(Constants.PYMT_ZOMATO_PRO,ord);
        ord.pymtothercredit = Utils.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);
        ord.pymtcoupon = Utils.getModeWisePayment(Constants.PYMT_COUPON,ord);*/
        
        
        
        if (ord.receiptno && ord.receiptno.length > 0){
            if(ord.receiptno.indexOf("/") > 0){
                ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
            }else{
                ord.rcptnodisplay = ord.receiptno;
            }
        }
        

    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }


    bifurcateDiscounts ( ord : Order){
        ord.fooddisc = 0;
        ord.bevdisc = 0;
        ord.misdisc = 0;
        ord.tobdisc = 0;
         
        for(var item of ord.cart.lineitems){
            if(item.discount > 0){
                var prod = this.dataService.getProductForId(item.productid);
                if(prod){
                    var cat = this.dataService.getCategoryForId(prod.categoryid);
                    if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                        ord.bevdisc = Utils.round(ord.bevdisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                        ord.fooddisc = Utils.round(ord.fooddisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                        ord.tobdisc = Utils.round(ord.tobdisc + item.discount,3);
                    }else{
                        ord.misdisc = Utils.round(ord.misdisc + item.discount,3);
                    }
                }
            }
        } 
    }

    


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){
            
                var ord = this.orderService.getClone(item);
                console.log('Receipt no :: ' + ord.receiptno);
                
                //Get status 
                ord.statusstr = Utils.getStatusStr(ord);
                //ord.orderitemsstr = Utils.itemsAsString(ord);
                //ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
                
                if(this.dataService.retailer.id == 1554){
                    ord.vatamt = Utils.round(ord.cart.sumitemtaxable + ord.cart.sumsc,3);
                }

                this.setDateStrings(ord);
                this.setPaymentString(ord);
                //this.setDeliveredBy(ord);
                //this.setKOTTokens(ord);
                //this.bifurcateDiscounts(ord);
                this.filteredlist.push(ord);
                this.incrementTotals(ord);
                
                
        

        }
        this.filteredlist = [... this.filteredlist];
            

    }

    async setData(dt : Table){
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


