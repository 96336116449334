import {Kitchen} from '../domain/kitchen'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { Billingcounter } from '../domain/billingcounter';
import { Consumercart } from '../domain/consumercart';
import { Order } from '../domain/order';
import { Cartlineitem } from '../domain/cartlineitem';
import { Kot } from '../domain/kot';
import { Kottoken } from '../domain/kottoken';

import { Billingcounterservice } from './billingcounterservice';
import { DatePipe } from '@angular/common';
import { Printingservice } from './printingservice';
import { Tableorder } from '../domain/tableorder';
import { Tablerequest } from '../domain/tablerequest';
import { Kitchenservice } from './kitchenservice';
import { Userservice } from './userservice';
import { Qrtablemap } from '../domain/qrtablemap';

@Injectable()
export class Qrorderservice  {
     constructor(
         private couchdbService : Couchdbservice,
         private dataService : Appdataservice,
         private kitchenService : Kitchenservice,
         private dynamoDBService : Dynamodbservice,
            
         private userService : Userservice,
         private printerService : Printerservice,
         private printingService : Printingservice,
         private datePipe : DatePipe,
         private counterService : Billingcounterservice,
         
     ){
         this.qrordersinprocess = [];
         this.qrrequests = [];
     }

    
    //Vars for KDS 
    qrordersinprocess : Tableorder[] ;
    qrrequests : Tablerequest[];   
    processingorders;

    goQROrders(){
        
    }

 
    //******************* FUNCTONS FOR KDS *******************/
    lastupdatedon; //Last updated on timestamp

    async startQROrderpolling(){
        try{
            //Initialize polling vars 
            if(!this.qrordersinprocess) this.qrordersinprocess = [];
            if(!this.lastupdatedon) this.lastupdatedon = 0;
        
            if(!this.processingorders){
                 this.getNewOrders();
            }
            
            //This refresh every 5 seconds 
            setTimeout(()=>{this.startQROrderpolling()},5000);
        }catch(err){}
    }

    checkQRId(counter : Billingcounter, qrid){
        for(var item of counter.cart.lineitems){
            if(item.qrid == qrid) return true; 

        }

        return false; 
    }

    async markRequestsAttended(processingList : Tablerequest[]){
        for(var req of processingList){
            req.status = Constants.REQUEST_ATTENDED;
            if(this.dataService.usedynamo){
                req.reqstatus = Constants.REQUEST_ATTENDED;
                await this.dynamoDBService.putItem("tablerequests",req);
            }else{
                await this.couchdbService.putItem("tablerequests",req);
            }
        }

        this.getNewOrders();
    }
    
    async rejectOrders(processingList : Tableorder[], rejectreason){
        for(var ord of processingList){
            ord.rejectreason = rejectreason;
            ord.status = Constants.QR_ORD_ITEM_CANCELLED;
            if(this.dataService.usedynamo){
                ord.ordstatus = ord.status;
                await this.dynamoDBService.putItem('tableorders',ord);
            }else
                await this.couchdbService.putItem('tableorders',ord);
        }
        await this.getNewOrders();
        return;
    }


    qrcountermap : Qrtablemap[];

    async initTableMap(){
        this.qrcountermap = [];

        var retlist= await this.dynamoDBService.getAllItemsForPosId('qrtablemap');
        if(retlist){
            if(Constants.DEBUG_MODE) console.log('|QR|Num maps-->' + JSON.stringify(retlist));
            this.qrcountermap = <Qrtablemap[]> retlist; 
            
            return true;
        }else{
            return false;
        }
        
     }

     public getActCounterId(qrcounterid){
         if(this.qrcountermap){
            for(var qrctr of this.qrcountermap){
                if(qrctr.qrid == qrcounterid) return qrctr.actid;
            }
        }
         return 0; 
     }
     
     
    async processorders(counter : Billingcounter,processingList : Tableorder[]){
        
        for(var order of processingList){
            var prod = this.dataService.getProductForId(order.productid);
            if(!prod){
                return "Product not found for order id: " + order.id;
            }
        }

        //Set the selected counter
        this.counterService.selectedcounter = counter; 

        for(var order of processingList){
            var prod = this.dataService.getProductForId(order.productid);
            if(!this.checkQRId(counter,order.id))
                this.counterService.addProductToCart(prod,order.quantity,order.customization,order.customizationrate,order.instructions,order.id,order.custinv);
        }

        //Now mark the status as updated on the products
        for(var order of processingList){
            order.status = Constants.QR_ORD_ACCEPTED;
            if(this.dataService.usedynamo){
                order.ordstatus = Constants.QR_ORD_ACCEPTED;
                await this.dynamoDBService.putItem('tableorders',order);
            }else
                await this.couchdbService.putItem('tableorders',order);
        }

        await this.getNewOrders();
        var ret = await this.kitchenService.sendToKitchen();
        if(ret){    
            return "ok";
        }else{
            return "Error sending to kitchen, please send to kitchen from the counter!";
        }


    }

    lastsoundplayed  =0;

    playAudio(){
        try{
            this.lastsoundplayed = new Date().getTime();

            let audio = new Audio();
            audio.src = "assets/demo/images/readyalert.mp3";
            audio.load();
            audio.play();
        }catch(err){
            console.log('|SOUND|Error playing sound ' + err);
        }
    }


    
    
    

    async getNewOrders(){

        try{
            //this.billingcounterlist = [];
            //Get current KOTs with updated timestamp 
            //this.qrordersinprocess = [];
            //this.qrrequests = [];
            var retlist; 
            var orders;
            var requests;
            var curorders = 0;
            var currequests = 0;

            if(this.qrordersinprocess && this.qrordersinprocess.length > 0){
                curorders = this.qrordersinprocess.length;
            }

            if(this.qrrequests && this.qrrequests.length > 0){
                currequests = this.qrrequests.length;
            }

            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and ordstatus = :v_status ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_status" :  Constants.QR_ORD_PLACED};
                console.log('Params' + JSON.stringify(attribparams));
                orders = await this.dynamoDBService.queryOnIndex('tableorders', "posid-ordstatus-index", conditionexpression,attribparams);
                console.log('Orders--> ' + JSON.stringify(orders));
                
            }else{
                var selector =  {"status" : {"$eq": Constants.QR_ORD_PLACED}};
                retlist = await this.couchdbService.getFromIndex('tableorders',selector);
                if(retlist && retlist.docs)
                orders = <Tableorder[]> retlist.docs;
            }
            
            if(orders){
                for(var order of orders){
                    if(this.dataService.retailer.id == 1338 || this.dataService.retailer.id == 1343 ){
                        var ctrtocheck = "" + this.getActCounterId(order.counterid);
                        order.countername = this.counterService.getBillingcounterNameForId(parseInt(""+ ctrtocheck));
                    }else{
                        order.countername = this.counterService.getBillingcounterNameForId(parseInt(""+ order.counterid));
                    }
                    if(Constants.DEBUG_MODE) console.log('|QR|Counter id-->' + order.counterid + ' Name ' + order.countername);
                }
                
                this.qrordersinprocess = orders;
                this.qrordersinprocess = [... this.qrordersinprocess];
                this.qrordersinprocess.sort((a:Tableorder, b: Tableorder)=> (a.counterid)  < (b.counterid)  ? -1 : 1 );
                this.lastupdatedon = new Date().getTime();

                if(orders && orders.length > curorders){
                    this.playAudio();
                }

                if(orders.length > 0){
                    if(this.lastsoundplayed){
                        var curtime = new Date().getTime();
                        if(curtime - this.lastsoundplayed > 10000){
                            this.playAudio();
                        }
                    }   
                }


                if(this.dataService.usedynamo){
                    var conditionexpression = "posid = :v_retailerid and reqstatus = :v_status ";  
                    var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_status" :  Constants.NEW_REQUEST};
                    requests = <Tablerequest[]>  await this.dynamoDBService.queryOnIndex('tablerequests', "posid-reqstatus-index", conditionexpression,attribparams);
                }else{
                    selector =  {"status" : {"$eq": Constants.NEW_REQUEST}};
                    retlist = await this.couchdbService.getFromIndex('tablerequests',selector);
                    if(retlist && retlist.docs){
                        requests = <Tablerequest[]>  retlist.docs;
                    }
                }
                if(requests){
                    //if(Constants.DEBUG_MODE) console.log('|QR REQ|Num Requests-->' + retlist.docs.length);
                    for(var req of requests){
                        //req.countername = this.counterService.getBillingcounterNameForId(parseInt(""+ req.counterid));

                        if(this.dataService.retailer.id == 1338 || this.dataService.retailer.id == 1343 ){
                            var ctrtocheck = "" + this.getActCounterId(req.counterid);
                            req.countername = this.counterService.getBillingcounterNameForId(parseInt(""+ ctrtocheck));
                        }else{
                            req.countername = this.counterService.getBillingcounterNameForId(parseInt(""+ req.counterid));
                        }

                        req.requesttypestr = Constants.getRequestTypeStr(req.requesttype);
                    }
                    
                    if(requests.length > currequests){
                        this.playAudio();
                    }

                 
                    if(requests.length > 0){
                        if(this.lastsoundplayed){
                            var curtime = new Date().getTime();
                            if(curtime - this.lastsoundplayed > 10000){
                                this.playAudio();
                            }
                        }   
                    }
                    
                    this.qrrequests = requests;
                    this.qrordersinprocess = [... this.qrordersinprocess];
                    
                    return true;
                }else{
                    return false;
                }

            }else{
                return false;
            }

        }catch(err){

        }

     }

     

}