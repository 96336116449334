import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {DataService}  from '../../service/dataservice';
import {Constants}    from '../../domain/constants';
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Utils } from 'src/app/service/utils';
import { Order } from 'src/app/domain/order';
import { Orderservice } from 'src/app/service/orderservice';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kot } from 'src/app/domain/kot';
import { Kitchenservice } from 'src/app/service/kitchenservice';

@Component({
    templateUrl: 'alertreport.html',
    providers: [ConfirmationService]
})
export class Alertreport implements OnInit 
{
    fromdate          : Date;
    todate            : Date;



    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private messageService : MessageService,
        private orderService : Orderservice,
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));

        /*this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());*/



        this.first=0;
        this.setData();

    }

    
    

    orderlist : Order[]; 
    cancelorderlist : Order[]; 
    pendingpaymentlist : Order[]; 
    discountorderlist : Order[];  
    duplicateprintlist : Order[]; 
    cancelitemlist :  Cartlineitem[];
    cancelkotlist : Kot[];



    first : number = 0; 

    
    setVariousSalesorderLists(){
        this.cancelorderlist = [];
        this.pendingpaymentlist = [];
        this.discountorderlist = []; 
        this.duplicateprintlist =[];

        for(var order of this.orderlist ){
            if(order.orderstatus == Constants.POS_ORDER_CANCELLED) 
                this.cancelorderlist.push(order);
            
            if(order.orderstatus == Constants.POS_ORDER_PROCESSED){
                
                if(order.cart.pendingamount > 0) 
                    this.pendingpaymentlist.push(order);

                
                if(order.cart.totaldiscount > 0) 
                    this.discountorderlist.push(order);

                if(order.reprintcount > 0){
                    this.duplicateprintlist.push(order);
                }
    
             }   

        }


        
        this.cancelorderlist = [... this.cancelorderlist];
        this.pendingpaymentlist = [... this.pendingpaymentlist];
        this.discountorderlist = [... this.discountorderlist];
        this.duplicateprintlist = [... this.duplicateprintlist];
        
        

    }
    
    
   

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }



    
    async setData(){

        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        
        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }


        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }
        
        this.isRunning = true; 

        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            for (var item of this.orderlist){
                item.statusstr = Utils.getStatusStr(item);
                item.orderitemsstr = Utils.itemsAsString(item);
                item.countertypestr = Constants.getCounterTypeName(item.countertype);
                item.createdonstr = this.datePipe.transform(item.createdon, "dd/MM HH:mm");
                item.businessdatestr = Utils.getBusinessDateAsString("" + item.businessdate);
            }
        }
    
        
        this.setVariousSalesorderLists();
        this.cancelitemlist = [];
        this.cancelkotlist = [];
        //Get the KOTs from running counters FIRST 
        var kotlist =  <Kot[]> await this.kitchenService.getKotList(fromdate,todate);

        if(kotlist){
            kotlist.sort((a:Kot,b:Kot)=> a.id < b.id  ? -1 : 1 );
            for(var kot of kotlist){
                    for(var kotitem of kot.lineitems){
                        if(kotitem.cancelled) {
                            kotitem.kottime = kot.id;
                            kotitem.kottoken = kot.kottoken;
                            kotitem.kottimestr = this.datePipe.transform(kotitem.kottime, "dd/MM HH:mm");
                            kotitem.proddetails = kotitem.productname ; 
                            if(kotitem.customization && kotitem.customization.length > 0 ) kotitem.proddetails = kotitem.proddetails + ' [' + kotitem.customization + ']';
                            if(kotitem.instructions && kotitem.instructions.length > 0 ) kotitem.proddetails = kotitem.proddetails + ' {' + kotitem.instructions + '}';
                            if(kotitem.foodprepared) kotitem.foodpreparedstr = 'Yes'; else kotitem.foodpreparedstr= 'No';
                            this.cancelitemlist.push(kotitem);
                        }
                        
                    }

                    if(kot.iscancelled){
                        this.kitchenService.setStringVarsinKot(kot);
                        if(kot.foodprepared) kot.foodpreparedstr = 'Yes'; else kot.foodpreparedstr = 'No';
                        this.cancelkotlist.push(kot);
                        
                    }
            }

            this.cancelitemlist = [... this.cancelitemlist];
            this.cancelkotlist = [... this.cancelkotlist];
            
        }
        
        this.isRunning = false; 
    }





}


