import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { openDB } from 'idb';


@Component({
    templateUrl: 'itemsalessummary.html',
    providers: [ConfirmationService]
})
export class Itemsalessummary implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Summaryrecord[];
    paymentmodes : SelectItem[];
    categorylist : SelectItem[];
    
    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;
    currentcategoryid; 

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private counterService : Billingcounterservice,
        private orderService  : Orderservice,

        private userService : Userservice, 
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    setCols(){
        this.cols = [
            { field: 'description', header: 'Description' },
            { field: 'summaryval11', header: 'Subcategory' },
            { field: 'summaryval2', header: 'Item count' },
            { field: 'summaryval3', header: 'Item amount' },
            { field: 'summaryval4', header: 'Item gross' },
            { field: 'summaryval5', header: 'Discount' },
            { field: 'summaryval6', header: 'Taxable amount' },
            { field: 'summaryval7', header: 'Tax' },
            { field: 'summaryval8', header: 'Item Total' }
        ];

        
        if(this.currentviewtype == Constants.VW_PRODUCT || this.currentviewtype == Constants.VW_PRODUCT_CUST){
            this.cols.unshift({ field: 'summaryval14', header: 'PLU Code' });
        }

        if(this.selectedRadio == 'datewise')
            this.cols.unshift({ field: 'summaryval1', header: 'Business date' });
        

    }
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'summarized';
        
        this.viewtypes = [
            { label: 'Product wise', value: Constants.VW_PRODUCT},
            { label: 'Product/Customization wise', value: Constants.VW_PRODUCT_CUST},
            { label: 'Category wise', value: Constants.VW_CATEGORY},
            { label: 'Sub category wise', value: Constants.VW_SUBCATEGORY},
            { label: 'Kitchen wise', value: Constants.VW_KITCHEN}
        ];

        this.currentviewtype = Constants.VW_PRODUCT;
        this.currentcategoryid = 0;

        this.orderlist = [];
        this.categorylist = [];
        this.categorylist.push({ label: 'All', value: 0});
        
        for(var cat of this.dataService.catlist){
            this.categorylist.push({ label: cat.name, value: cat.id});
        }

        
    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt,false);
    }

    refreshReportCategory(catid, dt : Table){
        this.currentcategoryid = catid;
        this.setData(dt,false);
    }

    changeDatewiseOption(dt : Table){
        console.log('Setting data...');
        this.setData(dt,false);
    }
    
    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    
    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            
        
    }

    roundTotals(){
        
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 

    exportCSV(dt : DataTable){
        this.setCols();
        dt.exportCSV();
    }
    
    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);

        for(var item of ord.cart.lineitems){
            var prod = this.dataService.getProductForId(item.productid);
            
            if(prod){
                //item.pcode = this.getInventoryPLUCode(item,prod);
                item.pcode = prod.code;
                var cat  = this.dataService.getCategoryForId(prod.categoryid);
                item.occode = 1;
                if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                    item.occode= 2;
                }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                    item.occode= 1;
                }else  if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                    item.occode= 4;
                }else{
                    item.occode= 3;
                }
            }
        }

    }

    createNewSummaryRecord(item : Cartlineitem,ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheck(item,ord);
        rec.description = this.getDescritpion(item);

        if(this.currentviewtype == Constants.VW_PRODUCT)
            rec.summaryval11 = this.dataService.getSubcategoryNameForId(item.subcatid);
        else
            rec.summaryval11 = "";

        rec.summaryval1 = ord.businessdatestr;
        rec.summaryval2 =  item.quantity;
        rec.summaryval3 =  item.amount;
        rec.summaryval4 =  item.grossamt;
        rec.summaryval5 =  item.discount;
        rec.summaryval6 =  item.taxableamt;
        rec.summaryval7 =  item.itemtax;
        rec.summaryval8 =  item.itemamount
        
        rec.summaryval14= item.pcode;
        
        
        return rec; 
    }
    

    addToExistingRecord(rec :Summaryrecord, item : Cartlineitem){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + item.quantity,3);
        rec.summaryval3 =  Utils.round(rec.summaryval3 + item.amount,3);
        rec.summaryval4 =  Utils.round(rec.summaryval4 + item.grossamt,3);
        rec.summaryval5 =  Utils.round(rec.summaryval5 + item.discount,3);
        rec.summaryval6 =  Utils.round(rec.summaryval6 + item.taxableamt,3);
        rec.summaryval7 =  Utils.round(rec.summaryval7 + item.itemtax,3);
        rec.summaryval8 =  Utils.round(rec.summaryval8 + item.itemamount,3);
        
    }

    getKeytoCheck(item : Cartlineitem,ord : Order){
        var key ="";
        if(this.selectedRadio == 'datewise')
            key = ord.businessdatestr;

        switch(this.currentviewtype){
            case Constants.VW_PRODUCT: return key +  item.productid;
            case Constants.VW_CATEGORY: return key +  item.catid;
            case Constants.VW_SUBCATEGORY: return key +  item.subcatid;
            case Constants.VW_KITCHEN: return key + item.kitchenid;
            
        }
        
    }


    createNewRecordProduct(ord: Order, sumrec : Summaryrecord){
        var rec = <Summaryrecord>{};

        var key ="";
        if(this.selectedRadio == 'datewise')
            key = ord.businessdatestr;

        rec.id = key + sumrec.summaryval4; //PLU CODE  
        rec.description = sumrec.summaryval5; //PRODUCT NAME  with customization 
        
        var subcat = this.dataService.getSubcategoryForId(sumrec.summaryval11);
        if(subcat){
            rec.summaryval11 = subcat.name;
        }
        
        rec.summaryval1 = ord.businessdatestr; //Business date
        rec.summaryval2 =  Utils.round(sumrec.summaryval6,3); //Quantity
        rec.summaryval3 =  Utils.round(sumrec.summaryval12,3); // Item Amount
        rec.summaryval4 =  Utils.round(sumrec.summaryval7,3);   //Gross Amount 
        rec.summaryval5 =  Utils.round(sumrec.summaryval10,3);  //Item discount  
        rec.summaryval6 =  Utils.round(sumrec.summaryval14,3);  // Taxable amount 
        rec.summaryval7 =  Utils.round(sumrec.summaryval13,3); //itemtax
        rec.summaryval8 =  Utils.round(sumrec.summaryval13 +sumrec.summaryval14,3); //itemtax
        rec.summaryval14 =  Utils.round(sumrec.summaryval4,3); //PRODUCT CODE
        


        this.filteredlist.push(rec);
    }


    addToExistingRecordProduct(rec :Summaryrecord,  sumrec : Summaryrecord){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + sumrec.summaryval6,3);
        rec.summaryval3 =  Utils.round(rec.summaryval3 + sumrec.summaryval12,3);
        rec.summaryval4 =  Utils.round(rec.summaryval4 + sumrec.summaryval7,3);
        rec.summaryval5 =  Utils.round(rec.summaryval5 + sumrec.summaryval10,3);
        rec.summaryval6 =  Utils.round(rec.summaryval6 + sumrec.summaryval14,3);
        rec.summaryval7 =  Utils.round(rec.summaryval7 + sumrec.summaryval13,3);
        rec.summaryval8 =  Utils.round(rec.summaryval8 + sumrec.summaryval13 + sumrec.summaryval14,3);
        
    }



    getDescritpion(item : Cartlineitem){
        switch(this.currentviewtype){
            case Constants.VW_PRODUCT: return  item.productname;

            case Constants.VW_CATEGORY:
                var cat = this.dataService.getCategoryForId (item.catid);
                if(cat) return cat.name; return "";
            case Constants.VW_SUBCATEGORY: 
                var subcat = this.dataService.getSubcategoryForId (item.subcatid);
                if(subcat) return subcat.name; return "";
            case Constants.VW_KITCHEN: 
                return this.kitchenService.getKitchenNameForId( item.kitchenid);
            
            
        }
        
    }

    //Get plu json 

    
    setFilterList(){
        this.filteredlist = [];
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
        


                var ord = this.orderService.getClone(item);
                this.setDetails(ord);
                if(ord.id == 1647111988318 ){
                        console.log('|ORDER ITEMS|' + ord.id + JSON.stringify(ord.cart.lineitems));
                }

                
                if(this.currentviewtype == Constants.VW_PRODUCT_CUST){
                    var arritemrec = <Summaryrecord[]> Utils.getFocusInsertDetailForReport(ord,this.dataService.getPosId());
                    for(var rec of arritemrec){
                        
                        var key ="";
                        if(this.selectedRadio == 'datewise')
                            key = ord.businessdatestr;
                
                        key=key + rec.summaryval4;
                        
                        if(rec.summaryval4 == "8600449"){
                            console.log('Quantity')
                        }

                        var currec = Utils.findInSummaryList(this.filteredlist, key);
                        if(currec){
                            this.addToExistingRecordProduct(currec,rec);
                        }else{
                            this.createNewRecordProduct(ord,rec);
                        }
                    }
                }else{
                    
                    for(var lineitem of ord.cart.lineitems){

                        if(this.currentcategoryid == 0 || lineitem.catid == this.currentcategoryid){
                            var key = this.getKeytoCheck(lineitem,ord);
                            var currec = Utils.findInSummaryList(this.filteredlist,key);
                            if(currec){
                                this.addToExistingRecord(currec,lineitem);
                            }else{
                                currec = this.createNewSummaryRecord(lineitem,ord);
                                this.filteredlist.push(currec);    
                            }
                        }
                    }
                }
                    
                    this.incrementTotals(ord);

                    

            }
                
                
        }

        if(this.selectedRadio == 'datewise')
            this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.id)  < (b.id)  ? -1 : 1 );
        else    
            this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval8)  > (b.summaryval8)  ? -1 : 1 );

        this.filteredlist = [... this.filteredlist];
        this.setCols();  

    }

    plujson;
    async setData(dt : Table, refetch){
        if(refetch){
            try{
            this.plujson = await this.dataService.getPLUCodeNames();
        }catch(err){}

            var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
            var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

            if(todate < fromdate){
                this.showErrorToast("To date can not be less than from date!");
                return;
            }

            if((this.todate.getTime() - this.fromdate.getTime()) > (92 * 24 * 60 * 60 * 1000)){
                this.showErrorToast("The data can be searched for maximum of 92 days!");
                return;
            }

            this.isRunning = true; 
            this.setTotalsToZero();
        
            this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
            this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
            }else{
                this.setFilterList();
            }
        }else{
            this.isRunning = true; 
            this.setTotalsToZero();
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


