import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Product } from 'src/app/domain/product';


@Component({
    templateUrl: 'datewisedineinanalysis.html',
    providers: [ConfirmationService]
})
export class Datewisedineinanalysis implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Summaryrecord[];
    paymentmodes : SelectItem[];
    categorylist : SelectItem[];

    
    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    displaychoicelist : SelectItem[]; 
    totalsrecord : Summaryrecord;

    arrguesttype;
    arrservedby; 
    curarray; 
    
    currentviewtype;
    currentcategoryid; 

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private counterService : Billingcounterservice,
        private orderService  : Orderservice,

        private userService : Userservice, 
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    getValue(rec : Summaryrecord, colpos){
        switch(colpos){
            case 1: return rec.summaryval1;
            case 2: return rec.summaryval2;
            case 3: return rec.summaryval3;
            case 4: return rec.summaryval4;
            case 5: return rec.summaryval5;
            case 6: return rec.summaryval6;
            case 7: return rec.summaryval7;
            case 8: return rec.summaryval8;
            case 9: return rec.summaryval9;
            case 10: return rec.summaryval10;
            case 11: return rec.summaryval11;
            case 12: return rec.summaryval12;
            case 13: return rec.summaryval13;
            case 14: return rec.summaryval14;
            
        }
    }

    setExportCols(){
        this.cols = [];
        this.cols.push({ field: 'description', header: 'Business Date' });
        var i = 1; 
        for(var str of this.curarray){
            this.cols.push({ field: 'summaryval' + i, header: str });
            i++;
        }

        this.cols.push({ field: 'summaryval14', header: 'Total' });

    }
    
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'summarized';
        
        this.viewtypes = [
            { label: 'Guest type wise', value: Constants.VW_GUEST_TYPE_WISE},
            { label: 'Served by wise', value: Constants.VW_SERVED_BY}
        ];

        this.displaychoicelist = [
            { label: 'Num guests', value: Constants.SHOW_NUMGUESTS},
            { label: 'Order count', value: Constants.SHOW_ORDERCOUNT},
            { label: 'Order amount', value: Constants.SHOW_BILLAMT}
            
        ];


        if(this.dataService.retailer.appconfig.posconfig.guesttypes && this.dataService.retailer.appconfig.posconfig.guesttypes.length > 0 ){
            this.arrguesttype = this.dataService.retailer.appconfig.posconfig.guesttypes.split(',');
        }else{
            this.arrguesttype = [];
        }

        this.arrservedby = [];
        this.curarray = this.arrguesttype;

        this.totalsrecord = <Summaryrecord>{};
        this.setTotalsToZero();
        this.currentviewtype = Constants.VW_GUEST_TYPE_WISE;
        this.currentdisplaychoice = Constants.SHOW_NUMGUESTS;

        this.currentcategoryid = 0;

        this.orderlist = [];
        this.categorylist = [];
        this.categorylist.push({ label: 'All', value: 0});
        
        for(var cat of this.dataService.catlist){
            this.categorylist.push({ label: cat.name, value: cat.id});
        }

        /*this.cols = [
            { field: 'summaryval1', header: 'Business Date' },
            { field: 'summaryval2', header: 'Time' },
            { field: 'summaryval12', header: 'Bill no' },
            { field: 'description', header: 'Scheme Code' },
            { field: 'summaryval11', header: 'Item Description' },
            { field: 'summaryval3', header: 'Quantity' },
            { field: 'summaryval4', header: 'Original rate' },
            { field: 'summaryval5', header: 'Org customization rate' },
            { field: 'summaryval6', header: 'Original gross' },
            { field: 'summaryval7', header: 'New rate' },
            { field: 'summaryval8', header: 'New customization rate' },
            { field: 'summaryval9', header: 'New gross' },
            { field: 'summaryval10', header: 'Scheme Benefit' }
            
        ];*/

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt,false);
    }

    refreshReportCategory(catid, dt : Table){
        this.currentcategoryid = catid;
        this.setData(dt,false);
    }

    changeDatewiseOption(dt : Table){
        console.log('Setting data...');
        this.setData(dt,false);
    }
    
    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    setHorizontaltotals(){
        for(var rec of this.filteredlist){
            rec.summaryval14 = Utils.round(rec.summaryval1 + rec.summaryval2 + rec.summaryval3 + rec.summaryval4 + 
                                rec.summaryval5 + rec.summaryval6 + rec.summaryval7 + rec.summaryval8 + 
                                rec.summaryval9 + rec.summaryval10 + rec.summaryval11 + rec.summaryval12 + 
                                rec.summaryval13, 2);

        }
    }

    setVerticalTotals(){
        this.setTotalsToZero();
        for(var rec of this.filteredlist){
            this.incrementTotals(rec);
        }
        this.roundTotals();
    }
    
    setTotalsToZero(){
        this.totalsrecord = <Summaryrecord>{};
        this.totalsrecord.summaryval1 = 0;
        this.totalsrecord.summaryval2 = 0;
        this.totalsrecord.summaryval3 = 0;
        this.totalsrecord.summaryval4 = 0;
        this.totalsrecord.summaryval5 = 0;
        this.totalsrecord.summaryval6 = 0;
        this.totalsrecord.summaryval7 = 0;
        this.totalsrecord.summaryval8 = 0;
        this.totalsrecord.summaryval9 = 0;
        this.totalsrecord.summaryval10 = 0;
        this.totalsrecord.summaryval11 = 0;
        this.totalsrecord.summaryval12 = 0;
        this.totalsrecord.summaryval13 = 0;
        this.totalsrecord.summaryval14 = 0;
        
    
    }


    incrementTotals(rec : Summaryrecord){
        this.totalsrecord.summaryval1 = this.totalsrecord.summaryval1 + rec.summaryval1;
        this.totalsrecord.summaryval2 = this.totalsrecord.summaryval2 + rec.summaryval2;
        this.totalsrecord.summaryval3 = this.totalsrecord.summaryval3 + rec.summaryval3;
        this.totalsrecord.summaryval4 = this.totalsrecord.summaryval4 + rec.summaryval4;
        this.totalsrecord.summaryval5 = this.totalsrecord.summaryval5 + rec.summaryval5;
        this.totalsrecord.summaryval6 = this.totalsrecord.summaryval6 + rec.summaryval6;
        this.totalsrecord.summaryval7 = this.totalsrecord.summaryval7 + rec.summaryval7;
        this.totalsrecord.summaryval8 = this.totalsrecord.summaryval8 + rec.summaryval8;
        this.totalsrecord.summaryval9 = this.totalsrecord.summaryval9 + rec.summaryval9;
        this.totalsrecord.summaryval10 = this.totalsrecord.summaryval10 + rec.summaryval10;
        this.totalsrecord.summaryval10 = this.totalsrecord.summaryval11 + rec.summaryval11;
        this.totalsrecord.summaryval12 = this.totalsrecord.summaryval12 + rec.summaryval12;
        this.totalsrecord.summaryval13 = this.totalsrecord.summaryval13 + rec.summaryval13;
        this.totalsrecord.summaryval14 = this.totalsrecord.summaryval14 + rec.summaryval14;
    }

    roundTotals(){
        this.totalsrecord.summaryval1 = Utils.round(this.totalsrecord.summaryval1,2);
        this.totalsrecord.summaryval2 = Utils.round(this.totalsrecord.summaryval2,2);
        this.totalsrecord.summaryval3 = Utils.round(this.totalsrecord.summaryval3,2);
        this.totalsrecord.summaryval4 = Utils.round(this.totalsrecord.summaryval4,2);
        this.totalsrecord.summaryval5 = Utils.round(this.totalsrecord.summaryval5,2);
        this.totalsrecord.summaryval6 = Utils.round(this.totalsrecord.summaryval6,2);
        this.totalsrecord.summaryval7 = Utils.round(this.totalsrecord.summaryval7,2);
        this.totalsrecord.summaryval8 = Utils.round(this.totalsrecord.summaryval8,2);
        this.totalsrecord.summaryval9 = Utils.round(this.totalsrecord.summaryval9,2);
        this.totalsrecord.summaryval10 = Utils.round(this.totalsrecord.summaryval10,2);
        this.totalsrecord.summaryval11 = Utils.round(this.totalsrecord.summaryval11,2);
        this.totalsrecord.summaryval12 = Utils.round(this.totalsrecord.summaryval12,2);
        this.totalsrecord.summaryval13= Utils.round(this.totalsrecord.summaryval13,2);
        this.totalsrecord.summaryval14 = Utils.round(this.totalsrecord.summaryval14,2);
        
         
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        var bsndate = Utils.getDateFromBusinessdate("" + ord.businessdate);
        ord.businessdatestr = this.datePipe.transform(bsndate, "EEEE, MMM d");
        ord.servedbystr = this.userService.getUserNameForId(ord.servedby);
    }

    addToRecordAtColumnPosition(rec : Summaryrecord,position, value){
        switch(position){
            case 1: rec.summaryval1 =  Utils.round(rec.summaryval1 + value,2); return; 
            case 2: rec.summaryval2 =  Utils.round(rec.summaryval2 + value,2); return; 
            case 3: rec.summaryval3 =  Utils.round(rec.summaryval3 + value,2); return; 
            case 4: rec.summaryval4 =  Utils.round(rec.summaryval4 + value,2); return; 
            case 5: rec.summaryval5 =  Utils.round(rec.summaryval5 + value,2); return; 
            case 6: rec.summaryval6 =  Utils.round(rec.summaryval6 + value,2); return; 
            case 7: rec.summaryval7 =  Utils.round(rec.summaryval7 + value,2); return; 
            case 8: rec.summaryval8 =  Utils.round(rec.summaryval8 + value,2); return; 
            case 9: rec.summaryval9 =  Utils.round(rec.summaryval9 + value,2); return; 
            case 10: rec.summaryval10 =  Utils.round(rec.summaryval10 + value,2); return; 
            case 11: rec.summaryval11 =  Utils.round(rec.summaryval11 + value,2); return; 
            case 12: rec.summaryval12 =  Utils.round(rec.summaryval12 + value,2); return; 
            case 13: rec.summaryval13 =  Utils.round(rec.summaryval13 + value,2); return; 
        }
    }

    getColumnPositionForGustType(guesttype){
        var i = 1;
        for(var gsttype of this.arrguesttype){
            if(guesttype == gsttype){
                return i;
            }
            i = i+1; 
        }
        return -1; 
    }

    getColumnPositionForServedBy(servedbystr){
        var i = 1;
        for(var servedby of this.arrservedby){
            if(servedby == servedbystr){
                return i;
            }
            i = i+1; 
        }
        this.arrservedby.push(servedbystr);
        return i; 
    }

    currentdisplaychoice;
    extractValueFromOrder(ord : Order){
        switch(this.currentdisplaychoice){
            case Constants.SHOW_BILLAMT: return ord.cart.sumitemtaxable;
            case Constants.SHOW_NUMGUESTS: return ord.numguests;
            case Constants.SHOW_ORDERCOUNT: return 1;
        }  
    }

   
    createNewSummaryRecord(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = ord.businessdate;
        rec.description = ord.businessdatestr;
        rec.summaryval1 =0;
        rec.summaryval2 =0;
        rec.summaryval3 =0;
        rec.summaryval4 =0;
        rec.summaryval5 =0;
        rec.summaryval6 =0;
        rec.summaryval7 =0;
        rec.summaryval8 =0;
        rec.summaryval9 =0;
        rec.summaryval10 =0;
        rec.summaryval11 =0;
        rec.summaryval12 =0;
        rec.summaryval13 =0;
        rec.summaryval14 =0;
        
        
        return rec; 
    }

    refreshViewtype(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt,false);
    }


    refreshDisplayChoice(displaychoice, dt : Table){
        this.currentdisplaychoice = displaychoice;
        this.setData(dt,false);
    }


    addToSummaryRec(rec : Summaryrecord, ord : Order){
        switch(this.currentviewtype){
            case Constants.VW_GUEST_TYPE_WISE:
                var pos = this.getColumnPositionForGustType(ord.guesttype);
                if(pos != -1){
                    this.addToRecordAtColumnPosition(rec,pos,this.extractValueFromOrder(ord));
                }
                return;
            case Constants.VW_SERVED_BY:
                this.addToRecordAtColumnPosition(rec,this.getColumnPositionForServedBy(ord.servedbystr),this.extractValueFromOrder(ord));
                return;
        }

    }


    
    setFilterList(){
        this.filteredlist = [];
        this.arrservedby = [];
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED && (item.countertype ==  Constants.NORMAL || item.countertype ==  Constants.AC_SPECIAL)){
        
                var ord = this.orderService.getClone(item);
                this.setDetails(ord);
                console.log('Checking for bsn date ' + ord.businessdate);
                var currec = Utils.findInSummaryList(this.filteredlist,ord.businessdate);
                if(currec){
                    this.addToSummaryRec(currec,ord);
                    
                }else{
                    currec = this.createNewSummaryRecord(ord);
                    this.addToSummaryRec(currec,ord); 
                    this.filteredlist.push(currec);    
                }
    
                

            }
                
                
        }

        
        this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.id)  < (b.id)  ? -1 : 1 );
        this.setHorizontaltotals();
        this.setVerticalTotals();
        this.setExportCols();


        if(this.currentviewtype == Constants.VW_GUEST_TYPE_WISE) this.curarray = this.arrguesttype;
        else this.curarray = this.arrservedby;

        this.filteredlist = [... this.filteredlist];
            

    }

    
    async setData(dt : Table, refetch){
        if(refetch){
            var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
            var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

            if(todate < fromdate){
                this.showErrorToast("To date can not be less than from date!");
                return;
            }

            if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
                this.showErrorToast("The data can be searched for maximum of 31 days!");
                return;
            }

            this.isRunning = true; 
            this.setTotalsToZero();
        
            this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
            this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
            }else{
                this.setFilterList();
            }
        }else{
            this.isRunning = true; 
            this.setTotalsToZero();
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


