import {Component,OnInit,Input,ViewChild,ElementRef, OnDestroy, HostListener} from '@angular/core';
import { Billingcounter } from '../domain/billingcounter';
import { Billingservice } from '../service/billingservice';
import { Appdataservice } from '../service/appdataservice';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Router } from '@angular/router';
import { Kitchenservice } from '../service/kitchenservice';
import { Constants } from '../domain/constants';
import { Kot } from '../domain/kot';
import { Summaryrecord } from '../domain/summaryrecord';
import { Cartlineitem } from '../domain/cartlineitem';
import { isUndefined } from 'util';

@Component({
    templateUrl: 'kitchendisplay.html'
})
export class Kitchendisplay implements OnInit,OnDestroy{
    billingcounterlist : Billingcounter[];
    showitembar : string;
 
    appVersion : string; 
    displayContextMenu = false;
    selectedCounter : Billingcounter;
 
    constructor(
        public billingService : Billingservice, 
        public dataService: Appdataservice,
        public messageService : MessageService,
        public datePipe : DatePipe,
        public counterService : Billingcounterservice, 
        public kitchenService : Kitchenservice,
        private _router : Router) {
        
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }

    }

    
    getPanelHeight(){
        var returningheight =  (window.innerHeight * 0.85) - 60 +  "px"; 
        return returningheight;
    }

    getViewHeight(){
        var returningheight =  (window.innerHeight * 0.85) +  "px"; 
        return returningheight;
    }
    getBillingViewStyle(){
        return "{height: '550px'}";
  
    }
    
    hideScrollbars(){
        document.documentElement.style.overflow = 'auto';  // firefox, chrome
    }


    counterlist : Billingcounter[];

    getWidth(){
        if(Constants.DEBUG_MODE) console.log('Width ' + this.getScreenWidth() + ' returning ' + this.getScreenWidth() * 0.9);
        return this.getScreenWidth() * 0.9;
    }

    getHeight(){
        if(Constants.DEBUG_MODE) console.log('Height' + this.getScreenHeight());
        return this.getScreenHeight() - 20;
    }
    

    getScreenWidth(){
        return window.screen.width;
    }

    getScreenHeight(){
        return window.screen.height;
    }
 

    ngOnDestroy(){
        this.dataService.lastrefreshtime = '';
    }
    
    ngOnInit() {
        this.hideScrollbars();
        this.curview = 'kot';
        if(!this.dataService.masterrefreshed){
            console.log('|KDS|Master data refresh..');
            this.refreshMasters();
        }
        this.setKOTPendingTimes();
        this.setCardWidth();
            
    }

    
    productsummary : Summaryrecord[] = [];
    curview = 'kot'; 
    kotView(){
        this.curview = 'kot';
    }


    findInProductList(summarylist : Summaryrecord[], item : Cartlineitem ) {
        for(var i = 0; i < summarylist.length; i++) {
            var proddetails = item.productid + '|' + item.customization + '|' + item.instructions;
            if(summarylist[i].id === proddetails ) {
                return i;
            }
        }
        return -1;
    }

    addToProductSummary( rec : Summaryrecord, kot : Kot, item : Cartlineitem){
        rec.summaryval1= rec.summaryval1 + item.quantity;
        var kotdetails = ' Kot#'+ kot.kottoken + ', Qty: ' + item.quantity + ', ' + kot.countername +'. '; 
        rec.summaryval2 = rec.summaryval2 + kotdetails;
    }


    isRunning : boolean = false; 

    cardwidth;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setCardWidth();
    }

    setCardWidth(){
        if(window.innerWidth < 600){
            this.cardwidth = window.innerWidth - 10;
        }else if(window.innerWidth < 900){
            this.cardwidth = window.innerWidth / 2 - 10 ;
        }else if(window.innerWidth < 1200){
            this.cardwidth = window.innerWidth / 3 - 10 ;
        }else{
            this.cardwidth = window.innerWidth / 4 - 10 ;
        }
    }



    setCounterView(){
        this.curview = 'counter';
    }
    productView(){
        this.showspinner = true;
        this.curview = 'product';
        this.productsummary = [];
        for(var kot of this.kitchenService.kotsinprocess){
            for(var item of kot.lineitems ){
                var status = item.kitstatus;
                if(!item.cancelled && item.kitstatus != Constants.READY_AT_KITCHEN){
                    var curindex = this.findInProductList(this.productsummary, item);
                    if(curindex >= 0){
                        var currecord = this.productsummary[curindex];
                        this.addToProductSummary(currecord,kot,item);
                    }else{
                        var newrecord = <Summaryrecord>{};
                        var proddetails = item.productid + '|' + item.customization + '|' + item.instructions;
                        
                        newrecord.id = proddetails;
                        newrecord.description = item.productname ;
                        if(item.customization && item.customization.length > 0) newrecord.description = newrecord.description + " (" + item.customization + ")";
                        if(item.instructions && item.instructions.length > 0) newrecord.description = newrecord.description + " {" + item.instructions + "}";

                        newrecord.summaryval1= item.quantity;

                        var kotdetails = ' Kot#'+ kot.kottoken + ', Qty: ' + item.quantity + ', ' + kot.countername +'. '; 
                        newrecord.summaryval2 = kotdetails;
                        this.productsummary.push(newrecord);
                        
                    }
                } 
            }
        }

        if(Constants.DEBUG_MODE) console.log('PRODSUM TOT' + this.productsummary.length);
        this.productsummary = [... this.productsummary];
        this.productsummary.sort((a:Summaryrecord, b: Summaryrecord)=> a.summaryval1 > b.summaryval1  ? -1 : 1 );
        
        this.showspinner= false;

    }

    tableView(){
        this.curview = 'table';
    }

    showErrorToast(message){
        console.log('**ERROR ' + message);
        this.messageService.clear();
        this.messageService.add({severity:'error',   key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success', life: 1000, key: 'billingtoast', closable: true, summary: 'Success', detail: message});
    }

    displayPicturebox;
    productid; 
    productname;
    displayImage(productid,productname){
        this.productid = productid;
        this.productname =productname; 
        console.log('PRODUCT NAME' + this.productname);
        this.displayPicturebox = true;
        
         
    }

    lastkotlist : Kot[];
    async fetchLastKOTs(){
        this.showspinner = true; 
        this.curview = 'lastkot';
        this.lastkotlist = <Kot[]> await this.kitchenService.getLastKotList();
        this.lastkotlist.sort((a:Kot, b: Kot)=> a.kottoken > b.kottoken  ? -1 : 1 );
        this.showspinner = false; 
    }

    async refreshMasters(){
        var email = '';
        var pass = '';

        var loginResponse = localStorage.getItem('loginresponse');
        if(loginResponse && loginResponse.length > 0){
            try{
                var jsonresp = JSON.parse(loginResponse);
                email = jsonresp.retailer.registeredemailid;
                pass = jsonresp.retailer.userpass;
            }catch(err){}
        }
        
        this.refreshingmasters = true;
        var retstatus = await this.dataService.initializeAppData(email, pass);
        this.refreshingmasters = false;
        
        if(retstatus){
            this.showSuccessToast('Master data refreshed!');
            this.dataService.masterrefreshed= true; 
        }else{
            this.showErrorToast('Error refreshing master data!');
        }
    
        
    }
    
    logout(){

        console.log('|TEST|*********** Logging out..' );
        this.dataService.isuserloggedin = false;
        localStorage.removeItem('email');
        localStorage.removeItem('pass');
    
        this._router.navigate(['/']);
    }
    


    refreshingmasters : boolean = false; 

    showError(message){
        //Here we will put an error message
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'toolbartoast', closable: true, summary: 'Error', detail:message});
        this.refreshingmasters = false;
    }
    
  
    stoprefreshing() {
		this.refreshingmasters = false;
    }
  
  
    setProductView(){

    }
    
    

    async markReady(kot  : Kot){
        if(!kot.arrselecteditems || kot.arrselecteditems.length == 0){
            this.showErrorToast('Please select at least one item to mark ready!');
            return; 
        }

        
        this.showspinner = true; 
        var cloneKot = this.cloneKOT(kot);
        var ret = await this.kitchenService.markFoodReady(cloneKot);
        this.showspinner = false; 

        if(ret){
            console.log('|KDS|KOT saved.. wud be updated in the list');
            this.kitchenService.kotsinprocess = [... this.kitchenService.kotsinprocess];
        }else{
            this.showErrorToast('Error updating, please try again!');
        }

        

    }

    showspinner; 

    setKOTPendingTimes(){
        if(this.kitchenService.kotsinprocess && this.kitchenService.kotsinprocess.length > 0){
            for(var kot of this.kitchenService.kotsinprocess){
                var billingsince = "";
                var timeelapsed =  new Date().getTime() - kot.id;
                timeelapsed = Math.round( timeelapsed / (60*1000));
                billingsince = " - " + timeelapsed + " Mins";    
                kot.pendingsince = billingsince; 

                //do it for all the item 
                for(var item of kot.lineitems){
                    if(item.kitstatus != Constants.READY_AT_KITCHEN){
                        item.preptime = timeelapsed;
                        
                        if(this.dataService.productsforsale){
                            var product = this.dataService.getProductForId(item.productid);
                            if(product && product.preparationtime && product.preparationtime < timeelapsed ){
                                item.timealert = true; 
                            }else{
                                item.timealert = false;
                            }
                        }
                    }
 
                }
            }
        }
        
        setTimeout(()=>{this.setKOTPendingTimes()},30000);
    }


    cloneKOT(fkot: Kot): Kot
    {
        var kot = <Kot>{};
        for(let prop in fkot) {
            kot[prop] = fkot[prop];
        }

        kot.lineitems = [];
        for(var item of fkot.lineitems){
            var newitem = new Cartlineitem();
            for(let prop in item) {
                newitem[prop] = item[prop];
            }
            
            kot.lineitems.push(newitem);
        }

        return kot; 
     }
    
    

}


