import {Usergroup} from '../domain/usergroup'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';

@Injectable()
export class Usergroupservice  {
     usergrouplist : Usergroup[];
     
     constructor(
         private couchdbService : Couchdbservice,
         private dataService : Appdataservice,
         private dynamoDBService : Dynamodbservice,
         private printerService : Printerservice,
         
     ){}

     public getNewId(){
         var maxid = 0;
         for(var ctr  of this.usergrouplist){
             if(ctr.id > maxid)
                maxid = ctr.id;
         }

         return maxid + 1; 
     }

     
     addEditInList(usergroup : Usergroup){
       var found = false; 
       for(var i=0;i<this.usergrouplist.length; i++){
           if(this.usergrouplist[i].id === usergroup.id){
               this.usergrouplist[i] = usergroup;
               found = true; 
               break;
           }
       }

       if(!found){
           this.usergrouplist.push(usergroup);
       }

    }


     public checkDuplicate(usergroup : Usergroup){
        for(var ctr  of this.usergrouplist){
            if(ctr.id != usergroup.id && ctr.name == usergroup.name)
               return true;
        }

        return false; 
    }


    getUsergroupNameForId(id){
        for(var usergroup of this.usergrouplist){
            if(usergroup.id === id) return usergroup.name ; 
        }
        return "";
    }

    getUsergroupForId(id){
        for(var usergroup of this.usergrouplist){
            if(usergroup.id === id) return usergroup; 
        }
        return null;
    }
    
    getUsergroupIdForName(name){
        for(var usergroup of this.usergrouplist){
            if(usergroup.name === name) return usergroup.id; 
        }
        return 0;
    }

       
    getUsergroup(name){
        for(var usergroup of this.usergrouplist){
            if(usergroup.name === name) return usergroup; 
        }
        return null;
    }

    getUsergroupDropdown(){
        var retlist = [];
        for(var usergroup of this.usergrouplist){
            if(!usergroup.onlyfromadmin || this.dataService.ismainadmin)
                retlist.push({label:usergroup.name, value:usergroup.id})
        }
        return retlist;
    }
    async initUsergroups(){
        this.usergrouplist = [];

        var retlist;
        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.getAllItemsForPosId('usergroups');
            if(retlist) this.usergrouplist = <Usergroup[]> retlist;
        }else{
            retlist = await this.couchdbService.getAllItems('usergroups');
            if(retlist) this.usergrouplist = <Usergroup[]> retlist.docs;
        }

        if(retlist){
            //if(Constants.DEBUG_MODE) console.log('|COUCH|Num usergroups-->' + JSON.stringify(retlist));
            return true;
        }else{
            return false;
        }
        
     }
     
     async save(usergroup){
        if(Constants.DEBUG_MODE) console.log('|COUCH|USERGROUPS|Saving USERGROUP ' + JSON.stringify(usergroup));

        var status;
        if(this.dataService.usedynamo){
            status = await this.dynamoDBService.putItem('usergroups',usergroup);    
        }else{
            status = await this.couchdbService.putItem('usergroups',usergroup);
        }

        if(status != "ERROR" && status != "CONFLICT"){
            this.addEditInList(usergroup);
            this.usergrouplist = [... this.usergrouplist];
            return status;
        }else{
            return status;
        }
        
     }
   



    

}