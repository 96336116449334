import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,Message,DataTable,Header,Dialog,InputText,Button, MessageService} from 'primeng/primeng';
import {TableModule} from 'primeng/table'
import {DataService} from '../service/dataservice';
import {Deliveryboyservice} from '../service/deliveryboyservice';

import {Deliveryboy} from '../domain/deliveryboy';
import {Router} from '@angular/router';	
import { Appdataservice } from '../service/appdataservice';


@Component({
    templateUrl: 'deliveryboys.html'
})
export class Deliveryboys implements OnInit {
    deliveryboys : Deliveryboy[];
    msgs: Message[] = [];
    isRunning : boolean; 
    
    msgssummary : Message[] = [];    

    constructor(public dataService : Appdataservice,
        public messageService : MessageService,
        public deliveryboyService : Deliveryboyservice, private _router : Router) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }
    
    ngOnInit() {
        this.deliveryboys = this.deliveryboyService.deliveryboylist;
    }
    
    selectedCounter : Deliveryboy;
    dlvrboy : Deliveryboy;
    displayAddEditDialog : boolean = false;
    enableEditButton : boolean = false;
    isForEdit : boolean = true;
    
    
    showAddEditDialog(fIsforEdit : boolean){
        this.msgs = [];
        this.msgssummary = [];
        this.isForEdit = fIsforEdit;
        
        if(fIsforEdit){
            this.dlvrboy = this.clonedlvrboy(this.selectedCounter);
        }else
            this.dlvrboy = <Deliveryboy>{};
        
        this.displayAddEditDialog = true;
    }
    
    
    
    clonedlvrboy(fdlvrboy: Deliveryboy): Deliveryboy
    {
        var dlvrboy = <Deliveryboy>{};
        for(let prop in fdlvrboy) {
            dlvrboy[prop] = fdlvrboy[prop];
        }
        return dlvrboy; 
     }
     
    onRowDoubleClick(event){
        this.showAddEditDialog(true);
    }
    
    onRowSelect(event) {
        this.enableEditButton =true;
    }
    
    onRowUnselect(event) {
        this.enableEditButton = false;
    }
    
    findselectedCounterIndex(): number {
        return this.deliveryboyService.deliveryboylist.indexOf(this.selectedCounter);
    }
    
    getDialogWidth() : number{
       return 400;
    }
    
    getDialogHeight() : number{
        return 250;
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    
    async refreshData(){
        this.isRunning = true;
        var ret = this.deliveryboyService.initDeliveryBoys();
        this.isRunning = false;
        if(ret){
            this.showSuccessToast('Data refreshed successfully!')
        }else{
            this.showSuccessToast('Error in refreshing data, please try again.')
        }
    }

    async save(dt : DataTable) 
    {
        this.msgs =[];
        this.isRunning = true;
            
        if(!this.dlvrboy.name  || this.dlvrboy.name  == ""){
            this.showErrorToast('Deliveryboy name can not be blank.')
            this.isRunning = false;
            return;
        }
        
        if(!this.dlvrboy.mobilenumber  || this.dlvrboy.mobilenumber  == ""){
            this.showErrorToast('Mobile number can not be blank.')
            this.isRunning = false;
            return;
        }
        
        
        if(isNaN(this.dlvrboy.mobilenumber )){
            this.showErrorToast('Mobile number must be numeric.')
            this.isRunning = false;
            return;
        }
        
        if(this.deliveryboyService.checkDuplicate(this.dlvrboy)){
            this.showErrorToast('Mobile number already exists.')
            this.isRunning = false;
            return;
        }


        if(!this.dlvrboy.empcode  || this.dlvrboy.empcode  == ""){
            this.showErrorToast('Employee code can not be blank.')
            this.isRunning = false;
            return;
        }

        if(!this.dlvrboy.active) this.dlvrboy.active = false;

        if(!this.isForEdit) this.dlvrboy.id = this.deliveryboyService.getNewId();
        
        this.dlvrboy.posid = this.dataService.getPosId();
        if(this.dataService.retailer.multilocation) this.dlvrboy.posid = this.dataService.getPosWithLocationId();

        
        var retstatus = await this.deliveryboyService.save(this.dlvrboy);
        
        //If true --> Coutner Added 
        if(retstatus != "ERROR" && retstatus != "CONFLICT"){
            this.isRunning = false;

            this.displayAddEditDialog = false; 
            this.enableEditButton = false;
            this.isRunning = false;
            this.showSuccessToast('Delivery boy saved successfully.'); 
        }else{
            if(retstatus == 'ERROR') this.showErrorToast('Error, please try again.'); 
            else this.showErrorToast('You seem to be having an old copy, please refresh data!'); 
            this.isRunning = false;
        }
    
            
    }
    
}


