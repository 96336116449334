import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { Kot } from 'src/app/domain/kot';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/service/utils';
import { Constants } from 'src/app/domain/constants';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Reprintlog } from 'src/app/domain/reprintlog';
import { Fb } from 'src/app/domain/fb';

@Component({
    templateUrl: 'customerfeedback.html',
    providers: [ConfirmationService]
})
export class Customerfeedback implements OnInit 
{
    fromdate      : Date;
    todate        : Date;
    loglist       : Fb[];

    isRunning = false; 
    statuses : SelectItem[];
    avgrating;
    avgscore;
                                

    totalamount = 0;

    selectedPayment;
    cols : any[];
    selectedposid; 

    arrselectedposids : SelectItem[];
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private datePipe : DatePipe,
        private dynamodbService : Dynamodbservice,
        public messageService : MessageService

        ) {
        
    }
   
        
    ngOnInit(){
      
        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getPreviousBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getPreviousBusinessDate());


        this.cols = [
            { field: 'businessdatestr', header: 'Business date'},
            { field: 'customername', header: 'Customer Name'},
            { field: 'customermobile', header: 'Customer Mobile'},
            { field: 'orderid', header: 'Order Id'},
            { field: 'rating', header: 'Rating'},
            { field: 'recoscore', header: 'Reco Score'},
            { field: 'addlfb', header: 'Detailed Feedback'},
            { field: 'whattoimprove', header: 'Improvement Category'},
            { field: 'foodimprovement', header: 'Food Improvement'},
            { field: 'expimprovement', header: 'Experience Improvement'},
            { field: 'deliveryimprovement', header: 'Delivery Improvement'},
            { field: 'staffimprovement', header: 'Staff Improvement'},
            { field: 'rootcause', header: 'Root Cause'},
            { field: 'correctiveaction', header: 'Corrective Action'}
            
        ];

        //this.selectedposid = this.dataService.outletlist[0].value;
        this.arrselectedposids = [];

    }

    isPosSelectd(posid){
        for(var pos of this.arrselectedposids){
            if(pos.value == posid)
                return true;
        }
    }
    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    selectedKOT; 

    selectedOrder : Fb;
    displayFeedbackActionDialog;
    rootcause;
    correctiveaction;

    showFeedbackDialog(){
        this.rootcause = '';
        this.correctiveaction = '';
        this.displayFeedbackActionDialog = true; 
    }

    async saveFeedback(){
        if(!this.rootcause || this.rootcause.trim().length == 0){
            this.showErrorToast('Please provide root cause.');
            return;
        }

        if(!this.correctiveaction || this.correctiveaction.trim().length == 0){
            this.showErrorToast('Please provide corrective action taken.');
            return;
        }


        this.selectedOrder.rootcause = this.rootcause;
        this.selectedOrder.correctiveaction = this.correctiveaction;
        
        var ret = await  this.dynamodbService.putItem('feedback', this.selectedOrder);
        if(!ret){
            this.showErrorToast('Error updating feedback, please try again!');
            this.selectedOrder.correctiveaction = '';
            this.selectedOrder.rootcause = '';
            return; 
        } else{
            this.displayFeedbackActionDialog = false;
            this.loglist = [... this.loglist];
        }
        
    }

    
    async setData(){

        this.totalamount =0;
        this.avgrating = 0;
        this.avgscore = 0;
        var totalrating = 0;
        var totalscore = 0;
        var fbcount = 0;


        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

       /* if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        
        }

        if(this.arrselectedposids.length == 0 ){
            this.showErrorToast("Please select at least one outlet!");
            return;
        }*/
        
        this.isRunning = true; 
        if(Constants.DEBUG_MODE) console.log ('Date From ' + new Date(fromdate) +  ' Date to  ' + new Date(todate));
        this.loglist = [];

        
            var loglistoutlet = await this.dynamodbService.getFeedbackList(fromdate,todate);
            if(loglistoutlet && loglistoutlet.length > 0){
                for(var log of loglistoutlet){
                    totalrating = totalrating + log.rating; 
                    totalscore = totalscore + log.recoscore; 
                    fbcount = fbcount + 1;            
                    log.businessdatestr = Utils.getBusinessDateAsString("" + log.businessdate);
                }
                this.loglist = this.loglist.concat(loglistoutlet);
            } 
            
        
        this.loglist = [... this.loglist];
        this.avgrating = Utils.round( totalrating/fbcount,2);
        this.avgscore = Utils.round( totalscore/fbcount,2);
        



        this.isRunning = false;

    }


    


}


