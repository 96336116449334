import {Injectable} from '@angular/core';

import {DataService} from '../service/dataservice'
import * as AWS from 'aws-sdk';
import {Constants} from '../domain/constants';
import { Printer } from '../domain/printer';
import { DatePipe } from '@angular/common';
import { Appdataservice } from './appdataservice';
import { SelectItem } from 'primeng/api';
import { User } from '../domain/user';

@Injectable()
export class Privilegeservice {
    
    constructor(private dataService : Appdataservice){}


public  getBillingPrivOptions() : SelectItem[]
{
        var options = [];
        options.push({label:'Sales', value:Constants.PRIV_SALES});
        options.push({label:'Edit Sales', value:Constants.PRIV_EDIT_SALES});
        options.push({label:'Delivery Management', value:Constants.PRIV_DELIVERIES});
        
        options.push({label:'Cancel Item', value:Constants.PRIV_CANCEL_ITEM});
        options.push({label:'Cancel KOT', value:Constants.PRIV_CANCEL_KOT});
        options.push({label:'Cancel Bill', value:Constants.PRIV_CANCEL_BILL});
        options.push({label:'Checkout', value:Constants.PRIV_CHECKOUT});
        options.push({label:'Duplicate Print', value:Constants.PRIV_DUPLICATE_PRINT});
        options.push({label:'Duplicate KOT', value:Constants.PRIV_DUPLICATE_KOT});
        options.push({label:'Apply Discount', value:Constants.PRIV_APPLY_DISCOUNT});
        options.push({label:'Apply Discount Post Bill', value:Constants.PRIV_DISCOUNT_POST_BILL});
        options.push({label:'Transfer Items', value:Constants.PRIV_TRANSFER_ITEMS});
        options.push({label:'Cashier Functions', value:Constants.PRIV_CASHIER_FUNCTIONS});
        options.push({label:'Change Served By', value:Constants.PRIV_CHANGE_SERVED_BY});
        options.push({label:'Create Users', value:Constants.PRIV_CREATE_USERS});
        options.push({label:'Sync offline data', value:Constants.PRIV_SYNC_OFFLINE_DATA});
        options.push({label:'Call center orders', value:Constants.PRIV_CALLCENTER});
        
        return options;
    }

    public  getReportingPrivilegesOptions() : SelectItem[]
    {
        var options = [];
        options.push({label:'Sales Reports', value:Constants.PRIV_SALES_REPORTS});
        options.push({label:'Business Summary', value:Constants.PRIV_BUSINESS_SUMMARY});
        options.push({label:'Monitoring Alerts', value:Constants.PRIV_MONITORING_ALERTS});
        options.push({label:'Collection Reports', value:Constants.PRIV_COLLECTION_REPORTS});
        options.push({label:'Short Summary', value:Constants.PRIV_SHORT_SUMMARY});
        options.push({label:'CSV Download', value:Constants.PRIV_CSV_DOWNLOAD});
        
        
        return options;
    }

    public validateBillingPrivilege(user : User, action : number ){
        if(user.arrbillingprivs){
            for(var priv of user.arrbillingprivs){
                if(priv == action) return true; 
            }
        }

        return false; 
    }


    public validateReportingPrivilege(user : User, action : number ){
        if(user.arrreportingprivs){
            for(var priv of user.arrreportingprivs){
                if(priv == action) return true; 
            }
        }

        return false; 
    }
    
    


}