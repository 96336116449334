import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from '../../paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Aggregatorservice } from 'src/app/service/aggregatorservice';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';


@Component({
    templateUrl: 'orderdetails.html',
    providers: [ConfirmationService]
})
export class Orderdetails implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    statusoptions : SelectItem[];
    selectedstatus; 
    currentviewtype;
    

    isRunning = false; 
    
    showpendingonly;

    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private counterService : Billingcounterservice,
        private aggregagtorService : Aggregatorservice,
        public userService : Userservice,
        private orderService  : Orderservice,
        private deliveryBoyservice : Deliveryboyservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    getTips(ord: Order){

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == Constants.PYMT_CARD){
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        try{
                            var tip = parseFloat(strtip);
                            return tip;
                        }catch(err){}
                    }
                }
        
            }
        }
        return 0;
    }

    
    ngOnInit(){
        //this.fromdate = new Date(new Date().setHours(0,0,0,0));
        //this.todate = new Date(new Date().setHours(0,0,0,0));

        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
    


        this.selectedRadio = 'itemdetails';
        this.showpendingonly = false; 
        this.statusoptions = [
            { label: 'Processed', value: Constants.POS_ORDER_PROCESSED },
            { label: 'Void', value: Constants.POS_ORDER_CANCELLED}
        ];
        
        this.selectedstatus = Constants.POS_ORDER_PROCESSED;
        
        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'receiptno', header: 'Invoice no' },
            { field: 'firstkotstr', header: 'First kot on' },
            { field: 'createdonstr', header: 'Proforma on' },
            { field: 'chkouttimestr', header: 'Checkout on' },
            { field: 'countertypestr', header: 'Counter type' },
            { field: 'aggrname', header: 'Aggregator Name' },
            { field: 'pymtreference', header: 'Txn reference' },
            
            /*{ field: 'countername', header: 'Counter name' },
            { field: 'numguests', header: 'Num Pax' },*/
            { field: 'customername', header: 'Customer name' },
            { field: 'customermobile', header: 'Customer mobile' },
            { field: 'orderitemsstr', header: 'Items' },
            { field: 'cart.sumitemcount', header: 'Item count' },
            { field: 'cart.sumitemamount', header: 'Item amount' },
            { field: 'cart.sumitemgross', header: 'Item gross' },
            { field: 'cart.totaldiscount', header: 'Discount' },
            { field: 'cart.sumitemtaxable', header: 'Item Taxable' },
            { field: 'cart.sumitemtax', header: 'Item Taxes' },
            { field: 'cart.sumitemnet', header: 'Item Total' },
            { field: 'cart.netcharges', header: 'Delivery Charges' },
            //{ field: 'cart.roundingamount', header: 'Rounding' },
            { field: 'cart.netpayable', header: 'Bill amount' },
            { field: 'cart.paidamount', header: 'Paid amount' },
            { field: 'cart.pendingamount', header: 'Pending amount' }
            
        ];

        this.cols.push({ field: 'pymtcash', header: 'Cash' });
        this.cols.push({ field: 'pymtcard', header: 'Card' });
        this.cols.push({ field: 'tips', header: 'Tips' });
        this.cols.push({ field: 'pymtonline', header: 'Online' });
        this.cols.push({ field: 'pymtcustwallet', header: 'Customer Wallet' });
        this.cols.push({ field: 'pymtaggregator', header: 'Aggregator' });
        this.cols.push({ field: 'pymtcomplimentary', header: 'Complimentary' });
        this.cols.push({ field: 'pymtcoupon', header: 'Coupon' });
        this.cols.push({ field: 'pymtzomatopro', header: 'Zomato Pro' });
        this.cols.push({ field: 'pymtothercredit', header: 'Other credit'});
        this.cols.push({ field: 'statusstr', header: 'Status' });
        this.cols.push({ field: 'kotnum', header: 'Kot Taken' });
        this.cols.push({ field: 'foodprep', header: 'Food Prepared' });
        
        this.cols.push({ field: 'cancelby', header: 'Void by' });
        this.cols.push({ field: 'voidbystr', header: 'Void by' });
        this.cols.push({ field: 'cancelreason', header: 'Void reason' });
        this.cols.push({ field: 'cancelremraks', header: 'Void remarks' });
        this.cols.push({ field: 'driverdetails', header: 'Driver details' });
        
        
        
        //this.cols.push({ field: 'checkedout', header: 'Checkedout' });
        //this.cols.push({ field: 'details', header: 'Details' });

        //this.cols.push({ field: 'kotnum', header: 'KOTs' });
        //this.cols.push({ field: 'kotcount', header: 'KOT count' });

        /*this.cols.push({ field: 'fooddisc', header: 'Food Disc' });
        this.cols.push({ field: 'bevdisc', header: 'Bev Disc' });
        this.cols.push({ field: 'tobdisc', header: 'Tob Disc' });
        this.cols.push({ field: 'misdisc', header: 'Misc Disc' });*/


        if(this.dataService.retailer.id == 1554){
            this.cols.push({ field: 'vatamt', header: 'VAT On Amount' });
            this.cols.push({ field: 'cart.sumvat', header: 'VAT' });
    
        }
        
        this.orderlist = [];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    setDeliveredBy(ord : Order){
        var dboy = this.deliveryBoyservice.getDeliveryboyStringForId(ord.deliveredby);
        ord.deliveredbystr = dboy;

        ord.voidbystr = this.userService.getUserNameForId(ord.voidby);

    }


    setDriverDetails(ord : Order){
        var str = "" + ord.deliveredby;

        if(str && str.length > 0){
            if(str.indexOf("(") > 0){
                ord.driverdetails = this.deliveryBoyservice.getDriverEmpDetailsForNameNumber(ord.deliveredby);
            }else{
                ord.driverdetails = this.deliveryBoyservice.getDriverEmpDetailsForId(ord.deliveredby);
            }
        }else{
            ord.driverdetails = "";
        }
        
    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 
    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
        //if(ord.orderstatus != Constants.POS_ORDER_CANCELLED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;
            this.totalreceivableamount = this.totalreceivableamount + ord.cart.pendingamount;
        //}
    }

    roundTotals(){
        
    }

    selectedRadio;



    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        if(ord.chkouttime && ord.chkouttime > 0){
            ord.chkouttimestr = this.datePipe.transform(ord.chkouttime, "dd/MM HH:mm");
        }else{
            ord.chkouttimestr = this.datePipe.transform(ord.updatedon, "dd/MM HH:mm");
        }
        
        if(ord.firstkoton && ord.firstkoton > 0){
            ord.firstkotstr = this.datePipe.transform(ord.firstkoton, "dd/MM HH:mm");
        }
    }

    findInArray(arrtokens,newtok){
        for(var item of arrtokens){
            if(item == newtok) return true; 
        }
        return false; 
    }
    
    setKOTTokens(ord : Order){

        var kottime = 0;

        var arrtoken = [];
        if( ord.cart && ord.cart.lineitems != null && ord.cart.lineitems.length > 0){
            for(var item of ord.cart.lineitems){
                console.log('KOTTIME ' + item.kottime);
                if(item.kottime < kottime || kottime == 0) kottime = item.kottime;

                if(!this.findInArray(arrtoken,item.kottoken)){
                    arrtoken.push(item.kottoken);
                }

            }

        }

        arrtoken = arrtoken.reverse();
        ord.kotnum = arrtoken.join(",");
        ord.kotcount = arrtoken.length;
        ord.firstkoton = kottime; 

        //ord.kotnum = arrtoken.join("|");
        //ord.kotcount = arrtoken.length;


    }
    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
            /*if(ord.countertype == Constants.ONLINE){
                var counter = this.counterService.getBillingcounterForId(ord.counterid);
                
                ord.aggrname = counter.aggregatorname;
            }*/
            ord.details = Utils.getOrderDetails(ord);
            ord.pymtreference = Utils.getReferenceNo(ord);
        }

        //Set mode wise payments on order 
        ord.pymtcash = Utils.getModeWisePayment(Constants.PYMT_CASH,ord);
        ord.pymtcard = Utils.getModeWisePayment(Constants.PYMT_CARD,ord);
        ord.tips = this.getTips(ord);
        ord.pymtonline = Utils.getModeWisePayment(Constants.PYMT_PG,ord);
        ord.pymtforex = Utils.getModeWisePayment(Constants.PYMT_FOREX,ord);
        ord.pymtcustwallet = Utils.getModeWisePayment(Constants.PYMT_WALLET,ord);
        ord.pymtaggregator = Utils.getModeWisePayment(Constants.PYMT_AGGREGATOR,ord);
        ord.pymtcomplimentary = Utils.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord);
        ord.pymtzomatopro = Utils.getModeWisePayment(Constants.PYMT_ZOMATO_PRO,ord);
        ord.pymtothercredit = Utils.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);
        ord.pymtcoupon = Utils.getModeWisePayment(Constants.PYMT_COUPON,ord);
        
        if (ord.receiptno && ord.receiptno.length > 0){
            if(ord.receiptno.indexOf("/") > 0){
                ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
            }else{
                ord.rcptnodisplay = ord.receiptno;
            }
        }
        

    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }


    bifurcateDiscounts ( ord : Order){
        ord.fooddisc = 0;
        ord.bevdisc = 0;
        ord.misdisc = 0;
        ord.tobdisc = 0;
         
        for(var item of ord.cart.lineitems){
            if(item.discount > 0){
                var prod = this.dataService.getProductForId(item.productid);
                if(prod){
                    var cat = this.dataService.getCategoryForId(prod.categoryid);
                    if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                        ord.bevdisc = Utils.round(ord.bevdisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                        ord.fooddisc = Utils.round(ord.fooddisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                        ord.tobdisc = Utils.round(ord.tobdisc + item.discount,3);
                    }else{
                        ord.misdisc = Utils.round(ord.misdisc + item.discount,3);
                    }
                }
            }
        } 
    }


    getInnerItemCount(ord : Order){
        var sumitemcount = 0;
        for(var item of ord.cart.lineitems){
            if(item.kitstatus != Constants.ITEM_CANCELLED && item.kitstatus != Constants.CANT_BE_PREPARED){
                sumitemcount =  Utils.round(sumitemcount + item.quantity ,3);
            }
        }
        return sumitemcount;
    }
    


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){
            if(item.orderstatus == this.selectedstatus){
                if(!this.showpendingonly || item.cart.pendingamount > 0){
                    var ord = this.orderService.getClone(item);
                    //console.log('|DET|Receipt no|' + ord.receiptno + '|DELIVERD BY|' + ord.deliveredby );
                    //console.log('|CHECK|INNER|' + ord.receiptno + '||' + this.getInnerItemCount(ord) + '|CART|' + ord.cart.sumitemcount);
                    if(this.getInnerItemCount(ord) != ord.cart.sumitemcount){
                        console.log('|CHECK|INNER|MISMATCH|' + ord.receiptno + '|' + ord.id + '|' + this.getInnerItemCount(ord) + '|CART|' + ord.cart.sumitemcount);
                    }

                    //Get status 
                    ord.statusstr = Utils.getStatusStr(ord);
                    ord.orderitemsstr = Utils.itemsAsString(ord);
                    ord.countertypestr = Constants.getCounterTypeName(ord.countertype);

                    ord.ordfrom = 'Outlet';
                    if(ord.ccordid && ord.ccordid > 0){
                        ord.ordfrom = 'Callcenter';
                    }
                    
                    if(ord.countertype == Constants.ONLINE){
                        ord.aggrname = this.aggregagtorService.getAggregatorNameForId(ord.aggrid);
                    }

                    this.setPaymentString(ord);
                    this.setDeliveredBy(ord);
                    this.setKOTTokens(ord);
                    this.setDriverDetails(ord);
                    this.setDateStrings(ord);
                    

                    this.bifurcateDiscounts(ord);
                    this.filteredlist.push(ord);
                    this.incrementTotals(ord);
                    
                    if(this.dataService.retailer.id == 1554){
                        ord.vatamt = Utils.round(ord.cart.sumitemtaxable + ord.cart.sumsc,3);
                    }
                }
            }

        }
        this.filteredlist = [... this.filteredlist];
            

    }

    async setData(dt : Table){
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


