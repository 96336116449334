import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {Appdataservice}  from '../service/appdataservice';
import {Constants}    from '../domain/constants';
import { DatePipe, NgIfContext } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Customer } from '../domain/Customer';
import { Payment } from '../domain/payment';

import { Dynamodbservice } from '../service/dynamodbservice';
import { Utils } from '../service/utils';
import { Settingsservice } from '../service/settingsservice';
import { Printingservice } from '../service/printingservice';


@Component({
    templateUrl: 'customerlist.html',
    providers: [ConfirmationService]
})
export class Customerlist implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    customerlist       :   Customer[];

    filteredlist : Customer[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private settingService : Settingsservice,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private printingService : Printingservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    payment : Payment; 


    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));

        this.cols = [
            { field: 'id', header: 'Mobile no' },
            { field: 'createdonstr', header: 'Created on' },
            { field: 'name', header: 'Name' },
            { field: 'emailaddress', header: 'Email Address' },
            { field: 'addressstr', header: 'Address (First)' },
            { field: 'numaddresses', header: 'Addresses Count' },
            { field: 'numorders', header: 'Order count' },
            { field: 'walletbal', header: 'Wallet Balance' },
            { field: 'numreferrals', header: 'Referrals Made' },
            { field: 'referralrewards', header: 'Referral Rewards' },
            { field: 'signupreward', header: 'Signup Reward' },
            { field: 'referredby', header: 'Referred By' },
            { field: 'numfeedback', header: 'Feedback Count' },
            { field: 'avgrating', header: 'Avg Rating' }
        ];


        this.customerlist = [];
        this.setData();

    }

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }



    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    setTotalsToZero(){
        this.totalwalletbalance =0; 
        this.totalrferrals = 0; 
        this.totalsignupreward = 0; 
        this.totalreferralreward =0 ; 
        this.totalfeedback =0 ;
        this.totalrating =0 ; 
        this.avgrating =0 ; 
        this.numcustwithfeedback = 0;

    }

    totalwalletbalance; 
    totalrferrals; 
    totalsignupreward; 
    totalreferralreward; 
    totalfeedback;
    totalrating; 
    avgrating; 
    numcustwithfeedback;


    roundTotals(){
        
    }

    selectedCustomer;

    setAddress(customer : Customer){
        if(customer.addresslist && customer.addresslist.length > 0){
             //customer.addressstr =  Utils.getAddressAstext(customer.addresslist[0]);
            customer.numaddresses = customer.addresslist.length;
        }else{
            customer.numaddresses = 0;
        }
    }

    setTopPurchases(customer : Customer){
        var str = '';
        if(customer.toppurchases && customer.toppurchases.length > 0){
            var i = 0;
            for(var pur of customer.toppurchases){
                str = str + this.dataService.getProductForId(pur.productid).name + ' (' + pur.quantity + '), ';
                i++; 
                if(i >= 5 ) break;
            }
            str = str.substring(0,str.length - 2);

        }
        customer.toppurchasestr = str; 
    }

    setCounters(customer : Customer){
        if(customer.fblist && customer.fblist.length > 0){
            customer.numfeedback = customer.fblist.length;
            var totalrating = 0; 
            for(var fb of customer.fblist){
                totalrating = totalrating  + fb.rt;
            }
            customer.avgrating = Utils.round(totalrating/customer.numfeedback,1);
            this.totalrating = this.totalrating + customer.avgrating;
            this.numcustwithfeedback = this.numcustwithfeedback  +1;
            this.avgrating = Utils.round(this.totalrating/this.numcustwithfeedback,1);
        }else{
            customer.numfeedback = 0;
            customer.avgrating = 0;
        }
        this.totalwalletbalance = this.totalwalletbalance  + customer.walletbal;
        if(!customer.numreferrals) customer.numreferrals = 0;
        if(!customer.signupreward) customer.signupreward = 0;
        if(!customer.referralrewards) customer.referralrewards = 0;
        if(!customer.numorders) customer.numorders = 0;

        
        this.totalrferrals  = this.totalrferrals + customer.numreferrals;
        this.totalsignupreward = this.totalsignupreward + customer.signupreward;
        this.totalreferralreward = this.totalreferralreward + customer.referralrewards;

    }

    
    async setData(){
        if(Constants.DEBUG_MODE) console.log('set data called');
        
        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.customerlist = await this.dynamoService.getCustomers();

        if(this.customerlist == null){
            this.showErrorToast("Error fetching Customers, please try again!")
        }else{
            this.customerlist.sort((a: Customer, b: Customer)=> a.id < b.id  ? -1 : 1 );

            for (var cust of this.customerlist){
                cust.createdonstr = this.datePipe.transform(cust.createdon, "dd/MM HH:mm");


                //this.setTopPurchases(cust);
                this.setAddress(cust);
                this.setCounters (cust);
            }
        }
      
        this.customerlist = [... this.customerlist];
        this.isRunning = false; 
    }


    
    
}


