import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Paymentdialog } from '../../paymentdialog';
import { Order } from '../../../domain/order';
import { Appdataservice } from '../../../service/appdataservice';
import { Orderservice } from '../../../service/orderservice';
import { Payment } from '../../../domain/payment';
import { Utils } from '../../../service/utils';
import { Constants } from '../../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';


@Component({
    templateUrl: 'salessummary.html',
    providers: [ConfirmationService]
})
export class Salessummary implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];
    countertypes : SelectItem[];

    filteredlist : Summaryrecord[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private counterService : Billingcounterservice,
        private orderService  : Orderservice,
        private userService : Userservice, 
        private dboyService : Deliveryboyservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    currentcountertype; 
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'summarized';
        
        this.viewtypes = [
            { label: 'Counter type wise', value: Constants.VW_COUNTERTYPE },
            { label: 'Counter wise', value: Constants.VW_COUNTER },
            { label: 'Weekday wise', value: Constants.VW_WEEKDAY },
            { label: 'Hour of day wise', value: Constants.VW_HOUR_OF_DAY },
            { label: 'Section wise', value: Constants.VW_SECTION },
            { label: 'Served-by wise', value: Constants.VW_SERVED_BY },
            { label: 'Billed-by wise', value: Constants.VW_OPERATOR },
            { label: 'Guest type wise', value: Constants.VW_GUEST_TYPE_WISE},
            { label: 'Delivery boy wise', value: Constants.VW_DELIVERY_BOY },
            { label: 'Aggregator wise', value: Constants.VW_AGGREGATOR },
            
            
        ];

        this.currentviewtype = Constants.VW_COUNTERTYPE;

        this.countertypes = [];
        this.countertypes.push({label:'All', value:0});

        this.countertypes.push({label:'Dine In', value:Constants.NORMAL});
        this.countertypes.push({label:'AC/Special', value:Constants.AC_SPECIAL});
        this.countertypes.push({label:'Takeaway', value:Constants.TAKEAWAY});
        this.countertypes.push({label:'Online', value:Constants.ONLINE});
        this.countertypes.push({label:'Delivery', value:Constants.PARCEL});
        this.countertypes.push({label:'Consumer App', value:Constants.SELFAPP});

        this.currentcountertype = 0
        this.orderlist = [];

        this.cols = [
            { field: 'summaryval1', header: 'Business date' },
            { field: 'description', header: 'Description' },
            { field: 'summaryval12', header: 'Order count' },
            { field: 'summaryval13', header: 'Guest count' },
            { field: 'summaryval2', header: 'Item count' },
            { field: 'summaryval3', header: 'Item amount' },
            { field: 'summaryval4', header: 'Item gross' },
            { field: 'summaryval5', header: 'Discount' },
            { field: 'summaryval6', header: 'Taxable amount' },
            { field: 'summaryval7', header: 'Tax' },
            { field: 'summaryval8', header: 'Item Total' },
            { field: 'summaryval9', header: 'Charges' },
            { field: 'summaryval10', header: 'Rounding' },
            { field: 'summaryval11', header: 'Net amount' }
        ];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt,false);
    }

    refreshReportCountertype(ctrtype, dt : Table){
        this.currentcountertype = ctrtype;
        this.setData(dt,false);
    }

    changeDatewiseOption(dt : Table){
        console.log('Setting data...');
        this.setData(dt,false);
    }
    
    
    first : number = 0; 
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalordercount = 0;
    totalguestcount = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totaltaxes = 0;
        this.totalordercount = 0;
        this.totalguestcount = 0;
    }


    incrementTotals(ord : Order){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalordercount = this.totalordercount + 1;
            if(ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL)
                this.totalguestcount = this.totalguestcount + ord.numguests;

            
            
        
    }

    roundTotals(){
        
    }

    selectedRadio;

    

    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        var ctr = this.counterService.getBillingcounterForId(ord.counterid);
        if(ctr){
            ord.sectionnamestr = ctr.sectionname;
            if(ctr.countertype == Constants.ONLINE){
                ord.aggrid = ctr.aggregatorid;
                ord.aggrname = ctr.aggregatorname;
            }
        }
        ord.servedbystr = this.userService.getUserNameForId(ord.servedby);
        ord.billedbystr = this.userService.getUserNameForId(ord.billedby);
        ord.weekdaystr = this.datePipe.transform (ord.createdon,"EEEE");
        
        
        ord.hourofdaystr  = Utils.getHourRangeFromHour(parseInt(this.datePipe.transform(ord.createdon,"HH")));
        ord.deliveredbystr = this.dboyService.getDeliveryboyNameForId(ord.deliveredby);

    }

    createNewSummaryRecord(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheck(ord);
        rec.description = this.getDescritpion(ord);

        rec.summaryval1 = ord.businessdatestr;
        rec.summaryval2 =  ord.cart.sumitemcount;
        rec.summaryval3 =  ord.cart.sumitemamount;
        rec.summaryval4 =  ord.cart.sumitemgross;
        rec.summaryval5 =  ord.cart.totaldiscount;
        rec.summaryval6 =  ord.cart.sumitemtaxable;
        rec.summaryval7 =  ord.cart.sumitemtax;
        rec.summaryval8 =  ord.cart.sumitemnet;
        rec.summaryval9 =  ord.cart.netcharges;
        rec.summaryval10 =  ord.cart.roundingamount;
        rec.summaryval11 =  ord.cart.netpayable;
        rec.summaryval12 = 1;
        
        if(ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL)
            rec.summaryval13 =  ord.numguests;
        else
            rec.summaryval13 =  0;
        
        
        return rec; 
    }

    addToExistingRecord(rec :Summaryrecord, ord : Order){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + ord.cart.sumitemcount,2);
        rec.summaryval3 =  Utils.round(rec.summaryval3 + ord.cart.sumitemamount,2);
        rec.summaryval4 =  Utils.round(rec.summaryval4 + ord.cart.sumitemgross,2);
        rec.summaryval5 =  Utils.round(rec.summaryval5 + ord.cart.totaldiscount,2);
        rec.summaryval6 =  Utils.round(rec.summaryval6 + ord.cart.sumitemtaxable,2);
        rec.summaryval7 =  Utils.round(rec.summaryval7 + ord.cart.sumitemtax,2);
        rec.summaryval8 =  Utils.round(rec.summaryval8 + ord.cart.sumitemnet,2);
        rec.summaryval9 =  Utils.round(rec.summaryval9 + ord.cart.netcharges,2);
        rec.summaryval10 =  Utils.round(rec.summaryval10 + ord.cart.roundingamount,2);
        rec.summaryval11 =  Utils.round(rec.summaryval11 + ord.cart.netpayable,2);
        rec.summaryval12 = rec.summaryval12 + 1;
        if(ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL)
            rec.summaryval13 =  rec.summaryval13 + ord.numguests;
        
        
    }

    getKeytoCheck(ord : Order){
        var key ="";
        if(this.selectedRadio == 'datewise')
            key = ord.businessdatestr;

        switch(this.currentviewtype){
            case Constants.VW_COUNTER: return key +  ord.countername;
            case Constants.VW_COUNTERTYPE: return key +  ord.countertypestr;
            case Constants.VW_DELIVERY_BOY: return key +  ord.deliveredbystr;
            case Constants.VW_SERVED_BY: return key + ord.servedbystr;
            case Constants.VW_OPERATOR: return key + ord.billedbystr;
            case Constants.VW_GUEST_TYPE_WISE: return key +  ord.guesttype;
            case Constants.VW_SECTION: return key + ord.sectionnamestr;
            case Constants.VW_WEEKDAY: return key + ord.weekdaystr;
            case Constants.VW_HOUR_OF_DAY: return key + ord.hourofdaystr;
            case Constants.VW_AGGREGATOR: return key + ord.aggrid;
            
            
        }
        
    }

    getDescritpion(ord : Order){
        switch(this.currentviewtype){
            case Constants.VW_COUNTER: return ord.countername;
            case Constants.VW_COUNTERTYPE: return ord.countertypestr;
            case Constants.VW_DELIVERY_BOY: return ord.deliveredbystr;
            case Constants.VW_SERVED_BY: return ord.servedbystr;
            case Constants.VW_OPERATOR: return ord.billedbystr;
            case Constants.VW_GUEST_TYPE_WISE: return ord.guesttype;
            case Constants.VW_SECTION: return ord.sectionnamestr;
            case Constants.VW_WEEKDAY: return ord.weekdaystr;
            case Constants.VW_HOUR_OF_DAY: return ord.hourofdaystr;
            case Constants.VW_AGGREGATOR: return ord.aggrname;
           
            
        }
        
    }

    
    setFilterList(){
        this.filteredlist = [];
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED && (item.countertype == this.currentcountertype || this.currentcountertype == 0)){

                if(this.currentviewtype == Constants.VW_AGGREGATOR && item.countertype != Constants.ONLINE)
                    continue;

                var ord = this.orderService.getClone(item);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);

                this.setDetails(ord);
                var key = this.getKeytoCheck(ord);
                var currec = Utils.findInSummaryList(this.filteredlist,key);
                if(currec){
                    this.addToExistingRecord(currec,ord);
                }else{
                    currec = this.createNewSummaryRecord(ord);
                    this.filteredlist.push(currec);    
                }

                this.incrementTotals(ord);

            }
                
                
        }

        if(this.selectedRadio == 'datewise' || this.currentviewtype == Constants.VW_HOUR_OF_DAY)
            this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.id)  < (b.id)  ? -1 : 1 );
        else
            this.filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval11)  > (b.summaryval11)  ? -1 : 1 );

        this.filteredlist = [... this.filteredlist];
            

    }

    
    async setData(dt : Table, refetch){
        if(refetch){
            var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
            var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

            if(todate < fromdate){
                this.showErrorToast("To date can not be less than from date!");
                return;
            }

            if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
                this.showErrorToast("The data can be searched for maximum of 31 days!");
                return;
            }

            this.isRunning = true; 
            this.setTotalsToZero();
        
            this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
            this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

            if(this.orderlist == null){
                this.showErrorToast("Error fetching orders, please try again!")
            }else{
                this.setFilterList();
            }
        }else{
            this.isRunning = true; 
            this.setTotalsToZero();
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


