import { Constants } from "../domain/constants";
import { Utils } from "../service/utils";
import { Cashdenomination } from "./cashdenomination";
import { Summaryrecord } from "./summaryrecord";

export class Shiftendrecord{
    id;
    shiftno;
    posid;
    businessdate;
    createdon;
    arrcashdenomination : Cashdenomination[];
    arrpymtmodewisesales : Summaryrecord[];
    arrcountertypewisesales : Summaryrecord[];
    
    forexinaed;
    coinsvalue;
    notesvalue;
    lowerdenominationvalue;
    netcash;
    totalcashinhand; 
    accountsreceivable; 
    orderpendingamount;
    bankdeposit; 
    floatcash; 
    pettycashexpense;
    salesincash; 
    discrepancy; 
    discrepancyreason;
    outgoingcashier; 
    remarks;
    excessshortfall;
    outgoingcashierid; 
    createdonstr;
    businessdatestr; 
    eoddate; //DATE put in if it is an EOD record
    eoddatestr;
    totaltips;

    isupdated;
    updateremarks; 
    
    public static getDenoWiseNoteValue(rec : Shiftendrecord, denovalue){
        if(!rec.arrcashdenomination) return 0;
        for(var deno of rec.arrcashdenomination)    {
            if(deno.denomination == denovalue){
                return deno.amount;
            }
        }
    }


    public static getPaymentModeWiseSale(rec : Shiftendrecord, pymtmode){
        if(!rec.arrpymtmodewisesales) return 0;

        for(var mode of rec.arrpymtmodewisesales) {
            if(mode.id == pymtmode){
                return mode.summaryval2;
            }
        }

        return 0;
    }

    public static getFocusInsert(posid,  rec : Shiftendrecord){
        var bsndate = Utils.getBusinessDateForSQL('' + rec.businessdate);
        
        var c1000 = Shiftendrecord.getDenoWiseNoteValue(rec, 1000);
        var c500 = Shiftendrecord.getDenoWiseNoteValue(rec, 500);
        var c200 = Shiftendrecord.getDenoWiseNoteValue(rec, 200);
        var c100 = Shiftendrecord.getDenoWiseNoteValue(rec, 100);
        var c50 = Shiftendrecord.getDenoWiseNoteValue(rec, 50);
        var c20 = Shiftendrecord.getDenoWiseNoteValue(rec, 20);
        var c10 = Shiftendrecord.getDenoWiseNoteValue(rec, 10);
        var c5 = Shiftendrecord.getDenoWiseNoteValue(rec, 5);
        var creditcard = Shiftendrecord.getPaymentModeWiseSale(rec,Constants.PYMT_CARD);
        //var creditcard = 0;
        var othercredit = Shiftendrecord.getPaymentModeWiseSale(rec,Constants.PYMT_OTHER_CREDIT) + Shiftendrecord.getPaymentModeWiseSale(rec,Constants.PYMT_AGGREGATOR) ;
        var complimentary = Shiftendrecord.getPaymentModeWiseSale(rec,Constants.PYMT_COMPLIMENTARY);     


        
        var setrinsert = "insert into ho_till(HO_Store,till_no,till_date,c_1000,c_500,c_200,c_100,c_50,c_20,c_10,c_5,c_coins," + 
                         "c_forex,c_ar,c_bills,c_float,c_cash_phy,c_cash_sys,c_cc,c_party,c_compliment,c_settle,Till_printdate," + 
                         "Till_Operator,MyDrawer_YN,AdvCash,AdvChq,AdvCC,PettyCash,LowerDenomination)values(" 
                         + posid + "," + rec.shiftno + ",'" + bsndate + "'," + c1000 + "," +  c500 + "," 
                         + c200 + "," + c100 + "," + c50 + "," + c20 + "," + c10 + "," + c5 + "," + rec.coinsvalue 
                         + "," + rec.forexinaed + "," + rec.accountsreceivable + ",0,"   + rec.floatcash + "," + rec.totalcashinhand 
                         + "," + rec.salesincash + "," + creditcard + "," + othercredit  +"," + complimentary + ",0,'" + rec.createdonstr + "','" + rec.outgoingcashier + "','Y',0,0,0,"+ rec.pettycashexpense + "," + rec.lowerdenominationvalue + ")" ;
                    
        return setrinsert; 

    }





}