import {Component, AfterViewInit, ElementRef, Renderer2, ViewChild, OnDestroy, OnInit, NgZone} from '@angular/core';
import {Router} from '@angular/router';	

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnDestroy, OnInit {

    layoutCompact = true;

    constructor( public _router : Router, public zone: NgZone){
    }

    ngOnInit() {
      console.log('Checking width.. ');
      if(window.innerWidth > 550){
        console.log('I am on PC.. Setting class ');
      }
    }
    ngOnDestroy() {
    }

}
