import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {DataService} from '../service/dataservice';
import {MessageService, ConfirmationService, SelectItem} from 'primeng/api';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Billingservice } from '../service/billingservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Appdataservice } from '../service/appdataservice';
import { Billingcounter } from '../domain/billingcounter';
import { Kot } from '../domain/kot';
import { Constants } from '../domain/constants';
import { Cartlineitem } from '../domain/cartlineitem';
import { Consumercart } from '../domain/consumercart';
import { Orderservice } from '../service/orderservice';
import { Printingservice } from '../service/printingservice';

@Component({
    selector: 'split-dlg',
    templateUrl: './splitdialog.html',

})
export class Splitdialog 
{

    constructor(
                public counterService : Billingcounterservice,
                public billingService : Billingservice,
                public kitchenService : Kitchenservice,
                public printingService : Printingservice,
                private confirmationService : ConfirmationService,
                private orderService : Orderservice,
                private messageService: MessageService,
                private dataService : Appdataservice) {}

    
    ctr : Billingcounter;
    splitTo : Billingcounter;
    splitcounterlist : Billingcounter[];

    selectedItems : Cartlineitem[];
    splittoctr : number;

    onShowDialog(){
        if(Constants.DEBUG_MODE) console.log('SElected counter ' + this.counterService.selectedcounter.countername);
        this.ctr = this.counterService.selectedcounter;
        this.selectedItems  = [];
        this.splitTo = this.clonectr(this.ctr);
        this.splitTo.cart = new Consumercart(); //Set a new cart
    }

    showConfirmation : boolean = false;
    showspinner : boolean = false; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false;
    }


    confirmSplit() {
        this.showConfirmation = true;
    }

    async processSplit(){
        this.showConfirmation = false; 
        
        this.showspinner = true;
        var origincheck = await this.counterService.checkAndGetLatest();
        if(origincheck == false){
            this.showErrorToast("You did not have latest copy of data, it is now refreshed!");
            return; 
        }

        var order = await this.orderService.checkoutCounter(this.splitTo,false);
        if(order){
            await this.counterService.splitItems(this.selectedItems);            
            this.showspinner = false;

            document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(order,false);
            setTimeout(()=>{ window.print();}, 200);
            
            
        }else{
            this.showErrorToast('Error while checkout, please try again!');
        }
        
        this.billingService.makeSplitDialogVisible = false; 
    }


    async splitItems(){
        
        if(this.selectedItems.length == 0){
            this.showErrorToast("Select atleast 1 Item for split checkout");
        }else{
            this.splitTo.cart.lineitems = [];
            for (var item of this.selectedItems){
                this.splitTo.cart.lineitems.push(item);
            }
            this.splitTo.cart.recomputeCart();
        
            this.confirmSplit();
        
        }
    }


    clonectr(fctr: Billingcounter): Billingcounter
    {
        var ctr = <Billingcounter>{};
        for(let prop in fctr) {
            ctr[prop] = fctr[prop];
        }
        return ctr; 
     }
    

    cancelSplit(){
        this.showConfirmation = false;
    }

    
  
    
}
