import {Billingcounter} from '../domain/billingcounter'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Section } from '../domain/section';
import { Consumercart } from '../domain/consumercart';
import { Cartlineitem } from '../domain/cartlineitem';
import { Product } from '../domain/product';
import { IoT1ClickDevicesService } from 'aws-sdk';
import { Payment } from '../domain/payment';
import { DatePipe } from '@angular/common';
import { Customer } from '../domain/customer';
import { Aggregatorservice } from './aggregatorservice';
import { Kot } from '../domain/kot';
import { SelectItem } from 'primeng/api';
import { Utils } from './utils';
import { Tableorder } from '../domain/tableorder';
import { Printerservice } from './printerservice';
import { Printingservice } from './printingservice';
import { isUndefined } from 'util';
import { Order } from '../domain/order';


@Injectable()
export class Billingcounterservice  {
     billingcounterlist : Billingcounter[];
     activelist : Billingcounter[];
     displaycounterlist : Billingcounter[];
     mobilecounters : Billingcounter[];

     arrassignedcounters;

     lstsections : Section[];
     selectedSection;

     private servertimeurl = 'https://hjc7g4pb48.execute-api.ap-south-1.amazonaws.com/prod/a-getservertime';  // URL to web API


    //SELECTED counter
    selectedcounter : Billingcounter;

    refreshTimer;

     constructor(
         private couchdbService : Couchdbservice,
         private dataService : Appdataservice,
         private printerService : Printerservice,
         private printingService : Printingservice,
         private dynamoDBService : Dynamodbservice,
         private aggregatorService : Aggregatorservice,
         private datePipe : DatePipe
         
     ){}

     public getNewId(){
         var maxid = 0;
         for(var ctr  of this.billingcounterlist){
             if(ctr.id > maxid)
                maxid = ctr.id;
         }

         return maxid + 1; 
     }

     refreshInList(ctr : Billingcounter){
        
        for(var i=0;i<this.billingcounterlist.length; i++){
            if(this.billingcounterlist[i].id === ctr.id){
                this.billingcounterlist[i] = ctr;
            }
        }

        //Rebuild the active list and display list
        this.setActiveList();

        if(this.selectedcounter && ctr.id == this.selectedcounter.id){
            this.selectedcounter = ctr;
            this.selectedcounter.cart.lineitems = [... this.selectedcounter.cart.lineitems];
        }

     }
 
     
     isServerTimeSet = false; 

     async getServerTime(){

        if(!this.isServerTimeSet){
            var resp = await this.dataService.syncHttp(this.servertimeurl,'{}');
            if(resp){
                var offset = resp.body.servertime - new Date().getTime();
                console.log('*** SERVER TIME ' +  this.datePipe.transform( resp.body.servertime, "HH:mm:ss") + ' -- ' + this.datePipe.transform( new Date().getTime(), "HH:mm:ss") + ' OFFSET ' + offset);
                this.couchdbService.timeoffset = offset; 
                this.dynamoDBService.timeoffset = offset;
                this.isServerTimeSet = true; 
            }
        }
        

     }

     addEditInList(ctr : Billingcounter){
        console.log('ADD|EDIT ***  ' + ctr.countername);
        var found = false; 
        for(var i=0;i<this.billingcounterlist.length; i++){
            if(this.billingcounterlist[i].id === ctr.id){
                this.billingcounterlist[i] = ctr;
                found = true; 
                break;
            }
        }

        if(!found){
            this.billingcounterlist.push(ctr);
        }
       
    }


     public checkDuplicate(counter : Billingcounter){
        for(var ctr  of this.billingcounterlist){
            if(ctr.id != counter.id && ctr.countername == counter.countername)
               return true;

            if(ctr.id != counter.id && ctr.shortname == counter.shortname)
               return true;
        }



        return false; 
    }


    getBillingcounterNameForId(id){
        for(var ctr of this.billingcounterlist){
            if(ctr.id === id) return ctr.countername; 
        }
        return "";
    }

    getBillingcounterForId(id){
        for(var ctr of this.billingcounterlist){
            if(ctr.id === id) return ctr; 
        }
        return null;
    }
    
    getBillingcounterIdForName(countername){
        for(var ctr of this.billingcounterlist){
            if(ctr.countername === countername) return ctr.id; 
        }
        return 0;
    }

       
    getBillingcounter(countername){
        for(var ctr of this.billingcounterlist){
            if(ctr.countername === countername) return ctr; 
        }
        return null;
    }

    setDetails(ctr : Billingcounter){
        ctr.typename = Constants.getCounterTypeName(ctr.countertype);
        if(ctr.countertype == Constants.ONLINE)
            ctr.aggregatorname = this.aggregatorService.getAggregatorNameForId(ctr.aggregatorid);
        else    
            ctr.aggregatorname = '';

        if(ctr.whitetext ) ctr.textcolor = 'white';
        else ctr.textcolor = 'black';

        //Set served by STR 
        

        if(!ctr.cart){
            ctr.cart = new Consumercart();
            ctr.cart.initCart(ctr.taxoverride);
        }else{
            ctr.cart = this.cloneCart(ctr.cart);
        }
        if(!ctr.paymentlist) ctr.paymentlist = [];
        if(!ctr.proformaid) ctr.proformaid =0;
        if(!ctr.ccorderid) ctr.ccorderid =0;
        
        if(!ctr.cart.deliverycharges) ctr.cart.deliverycharges =0;
        
    }
    
    clearCart(ctr : Billingcounter){
        ctr.cart = new Consumercart();
        ctr.cart.initCart(ctr.taxoverride);
    }
    
    public  cloneCart(cart : Consumercart){
        var newcart = new Consumercart();
        newcart.initCart(cart.taxbehavior);

        for (var item of cart.lineitems){
            var newitem = new Cartlineitem();
            for(let prop in item) {
                newitem[prop] = item[prop];
            }
            newcart.lineitems.push(newitem);
        }
        for(var pymt of cart.paymentlist){
            newcart.paymentlist.push(pymt);    
        }

        newcart.discountdetail = cart.discountdetail;
        newcart.deliverycharges = cart.deliverycharges;
        
        
        newcart.recomputeCart();

        return newcart;
     }

     lastupdatedon = 0; 
      
     async initBillingcounters(){
        this.billingcounterlist = [];
        if(Constants.DEBUG_MODE) console.log('|BCTR|Fetching from couch');

        var retlist;
        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.getAllItemsForPosId('billingcounters');
            if(retlist) this.billingcounterlist = <Billingcounter[]> retlist;
        }else{
            retlist = await this.couchdbService.getAllItems('billingcounters');
            if(retlist) this.billingcounterlist = <Billingcounter[]> retlist.docs;
        }

        //var retlist = await this.couchdbService.getAllItems('billingcounters');
        if(retlist){
            //if(Constants.DEBUG_MODE) console.log('|COUCH|Num ctrs-->' + JSON.stringify(retlist));
            //this.billingcounterlist = <Billingcounter[]> retlist.docs;
            for(var ctr of this.billingcounterlist){
                this.setDetails(ctr);
                if(ctr.updatedon > this.lastupdatedon) this.lastupdatedon = ctr.updatedon;
            }

            this.setActiveList();
            this.updateBillingTimes();
            this.getServerTime();
            this.setReadyCount();
            return true;
        }else{
            return false;
        }


        
     }
     
     async save(billingcounter){
        //if(Constants.DEBUG_MODE) console.log('|BCTRTEST|DBOYSEVICE|Saving DBOY ' + JSON.stringify(billingcounter));
        //var status= await this.couchdbService.putItem('billingcounters',billingcounter);
        var status;
        if(this.dataService.usedynamo){
            status = await this.dynamoDBService.putItem('billingcounters',billingcounter);    
            if(status == 'DONE'){

                var newlocktime = new Date().getTime() + this.dynamoDBService.timeoffset;
                var ctrlock = {posid: this.dataService.getPosId() , id: billingcounter.id , lockedon: newlocktime};

                console.log('CTRLOCK: ' + JSON.stringify(ctrlock));

                await this.dynamoDBService.putItem('counterlock',ctrlock);    

            }
        }else{
            status = await this.couchdbService.putItem('billingcounters',billingcounter);
        }

        if(status != "ERROR" && status != "CONFLICT"){
            this.setDetails(billingcounter);
            this.addEditInList(billingcounter);
            this.setActiveList();
            this.billingcounterlist = [... this.billingcounterlist];
            return status;
        }else{
            return status;
        }
        
     }

     async lockCounter(){

        var newlocktime = new Date().getTime() + this.dynamoDBService.timeoffset;
        var curlockedon = this.selectedcounter.lockedon;
        if(!curlockedon) curlockedon = 0;

        var lockexpiry = newlocktime - (10*1000); // 1   

        var key  = {"posid": "" + this.dataService.getPosId(), "id" : this.selectedcounter.id};


        console.log('|LOCK|CHECKING LOCK ITEM--- ' + JSON.stringify(attribparams));
        var curcopy =  await this.dynamoDBService.getItem('counterlock',key); 

        if(!curcopy){
            console.log('|LOCK|CRETAING FIRST LOCK --- ' + JSON.stringify(attribparams));
            var ctrlock = {posid: this.dataService.getPosId() , id: this.selectedcounter.id , lockedon: newlocktime};
            var ret = await this.dynamoDBService.putItem('counterlock',ctrlock);    

            console.log('|LOCK|CRETAING FIRST LOCK STATUS --- ' + ret);
            if(ret == 'DONE'){
                console.log('|LOCK|first lock created! ');
                this.selectedcounter.lockedon = newlocktime;
                return true;
            }else
                return false; 
        }else{


            var conditionexpression = "lockedon = :curlockedon or lockedon <  :lockexpiry" ;  
            var attribparams= {":curlockedon" : curlockedon, ":newlocktime" : newlocktime , ":lockexpiry" : lockexpiry};
            var updateexpression = "set lockedon = :newlocktime";

            console.log('|LOCK|Acquiring Lock --- ' + JSON.stringify(attribparams));
            var status = await this.dynamoDBService.updateItem('counterlock',updateexpression,conditionexpression,attribparams,key);
            if(status == 'DONE'){
                console.log('|LOCK|Lock acquired');
                this.selectedcounter.lockedon = newlocktime;
                return true; 
            }else{
                console.log('|LOCK|Could not get a lock');
                return false;
            }
        }

     }


     //This function, starts a billing session for a counter, 
     async startCounterSession(counter : Billingcounter){

        var key  = {"posid": "" + this.dataService.getPosId(), "id" : counter.id};
        var sessionid  = this.dynamoDBService.getSessionId();
        var nosession = 0;
        var conditionexpression = "sessionid = :nosession or attribute_not_exists(sessionid)" ;  
        var updateexpression = "set sessionid = :v_sessionid , updatedon = :v_sessionid ";
        var attribparams= {":v_sessionid" : sessionid, ":nosession" : nosession};
 
        console.log('|SESSION| --- ' + JSON.stringify(attribparams));
        var status = await this.dynamoDBService.updateItem('billingcounters',updateexpression,conditionexpression,attribparams,key);
        if(status == 'DONE'){
            console.log('|SESSION|Session set');
            counter.sessionid = sessionid;
            counter.updatedon = sessionid;
            return true; 
        }else{
            console.log('|SESSION|Could not START sssion');
            return false;
        }

     }


     //This function, starts a billing session for a counter, 
     async lockForCustomerEntry(counter : Billingcounter){
        var key  = {"posid": "" + this.dataService.getPosId(), "id" : counter.id};
        
        var custentry  = this.dynamoDBService.getSessionId();
        var noentry = 0;
        var lockexpiry  = this.dynamoDBService.getSessionId() - (300*1000); //5 mins lock max 

        var conditionexpression = "custentry = :noentry or attribute_not_exists(custentry) or custentry < :lockexpiry" ;  
        var updateexpression = "set custentry = :v_custentry";
        var attribparams= {":v_custentry" : custentry, ":noentry" : noentry, ":lockexpiry" : lockexpiry};
 
        console.log('|CUSTLOCK| --- ' + JSON.stringify(attribparams));
        var status = await this.dynamoDBService.updateItem('billingcounters',updateexpression,conditionexpression,attribparams,key);
        if(status == 'DONE'){
            console.log('|CUSTLOCK|Cust entry locked');
            counter.custentry = 1;
            return true; 
        }else{  
            console.log('|CUSTLOCK|Cust entry locked');
            return false;
        }

     }


     //This function, starts a billing session for a counter, 
     async unlockCustomerEntry(counter : Billingcounter){
        var key  = {"posid": "" + this.dataService.getPosId(), "id" : counter.id};
        
        var custentry  = 1;
        var noentry = 0;
        var sessionid  = this.dynamoDBService.getSessionId();

        var conditionexpression = "attribute_exists(custentry)" ;  
        var updateexpression = "set custentry = :noentry";
        var attribparams= {":noentry" : noentry};
 
        console.log('|CUSTLOCK| --- ' + JSON.stringify(attribparams));
        var status = await this.dynamoDBService.updateItem('billingcounters',updateexpression,conditionexpression,attribparams,key);
        if(status == 'DONE'){
            console.log('|CUSTLOCK|Cust entry UNlocked');
            counter.custentry = 0;
            return true; 
        }else{
            console.log('|CUSTLOCK|ERROR in unlock -->' + status);
            return false;
        }

     }

     
     async checkAndGetLatest(){
        var latestcopy : Billingcounter; 
        var key  = {"posid": "" + this.dataService.getPosId(), "id" : this.selectedcounter.id};
        console.log('|BCTRTEST|Checking LATEST');
        if(this.dataService.usedynamo){
            latestcopy = <Billingcounter> await this.dynamoDBService.getItem('billingcounters',key);        
            //console.log('|BCTRTEST|Latest updatedon ' + latestcopy.updatedon + ' Current updated ' + this.selectedcounter.updatedon);
            if(latestcopy && latestcopy.updatedon <= this.selectedcounter.updatedon){
                return await this.lockCounter(); 
            }
        }else{
            latestcopy = <Billingcounter> await this.couchdbService.getItem('billingcounters', this.selectedcounter.id);
            if(latestcopy && latestcopy._rev == this.selectedcounter._rev ){
                return true; 
            }
        }

        if(latestcopy && latestcopy.updatedon > this.selectedcounter.updatedon){
            this.setDetails(latestcopy);
            this.refreshInList(latestcopy);
        }
        return false; 
    

     }

     async checkLatestCounter(counter : Billingcounter){
        var latestcopy : Billingcounter; 
        var key  = {"posid": "" + this.dataService.getPosId(), "id" : counter.id};
        console.log('|BCTRTEST|Checking LATEST');
        if(this.dataService.usedynamo){
            latestcopy = <Billingcounter> await this.dynamoDBService.getItem('billingcounters',key);        
            if(latestcopy && latestcopy.updatedon == counter.updatedon){
                return true; 
            }
        }else{
            latestcopy = <Billingcounter> await this.couchdbService.getItem('billingcounters', counter.id);
            if(latestcopy && latestcopy._rev == counter._rev ){
                return true; 
            }
        }

        if(latestcopy && latestcopy.updatedon > counter.updatedon){
            this.setDetails(latestcopy);
            this.refreshInList(latestcopy);
        }
        return false; 
            
     }

     async checkDoubleTransfer(itemid){
        var key  = {"posid": "" + this.dataService.getPosId(), "itemid" : itemid};
        var curcopy =  await this.dynamoDBService.getItem('itemtransfers',key); 

        if(curcopy){
            return true;
        }else{
            return false; 
        }

     }

     async transferItems(tocounter : Billingcounter, lineitems : Cartlineitem[]){
    
        //First enter in the transferitem table
        for(var item of lineitems){
            var itemid = item.kottoken + "|" + item.createdon;
            var fromsessionid = this.selectedcounter.id + "|" + this.selectedcounter.sessionid;
            var tosessionid = tocounter.id + "|" + tocounter.sessionid;
            var kotid = item.kottime;
            var transferitem = {posid : this.dataService.getPosId(), kotid : kotid, itemid : itemid, businessdate  : this.dataService.getCurrentBusinessDate(), fromsessionid : fromsessionid, tosessionid : tosessionid }
            var ret; 
            if(this.dataService.usedynamo){
                ret = await this.dynamoDBService.putItem('itemtransfers', transferitem);            
            }else{
                ret = await this.couchdbService.putItem('itemtransfers', transferitem);            
            }
            if(ret != 'DONE') return false; 

        }


         for(var item of lineitems){
             tocounter.cart.lineitems.push(item);
             this.removeItemFromCart(item);
         }


         tocounter.cart.recomputeCart();
         await this.saveCounter(tocounter);
         await this.saveSelectedCounter();
         return true; 
     }

     async splitItems(lineitems : Cartlineitem[]){
        for(var item of lineitems){
            this.removeItemFromCart(item);
        }
        await this.saveSelectedCounter();
        return true; 
    }




     async saveSelectedCounter(){
        console.log('|SAVECOUNTER|SAVE SELECTED  COUNTER **** ');
        var status; 
        if(this.dataService.usedynamo){
            status= await this.dynamoDBService.putItem('billingcounters',this.selectedcounter);
        }else
            status= await this.couchdbService.putItem('billingcounters',this.selectedcounter);

        console.log('|SAVECOUNTER|SELECTED COUNTER status **** ' + status);
        if(this.dataService.retailer.appconfig.posconfig.offlineenabled){
            this.saveSelectedCounterToOffline();
        }
        return status;
        
     }


     async saveSelectedCounterToOffline(){

        console.log('|SAVECOUNTER|OFFLINE|Getting counter ' );

        var couchitem = await this.couchdbService.getItem('billingcounters', this.selectedcounter.id);
        if(couchitem){
            console.log('|SAVECOUNTER|OFFLINE|Counter obtained' );
            this.selectedcounter._id = couchitem._id; 
            this.selectedcounter._rev = couchitem._rev;
        }

        var status= await this.couchdbService.putItem('billingcounters',this.selectedcounter);
        console.log('|SAVECOUNTER|OFFLINE|SELECTED COUNTER saved **** ' + status);
        return status;
     }


     async saveCounter(ctr : Billingcounter){
        console.log('|SAVECOUNTER|WITH CTR');
        if(this.dataService.usedynamo){
            status= await this.dynamoDBService.putItem('billingcounters',ctr);
        }else
            status= await this.couchdbService.putItem('billingcounters',ctr);

        //var status= await this.couchdbService.putItem('billingcounters',ctr);
        if(this.dataService.retailer.appconfig.posconfig.offlineenabled){
            this.saveCtrToOffline(ctr);
        }
        console.log('|SAVECOUNTER|WITH CTR|status **** ' + status);
        return status; 
        
     }

     async saveCtrToOffline(ctr : Billingcounter){
        console.log('|SAVECOUNTER|OFFLINE|WITH CTR Getting counter ' );

        var couchitem = await this.couchdbService.getItem('billingcounters', ctr.id);
        if(couchitem){
            console.log('|SAVECOUNTER|OFFLINE|Counter obtained' );
            ctr._id = couchitem._id; 
            ctr._rev = couchitem._rev;
        }

        var status= await this.couchdbService.putItem('billingcounters',ctr);
        console.log('|SAVECOUNTER|OFFLINE|WITH CTR status **** ' + status);
        return status;
     }

     getRunningcounterCount(section){
        if(!this.activelist || this.activelist.length == 0) return 0;
        var count = 0;
        for(var ctr of this.activelist){
            if(ctr.sectionname === section)
                if(ctr.cart && ctr.cart.sumitemcount > 0) {count = count+1};
        }
        return count;

    }

   
     
     buildSectionList(){
        console.log('*** REBUILDING SECTION LIST');
        this.lstsections = [];
        for(var ctr of this.activelist){

            var sectionfound = false;
            for(var sect of this.lstsections){
                if(sect.name.toLowerCase() === ctr.sectionname.trim().toLowerCase()){
                    sectionfound = true;
                }
            }
        
            if(!sectionfound){
                var section = <Section>{};
                section.name = ctr.sectionname;
                section.label = ctr.sectionname;
                section.backgroundcolor = 'cyan-btn';

                var numrunningcounters  = this.getRunningcounterCount(section.name);
                if(numrunningcounters > 0) section.label = section.name + ' (' + numrunningcounters + ')';

                if(section.name === this.selectedSection) section.backgroundcolor = 'amber-btn';

                this.lstsections.push(section);
            }
        }

        console.log('*** NUMSECTIONS ' + this.lstsections.length);
        this.lstsections = [... this.lstsections];


    }

    updateBillingTimes(){
        try{
            this.setBillingSince();
            if(this.dataService.retailer.appconfig.posconfig.liverefresh) 
                this.refreshBillingcounters();

            //Set the srver time 
            this.getServerTime();
            this.dataService.curbsndate = Utils.getBusinessDateAsString("" + this.dataService.getCurrentBusinessDate());

            //if(this.dataService.isuserloggedin)
            this.refreshTimer =    setTimeout(()=>{this.updateBillingTimes()},10000);


        }catch(err){}
    }

    clearTimer(){
        console.log('|BCTRTEST|LIVE REFRSH|Clearingtimeout')
        clearTimeout(this.refreshTimer);
    }
    

    setBillingSince(){
        if(this.activelist && this.activelist.length > 0){
               for(var ctr of this.activelist){
                   var billingsince = "";
                   var timeelapsed = 0; 
                   if( ctr && ctr.occupiedon > 0){
                       timeelapsed =  new Date().getTime() - ctr.occupiedon;
                       timeelapsed = Math.round( timeelapsed / (60*1000));
                       billingsince = " - " + timeelapsed + " Mins";    
                   }

                   ctr.billingsince = billingsince; 

                   if(ctr.cart.sumitemcount == 0 && this.dataService.retailer.appconfig.posconfig.autocleartime){
                       if(timeelapsed >  this.dataService.retailer.appconfig.posconfig.autocleartime){
                            ctr.numguests = 0;
                            ctr.servedby = 0;
                            ctr.guesttype = '';
                            ctr.occupiedon = 0;
                            ctr.billingsince = '';
                            console.log('|BCTRTEST|AUTO CLEAR COUNTER **** ');
                            this.saveCounter(ctr);
                        }
                   }
                   
                   
               }
       }
    }

    setActiveList(){
        this.activelist = [];
        
        for(var ctr of this.billingcounterlist){
            if(ctr.active){
                this.activelist.push(ctr);
            } 
        }

        this.activelist.sort((a:Billingcounter, b: Billingcounter)=> (a.countertype + a.countername)  < (b.countertype + b.countername)  ? -1 : 1 );
        this.activelist = [... this.activelist];
        
        if(this.mobilecounters && this.mobilecounters.length > 0) this.mobilecounters = [... this.mobilecounters];
        this.setDisplayCounterlist();
        this.setBillingSince();
    }


    checkMobileCounter(counterid){
        if(this.arrassignedcounters && this.arrassignedcounters.length > 0){
            for(var ctr of this.arrassignedcounters){
                if(ctr == counterid) return true; 
            }
        }

        return false; 
    }


    setDisplayCounterlist(){
        if(Constants.DEBUG_MODE) console.log('*** setting display list!');
        this.displaycounterlist = [];
        this.mobilecounters = [];

        if(!this.activelist || this.activelist.length == 0) return;

        if(!this.selectedSection){
            var lastsection = localStorage.getItem('selectedsection');

            if(lastsection && lastsection.length > 0){
                this.selectedSection = lastsection;
            }else
                this.selectedSection = this.activelist[0].sectionname;

        }

        var cursection = this.selectedSection;
        for(var ctr of this.activelist){
            if(ctr.sectionname === cursection || cursection === 'ALL' ){
                //ctr.displayname = this.getName(ctr);
                this.displaycounterlist.push(ctr);
            }
            if(this.checkMobileCounter(ctr.id)){
                this.mobilecounters.push(ctr);
            }

        }

        
        
        this.displaycounterlist.sort((a:Billingcounter, b: Billingcounter)=> (a.countertype + a.countername)  < (b.countertype + b.countername)  ? -1 : 1 );
        this.displaycounterlist = [... this.displaycounterlist];
        
        this.mobilecounters.sort((a:Billingcounter, b: Billingcounter)=> (a.countertype + a.countername)  < (b.countertype + b.countername)  ? -1 : 1 );
        this.mobilecounters = [... this.mobilecounters];
        
        
        if(Constants.DEBUG_MODE) console.log('*** Building Section List!');
        this.buildSectionList();

    }

 
    //Cart functions 
    clearSchemes(){
        this.clearItemLevelShemes();
        this.clearBillLevelScheme();
        this.selectedcounter.cart.recomputeCart();
    }
    clearItemLevelShemes(){
        for(var item of this.selectedcounter.cart.lineitems){
            var prod = this.dataService.getProductForId(item.productid);
            if(item.schcode && item.schcode.length > 0){
                item.discount = 0;
                item.computeItemTaxes(prod);
            }
        }
    }

    //Clear schemes from cart 
    public clearBillLevelScheme(){
        this.selectedcounter.cart.clearBillLevelScheme();
    }


    getDineInScheme(prod : Product){
        //console.log('|SCHEME|Counter Type ' + this.selectedcounter.countertype + ' ID ' +  this.dataService.retailer.id);
        if(this.selectedcounter.countertype == Constants.NORMAL || this.selectedcounter.countertype == Constants.AC_SPECIAL || (this.selectedcounter.countertype == Constants.TAKEAWAY && (this.dataService.retailer.id == 1554 || this.dataService.retailer.id == 1511))){
            for(var sch of this.dataService.schemelist){
                if(Utils.checkItemSchemeApplicability(prod,sch) && Utils.checkSchemeValidity(sch,this.dataService.getCurrentBusinessDate())){
                    return sch;
                }
            }
        }
        return null;
    }

    sendToDVRAdd(prod : Product, quantity, customization, customizationrate, instructions){
        if(this.printerService.dvr){

            var rate =  Utils.getApplicableRate(prod,this.selectedcounter.countertype, this.dataService.retailer.locationid);
            var strrate = Utils.format(rate + customizationrate , 2);

            var itemstring = this.selectedcounter.countername + ', Event: Add to cart <BR>' + quantity + ' x ' + prod.name + ' @ ' + strrate  + '<BR>';
            
            if(customization && customization.length > 0){
                itemstring = itemstring  + ' Customization: ' + customization + '<BR>';
            }
            if(instructions && instructions.length > 0){
                itemstring = itemstring  + ' Instructions: ' + instructions + ' <BR>';
            }
            
            itemstring = itemstring + "User: " + this.dataService.loggedinusername + "<BR>";
            if(this.selectedcounter.servedbystr && this.selectedcounter.servedbystr.length > 0 )
            itemstring = itemstring + "Served by: " + this.dataService.loggedinusername + "<BR>";
            
            itemstring = itemstring + "<BR><BR>";
            this.printingService.synchronousprint(itemstring,Constants.LAN,this.printerService.dvr.deviceaddress);
        }
        
    }


    sendToDVRRemove(prod : Product, quantity){
        if(this.printerService.dvr){
            
            var itemstring = this.selectedcounter.countername + ', Event: Remove from cart <BR>' + quantity + ' x ' + prod.name + ' <BR>';
            
            
            itemstring = itemstring + "User: " + this.dataService.loggedinusername + "<BR>";
            if(this.selectedcounter.servedbystr && this.selectedcounter.servedbystr.length > 0 )
            itemstring = itemstring + "Served by: " + this.dataService.loggedinusername + "<BR>";
            
            itemstring = itemstring + "<BR><BR>";
            this.printingService.synchronousprint(itemstring,Constants.LAN,this.printerService.dvr.deviceaddress);
        }
        
    }

    sendSettlementToDVR(order : Order){
            if(this.printerService.dvr){
                var strret = this.printingService.getSettlementAsString(order);
                this.printingService.synchronousprint(strret,Constants.LAN,this.printerService.dvr.deviceaddress);
            }
            
    
    }

    sendEventToDvr(eventdescription){
        if(this.printerService.dvr){
            var itemstring = this.selectedcounter.countername + ', Event: ' + eventdescription + '<BR>';
            itemstring = itemstring + "User: " + this.dataService.loggedinusername + "<BR>";
            if(this.selectedcounter.servedbystr && this.selectedcounter.servedbystr.length > 0 )
            itemstring = itemstring + "Served by: " + this.dataService.loggedinusername + "<BR>";
            
            itemstring = itemstring + "<BR><BR>";
            this.printingService.synchronousprint(itemstring,Constants.LAN,this.printerService.dvr.deviceaddress);
        }
        
    }


    public addToCart(productid : number, quantity, customization,customizationrate, instructions,custinv){
        //this.clearSchemes(); //First clear if there are any existing scehems 
        var prod = this.dataService.getProductForId(productid);
        var sch = this.getDineInScheme(prod);
        
        var scharge = 0;
        var tfee=0; 
        var mfee =0 ;

        if(this.dataService.retailer.appconfig.posconfig.scharge) scharge = this.dataService.retailer.appconfig.posconfig.scharge;
        if(this.dataService.retailer.appconfig.posconfig.tfee) tfee = this.dataService.retailer.appconfig.posconfig.tfee;
        if(this.dataService.retailer.appconfig.posconfig.mfee) mfee = this.dataService.retailer.appconfig.posconfig.mfee;

        
        console.log('CHARGES|scharge' + scharge);
        console.log('CHARGES|tfee' + tfee);
        console.log('CHARGES|mfee' + mfee);

        //For ash
        if(this.dataService.retailer.id == 1554){
            this.selectedcounter.cart.ashrounding = true; 
        }else{
            this.selectedcounter.cart.ashrounding = false;     
        }
        
        this.selectedcounter.cart.addToCart(prod, this.selectedcounter.countertype, quantity, customization,customizationrate,instructions,custinv,sch,0,scharge,tfee,mfee, this.dataService.retailer.locationid);
        this.sendToDVRAdd( prod,quantity,customization,customizationrate,instructions);
    }

    //Aded for QR ID
    public addProductToCart(prod : Product, quantity, customization,customizationrate,instructions,qrid, custinv){
        //this.clearSchemes(); //First clear if there are any existing scehems 
        var sch = this.getDineInScheme(prod);

        var scharge = 0;
        var tfee=0; 
        var mfee =0 ;
        if(this.dataService.retailer.appconfig.posconfig.scharge) scharge = this.dataService.retailer.appconfig.posconfig.scharge;
        if(this.dataService.retailer.appconfig.posconfig.tfee) tfee = this.dataService.retailer.appconfig.posconfig.tfee;
        if(this.dataService.retailer.appconfig.posconfig.mfee) mfee = this.dataService.retailer.appconfig.posconfig.mfee;

        
        console.log('CHARGES|scharge' + scharge);
        console.log('CHARGES|tfee' + tfee);
        console.log('CHARGES|mfee' + mfee);
        //For ash
        if(this.dataService.retailer.id == 1554){
            this.selectedcounter.cart.ashrounding = true; 
        }else{
            this.selectedcounter.cart.ashrounding = false;     
        }
        
        this.selectedcounter.cart.addToCart(prod,this.selectedcounter.countertype, quantity, customization,customizationrate,instructions, custinv,sch,qrid,scharge,tfee,mfee, this.dataService.retailer.locationid);
        this.sendToDVRAdd(prod,quantity,customization,customizationrate,instructions);
    }


    public setQuantity(item : Cartlineitem, newquantity){
        //this.clearSchemes(); //First clear if there are any existing scehems 
        var prod = this.dataService.getProductForId(item.productid);
        this.selectedcounter.cart.setQuantity(prod,item,newquantity);
        this.sendEventToDvr('Set quantity of ' + item.productname + ' to ' + newquantity);
    }

    public removeItemFromCart(item : Cartlineitem){
        //this.clearSchemes(); //First clear if there are any existing scehems 
        //Not needed to clear schemes ! 
        this.sendEventToDvr('Remove line item ' + item.productname + ' with quantity ' + item.quantity);
        this.selectedcounter.cart.removeItemFromCart(item);
        
    }


    public removeFromCart(productid, quantity, customization,instructions){
        this.clearSchemes(); //First clear if there are any existing scehems 
        var prod = this.dataService.getProductForId(productid);
        this.selectedcounter.cart.removeFromCart(prod,quantity, customization,instructions);
        this.sendToDVRRemove(prod,quantity);
    }

    public incremenQuantity(item : Cartlineitem){
        this.clearSchemes(); //First clear if there are any existing scehems 
        var prod = this.dataService.getProductForId(item.productid);
        this.selectedcounter.cart.incremenQuantity(prod,item);
        this.sendEventToDvr('Increment quantity of ' + item.productname);
    }

    public decrementQuantity( item : Cartlineitem){
        this.clearSchemes(); //First clear if there are any existing scehems 
        var prod = this.dataService.getProductForId(item.productid);
        this.selectedcounter.cart.decrementQuantity(prod,item);
        this.sendEventToDvr('Decrement quantity of ' + item.productname);
    }
 
    public applyItemDiscount (item : Cartlineitem,discountval : number){
        var prod = this.dataService.getProductForId(item.productid);
        this.selectedcounter.cart.applyItemDiscount(prod,item,discountval);
    }
 
    
 
    clearCounter(ctr : Billingcounter){
        ctr.customer = null; //remove the customer
        ctr.deliveryby = 0;
        ctr.deliveryon = null;
        ctr.deladdrline1 = '';
        ctr.deladdrline2 = '';
        
        ctr.remarks = '';
        ctr.alias = '';
        this.clearCart(ctr);
        ctr.kottokens  = '';
        ctr.numguests = 0;
        ctr.occupiedon = 0;
        ctr.guesttype = '';
        ctr.operatorid = 0;
        ctr.proformaid = 0; 
        ctr.sessionid = 0;
        ctr.ccorderid = 0;
        ctr.callsessionid = '';
        ctr.customer = <Customer>{};
        
        ctr.billingsince = '';
        ctr.cart.paymentlist = [];
        ctr.paymentstring = '';
        this.sendEventToDvr('Clear Counter');

    }

    playAudio(){
        try{
        let audio = new Audio();
        audio.src = "assets/demo/images/readyalert.mp3";
        audio.load();
        audio.play();
        }catch(err){
            console.log('|SOUND|Error playing sound ' + err);
        }
    }


    readycount =0;
    setReadyCount(){
        var readycount = 0; 

        for(var ctr of this.mobilecounters){
            for(var item of ctr.cart.lineitems){
                if(item.kitstatus == Constants.READY_AT_KITCHEN){
                     readycount = readycount + item.quantity;
                }
            }
        }

        console.log('|KDS|Readycount ' + this.readycount);
        this.readycount = readycount;
        
        //Play audio  on the captain tab
        if(this.readycount > 0 && window.innerWidth < 900){
            this.playAudio();
        }
    }

    async refreshBillingcounters(){
        try{

            //this.billingcounterlist = [];
            

            if(Constants.DEBUG_MODE) console.log('|BCTRTEST|LIVE REFRSH|Fetching from DB from --> ' + this.lastupdatedon + ' | logged in | ' + this.dataService.isuserloggedin + ' Run on ' + this.datePipe.transform(new Date(),"HH:mm:ss"));
            //var retlist = await this.couchdbService.getFromIndex('billingcounters',selector);

            var retlist;
            var newlist;  
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and updatedon > :v_updatedon ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_updatedon":  this.lastupdatedon};
                if(Constants.DEBUG_MODE) console.log('|BCTRTEST|Query -->' + conditionexpression + '--' + JSON.stringify(attribparams));

                newlist = <Billingcounter[]> await this.dynamoDBService.queryOnIndex('billingcounters', "posid-updatedon-index", conditionexpression,attribparams);
                
            }else{
                var selector =  {"updatedon": { "$gt": this.lastupdatedon}};
                retlist = await this.couchdbService.getFromIndex('billingcounters',selector);
                if(retlist && retlist.docs)
                    newlist = <Billingcounter[]> retlist.docs;
            }
            
            if(newlist){
                //if(Constants.DEBUG_MODE) console.log('|BCTRTEST|Num ctrs-->' + retlist.docs.length);
                
                for(var ctr of newlist){
                    var curctr = this.getBillingcounterForId(ctr.id);
                    
                    //This prevents update by refetching my own counter!
                    if(ctr.updatedon > curctr.updatedon){
                        this.setDetails(ctr);
                        this.refreshInList(ctr);
                    }
                    if(ctr.updatedon > this.lastupdatedon) this.lastupdatedon = ctr.updatedon;
                }

                
                //return true;
            }else{
                //return false;
            }

            this.setReadyCount();



        }catch(err){

        }

     }




 
     
     setPaymentString(ctr : Billingcounter){
        var paymentstring = "";
        if( ctr.cart && ctr.cart.paymentlist != null && ctr.cart.paymentlist.length > 0){
            for(var pymt of ctr.cart.paymentlist){
                paymentstring = paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            paymentstring = paymentstring.substring(0,paymentstring.length - 2);
        }else{
            if(ctr.defaultpaymentmode != 0){
                paymentstring = Constants.getPymtModeName(ctr.defaultpaymentmode);
            }
        }

        ctr.paymentstring = paymentstring; 
        
     }
    

    setPaidAndRemainingAmount(ctr : Billingcounter){
        ctr.cart.setPaidAndRemainingAmount();
        this.setPaymentString(ctr);
    }

    async addPaymentOnCounter(ctr : Billingcounter, pymt : Payment){
        if(!pymt.id ) pymt.id = new Date().getTime();
        if(!ctr.cart.paymentlist) ctr.cart.paymentlist = [];
        ctr.cart.paymentlist.push(pymt);

        ctr.cart.paymentlist = [... ctr.cart.paymentlist];

        this.setPaidAndRemainingAmount(ctr);
        await this.saveCounter(ctr);

        this.sendEventToDvr('Add payment. <BR> Amount: ' + Utils.format(pymt.paidamount,2) + ' Payment mode: ' + pymt.paymentmodename);

    }

    async setCustomerDeliveryInfo(ctr : Billingcounter, customer : Customer, deliveryby , deliverycharges){
        ctr.customer = customer; 
        ctr.deliveryby = deliveryby; 
        ctr.deladdrline1 = customer.addressline1;
        ctr.deladdrline2 = customer.addressline2;
        ctr.custentry = 0;
        

        ctr.cart.setDeliveryCharges (parseInt(deliverycharges));
        this.sendEventToDvr('Set customer on counter. <BR> Customer name: ' + customer.name);
        
        this.setPaidAndRemainingAmount(ctr);
        return await this.saveCounter(ctr);
    }


    getCloneCounter (counterid, cart : Consumercart){
        for(var ctr of this.billingcounterlist){
            if(ctr.id === counterid){
                var temp = new Billingcounter();
                for(let prop in ctr) {
                    temp[prop] = ctr[prop];
                }
                temp.cart = cart; 
                this.setDetails(temp);
                return temp;
            }
        }
        return null;
    }

    /************** KDS FUNCTIONS */
    async getCounterForKOT(counterid){
        var latestcopy = null;
        if(this.dataService.usedynamo){
            var key  = {"posid": "" + this.dataService.getPosId(), "id" : counterid};
            latestcopy = <Billingcounter> await this.dynamoDBService.getItem('billingcounters', key);
        }else{
            latestcopy = <Billingcounter> await this.couchdbService.getItem('billingcounters', counterid);
        }
        if(latestcopy){
            this.setDetails(latestcopy);
            return latestcopy; 
        }else
            return null;

    }

    async updateAsServedOnQR(qrid){
        var qrorder = null;
        if(this.dataService.usedynamo){
            var key  = {"posid": "" + this.dataService.getPosId(), "id" : qrid};
            qrorder = <Tableorder> await this.dynamoDBService.getItem('tableorders', key);
        }else{
           qrorder = <Tableorder> await this.couchdbService.getItem('tableorders', qrid);
        }
        if(qrorder){
            if(qrorder.status != Constants.QR_ORD_SERVED){
                qrorder.status = Constants.QR_ORD_SERVED;
                if(this.dataService.usedynamo){
                    await this.dynamoDBService.putItem('tableorders',qrorder);
                }else{
                    await this.couchdbService.putItem('tableorders',qrorder);
                }
            }
        }

    }


    async updateKOT(item : Cartlineitem){
        var kot = null;
        if(this.dataService.usedynamo){
            var key  = {"posid": "" + this.dataService.getPosId(), "id" : item.kottime};
            kot = <Kot> await this.dynamoDBService.getItem('tableorders', key);
        }
        if(kot){
            
        }

    }

    async markItemsAsServed(ctr : Billingcounter){
        var counter = await this.getCounterForKOT(ctr.id);

        if(counter){
            for(var item of ctr.arrselecteditems){
                for(var curitem of counter.cart.lineitems){
                    if(curitem.createdon == item.createdon && curitem.kitstatus == Constants.READY_AT_KITCHEN){
                        curitem.kitstatus = Constants.SERVED;
                    }
                }

                //Let this run in the background
                this.updateAsServedOnQR(item.qrid);

            }

            counter.arrselecteditems  =[];
            var ret= await this.saveCounter(counter);
            if(ret == 'DONE'){
                
                this.refreshInList(counter);
                this.setReadyCount();
                return true; 
            }
        }

        return false; 
    }

    //Set the update for kot 
    async setKitchenUpdate(kot : Kot){
        //Make 3 tries 
        for(var i =0; i<3;i++){
            var ctr = await this.getCounterForKOT(kot.counterid);
            if(ctr){
                for(var item of kot.lineitems){
                    if(item.kitstatus == Constants.READY_AT_KITCHEN){
                        for(var cartitem of ctr.cart.lineitems){
                            if(cartitem.createdon == item.createdon && cartitem.kitstatus != Constants.SERVED){
                                cartitem.kitstatus = Constants.READY_AT_KITCHEN;
                            }
                        }
                    }
                }

                await this.saveCounter(ctr);
                this.refreshInList(ctr);
            }

        }   
        
    }

    public  getCaptainCounterOptions() : SelectItem[]
    {
        var options = [];
        for(var ctr of this.activelist){
            if(ctr.countertype == Constants.NORMAL || ctr.countertype == Constants.AC_SPECIAL){
                options.push({label:ctr.countername, value:ctr.id});
            }
        }
        return options;
    }


    public  getCounterCopyOptions(ctr : Billingcounter) : SelectItem[]
    {
        var options = [];
        for(var counter of this.activelist){
            if(counter.countertype == ctr.countertype && counter.id != ctr.id){
                options.push({label:counter.countername, value:counter.id});
            }
        }
        return options;
    }

    
    public isFastCheckOut(){
        if(this.dataService.retailer && (this.dataService.retailer.id == 1343 || this.dataService.retailer.id == 1511) && this.selectedcounter && this.selectedcounter.countertype == Constants.TAKEAWAY){
            return true; 
        }

        return false; 
    }

    public  copyCart(cart : Consumercart){
        for (var item of cart.lineitems){
            this.addToCart(item.productid,item.quantity,item.customization,item.customizationrate,item.instructions,item.custinv);
        }
    }


    public getFreeCounter(order : Order){

        if(order.aggrid == 99) order.countertype = Constants.SELFAPP;
        else if(order.aggrid > 0) order.countertype = Constants.ONLINE;
        else{
          if(order.ordertype == 'takeaway')
            order.countertype = Constants.TAKEAWAY;
          else
            order.countertype = Constants.PARCEL;
        }   
        
        for(var counter of this.activelist){
            if(order.countertype == counter.countertype){
                if(order.countertype == Constants.ONLINE){
                    if(order.aggrid == counter.aggregatorid){
                        return counter;    
                    }
                }else{
                    return counter;       
                    
                }
                
            }
        }

        return null;
    }


}