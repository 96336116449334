import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {DataService}  from '../../service/dataservice';
import {Constants}    from '../../domain/constants';
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Utils } from 'src/app/service/utils';
import { Order } from 'src/app/domain/order';
import { Orderservice } from 'src/app/service/orderservice';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kot } from 'src/app/domain/kot';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Summaryrecord } from 'src/app/domain/summaryrecord';

@Component({
    templateUrl: 'dashboard.html',
    providers: [ConfirmationService]
})
export class Dashboard implements OnInit 
{
    fromdate          : Date;
    todate            : Date;

    todayssalesamount =0; 
    samedaylastweeksale = 0;
    todayssalecomparison;
    todaysordercount = 0;
    todayspaidamount =0;
    todaysunpaidamount = 0;
    todaysunpaidreceiptcount = 0;

    thisweeksale=0;
    lastweeksales=0;
    weeklycomparison;
    
    thismonthssalesamount=0;
    thismonthbillcount=0;
    cashboxcurbal=0;

    todayscancellationcount;
    todayscancellationamount;
    
    thisweekcancellationcount;
    thisweekcancellationamount;
    thismonthcancellationamount;

    todaysdiscount;
    thisweekdiscount;
    thismonthdiscount;
    
    last30dayssale       : Summaryrecord[];



    
    chartdatasaletrend : any;
    optionssaletrend : any;

    chartdatacatsale : any; 
    chartdatasubcatsale : any; 


    viewtypes : SelectItem[];
    currentviewtype;
    orderlist : Order[];

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private messageService : MessageService,
        private orderService : Orderservice,
        private kitchenService : Kitchenservice,
        private datePipe : DatePipe) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.setData();
    }

    getPieChartData(summarylist : Summaryrecord[]){
        var labelsarray : string[] = [];
        var series1 = [];
        var series2 = [];

        for (var rec of summarylist){
            labelsarray.push(rec.description);
            series1.push(rec.summaryval1);
            series2.push(rec.summaryval2);
        }

        var chartdata = {
            labels: labelsarray,
            datasets: [
                {
                    label: 'Sale amount',
                    data: series2,
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56",
                        "#ffe082",
                        "#ffc107",
                        "#00838f",
                        "#3be9ff",
                        "#ff7e55",
                        "#fc978f",
                        "#c4d2f5",
                        "#26B8E9",
                        "#3e8e41"
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56",

                    ]
                }
            ]
        }

        
        return chartdata;


    }


    buildChartDataDatewise(summarylist : Summaryrecord[]){
        var labelsarray : string[] = [];
        var series1 = [];
        var series2 = [];

        for (var rec of summarylist){
            labelsarray.push(rec.description);
            series1.push(rec.summaryval1);
            series2.push(rec.summaryval2);
        }

        this.chartdatasaletrend = {
            labels: labelsarray,
            datasets: [
                {
                    label: 'Sale amount',
                    data: series2,
                    borderColor: '#4bc0c0'
                }
            ]
        }

        this.optionssaletrend = {
            title: {
                display: true,
                text: 'Last 30 days sales',
                fontSize: 16
            },
            legend: {
                position: 'bottom'
            }
        };


    }

    curbusinessdate; 
    curbsndateAsDate : Date;
    curbusinessweek; 
    curdayofweek;
    curbsnmonth;

    getCategoryWiseSales(orderlist : Order[]){
        var summarylist : Summaryrecord[];
        summarylist  = [];

        for(var order of orderlist){
            var addordercount =true;
            if(order.orderstatus === Constants.POS_ORDER_PROCESSED){
                for (var lineitem of order.cart.lineitems){
                        var currecord = Utils.findInSummaryList(summarylist, lineitem.catid);
                        if(currecord){
                            currecord.summaryval1 = Utils.round(currecord.summaryval1 + order.cart.sumitemcount,2);
                            currecord.summaryval2 = Utils.round(currecord.summaryval2 + order.cart.netpayable,2);
                        }else{
                            var newrecord = <Summaryrecord>{};
                            newrecord.id = lineitem.catid;
                            var cat = this.dataService.getCategoryForId(lineitem.catid);
                            if(cat) newrecord.description = cat.name;
                            newrecord.summaryval1 = order.cart.sumitemcount;
                            newrecord.summaryval2 = order.cart.netpayable;
                            summarylist.push(newrecord);
                        }
                }  
            }

        }

        summarylist.sort((a:Summaryrecord, b: Summaryrecord)=> a.summaryval2 > b.summaryval2  ? -1 : 1 );
        return summarylist;

    } 



    getBusinessDateWiseSalesForGraph(orderlist : Order[]){
        var summarylist : Summaryrecord[];
        summarylist  = [];

        for(var order of orderlist){

            if(order.orderstatus === Constants.POS_ORDER_PROCESSED){
            
                var currecord = Utils.findInSummaryList(summarylist, order.businessdate);
                if(currecord){
                    currecord.summaryval1 = Utils.round(currecord.summaryval1 + order.cart.sumitemcount,2);
                    currecord.summaryval2 = Utils.round(currecord.summaryval2 + order.cart.netpayable,2);
                    
                }else{
                    var newrecord = <Summaryrecord>{};
                    newrecord.id = order.businessdate;
                    newrecord.description = Utils.formatBusinessDateGraph("" + order.businessdate);
                    newrecord.summaryval1 = order.cart.sumitemcount;
                    newrecord.summaryval2 = order.cart.netpayable;
                    newrecord.summaryval3 = order.businessdate;
                    summarylist.push(newrecord);
                }

            }
        }

        summarylist.sort((a:Summaryrecord, b: Summaryrecord)=> a.summaryval3 < b.summaryval3  ? -1 : 1 );
        return summarylist;

    }


    

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }



    
    isToday(order : Order){
        if(order.businessdate == this.curbusinessdate){
            return true;
        }
        return false;
        
    }



    isThisWeek(order : Order){
        var bsndate = Utils.getDateFromBusinessdate(''+ order.businessdate);
        var bsnweek=  this.datePipe.transform(bsndate,"w");
        if(bsnweek === this.curbusinessweek) return true; 

        return false; 
    }


    isLastWeek(order : Order){
        var bsndate = Utils.getDateFromBusinessdate(''+ order.businessdate);
        var bsnweek=  this.datePipe.transform(bsndate,"w");
        if(parseInt(bsnweek) === (this.curbusinessweek-1)) return true; 

        return false; 
    }




    isSamedayLastWeek(order : Order){
        var bsndate = Utils.getDateFromBusinessdate(''+ order.businessdate);
        var bsnweek=  parseInt( this.datePipe.transform(bsndate,"w"));
        var dayofweek = this.datePipe.transform(bsndate,"EE");
        
        if(bsnweek === (this.curbusinessweek-1) && dayofweek === this.curdayofweek) return true; 

        return false; 
    }


    isThismonth(order){
        var bsndate = Utils.getDateFromBusinessdate('' + order.businessdate);
        var bsnmonth=  this.datePipe.transform(bsndate,"MM");
        if(bsnmonth === this.curbsnmonth) return true; 

        return false;
    }

    setTotalsToZero(){
        this.todayscancellationamount = 0;
        this.todayscancellationcount= 0;
        this.todayssalesamount = 0;
        this.todaysordercount =0;
        this.todaysdiscount = 0;
        
        this.todayspaidamount =0;
        this.todaysunpaidamount = 0;
        this.todaysunpaidreceiptcount = 0;

        this.thisweeksale=0;
        this.lastweeksales = 0;
        this.todayssalecomparison ='';
        this.weeklycomparison ='';
        this.thismonthdiscount = 0;
        this.thismonthssalesamount = 0;
        this.thisweekcancellationamount =0;
        this.thisweekdiscount = 0;
        this.thismonthcancellationamount= 0;
        this.thismonthbillcount =0;
        this.samedaylastweeksale = 0;
        
    }

    computeSummary(){
        this.setTotalsToZero();
        for(var order of this.orderlist){
            if(order.orderstatus == Constants.POS_ORDER_PROCESSED){
                   if(this.isToday(order)){
                        this.todayssalesamount = this.todayssalesamount + order.cart.netpayable;
                        this.todaysdiscount = this.todaysdiscount + order.cart.totaldiscount;
                        this.todaysordercount = this.todaysordercount + 1;

                        this.todayspaidamount = this.todayspaidamount + order.cart.paidamount;
                        if(order.cart.pendingamount > 0){
                            this.todaysunpaidamount = this.todaysunpaidamount + order.cart.pendingamount;
                            this.todaysunpaidreceiptcount = this.todaysunpaidreceiptcount + 1;
                        }
                   }
                   
                   if(this.isThisWeek(order)){
                        this.thisweeksale = this.thisweeksale + order.cart.netpayable;
                        this.thisweekdiscount = this.thisweekdiscount + order.cart.totaldiscount;
                        
                   }

                   if(this.isLastWeek(order)){
                        this.lastweeksales = this.lastweeksales + order.cart.netpayable;
                    }

                   if(this.isSamedayLastWeek(order)){
                       this.samedaylastweeksale = this.samedaylastweeksale + order.cart.netpayable;
                   }

                   if(this.isThismonth(order)){
                        this.thismonthssalesamount = this.thismonthssalesamount + order.cart.netpayable;
                        this.thismonthdiscount = this.thismonthdiscount + order.cart.totaldiscount;
                        this.thismonthbillcount = this.thismonthbillcount + 1;
                   }

            }else{

                if(this.isToday(order)){
                    this.todayscancellationcount = this.todayscancellationcount +1;
                    this.todayscancellationamount = this.todayscancellationamount + order.cart.netpayable;
                }
         
            }


        }//End of FOR 

        if(this.samedaylastweeksale > 0){
            var salerpercent = Math.round(this.todayssalesamount/this.samedaylastweeksale * 100);
            this.todayssalecomparison ="[" + this.todaysordercount +  " bills. " + salerpercent  + "% of last " + this.curdayofweek  + "]";

        }else{
            this.todayssalecomparison ="[" + this.todaysordercount +  " bills ]";
        }

        
        if(this.lastweeksales > 0){
            var salerpercent = Math.round(this.thisweeksale/this.lastweeksales * 100);
            this.weeklycomparison ="[" + salerpercent  + "% of last week]";
        }else
           this.todayssalecomparison ="[No sales last week]";

        //Build last 30 days sale 
        this.last30dayssale = this.getBusinessDateWiseSalesForGraph(this.orderlist);        
        this.buildChartDataDatewise(this.last30dayssale);
   
        this.chartdatacatsale = this.getPieChartData(this.getCategoryWiseSales(this.orderlist));
        //this.chartdatasubcatsale = this.getPieChartData(this.reportservice.getSubcategoryWiseSales(this.orderlist));
        
    }

    
    async setData(){

        if(Constants.DEBUG_MODE) console.log('set data called');
        var curtime =  new Date(new Date().setHours(0,0,0,0)).getTime();
        var fromdatetime = curtime - (31*24*60*60*1000); //31 days from now 
        this.fromdate = new Date(fromdatetime);
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        

        
        this.curbusinessdate = this.dataService.getCurrentBusinessDate();
        this.curbsndateAsDate = Utils.getDateFromBusinessdate('' + this.curbusinessdate);
        this.curbusinessweek=  this.datePipe.transform(this.curbsndateAsDate,"w");
        this.curdayofweek = this.datePipe.transform(this.curbsndateAsDate,"EE");
        this.curbsnmonth =  this.datePipe.transform(this.curbsndateAsDate,"MM");

        this.last30dayssale = [];
        this.orderlist = [];

        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        this.isRunning = true; 

        this.orderlist =  await this.orderService.getOrderlist(fromdate,todate);
        this.computeSummary();
        this.isRunning = false; 
    }





}


