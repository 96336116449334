import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Appdataservice} from '../service/appdataservice';
import {Billingservice} from '../service/billingservice';
import {MessageService, SelectItem} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';
import { Constants } from '../domain/constants';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Customerservice } from '../service/customerservice';
import { Customer } from '../domain/customer';
import { Customeraddress } from '../domain/customeraddress';

@Component({
    selector: 'customer-dlg',
    templateUrl: './customerdialog.html',

})
export class Customerdialog {
    
    customer : Customer; 
    addressline1;
    addressline2;

    @ViewChild('custmobile') txtCustomerMobile : AutoComplete;
    @ViewChild('genericsearchbox') txtGenericSearch : AutoComplete;

    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                public dataService : Appdataservice,
                
                public counterService : Billingcounterservice,
                public customerService : Customerservice) {}


                
    txtcustomer; 
    txtgeneric; 
    resultsgeneric;
    resultscustomer; 

    mobilenumber;
    iscustomerset = false;
    
    
    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.billingService.makecustomerdialogvisible){
            if(event.keyCode == 13  ) {
                  if(this.iscustomerset ) this.closeDialog();
                  //else this.setCustomer();
            }
        }
    }

    @ViewChild('custname') txtName : ElementRef;

    setFocusOnName(){
        this.txtName.nativeElement.focus();
    }
    
    setAndClose(){
        //if(this.setCustomer()) this.closeDialog();
    }

    clear(){
        this.counterService.selectedcounter.customer = <Customer>{};
        this.closeDialog();
     }
    

    /*categoryselection : SelectItem[];
    programselection : SelectItem[];
    loyaltyprogegorylist : Posloyaltyprogram[];
    custcategories : Poscustcategory[];


    setCategorySelection() {
        this.categoryselection = [];
        
        for(let i = 0; i < this.dataService.custcategories.length; i++) {
            let cat = this.dataService.custcategories[i];
            this.categoryselection.push({label:cat.catname, value:cat.id});
        }

    }

    setProgramSelection() {
        this.programselection = [];
        
        for(let i = 0; i < this.dataService.loyaltyprograms.length; i++) {
            let prog = this.dataService.loyaltyprograms[i];
            this.programselection.push({label:prog.programname, value:prog.id});
        }
        
    }*/
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    async save(){
       
        
        if(!this.customer.name || this.customer.name.trim().length == 0 ){
            this.messageService.clear();
            this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:'Please provide customer name!'});
            return false;
        }


        
        var status = await this.customerService.saveCustomer(this.customer);
        if(status){
            if(this.addressline1 && this.addressline1.length > 0){
                var addr = <Customeraddress>{};
                addr.addressline1 = this.addressline1;
                addr.addressline2 = this.addressline2;
                if(!this.customer.addresslist) this.customer.addresslist = [];
                this.customer.addresslist.push(addr);
            }
            this.counterService.selectedcounter.customer = this.customer;
        }

        this.iscustomerset = true; 
        return true;
        //this.customerService.saveCustomerFromBill()
    }

    closeDialog(){
        this.billingService.makecustomerdialogvisible = false;
    }

    isnewcustomer= false;
    
    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');

                
        if(this.counterService.selectedcounter.customer && this.counterService.selectedcounter.customer.mobileno){
            this.customer = this.customerService.getClone(this.counterService.selectedcounter.customer);
            if(this.customer.addresslist && this.customer.addresslist.length > 0){
                var addr = this.customer.addresslist[0];
                this.addressline1 = addr.addressline1;
                this.addressline2 = addr.addressline2;
            }
            this.isnewcustomer = false;

        }else{
            this.customer = <Customer>{};
            this.customer.mobileno =  this.billingService.mobilefornewcust;
            this.addressline1 = '';
            this.addressline2 = '';
            this.isnewcustomer = true;
            
        }

        setTimeout(()=>{ this.setFocusOnName();}, 100);
        
        /*this.customer = Customer.getClone(this.dataService.selectedcounter.customer);
        this.txtgeneric= '';
        if(this.customer.mobilenumber) {
            this.mobilenumber = this.customer.mobilenumber;
            this.iscustomerset = true;
        }else{
            this.mobilenumber = '';
            this.iscustomerset = false;
            if(this.dataService.retailer.crmloyalty){
                if(this.dataService.loyaltyprograms && this.dataService.loyaltyprograms.length > 0){
                    this.customer.loyaltyprogram = this.dataService.loyaltyprograms[0].id;
                }

                if(this.dataService.custcategories && this.dataService.custcategories.length > 0){
                    this.customer.custcategory = this.dataService.custcategories[0].id;
                }
            }
        }
        setTimeout(()=>{ this.txtGenericSearch.inputEL.nativeElement.focus();}, 100);*/
    }
    
    ngOnInit(){

        /*if(this.dataService.retailer.crmloyalty){
            this.setCategorySelection();
            this.setProgramSelection();
        }*/
    }


    
}
