import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,Message,DataTable,Header,Dialog,InputText,Button, MessageService, SelectItem, Password} from 'primeng/primeng';
import {TableModule} from 'primeng/table'
import {DataService} from '../service/dataservice';
import {Userservice} from '../service/userservice';

import {User} from '../domain/user';
import {Router} from '@angular/router';	
import { Appdataservice } from '../service/appdataservice';
import { Printerservice } from '../service/printerservice';
import { Constants } from '../domain/constants';
import { Privilegeservice } from '../service/privilegeservice';
import { Usergroupservice } from '../service/usergroupservice';
import { Usergroup } from '../domain/usergroup';
import { Billingcounterservice } from '../service/billingcounterservice';


@Component({
    templateUrl: 'users.html'
})
export class Users implements OnInit {
    users : User[];
    msgs: Message[] = [];
    isRunning : boolean; 
    
    msgssummary : Message[] = [];    

    selectusergroups : SelectItem[];
    
    
    constructor(public dataService : Appdataservice,
        public messageService : MessageService,
        public privilegeService : Privilegeservice,
        public printerService : Printerservice,
        public usergroupService : Usergroupservice,
        public counterService : Billingcounterservice,

        public userService : Userservice, private _router : Router) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }
    
    ngOnInit() {
        this.users = this.userService.userlist;
        this.selectusergroups = this.usergroupService.getUsergroupDropdown();
        this.selectcounters = this.counterService.getCaptainCounterOptions();

    }
    
    selectedCounter : User;
    user : User;
    displayAddEditDialog : boolean = false;
    enableEditButton : boolean = false;
    isForEdit : boolean = true;
    showcounters;
    selectcounters : SelectItem[];
    
    doIShowCounters(usergroup : Usergroup){
        if(usergroup && usergroup.arrbillingprivs){
            for(var priv of usergroup.arrbillingprivs){
                if(priv == Constants.PRIV_SALES){
                    return true; 
                }
            }
        }

        return false; 
    }

    userGroupChange(){
        var usergroup = this.usergroupService.getUsergroupForId(this.user.usergroupid);
        this.showcounters = this.doIShowCounters(usergroup);
    }
    
    showAddEditDialog(fIsforEdit : boolean){
        this.msgs = [];
        this.msgssummary = [];
        this.isForEdit = fIsforEdit;
        
        if(fIsforEdit){
            this.user = this.cloneuser(this.selectedCounter);
            var usergroup = <Usergroup> this.usergroupService.getUsergroupForId(this.user.usergroupid);
            if(!this.dataService.ismainadmin && usergroup.onlyfromadmin){
                this.showErrorToast('No privileges to edit this user!');
                return;
            }

            this.showcounters = this.doIShowCounters(usergroup);
        }else{
            this.user = <User>{};
            /*var firstgroup = this.usergroupService.usergrouplist[0];
            
            if(this.usergroupService.usergrouplist && this.usergroupService.usergrouplist.length > 0){
                this.user.usergroupid = firstgroup.id;
            }*/
            this.user.usergroupid = this.selectusergroups[0].value;
            var usergroup = this.usergroupService.getUsergroupForId(this.user.usergroupid);

            this.user.active = true;
            this.showcounters = this.doIShowCounters(usergroup);
        }
        
        this.displayAddEditDialog = true;
    }
    
    
    
    cloneuser(fuser: User): User
    {
        var user = <User>{};
        for(let prop in fuser) {
            user[prop] = fuser[prop];
        }
        return user; 
     }
     
    onRowDoubleClick(event){
        this.showAddEditDialog(true);
    }
    
    onRowSelect(event) {
        this.enableEditButton =true;
    }
    
    onRowUnselect(event) {
        this.enableEditButton = false;
    }
    
    findselectedCounterIndex(): number {
        return this.userService.userlist.indexOf(this.selectedCounter);
    }
    
    getDialogWidth() : number{
       return 400;
    }
    
    getDialogHeight() : number{
        return 250;
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
   

    
    async refreshData(){
        this.isRunning = true;
        var ret = this.userService.initUsers();
        this.isRunning = false;
        if(ret){
            this.showSuccessToast('Data refreshed successfully!')
        }else{
            this.showSuccessToast('Error in refreshing data, please try again.')
        }
    }

    async save(dt : DataTable) 
    {
        this.msgs =[];
        this.isRunning = true;
        
        if(!this.user.name  || this.user.name.trim()  == ""){
            this.showErrorToast('Name can not be blank.')
            this.isRunning = false;
            return;
        }

        if(!this.user.username  || this.user.username.trim()  == ""){
            this.showErrorToast('Username can not be blank.')
            this.isRunning = false;
            return;
        }

        if(this.user.username.indexOf('@') >=0 ){
            this.showErrorToast('Username can not contain @ symbol.')
            this.isRunning = false;
            return;
        }

        if(!this.user.password  || this.user.password.trim()  == ""){
            this.showErrorToast('Password can not be blank.')
            this.isRunning = false;
            return;
        }

        if(this.user.password.length < 6 ){
            this.showErrorToast('Password must be of at least 6 characters.')
            this.isRunning = false;
            return;
        }
        

        
        
        if(this.userService.checkDuplicate(this.user)){
            this.showErrorToast('Name/User name already exists.')
            this.isRunning = false;
            return;
        }

        this.user.usergroupname  = this.usergroupService.getUsergroupNameForId(this.user.usergroupid);

        
        if(!this.isForEdit) this.user.id = this.userService.getNewId();
        this.user.posid = this.dataService.getPosId();
        if(this.dataService.retailer.multilocation) this.user.posid = this.dataService.getPosWithLocationId();

        
        var retstatus = await this.userService.save(this.user);
        
        //If true --> Coutner Added 
        if(retstatus != "ERROR" && retstatus != "CONFLICT"){
            this.isRunning = false;

            this.displayAddEditDialog = false; 
            this.enableEditButton = false;
            this.isRunning = false;
            this.showSuccessToast('User saved successfully.'); 
        }else{
            if(retstatus == 'ERROR') this.showErrorToast('Error, please try again.'); 
            else this.showErrorToast('You seem to be having an old copy, please refresh data!'); 
            this.isRunning = false;
        }
    
            
    }
    
}


