import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from './paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Printingservice } from '../service/printingservice';
import { Billingservice } from '../service/billingservice';


@Component({
    templateUrl: 'deliverymanagement.html',
    providers: [ConfirmationService]
})
export class Deliverymanagement implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];
    arrselecteditems : Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    deliveryboys : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private orderService  : Orderservice,
        private billingService : Billingservice,
        private deliveryBoyservice : Deliveryboyservice,
        private printingService : Printingservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
    deliveredby;

    initDeliveryBoys(){
        this.deliveryboys = [];
        for(var boy of this.deliveryBoyservice.deliveryboylist){
            if(boy.active){
                var boydetails = boy.name + ' (' + boy.mobilenumber + ') ';
                this.deliveryboys.push({label: boydetails, value: boy.id});
                if(this.deliveredby == null){
                    this.deliveredby = boy.id;
                }
            }
        }

    }
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.selectedRadio = 'itemdetails';
        
        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'createdonstr', header: 'Created on' },
            { field: 'countertypestr', header: 'Counter type' },
            { field: 'countername', header: 'Counter name' },
            { field: 'customername', header: 'Customer name' },
            { field: 'customermobile', header: 'Customer mobile' },
            { field: 'orderitemsstr', header: 'Items' },
            { field: 'cart.sumitemcount', header: 'Item count' },
            { field: 'cart.sumitemamount', header: 'Item amount' },
            { field: 'cart.sumitemgross', header: 'Item gross' },
            { field: 'cart.totaldiscount', header: 'Discount' },
            { field: 'cart.sumitemtaxable', header: 'Item Taxable' },
            { field: 'cart.sumitemtax', header: 'Item Taxes' },
            { field: 'cart.sumitemnet', header: 'Item Total' },
            { field: 'cart.netcharges', header: 'Charges' },
            { field: 'cart.roundingamount', header: 'Rounding' },
            { field: 'cart.netpayable', header: 'Bill amount' },
            { field: 'cart.paidamount', header: 'Paid amount' },
            { field: 'cart.pendingamount', header: 'Pending amount' },
            { field: 'pymtpaymentstring', header: 'Payment details' },
            { field: 'deliveredbystr', header: 'Delivered by' },
            { field: 'statusstr', header: 'Status' }
        ];

        
        this.orderlist = [];
        this.initDeliveryBoys();
        this.setData(null);
        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    setDeliveredBy(ord : Order){
        var dboy = this.deliveryBoyservice.getDeliveryboyStringForId(ord.deliveredby);
        ord.deliveredbystr = dboy;
    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 
    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
        if(ord.orderstatus != Constants.POS_ORDER_CANCELLED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;
            this.totalreceivableamount = this.totalreceivableamount + ord.cart.pendingamount;
        }
    }

    roundTotals(){
        
    }

    selectedRadio;
    showspinner;
    displaydevlieryboydialog;

    cancelOrder(){
        this.billingService.orderForCancellation = this.selectedOrder;
        this.billingService.makeordercancelDialogVisible = true;
    }

    showDeliveryDialog(){
        if(this.arrselecteditems.length > 3){
            this.showErrorToast('Maximum of 3 orders can be assigned at a time to a delivery boy');
            return;
        }

        for(var item of this.arrselecteditems){
            if(item.deliveredby > 0){
                this.showErrorToast('Delivery boy already assigned to order#: ' + item.id + '. Receipt# ' + item.receiptno);
                return;
            }
        }
        
        this.displaydevlieryboydialog = true; 
    }

    checkNumOrdersAssigned(){
        var ordercnt= 0;
        for(var ord of this.filteredlist){
            if(ord.deliveredby == this.deliveredby) ordercnt ++;
        }

        
        if((ordercnt + this.arrselecteditems.length) > 3){
            this.showErrorToast('Maximum of 3 pending orders can be assigned to a delivery boy!');
            return false;
        }else
            return true;

    }

    async assignDeliveryboy(){
        if(!this.checkNumOrdersAssigned()) return;

        this.showspinner = true; 
        for(var ord of this.arrselecteditems){
            ord.deliveredby = this.deliveredby; 
            await this.orderService.updateOrderInDB(ord);
        }
        this.setData(null);
        this.showspinner = false; 
        this.displaydevlieryboydialog = false; 
        
        
    }

    async markDelivered(){

        for(var item of this.arrselecteditems){
            if(!(item.deliveredby > 0)){
                this.showErrorToast('Delivery boy not assigned to order#: ' + item.id + '. Receipt# ' + item.receiptno);
                return;
            }
        }

        this.showspinner = true;
        for(var ord of this.arrselecteditems){
            ord.dlvrdone = true; 
            await this.orderService.updateOrderInDB(ord);
        }
        this.setData(null);
        this.showspinner = false; 

        
    }

    async reprintReceipt()
    {
        this.showspinner = true; 

        if(!this.selectedOrder.reprintcount) this.selectedOrder.reprintcount = 1; 
        else this.selectedOrder.reprintcount = this.selectedOrder.reprintcount + 1;

        var ret = await this.orderService.updateOrderInDB(this.selectedOrder);
        this.showspinner = false; 

        if(ret){
            document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(this.selectedOrder,true);
            setTimeout(()=>{ window.print();}, 200);
        }else{
            this.showErrorToast('Error, please try again!');
        }

            
    }

    
    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
    }

    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
        }

        if(!ord.dlvrdone) ord.dlvrstatus = 'Pending';
        else ord.dlvrstatus = 'Done';
    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){
            if(item.orderstatus == Constants.POS_ORDER_PROCESSED && (item.countertype == Constants.PARCEL || item.countertype == Constants.SELFAPP) && !item.dlvrdone)
            {
                var ord = this.orderService.getClone(item);
                console.log('Receipt no :: ' + ord.receiptno);
                
                //Get status 
                ord.statusstr = Utils.getStatusStr(ord);
                ord.orderitemsstr = Utils.itemsAsString(ord);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
                
                this.setDateStrings(ord);
                this.setPaymentString(ord);
                this.setDeliveredBy(ord);
                this.filteredlist.push(ord);
                this.incrementTotals(ord);
            }
        }
        this.filteredlist = [... this.filteredlist];    
    }

    async setData(dt : Table){
        
        this.showspinner = true; 
        this.setTotalsToZero();
        var curbsndate = this.dataService.getCurrentBusinessDate();
        this.orderlist = await this.orderService.getOrderlist(curbsndate,curbsndate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        if(dt) dt.first = 0;
        
        this.filteredlist = [... this.filteredlist];
        this.first =0 ;
        this.arrselecteditems = [];
        this.showspinner = false; 
    }


    
    
}


