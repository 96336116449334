import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {Appdataservice}  from '../service/appdataservice';
import {Constants}    from '../domain/constants';
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from '../domain/order';
import { Payment } from '../domain/payment';

import { Orderservice } from '../service/orderservice';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Utils } from '../service/utils';
import { Settingsservice } from '../service/settingsservice';
import { Printingservice } from '../service/printingservice';


@Component({
    templateUrl: 'onlineordersreport.html',
    providers: [ConfirmationService]
})
export class Onlineordersreport implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private settingService : Settingsservice,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private orderService  : Orderservice,
        private printingService : Printingservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    payment : Payment; 


    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));

        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'createdonstr', header: 'Placed on' },
            { field: 'acceptedonstr', header: 'Accepted on' },
            { field: 'foodreadyonstr', header: 'Food ready on' },
            { field: 'dispatchedonstr', header: 'Dispatched on' },
            { field: 'completedonstr', header: 'Completed on' },
            { field: 'rejectedonstr', header: 'Rejected on' },
            { field: 'customername', header: 'Customer name' },
            { field: 'customermobile', header: 'Customer mobile' },
            { field: 'addressstr', header: 'Delivery address' },
            { field: 'orderitemsstr', header: 'Items' },
            { field: 'cart.sumitemcount', header: 'Item count' },
            { field: 'cart.sumitemgross', header: 'Item amount' },
            { field: 'cart.totaldiscount', header: 'Discount' },
            { field: 'cart.netcharges', header: 'Charges' },
            { field: 'cart.couponvalue', header: 'Coupon used' },
            { field: 'cart.roundingamount', header: 'Rounding' },
            { field: 'cart.netpayable', header: 'Net amount' },
            { field: 'cart.paidamount', header: 'Paid amount' },
            { field: 'paidincash', header: 'Cash' },
            { field: 'paidonline', header: 'Online' },
            { field: 'pymtreference', header: 'Payment Reference' },
            { field: 'deliveredby', header: 'Delivered by' },
            { field: 'statusstr', header: 'Order status' },
            { field: 'statusstr', header: 'Order status' }
            
            

        ];

        if(this.dataService.retailer.multilocation &&  this.dataService.retailer.locationid == 0){
             this.cols.push({ field: 'locationname', header: 'Location' });
             this.cols.push({ field: 'oldlocname', header: 'Migrated From' });
             this.cols.push({ field: 'migratereason', header: 'Migration Reason' });
        }

        this.orderlist = [];

        this.paymentmodes = [];
        this.paymentmodes.push({label:'Cash', value: Constants.CASH});
        this.paymentmodes.push({label:'Card/Online', value: Constants.CARD_ONLINE});

            
        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    showPaymentDialog(){
         this.payment = <Payment>{};
         this.payment.paidamount = this.selectedOrder.cart.netpayable - this.selectedOrder.cart.paidamount;
         this.payment.paymentmode =Constants.CASH; 
         this.makereceiptsDialogVisible = true; 
    }
    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    
    async printReceipt()
    {

        if(this.settingService.printer.printertype == Constants.BROWSER){
            //document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(this.selectedOrder);
            window.print(); 
            
        }else{
            var receiptformat= this.printingService.generateReceiptFormat(this.selectedOrder,true);
            var printeridentifier = this.settingService.kotprinter.printername;
            if(this.settingService.printer.printertype == Constants.LAN || this.settingService.printer.printertype == Constants.USB || this.settingService.printer.printertype == Constants.BLUETOOTH)
                printeridentifier = this.settingService.printer.deviceaddress;
            
            var retstatus = await this.printingService.synchronousprint(receiptformat, this.settingService.kotprinter.printertype,printeridentifier);
            if(retstatus) {
               
            }else{
                this.showErrorToast ('Error printing receipt!');
            }        
        }
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totaldiscount = 0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 

    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
        this.totalcash = 0;
        this.totalonline = 0;
    }


    incrementTotals(ord : Order){
        if(ord.orderstatus != Constants.ORDER_REJECTED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemgross;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totaltaxes=  this.totaltaxes + ord.cart.nettaxes;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;

            this.totalcash = this.totalcash + ord.paidincash;
            this.totalonline = this.totalonline + ord.paidonline;
            this.totalreceivableamount = this.totalreceivableamount + (ord.cart.netpayable - ord.cart.paidamount);
        }
    }

    roundTotals(){
        
    }

    selectedRadio;


    
    filterreport(dt : Table)
    {

        dt.first = 0;
        this.filteredlist  = [];
         for (var det of this.orderlist){
             if(this.showpendingpayments){
                if(det.cart.paidamount < det.cart.netpayable)
                    this.filteredlist.push(det);
             }else  
                this.filteredlist.push(det);

            this.incrementTotals(det);
         }
        
         this.roundTotals();
         dt.first = 0;
        
    }

    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){

        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");

        if(ord.acceptedon > 0) ord.acceptedonstr = this.datePipe.transform(ord.acceptedon, "dd/MM HH:mm");
        if(ord.foodreadyon > 0) ord.foodreadyonstr = this.datePipe.transform(ord.foodreadyon, "dd/MM HH:mm");
        if(ord.dispatchedon > 0) ord.dispatchedonstr = this.datePipe.transform(ord.dispatchedon, "dd/MM HH:mm");
        if(ord.completedon > 0) ord.completedonstr = this.datePipe.transform(ord.completedon, "dd/MM HH:mm");
        if(ord.rejectedon > 0) ord.rejectedonstr = this.datePipe.transform(ord.rejectedon, "dd/MM HH:mm");
        
    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }

    async saveReceipt(){

        if(this.payment.paymentmode == Constants.CARD_ONLINE){
            if(!this.payment.pymtreference || this.payment.pymtreference.trim().length == 0){
                this.showErrorToast('Reference number is mandatory for card/online payments');
                return; 
            }
        }
            

        var ord = this.getOrderFromList(this.selectedOrder.id);

        if(ord){
            this.isRunning = true; 

            if(!ord.cart.paymentlist) 
                 ord.cart.paymentlist  = [];
            ord.cart.paymentlist.push(this.payment);
            //ord.updatePaidAmount(); 
            ord.cart.paidamount = ord.cart.netpayable;

            var retstatus = await this.dynamoService.putItem('order', ord);
            if(retstatus){
                this.setTotalsToZero();
                this.setFilterList();
                this.showSuccessToast('Payment entry added on order!');
                this.selectedOrder = null;
            }else{
                this.showErrorToast('Error while saving payment entry, please try again!');
            }
         
            this.makereceiptsDialogVisible = false;
            this.isRunning = false; 
        }
    }


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){

            if(this.dataService.retailer.locationid == 0 || this.dataService.retailer.locationid == item.locationid ){

                var processed = true; 
                if(this.showpendingpayments){
                    if(item.cart.paidamount >= item.cart.netpayable) processed = false; 
                }
                
                if(processed){

                    var ord = this.orderService.getClone(item);
                    //Get status 
                    ord.statusstr = Utils.getStatusStr(ord);
                    ord.orderitemsstr = Utils.itemsAsString(ord);
                    var cash = 0, paidonline = 0;
                        
                    if(ord.cart.paidamount > 0){
                        if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                            var pymt = ord.cart.paymentlist[0];
                            if(pymt.paymentmode == Constants.CASH){
                                cash = cash + pymt.paidamount;
                            }else{
                                paidonline = paidonline + pymt.paidamount;
                                ord.pymtreference = pymt.pymtreference;
                            }
                            
                        } 

                        
                    }

                    ord.paidincash = cash;
                    ord.paidonline = paidonline;

                    this.setDateStrings(ord);
                    this.filteredlist.push(ord);
                    this.incrementTotals(ord);
                }
                }

        }
        this.filteredlist = [... this.filteredlist];
            

    }

    async setData(dt : Table){
        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromtime =   this.fromdate.getTime();
        var totime =     this.todate.getTime();

        if(totime < fromtime){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }
        
        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.orderlist = await this.dynamoService.getOrders(fromtime,(totime + (24*60*60*1000))) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


