import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,GalleriaModule, InputTextModule, OverlayPanel, ContextMenu,Message,DataTable,AutoComplete, Header,Dialog,InputText,Button, BreadcrumbModule} from 'primeng/primeng';
import { DataViewModule } from 'primeng/dataview';
import {DragDropModule} from 'primeng/dragdrop';

import {DataService} from '../service/dataservice';
import {Billingcounterservice} from '../service/billingcounterservice';
import {MessageService, SelectItem} from 'primeng/api';

import {Billingcounter} from '../posdomain/billingcounter';
import {Cart} from '../posdomain/cart';

import {HostListener} from '@angular/core';
import {ToolbarModule} from 'primeng/toolbar';
import {MenuItem} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';

import * as AWS from 'aws-sdk';
import { Appdataservice } from '../service/appdataservice';
import { Table } from 'primeng/table';
import { Constants } from '../domain/constants';
import { Cartlineitem } from '../domain/cartlineitem';
import { Product } from '../domain/product';

import { Billingservice } from '../service/billingservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Orderservice } from '../service/orderservice';
import { IoT1ClickDevicesService } from 'aws-sdk';
import { Customerservice } from '../service/customerservice';
import { Customer } from '../domain/customer';
import { Utils } from '../service/utils';
import { Printingservice } from '../service/printingservice';
import { Userservice } from '../service/userservice';


@Component({
    templateUrl: 'mobilebilldialog.html'
})
export class Mobilebilldialog implements OnInit {

    showproductscreen = true;
    barcodemode = false; 
    makecoupondialogvisible = false; 
    couponcode = '';

    displayContextMenu = false;
    //selectedCounter : Billingcounter;
    constructor(public dataService: Appdataservice,
                private messageService: MessageService,
                public kitchenService : Kitchenservice,
                public orderService : Orderservice,
                public printingService : Printingservice,
                public userService : Userservice,
                public billingService : Billingservice,
                public customerService : Customerservice,
                public counterService : Billingcounterservice) {}
    


    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear('billingtoast');
        this.messageService.add({severity:'success', life: 1000, key: 'billingtoast', closable: true, summary: 'Success', detail: message});
    }
    ischeckoutinprogress = false; 
    printreceipt = true;

    showclientcheckoutdialog = false;
    
    //Display settings 
    tablerowfont;
    tablerowpadding;
    scrollbarwidth;
    posip;
    serviceport;

    tablerowfontoptions : SelectItem[];
    tablerowpaddingoptions:  SelectItem[];
    scrollbarwidthoptions : SelectItem[];

    viewcart; 
    
    
    ngOnInit(){
        this.ischeckoutinprogress = false;
        this.showproductscreen = true; 
        this.billingService.checkoutinprogress = false; 
        
        this.tablerowfontoptions = []; 
        this.tablerowfontoptions.push({label: 'Small', value: '13px'});
        this.tablerowfontoptions.push({label: 'Medium', value: '14px'});
        this.tablerowfontoptions.push({label: 'Large', value: '16px'});

        this.tablerowpaddingoptions = []; 
        this.tablerowpaddingoptions.push({label: 'Small', value: '3px'});
        this.tablerowpaddingoptions.push({label: 'Medium', value: '5px'});
        this.tablerowpaddingoptions.push({label: 'Large', value: '7px'});

        this.scrollbarwidthoptions = []; 
        this.scrollbarwidthoptions.push({label: 'Thin', value: '5px'});
        this.scrollbarwidthoptions.push({label: 'Medium', value: '7px'});
        this.scrollbarwidthoptions.push({label: 'Thick', value: '16px'});

        this.tablerowpadding = '5px';
        this.tablerowfont = '14px';
        this.scrollbarwidth = '7px';

        if(localStorage.getItem("tablerowpadding") && localStorage.getItem("tablerowpadding").length > 0){
            this.tablerowpadding = localStorage.getItem("tablerowpadding");
        }        
        if(localStorage.getItem("tablerowfont") && localStorage.getItem("tablerowfont").length > 0){
            this.tablerowfont = localStorage.getItem("tablerowfont");
        }        
        if(localStorage.getItem("scrollbarwidth") && localStorage.getItem("scrollbarwidth").length > 0){
            this.scrollbarwidth = localStorage.getItem("scrollbarwidth");
        }
        
        if(localStorage.getItem("posip") && localStorage.getItem("posip").length > 0){
            this.posip = localStorage.getItem("posip");
        }

        if(localStorage.getItem("serviceport") && localStorage.getItem("serviceport").length > 0){
            this.serviceport = localStorage.getItem("serviceport");
        }

        let root = document.documentElement;
        root.style.setProperty('--tablerow-padding', this.tablerowpadding);
        root.style.setProperty('--tablerow-font-size', this.tablerowfont);
        root.style.setProperty('--scrollbar-width', this.scrollbarwidth);
        
        
        console.log('After billing window opens ......' + window.innerHeight);
        this.arrinstructions = [];
        var strinstructions = this.dataService.retailer.appconfig.posconfig.presetinstructions;
        if(strinstructions != null && strinstructions.length > 0){
            this.arrinstructions = strinstructions.split(',');
        }
        
        this.counterService.selectedcounter.cart.lineitems = [...this.counterService.selectedcounter.cart.lineitems];
        
        //set the top items 
        this.billingService.makesubcatlistvisible = false;
        this.billingService.productfilter = '';
        this.billingService.showRecommendedProducts();
        
        
        this.iscustomerfocussed = false;
        this.viewcart= false; 

    }
    
    showCart(){
        this.viewcart = true; 
    }

    goBack(){
        this.viewcart = false;
    }
    applyCoupon(){

    }
    showDeliveryDialog(){

        console.log('|DLVERY|Checking..');

        if(this.counterService.selectedcounter){
            if(this.counterService.selectedcounter.countertype == 3 ||
            this.counterService.selectedcounter.countertype == 4 ||
            this.counterService.selectedcounter.countertype == 7
            ){
                console.log('|DLVERY|Making dialog visible');
                this.billingService.makederliveryDialogVisible = true;
            }
        }

    }

    showSettingsDialog(){
        this.billingService.makedisplayDialogVisible = true; 
    }

    applyDisplaySettings(){
        let root = document.documentElement;
        root.style.setProperty('--tablerow-padding', this.tablerowpadding);
        root.style.setProperty('--tablerow-font-size', this.tablerowfont);
        root.style.setProperty('--scrollbar-width', this.scrollbarwidth);

        localStorage.setItem('tablerowpadding',this.tablerowpadding);
        localStorage.setItem('tablerowfont',this.tablerowfont);
        localStorage.setItem('scrollbarwidth',this.scrollbarwidth);
        
        if(this.posip && this.posip.length > 0){
            localStorage.setItem('posip',this.posip);
        }else{
            localStorage.removeItem('posip');
        }

        if(this.serviceport && this.serviceport.length > 0){
            localStorage.setItem('serviceport',this.serviceport);
        }else{
            localStorage.removeItem('serviceport');
        }

        this.billingService.makedisplayDialogVisible = false; 
    
    }


    async checkout(forproforma){
        if(this.counterService.selectedcounter.cart.sumitemcount == 0){
            this.showErrorToast("No items in the bill!");
            return;
        }

        if(this.kitchenService.checkUnsent(this.counterService.selectedcounter.cart)){
            this.showErrorToast("KOT for one or more items is pending!");
            return;
        }

        this.counterService.selectedcounter.kottokens =  this.kitchenService.getKOTTokensFromCart(this.counterService.selectedcounter.cart);

        if(!forproforma){
            if(this.counterService.selectedcounter.validatefullpayment && this.counterService.selectedcounter.cart.pendingamount > 0){
                this.billingService.checkoutinprogress = true; 
                this.billingService.billdialoginstance = null; //NULL here, i am on mobile! 
                this.billingService.pymtfromordermanagement = false;
                this.billingService.makepaymentdialogvisible = true;                
                return; 
            }
        }

        

        this.showspinner = true;
        
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data, it is refreshed now!');
            this.showspinner = false; 
            return;
        }


        
        
       /* if(this.counterService.selectedcounter.validatefullpayment 
            && this.counterService.selectedcounter.defaultpaymentmode == 0
            && this.counterService.selectedcounter.cart.pendingamount > 0)
            {
            this.billingService.makepaymentdialogvisible = true; 
            return; 
        }*/
        
        var printreceipt = true; 
        var isduplicate = false; 
         if(this.counterService.selectedcounter.proformaid > 0 ){
                if(forproforma) isduplicate = true;
                else printreceipt = false;
         }
        
        
        var order = await this.orderService.checkoutCounter(this.counterService.selectedcounter,forproforma);
        if(order){
             
             if(forproforma){
                this.counterService.selectedcounter.proformaid = order.id ; 
                this.counterService.saveSelectedCounter(); // Put it back on the server
             }else{
                this.counterService.clearCounter(this.counterService.selectedcounter);
                this.counterService.saveSelectedCounter(); // Put it back on the server
             }
              
             this.billingService.displayBillDialog = false; 
             this.showspinner = false;

                         
            if(printreceipt){
                document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(order,isduplicate);
                setTimeout(()=>{ window.print();}, 200);
            }

        
        }else{
            this.showspinner = false;
            this.showErrorToast('Error while checking out, please try again!');
        }


    }

    getPanelHeight(){
        var returningheight =  (window.innerHeight * 0.85) - 60 +  "px"; 
        return returningheight;
    }

    getViewHeight(){
        //if(Constants.DEBUG_MODE) console.log('Inner height ' + window.innerHeight + ' Inner width '  + window.innerWidth);

        var returningheight =  (window.innerHeight * 0.82) +  "px"; 
        
        /*if(window.innerHeight < 600)
             returningheight =  (window.innerHeight * 0.70) +  "px"; 
        else if(window.innerHeight < 650)
            returningheight =  (window.innerHeight * 0.75) +  "px"; */
        
        return returningheight;
    }
    getBillingViewStyle(){
        return "{height: '550px'}";
    }

    //selectedLineItem : Cartlineitem;

   
    @ViewChild('auto') txtProductBox : AutoComplete;    
    @ViewChild('autoinstr') txtInstructionBox : AutoComplete;
    @ViewChild('quantity') txtQuantiyBox : ElementRef;
    @ViewChild('qtybox') txtEditQuantiyBox : ElementRef;
    @ViewChild('couponbox') txtCouponBox : ElementRef;
    @ViewChild('remarksbox') txtRemarksBox : ElementRef;
    
    @ViewChild('dt') dataTable : Table;

    selectedItemIndex : number;


    onCouponShow(){
        setTimeout(()=>{ this.txtCouponBox.nativeElement.focus();}, 100);
    }

    onRemarksShow(){
        setTimeout(()=>{ this.txtRemarksBox.nativeElement.focus();}, 100);
    }
    

    
    texteditquantity;

    onQuantityShow(){
        setTimeout(()=>{ this.txtEditQuantiyBox.nativeElement.focus();}, 100);
    }
    
    onRowSelect(event){
         
         
    }

    onRowUnselect(event){
        this.selectedItemIndex = -1;
        
    }


    hideAllDialogs(){
        
    }

    iscustomerfocussed = false; 

    focusCustomer(){
        console.log('Customer box focussed');
        this.iscustomerfocussed= true; 
    }
    
    blurCustomer(){
        console.log('Customer box lost focus');
        this.iscustomerfocussed= false; 
    }

    toggleBarcode(){
        this.barcodemode  = !this.barcodemode;
    }

    async findCustomer(){
        /*if(this.custmobile == null || this.custmobile.length < 9 || isNaN(this.custmobile) || isNaN(parseInt(this.custmobile))){
            this.showErrorToast('Invalid customer mobile no!');
            return null;
        }
        
        var retlist = <Customer[]> await this.customerService.findCustomer(this.custmobile);
        if(retlist){
            if(retlist.length == 0){
                this.billingService.iscustfound = false;
                this.billingService.mobilefornewcust = this.custmobile;
                this.billingService.makecustomerdialogvisible = true; 
                this.counterService.selectedcounter.customer = null;//Unset if existing customer 
            }else{
                var cust = retlist[0];
                
                cust.toppurchasestr = this.customerService.getTopPurchases(cust);
                this.counterService.selectedcounter.customer = retlist[0];
                
            }
        }else{
            this.showErrorToast('Error fetching customer details, please try again!');
        }*/
    }

    custmobile;

    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.billingService.displayBillDialog){

            if(event.keyCode == 27){
                if(this.billingService.isAnyDialogOpen()) this.hideAllDialogs();
                else this.billingService.displayBillDialog = false;
            }

            //F4 --> Make all dialogs invisible
            if(event.keyCode == 115){
                this.billingService.displayBillDialog = false;
                this.hideAllDialogs();
            } 
            if(this.iscustomerfocussed){
                if(event.keyCode == 13){
                    this.findCustomer();
                }
                return;
            }
       
            //Enter pressed
            if(!this.billingService.isAnyDialogOpen() ){
                
                switch(event.keyCode){
                    case 13: this.handleEnter();break;
                    case 107: this.incrementQuantity();break;
                    case 109: this.decrementQuantity();break;
                    case 46: this.removeItem();break;
                    case 40: this.moveDown();break;    
                    case 38: this.moveUP();break;
                    case 117: this.checkout(true);break;
                    case 118: this.toggleBarcode();break;
                    case 120: this.sendKOT();break;
                    case 123: this.printreceipt = !this.printreceipt; break;
                    case 121: this.checkout(false);break;
                    case 113: this.printreceipt = !this.printreceipt; break;        
                    case 19: this.checkout(false); break;
                    case 33: this.showPaymentDialog();break;
                    
                    case 17: this.showDiscountBoxForBill();break;
                    
                    case 45: this.setFocusOnProduct();break;
                    case 111: this.showQuantityDialog();break;
                    case 34: this.showDeliveryDialog();break;
                    case 106: this.showInstructionsDialog(); break;
                    default: return; //Other keys let it get typed
                }
        
                event.stopPropagation(); // Stop propgation as the event was handled here!
                event.preventDefault();
                return false;
            }else{

                //Quantity dialog
                if(this.billingService.makequantityDialogVisible){
                    if(event.keyCode == 13) this.setQuantity();
                }

                if(this.billingService.makeremarksDialogVisible){
                    if(event.keyCode == 13) this.saveRemarks();
                }


            }   
          
        }


    }
        
    showRemarksDialog(){
        this.billingService.makeremarksDialogVisible = true;
            
    }

    
    showOperatorDialog (){
        
    }


    showInstructionsDialog(){
        if(this.billingService.selectLineitem){
            this.billingService.makeinstructionsdialogvisible = true;
        }
    }


    currentPage; 

    setCurrentPage(n: number) {
        if(this.currentPage != n){
            this.first = n * this.rowsPerpage;
            this.currentPage = n;
            if(Constants.DEBUG_MODE) console.log('setting current page tp ' + n );
        }

    }

    selectLastItem(){
        if(this.counterService.selectedcounter.cart.lineitems && this.counterService.selectedcounter.cart.lineitems.length > 0){

            //var len = this.counterService.selectedcounter.cart.lineitems.length;
            this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[0];
            //console.log('LAST ITEM --> ' + this.counterService.selectedcounter.cart.lineitems[len-1].productname); 
            
            //var pageno = Math.floor((len-1)/this.rowsPerpage);
            //this.setCurrentPage(pageno);
            //this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[0];
        }
    }

    //Increment and Decrement 
    moveDown(){
        if(this.txtProductBox.suggestions != null && this.txtProductBox.suggestions.length > 0) return;
        if(this.txtInstructionBox.suggestions != null && this.txtInstructionBox.suggestions.length > 0) return;
        
        if(this.billingService.selectLineitem != null ){
            var curIndex = this.counterService.selectedcounter.cart.lineitems.indexOf(this.billingService.selectLineitem);
            if(curIndex < (this.counterService.selectedcounter.cart.lineitems.length - 1)){
                this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[curIndex+1];
            }
        }else{
            if(this.counterService.selectedcounter.cart.lineitems.length > 0){
                this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[0];
            }
        }


        this.txtProductBox.inputEL.nativeElement.blur();
        
    }

    autoScroll(){
        
        
    }


    moveUP(){

        if(this.txtProductBox.suggestions != null && this.txtProductBox.suggestions.length > 0) return;
        if(this.txtInstructionBox.suggestions != null && this.txtInstructionBox.suggestions.length > 0) return;
        if(this.txtProductBox.suggestions != null && this.txtProductBox.suggestions.length > 0) return;
        if(this.txtInstructionBox.suggestions != null && this.txtInstructionBox.suggestions.length > 0) return;
       
        if(this.billingService.selectLineitem != null ){
            var curIndex = this.counterService.selectedcounter.cart.lineitems.indexOf(this.billingService.selectLineitem);
            if(curIndex == 0){
                //Now go back to product search 
                this.txtProductBox.inputEL.nativeElement.focus();
                this.billingService.selectLineitem = null;
            }else{
                this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[curIndex - 1];
                var pageno = Math.floor((curIndex-1)/this.rowsPerpage);
                this.setCurrentPage(pageno);

                this.txtProductBox.inputEL.nativeElement.blur();
            }
        }

    }



    //Increment and Decrement 
    incrementQuantity(){
        if(this.billingService.selectLineitem != null ){

            if(!this.isCounterBillable()) return;

            if(this.billingService.selectLineitem.kitstatus != Constants.UNSENT){
                this.showErrorToast("Item already sent to kitchen!");
                return;
            }
            this.counterService.incremenQuantity(this.billingService.selectLineitem);
            
        }
    }


    //Set quantity 
    setQuantity(){
        if(this.billingService.selectLineitem != null ){

            if(!this.isCounterBillable()) return;

            if(this.texteditquantity && this.texteditquantity.trim().length > 0 && !isNaN(this.texteditquantity) && parseFloat(this.texteditquantity) > 0){
                this.counterService.setQuantity(this.billingService.selectLineitem,parseFloat(this.texteditquantity));
                this.billingService.makequantityDialogVisible = false;
            }else{
                this.showErrorToast("Invalid quantity!");
            }
        }else{
            this.billingService.makequantityDialogVisible = false;
        }

    }


    decrementQuantity(){
        if(this.billingService.selectLineitem != null ){

            if(!this.isCounterBillable()) return;

            if(this.billingService.selectLineitem.kitstatus != Constants.UNSENT){
                this.showErrorToast("Item already sent to kitchen!");
                return;
            }
            this.counterService.decrementQuantity(this.billingService.selectLineitem);
            
        }
       
    }

    qtydialogheader;

    showCouponDialog(){
        
    }

    showQuantityDialog(){
        if(this.billingService.selectLineitem != null){
            if(this.billingService.selectLineitem.kitstatus != Constants.UNSENT){
                this.showErrorToast("Item already sent to kitchen!");
            }else{
                this.qtydialogheader = "Quantity for: " + this.billingService.selectLineitem.productname;
                this.texteditquantity = "";
                //this.txtEditQuantiyBox.nativeElement.focus();
                this.billingService.makequantityDialogVisible = true;
            }

        }
        
    }


    

    showDiscountBoxForBill(){
        if(this.userService.checkBillingPriv(Constants.PRIV_APPLY_DISCOUNT)){
            this.billingService.makediscountvisible = true; 
        }else{
            this.billingService.reauthpurpose = Constants.PRIV_APPLY_DISCOUNT;
            this.billingService.makeReauthDialogVisible = true;
        }

        
    }

    
    first : number = 0;

    showCustomerDialog(){
    }

    showPaymentDialog(){
        if(this.kitchenService.checkUnsent(this.counterService.selectedcounter.cart)){
            this.showErrorToast("KOT for one or more items is pending!");
            return;
        }

        this.billingService.checkoutinprogress = false; 
        this.billingService.pymtfromordermanagement = false; 
        this.billingService.makepaymentdialogvisible = true;
    }


    getImageSrc(kitstatus){

    }

    async refetchCounter(){
    }


    async sendKOT(){

        if(!this.kitchenService.checkUnsent(this.counterService.selectedcounter.cart)){
            this.showErrorToast('All KOTs already generated!');
            return;
        }

        
        this.showspinner = true; 
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data, it is refreshed now!');
            this.showspinner = false; 
            return;
        }
        
        var ret= await this.kitchenService.generateKOTs();
        if(ret != 'ok'){
            this.showErrorToast('Error sending items to kitchen');
        }else{
            
            /*console.log('Is Active--> ' + qz.websocket.isActive());
            if(!qz.websocket.isActive())
                await qz.websocket.connect();
            
            alert("Connected!");
            var config = qz.configs.create("POS");
            console.log('Connected to QZZ ');
            
            var data = [
                'Data\n',
                'Should be simple data\n',
                'To be printed\n'
            ];

            qz.print(config,data);*/
        

        }
        this.showspinner = false;

    }


    closeDialog(){
            
    }


    isCounterBillable(){
        return true;
    }
    
    cancelItems(){
        if(this.kitchenService.checkSent(this.counterService.selectedcounter.cart)){
            if(this.userService.checkBillingPriv(Constants.PRIV_CANCEL_KOT)){
                this.billingService.makekotCancelDialogVisible = true;
            }else{
                this.billingService.reauthpurpose = Constants.PRIV_CANCEL_KOT;
                this.billingService.makeReauthDialogVisible = true;
            }
    
                
        }else{
            this.counterService.clearCart(this.counterService.selectedcounter);
        }
    }



    showspinner;

    emptyCart(){
    }

    getItemBarVisibility(){
    }


    generateProforma(){
        setTimeout(()=>{ this.ischeckoutinprogress = false;}, 1000);
    }



    removeItem(){
        if(this.billingService.selectLineitem != null ){
            if(!this.isCounterBillable()) return;
            
            if(this.billingService.selectLineitem.kitstatus != Constants.UNSENT){
                this.showErrorToast('Item already sent to kitchen!');
                //this.billingService.makeitemcancelDialogVisible = true;
                return;
            }
    
            var position= this.counterService.selectedcounter.cart.lineitems.indexOf(this.billingService.selectLineitem);
            this.counterService.removeItemFromCart(this.billingService.selectLineitem);
            var newlength = this.counterService.selectedcounter.cart.lineitems.length;
            if( newlength > position){
                this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[position];
            }else if(newlength > 0){
                this.billingService.selectLineitem = this.counterService.selectedcounter.cart.lineitems[newlength - 1];
            }else{
                this.billingService.selectLineitem = null;
                this.billingService.showitembar = 'hidden';
            }

        }
    }



    isDeliveryTakeaway(){
    }


    handleEnter(){
        this.addItemThroughKeypad();
    }

    addItemThroughKeypad(){
        var productid =0;
        var pname = this.txtProductBox.inputEL.nativeElement.value;
        
        if(!this.barcodemode){
            if(pname != null && pname.trim().length > 0){
                productid = this.dataService.getSaleProductIdByNameCode(pname);
            }
            
            if(productid != 0){
                if(this.textquantity != null && this.textquantity.trim().length > 0 && !isNaN(this.textquantity) ){
                    this.addItemtoBill();
                    return;
                }
            }
        }else{

            
            var prod = null;
            if(pname != null && pname.trim().length > 0){
                if(Constants.DEBUG_MODE) console.log('|BARCODE|finding for barcode' + pname);
                this.txtProductBox.inputEL.nativeElement.value = '';
                prod = this.dataService.getProductByBarcode(pname);
                if(Constants.DEBUG_MODE) console.log('|BARCODE|Step 2:' + pname + new Date().getTime());
                
            }
            
            if(prod != null){
                this.addItemFromProductClick(prod);
                if(Constants.DEBUG_MODE) console.log('|BARCODE|Step 3:' + pname + new Date().getTime());
            } 


        }

        this.setFocusOnProduct();

    }
    

    rowsPerpage = 12; 

    onHide(event){
        this.counterService.selectedcounter.unsentitems = this.kitchenService.checkUnsent(this.counterService.selectedcounter.cart);
        this.counterService.displaycounterlist = [... this.counterService.displaycounterlist];
    }


    onAfterShow(event){

        console.log('After billing window opens ......' + window.innerHeight);
        this.ischeckoutinprogress = false;
        this.billingService.checkoutinprogress = false; 
        this.arrinstructions = [];
        var strinstructions = this.dataService.retailer.appconfig.posconfig.presetinstructions;
        if(strinstructions != null && strinstructions.length > 0){
            this.arrinstructions = strinstructions.split(',');
        }
        
        //initialize the customer
        if(this.counterService.selectedcounter.customer && this.counterService.selectedcounter.customer.mobileno){
            this.custmobile = this.counterService.selectedcounter.customer.mobileno;
        }else{
            this.custmobile = '';
        }

        /*if(window.innerHeight > 700)
            this.rowsPerpage = 12;
        else if(window.innerHeight > 600)
            this.rowsPerpage = 10;
        else
            this.rowsPerpage = 8;

        this.dataTable.rows = this.rowsPerpage;*/
        
        
        //print receipt 
        if(Constants.DEBUG_MODE) console.log('RECEIPT : ' + this.counterService.selectedcounter.printreceipt);
        this.printreceipt= this.counterService.selectedcounter.printreceipt;


        this.billingService.showitembar = 'hidden';
        //this.billingService.selectLineitem = null;
        this.counterService.selectedcounter.cart.lineitems = [...this.counterService.selectedcounter.cart.lineitems];
        
        //set the top items 
        this.billingService.makesubcatlistvisible = false;
        this.billingService.setDefaultlist();
    
        //this.counterService.setPaymentString(this.counterService.selectedcounter);
        setTimeout(()=>{this.setFocusOnProduct()}, 150);

        /*if(this.counterService.selectedcounter.onbillstart == Constants.OPEN_CUSTOMER_DIALOG){
            if(this.counterService.selectedcounter.customer == null || !this.counterService.selectedcounter.customer.mobileno )
                this.billingService.makecustomerdialogvisible = true;
        }

        if(this.counterService.selectedcounter.onbillstart == Constants.OPEN_OPERATOR_DIALOG){
            if(this.counterService.selectedcounter.operatorid == 0 )
                this.billingService.makeoperatorDialogVisible = true;
        }*/

        this.iscustomerfocussed = false;
        
    }


    hideScrollbars(){
        document.documentElement.style.overflow = 'hidden';  // firefox, chrome
    }
    processBill(){

    }

    
    getStyle(){
        return "width:" + this.getScreenWidth()  + "px;height:"+ this.getScreenHeight() + "px;"
    }
    
    getWidth(){
        return this.getScreenWidth() * 0.9;
    }

    getHeight(){
        if(Constants.DEBUG_MODE) console.log(this.getScreenHeight() - 20);
        return this.getScreenHeight() - 20;
    }
    
    getScrollStyle(){
        return "{width: '100%',height: '" + this.getScreenHeight() * 0.7 + "px'}";
    }

    getScreenWidth(){
        return window.screen.width;
    }

    getScreenHeight(){
        return window.screen.height;
    }
    
    openFileWindow(fileInput: HTMLElement){
        fileInput.click();
    }
  
    showProductName(cartlineitem : Cartlineitem) : String{
        if(Constants.DEBUG_MODE) console.log('|PERFORMANCE|Getting productname for  ' + cartlineitem.productname  +  ' ** ' + new Date().getTime());
        

        var prodname = cartlineitem.productname;  
        if(cartlineitem.instructions != null && cartlineitem.instructions.length > 0)
            prodname = prodname + " (" + cartlineitem.instructions +") "; 
        
        if(cartlineitem.customization != null && cartlineitem.customization.length > 0)
            prodname = prodname + " (" + cartlineitem.customization +") ";
        
        if(cartlineitem.schcode != null && cartlineitem.schcode.length > 0)
            prodname = prodname + " (" + cartlineitem.schcode +") ";
        
        return prodname;
        
    }

    resultsproduct : string[];
    txtproduct : string;
    txtinstructions : string;
    lineitems : Cartlineitem[];
    resultsinstructions : string[];
    //presetinstructions = ['Less Spice','Less Oil', 'Jain', 'No Onion/Garlic', 'Roasted', 'Kadak Pav'];

    arrinstructions : string[];

    searchinstructions(event) {
        this.resultsinstructions = [];
        let query = event.query;        
        
        for(let i = 0; i < this.arrinstructions.length; i++) {
            let strinstruction = this.arrinstructions[i];

            if(strinstruction.toLowerCase().indexOf(query.toLowerCase()) >= 0)  {
                this.resultsinstructions.push(strinstruction);
            }
        }

    }

    getImageName(prod : Product){
        if(Constants.DEBUG_MODE) console.log('|PERFORMANCE|Getting image for product ' + prod.name  +  ' ** ' + new Date().getTime());
        return "https://s3.ap-south-1.amazonaws.com/foodkartiposimages/products/" + prod.id +".jpg";
    }

    getSubcatImageName(subcatid){
        return "https://s3.ap-south-1.amazonaws.com/foodkartiposimages/subcategories/" + subcatid +".jpg";
    }

    getVegNonVeg(prod : Product){
        if(prod.isvegeterian)
            return "assets/demo/images/veg.png";
        else
            return "assets/demo/images/nonveg.png";
    }

    searchproduct(event) {
        if(!this.barcodemode){
            this.txtproduct = '';
            this.resultsproduct = [];
            let query = event.query; 
            
            if(Constants.DEBUG_MODE) console.log('|PRODUCTOPTIMIZE|'+ query  + new Date().getTime());
            this.resultsproduct = this.dataService.searchProductsForBilling(query);
            //if(Constants.DEBUG_MODE) console.log('PRODUCTOPTIMIZE' + new Date().getTime());
        }
    }

    msgsinventory: Message[] = [];
    textquantity;

    currentfocus; 

    
    setFocusOnProduct(){
        //if(Constants.DEBUG_MODE) console.log('Showing focus on product');
        //if(!this.settingsService.touchmode)
            this.txtProductBox.inputEL.nativeElement.focus();
        
        this.currentfocus = 1;
        this.textquantity = '1';
        this.txtproduct = '';
        this.txtinstructions = '';
        //if(Constants.DEBUG_MODE) console.log('txtproduct is after setting ' + this.txtproduct );

    }

    setFocusOnQuantity(){
        this.currentfocus = 2;
        this.txtQuantiyBox.nativeElement.focus();
        this.txtQuantiyBox.nativeElement.select();
    }

    setFocusOnInstructions(){
        this.currentfocus = 3;
        this.txtInstructionBox.inputEL.nativeElement.focus();
    }
    

    addItemFromProductClick(product){

        if(product.customization != null){
            if(Constants.DEBUG_MODE) console.log('Displaying customization dialog1');
            this.billingService.productForCustomization = product;
            this.billingService.quantityForCustomization= 1;
            this.billingService.makeCustomizationDialogVisible = true;
            this.billingService.billdialoginstance = null;//On mobile
            
        }else{
            this.counterService.addProductToCart(product,1,'',0,'',0,"");
            this.selectLastItem();
        }

       
    }


    addToCart(product : Product,quantity,instructions ){
        this.counterService.addProductToCart(product,quantity,'',0,instructions,0,"");
        this.selectLastItem();
    }


    addItemtoBill(){
        
        var productid = 0;
        var product : Product;
       

        this.msgsinventory = [];
        
        /*if(this.counterService.selectedcounter.proformaid > 0 && !this.dataService.retailer.multipleproforma && this.counterService.selectedcounter.cart.lineitems.length  > 0 ){
            this.showErrorToast("Proforma is already generated!");
            return;
        }
    
        if(this.counterService.selectedcounter.kottoserver){
            this.showErrorToast('Reply for one or more KOTs is not received, please Refresh counter!');
            return;
        }*/

    
        if(this.txtproduct != null && this.txtproduct.trim().length > 0){
            product = this.dataService.getProductByNameCode(this.txtproduct);
        }
        
        if(product == null){
            this.msgsinventory.push({severity:'error', summary:'Error:', detail: 'Please select a valid product!'});
            return;
        }
        
        
        if(this.textquantity != null && this.textquantity.trim() == ""){
            this.msgsinventory.push({severity:'error', summary:'Error:', detail: 'Quantity can not be blank!'});
            return;
        }

        if(isNaN(this.textquantity.trim())){
            this.msgsinventory.push({severity:'error', summary:'Error:', detail: 'Quantity must be numeric!'});
            return;
        }
        var quantity = parseFloat(this.textquantity);

        if(quantity < 0){
            this.msgsinventory.push({severity:'error', summary:'Error:', detail: 'Invalid quantity!'});
            return;
        }

        if(product.customization != null){
            if(Constants.DEBUG_MODE) console.log('Displaying customization dialog1');
            this.billingService.productForCustomization = product;
            this.billingService.quantityForCustomization= quantity;
            this.billingService.makeCustomizationDialogVisible = true;
            this.billingService.billdialoginstance = null; //on mobile
            
        }else{
            this.addToCart(product,quantity,this.txtinstructions);
            this.selectLastItem();
        }



        //if(Constants.DEBUG_MODE) console.log('billing service ' + this.billingService.selectedCounter.cart.netpayable);
        this.setFocusOnProduct();

       

    }



    showProductsForSubcat(subcatid){
        if(Constants.DEBUG_MODE) console.log('Subcat clicked --> -->' + subcatid ); //+ ' Event ' + event.label);
        this.billingService.currentSubcategory = subcatid;
        this.billingService.productfilter = '';
        this.billingService.productlist = this.dataService.getSubcategoryForId(subcatid).prodlist;
        this.billingService.productlist=[...this.billingService.productlist];
        this.billingService.makesubcatlistvisible = false;
    }

   
    remarks;
    saveRemarks(){
        if(!this.remarks || this.remarks.trim().length == 0 ){
            this.showErrorToast("Remarks can not be empty");
            return; 
        }
        
        this.counterService.selectedcounter.remarks =this.remarks;
        this.billingService.makeremarksDialogVisible = false;

    }



    
   
    
}


