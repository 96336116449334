import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,SelectItem,Growl,Column,Message,DataTable,Header,Dialog,InputText,Button,ColorPickerModule,ColorPicker, MessageService} from 'primeng/primeng';
import {TableModule} from 'primeng/table'
import {DataService} from '../service/dataservice';
import {Product} from '../domain/product';
import {Billingcounterservice} from '../service/billingcounterservice';

import {Billingcounter} from '../domain/billingcounter';
import {CustomizationOption} from '../domain/customizationoption';
import {Router} from '@angular/router';	
import {Constants} from '../domain/constants';


import * as AWS from 'aws-sdk';
import { Bulkbillingcounter } from '../domain/bulkbillingcounter';
import { Appdataservice } from '../service/appdataservice';
import { Printerservice } from '../service/printerservice';
import { Aggregatorservice } from '../service/aggregatorservice';

@Component({
    templateUrl: 'billingcounters.html'
})
export class Billingcounters implements OnInit {

    counters : Billingcounter[];
    msgs: Message[] = [];
    isRunning : boolean; 
    countertypes : SelectItem[];
    aggregators : SelectItem[];
    selectonbillstart : SelectItem[];
    selectonbillend : SelectItem[];
    selectoperatortype : SelectItem[];
    defaultpaymentmodes : SelectItem[];
    selectgstbehavior : SelectItem[];
    selectprinters : SelectItem[];
    selectkotprinters : SelectItem[];
    

    displayCopyDialog ;


    msgssummary : Message[] = [];    

    bulkcreate : Bulkbillingcounter;

    constructor(public dataService : Appdataservice, public messageService : MessageService,
         public billingCounterService : Billingcounterservice,
         public aggregatorService : Aggregatorservice,
         private _router : Router , public printerService : Printerservice) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }
    
    ngOnInit() {

        console.log('Num counters --> '  + this.billingCounterService.billingcounterlist.length);
        this.counters = this.billingCounterService.billingcounterlist;
        this.countertypes = [];
        this.countertypes.push({label:'Regular', value:Constants.NORMAL});
        this.countertypes.push({label:'AC/Special', value:Constants.AC_SPECIAL});
        this.countertypes.push({label:'Takeaway', value:Constants.TAKEAWAY});
        this.countertypes.push({label:'Online', value:Constants.ONLINE});
        this.countertypes.push({label:'Delivery', value:Constants.PARCEL});
        this.countertypes.push({label:'Consumer App', value:Constants.SELFAPP});

    
        /*this.aggregators = [];
        this.aggregators.push({label:'Swiggy', value:Constants.SWIGGY});
        this.aggregators.push({label:'Zomato', value:Constants.ZOMATO});
        this.aggregators.push({label:'FoodPanda', value:Constants.FOODPANDA});
        this.aggregators.push({label:'UberEats', value:Constants.UBEREATS});
        this.aggregators.push({label:'Scootsy', value:Constants.SCOOTSY});
        this.aggregators.push({label:'Dunzo', value:Constants.DUNZO});
        this.aggregators.push({label:'Magic PIN', value:Constants.MAGICPIN});
        this.aggregators.push({label:'Talabat', value:Constants.TALABAT});*/
        this.aggregators = this.aggregatorService.aggretatoroptions;
        
    
        this.defaultpaymentmodes = [];
        this.defaultpaymentmodes.push({label:'Unpaid', value:0});
        this.defaultpaymentmodes.push({label:'Cash', value:Constants.PYMT_CASH});
        this.defaultpaymentmodes.push({label:'Card', value:Constants.PYMT_CARD});
        this.defaultpaymentmodes.push({label:'Online', value:Constants.PYMT_PG});
        this.defaultpaymentmodes.push({label:'Customer account', value:Constants.PYMT_ACT});
        this.defaultpaymentmodes.push({label:'UPI', value:Constants.PYMT_UPI});
        this.defaultpaymentmodes.push({label:'PayTM', value:Constants.PYMT_PAYTM});
        this.defaultpaymentmodes.push({label:'Other Wallets', value:Constants.PYMT_TP_WALLET});
        this.defaultpaymentmodes.push({label:'Aggregator', value:Constants.PYMT_AGGREGATOR});

        this.selectonbillstart = [];
        this.selectonbillstart.push({label:'No Dialog', value:Constants.NO_DIALOG});
        this.selectonbillstart.push({label:'Customer dialog', value:Constants.OPEN_CUSTOMER_DIALOG});
        this.selectonbillstart.push({label:'Operator dialog', value:Constants.OPEN_OPERATOR_DIALOG});
        
        this.selectonbillend = [];
        this.selectonbillend.push({label:'No Dialog', value:Constants.NO_DIALOG});
        this.selectonbillend.push({label:'Payment dialog', value:Constants.OPEN_PAYMENT_DIALOG});
        this.selectonbillend.push({label:'Delivery dialog', value:Constants.OPEN_DELIVERY_DIALOG});
        this.selectonbillend.push({label:'Both Payment / Delivery', value:Constants.OPEN_PYMT_DLVRY_DIALOG});
        
        
        
        this.selectgstbehavior = [];
        this.selectgstbehavior.push({label:'Same as Product', value:Constants.SAME_AS_PRODUCT});
        this.selectgstbehavior.push({label:'Forward Tax', value:Constants.TAX_IN_FORWARD});
        this.selectgstbehavior.push({label:'Reverse Tax', value:Constants.TAX_IN_REVERSE});

        this.selectprinters = [];
        this.selectprinters.push({label:'No Print', value:-1});

        for(var printer of this.printerService.printerlist){
            this.selectprinters.push({label:printer.printername, value:printer.id});
        }

        this.selectkotprinters = [];
        this.selectkotprinters.push({label:'No Print', value:-1});
        this.selectkotprinters.push({label:'In Kitchen', value:0});
        

        for(var printer of this.printerService.printerlist){
            this.selectkotprinters.push({label:printer.printername, value:printer.id});
        }

        
    }
    
    selectedCounter : Billingcounter;
    ctr : Billingcounter;
    displayAddEditDialog : boolean = false;
    enableEditButton : boolean = false;
    isForEdit : boolean = true;
    displayBulkCreateDialog : boolean = false;
    selectcopycounters : SelectItem[];
    arrselectedcounters;

    showBulkCreateDialog(){
        this.bulkcreate = <Bulkbillingcounter>{};
        this.bulkcreate.countertype = Constants.NORMAL;
        this.bulkcreate.colorcode = Constants.getDefaultColor(Constants.NORMAL);
        this.bulkcreate.aggregatorid = Constants.SWIGGY;
        this.bulkcreate.defaultpaymentmode = 0;
        this.bulkcreate.receipton = -1;
        this.bulkcreate.koton = -1;
        
        this.bulkcreate.whitetext = false;
        this.displayBulkCreateDialog = true;
        
    }

    showCopyDialog(){
        this.ctr = this.clonectr(this.selectedCounter);
        this.arrselectedcounters = [];
        this.selectcopycounters = this.billingCounterService.getCounterCopyOptions(this.ctr);
        this.statustext = '';
        this.displayCopyDialog = true;

    }

    statustext;

    

    async copySettings(){
        if(!this.arrselectedcounters || this.arrselectedcounters.length == 0){
            this.showErrorToast('Please select at least one destination counter!');
            return;
        }

        

        //Now copy
        this.isRunning = true; 
        for(var dest of this.arrselectedcounters){
            var destctr = this.billingCounterService.getBillingcounterForId(dest);
            if(destctr){
                var destclone = this.clonectr(destctr);
                this.statustext = 'Copying settings to : ' + destclone.countername;
                destclone.colorcode = this.ctr.colorcode;
                destclone.whitetext = this.ctr.whitetext;
                destclone.defaultpaymentmode = this.ctr.defaultpaymentmode;
                destclone.onbillend = this.ctr.onbillend;
                destclone.onbillstart = this.ctr.onbillstart;
                destclone.receipton = this.ctr.receipton;
                destclone.dupreceipton = this.ctr.dupreceipton;
                destclone.koton = this.ctr.koton;
                destclone.dupkoton = this.ctr.dupkoton;
                destclone.dupkotconsolidated = this.ctr.dupkotconsolidated;
                destclone.dupkotkitchenspecific = this.ctr.dupkotkitchenspecific;
                destclone.dupkotkitchenid = this.ctr.dupkotkitchenid;
                destclone.keepopenonchkout = this.ctr.keepopenonchkout;
                destclone.applyscheme = this.ctr.applyscheme;
                destclone.validatecustomer = this.ctr.validatecustomer;
                destclone.validatefullpayment = this.ctr.validatefullpayment;
                destclone.validatedeliveryinfo = this.ctr.validatedeliveryinfo;
                destclone.validateproforma = this.ctr.validateproforma;
                destclone.sectionname = this.ctr.sectionname;
                
                var ret = await this.billingCounterService.saveCounter(destclone);
                if(ret == 'DONE'){
                    this.billingCounterService.refreshInList(destclone);
                }else{
                    this.showErrorToast('Error while copying settings to : ' + destclone.countername + ', please try again!');
                    this.isRunning = false; 
                    return;
                }
            }

        }

        this.isRunning = false;
        this.displayCopyDialog = false;  
    }

    getHotkeyForId(counterid){
        if(counterid <= 9) return "" + counterid; 
        
        counterid = counterid + 55; 
        return String.fromCharCode(counterid).toUpperCase();
    }
    
    changeColorCode(){
        this.ctr.colorcode = Constants.getDefaultColor(this.ctr.countertype);
        
    }

    changeBulkCreateColorCode(){
        this.bulkcreate.colorcode = Constants.getDefaultColor(this.bulkcreate.countertype);
    }

    showAddEditDialog(fIsforEdit : boolean){
        this.msgs = [];
        this.msgssummary = [];
        this.isForEdit = fIsforEdit;
        

        if(fIsforEdit){
            this.ctr = this.clonectr(this.selectedCounter);
        }else{
            this.ctr = <Billingcounter>{};
            this.ctr.countertype = Constants.NORMAL;
            this.ctr.colorcode = Constants.getDefaultColor(Constants.NORMAL);
            this.ctr.aggregatorid = Constants.SWIGGY;
            this.ctr.defaultpaymentmode = 0;
            this.ctr.kotwithreceipt = false;
            this.ctr.keepopenonchkout = false;
            this.ctr.applyscheme = false;
            this.ctr.singlekot = false;
            this.ctr.printreceipt = true;
            this.ctr.active = true; 
            this.ctr.applyscheme = true;
            this.ctr.whitetext = false;

        }


        this.displayAddEditDialog = true;
    }
    
    
    
    clonectr(fctr: Billingcounter): Billingcounter
    {
        var ctr = <Billingcounter>{};
        for(let prop in fctr) {
            ctr[prop] = fctr[prop];
        }
        return ctr; 
     }
     
    onRowDoubleClick(event){
        this.showAddEditDialog(true);
    }
    
    onRowSelect(event) {
        this.enableEditButton =true;
    }
    
    onRowUnselect(event) {
        this.enableEditButton = false;
    }
    
    
    
    findselectedCounterIndex(): number {
        return this.billingCounterService.billingcounterlist.indexOf(this.selectedCounter);
    }
    
    getDialogWidth() : number{
       return 600;
    }
    
    getDialogHeight() : number{
        return 450;
    }

    getCounterNameForBulk(bulkcounter : Bulkbillingcounter,cursequence){
        if(cursequence < 10){
            return bulkcounter.prefix + ' 0' + cursequence;
        }else
            return bulkcounter.prefix + ' ' + cursequence;
    }   

    getShortNameForBulk(bulkcounter : Bulkbillingcounter,cursequence){
        if(cursequence < 10){
            return bulkcounter.shortnameprefix + ' 0' + cursequence;
        }else
            return bulkcounter.shortnameprefix + ' ' + cursequence;
    }   
    
    getCounterForBulk(bulkcounter : Bulkbillingcounter,cursequence){
        var ctr = <Billingcounter>{}

        ctr.countertype = bulkcounter.countertype;
        ctr.aggregatorid = bulkcounter.aggregatorid;
        
        ctr.defaultpaymentmode = bulkcounter.defaultpaymentmode;
        ctr.charge = 0;
        ctr.sectionname = bulkcounter.sectionname;
        ctr.countername = this.getCounterNameForBulk(bulkcounter,cursequence);
        ctr.shortname  = this.getShortNameForBulk(bulkcounter,cursequence);
        ctr.hotkey = this.getHotkeyForId(cursequence);
        ctr.chargelabel = '';
        ctr.kotwithreceipt = false;
        ctr.keepopenonchkout = false;
        ctr.applyscheme = false;
        ctr.singlekot = false;
        ctr.printreceipt = true;
        ctr.active = true;
        ctr.chargeinpercent = false;
        ctr.validatecustomer = false;
        ctr.validatedeliveryinfo = false;
        ctr.validatefullpayment = false;
        ctr.kotwithreceipt = false;
        ctr.keepopenonchkout = false;
        ctr.applyscheme = true;
        ctr.singlekot = false;
        ctr.dupkotconsolidated = false;
        ctr.dupkotkitchenspecific = false; 
        ctr.dupkoton = -1; 
        ctr.dupreceipton = -1; 
        ctr.receipton = bulkcounter.receipton;
        ctr.koton= bulkcounter.koton;
        ctr.colorcode = bulkcounter.colorcode;
        ctr.whitetext = bulkcounter.whitetext;

        return ctr;
        
    }

    async bulkCreateCounter(){
        this.msgs =[];
        this.isRunning = true;
        
        if(!this.bulkcreate.prefix  || this.bulkcreate.prefix  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Counter prefix can not be blank'});
            this.isRunning = false;
            return;
        }


        if(!this.bulkcreate.shortnameprefix  || this.bulkcreate.shortnameprefix  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Short name prefix can not be blank'});
            this.isRunning = false;
            return;
        }

        if(this.bulkcreate.shortnameprefix.length > 3 ){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Short name prefix length can not be greater than 3 characters'});
            this.isRunning = false;
            return;
        }

        if(!this.bulkcreate.rangestart  || isNaN(this.bulkcreate.rangestart) || isNaN(parseInt(this.bulkcreate.rangestart))){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Invalid start sequence!'});
            this.isRunning = false;
            return;
        }

        var rangestart = parseInt(this.bulkcreate.rangestart);
        if(rangestart  <=0 ){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Start sequence must be greater than 0!'});
            this.isRunning = false;
            return;
        }

        if(!this.bulkcreate.rangeend  || isNaN(this.bulkcreate.rangeend) || isNaN(parseInt(this.bulkcreate.rangeend))){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Invalid end sequence!'});
            this.isRunning = false;
            return;
        }

        var rangeend = parseInt(this.bulkcreate.rangeend);
        if(rangeend  <=0 ){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'End sequence must be greater than 0!'});
            this.isRunning = false;
            return;
        }

        if(rangeend <= rangestart){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'End sequence must be greater than start sequence' });
            this.isRunning = false;
            return;
        }

        if(!this.bulkcreate.sectionname  || this.bulkcreate.sectionname  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Section name can not be blank'});
            this.isRunning = false;
            return;
        }

        //Three Steps : Make all ready, Validate all finally create all 
        var counterscreated : Billingcounter[] = [];
        for(var i =rangestart; i <= rangeend; i++){
            counterscreated.push(this.getCounterForBulk(this.bulkcreate, i));
        }


        //Validate if we are creating duplicate
        for(var ctr of counterscreated){
            if(this.billingCounterService.checkDuplicate(ctr)){
                var message = 'Billing counter with same name (' + ctr.countername +  ') / shortname ('+ ctr.shortname + ') already exists!'
                this.msgs.push({severity:'error', summary:'Error:', detail: message });
                this.isRunning = false;
                return;
            }
    
    
            /*if(this.billingCounterService.checkDuplicateHotkey(ctr)){
                this.msgs.push({severity:'error', summary:'Error:', detail: 'Billing counter with hotkey (' + ctr.hotkey +  ') exists in the ' + ctr.sectionname  + ' section!'});
                this.isRunning = false;
                return;
            }*/
    
        }

        //Now go ahead and create all 
        for (var ctr of counterscreated){
            ctr.id = this.billingCounterService.getNewId();
            ctr.posid = this.dataService.getPosId();
            if(this.dataService.retailer.multilocation) ctr.posid = this.dataService.getPosWithLocationId();

            var retstatus = await this.billingCounterService.save(ctr);
            if(retstatus){
                
            }else{
                this.msgs.push({severity:'error', summary:'Error:', detail: 'Error creating counter ' + ctr.countername + ', please try again!'});
                this.isRunning = false;
                return;
            }
        }


        this.isRunning = false;
        this.displayBulkCreateDialog = false;
        this.isRunning = false;
        this.msgssummary.push({severity:'info', summary:'Success:', detail: 'All counters created successfully.'});
        

    }


    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }


    
    
    async save(dt : DataTable) 
    {
        this.msgs =[];
        this.isRunning = true;
            
        if(!this.ctr.countername  || this.ctr.countername  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Counter name can not be blank'});
            this.isRunning = false;
            return;
        }
        
        if(!this.ctr.shortname  || this.ctr.shortname  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Counter short name can not be blank'});
            this.isRunning = false;
            return;
        }

        if(!this.ctr.sectionname || this.ctr.sectionname.trim()  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Section name can not be blank'});
            this.isRunning = false;
            return;
        }


        if(!this.ctr.hotkey || this.ctr.hotkey.trim()  == ""){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Hot key can not be blank'});
            this.isRunning = false;
            return;
        }
        
        var hotkeycode = this.ctr.hotkey.charCodeAt(0);
        if((hotkeycode >= 48 && hotkeycode <=57) || (hotkeycode >= 96 && hotkeycode <=105) || (hotkeycode >= 65 && hotkeycode <= 90)){
        }else{
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Invalid hotkey. Must be between A-Z or 0-9'});
            this.isRunning = false;
            return;
        } 
            



        if(this.billingCounterService.checkDuplicate(this.ctr)){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Billing counter with same name / shortname already exists!'});
            this.isRunning = false;
            return;
        }


        /*if(this.billingCounterService.checkDuplicateHotkey(this.ctr)){
            this.msgs.push({severity:'error', summary:'Error:', detail: 'Billing counter with hotkey exists in the ' + this.ctr.sectionname  + ' section!'});
            this.isRunning = false;
            return;
        }*/



        if(!this.ctr.active) this.ctr.active = false;
        if(!this.ctr.chargeinpercent) this.ctr.chargeinpercent = false;
        if(!this.ctr.validatecustomer) this.ctr.validatecustomer = false;
        if(!this.ctr.validatedeliveryinfo) this.ctr.validatedeliveryinfo = false;
        if(!this.ctr.validatefullpayment) this.ctr.validatefullpayment = false;
        if(!this.ctr.kotwithreceipt) this.ctr.kotwithreceipt = false;
        if(!this.ctr.keepopenonchkout) this.ctr.keepopenonchkout = false;
        if(!this.ctr.applyscheme) this.ctr.applyscheme = false;
        if(!this.ctr.singlekot) this.ctr.singlekot = false;
        

        if(!this.isForEdit) this.ctr.id = this.billingCounterService.getNewId();
        this.ctr.posid = this.dataService.getPosId();
        if(this.dataService.retailer.multilocation) this.ctr.posid = this.dataService.getPosWithLocationId();

        
        var retstatus = await this.billingCounterService.save(this.ctr);
        
        //If true --> Coutner Added 
        if(retstatus != "ERROR" && retstatus != "CONFLICT"){
            this.isRunning = false;

            this.displayAddEditDialog = false; 
            this.enableEditButton = false;
            this.isRunning = false;
            this.showSuccessToast('Counter saved successfully.'); 
        }else{
            if(retstatus == 'ERROR') this.showErrorToast('Error, please try again.'); 
            else this.showErrorToast('You seem to be having an old copy, please refresh data!'); 
            this.isRunning = false;
        }
    
            
    }
    
}


