import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Appdataservice} from '../service/appdataservice';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';

import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../service/billingservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Userservice } from '../service/userservice';
import { User } from '../domain/user';

@Component({
    selector: 'reauth-dlg',
    templateUrl: './reauthdialog.html',

})
export class Reauthdialog {
    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private userService : Userservice, 
                private dataService : Appdataservice,
                public counterService : Billingcounterservice) {}

    

    username; 
    password; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    
    @ViewChild('userbox') txtUserName : ElementRef;
    @ViewChild('cardbox') txtCardId : ElementRef;

    cardid;
    cardAuth(){
		if(this.cardid && this.cardid.length > 0){
            //var reauthuser = await this.userService.getAuthorization(this.username, this.password);
            if(this.cardid.trim().toUpperCase() == this.reauthuser.usercode.trim().toUpperCase()){
                this.billingService.makeCardDialogVisible = false; 
                this.showNextDialog();
            }else{
                this.showErrorToast('Invalid card!');
            }
        }

	}



    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if((this.cardid && this.cardid.length > 0) || (this.username && this.username.length > 0)){
            if(event.keyCode == 13  ) {
                /*if(this.billingService.makeCardDialogVisible){
                    this.cardAuth();
                }else{*/
                    /*if(this.reauthuser && this.reauthuser.usercode && this.reauthuser.usercode.length > 20){
                        this.billingService.makeCardDialogVisible = true;
                        setTimeout(()=>{ this.txtCardId.nativeElement.focus();}, 100);
                    }else{    */
                        this.reAuth();
                    //}
                //}
            }
        }
    }

    
    closeDialog(){
        this.billingService.makeReauthDialogVisible = false;
        this.billingService.makeCardDialogVisible = false; 
    }
    showNextDialog(){
        

        this.closeDialog();
        switch(this.billingService.reauthpurpose){
            case Constants.PRIV_APPLY_DISCOUNT:
                this.billingService.makediscountvisible = true;
                break;
            case Constants.PRIV_CANCEL_ITEM:
                this.billingService.makeitemcancelDialogVisible = true;
                break; 
            case Constants.PRIV_CANCEL_KOT:
                this.billingService.makekotCancelDialogVisible = true;
                break; 
            case Constants.PRIV_DUPLICATE_PRINT:
                this.billingService.makeReprintDialogVisible = true;
                break;
            case Constants.PRIV_DUPLICATE_KOT:
                this.billingService.makeReprintDialogVisible = true;
                break;
        }
        
    }


    reauthuser : User; 

    async reAuth(){
        if((this.cardid && this.cardid.length > 0) || (this.username && this.username.length > 0)){
            this.reauthuser = await this.userService.getAuthorization(this.username, this.password,this.cardid);
            if(this.reauthuser){
                if(this.userService.checkReauthPriv(this.reauthuser,this.billingService.reauthpurpose)){
                    /*if(this.reauthuser && this.reauthuser.usercode && this.reauthuser.usercode.length > 20){
                        this.billingService.makeCardDialogVisible = true; 
                        setTimeout(()=>{ this.txtCardId.nativeElement.focus();}, 100);
                    }else{*/
                        this.showNextDialog();
                    //}
                }else{
                    this.showErrorToast('User does not have privilege!');
                }
            }else{
                this.showErrorToast('Invalid credentials!');
            }
        }
    }


    cardlogin;
    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');
        this.username = '';
        this.password = '';
        this.cardid = '';
        //this.billingService.makeCardDialogVisible = false;
        this.cardlogin = localStorage.getItem("cardlogin");
        if(!this.cardlogin)
        setTimeout(()=>{ this.setFocusOnName();}, 100);
		else
			setTimeout(()=>{this.txtCardId.nativeElement.focus();}, 100);


        
    }


    setFocusOnName(){
        this.txtUserName.nativeElement.focus();
    }
    
    
    ngOnInit(){

        
    }


    
}
