
import { openDB, deleteDB, wrap, unwrap } from 'idb';
import {Injectable} from '@angular/core';
import { Constants } from '../domain/constants';
import { DataService } from './dataservice';
import { Appdataservice } from './appdataservice';
import { Http, RequestOptions,Headers } from '@angular/http';
import { restoreView } from '@angular/core/src/render3';


@Injectable()
export class Couchdbservice{

    public serverip; 
    public serverauth; 
    
    constructor (public dataService : Appdataservice,private http: Http){
        this.serverip = null;
    }

    public timeoffset = 0;  

    async putInDB(url,body,auth)  {
        try{

            let headers = new Headers ({ 'Content-Type': 'application/json', authorization : auth });
            let options = new RequestOptions({ headers: headers });
            
            if(Constants.DEBUG_MODE) console.log('|PUTITEM|*** HTTP POST URL: '  + url + ' Body ' + JSON.stringify(body));
            const retpromise = await this.http.put(url,body,options).toPromise();
            if(Constants.DEBUG_MODE) console.log('**Return: '  + JSON.stringify(retpromise.json()));
            return retpromise.json();
            
        }catch(err){
            if(Constants.DEBUG_MODE) console.error('Error in fetching data from: ' + url + ' -- ' + err.toString());
            if(err.toString().toUpperCase().indexOf("CONFLICT") >= 0) return "CONFLICT";
            return "ERROR";
        }
    }


    //Store without partition
    public async readGlobalStore(storename){
        var couchURL= "http://" + this.serverip + ":5984/" + storename + "/_find";
        var body  = {
            "selector": {
             },
             "skip": 0
         };

        var retstatus = await this.dataService.syncHttp(couchURL,body,'','POST',this.serverauth);
        return retstatus;

    }

    
    //Get all items 
    public async getAllItems(storename,posid?){
        var partitionkey = this.dataService.getPosId();
        if(posid && posid.length > 0) partitionkey = posid; 

        var couchURL= "http://" + this.serverip + ":5984/" + storename + "/_partition/" + partitionkey + "/_find";
        console.log('MIGRATION| COUCHURL' + couchURL);
        var body  = {
            "selector": {
             },
             "skip": 0,
             "limit" : 30000
         };

        var retstatus = await this.dataService.syncHttp(couchURL,body,'','POST',this.serverauth);
        return retstatus;
        
    }

    //Get all items 
    public async getFromIndex(storename,selectorjson,sortjson? , limit? ){
        
        
        var partitionkey = this.dataService.getPosId();
        
        var couchURL= "http://" + this.serverip + ":5984/" + storename + "/_partition/" + partitionkey + "/_find";
        var body;

        if(sortjson && sortjson.length > 0){
            body  = {"selector": selectorjson, "sort" : sortjson, "skip": 0,"limit" : limit};
        }else{
            body = {"selector": selectorjson,"skip": 0,"limit" : 3000};
        }

        var retstatus = await this.dataService.syncHttp(couchURL,body,'','POST',this.serverauth);
        return retstatus;
    }
    
    

    //Get all items 
    public async getFromIndexPosID(storename,selectorjson,posid){
        
        
        var partitionkey = posid;
        
        var couchURL= "http://" + this.serverip + ":5984/" + storename + "/_partition/" + partitionkey + "/_find";
        var body;

        body = {"selector": selectorjson,"skip": 0,"limit" : 20000};
    
        var retstatus = await this.dataService.syncHttp(couchURL,body,'','POST',this.serverauth);
        return retstatus;
    }
    

    //Get all items 
    public async getTopItemsFromIndex(storename, indexname, countitems, isreverse ){
    
    }

    //Get all items 
    public async getFromRangeIndex(storename, indexname, lowerrangevalue, upperrangevalue ){
        
    }

    //Empty Store 
    public async clearStore(storename){

    }

    //Get all items in indexed order 
    public async putItem(storename, item) {
        item.updatedon = new Date().getTime() + this.timeoffset;
        var partitionkey = this.dataService.getPosId();
        
        var couchURL= "http://" + this.serverip + ":5984/" + storename + "/" + partitionkey + ":" + item.id;
        var retstatus = await this.putInDB(couchURL,item,this.serverauth);
        
        if(retstatus && retstatus.ok) {
            item._rev = retstatus.rev; //update the local revision with what is supplied by server
            return "DONE";
        }else{
            if(Constants.DEBUG_MODE) console.error ('|COUCH|Error writing in DB' );
            return retstatus;
        }
        
    }


    public async getItem(storename, id) {
        var partitionkey = this.dataService.getPosId();
        var couchURL= "http://" + this.serverip + ":5984/" + storename + "/" + partitionkey + ":" + id;
        var retstatus = await this.dataService.syncHttp(couchURL,'','','GET',this.serverauth);
        return retstatus;
    
    }

    
    public async removeItem(storename, key) {

    }

    public async bulkPut(storename, items) {
    
    }
    
    
}
