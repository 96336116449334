import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';	
import {HostListener} from '@angular/core';
import {DataService} from '../service/dataservice';
import {Constants} from '../domain/constants';
import { environment } from '../../environments/environment';
import { QRCodeComponent, QRCodeModule } from 'angularx-qrcode';

import {Button, MessageService, SelectItem} from 'primeng/primeng';
import {InputText,Messages,Message,Growl} from 'primeng/primeng';
import { DatePipe } from '@angular/common';
import { Appdataservice } from '../service/appdataservice';
import { Settingsservice } from '../service/settingsservice';
import { Deliveryboyservice } from '../service/deliveryboyservice';
import { Printerservice } from '../service/printerservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Usergroupservice } from '../service/usergroupservice';
import { Userservice } from '../service/userservice';
import { Couchdbservice } from '../service/couchdbservice';
import { Aggregatorservice } from '../service/aggregatorservice';
import { Discountpartnerservice } from '../service/discountpartnerservice';
import { Shiftendservice } from '../service/shiftendservice';
import { Utils } from '../service/utils';
import { Qrorderservice } from '../service/qrorderservice';
import { Billingservice } from '../service/billingservice';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Callcenterservice } from '../service/callcenterservice';
import { Urbanpiperservice } from '../service/urbanpiperservice';

@Component({
	selector: 'login-start',	
	templateUrl : 'login.html'
})
export class LoginCmp implements OnInit{


	@ViewChild('emailid') txtEmailIdBox :  ElementRef;

	@ViewChild('cardbox') txtCardBox :  ElementRef;

	public email: string;
	public password: string;
	value; //Value of progress bar 

	loginasadmin;
	ngOnInit(){
		
		this.qrInfo = "www.google.com";
		if(localStorage.getItem('email') != null && localStorage.getItem('email').length > 0){
			this.email = localStorage.getItem('email');
			this.password = localStorage.getItem('pass');
			//this.login();
		}
		this.cardlogin = localStorage.getItem("cardlogin");
		if(!this.cardlogin)
			setTimeout(()=>{this.txtEmailIdBox.nativeElement.focus();}, 50);
		else
			setTimeout(()=>{this.txtCardBox.nativeElement.focus();}, 100);


		this.value = 0;
		/*this.email = 'ihc@gmail.com';
		this.password = 'cafe123';
		this.login();*/
	}



	public isRequesting: boolean;
	constructor( private _router : Router, 
				public datePipe : DatePipe,
				public messageService : MessageService,
				private settingsService : Settingsservice,
				private deliveryboyService : Deliveryboyservice,
				private billingcounterService : Billingcounterservice,
				public billingService : Billingservice,
				public dynamodbService : Dynamodbservice,
				
				private usergroupService : Usergroupservice,
				private shiftendService : Shiftendservice,
				private couchDBService : Couchdbservice,
				private userService : Userservice,
				private printerService : Printerservice,
				private qrOrderingService : Qrorderservice,
				private ccService : Callcenterservice,
				public upService : Urbanpiperservice,
				
				private kitchenService : Kitchenservice,
				private aggregatorService : Aggregatorservice,
				private discountpartnerService : Discountpartnerservice, 
				public dataService : Appdataservice
		)
	{
		
	}
	

	setAdminLogin(){
		this.cardid = '';
		this.loginasadmin = true;
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
	   if(event.keyCode == 13 ){
		   //if(this.billingService.makeCardDialogVisible == false){
				if((this.cardid && this.cardid.length > 0) || (this.email && this.email.length > 0 && this.password && this.password.length > 0)){
						this.login(); 
				}
			/*}else{
				this.cardAuth();
			}*/
	   }
	}

	showErrorToast(message){
		this.isRunning = false; 
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'logintoast', closable: true, summary: 'Error', detail:message});
	}
	
	
	checkAdmin(){
		if(this.email && this.email.indexOf('@')>0) return true;
		return false; 
	}

	cardid;

	navigateToBillingSCreen(){
		console.log('|LOGIN|Navigating to billing screen');
		
		this.dataService.isuserloggedin = true;
		localStorage.setItem('email' , this.email);
		localStorage.setItem('pass' , this.password);
		this.billingService.makeCardDialogVisible = false;  
		this._router.navigate(['/billingscreen']);	

	}

	cardAuth(){
		if(this.cardid && this.cardid.length > 0){
            //var reauthuser = await this.userService.getAuthorization(this.username, this.password);
            if(this.cardid.trim().toUpperCase() == this.userService.loggedinuser.usercode.trim().toUpperCase()){
                    this.navigateToBillingSCreen();
            }else{
                this.showErrorToast('Invalid card!');
            }
        }

	}

	cardlogin;
	
	isRunning; 


	async createMapping(){
        var custjson = await this.dataService.getCustomerDataFile('qrmap.json');
        for(var item of custjson.qrmap){
            
            
            var ret  = await this.dynamodbService.putItem('qrtablemap', item);
            console.log('MAPPING CREATED --> ' + item.qrid + ' Pos ID ' + item.posid );
        }

    }
	
	async importCoupons(){
        var custjson = await this.dataService.getCouponCodes();
        for(var item of custjson['IP CODE']){
            var ret  = await this.dynamodbService.putItem('gulfnewscode', item);
            console.log('Cust imported --> |' + item.code + '| Pos ID ' + item.usagestatus );
        }

    }

	qrInfo;
    async login() {
		this.isRunning = true; 
		this.value = 0;
		var response = false; 
		var userinitdone = false; 
		
		if(this.checkAdmin()){
			response = await this.dataService.initializeAppData(this.email, this.password);

			if(response){
				
				this.dataService.isadminlogin = true; 
				if(this.password.length > 15)  this.dataService.ismainadmin = true; 
				if(!this.dataService.ismainadmin){
					if(!this.dataService.validateInitialization()){
						this.isRunning = false;		
						this.showErrorToast('Terminal not initialized, please contact admin for the same!');
						return;
					}
				}

			} 
		}else{
			this.dataService.isadminlogin = false; 

			
			if(this.loginasadmin){
				this.isRunning = false;		
				this.showErrorToast('You can login only as admin user on this terminal!');
				return;
			}	

			var curdata = JSON.parse(localStorage.getItem('loginresponse'));
			if(curdata && curdata.status == 0){
				console.log('** APP CONFIG --> ' + curdata.retailer.appconfig.posconfig.couchdbip);
				this.dataService.parseServerResponse(curdata);

				//Set thecouch db server ip 
				this.couchDBService.serverip = this.dataService.retailer.appconfig.posconfig.couchdbip; 
				this.couchDBService.serverauth = this.dataService.retailer.appconfig.posconfig.couchdbauth;

				if(!this.dataService.validateInitialization()){
					this.isRunning = false;		
					this.showErrorToast('Terminal not initialized, please contact admin for the same!');
					return;
				}	

				//console.log('Importing couoons ') ; 
				//await this.importCoupons();
				
	
				if(this.email && this.email.toLowerCase() == 'kitchen' && this.password && this.password.toLowerCase() == 'kitchen'){
					response = true;  
					await this.kitchenService.initKitchens();
					await this.userService.initUsers();
					this.dataService.iskdslogin = true; 
					this.kitchenService.kitchenid =1;
					this.kitchenService.setKitchenName();
					
					this.kitchenService.startKOTPollingOnKDS();
				}else if(this.email && this.email.toLowerCase() == 'kitchen2' && this.password && this.password.toLowerCase() == 'kitchen2'){
					response = true;  
					await this.userService.initUsers();
					await this.kitchenService.initKitchens();
					this.dataService.iskdslogin = true; 
					this.kitchenService.kitchenid =2;
					this.kitchenService.setKitchenName();
					this.kitchenService.startKOTPollingOnKDS();
				}else if(this.email && this.email.toLowerCase() == 'kitchen3' && this.password && this.password.toLowerCase() == 'kitchen3'){
					response = true;  
					await this.userService.initUsers();
					await this.kitchenService.initKitchens();

					this.dataService.iskdslogin = true; 
					this.kitchenService.kitchenid =3;
					this.kitchenService.setKitchenName();
					
					this.kitchenService.startKOTPollingOnKDS();
				}else{
					this.dataService.iskdslogin = false; 
					response = await  this.userService.loginOfflineUser(this.email,this.password,this.cardid);
				}

				//Start QR Polling service 
				if(this.dataService.iskdslogin == false && this.dataService.retailer.appconfig.posconfig.qrprocessing){
					this.qrOrderingService.startQROrderpolling();
				}



			}else{
				this.showErrorToast('Please login with admin login for the first time!');
			}
		}

		this.value= 50;

		if(response){
			console.log('LOGGED IN***********');
			
			this.settingsService.initService();
			
			//Set thecouch db server ip 
			this.couchDBService.serverip = this.dataService.retailer.appconfig.posconfig.couchdbip; 
			this.couchDBService.serverauth = this.dataService.retailer.appconfig.posconfig.couchdbauth;

			var ret= await this.deliveryboyService.initDeliveryBoys();
			this.value = 55;

			if(ret) ret = await this.aggregatorService.initAggregators();			
			this.value = 60;
			
			if(ret) ret= await this.printerService.initPrinters();
			this.value = 65;
			
			if(ret) ret= await this.kitchenService.initKitchens();
			this.value = 70;
			
			if(ret) ret = await this.billingcounterService.initBillingcounters();
			this.value = 75;
			
			if(ret) ret = await this.usergroupService.initUsergroups();
			this.value = 80;
			
			if(ret) ret = await this.userService.initUsers();
			this.value = 90;
			
			if(ret && (this.dataService.retailer.id == 1338 || this.dataService.retailer.id == 1343) ){
				//await this.createMapping();
				ret = await this.qrOrderingService.initTableMap();

			}
			
			this.value = 95;
			

			if(ret) ret = await this.discountpartnerService.initDiscountpartners();
			this.value = 100;
			
			
			if(!ret){ 
				this.showErrorToast('Error in fetching data, please try again!'); 
				
				return;
			}

			//Set the usergroup of logged in user
			if(!this.dataService.isadminlogin && !this.dataService.iskdslogin) await this.userService.setCurrentUserGroup();
			
			
			//Start QR Polling service 
			if(this.dataService.ccenabled && this.userService.callcenterallowed){
				this.ccService.ccordersinprocess = []; //Initized .. keep calling 
				this.ccService.setPendingOrders();
			}


			//Start QR Polling service 
			if(this.dataService.retailer.appconfig.posconfig.urbanpiper){
				console.log('SEtting UP SERVICE..');
				this.upService.uporderinprocess = [];
				this.upService.setPendingOrders();
			}

			
			//Set the mobile display counter list for captain 
			//this.userService.setMobileDisplayList();

			if(this.dataService.iskdslogin){
				this.dataService.isuserloggedin = true; 
				this._router.navigate(['/kitchendisplay']);		
				return;
			}

			//Get last EOD date 
			var lasteoddate = await this.shiftendService.getLastEOD();
			console.log('|LAST|Last EOD ' + lasteoddate);
			if(lasteoddate == -1){
				this.showErrorToast('Error reading last EOD, please login again!'); return;
			}else{
				this.dataService.lasteoddate = lasteoddate;
				this.dataService.strlasteoddate = Utils.getBusinessDateAsString("" + lasteoddate);
				this.dataService.curbsndate = Utils.getBusinessDateAsString("" + this.dataService.getCurrentBusinessDate());
			}

				
			this.isRunning = false;		
			
			if( !this.dataService.isadminlogin && this.userService.loggedinuser && this.userService.loggedinuser.usercode && this.userService.loggedinuser.usercode.length > 20){
				
				console.log('DISPLAY ING CARD AUTH...	');
				this.billingService.reauthpurpose = Constants.FOR_LOGIN;
				this.billingService.makeCardDialogVisible = true; 

				setTimeout(()=>{this.txtCardBox.nativeElement.focus();}, 100);
				
				return;

			}else{
				this.navigateToBillingSCreen();	
			}
			
		}else{
			this.isRunning = false;		
			this.showErrorToast('Error in login!');
		}
	}


}

