import { Injectable } from "@angular/core";
import { Consumercart } from "../domain/consumercart";
import { Cartlineitem } from "../domain/cartlineitem";
import { Order } from "../domain/order";
import { DataService } from "./dataservice";
import { Appdataservice } from "./appdataservice";
import { Dynamodbservice } from "./dynamodbservice";
import { Constants } from "../domain/constants";
import { Billingcounter } from "../domain/billingcounter";
import { Couchdbservice } from "./couchdbservice";
import { Printingservice } from "./printingservice";
import {Receiptno} from  "../domain/receiptno";
import { Userservice } from "./userservice";
import { Shiftendrecord } from "../domain/shiftendrecord";
import { Shiftno } from "../domain/shiftno";

@Injectable()
export class Shiftendservice  {

    constructor (
            public dataService : Appdataservice,
            public userService : Userservice,
            private dynamoDBService : Dynamodbservice,
            public printingService : Printingservice,
            public couchDBService : Couchdbservice,    
        ) {
    }


     
    clearCart(){
         //this.cart.initCart();
    }


    async getLastShift(){
        try{
            //this.billingcounterlist = [];
            var retlist; 
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and businessdate = :v_curdate ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_curdate":  this.dataService.getCurrentBusinessDate()};
                retlist =  <Shiftendrecord[]> await this.dynamoDBService.queryOnIndexDesc('shifts', "posid-businessdate-index", conditionexpression,attribparams,1);
                if(retlist && retlist.length == 1){
                    return retlist[0];
                }else
                    return null;
            }else{
                var selector =  {"businessdate": { "$eq": this.dataService.getCurrentBusinessDate()}};
                var sort =  [{"createdon": "desc"}];

                if(Constants.DEBUG_MODE) console.log('|SHIFTCHANGE|Fetching from couch');
                var retlist = await this.couchDBService.getFromIndex('shifts',selector,sort,1);
                if(retlist && retlist.docs.length == 1){
                    return retlist.docs[0];
                }else 
                    return null;
            }
        }catch(err){
            console.error('ERror fetching Orders ' + err);
            return null;
        }

    }

    async getEODForDate(eoddate){
        var conditionexpression = "posid = :v_retailerid and eoddate = :v_start ";  
        var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_start":  eoddate};
        var retlist =  <Shiftendrecord[]> await this.dynamoDBService.queryOnIndexDesc('shifts', "posid-eoddate-index", conditionexpression,attribparams,1);
        if(retlist && retlist.length == 1){
            return retlist[0];
        }else
            return null;
    }

    async getLastEOD(){
        try{
            //this.billingcounterlist = [];

            var retlist; 
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and eoddate >= :v_start ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_start":  this.dataService.getEODCheckDate()};
                retlist =  <Shiftendrecord[]> await this.dynamoDBService.queryOnIndexDesc('shifts', "posid-eoddate-index", conditionexpression,attribparams,1);
                if(retlist && retlist.length == 1){
                    return retlist[0].eoddate;
                }else
                    return null;
            }else{
                var selector =  {"businessdate": { "$gte": this.dataService.getEODCheckDate()}};
                var sort =  [{"eoddate": "desc"}];

                if(Constants.DEBUG_MODE) console.log('|SHIFTCHANGE|Fetching from couch');
                retlist = await this.couchDBService.getFromIndex('shifts',selector,sort,1);
                if(retlist && retlist.docs.length == 1){
                    return retlist.docs[0].eoddate;
                }else 
                    return null;
            }
        }catch(err){
            console.error('ERror Reading EOD ' + err);
            return -1;
        }

    }


    async endShift(shiftendrecord : Shiftendrecord){

        shiftendrecord.shiftno = await this.getNewShiftNo();
        if(shiftendrecord.shiftno == 'ERR') return false; 

        shiftendrecord.id = new Date().getTime();
        var status;
        if(this.dataService.usedynamo){
            shiftendrecord.posid = this.dataService.getPosId();
            status= await this.dynamoDBService.putItem('shifts',shiftendrecord);
        }else
            status= await this.couchDBService.putItem('shifts',shiftendrecord);

        if(status == "DONE" ){
            return true;
        }else{
            return false;
        }

    }


    async updateEOD(shiftendrecord : Shiftendrecord){
        var status= await this.dynamoDBService.putItem('shifts',shiftendrecord);
        if(status == "DONE" ){
            return true;
        }else{
            return false;
        }

    }

    /*async endDay(shiftendrecord : Shiftendrecord){

        shiftendrecord.shiftno = await this.getNewShiftNo();
        if(shiftendrecord.shiftno == 'ERR') return false; 

        shiftendrecord.createdon = new Date().getTime();
        shiftendrecord.id = new Date().getTime();
        
        var status= await this.couchDBService.putItem('endDay',shiftendrecord);
        if(status == "DONE" ){
            return true;
        }else{
            return false;
        }
    }*/

    async getNewShiftNoWithoutUpdate() 
    {
        var retryattempt =0;
        while(retryattempt < 3){
                if(Constants.DEBUG_MODE) console.log('*** Fetching token attempt :: ' + retryattempt);
                //var receiptnorec = <Shiftno> await this.couchDBService.getItem('shiftnos',this.dataService.getPosId());
                var receiptnorec;
                var key  = {"posid": "" + this.dataService.getPosId()};

                if(this.dataService.usedynamo){
                    receiptnorec = <Shiftno> await this.dynamoDBService.getItem('shiftnos',key);
                }else{
                    receiptnorec = <Shiftno> await this.couchDBService.getItem('shiftnos',this.dataService.getPosId());
                }

                if(receiptnorec == null ){
                }else{
                    var newtoken = receiptnorec.shiftno + 1;
                    return  newtoken;
                }
        }
        return -1;

    }


    
    async getNewShiftNo() 
    {
        var retryattempt =0;

        while(retryattempt < 3){
                if(Constants.DEBUG_MODE) console.log('*** Fetching token attempt :: ' + retryattempt);
                //var receiptnorec = <Shiftno> await this.couchDBService.getItem('shiftnos',this.dataService.getPosId());
                var receiptnorec;
                var key  = {"posid": "" + this.dataService.getPosId()};

                if(this.dataService.usedynamo){
                    receiptnorec = <Shiftno> await this.dynamoDBService.getItem('shiftnos',key);
                }else{
                    receiptnorec = <Shiftno> await this.couchDBService.getItem('shiftnos',this.dataService.getPosId());
                }


                
                if(receiptnorec == null ){
                    /*var tok = <Shiftno>{};
                    tok.posid = this.dataService.getPosId();
                    tok.shiftno = 1; 
                    tok.id = this.dataService.getPosId();
                    
                    var status;
                    if(this.dataService.usedynamo)
                        status = await this.dynamoDBService.putItem('shiftnos',tok);
                    else
                        status = await this.couchDBService.putItem('shiftnos',tok);

                    if(status == 'DONE'){
                        return '1';
                    }*/
                }else{
                    var curtoken = receiptnorec.shiftno;
                    var newtoken = receiptnorec.shiftno + 1;
                    receiptnorec.shiftno = newtoken;
                    //var status = await this.couchDBService.putItem('shiftnos',receiptnorec);

                    var status;
                    if(this.dataService.usedynamo){
                        var conditionexpression = "posid = :posid  and shiftno = :oldtoken ";  
                        var attribparams= {":newtoken" : newtoken, ":oldtoken" : curtoken, ":posid" : this.dataService.getPosId()};

                        var updateexpression = "set shiftno = :newtoken";

                        status = await this.dynamoDBService.updateItem('shiftnos',updateexpression,conditionexpression,attribparams,key);
                    
                    }else
                        status = await this.couchDBService.putItem('shiftnos',receiptnorec);

                    if(status == 'DONE'){
                        return '' + newtoken;
                    }
                }

                retryattempt = retryattempt + 1;
        }
        return 'ERR';

    }


    //Service for reports 
    async getShiftendList(fromdate, todate){
        try{

            var retlist; 
            if(this.dataService.usedynamo){
                var conditionexpression = "posid = :v_retailerid and businessdate BETWEEN :v_start and :v_end ";  
                var attribparams= {":v_retailerid" : this.dataService.getPosId() ,":v_start":  fromdate , ":v_end" : todate};
                return await this.dynamoDBService.queryOnIndex('shifts', "posid-businessdate-index", conditionexpression,attribparams);

            }else{
                var selector =  {"businessdate": { "$gte": fromdate, "$lte" : todate}};
                retlist =  await this.couchDBService.getFromIndex('shifts',selector);
                if(retlist && retlist.docs){
                    return retlist.docs;
                }else 
                    return null;
            }
            
        
            
        }catch(err){
            console.error('ERror fetching Orders ' + err);
            return null;
        }
    }
    


}