import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Appdataservice} from '../service/appdataservice';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';

import {HostListener} from '@angular/core';

import { Cartservice } from '../posservice/cartservice';
import { Billingservice } from '../service/billingservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Userservice } from '../service/userservice';
import { Router } from '@angular/router';
import { LoginCmp } from './login';

@Component({
    selector: 'card-dlg',
    templateUrl: './carddialog.html',

})
export class Carddialog {
    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private _router : Router, 
                private userService : Userservice, 
                private dataService : Appdataservice,
                public counterService : Billingcounterservice) {}

    

    username; 
    password; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    
    @ViewChild('userbox') txtUserName : ElementRef;

    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.username && this.username.length > 0){
            if(event.keyCode == 13  ) {
                  this.reAuth();
            }
        }
    }

    
    closeDialog(){
        this.billingService.makeCardDialogVisible = false;
    }
    showNextDialog(){
        this.closeDialog();
        console.log('this.billingService.reauthpurpose --> ' + this.billingService.reauthpurpose);
        switch(this.billingService.reauthpurpose){
            
                
            case Constants.PRIV_APPLY_DISCOUNT:
                this.billingService.makediscountvisible = true;
                break;
            case Constants.PRIV_CANCEL_ITEM:
                this.billingService.makeitemcancelDialogVisible = true;
                break; 
            case Constants.PRIV_CANCEL_KOT:
                this.billingService.makekotCancelDialogVisible = true;
                break; 
            case Constants.PRIV_DUPLICATE_PRINT:
                this.billingService.makeReprintDialogVisible = true;
                break;
            case Constants.PRIV_DUPLICATE_KOT:
                this.billingService.makeReprintDialogVisible = true;
                break;
        }
        
    }
    
    async reAuth(){
        if(this.username && this.username.length > 0){
            //var reauthuser = await this.userService.getAuthorization(this.username, this.password);
            if(this.username.trim().toUpperCase() == this.userService.loggedinuser.usercode.trim().toUpperCase()){
                    this.showNextDialog();
            }else{
                this.showErrorToast('Invalid card!');
            }
        }
    }


    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');
        this.username = '';
        this.password = '';
        setTimeout(()=>{ this.setFocusOnName();}, 100);
    }


    setFocusOnName(){
        this.txtUserName.nativeElement.focus();
    }
    
    
    ngOnInit(){

        
    }


    
}
