import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {LoginCmp} from './view/login';
import { Orderprocessing } from './view/orderprocessing';
import { Setaggregatorstatus } from './view/setaggregatorstatus';
import { Deliveryboys } from './view/deliveryboys';
import { Onlineordersreport } from './view/onlineordersreport';
import { Customerlist } from './view/customerlist';
import { Printers } from './view/printers';
import { Kitchens } from './view/kitchens';
import { Billingcounters } from './view/billingcounters';
import { Usergroups } from './view/usergroups';
import { Users } from './view/users';
import { Billingscreen } from './view/billingscreen';
import { Kotdetails } from './view/reports/kotreports/kotdetails';
import { Kotcancellations } from './view/reports/kotreports/kotcancellations';
import { Kotitemcancellations } from './view/reports/kotreports/kotitemcancellations';
import { Orderdetails } from './view/reports/salesreports/orderdetails';
import { Paymentdetails } from './view/reports/paymentreports/paymentdetails';
import { Paymentsummary } from './view/reports/paymentreports/paymentsummary';
import { Salessummary } from './view/reports/salesreports/salessummary';
import { Itemsalessummary } from './view/reports/salesreports/itemsalesssummary';
import { Ordermanagement } from './view/ordermanagement';
import { Alertreport } from './view/reports/alertreport';
import { Cashierchange } from './view/cashierchange';
import { Tillreport } from './view/reports/tillreport';
import { Processeod } from './view/processeod';
import { Dashboard } from './view/reports/dashboard';
import { Mobilebilldialog } from './view/mobilebilldialog';
import { Kitchendisplay } from './view/kitchendisplay';
import { Counterview } from './view/counterview';
import { Deliverymanagement } from './view/deliverymanagement';
import { Businesssummaryreport } from './view/reports/businesssummaryreport';
import { Schemeitemdetails } from './view/reports/salesreports/schemeitemdetails';
import { Datewisedineinanalysis } from './view/reports/salesreports/datewisedineinanalysis';
import { Qrprocessing } from './view/qrprocessing';
import { Orderswithscheme } from './view/reports/salesreports/orderswithscheme';
import { Buildsummary } from './view/reports/buildsummary';
import { Customizationreport } from './view/reports/salesreports/customizationreport';
import { Focustransdetail } from './view/reports/salesreports/focustransdetail';
import { Migratetocouch } from './view/migratetocouch';
import { Syncfromcouch } from './view/syncfromcouch';
import { Shortsummaryreport } from './view/reports/shortsummaryreport';
import { Taxdetails } from './view/reports/salesreports/taxdetails';
import { Menuanalysis } from './view/reports/salesreports/menuanalysis';
import { Kotbillreco } from './view/reports/kotreports/kotbillreco';
import { Customerfeedback } from './view/reports/customerfeedback';
import { Serverperformance } from './view/reports/serverperformance';
import { Pendingorderlist } from './view/pendingorderlist';
import { Branddashboard } from './view/branddashboard';
import { Reprocesseod } from './view/reprocesseod';
import { Onlineorderlist } from './view/onlineorderlist';
import { Menuanalysisdaterange } from './view/reports/salesreports/menuanalysisdaterange';



export const routes: Routes = [
    {path: '', component: LoginCmp},
    {path: 'orderprocessing', component: Orderprocessing},
    {path: 'statusupdate', component: Setaggregatorstatus},
    {path: 'deliveryboys', component: Deliveryboys},
    {path: 'printers', component: Printers},
    {path: 'kitchens', component: Kitchens},
    {path: 'billingcounters', component: Billingcounters},
    {path: 'usergroups', component: Usergroups},
    {path: 'onlineordersreport', component: Onlineordersreport},
    {path: 'orderdetails', component: Orderdetails},
    {path: 'ordermanagement', component: Ordermanagement},
    {path: 'alertreport', component: Alertreport},
    {path: 'salessummary', component: Salessummary},
    {path: 'itemsalessummary', component: Itemsalessummary},
    {path: 'paymentdetails', component: Paymentdetails},
    {path: 'customerlist', component: Customerlist},
    {path: 'users', component: Users},
    {path: 'billingscreen', component: Billingscreen},
    {path: 'paymentsummary', component: Paymentsummary},
    {path: 'kotdetails', component: Kotdetails},
    {path: 'kotcancellations', component: Kotcancellations},
    {path: 'kotitemcancellations', component: Kotitemcancellations},
    {path: 'cashierchange', component: Cashierchange},
    {path: 'tillreport', component: Tillreport},
    {path: 'processeod', component: Processeod},
    {path: 'dashboard', component: Dashboard},
    {path: 'mobilebilldialog', component: Mobilebilldialog},
    {path: 'kitchendisplay', component: Kitchendisplay},
    {path: 'counterview', component: Counterview},
    {path: 'deliverymanagement', component: Deliverymanagement},
    {path: 'businesssummaryreport', component: Businesssummaryreport},
    {path: 'schemeitemdetails', component: Schemeitemdetails},
    {path: 'datewisedineinanalysis', component: Datewisedineinanalysis},
    {path: 'qrprocessing', component: Qrprocessing},
    {path: 'orderswithscheme', component: Orderswithscheme},
    {path: 'buildsummary', component: Buildsummary},
    {path: 'customizationreport', component: Customizationreport},
    {path: 'focusdetail', component: Focustransdetail},
    {path: 'migratetocouch', component: Migratetocouch},
    {path: 'syncfromcouch', component: Syncfromcouch},
    {path: 'shortsummaryreport', component: Shortsummaryreport},
    {path: 'taxdetails', component: Taxdetails},
    {path: 'menuanalysis', component: Menuanalysis},
    {path: 'menuanalysisdaterange', component: Menuanalysisdaterange},
    {path: 'kotbillreco', component: Kotbillreco},
    {path: 'customerfeedback', component: Customerfeedback},
    {path: 'serverperformance', component: Serverperformance},
    {path: 'pendingorderlist', component: Pendingorderlist},
    {path: 'onlineorderlist', component: Onlineorderlist},
    {path: 'branddashboard', component: Branddashboard},
    {path: 'reprocesseod', component: Reprocesseod},
    
    
    
    
    
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'});
