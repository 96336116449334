import {Scheme} from './scheme';
import { SelectItem } from 'primeng/api';

export class Constants{

    //version 
    public static APP_VERSION="8.4.6";
    public static DEBUG_MODE : boolean = true;

        
    //Scheme run on 
    public static ALL_PRODUCTS : Number =0;
    public static SPECIFIC_SUBCATEGORY : Number =1;
    public static SPECIFIC_PRODUCT : Number =2;
    
    /** SCHEME CONSTANTS **/
    public static SCHEME_ON_ALL_PRODUCTS : Number = 0;
    public static SCHEME_ON_CATEGORY : Number = 1;
    public static SCHEME_ON_SUBCATEGORY : Number = 2;
    public static SCHEME_ON_PRODUCT : Number = 3;
    public static SCHEME_ON_ENTIREBILL : Number = 1;
    public static SCHEME_ON_LINEITEMS : Number = 2;
    /** SCHEME CONSTANTS **/

    //Scheme Priority
    public static LOW : Number =0;
    public static MEDIUM : Number =1;
    public static HIGH :  Number =2;    
    public static VERY_HIGH : Number =3;
    
    //DISPLAY TO GENDER 
    public static ALL : Number =0;
    public static MALE : Number =1;
    public static FEMALE : Number =2;

    //Active in-active
    public static  ACTIVE : Number = 0;
    public static  INACTIVE : Number = 1;
        
    
    //YES NO FLAG 
    public static  YES : Number = 1;
    public static  NO : Number = 2;
    
    
    //ORDER STATUS 
    public static  NEW_ORDER : Number = 0;
    public static  CONFIRMED_ORDER : Number = 1;
    public static  OUT_FOR_DELIVERY : Number = 2;
    public static  ORDER_EXECUTED : Number = 3;
    public static  ORDER_REJECTED : Number = 4;
    public static  ORDER_CANCELLED : Number = 5;
    
    //payment mode
    public static CASH_PAYMENT : Number = 0;
    public static CARD_PAYMENT : Number = 1;
    public static POS_PAYMENT : Number = 2;
    

    //inventory types
    public static SALE_PURCHASE_BOTH : Number = 0;
    public static SALE_ONLY : Number = 1;
    public static PURCHASE_ONLY : Number = 2;
    public static KITCHEN_PROCESSED : Number = 3;
    
    //functions 
    public static POS_ENABLED = 0;
    public static POS_INV_ENABLED = 1;
    public static POS_CLOUD_ENABLED = 2;
    public static SERVICE_APP_ENABLED = 3;
    public static KITCHEN_APP_ENABLED = 4;
    public static CONSUMER_APP_ENABLED = 5;
    
    //GST Type 
    public static SAME_FOR_ALL_PRODUCTS = 0;
    public static CATEGORY_WISE = 1;
    public static PRODUCT_WISE = 2;
 
    //Location Type 
    public static CENTRAL_LOGIN = -1;
    public static STORE = 0;
    public static WAREHOUSE = 1;
    public static KITCHEN = 2;
    public static DISPLAY_LOGIN = 3;
    
    
    //Outlet type 
    public static POS = 0;
    public static RESTAURANT = 1;
    public static FOOD_TAKEAWAY = 2;
  
    //Aggregators/
    public static ZOMATO = 1;
    public static SWIGGY = 2;
    public static FOODPANDA = 3;
    public static UBEREATS = 4;
    public static SCOOTSY = 5;
    public static SELF = 6;


    public static getCounterTypeName(id : Number) : string {
        switch(id){
            case this.NORMAL:
                return "Regular";
            case this.AC_SPECIAL:
                return "AC/Special";
            case this.TAKEAWAY:
                return "Takeway";
            case this.ONLINE:
                return "Online";
            case this.FREE:
                return "Free";
            case this.PARCEL:
                return "Parcel";
        }
        
        return "";
    }

    public static getCounterTypeAsList() : SelectItem[] {
        var ctypelist : SelectItem[];
        ctypelist= [
            { label: 'Normal', value: Constants.NORMAL },
            { label: 'AC/Special', value: Constants.AC_SPECIAL },
            { label: 'Takeaway', value: Constants.TAKEAWAY},
            { label: 'Parcel', value: Constants.PARCEL},
            { label: 'Online', value: Constants.ONLINE},
            { label: 'Free', value: Constants.FREE}
        ];

        return ctypelist;
    }

    public static getAggregatorName(id : Number) : string {
        switch(id){
            case this.ZOMATO:
                return "Zomato";
            case this.SWIGGY:
                return "Swiggy";
            case this.FOODPANDA:
                return "FoodPanda";
            case this.SCOOTSY:
                return "Scootsy";
            case this.UBEREATS:
                return "UberEats";
            case this.SELF:
                return "Self";
      
        }
        
        return "";
    }



    public static getAggregatorId(name) : number {
        var uname = name.toUpperCase();

        switch(uname){
            case "ZOMATO":
                return this.ZOMATO;
            case "SWIGGY":
                return this.SWIGGY;
            case "FOODPANDA":
                return this.FOODPANDA;
            case "OTHERS":
                return this.SELF;
      
        }
        
        return this.SELF;
    }

    //Kitchen statuses
    public static UNSENT = -1;
    public static DISPATCHED_TO_KITCHEN = 99;
    public static RECIEVED_BY_KITCHEN = 0;
    public static BEING_PREPARED = 1;
    public static READY_AT_KITCHEN = 2;
    public static CANT_BE_PREPARED = 3;
    public static ITEM_CANCELLED = 4;
    public static SERVED = 5;

     

     //Txntypes 
     public static  STOCK_CREDIT_THROUGH_NOTE = 1;
     public static  STOCK_DEBIT_THROUGH_NOTE = 2;
     public static  STOCK_TRANSFER_CREDIT = 3;
     public static  STOCK_TRANSFER_DEBIT = 4;
     public static  STOCK_SALE_AT_STORE = 5;
     public static  STOCK_SALE_REVERSAL_AT_STORE = 6;
     public static  STOCK_PURCHASE = 7;
     public static  STOCK_CONSUMPTION_IN_KITCHEN = 8;

     public static getInventoryTxnTypeStr(txntype : Number) : string {
        switch(txntype){
            case this.STOCK_DEBIT_THROUGH_NOTE:
                return "Debited";
            case this.STOCK_CREDIT_THROUGH_NOTE:
                return "Credited";
            case this.STOCK_TRANSFER_CREDIT:
                return "Transfer credit";
            case this.STOCK_TRANSFER_DEBIT:
                return "Transfer debit";
            case this.STOCK_SALE_AT_STORE:
                return "Sold at store";
            case this.STOCK_SALE_REVERSAL_AT_STORE:
                return "Sale Rev. at store";
      
        }
        
        return "";
    }
    
    //Transfer note statuses
    public static STOCK_DISPATCHED = 0;
    public static STOCK_ACCEPTED = 1;
    public static STOCK_REJECTED = 2;
    
    public static getTransfernoteStatus(status : Number) : string {
        switch(status){
            case this.STOCK_DISPATCHED:
                return "Dispatched";
            case this.STOCK_ACCEPTED:
                return "Accepted";
            case this.STOCK_REJECTED:
                return "Rejected";
                       
        }
        
        return "";
    }

    
    
    //Order status
    public static PENDING = 0;
    public static COMPLETED = 1;
    public static CANCELLED = 2;


    public static getOrderStatus(status : Number) : string {
        switch(status){
            case this.PENDING:
                return "Pending";
            case this.COMPLETED:
                return "Completed";
            case this.CANCELLED:
                return "Cancelled";
                       
        }
        
        return "";
    }

    

    public static REQ_MADE = 0;
    public static REQ_ACCEPTED = 1;
    public static REQ_REJECTED = 2;

    public static getRequisitionnoteStatus(status : Number) : string {
        switch(status){
            case this.REQ_MADE:
                return "Request made";
            case this.REQ_ACCEPTED:
                return "Accepted";
            case this.STOCK_REJECTED:
                return "Rejected";
                       
        }
        
        return "";
    }


    public static getInventoryTypeStr(inventorytype : Number) : string {
        switch(inventorytype){
            case this.SALE_ONLY:
                return "Sales only";
            case this.PURCHASE_ONLY:
                return "Purchase only";
            case this.SALE_PURCHASE_BOTH:
                return "Both sales/purchase";
        }
        
        return "";
    }
    
    public static getLocationTypeStr(locationtype : Number) : string {
        switch(locationtype){
            case this.STORE:
                return "Store";
            case this.WAREHOUSE:
                return "Warehouse";
            case this.KITCHEN:
                return "Kitchen";
        }
        
        return "";
    }
    
    public static getGenderStr(gender : Number) : string {
        switch(gender){
            case this.MALE:
                return "Male";
            case this.FEMALE:
                return "Female";
            case this.ALL:
                return "All";
        }
        return "";
    }
    
    
    public static getPriorityStr(priority : Number) : string {
        switch(priority){
            case this.LOW:
                return "Low";
            case this.MEDIUM:
                return "Medium";
            case this.HIGH:
                return "High";
            case this.VERY_HIGH:
                return "Very high";
                
        }
        return "";
    }
    
    public static getSchemerunonStr(schemerunon : Number) : string {
        switch(schemerunon){
            case this.ALL_PRODUCTS:
                return "All products";
            case this.SPECIFIC_PRODUCT:
                return "Product:";
            case this.SPECIFIC_SUBCATEGORY:
                return "Category:";
                
        }
        return "";
    }
    
    
    public static populateSchemeFields(sch : Scheme) : void {
        if(sch.daysofweek.length == 7)
            sch.strdaysofweek = 'All'
        else
            sch.strdaysofweek = sch.daysofweek.toString();
    
        if(sch.isactive == this.ACTIVE)
            sch.strisactive = 'Active'
        else
            sch.strisactive = 'Inactive'
            
        sch.strdisplaytogender = this.getGenderStr(sch.displayedtogender);
        sch.strdisplaypriority = this.getPriorityStr(sch.displaypriority);
        sch.strschemeruntype =  this.getSchemerunonStr(sch.schemeruntype);
        
        sch.runallday = true;//Hardcoded for now       
        if(sch.runallday)
            sch.strapplicabletime = "All day";
        else
            sch.strapplicabletime = sch.starttime + " - " + sch.endtime;        
                
    }
    
    
    //Billing counter types 
    public static NORMAL=1;
    public static AC_SPECIAL=2;
    public static PARCEL=3;
    public static  ONLINE=4;
    public static FREE=5;
    public static TAKEAWAY=6;


    public static ONLINE_PLACED = 1;
    public static ONLINE_ACKNOWLEDGED = 2;
    public static ONLINE_FOODREADY = 3;
    public static ONLINE_DISPATCHED = 4;
    public static ONLINE_COMPLETED = 5;
    public static ONLINE_CANCELLED = 6;
    
    public static getOnlineStatusAsString(status){
        switch(status){
            case Constants.ONLINE_PLACED:
                return "Placed";
            case Constants.ONLINE_ACKNOWLEDGED:
                return "Accepted";
            case Constants.ONLINE_DISPATCHED:
                return "Dispatched";
            case Constants.ONLINE_FOODREADY:
                return "Food ready";
            case Constants.ONLINE_COMPLETED:
                return "Completed";
            case Constants.ONLINE_CANCELLED:
                return "Cancelled";
                            
        }
    }
    

    //restore status 
    public static FETCHING_DATA = 1; 
    public static STORING_LOCALLY = 2;
    public static RESTORE_DONE = 3;
    public static RESTORE_FAILED = 4;

    public static getRestoreStatusAsString(status){
        switch(status){
            case Constants.FETCHING_DATA:
                return "Fetching data";
            case Constants.STORING_LOCALLY:
                return "Creating records";
            case Constants.RESTORE_DONE:
                return "Restore done";
            case Constants.RESTORE_FAILED:
                return "Restore failed";
            default: return "";    

        }
    }
    
    
    public static getRestoreStatusImage(status){
        switch(status){
            case Constants.FETCHING_DATA:
                return "yellow";
            case Constants.STORING_LOCALLY:
                return "blue";
            case Constants.RESTORE_DONE:
                return "green";
            case Constants.RESTORE_FAILED:
                return "red";
            default: return "yellow";    


        }
    }


//POS inventory txn type
public static  POS_CUST_WALLET_PURCHASE = 1;
public static  POS_CUST_ACT_CREDIT = 2;
public static  POS_CUST_ACT_DEBIT = 3;
public static  POS_CUST_ACT_REFUND = 4;
public static  POS_CUST_PURCHASE_CANCEL = 5;



  
public static getPosCustTxntypeAsString(txntype) : string {
    switch(txntype){
        case this.POS_CUST_WALLET_PURCHASE:
            return "Purchase";
        case this.POS_CUST_ACT_CREDIT:
            return "Account Credit";
        case this.POS_CUST_ACT_DEBIT:
            return "Account Debit";
        case this.POS_CUST_PURCHASE_CANCEL:
                return "Payment Cancl";
            
    }
    return "";
}

public static getPosCustTxntypeForPrint(txntype,paperwidth) : string {
    switch(txntype){
        case this.POS_CUST_WALLET_PURCHASE:
            if(paperwidth == Constants.TWO_INCH_PAPER) return "Dr";
            else return "Purchase";
        case this.POS_CUST_ACT_CREDIT:
            if(paperwidth == Constants.TWO_INCH_PAPER) return "Cr";
            else return "Act credit";
        case this.POS_CUST_ACT_DEBIT:
            if(paperwidth == Constants.TWO_INCH_PAPER) return "Dr";
            else return "Act debit";
        case this.POS_CUST_PURCHASE_CANCEL:
            if(paperwidth == Constants.TWO_INCH_PAPER) return "Cr";
            else return "Reversal";
    }
    return "";
}



public static  POS_SUP_ACT_PURCHASE = 1;
public static  POS_SUP_ACT_CREDIT = 2;
public static  POS_SUP_ACT_DEBIT = 3;
public static  POS_SUP_ACT_REFUND = 4;
public static  POS_SUP_PURCHASE_CANCEL = 5;


public static getPosSupTxntypeAsString(txntype) : string {
    switch(txntype){
        case this.POS_SUP_ACT_PURCHASE:
            return "Purchase";
        case this.POS_SUP_ACT_CREDIT:
            return "Account Credit";
        case this.POS_SUP_ACT_DEBIT:
            return "Account Debit";
        case this.POS_SUP_ACT_REFUND:
            return "Purchase refund";
        case this.POS_SUP_PURCHASE_CANCEL:
             return "Payment Cancl";
                    
       
    }
    return "";
}


    //POS inventory txn type
    public static  POS_STOCK_PURCHASE = 1;
    public static  POS_STOCK_SALE = 2;
    public static  POS_STOCK_EDIT_CREDIT = 3;
    public static  POS_STOCK_EDIT_DEBIT = 4;
    public static  POS_OPENING_STOCK = 5;
    public static  POS_STOCK_SALE_REVERSAL= 6;
    public static  POS_STOCK_PURCHASE_REVERSAL= 7;
    public static  POS_STOCK_CONSUMPTION = 8;
    public static  POS_STOCK_UPDATE = 9;
    public static  POS_STOCK_KITCHEN_CONSUMPTION = 10;
    public static  POS_STOCK_KITCHEN_PROCESSED = 11;
    public static  POS_STOCK_XFER_IN = 12;
    public static  POS_STOCK_XFER_OUT = 13;
    

      
    public static getPosInvTxntypeAsString(txntype) : string {
        switch(txntype){
            case this.POS_STOCK_PURCHASE:
                return "Purchase";
            case this.POS_STOCK_SALE:
                return "Sale";
                    
            case this.POS_STOCK_EDIT_CREDIT:
                return "Manual credit";
            case this.POS_STOCK_EDIT_DEBIT:
                return "Manual debit";
            case this.POS_STOCK_EDIT_DEBIT:
                return "Manual debit";
            case this.POS_OPENING_STOCK:
                return "Opening stock";
            case this.POS_STOCK_SALE_REVERSAL:
                return "Sale reversal";
            case this.POS_STOCK_PURCHASE_REVERSAL:
                return "Purchase reversal";
            case this.POS_STOCK_CONSUMPTION:
                return "Consumption";
            case this.POS_STOCK_UPDATE:
                return "Stock update";
            case this.POS_STOCK_KITCHEN_CONSUMPTION:
                return "Kitchen Consumption";
            case this.POS_STOCK_KITCHEN_PROCESSED:
                return "Kitchen Production";
            case this.POS_STOCK_XFER_IN:
                return "Transfer In";
            case this.POS_STOCK_XFER_OUT:
                return "Transfer Out";
                            
                            
           
        }
        return "";
    }
    

    //Sales order / Payment / etc status 
    public static STATUS_PROCESSED = 0; 
    public static STATUS_CANCELLED = 1; 
    
    public static DLVRY_COMPLETED = 0;
    public static DLVRY_PENDING = 1;
     

    //Payment modes 
    public static PYMT_CASH= 1;
    public static PYMT_CARD= 2;
    public static PYMT_ACT= 3;
    public static PYMT_UPI= 4;
    public static PYMT_PAYTM= 5;
    public static PYMT_WALLET= 6;
    public static PYMT_CHEQUE= 7;
    public static PYMT_AGGREGATOR=8;
    public static PYMT_COMPLEMENTARY=9;
    


    


  public static getPymtModeName(pymtmode) : string {
        switch(pymtmode){
            case this.PYMT_CASH:
                return "Cash";
            
            case this.PYMT_CARD:
                return "Card/Online";
            
            case this.PYMT_ACT:
                return "Account";
            
            case this.PYMT_PAYTM:
                return "Pay TM";
            
            case this.PYMT_UPI:
                return "UPI";
            
            case this.PYMT_WALLET:
                return "Wallet";
            
            case this.PYMT_CHEQUE:
                return "Cheque";
            
            case this.PYMT_AGGREGATOR:
                return "Aggregator";
            
            case this.PYMT_COMPLEMENTARY:
                return "Complementary";
                    
                
        }
        return "";
    }
       

    //printer types 
    public static SYSTEM=1;
    public static USB=2;
    public static LAN=3;
    public static BLUETOOTH=4;
    

    //comms protocol 
    public static HTTP=1;
    public static WEB_SOCKET=2;
    
    //cancel kot 
    public static KOT_CANCEL_COUNTER=1;
    public static KOT_CANCEL_KITCHEN=2;
    public static KOT_CANCEL_BOTH=3;
    

    //printer sequence reset: 
    public static NEVER=0;
    public static DAILY=1;
    public static MONTHLY=2;
    public static YEARLY=3;
    public static FINANCIAL_YEARLY=4;


    //Reports view types
    public static VW_CATEGORY =1; 
    public static VW_SUBCATEGORY =2; 
    public static VW_PRODUCT =3;
    public static VW_OPERATOR=4;
    public static VW_COUNTERTYPE=5;
    public static VW_COUNTER=6;
    public static VW_BSNDATE=7;
    public static VW_WEEKDAY=8;
    public static VW_KITCHEN=9;
    public static VW_PAYMENTMODE=10;
    public static VW_REVENUEHEAD=11;
    public static VW_EXPENSEHEAD=12;
    public static VW_SUPPLIER=13;
    public static VW_DELIVERY_BOY=14;
    public static VW_POS_LOCATION=15;
    public static VW_HSN_NO=16;
    public static VW_SECTION=17;
    public static VW_HOUR_OF_DAY=18;
    public static VW_CUSTOMIZATION=19;
    

 
 



    //Details View 
    public static VW_SALES_DET_SUCCESSFUL = 0; 
    public static VW_SALES_DET_ALL = 1; 
    public static VW_SALES_DET_PENDINGDEL =2; 
    public static VW_SALES_DET_PENDINGPAYMENT =3;
    public static VW_SALES_DET_CANCELLED =4;
    public static VW_SALES_DET_PARCELS =5;
    public static VW_SALES_DET_BOOKINGS =6;
    


    


    //Paper size for printer 
    public static TWO_INCH_PAPER = 2;
    public static THREE_INCH_PAPER = 3;
    public static A4 = 4;
    public static COL40_PRINTER = 5;
    public static COL42_PRINTER = 6;
    
     


    //Paper size for printer 
    public static NO_DIALOG = 0;
    public static OPEN_CUSTOMER_DIALOG = 1;
    public static OPEN_OPERATOR_DIALOG = 2;
    public static OPEN_PAYMENT_DIALOG = 3;
    public static OPEN_DELIVERY_DIALOG = 4;
    public static OPEN_PYMT_DLVRY_DIALOG = 5;

    //Bill operator 
    public static LOGGED_IN_OPERATOR = 0;
    public static DYNAMIC_OPERATOR = 1;
 
    //System revenue heads 
    public static SYS_POS_RECEIPTS=1; 
    public static SYS_PURCHASE_REFUND=2;
    public static SYS_CASH_INFUSION=3;
    

    

    public static getPoSOrderStatus(status : Number) : string {
        switch(status){
            case this.STATUS_CANCELLED:
                return "Cancelled";
            case this.STATUS_PROCESSED:
                return "Processed";
        }
        
        return "";
    }

    public static getPoSDeliveryStatus(status : Number) : string {
        switch(status){
            case this.DLVRY_COMPLETED:
                return "Completed";
            case this.DLVRY_PENDING:
                return "Pending";
        }
        
        return "";
    }

    //Expense heads 
    public static EXPENSE_POS_REFUND =1;
    public static EXPENSE_PURCHASE = 2;
    public static EXPENSE_POS_WITHDRAWAL= 3;
 
    
    //Cash box txn types 
    public static CASH_BOX_CREDIT = 1;
    public static CASH_BOX_DEBIT = 2;
    public static CASH_BOX_UPDATE = 3;
    public static CASH_BOX_DEPOSIT = 4;
    public static CASH_BOX_WITHDRAWAL = 5;
    

    public static getPosCashboxTxntypeAsString(txntype) : string {
        switch(txntype){
            case this.CASH_BOX_CREDIT:
                return "Credit";
            case this.CASH_BOX_DEBIT:
                return "Debit";
            case this.CASH_BOX_UPDATE:
                return "Update";
            case this.CASH_BOX_DEPOSIT:
                return "Deposit";
            case this.CASH_BOX_WITHDRAWAL:
                return "Withdrawal";
           
        }
        return "";
    }

    //intercomms msg types 
    public static LOGIN=1;
    public static LOGINRESPONSE=2;
    public static SYNCCOUNTER=3;
    public static KOTREQUEST=4;
    public static KOTRESPONSE=5;
    public static CHECKOUTREQUEST=6;
    public static CHECKOUTRESPONSE=7;
    public static PROFORMAREQUEST=8;
    public static PROFORMARESPONSE=9;
    public static REFETCHCOUNTER=10;
    public static REFETCHCOUNTERRESPONSE=11;


    
    //Messages for table sharing 
    public static CREATESHARE=12;
    public static CREATESHARERESPONSE=13;
    public static REMOVESHARE=14;
    public static REMOVESHARERESPONSE=15;
    public static CANCELKOT=16;
    public static CANCELKOTERESPONSE=17;
    public static CANCELITEM=18;
    public static CANCELITEMERESPONSE=19;
    public static TRANSFERKOT=20;
    public static TRANSFERKOTRESPONSE=21;

    //Messages for kitchen 
    public static KOTTOKITCHEN = 22;
    public static KITCHENKOTRESPONSE = 23;

    //Messages for view last 
    public static VIEWLAST=24
    public static VIEWLASTRESPONSE=25;




    //Pos Message status 
    public static NEW_MESSAGE=1; 
    public static MESSAGE_RECEIVED=2;
    public static MESSAGE_ACCEPTED=3;
    public static MESSAGE_REJECTED=4;
    
     
    //Remote message types
    public static REMOTE_PURCHASE=1; 
    public static REMOTE_STOCK_TRANSFER=2; 
    public static REMOTE_STOCK_REQUISITION=3; 

    public static SAME_AS_PRODUCT=0;
    public static GST_IN_FORWARD=1;
    public static GST_IN_REVERSE=2;
     

    
    
}
