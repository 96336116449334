import {User} from '../domain/user'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';
import { Printerservice } from './printerservice';
import { Usergroupservice } from './usergroupservice';
import { Usergroup } from '../domain/usergroup';
import { Billingcounterservice } from './billingcounterservice';

@Injectable()
export class Userservice  {
     userlist : User[];
     
     constructor(
         private couchdbService : Couchdbservice,
         private dataService : Appdataservice,
         private usergroupService : Usergroupservice,
         private counterService : Billingcounterservice,
         private dynamoDBService : Dynamodbservice,
         private printerService : Printerservice,
         
     ){}

     public getNewId(){
         var maxid = 0;
         for(var ctr  of this.userlist){
             if(ctr.id > maxid)
                maxid = ctr.id;
         }

         return maxid + 1; 
     }

     
     addEditInList(user : User){
       var found = false; 
       for(var i=0;i<this.userlist.length; i++){
           if(this.userlist[i].id === user.id){
               this.userlist[i] = user;
               found = true; 
               break;
           }
       }

       if(!found){
           this.userlist.push(user);
       }

    }


     public checkDuplicate(user : User){
        for(var ctr  of this.userlist){
            if(ctr.id != user.id && ctr.name == user.name)
               return true;

            if(ctr.id != user.id && ctr.username == user.username)
               return true;
        }

        return false; 
    }

    editsalesallowed;
    alertsallowed; 
    cashierfunctionsallowed; 

    reportsallowed;
    salesreportsallowed;
    businesssummaryallowed;
    paymentsreportsallowed;
    createusersallowed; 
    offlinesyncallowed;
    deliverymanagementallowed;
    
    callcenterallowed;

    getUserNameForId(id){
        for(var user of this.userlist){
            if(user.id === id) return user.name ; 
        }
        return "";
    }

    getUserForId(id){
        for(var user of this.userlist){
            if(user.id === id) return user; 
        }
        return null;
    }
    
    getUserIdForName(name){
        for(var user of this.userlist){
            if(user.name === name) return user.id; 
        }
        return 0;
    }

       
    getUser(name){
        for(var user of this.userlist){
            if(user.name === name) return user; 
        }
        return null;
    }

    public loggedinuser : User; 
    public loggedinusergroup  : Usergroup; 

    async loginOfflineUser(username,userpass,cardid){
        await this.initUsers();

        //if(this.dataService.usedynamo){
        
            for(var user of this.userlist)
            {
                   var authenticated = false;
                   if(cardid && cardid.length > 0){
                        if(user.usercode == cardid && user.active) authenticated = true;
                   }else{
                        if(user.username == username && user.password == userpass && user.active) authenticated = true;
                   }  

                    if(authenticated)
                    {
                        this.loggedinuser = user;
                        this.dataService.loggedinusername = user.name;
                        
                        if(this.loggedinuser.arrassignedcounters && this.loggedinuser.arrassignedcounters.length > 0) this.counterService.arrassignedcounters = this.loggedinuser.arrassignedcounters;
                        return true; 
                    }

            }

            return false;
        
        /*}else{
            //this.billingcounterlist = [];
            var selector =  {"username": { "$eq": username}, "password" : {"$eq" : userpass}};
            var retlist = await this.couchdbService.getFromIndex('users',selector);
            if(retlist && retlist.docs && retlist.docs.length > 0){
                this.loggedinuser = retlist.docs[0];
                if(this.loggedinuser.arrassignedcounters && this.loggedinuser.arrassignedcounters.length > 0) this.counterService.arrassignedcounters = this.loggedinuser.arrassignedcounters;
                return true; 
            }else 
                return false;
        }*/
    }

    async getAuthorization(username, userpass,cardid){

        //if(this.dataService.usedynamo){
            for(var user of this.userlist){

                if(cardid && cardid.length > 0){
                    if(user.usercode == cardid && user.active) return user;
                }else{
                    if(user.username == username && user.password == userpass && user.active) return user;
                }  

                /*if(user.username == username && user.password == userpass && user.active){
                    return user; 
                }*/
            }
            return null;
        /*}else{
            var selector =  {"username": { "$eq": username}, "password" : {"$eq" : userpass}};
            var retlist = await this.couchdbService.getFromIndex('users',selector);
            if(retlist && retlist.docs && retlist.docs.length > 0){
                return retlist.docs[0]; 
            }else 
                return null;
        }*/
    }
    
    processLogout(){
        this.editsalesallowed = false;
        this.reportsallowed = false;
        this.salesreportsallowed = false;
        this.paymentsreportsallowed = false;
        this.businesssummaryallowed = false;
        this.createusersallowed  = false;
        this.alertsallowed = false;
        this.callcenterallowed = false;
        this.deliverymanagementallowed = false; 
        this.cashierfunctionsallowed = false;
        this.dataService.csvdownload = false;
    }
    
    setCurrentUserGroup(){
        console.log('USERGROUP ID: ' + this.loggedinuser.usergroupid);
        this.loggedinusergroup = this.usergroupService.getUsergroupForId(this.loggedinuser.usergroupid);
        this.editsalesallowed = this.checkBillingPriv(Constants.PRIV_EDIT_SALES);
        this.deliverymanagementallowed = this.checkBillingPriv(Constants.PRIV_DELIVERIES);
        this.reportsallowed = this.canUserViewReports();
        this.salesreportsallowed = this.checkReportingPrivilege(Constants.PRIV_SALES_REPORTS);
        this.paymentsreportsallowed = this.checkReportingPrivilege(Constants.PRIV_COLLECTION_REPORTS);
        this.businesssummaryallowed = this.checkReportingPrivilege(Constants.PRIV_BUSINESS_SUMMARY);
        this.createusersallowed  = this.checkBillingPriv(Constants.PRIV_CREATE_USERS);
        this.alertsallowed = this.checkReportingPrivilege(Constants.PRIV_MONITORING_ALERTS);
        this.callcenterallowed = this.checkBillingPriv(Constants.PRIV_CALLCENTER);
        this.cashierfunctionsallowed = this.checkBillingPriv(Constants.PRIV_CASHIER_FUNCTIONS);
        this.dataService.csvdownload = this.checkReportingPrivilege(Constants.PRIV_CSV_DOWNLOAD);
        this.offlinesyncallowed = this.checkBillingPriv(Constants.PRIV_SYNC_OFFLINE_DATA);
    }

    checkBillingPriv(action){
        for(var priv of this.loggedinusergroup.arrbillingprivs){
            if(action == priv) return true;
        }
        return false; 
    }

    checkMobileCounter(counterid){
        if(this.loggedinuser && this.loggedinuser.arrassignedcounters){
            for(var ctr of this.loggedinuser.arrassignedcounters){
                if(ctr == counterid) return true; 
            }
        }

        return false; 
    }

    setMobileDisplayList(){
        this.counterService.mobilecounters = [];
        for(var ctr of this.counterService.activelist){
            if(this.checkMobileCounter(ctr.id)){
                this.counterService.mobilecounters.push(ctr);
            }
        }
    }

    checkReauthPriv(user : User, action){
        var usergroup = this.usergroupService.getUsergroupForId(user.usergroupid);

        for(var priv of usergroup.arrbillingprivs){
            if(action == priv) return true;
        }
        return false; 
    }

    checkReportingPrivilege(action){
        if(!this.loggedinusergroup.arrreportingprivs) this.loggedinusergroup.arrreportingprivs = [];

        for(var priv of this.loggedinusergroup.arrreportingprivs){
            if(action == priv) return true;
        }
        return false; 
    }
    
    canUserBill(){
        if(this.loggedinusergroup && this.loggedinusergroup.arrbillingprivs && this.loggedinusergroup.arrbillingprivs.length > 0){
            return true; 
        }
        return false; 
    }

    canUserViewReports(){
        if(this.loggedinusergroup && this.loggedinusergroup.arrreportingprivs && this.loggedinusergroup.arrreportingprivs.length > 0){
            return true; 
        }
        return false; 
    }

    
    async initUsers(){
        this.userlist = [];
        
        var retlist;
        if(this.dataService.usedynamo){
            console.log('*** getting all users...');
            retlist = await this.dynamoDBService.getAllItemsForPosId('users');
            console.log('*** users found ...' + retlist.length);
            if(retlist) this.userlist = <User[]> retlist;
        }else{
            retlist = await this.couchdbService.getAllItems('users');
            if(retlist) this.userlist = <User[]> retlist.docs;
        }

        if(retlist){
            //this.userlist = <User[]> retlist.docs;
            if(this.usergroupService.usergrouplist){
                for(var user of this.userlist ){
                    user.usergroupname  = this.usergroupService.getUsergroupNameForId(user.usergroupid);
                }
            }
            return true;
        }else{
            return false;
        }
        
     }
     
     async save(user){
        if(Constants.DEBUG_MODE) console.log('|COUCH|DBOYSEVICE|Saving DBOY ' + JSON.stringify(user));
        //var status= await this.couchdbService.putItem('users',user);

        var status;
        if(this.dataService.usedynamo){
            status = await this.dynamoDBService.putItem('users',user);    
        }else{
            status = await this.couchdbService.putItem('users',user);
        }

        if(status != "ERROR" && status != "CONFLICT"){
            this.addEditInList(user);
            this.userlist = [... this.userlist];
            return status;
        }else{
            return status;
        }
        
     }
   



    

}