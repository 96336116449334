import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {DataService} from '../service/dataservice';
import {Billingservice} from '../service/billingservice';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import { AutoComplete,ScrollPanel } from 'primeng/primeng';

import {HostListener} from '@angular/core';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Customization } from '../domain/customization';
import { Utils } from '../service/utils';
import { Appdataservice } from '../service/appdataservice';
import { Billdialog } from './billdialog';
import { CustomizationOption } from '../domain/customizationoption';

@Component({
    selector: 'customization-dlg',
    templateUrl: './customizationdialog.html',

})
export class Customizationdialog {
    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private dataService : Appdataservice,
                public counterService : Billingcounterservice) {}

    
    @ViewChild('pricebox')  txtPriceBox : ElementRef;

    @ViewChild('namebox')  txtNameBox : ElementRef;
    @ViewChild('focusbox')  txtFocusBox : ElementRef;
    @ViewChild('qtybox')  txtQtyBox : ElementRef;
    

    @ViewChild('custompanel') scrollPanel : ScrollPanel;

    dialogheader;
    
    dynamicprice;
    dynamicquantity; 
    dynamicrate;
    dynamicname;
    showtime; 

    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.billingService.makeCustomizationDialogVisible){
            if(event.keyCode == 13  ) {
                  if(this.customization){
                    /*if(this.customization.dynamicquantity)  
                        this.addtoBill();
                    else{    */
                        if((new Date().getTime() - this.showtime) > 300)
                            this.addCustomization();
                    //}
                  }
            }
        }
    }





    
    closeDialog(){
        this.billingService.makeCustomizationDialogVisible = false;
    }

    customization : Customization; 

    getWidth(){
       /* if(this.customization && this.customization.dynamicprice)
            return 300;
        else*/
            return 600; 

    }

    getHeight(){
        /*if(this.customization && this.customization.dynamicprice)
            return 250;
        else*/
            return 500; 
    }


    onRateOrQuantityChange(){
        var newquantity = 0;
        var newrate = 0;
        if(Constants.DEBUG_MODE) console.log('HERE in qty rate change');
        if(this.dynamicquantity && this.dynamicquantity.trim().length > 0 && !isNaN(this.dynamicquantity) && parseFloat(this.dynamicquantity) >= 0){
            newquantity =  parseFloat(this.dynamicquantity);
        }

        if(this.dynamicrate && this.dynamicrate.trim().length > 0 && !isNaN(this.dynamicrate) && parseFloat(this.dynamicrate) >= 0){
            newrate =  parseFloat(this.dynamicrate);
        }


        if(newquantity > 0 && newrate > 0){
            this.dynamicprice = "" + Math.round( newquantity * newrate);
        }
        
    }


    onPriceChange(){
        var newprice = 0;
        var newrate = 0;
        if(this.dynamicprice && this.dynamicprice.trim().length > 0 && !isNaN(this.dynamicprice) && parseFloat(this.dynamicprice) >= 0){
            newprice=  parseInt(this.dynamicprice);
        }

        if(this.dynamicrate && this.dynamicrate.trim().length > 0 && !isNaN(this.dynamicrate) && parseFloat(this.dynamicrate) >= 0){
            newrate =  parseFloat(this.dynamicrate);
        }

        if(newprice > 0 && newrate > 0){
            this.dynamicquantity = ""  +  Utils.round(newprice / newrate,3);
        }
        
    }


    setPresetValues(){
        /*if(this.customization.priceforfullunit &&  parseInt(this.customization.priceforfullunit) > 0) {
            this.dynamicrate = "" + this.customization.priceforfullunit;
        }else{
            this.dynamicrate = "" + this.billingService.productForCustomization.sellingprice;      
        }
        
        this.dynamicprice = this.dynamicrate;
        this.dynamicquantity = '1';*/
        this.dynamicprice ='';
        this.dynamicquantity ='';
        this.dynamicprice ='';
        this.dynamicname = '';
        
        
    }

    setEmpty(){
        this.dynamicprice = null;
        this.dynamicquantity = null;
        this.dynamicrate = null;
        this.customizationchosen = '';
    }

    onHide(){
        if(Constants.DEBUG_MODE) console.log('On hide called!');
        this.setEmpty();
    }

    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');
        this.setEmpty();
        this.showtime = new Date().getTime();

        /*this.arrcustomizations = [];
        if(this.billingService.productForCustomization.customization.optionlist != null && this.dataService.retailer.presetinstructions.length > 0){
            this.arrinstructions = this.dataService.retailer.presetinstructions.split(',');
        }*/
        
        this.customization = this.billingService.productForCustomization.customization; 
        if(this.customization.dynamicprice){


            var orgrate = "";
            if(this.customization.priceforfullunit &&  parseInt(this.customization.priceforfullunit) > 0) {
                orgrate = "" + this.customization.priceforfullunit;
            }else{
                orgrate = "" + this.billingService.productForCustomization.sellingprice;      
            }

            if(orgrate &&  parseInt(orgrate)  > 0)
                this.dialogheader =  "Set dynamic price for " + this.billingService.productForCustomization.name + " (" + this.dataService.currencysymbol +  orgrate + ")";
            else
                this.dialogheader =  "Set dynamic price for " + this.billingService.productForCustomization.name;

            if(this.customization.dynamicname){
                setTimeout(()=>{ this.txtNameBox.nativeElement.focus();}, 100);
            }else 
                setTimeout(()=>{ this.txtPriceBox.nativeElement.focus();}, 100);
            setTimeout(()=>{ this.setPresetValues();}, 200);
        
        }else{

            console.log('**HERE **');
            this.dialogheader =  "Customize product";
            
            if(this.customization.optionlist){
                for (var option of this.customization.optionlist){
                    option.choicesmade = null;
                    if(option.selectone) option.choicesmade = option.choicelist[0];

                }   
            }

            if(this.customization.dynamicquantity){
                console.log('Setting focus on quantity..');
                setTimeout(()=>{ this.txtQtyBox.nativeElement.focus();}, 100);
            }
            
         
            //setTimeout(()=>{ window.location.hash = '#focusdiv';}, 100);
            

            
        }
    }

    buildCustError(option : CustomizationOption){
		var str = '' + option.optionheader;
		str = str.toLowerCase();
			
		if(str.indexOf('choose') >= 0){
			return 'Please ' + str + ".";
		}

		if(str.indexOf('choice') >= 0){
			return 'Please provide ' + str + ".";
		}

		return 'Please select one option for: ' + option.optionheader;
	}

	validateCustomization() : boolean{
        if(this.customization.optionlist){
            for(var opt of this.customization.optionlist){
                
                if(opt.selectone){
                    var optionpresent = false; 
                    var strheader = opt.optionheader;
                    
                    if(strheader.toLowerCase().indexOf('add') >= 0){
                        optionpresent = true;
                    }
                    if(!opt.optional && optionpresent == false){
                        for(var choice of opt.choicelist){
                            if(opt.choicesmade && opt.choicesmade == choice.description){
                                optionpresent = true; 
                                break;
                            }
                        }

                        if(!optionpresent){
                            this.showErrorToast(this.buildCustError(opt));
                            return false;
                        }
                        
                    }
                }else{
                    //validation for multi select
                    var numchoicesmade = 0;
                    if(opt.choicesmade) numchoicesmade = opt.choicesmade.length;

                    if(opt.min && opt.min > numchoicesmade){
                        this.showErrorToast('Please select at least ' + opt.min + ' option(s) for ' + opt.optionheader);
                        return false;
                    }

                    if(opt.max && opt.max < numchoicesmade){
                        this.showErrorToast('You can not select more than  ' + opt.max + ' option(s) for ' + opt.optionheader);
                        return false;
                    }


                    if(opt.exact && opt.exact != numchoicesmade){
                        this.showErrorToast('You need to select exactly  ' + opt.exact + ' option(s) for ' + opt.optionheader);
                        return false;
                    }
                    
                    var askqty = 0;
                    for(var choice of opt.choicelist){
                        if(opt.choicesmade && opt.choicesmade.length > 0){
                            for(var madechoice of opt.choicesmade){
                                if(choice.description === madechoice){
                                    //This choice is made 
                                    if(choice.askquantity){
                                        if(!choice.quantity || isNaN(choice.quantity) || isNaN(parseInt(choice.quantity)) || parseInt(choice.quantity) <= 0){
                                            this.showErrorToast('Please provide valid quantity for ' + choice.description);
                                            return false;   
                                        }else{
                                            askqty = askqty + parseInt(choice.quantity);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if(opt.maxaskqty && opt.maxaskqty < askqty){
                        this.showErrorToast('You can not provide more than   ' + opt.maxaskqty + ' quantity for ' + opt.optionheader);
                        return false;
                    }

                    if(opt.minaskqty && opt.minaskqty > askqty){
                        this.showErrorToast('You have to provide  ' + opt.maxaskqty + ' quantity for ' + opt.optionheader);
                        return false;
                    }



                }
            }
        }

		return true;

	}


    customizationchosen = ''; 

    addCustomization(){

        var custinv = '';
        var totalcost = 0; 

        this.customizationchosen = '';


        if(this.customization.optionlist){
            for(var option of this.customization.optionlist){
                if(option.choicesmade && option.choicesmade.length > 0){
                    if(option.selectone){
                        for(var choice of option.choicelist){
                            if(choice.description === option.choicesmade){
                                this.customizationchosen = this.customizationchosen + choice.description + ", ";
                                totalcost = totalcost + choice.cost;


                                if(choice.inventory && choice.inventory.length > 0)
                                    custinv = custinv + choice.inventory + "|"  + choice.cost + ", ";
                            }
                        }
                    }else{
                        
                        for(var choice of option.choicelist){
                            for(var madechoice of option.choicesmade){
                                if(choice.description === madechoice){
                                    if(choice.quantity && choice.quantity > 0)
                                        this.customizationchosen = this.customizationchosen + choice.description + " (" +  choice.quantity + "), ";
                                    else    
                                        this.customizationchosen = this.customizationchosen + choice.description + ", ";
                                    totalcost = totalcost + choice.cost;
                                    if(choice.inventory && choice.inventory.length > 0)
                                        custinv = custinv + choice.inventory + "|"  + choice.cost +", ";
                                }
                            }
                        }

                    }
                }
            }
        }

        if(this.customizationchosen.length > 2 ){
            this.customizationchosen =this.customizationchosen.substring(0,this.customizationchosen.length -2);
        }

        if(custinv.length > 2 ){
            custinv =custinv.substring(0,custinv.length -1);
        }

        if(Constants.DEBUG_MODE) console.log('CUSTINV|Passwing inventory string as:' + custinv + ':');

        var qty  = this.billingService.quantityForCustomization;
        var instructions = '';
        if(this.customization.dynamicquantity){
            if(!this.validateQuantity()) return;
            qty = Utils.round(this.dynamicquantity/this.customization.qtyfactor,3);
            instructions = ' (' + this.dynamicquantity + ' ' + this.customization.qtyunit + ') ';

        }

        if(this.validateCustomization()){
            //Cartservice.addItemToCart(this.dataService.selectedcounter,this.dataService.selectedcounter.cart,this.billingService.productForCustomization,1,'', 0, 0,customizationchosen,totalcost,this.dataService,custinv);
            //var instructions = '';

            this.counterService.addProductToCart(this.billingService.productForCustomization,qty,this.customizationchosen,totalcost,instructions,0,custinv);

            this.billingService.makeCustomizationDialogVisible = false;
            var billdialog = <Billdialog> this.billingService.billdialoginstance;
            if(billdialog != null){
                billdialog.setFocusOnProduct();
                billdialog.selectLastItem();
            }
        }

        


    } 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    validateQuantity(){
        if(!this.dynamicquantity || isNaN(this.dynamicquantity) || isNaN(parseInt(this.dynamicquantity))){
            this.showErrorToast('Please enter valid quantity');
            return false;' '
        }

        return true; 
    }
    
    addtoBill(){

        

        if(this.validateCustomization()){

            var qty = Utils.round(this.dynamicquantity / 1000,3);

            //Cartservice.addItemToCart(this.dataService.selectedcounter,this.dataService.selectedcounter.cart,this.billingService.productForCustomization,1,'', 0, 0,customizationchosen,totalcost,this.dataService,custinv);
            this.counterService.addProductToCart(this.billingService.productForCustomization,qty,this.customizationchosen,0,'',0,'');

            this.billingService.makeCustomizationDialogVisible = false;
            var billdialog = <Billdialog> this.billingService.billdialoginstance;
            if(billdialog != null){
                billdialog.setFocusOnProduct();
                billdialog.selectLastItem();
            }
        }

        /*var amount = 0; 
        var quantity = 0;
        var rate = 0;

        var prod = this.billingService.productForCustomization;
        var quantity = this.billingService.quantityForCustomization;
        

        if(!isNaN(this.dynamicprice) && parseInt(this.dynamicprice) >= 0){
            amount = parseInt(this.dynamicprice);
        }

        if(!isNaN(this.dynamicrate) && parseFloat(this.dynamicrate) >= 0){
            rate = parseFloat(this.dynamicrate);
        }

        if(!isNaN(this.dynamicquantity) && parseFloat(this.dynamicquantity) >= 0){
            quantity = parseFloat(this.dynamicquantity);
        }


        if(prod.customization.minrate && parseInt(prod.customization.minrate) > 0){
            var minrate = parseFloat(prod.customization.minrate);
            if(rate < minrate){
                this.showErrorToast("Rate can not be less than " + minrate);
                return;
            }
        }

        var customization = '';
        if(prod.customization.priceforfullunit && parseInt(prod.customization.priceforfullunit) > 0){
            var baserate = parseFloat(prod.customization.priceforfullunit);
            if(rate != baserate){
                customization = '@'+ rate;
            }
        }


         if(amount > 0 && rate > 0 && quantity > 0){
            Cartservice.addItemToCart(this.dataService.selectedcounter,this.dataService.selectedcounter.cart,prod,quantity,'', rate,  amount,customization,0);
            

            this.counterService.editCounter(this.dataService.selectedcounter,false);
            this.setEmpty();

            this.billingService.makeCustomizationDialogVisible = false;

            var billdialog = <Billdialog> this.billingService.billdialoginstance;
            if(billdialog != null){
                setTimeout(()=>{billdialog.setFocusOnProduct();},100);
            }

         }




        var priceadded = false;
        var quantityadded = false;
        var rateandamount = false; 
        var israte = false; 
        var rate = 0;
        var amount = 0;
        var qty = 0.0;

        //Capture what got supplied
        var prod = this.billingService.productForCustomization;
        var quantity = this.billingService.quantityForCustomization;
        



        if(this.dynamicprice && this.dynamicprice.trim().length > 1 ){
            
            
            if(this.dynamicprice.trim().charAt(0)==='@' ){
                if(Constants.DEBUG_MODE) console.log('This is the rate');
                israte = true;
                this.dynamicprice = this.dynamicprice.trim().substring(1,this.dynamicprice.trim().length);
                if(!isNaN(this.dynamicprice) && parseInt(this.dynamicprice) >= 0){
                    priceadded = true;
                    rate = parseInt(this.dynamicprice);
                }

            }else if(this.dynamicprice.trim().indexOf(",") > 0){
                if(Constants.DEBUG_MODE) console.log('DYNAMIC Rate and amt found' );
                var arrrateamt = this.dynamicprice.trim().split(",");
                var strrate = arrrateamt[0];
                var stramt = arrrateamt[1];

                if(Constants.DEBUG_MODE) console.log('DYNAMIC Rate ' + strrate + ' AMT ' + stramt );

                if(!isNaN(strrate) && parseInt(strrate) > 0 && !isNaN(stramt) &&  parseInt(stramt) > 0  ){
                    rateandamount = true; 
                    rate = parseInt(strrate);
                    amount = parseInt(stramt);
                }

            }else{
                if(!isNaN(this.dynamicprice) && parseInt(this.dynamicprice) >= 0){
                    priceadded = true;
                    amount = parseInt(this.dynamicprice);
                }
            }
        
        }
        
        if(this.dynamicquantity && this.dynamicquantity.trim().length > 0 && !isNaN(this.dynamicquantity) && parseFloat(this.dynamicquantity) >= 0){
            quantityadded = true;
            qty= parseFloat(this.dynamicquantity);
        }

        var customization = "";
        if(rateandamount){
            customization = '@' + rate;
            quantity = Utils.round(amount/ rate,3);
        }else if(priceadded && quantityadded){
            if(israte){
                quantity = qty; 
                amount = Math.round(qty*rate);
                customization = '@' + rate;
            }else{
                quantity = qty; 
                rate = Utils.round(amount/qty,3);
                customization = '@' + rate;
            }
        }else if(priceadded){
            if(israte){
                quantity = 1; 
                customization = '@' + rate;
                amount = rate;  
            }else{
                if(prod.customization.priceforfullunit &&  parseInt(prod.customization.priceforfullunit) > 0) {
                    quantity = Utils.round(amount/ prod.customization.priceforfullunit,3);
                    rate =  parseInt(prod.customization.priceforfullunit);
                }else{
                    quantity = 1; 
                    customization = '@' + amount; 
                    rate = amount;
                }
            }
        }else if (quantityadded){
            quantity = qty; 
            if(prod.customization.priceforfullunit &&  parseInt(prod.customization.priceforfullunit) > 0) {
                rate = prod.customization.priceforfullunit;
            }else{
                rate = prod.sellingprice;
            }
            amount = Math.round(qty*rate);
        }


        
        if(this.customization.dynamicname ){
            if(!this.dynamicname || this.dynamicname.trim().length == 0 ){
                this.showErrorToast("Please provide product name");
                return;
            }
            customization = this.dynamicname;
        }

        if(quantityadded || priceadded || rateandamount){
            if(prod.customization.minrate && parseInt(prod.customization.minrate) > 0){
                var minrate = parseFloat(prod.customization.minrate);
                if(rate < minrate){
                    this.showErrorToast("Rate can not be less than " + minrate);
                    return;
                }
            }

            this.counterService.addProductToCart(prod,quantity,customization,rate,'',0,"");
            this.billingService.makeCustomizationDialogVisible = false;

            var billdialog = <Billdialog> this.billingService.billdialoginstance;
            if(billdialog != null){
                setTimeout(()=>{billdialog.setFocusOnProduct();},100);
            }

           
        }*/

    }


    ngOnInit(){

        
    }


    
}
