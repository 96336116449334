import {Billingcounter} from '../domain/billingcounter'
import {Injectable,Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Constants} from '../domain/constants'
import {Cart} from '../posdomain/cart'
import {Messages,Growl,Column,GalleriaModule, InputTextModule, OverlayPanel, ContextMenu,Message,DataTable,AutoComplete, Header,Dialog,InputText,Button, MessageService} from 'primeng/primeng';
import {DataService} from '../service/dataservice';
import {Product} from '../domain/product';
import {Billingcounterservice} from './billingcounterservice';
import { Cartlineitem } from '../domain/cartlineitem';
import { Consumercart } from '../domain/consumercart';
import { Appdataservice } from './appdataservice';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { Order } from '../domain/order';
import { Ordermanagement } from '../view/ordermanagement';
import { Kot } from '../domain/kot';
import { Router } from '@angular/router';
import { Pendingorderlist } from '../view/pendingorderlist';
import { Onlineorderlist } from '../view/onlineorderlist';


@Injectable()
export class Billingservice  {

    //List of products to be shown on screen 
    productlist : Product[];
    currentSubcategory;
    productfilter;

    //selectedCounter : Billingcounter;
    selectLineitem : Cartlineitem;
    productForCustomization  : Product;
    quantityForCustomization : number;

    
    displayBillDialog : boolean = false; 
    makediscountvisible : boolean = false;
    makeSettingsDialogVisible : boolean = false; 
    makelastorderdialogvisible : boolean = false;
    makecustomerdialogvisible : boolean = false;
    makepaymentdialogvisible : boolean = false;
    makeinstructionsdialogvisible : boolean = false;
    makequantityDialogVisible : boolean = false; 
    makecouponDialogVisible : boolean = false; 
    makeitemcancelDialogVisible : boolean = false; 
    makeremarksDialogVisible : boolean = false;
    makereceiptsDialogVisible : boolean = false; 
    makeordercancelDialogVisible : boolean = false; 
    makeDelCompletionDialogVisible : boolean = false;
    makeCustomizationDialogVisible : boolean = false; 
    makeshareselectDialogVisible : boolean = false;
    makeotpDialogvisible : boolean = false;
    makePrinterSelectDialogVisible : boolean = false; 
    makeexpenseDialogVisible : boolean = false;
    makeoperatorDialogVisible : boolean = false;
    makekotCancelDialogVisible : boolean = false;
    makederliveryDialogVisible : boolean = false;
    makeorderDetailDialogVisible : boolean = false;
    makeChangePassDialogVisible : boolean = false;
    makeScreenLockDialogVisible : boolean = false;
    makeTransferDialogVisible : boolean = false;
    makeBookingCompletionDialogVisible : boolean = false; 
    makeBookingcancelDialogVisible : boolean = false;
    makemonitoringdialogvisible : boolean = false; 
    makeSplitDialogVisible : boolean = false; 
    makeReauthDialogVisible : boolean = false; 
    makeReprintDialogVisible : boolean = false;
    makeCardDialogVisible : boolean = false; 
    makedisplayDialogVisible : boolean = false;
    
    reauthpurpose; 
    

    discountboxitemlevel : boolean = false;
    discountpostcheckout : boolean = false; 
    existingdiscountval : string;
    existinginpercent : boolean;
    existingonall : boolean;


    settingspanelvisible : boolean; 

    showitembar;
    selectedSection;

    lastreceiptno; 
    lastordervalue;

    lastcheckout; 

    checkoutinprogress : boolean = false;
    billdialoginstance;

    salesmanagementinstance; 

    qrfbstring : string = "HELLO WORLD"; 
    printqr : boolean = false; 


    hideAllDialogsApartFromBilling (){
        this.makecouponDialogVisible = false; 
        this.makediscountvisible = false;
        this.makecustomerdialogvisible = false;
        this.makepaymentdialogvisible = false;
        this.makeinstructionsdialogvisible = false;
        this.settingspanelvisible = false;
        this.makequantityDialogVisible= false;
        this.makekotCancelDialogVisible = false;
        this.makeitemcancelDialogVisible = false;
        this.makeremarksDialogVisible = false;
        this.makederliveryDialogVisible = false;
        this.makereceiptsDialogVisible = false; 
        this.makeexpenseDialogVisible = false;
        this.makeChangePassDialogVisible = false;
        this.makeoperatorDialogVisible = false;
        this.makeordercancelDialogVisible = false;
        this.makeDelCompletionDialogVisible = false;
        this.makelastorderdialogvisible = false;
        this.makeorderDetailDialogVisible = false;
        this.makeTransferDialogVisible = false;
        this.makeCustomizationDialogVisible = false;
        this.makePrinterSelectDialogVisible = false;
        this.makeshareselectDialogVisible = false;
        this.makeotpDialogvisible  = false; 
        this.makeSplitDialogVisible = false; 
        this.makeBookingCompletionDialogVisible = false;
        this.makeBookingcancelDialogVisible = false; 
        this.makemonitoringdialogvisible = false; 
        this.makeSettingsDialogVisible = false; 
        this.makeReauthDialogVisible = false;
        this.makeReprintDialogVisible = false;
        this.makeCardDialogVisible = false;
        this.makedisplayDialogVisible = true;
        
    }
 
    openBillWindow(){

        if(Constants.DEBUG_MODE) console.log('Opening bill window ' + this.counterService.selectedcounter.countername);
        //this.counterService.selectedcounter = ctr;
        this.hideAllDialogsApartFromBilling();


        this.displayBillDialog = true;
        this.checkoutinprogress = false;
        
    }


    constructor(public counterService : Billingcounterservice,
        public dataService : Appdataservice,
        private _router : Router, 
        public messageService : MessageService)
    {
        if(Constants.DEBUG_MODE) console.log('in constructor of biling service ..');
        this.dataService.selectedcounter = <Billingcounter> {};
        this.dataService.selectedcounter.cart = <Consumercart> {};
        this.productlist = [];
        
    }

    public logininstance; 

    navigateToBillingScreen(){
        console.log('|BILLINGSERVICE|Navigating to billing screen');
        this._router.navigate(['/billingscreen']);	
    }


    displayonlinebar : boolean;
    displaybookingalertbar : boolean; 
    

    orderForCancellation : Order; 
    ordermanagementInstance : Ordermanagement;  
    ccmanagementinstance : Pendingorderlist;
    upmanagementinstance : Onlineorderlist;

    pymtfromordermanagement : boolean;
    pymtfromcallcenterorder : boolean;
    pymtfromuporder : boolean;

    voidfromordermanagement : boolean;
    voidfromaggregator  :boolean;

    orderForPayment : Order;
    
    eoddate; 

    isAnyDialogOpen(){
        return this.makedisplayDialogVisible || this.makeReprintDialogVisible || this.makeCardDialogVisible ||  this.makeReauthDialogVisible || this.makeSplitDialogVisible || this.makecouponDialogVisible ||  this.makeSettingsDialogVisible || this.makemonitoringdialogvisible ||  this.makeBookingcancelDialogVisible || this.makeBookingCompletionDialogVisible ||  this.makeotpDialogvisible || this.makeshareselectDialogVisible || this.makePrinterSelectDialogVisible || this.makeCustomizationDialogVisible || this.makeTransferDialogVisible || this.makeorderDetailDialogVisible || this.makelastorderdialogvisible || this.makeDelCompletionDialogVisible || this.makederliveryDialogVisible ||   this.makeordercancelDialogVisible || this.makeoperatorDialogVisible ||  this.makeChangePassDialogVisible || this.makeScreenLockDialogVisible || this.makeexpenseDialogVisible || this.makereceiptsDialogVisible ||  this.makederliveryDialogVisible || this.makeremarksDialogVisible || this.makeitemcancelDialogVisible ||  this.makekotCancelDialogVisible || this.makequantityDialogVisible || this.makeinstructionsdialogvisible || this.makecustomerdialogvisible || this.makediscountvisible || this.makepaymentdialogvisible || this.settingspanelvisible;
    }


    setInstructions(instructions : string){
        this.selectLineitem.instructions = instructions;
        this.counterService.selectedcounter.cart.lineitems = [... this.counterService.selectedcounter.cart.lineitems];
        this.makeinstructionsdialogvisible = false;
    }
    
    setDefaultlist(){
        if(this.dataService.showsubcatinmenu && this.dataService.showsubcatbydefault){
            this.makesubcatlistvisible = true;
        }else{
            this.showRecommendedProducts();
            this.makesubcatlistvisible = false;
        }
    }

    showRecommendedProducts(){
        this.productlist = this.dataService.recommendedproducts;
        this.productlist = [... this.productlist];
    }


    filterProducts(){
        if(this.productfilter && this.productfilter.length > 0)
        {
             var prodlist : Product[] = [];
             for(var prod of this.dataService.productsforsale){
                 if(this.productfilter && this.productfilter.length > 0){
                     if(prod.code === this.productfilter || prod.name.toLowerCase().indexOf(this.productfilter.toLowerCase()) >= 0 ||  prod.generatedcode.toLowerCase().indexOf(this.productfilter.toLowerCase()) >= 0 ) {
                         prodlist.push(prod);
                     }
                 }else{
                     prodlist.push(prod);
                 }
             }
             
             this.productlist = prodlist;
             this.productlist= [... this.productlist];
         }
 
       
    }


    showcountername;

    applyDiscountOnAllItems(discountval){

        
    }

    applyDiscountOnCategory(discountval,catid){
        
    }

    
    
    applyDiscountOnPresetCategories(discountval){
       
        
    }

    


    applyDiscount(discountval, discountinpercentage, discountonall)
    {
        if(this.discountboxitemlevel){
            //this.counterService.applyItemDiscount(this.selectLineitem,discountval,discountinpercentage,'');
        }else{
            //Cartservice.applyDiscountOnBill(cart,this.dataService,discountval,discountinpercentage,discountonall,counter);
        }
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    //Checkout services! .. operator dialog .. and checkout servics..
    validateCheckout(forproforma)
    {

    
        
        return true;
    }


    async proforma(printreceipt : boolean){
    
        
    }


    closeBillingDialog(){
        if(window.innerWidth < 700){
           // this._router.navigate(['/mobilebillingscreen']);
        }else{
                this.displayBillDialog = false;
        }
    }
    
  

    //Make category list visible 
    makesubcatlistvisible : boolean = false;
    iscustfound : boolean = false; 
    mobilefornewcust;  

    deliveryfromcheckout = false; 
    paymentfromcheckout = true;


    //reprint related vars
    reprinttype; //Order / KOT 
    orderForReprint : Order;    
    kotForReprint : Kot;
    
    
}