import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {DataService}  from '../service/dataservice';
import {Product}      from '../domain/product';
import {Constants}    from '../domain/constants';
import {Retailer}     from '../domain/retailer';
import {Headers, RequestOptions} from '@angular/http';
import { Appdataservice } from '../service/appdataservice';

@Component({
    templateUrl: 'setaggregatorstatus.html',
    providers: [ConfirmationService]
})
export class Setaggregatorstatus implements OnInit 
{
    isRunning = false; 
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private confirmationService: ConfirmationService,

        private messageService : MessageService
        ) {
            if(!dataService.isuserloggedin){
                this._router.navigate(['/']);
            }
    }
   
    displayDetailDialog : boolean = false;


    showErrorToast(message){
        this.messageService.add({severity:'error',  key: 'inventorytoast', closable: true, summary: 'Error', detail:message});
        this.isRunning = false;
    }

    

    platforms : string[];
    productlist : string[];

    selectedItems : Product[];

    activate(){
        if(!this.selectedItems || this.selectedItems.length == 0){
            this.showErrorToast('Please select atleast one item!');
            return false;
        }
        
        var confirmessage = 'Are you sure you want activate ' + this.selectedItems.length + ' products?';
        this.confirmationService.confirm({
            message: confirmessage,
            accept: () => {
                //Actual logic to perform a confirmation
                this.activateProducts();
            }
        });
        
        return true;
 
    }

    ngOnInit(){

    }


    async activateProducts(){

        this.isRunning  = true;

        for(var prod of this.selectedItems)
            prod.activeonapp  = true;

        var ret = await this.dataService.updateProductsBulk(this.selectedItems);
        this.isRunning = false; 
        this.messageService.clear();
        
        //Now update 
        if(ret) {
            for(var prod of this.selectedItems){
                var curprod = this.dataService.getProductForId(prod.id);
                curprod.activeonapp = true;
            }
            
            this.selectedItems = [];
            this.dataService.productsforsale = [... this.dataService.productsforsale];
            this.messageService.add({severity:'success',  key: 'inventorytoast', closable: true, summary: 'Success', detail:'Products activated successfully!'});


        }else
            this.showErrorToast('Error in updating products, please try again!');
    }


    deactivate(){
        if(!this.selectedItems || this.selectedItems.length == 0){
            this.showErrorToast('Please select atleast one item!');
            return false;
        }
        
        var confirmessage = 'Are you sure you want de-activate ' + this.selectedItems.length + ' products?';
        this.confirmationService.confirm({
            message: confirmessage,
            accept: () => {
                //Actual logic to perform a confirmation
                this.deactivateProducts();
            }
        });
        
        return true;
 
    }


    async deactivateProducts(){

        this.isRunning  = true;

        for(var prod of this.selectedItems)
            prod.activeonapp  = false;

        var ret = await this.dataService.updateProductsBulk(this.selectedItems);
        this.isRunning = false; 
        this.messageService.clear();
        
        //Now update 
        if(ret){ 
            for(var prod of this.selectedItems){
                var curprod = this.dataService.getProductForId(prod.id);
                curprod.activeonapp = false;
            }
            
            this.selectedItems = [];
            this.dataService.productsforsale = [... this.dataService.productsforsale];
            this.messageService.add({severity:'success',  key: 'inventorytoast', closable: true, summary: 'Success', detail:'Products de-activated successfully!'});
        }else
            this.showErrorToast('Error in updating products, please try again!');
    }

    
    
   
   

}


