import {Printer} from '../domain/printer'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';

@Injectable()
export class Printerservice{

     printerlist : Printer[];
     dvr : Printer;

     constructor(
         private couchdbService : Couchdbservice,
         private dynamoDBService : Dynamodbservice,
         private dataService : Appdataservice
     ){}

     public getNewId(){
         var maxid = 0;
         for(var ctr  of this.printerlist){
             if(ctr.id > maxid)
                maxid = ctr.id;
         }
         return maxid + 1; 
     }

     
     addEditInList(printer : Printer){
         
       var found = false; 
       for(var i=0;i<this.printerlist.length; i++){
           if(this.printerlist[i].id === printer.id){
               this.printerlist[i] = printer;
               found = true; 
               break;
           }
       }

       if(!found){
           this.printerlist.push(printer);
       }
    }


     public checkDuplicate(printer : Printer){
        for(var ctr  of this.printerlist){
            if(ctr.id != printer.id && ctr.printername == printer.printername)
               return true;
        }

        return false; 
    }


    getPrinterNameForId(id){
        if(this.printerlist){
            for(var printer of this.printerlist){
                if(printer.id === id) return printer.printername ; 
            }
        }
        return "";
    }

    getPrinterForId(id){
        for(var printer of this.printerlist){
            if(printer.id === id) return printer; 
        }
        return null;
    }
    
    getPrinterIdForName(name){
        for(var printer of this.printerlist){
            if(printer.printername === name) return printer.id; 
        }
        return 0;
    }

       
    getPrinter(name){
        for(var printer of this.printerlist){
            if(printer.printername === name) return printer; 
        }
        return null;
    }


    

    
    async initPrinters(){
        this.printerlist = [];
        //if(Constants.DEBUG_MODE) console.log('|DBOYSEVICE|Fetching from couch');
        //var retlist = await this.couchdbService.getAllItems('printers');

        var retlist;
        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.getAllItemsForPosId('printers');
            if(retlist) this.printerlist = <Printer[]> retlist;
        }else{
            retlist = await this.couchdbService.getAllItems('printers');
            if(retlist) this.printerlist = <Printer[]> retlist.docs;
        }


        if(retlist){
            
            for(var printer of this.printerlist){
                if(printer.printername == 'DVR'){
                    this.dvr = printer; 
                }
            }
            return true;
        }else{
            return false;
        }
        
     }

     
     
     async save(printer){
        if(Constants.DEBUG_MODE) console.log('|PRINTERS|Saving printer ' + JSON.stringify(printer));
        //var status= await this.couchdbService.putItem('printers',printer);

        var status;
        if(this.dataService.usedynamo){
            status = await this.dynamoDBService.putItem('printers',printer);    
        }else{
            status = await this.couchdbService.putItem('printers',printer);
        }


        if(status != "ERROR" && status != "CONFLICT"){
            this.addEditInList(printer);
            this.printerlist = [... this.printerlist];
            return status;
        }else{
            return status;
        }
        
     }
   



    

}