import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from '../domain/order';
import { Appdataservice } from '../service/appdataservice';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Orderservice } from '../service/orderservice';
import { Constants } from '../domain/constants';
import { Utils } from '../service/utils';
import { Deliveryboyservice } from '../service/deliveryboyservice';
import { Aggregatorservice } from '../service/aggregatorservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Callcenterservice } from '../service/callcenterservice';
import { Userservice } from '../service/userservice';
import { Customer } from '../domain/customer';
import { Kitchenservice } from '../service/kitchenservice';
import { Billingservice } from '../service/billingservice';
import { Printingservice } from '../service/printingservice';
import { UPMessage } from '../updomain/upmessage';
import { Urbanpiperservice } from '../service/urbanpiperservice';
import { timeStamp } from 'console';
import { jsonpCallbackContext } from '@angular/common/http/src/module';


@Component({
    templateUrl: 'onlineorderlist.html',
    providers: [ConfirmationService]
})
export class Onlineorderlist implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    statusoptions : SelectItem[];
    selectedstatus; 
    currentviewtype;

    isRunning = false; 
    
    showpendingonly;

    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private counterService : Billingcounterservice,
        private aggregagtorService : Aggregatorservice,
        private orderService  : Orderservice,
        public upService : Urbanpiperservice,
        public printingService : Printingservice,
        private userService : Userservice,
        private billingService : Billingservice,
        private kitchenService : Kitchenservice,
        private deliveryboyService : Deliveryboyservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    getTips(ord: Order){

        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == Constants.PYMT_CARD){
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        try{
                            var tip = parseFloat(strtip);
                            return tip;
                        }catch(err){}
                    }
                }
        
            }
        }
        return 0;
    }

    cardwidth;
    setCardWidth(){
        if(window.innerWidth < 500){
            this.cardwidth = window.innerWidth - 10;
        }else if(window.innerWidth < 1200){
            this.cardwidth = window.innerWidth / 3 - 10 ;
        }else{
            this.cardwidth = window.innerWidth / 4 - 10 ;
        }


    }

    deliveryboys;
        
    ngOnInit(){
        //this.fromdate = new Date(new Date().setHours(0,0,0,0));
        //this.todate = new Date(new Date().setHours(0,0,0,0));
        console.log('On Init Called!');
        this.setCardWidth();
    
        this.orderlist = [];
        this.setData(null);

        this.deliveryboys =[];
        this.locationlist = [];


        for(var boy of this.deliveryboyService.deliveryboylist){
            if(boy.active){
                var boydetails = boy.name + ' (' + boy.mobilenumber + ') ';
                this.deliveryboys.push({label: boydetails, value: boydetails});
                if(this.deliveredby == null){
                    this.deliveredby = boydetails;
                }
            }
        }

        if(this.dataService.retailer.multilocation){
            for(var loc of this.dataService.locationlist){
                if(loc.id != this.dataService.retailer.locationid){
                    this.locationlist.push({label: loc.locationname, value: loc.id});
                }
            }
        }

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    setDeliveredBy(ord : Order){
        var dboy = this.deliveryboyService.getDeliveryboyStringForId(ord.deliveredby);
        ord.deliveredbystr = dboy;
    }

            
    first : number = 0; 

    showErrorToast(message){
        this.showspinner = false;
         
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 
    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
        //if(ord.orderstatus != Constants.POS_ORDER_CANCELLED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;
            this.totalreceivableamount = this.totalreceivableamount + ord.cart.pendingamount;
        //}
    }

    roundTotals(){
        
    }

    selectedRadio;



    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
    }

    findInArray(arrtokens,newtok){
        for(var item of arrtokens){
            if(item == newtok) return true; 
        }
        return false; 
    }
    
    setKOTTokens(ord : Order){
        var arrtoken = [];
        if( ord.cart && ord.cart.lineitems != null && ord.cart.lineitems.length > 0){
            for(var item of ord.cart.lineitems){
                if(!this.findInArray(arrtoken,item.kottoken)){
                    arrtoken.push(item.kottoken);
                }
            }

        }
        ord.kotnum = arrtoken.join("|");
        ord.kotcount = arrtoken.length;


    }
    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
            /*if(ord.countertype == Constants.ONLINE){
                var counter = this.counterService.getBillingcounterForId(ord.counterid);
                ord.aggrname = counter.aggregatorname;
            }*/
        
            
            ord.details = Utils.getOrderDetails(ord);
        }

        //Set mode wise payments on order 
        ord.pymtcash = Utils.getModeWisePayment(Constants.PYMT_CASH,ord);
        ord.pymtcard = Utils.getModeWisePayment(Constants.PYMT_CARD,ord);
        ord.tips = this.getTips(ord);
        ord.pymtonline = Utils.getModeWisePayment(Constants.PYMT_PG,ord);
        ord.pymtforex = Utils.getModeWisePayment(Constants.PYMT_FOREX,ord);
        ord.pymtcustwallet = Utils.getModeWisePayment(Constants.PYMT_WALLET,ord);
        ord.pymtaggregator = Utils.getModeWisePayment(Constants.PYMT_AGGREGATOR,ord);
        ord.pymtcomplimentary = Utils.getModeWisePayment(Constants.PYMT_COMPLIMENTARY,ord);
        ord.pymtzomatopro = Utils.getModeWisePayment(Constants.PYMT_ZOMATO_PRO,ord);
        ord.pymtothercredit = Utils.getModeWisePayment(Constants.PYMT_OTHER_CREDIT,ord);
        ord.pymtcoupon = Utils.getModeWisePayment(Constants.PYMT_COUPON,ord);
        
        if (ord.receiptno && ord.receiptno.length > 0){
            if(ord.receiptno.indexOf("/") > 0){
                ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
            }else{
                ord.rcptnodisplay = ord.receiptno;
            }
        }
        

    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }



    bifurcateDiscounts ( ord : Order){
        ord.fooddisc = 0;
        ord.bevdisc = 0;
        ord.misdisc = 0;
        ord.tobdisc = 0;
         
        for(var item of ord.cart.lineitems){
            if(item.discount > 0){
                var prod = this.dataService.getProductForId(item.productid);
                if(prod){
                    var cat = this.dataService.getCategoryForId(prod.categoryid);
                    if(cat && cat.name.toUpperCase().indexOf("BEV") >= 0){
                        ord.bevdisc = Utils.round(ord.bevdisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("FOOD") >= 0){
                        ord.fooddisc = Utils.round(ord.fooddisc + item.discount,3);
                    }else if (cat && cat.name.toUpperCase().indexOf("TOB") >= 0){
                        ord.tobdisc = Utils.round(ord.tobdisc + item.discount,3);
                    }else{
                        ord.misdisc = Utils.round(ord.misdisc + item.discount,3);
                    }
                }
            }
        } 
    }


    checkBillingPrivs(){
        if(this.userService.loggedinusergroup && this.userService.loggedinusergroup.arrbillingprivs){
            if(this.userService.checkBillingPriv(Constants.PRIV_SALES)){
                return true; 
            }
        }

        this.showErrorToast('Billing privileges not assigned to this user!');
        return false; 
    }

    showspinner;

    async reprintKOT(order : Order){
        var kotarray = this.kitchenService.getKOTArrayFromCart(order.cart);

        
        if(kotarray && kotarray.length > 0){
            
            var kotforreprint = kotarray[0];
            kotforreprint.agentname = order.billedbystr;

            this.billingService.reprinttype = 'kot';
            this.billingService.kotForReprint = kotforreprint;

            this.billingService.makeReprintDialogVisible = true;
 
        

        }

    }

    
    async migrateOrder(){
        var upord = this.getUPOrderforPoSOrderId(this.selectedOrder.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        console.log('|MIGRATE|Will migrate up order ' + upord.id);

        
        var ord = this.orderService.getClone(this.selectedOrder);
        var newposid = this.dataService.retailer.id + "|" + this.newlocation;
        if(newposid == this.selectedOrder.posid){
            this.showErrorToast('New location same as old location!');
            return;
        }

        if(!this.migratereason || this.migratereason.trim() == '' ){
            this.showErrorToast('Please provide migration reason!');
            return;
        }

        //Change the location details 
        upord.oldposid = this.selectedOrder.posid;
        upord.posid = newposid;
        upord.migratereason = this.migratereason;
        upord.posorder.posid = newposid; //Will remove as well 

        //Remove the pos order propoert
        //delete upord.posorder;

        this.showspinner = true; 
        var ret = await this.dynamoService.putItem('uponlineorders', upord);
        console.log('|MIGRATE| RET ' + ret);
        if(ret == 'DONE'){
            console.log('|MIGRATE| DELETING KEY ' + JSON.stringify({posid : upord.oldposid , id: upord.id}));
            var ret1 = await this.dynamoService.deleteItem('uponlineorders', {posid : upord.oldposid , id: upord.id});
        }else{
            this.showErrorToast('Error in migration, please try again!');
            return;
        }
        await this.setData(null);
        this.showspinner = false; 

        this.displaymigrateDialog = false; 
    }

    
    migratereason; 
    locationlist;
    newlocation;
    displaymigrateDialog;

    showMigrationDialog(ord : Order){
        this.selectedOrder = this.orderService.getClone(ord);
        this.newlocation =  this.dataService.locationlist[0].id;
        this.migratereason = '';
        this.displaymigrateDialog = true;
    }


    displayacceptDialog;

    async settlementAdvice(){
        document.getElementById("printable").innerHTML = this.printingService.printSettlementAdvice(this.selectedOrder);
        setTimeout(()=>{ window.print();}, 200);
    }


    confirmAccept(ord: Order){
        this.selectedOrder = this.orderService.getClone(ord);
        this.selectedOrder.addressstr  = this.selectedOrder.deladdrline1 + ", " + this.selectedOrder.deladdrline2 + ", " + this.selectedOrder.deladdrline3;
        this.displayacceptDialog = true;
    }

    printReceipt(ord : Order){
        document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(ord,false);
        console.log('|HTML|' + document.getElementById("printable").innerHTML);
        setTimeout(()=>{ window.print();}, 200);
    }
    
    

    async markFoodReady(order : Order){
        this.showspinner = true; 

        var ord = this.orderService.getClone(order);
        ord.orderstatus = Constants.FOOD_READY;
        ord.foodreadyon = new Date().getTime();
        
        
        var upord = this.getUPOrderforPoSOrderId(order.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        var upateUPStatus = await this.upService.updateStatusOnUrbanPiper(upord.id,'Food Ready','',upord.ispizzpan,upord.issfcplus);
        /*if(!upateUPStatus){
            this.showErrorToast("Error updating status on urban piper,please try again!");
            return;
        }*/

        upord.orderstatus =  Constants.FOOD_READY;
        upord.posfoodreadyon = this.dynamoService.getSessionId();

        var ret = await this.dynamoService.putItem('uponlineorders', upord);
        if(ret == 'DONE'){
            await this.setData(null);
       
        }else{
            this.setData(null);

            this.showErrorToast("Error while updating order, please try again!");
            return;
        }

        this.showspinner = false; 
       

    }

    confirmDispatch(ord: Order){
        this.selectedOrder = this.orderService.getClone(ord);
        this.displayDispatchDialog = true;
    }
    

    displayDispatchDialog;
    deliveredby;
    
    async dispatchOrder(){
        this.selectedOrder.deliveredby = this.deliveredby;
        this.selectedOrder.deliveryon = new Date().getTime();

        this.selectedOrder.orderstatus  = Constants.OUT_FOR_DELIVERY;
        this.showspinner = true;
        var upord = this.getUPOrderforPoSOrderId(this.selectedOrder.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        var upateUPStatus = await this.upService.updateStatusOnUrbanPiper(upord.id,'Dispatched','',upord.ispizzpan,upord.issfcplus);
        /*if(!upateUPStatus){
            this.showErrorToast("Error updating status on urban piper,please try again!");
            return;
        }*/
        
        upord.orderstatus =  Constants.OUT_FOR_DELIVERY;
        upord.posdispatchedon = this.dynamoService.getSessionId();

        var ret = await this.dynamoService.putItem('uponlineorders', upord);
        await this.setData(null);
        this.displayDispatchDialog= false;
            
    }

    getFreeOnlineCounter(uporder : UPMessage){
        var aggrid=  this.aggregagtorService.getAggregatorFromUpOrder(uporder);
        for(var counter of this.counterService.activelist){
            if(aggrid == counter.aggregatorid){
                return counter;    
            }
        }
        return null;
    }


    completionrunning; 

    async markCompleted(){
        if(this.completionrunning ) return; 
        this.completionrunning = true;
        
        var ord = this.selectedOrder;
        //console.log('KEY ' + JSON.stringify(key));
        var upord = this.getUPOrderforPoSOrderId(ord.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        var upateUPStatus = await this.upService.updateStatusOnUrbanPiper(upord.id,'Completed','',upord.ispizzpan,upord.issfcplus);
        /*if(!upateUPStatus){
            this.showErrorToast("Error updating status on urban piper,please try again!");
            return;
        }*/

        /*var key  = {"posid": "" + ord.posid, "id" : upord.id};
        var uporder  = <Order> await this.dynamoService.getItem('uponlineorders',key);
        if(uporder){
            //If this is already completed, then just return from here 
            if(uporder.orderstatus == Constants.ORDER_EXECUTED){
                await this.setData(null);
                this.showspinner = false;
                this.completionrunning = false;
                return;         
            }
        }*/

        //ord.orderstatus  = Constants.ORDER_EXECUTED;
        upord.orderstatus = Constants.ORDER_EXECUTED;
        upord.poscompletedon = this.dynamoService.getSessionId();

        this.showspinner = true; 
        var retstatus = await this.dynamoService.putItem('uponlineorders', upord);
        
        var neword  = this.orderService.getClone(ord);
        neword.orderstatus= Constants.POS_ORDER_PROCESSED;
        neword.checkedout = true; 

        await this.dynamoService.putItem('orders', neword);
        await this.setData(null);
        this.showspinner = false;
        this.completionrunning = false;
        
    }


    async voidOrder(ord : Order){
        this.selectedOrder  = ord;
        
        
    }

    async markVoid(ord : Order){
        this.selectedOrder = ord;
        console.log('|ONCANCEL|ORD ID ' + ord.id);

        var upord = this.getUPOrderforPoSOrderId(ord.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        /**
         * This is for cancellation, if allowed that is ! 
         */
        var upateUPStatus = await this.upService.updateStatusOnUrbanPiper(upord.id,'Cancelled',ord.cancelreason,upord.ispizzpan,upord.issfcplus);
        /*if(!upateUPStatus){
            this.showErrorToast("Error updating status on urban piper, please try again!");
            return;
        }*/

        upord.orderstatus = Constants.ORDER_CANCELLED;
        
        this.showspinner = true; 
        var retstatus = await this.dynamoService.putItem('uponlineorders', upord);
        await this.setData(null);
        this.showspinner = false;
        
    }
        
    
    
    async markCancelled(ord : Order){
        
        this.selectedOrder = ord;
        console.log('|ONCANCEL|ORD ID ' + ord.id);

        var upord = this.getUPOrderforPoSOrderId(ord.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        upord.orderstatus = Constants.ORDER_CANCELLED;

        this.showspinner = true; 
        var retstatus = await this.dynamoService.putItem('uponlineorders', upord);
        
        var neword  = this.orderService.getClone(ord);
        neword.orderstatus= Constants.POS_ORDER_CANCELLED;
        neword.checkedout = true; 

        console.log('|ONCANCEL|NEW ORD ID ' + neword.id);

        await this.dynamoService.putItem('orders', neword);
        await this.setData(null);
        this.showspinner = false;
        
        
        
    }




    async completeOrder(ord : Order){
        this.selectedOrder  = ord;
        if(ord.cart.pendingamount > 0){
            this.showPaymentDialog();
        }else{
            this.markCompleted();
        } 
    }

    getUPOrderforPoSOrderId(posorderid){
        for(var upord of this.upService.uporderinprocess){
            console.log('|UPORDERS|UP ORDER ' + upord.id);
            if(upord.posorder && upord.posorder.id == posorderid){
                return upord;
            }

            
        }

        return null;

    }

    

    async acceptOrder(){
        var order = this.selectedOrder;
        this.showspinner = true; 
        //order.orderstatus = Constants.CONFIRMED_ORDER;

        var upord = this.getUPOrderforPoSOrderId(order.id);
        if(!upord){
            this.showErrorToast("Error in getting Online order, please try again!");
            return;
        }

        console.log('*** UP ORDER ' + JSON.stringify(upord));
        var upateUPStatus = await this.upService.updateStatusOnUrbanPiper(upord.id,'Acknowledged','',upord.ispizzpan,upord.issfcplus);
        if(!upateUPStatus){
            this.showErrorToast("Error updating status on urban piper, please try again!");
            return;
        }

        order.receiptno = await this.orderService.getNewReceiptNo();
        order.orderstatus = Constants.POS_ORDER_PROCESSED;
        upord.posorder = order;
        
        upord.orderstatus =  Constants.CONFIRMED_ORDER;
        upord.posacceptedon = this.dynamoService.getSessionId();

        var ret = await this.dynamoService.putItem('uponlineorders', upord);
        if(ret == 'DONE'){
            var counter = this.counterService.getBillingcounterForId(order.counterid);
            var retkot = await this.kitchenService.generateCCKOTs(order,counter);
            if(retkot != 'ok'){
                order.kotprinted = false; 
            }else{
                order.kotprinted = true;
            }

            var ret = await this.dynamoService.putItem('orders', order);
            this.settlementAdvice();
            await this.setData(null);

        }else{
            this.showErrorToast("Error while updating order, please try again!");
            return;
        }


        this.showspinner = false;
        this.displayacceptDialog = false; 
        //this.showSuccessToast('Order accepted');


    }

    showPaymentDialog(){
        if(this.selectedOrder && this.selectedOrder.cart){
            if(!this.selectedOrder.cart.paymentlist) this.selectedOrder.cart.paymentlist = [];
            this.billingService.orderForPayment = this.selectedOrder;
            console.log('Cart Net payable ' + this.selectedOrder.cart.netpayable);
            this.billingService.upmanagementinstance = this; 
               
            this.billingService.pymtfromordermanagement = false; 
            this.billingService.pymtfromcallcenterorder = false;
            this.billingService.pymtfromuporder = true;
             
            this.billingService.makepaymentdialogvisible = true;
        
        }
        
    }


    showVoidDialog(ord : Order){
        this.selectedOrder = ord;
        this.billingService.orderForCancellation = this.selectedOrder;
        this.billingService.upmanagementinstance = this; 
        this.billingService.voidfromordermanagement = false; 
        this.billingService.voidfromaggregator =true;
        
        this.billingService.makeordercancelDialogVisible = true;
        
    }

    


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){
                var ord = this.orderService.getClone(item);
                
                //Get status 
                ord.statusstr = Utils.getStatusStr(ord);
                ord.orderitemsstr = Utils.itemsAsString(ord);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
                
                this.setDateStrings(ord);
                this.setPaymentString(ord);
                this.bifurcateDiscounts(ord);
                this.filteredlist.push(ord);
                this.incrementTotals(ord);
                
                if(this.dataService.retailer.id == 1554){
                    ord.vatamt = Utils.round(ord.cart.sumitemtaxable + ord.cart.sumsc,3);
                }
        
        }
        this.filteredlist = [... this.filteredlist];
            

    }

    dunzostatus; 
    
    async setData(dt : Table){
        this.showspinner = true; 
        var orderlist = await this.upService.fetchPendingorders();
        if(orderlist){
            this.upService.generatePoSOrdersFromUPOrders(orderlist);
            this.upService.uporderinprocess = orderlist;

            if(this.upService.areNewOrders()){
                this.upService.playAudio();
            }
        }
        this.showspinner = false;
    }
    
    
}


