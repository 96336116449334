import {Component,OnInit,Input,ViewChild,ElementRef, HostListener} from '@angular/core';
import {Billingscreen} from './billingscreen';
import {DataService} from '../service/dataservice';
import {Billingservice} from '../service/billingservice';

import { MessageService, SelectItem } from 'primeng/api';
import { Constants } from '../domain/constants';
import { Consumercart } from '../domain/consumercart';
import { Appdataservice } from '../service/appdataservice';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Utils } from '../service/utils';
import { Discountdetail } from '../domain/discountdetail';
import { Cartlineitem } from '../domain/cartlineitem';
import { JsonPipe } from '@angular/common';
import { Discountpartnerservice } from '../service/discountpartnerservice';
import { Gulfnewscode } from '../domain/gulfnewscode';


@Component({
    selector: 'discount-dlg',
    templateUrl: './discountcomponent.html',

})
export class Discountcomponent{

    appliedon : string;  
    discountDetail : Discountdetail;
    isother;
    
    partnereoptions : SelectItem[];

    @ViewChild('disc') txtDiscountbox : ElementRef;
    constructor(public billingService : Billingservice,
            public dataService : Appdataservice,
            public counterService : Billingcounterservice,
            public partnerService : Discountpartnerservice,
            public messageService : MessageService,
            ) {}


    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) { 
        if(this.billingService.makediscountvisible){
            if(event.keyCode == 13  ) {
                  this.applyDiscount();
            }
        }
    }



    getStyleClass(){
        if(window.innerWidth < 700) return 'discDialog';
        else return 'discDialogMobile'; 
    }


    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false; 
    }


    setDiscountPercent(discval){
        this.discountDetail.discountvalue = discval;

    }
    calculateApplicablDiscount(cart : Consumercart,discpercent){
        var applicableamount =0;
        
        for (var item of cart.lineitems){
            if(this.appliedon === "1"){ 
                var category = this.dataService.getCategoryForId(item.catid);
                if(category && category.discountapplicable) {
                    applicableamount = applicableamount + item.amount;
                }
            }else if(this.appliedon === "2"){
                applicableamount = applicableamount + item.amount;
            }else{
                if(item.catid === this.selectedcategory){
                    applicableamount = applicableamount + item.amount;
                }
            } 
        }

        if(applicableamount > 0)
            return  Utils.round(applicableamount * discpercent/ 100.0,2);
        else
            return 0;
                

    }

    checkApplicability(item : Cartlineitem){
        switch(this.discountDetail.applicablefor){
            case Constants.DISC_FOR_ALL_ITEMS: return true; 
            case Constants.DISC_FOR_SELECTED_CATS:
                for(var val of this.discountDetail.appliedon){
                    if(val == item.catid) return true; 
                }
                break;
            case Constants.DISC_FOR_SELECTED_SUBCATS:
                for(var val of this.discountDetail.appliedon){
                    if(val == item.subcatid) return true; 
                }
                break;
            case Constants.DISC_FOR_SELECTED_PRODS:
                for(var val of this.discountDetail.appliedon){
                    if(val == item.productid) return true; 
                }
                break;
        }
        return false;
    }

    isRunning: boolean = false;
    showspinner: boolean = false; 
    
    async clearDiscount(){
        var discval = 0;
        this.showspinner = true;
        var cart = this.counterService.selectedcounter.cart;

        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data, it is refreshed now!');
            this.billingService.makediscountvisible = false; 
            return;
        }

        /*if(cart.discountdetail.discountcode == 83){
            var ret = await this.partnerService.clearGulfnewsCode(cart.discountdetail.remarks.trim());
            if(ret != 'DONE'){
                this.showErrorToast('Error while clearing gulf news code, please try again!');
                this.billingService.makediscountvisible = false; 
                return;
    
            }
        }*/

        //Apply now
        var len = cart.lineitems.length;
        for(var i =0; i<  len;i++){
            var item = cart.lineitems[i];
            console.log('|DISC|Checking applicability for ' + item.productname);
            if(this.checkApplicability(item)){
                this.counterService.applyItemDiscount(item,discval);
            }
        }

        cart.discountdetail = null;

        await this.counterService.saveSelectedCounter();
        this.showspinner = false;
        this.billingService.makediscountvisible = false;
        
    }

    checkScheme(){
        for(var item of this.counterService.selectedcounter.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){
                this.showErrorToast("Scheme has already been applied, cant add amount discount to this bill!");
                return false;    
            }
        }
        
        return true;
    }


    checkSchemeForItems(){
        for(var item of this.counterService.selectedcounter.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){
                if(this.checkApplicability(item)){
                    this.showErrorToast("Scheme has already been applied to item: " + item.productname + ", cant apply further discount!");
                    return false;    
                }
            }
        }
        
        return true;
    }

    async applyDiscount(){
        
        var cart = this.counterService.selectedcounter.cart;
        var discval = this.discountDetail.discountvalue;
        this.showspinner = true;


        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data, it is refreshed now!');
            this.billingService.makediscountvisible = false; 
            return;
        }
        
        if(isNaN(discval) || isNaN(parseInt(discval)) || parseInt(discval) <= 0  ){
            this.showErrorToast("Invalid discount value, must be numeric and greater than 0.");
            return;
        }

        //Gulf news
        if(this.discountDetail.discountcode == 83){
            if(!this.discountDetail.remarks || this.discountDetail.remarks.trim().length == 0 ){
                this.showErrorToast("Please provide Gulf news coupon code!");
                return;
            }
            var retcode = await this.partnerService.getGulfnewsCode(this.discountDetail.remarks);
            if(retcode && retcode.code == this.discountDetail.remarks.trim()){
                if(retcode.usagestatus == 1){
                    this.showErrorToast("This code has already been used!");
                    return;
                }
           }else{
                this.showErrorToast("Gulf news Code not found!");
                return;
            }
        }

        console.log('Partner Id  ' + this.discountDetail.partnerid);
        console.log('discount code  ' + this.discountDetail.discountcode);
        
        if(this.discountDetail.partnerid > 0 || this.discountDetail.discountcode == 83){
            if(!this.discountDetail.guestname){
                this.showErrorToast("Please provide guest name for discount!");
                return;
            }
            if(this.discountDetail.guestname.trim().length == 0){
                this.showErrorToast("Please provide guest name for discount!");
                return;
            }

            
            if(!this.discountDetail.guestdetails){
                this.showErrorToast("Please provide guest details for discount!");
                return;

            } 
            
            if(this.discountDetail.guestdetails.trim().length == 0){
                  this.showErrorToast("Please provide guest details for discount!");
                return;
            }

        }

        
        //console.log('Discount in percentage: ' + discountinpercentage);
        if(this.discountDetail.discounttype == "1" ){
            if(parseInt(discval) > 100 ){
                this.showErrorToast("Discount can not be greater than 100%");
                return;
            }

            if(!this.checkSchemeForItems()) return; 
            
            if(this.discountDetail.discountcode == 83){
                var ret = await this.partnerService.consumeGulfnewsCode(this.discountDetail.remarks.trim());
                if(ret != 'DONE'){
                    this.showErrorToast("Error, please try again!");
                    return;
                }
            }
            
            //Apply now
            var len = cart.lineitems.length;
            for(var i =0; i<  len;i++){
                var item = cart.lineitems[i];
                console.log('|DISC|Checking applicability for ' + item.productname);
                if(this.checkApplicability(item)){
                        this.counterService.applyItemDiscount(item,discval);
                }
            }

            
        }else{
            console.log('Checking discount value');
            if(parseInt(discval) > cart.netpayable){
                this.showErrorToast("Discount can not be greater than payable amount");
                return;
            }

            if(!this.checkScheme()) return; 

            if(this.discountDetail.discountcode == 83){
                var ret = await this.partnerService.consumeGulfnewsCode(this.discountDetail.remarks.trim());
                if(ret != 'DONE'){
                    this.showErrorToast("Error, please try again!");
                    return;
                }
            }


            //find out the percentage 
            var discper = Utils.round(discval/cart.sumitemnet*100,3);

            console.log('|DISC JSON|' + 'Cart Length ' + cart.lineitems.length);
            
            //Apply now
            var len = cart.lineitems.length;
            for(var i =0; i<  len;i++){
                var item = cart.lineitems[i];
                this.counterService.applyItemDiscount(item,discper);
            }

        }


        cart.discountdetail = this.discountDetail;

        await this.counterService.saveSelectedCounter();
        this.showspinner = false;
        this.billingService.makediscountvisible = false;

        
        
    
    }

    cartcategories : SelectItem[]; 
    cartsubcategories  : SelectItem[];
    cartproducts  : SelectItem[]; 


    selectedcategory;
    step1 : boolean = true; 

    applicableatoptions : SelectItem[];
    discountcodes : SelectItem[];


    checkAndAddCategory(item : Cartlineitem){
        for(var cat of this.cartcategories){
            if(cat.value == item.catid) return; 
        }
        var category = this.dataService.getCategoryForId(item.catid);
        if(category){
            this.cartcategories.push({label : category.name, value : category.id});
        }

    }

    checkAndAddSubCategory(item : Cartlineitem){
        for(var subcat of this.cartsubcategories){
            if(subcat.value == item.subcatid) return; 
        }
        var subcategory = this.dataService.getSubcategoryForId(item.subcatid);
        if(subcategory){
            this.cartsubcategories.push({label : subcategory.name, value : subcategory.id});
        }

    }

    checkAndAddProduct(item : Cartlineitem){
        for(var prod of this.cartproducts){
            if(prod.value == item.productid) return; 
        }
        var product = this.dataService.getProductForId(item.productid);
        if(product){
            this.cartproducts.push({label : product.name, value : product.id});
        }
    }

    

    setSelectOptions(){
        this.cartcategories = [];
        this.cartsubcategories = [];
        this.cartproducts = [];

        for(var item of this.counterService.selectedcounter.cart.lineitems){
            this.checkAndAddCategory(item);
            this.checkAndAddSubCategory(item);
            this.checkAndAddProduct(item);
        }
    }

    changeApplicableFor(){
        this.discountDetail.appliedon = [];
    }

    onShow(){
        if(Constants.DEBUG_MODE) console.log('Dialog shown!');

        this.isRunning = false;
        if(this.counterService.selectedcounter.cart.discountdetail){
            
    
            this.discountDetail = this.counterService.selectedcounter.cart.discountdetail;
            if(Constants.DEBUG_MODE) console.log('|DISC|Cart discount detail set! DISC VAL -- ' +  this.discountDetail.discountvalue);
            if(this.discountDetail.discounttype == 1){

                var discval = this.discountDetail.discountvalue;
                
                if(discval == null || discval == 0 || discval == 10 || discval == 15 || discval == 20 || discval == 25 || discval == 30 || discval == 40 || discval == 50 ){
                    this.isother = false; 
                }else{
                    this.isother = true; 
                }
            }

        }else{
            if(Constants.DEBUG_MODE) console.log('|DISC|Setting discount type!');
    
            this.discountDetail = <Discountdetail>{};
            this.discountDetail.discounttype = "1";
            this.discountDetail.applicablefor = Constants.DISC_FOR_ALL_ITEMS;
            this.discountDetail.partnerid = 0;
            this.discountDetail.appliedon = [];
            this.isother = false; 
        }

        this.setSelectOptions();
        
        setTimeout(()=>{ this.txtDiscountbox.nativeElement.focus();}, 100);

    }

    setPartnerPercentage(){
        var partnerid  = this.discountDetail.partnerid;
        if(partnerid > 0){
            var partner = this.partnerService.getDiscountPartnerForId(partnerid);
            if(partner.name != 'Zomato Pro'){
                this.discountDetail.discountvalue = partner.discountpercentage;
                this.discountDetail.discounttype = "1";
            }
        }
    }
    
    ngOnInit(){
        this.applicableatoptions = [];
        this.applicableatoptions.push({label: 'All Items', value: Constants.DISC_FOR_ALL_ITEMS});
        this.applicableatoptions.push({label: 'Selected Categories', value: Constants.DISC_FOR_SELECTED_CATS});
        this.applicableatoptions.push({label: 'Selected Subcategories', value: Constants.DISC_FOR_SELECTED_SUBCATS});
        this.applicableatoptions.push({label: 'Selected Products', value: Constants.DISC_FOR_SELECTED_PRODS});

        this.discountcodes = [];
        this.discountcodes.push({label: 'HCT WOMEN', value: 86});
        this.discountcodes.push({label: 'DXB POLICE WOMEN', value: 87});
        
        this.discountcodes.push({label: 'HOLIEVENT', value: 88});
        this.discountcodes.push({label: 'HOMES RUS', value: 89});
        this.discountcodes.push({label: 'JASHANMAL', value: 90});

        this.discountcodes.push({label: 'Regular Customer', value: 71});
        this.discountcodes.push({label: 'Gulf News', value: 83});
        this.discountcodes.push({label: 'HHEO', value: 84});
        this.discountcodes.push({label: 'Emirates Pass', value: 85});
        
        this.discountcodes.push({label: 'Mall/Petrol Station', value: 72});
        this.discountcodes.push({label: 'Discount card', value: 73});
        this.discountcodes.push({label: 'SFC Group staff', value: 74});
        this.discountcodes.push({label: 'Promotion voucher', value: 75});
        this.discountcodes.push({label: 'Discount voucher', value: 76});
        this.discountcodes.push({label: 'Credit card holders', value: 77});
        this.discountcodes.push({label: 'Other discount', value: 78});
        this.discountcodes.push({label: 'Zomato Gold', value: 79});
        
        this.discountcodes.push({label: 'Red Crescent', value: 81});

        
        
         

        this.partnereoptions = [];
        this.partnereoptions.push({label: 'Self', value: 0});
        
        for(var partner of this.partnerService.discountpartnerlist){
            this.partnereoptions.push({label : partner.name, value : partner.id});
        }
        

        this.discountDetail = <Discountdetail>{};
        this.discountDetail.partnerid = 0;
        this.discountDetail.discountcode = 71;
    }


    
}
