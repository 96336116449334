import {Cart} from '../posdomain/cart';
import {Cartlineitem} from '../posdomain/cartlineitem';

import {Retailerproduct} from '../domain_old/retailerproduct';
import {Billingcounter} from '../posdomain/billingcounter';
import {Constants} from '../domain_old/constants';
import { Utilfunctions } from '../domain_old/utilfunctions';
import { DataService } from '../service/dataservice';

import {Productcategory} from '../domain_old/productcategory';
import { Retailerscheme } from '../domain_old/retailerscheme';
import { scheduleMicroTask } from '@angular/core/src/util';

export class Cartservice{

     static getApplicableRate(ctr: Billingcounter , prod : Retailerproduct){ 
        switch(ctr.countertype){
            case Constants.NORMAL:
                return prod.sellingprice;
            case Constants.AC_SPECIAL:
                return prod.acprice;
            case Constants.PARCEL:
                return prod.parcelprice;
            case Constants.TAKEAWAY:
                return prod.takeawayprice;
            case Constants.FREE:
                return 0;
            case Constants.ONLINE:
                return prod.onlineprice;
        }
    }
    
    static applyDiscountOnItem(item : Cartlineitem, cart : Cart, dataService : DataService, discountval : number, ispercentage : boolean, ctr : Billingcounter){
        if(isNaN(discountval)) return; //Return if discount is not a number
        
        var prod  = dataService.getProductForId(item.productid);

        if(prod != null){
            item.discinper = ispercentage;
            if(ispercentage){
                item.discount = Utilfunctions.roundToTwoDecimal(item.amount * discountval/ 100.0);
            }else{
                item.discount = discountval;
            }

            item.discval = discountval;
            
            //Compute taxes on item 
            Cartservice.computeTaxesOnItem(item,prod,ctr);
            Cartservice.setTotalsForCart(cart,dataService,false,ctr);
        }
    }



    
    static applyChargesOnBill(cart : Cart,ctr : Billingcounter){
        if(ctr.chargeinpercent){
            
        }
    
    }
    
    
    //This will basically mean that the discount is applied @ bill level and not at item level 
    static applyBillDiscountScheme(cart : Cart, schcode, discountval : number, ispercentage : boolean ){
        var applieddiscount = discountval;
        if(Constants.DEBUG_MODE) console.log('|SCHEME|Cart item amount -->' + cart.itemamount);
        if(ispercentage)
            applieddiscount  = Math.round(cart.itemamount * discountval / 100.0);

         if(applieddiscount > cart.discount){
            cart.discinper = ispercentage;
            cart.discval = discountval;
            cart.discount = applieddiscount;
            cart.schcode = schcode;
         }   

    }


    static applyDiscountOnBill(cart : Cart, dataService : DataService, discountval : number,
        ispercentage : boolean , applyonall : boolean,counter : Billingcounter){
        cart.discinper = ispercentage;
        cart.discval = discountval;
         
        if(isNaN(discountval)) return; //Return if discount is not a number

        if(ispercentage){
            var applicableamount = 0;

            for (var item of cart.lineitems) {
                if(item.kitstatus != Constants.ITEM_CANCELLED && item.kitstatus != Constants.CANT_BE_PREPARED) {
                    if (applyonall) {
                        applicableamount = applicableamount + item.amount - item.discount;
                    } else {
                        var category : Productcategory;
                        category = dataService.getCategoryForId(item.catid);
                        
                        if (category != null && category.discountapplicable)
                            applicableamount = applicableamount + item.amount - item.discount;
                    }
                }
            }

            cart.discount = Utilfunctions.roundToTwoDecimal(applicableamount * discountval / 100.0);
        }else{
            cart.discount = discountval;
        }

        Cartservice.setTotalsForCart(cart,dataService,false,counter);
        if(Constants.DEBUG_MODE) console.log('Applying discount on bill as' + cart.discount);
        

    }


    

    static  setPaidAndRemainingAmount(cart : Cart){
        var paidamount = 0;
        cart.paidamount = paidamount;
        cart.pendingamount = cart.netpayable - cart.paidamount;

    }



    static addChargesOnCart(cart : Cart){
         if(cart.chargesval > 0){
             if(cart.chargesinper) {
                 cart.charges = Utilfunctions.roundToTwoDecimal(cart.itemamount * cart.chargesval / 100.0);
             }else{
                 
                if(cart.itemcount > 0)
                    cart.charges = Utilfunctions.roundToTwoDecimal( parseFloat(cart.chargesval));
                else
                    cart.charges = 0;
             }
         }else{
             cart.charges = 0;
         } 
    }

    
    
    
    static  setTotalsForCart(cart : Cart, dataService : DataService, applySchemes,counter : Billingcounter){
        if(Constants.DEBUG_MODE) console.log('|SCHEME|SET TOTALS CALLED!');

        
        var totalamount = 0 ;
        var totalitems=0;
        var totalcgst=0;
        var totalsgst =0;
        var totalvat=0;
        var totalwithtax=0;
        var discountonitems =0;
        
        //apply item level schemes 
        if(applySchemes) Cartservice.applyItemDiscountSchemesOnCart(cart,Cartservice.curbsndate,dataService,counter);

        for(var item of cart.lineitems ){
            if(item.kitstatus != Constants.ITEM_CANCELLED && item.kitstatus != Constants.CANT_BE_PREPARED) {
                totalamount = totalamount + item.amount;
               
                //Lets fight with this
                if(item.revtax) totalamount = totalamount - item.taxes;

                totalitems += item.quantity;
                totalcgst += item.cgst;
                totalsgst += item.sgst;
                totalvat += item.vat;
                totalwithtax += item.itemamount;

                discountonitems += parseFloat( item.discount);

            }
        }

        cart.itemcount = Utilfunctions.roundToTwoDecimal(totalitems);
        cart.itemamount = Utilfunctions.roundToTwoDecimal(totalamount);
        cart.discountonitems = Utilfunctions.roundToTwoDecimal(discountonitems);
        
        cart.cgst = Utilfunctions.roundToTwoDecimal(totalcgst);
        cart.sgst = Utilfunctions.roundToTwoDecimal(totalsgst); 
        cart.vat = Utilfunctions.roundToTwoDecimal(totalvat);

        cart.totalwithtax = Utilfunctions.roundToTwoDecimal(totalwithtax);   
        cart.taxes = Utilfunctions.roundToTwoDecimal(cart.sgst + cart.cgst + cart.vat); 
        if(cart.discount == 0) cart.discinper = true;

        Cartservice.addChargesOnCart(cart);
        if(cart.charges == null || isNaN(cart.charges) || isNaN(parseInt(cart.charges))){
            cart.charges = 0;
        }

        //Before totalling apply schemes if any 
        if(applySchemes) Cartservice.applyBillLevelSchemesOnCart(cart,Cartservice.curbsndate);
        
        if(cart.discount > cart.totalwithtax) {
            cart.discount = cart.totalwithtax;
        }

        cart.netpayable = Math.round(cart.totalwithtax - cart.discount + cart.charges);
        cart.roundingamount = Utilfunctions.roundToTwoDecimal(cart.netpayable - (cart.totalwithtax - cart.discount + cart.charges));
        Cartservice.setPaidAndRemainingAmount(cart);

    }


    static roundtaxesoncart(cart : Cart){
        cart.cgst  = Utilfunctions.roundToTwoDecimal(cart.cgst);
        cart.sgst  = Utilfunctions.roundToTwoDecimal(cart.sgst);
        cart.vat  = Utilfunctions.roundToTwoDecimal(cart.vat);
        cart.totalwithtax = Utilfunctions.roundToTwoDecimal(cart.totalwithtax);
        cart.taxes =  Utilfunctions.roundToTwoDecimal(cart.cgst + cart.sgst + cart.vat);
    }

    static roundtaxesonitem(lineitem : Cartlineitem){
        lineitem.amount = Utilfunctions.roundToTwoDecimal(lineitem.amount);
        
        lineitem.cgst  = Utilfunctions.roundToThreeDecimal(lineitem.cgst);
        lineitem.sgst  = Utilfunctions.roundToThreeDecimal(lineitem.sgst);
        lineitem.vat  = Utilfunctions.roundToThreeDecimal(lineitem.vat);
        lineitem.taxes = Utilfunctions.roundToTwoDecimal(lineitem.cgst + lineitem.sgst + lineitem.vat);
    }
    

    


    static computeTaxesOnItem(lineitem : Cartlineitem, prod : Retailerproduct, ctr : Billingcounter){
        
        var taxableamount = lineitem.amount - lineitem.discount;
        lineitem.cgst = 0;
        lineitem.sgst= 0;
        lineitem.vat =0 ;

        var reversecomputetax : boolean = false; 
        if(ctr.gstoverride && ctr.gstoverride > 0){
            if(ctr.gstoverride == Constants.GST_IN_REVERSE) reversecomputetax = true;
        }else{
            if(prod.reversecomputetax == Constants.YES) reversecomputetax = true;     
        }

        if(reversecomputetax) {
                lineitem.revtax = true;
                var totalgst = prod.cgst + prod.sgst;
                if(totalgst  > 0){
                    var totaltax = (taxableamount) * totalgst / (100 + totalgst);
                    lineitem.cgst =  totaltax * prod.cgst / totalgst;
                    lineitem.sgst =  totaltax * prod.sgst / totalgst;
                }

                if(prod.vat > 0)
                    lineitem.vat = (taxableamount) * prod.vat / (100 + prod.vat);
                
                Cartservice.roundtaxesonitem(lineitem);
                lineitem.itemamount = Utilfunctions.roundToTwoDecimal(taxableamount);
        }else{
            lineitem.revtax = false;
            lineitem.cgst =  prod.cgst / 100.0 * (taxableamount);
            lineitem.sgst =  prod.sgst / 100.0 * (taxableamount);
            lineitem.vat  =  prod.vat / 100.0 * (taxableamount);

            Cartservice.roundtaxesonitem(lineitem);
            lineitem.itemamount = Utilfunctions.roundToTwoDecimal(taxableamount + lineitem.taxes);

        }
    
    }


    static setQuantity(cart : Cart, item : Cartlineitem,prod : Retailerproduct,newquantity, 
        dataService : DataService,counter : Billingcounter){
        //if(item.kitstatus == Constants.UNSENT){
            item.quantity = newquantity;
            item.amount = Math.round(item.quantity * (item.rate+item.custamount));
            if(item.discval > 0 && item.discinper){
                item.discount = Math.round(item.amount * item.discval / 100);
            }

            var applyscheme = dataService.selectedcounter.applyscheme;
            if(counter) applyscheme = counter.applyscheme;
            
            Cartservice.computeTaxesOnItem(item,prod,counter);
            Cartservice.setTotalsForCart(cart,dataService,applyscheme,counter);
        //} 
    }



    static incrementQuantityProd(prod : Retailerproduct,dataService : DataService,counter : Billingcounter){
        for(var item of counter.cart.lineitems){
            if(item.productid === prod.id){
                item.quantity = item.quantity + 1;
                item.amount = Math.round(item.quantity * (item.rate+item.custamount));
                if(item.discval > 0 && item.discinper){
                    item.discount = Math.round(item.amount * item.discval / 100);
                }
                
                Cartservice.computeTaxesOnItem(item,prod,counter);
                Cartservice.setTotalsForCart(counter.cart,dataService,counter.applyscheme,counter);

            }
        }
    }

    static reduceQuantityProd( prod : Retailerproduct,
        dataService : DataService,counter : Billingcounter){
        for(var item of counter.cart.lineitems){
            if(item.productid === prod.id){
                if(item.quantity <= 1){
                    Cartservice.removeItem(counter.cart,item,dataService,counter);
                }else{
                    item.quantity = item.quantity - 1;
                    item.amount = Math.round(item.quantity * (item.rate+item.custamount));
                    if(item.discval > 0 && item.discinper){
                        item.discount = Math.round(item.amount * item.discval / 100);
                    }
                
                    Cartservice.computeTaxesOnItem(item,prod,counter);
                    Cartservice.setTotalsForCart(counter.cart,dataService,dataService.selectedcounter.applyscheme,counter);
                }
            }
        }
    }


    
    static incrementQuantity(cart : Cart, item : Cartlineitem, prod : Retailerproduct,
        dataService : DataService,counter : Billingcounter){
        if(item.kitstatus == Constants.UNSENT){
            item.quantity = item.quantity + 1;
            item.amount = Math.round(item.quantity * (item.rate+item.custamount));
            if(item.discval > 0 && item.discinper){
                item.discount = Math.round(item.amount * item.discval / 100);
            }
            
            Cartservice.computeTaxesOnItem(item,prod,counter);
            Cartservice.setTotalsForCart(cart,dataService,counter.applyscheme,counter);
        }
    }

    static setCartTotalsToZero(cart : Cart){

        cart.netpayable = 0;
        cart.itemamount = 0;
        cart.itemcount =0;
        cart.taxes = 0; 
        cart.cgst = 0;
        cart.sgst = 0;
        cart.vat = 0;
        cart.totalwithtax = 0;
        cart.discount=0;
        cart.schcode = '';
        cart.disconcat = "1";
        cart.discountonitems = 0;
        cart.discval =0;
        cart.charges = 0;
        cart.discinper = true; 
        
    }


    static createInitialCart(ctr : Billingcounter){
        ctr.cart = <Cart>{};
        ctr.cart.lineitems = [];
        ctr.cart.chargesinper = ctr.chargeinpercent;
        ctr.cart.chargesval = ctr.charge;
        ctr.cart.chargelabel = ctr.chargelabel;
        
        Cartservice.setCartTotalsToZero(ctr.cart);
        
    }

    static createInitialCartOnlineOrder(ctr : Billingcounter, charges){
        ctr.cart = <Cart>{};
        ctr.cart.lineitems = [];
        ctr.cart.chargesinper = false;
        ctr.cart.chargesval = charges;
        ctr.cart.chargelabel = ctr.chargelabel;
        Cartservice.setCartTotalsToZero(ctr.cart);
        
    }


    static getEmptyCart(dataService : DataService) : Cart{
        var cart = <Cart>{};
        cart.lineitems = [];
        cart.kottokens = '';
        Cartservice.setCartTotalsToZero(cart);
        return cart;
    }

    static checkProductExists(ctr : Billingcounter, prodid){
        for(var item of ctr.cart.lineitems){
            //console.log('Prodid  ' + item.productid + ' check ' + prodid)
            if(item.productid === prodid){
                return true;
            }
        }
        return false;
    }


    static getProductList(ctr : Billingcounter, dataService : DataService){
        var retlist : Retailerproduct[] = [];
        for(var item of ctr.cart.lineitems){
            var prod = dataService.getProductForId(item.productid);
            retlist.push(prod);
        }
        return retlist;
    }


    static getCurrentQuantity(ctr : Billingcounter, prodid){
        for(var item of ctr.cart.lineitems){
            if(item.productid === prodid){
                return item.quantity;
            }
        }
        return 0;
    }
    static emptyCart(ctr : Billingcounter,dataService : DataService){
        ctr.cart.lineitems = [];
        ctr.cart.discinper = true; 
        ctr.cart.disconcat = "1";
        ctr.cart.kottokens = '';
        ctr.cart.chargesinper = ctr.chargeinpercent;
        ctr.cart.chargesval = ctr.charge;
        ctr.cart.chargelabel = ctr.chargelabel;
        Cartservice.setCartTotalsToZero(ctr.cart);
    }

    static reduceQuantity(cart : Cart, item : Cartlineitem, prod : Retailerproduct,
        dataService : DataService,counter : Billingcounter){
        if(item.kitstatus == Constants.UNSENT){
            if(item.quantity <= 1){
                Cartservice.removeItem(cart,item,dataService,counter);
            }else{
                item.quantity = item.quantity - 1;
                item.amount = Math.round(item.quantity * (item.rate+item.custamount));
                if(item.discval > 0 && item.discinper){
                    item.discount = Math.round(item.amount * item.discval / 100);
                }
            
                Cartservice.computeTaxesOnItem(item,prod,counter);
                Cartservice.setTotalsForCart(cart,dataService,dataService.selectedcounter.applyscheme,counter);
            }
        }
    }


    static removeItem(cart : Cart, item : Cartlineitem, dataService : DataService,counter : Billingcounter ){
        var position = cart.lineitems.indexOf(item);
        if(Constants.DEBUG_MODE) console.log('Removing item at position ' + position);
        if(position >= 0 ){
            //if(item.kitstatus == Constants.UNSENT){
                cart.lineitems.splice(position,1);
                Cartservice.setTotalsForCart(cart,dataService,counter.applyscheme,counter);
                cart.lineitems  = [... cart.lineitems];
            //}
        }
    }



    
    static addItemToCart(ctr : Billingcounter, cart : Cart,prod : Retailerproduct, quantity : number,
         instructions : string, dynamicrate : number, dynamicamount : number, 
         customization: string, customizationamount : number, dataService : DataService,
         custinv? : string){
            var rate = 0;
            var amount = 0;

            if(dynamicrate > 0 ){
                rate = dynamicrate;
                amount = dynamicamount; //This will be rounded amount
           
            }else{
                rate =  this.getApplicableRate(ctr,prod);
                amount = Math.round(quantity * (rate+customizationamount)); //This will be rounded amount
            }   

            var isfound : boolean = false;

            for(var curitem of cart.lineitems ){
                if(curitem.productid == prod.id && curitem.customization === customization  && curitem.instructions == instructions && curitem.kitstatus == Constants.UNSENT){
                    isfound = true; 
                    curitem.quantity += quantity;
                    curitem.quantity = Utilfunctions.roundToThreeDecimal(curitem.quantity);
                    
                    curitem.amount = Math.round(curitem.quantity * (rate+customizationamount));
                    if(curitem.discval > 0 && curitem.discinper){
                        curitem.discount = Math.round(curitem.amount * curitem.discval / 100);
                    }
                    
                    Cartservice.computeTaxesOnItem(curitem,prod,ctr);
                    
                }
            }

            if(!isfound){

                //Item not present in cart, generate a new item 
                var item : Cartlineitem;
                item = <Cartlineitem> {};
    
                item.productid = prod.id;
                item.quantity =quantity;
                item.rate = rate; 
                item.amount = amount;
                item.kitstatus = Constants.UNSENT;
                item.kottime = 0;
                item.kottoken = 0;
                item.discount = 0;
                item.instructions = instructions;
                item.discval = 0;
                item.createdon = new Date().getTime();
                item.productname = prod.name;
                item.customization = customization;
                item.custamount = customizationamount;
                if(custinv && custinv.length > 0) item.custinv = custinv; 
                
                item.catid = prod.categoryid;
                item.subcatid = prod.subcategoryid;

                item.kitchenid = prod.kitchenid;
                Cartservice.computeTaxesOnItem(item,prod,ctr);
                cart.lineitems.unshift(item);

                Cartservice.setTotalsForCart(cart,dataService,ctr.applyscheme,ctr);
                cart.lineitems  = [... cart.lineitems];
            }else{
                Cartservice.setTotalsForCart(cart,dataService,ctr.applyscheme,ctr);
                cart.lineitems  = [... cart.lineitems];
            }


    }


    
    
    
    static getCartItemsAsString(cart : Cart){
        var itemstring = "";
        for(var item of cart.lineitems){
                itemstring = itemstring  + item.productname + " (" + item.quantity + "), ";
        }

        if (itemstring.length > 3){
            itemstring  = itemstring.substring(0,itemstring.length - 2);
        }

        return itemstring;

    }

    static getCloneOfCart(cart : Cart){
        var newcart = <Cart>{};
        for(let prop in cart) {
            newcart[prop] = cart[prop];
        }
        return newcart; 
    }
    
    static getCloneOfItem(item : Cartlineitem){
        var newitem = <Cartlineitem>{};
        for(let prop in item) {
            newitem[prop] = item[prop];
        }
        return newitem; 
    }

    static addToListIfExists(cart : Cart, addeditem : Cartlineitem){
        for (var item of cart.lineitems){
            if(item.productid == addeditem.productid 
                && item.rate === addeditem.rate
                && item.customization === addeditem.customization 
                && item.discinper === addeditem.discinper 
                && item.discval === addeditem.discval)
            {
                item.quantity = item.quantity + addeditem.quantity;
                item.amount = item.amount + addeditem.amount;
                item.cgst =  item.cgst + addeditem.cgst;
                item.sgst =  item.sgst + addeditem.sgst; 
                item.discount = item.discount + addeditem.discount;
                item.taxes = item.taxes + addeditem.taxes;
                item.itemamount = item.itemamount + addeditem.itemamount;
                
                return true;
            }
        }

        return false;
    }

    static schemelist : Retailerscheme[];
    static curbsndate;

    static clearDiscountforSchemes(cart : Cart,dataService : DataService,counter : Billingcounter){
        for(var lineitem of cart.lineitems){
            if(lineitem.schcode && lineitem.schcode.length > 0){
                lineitem.discinper = true;
                lineitem.discount = 0;
                lineitem.discval = 0;
                lineitem.schcode = '';
                var prod= dataService.getProductForId(lineitem.productid);
                Cartservice.computeTaxesOnItem(lineitem,prod,counter);
            }
        }
    }


    static clearBillLevelScheme(cart : Cart){
        if(cart.schcode && cart.schcode.length > 0){
            cart.discinper = true;
            cart.discount = 0;
            cart.discval = 0;
            cart.schcode = '';
        }
    
    }



    static checkItemSchemeApplicability(item : Cartlineitem, sch : Retailerscheme){
        switch(sch.schemetype){
            case Constants.SCHEME_ON_PRODUCT:
                if(sch.schemerunon === item.productid) return true;
                break;
            case Constants.SCHEME_ON_SUBCATEGORY:
                if(sch.schemerunon === item.subcatid) return true;
                break;
            case Constants.SCHEME_ON_CATEGORY:
                if(sch.schemerunon === item.catid) return true;
                break;
        }
        return false;
    }


    static getSchemeApplicableQty(sch : Retailerscheme, cart : Cart){
        var conditionalqty = sch.conditionalquantity;
        var purchasedqty = 0;
        for(var item of cart.lineitems){
            switch(sch.conditiontype){
                case Constants.SCHEME_ON_PRODUCT:
                    if(sch.conditionrunon === item.productid) purchasedqty = purchasedqty + item.quantity;
                    break;
                case Constants.SCHEME_ON_SUBCATEGORY:
                    if(sch.conditionrunon === item.subcatid) purchasedqty = purchasedqty + item.quantity;
                    break;
                case Constants.SCHEME_ON_CATEGORY:
                    if(sch.conditionrunon === item.catid) purchasedqty = purchasedqty + item.quantity;
                    break;
            }
        }

        if(sch.schemetype === sch.conditiontype && sch.schemerunon === sch.conditionrunon){
            conditionalqty = conditionalqty + 1;
        }

        //Divide by conditional quantity + 1 
        return Math.floor(purchasedqty/(conditionalqty));

    }


    static applyConditionalScheme(sch : Retailerscheme, cart : Cart,
        dataService :DataService,counter : Billingcounter){
        var applicableqty = Cartservice.getSchemeApplicableQty(sch,cart);

        if(applicableqty > 0){
            //get an array of line items 
            var arritems : Cartlineitem[] = [];
            for (var item of cart.lineitems){
                arritems.push(item);
            }
            
            //Sort in order of rate
            arritems.sort((a:Cartlineitem, b: Cartlineitem)=> a.rate < b.rate  ? -1 : 1 );

            //Now browse through array 
            for(var item of arritems){
                if(Cartservice.checkItemSchemeApplicability(item,sch)){
                    var appliedqty = applicableqty;
                    if(item.quantity < applicableqty) 
                         appliedqty = item.quantity;      
                    
                    //Apply here
                    var applicableamount = item.rate * appliedqty;
                    var discount = sch.discountvalue;
                    if(sch.discountinpercentage){
                        discount = Utilfunctions.roundToTwoDecimal(applicableamount * sch.discountvalue/ 100.0);
                    }

                    if(discount > item.discount){
                        item.schcode = sch.schemecode;
                        item.discount = discount;
                        item.discval = discount;
                        item.discinper = false;
                        var prod = dataService.getProductForId(item.productid);
                        Cartservice.computeTaxesOnItem(item,prod,counter);
                    }else{
                        appliedqty = 0;//Nothing got applied!
                    }
                    

                    if(appliedqty == applicableqty) return;
                    else applicableqty = applicableqty - appliedqty;
                    
                }
            }


        }
    }




    static applyItemDiscountSchemesOnCart(cart : Cart, curbsndate,dataService : DataService,counter : Billingcounter){

        //Clear discount for ALL items which have been applied with schemes
        Cartservice.clearDiscountforSchemes(cart,dataService,counter);

        for(var sch of Cartservice.schemelist){

            //We have a valid scheme!
            if(parseInt(sch.schemeapplicableon) === Constants.SCHEME_ON_LINEITEMS){
                if(Utilfunctions.checkSchemeValidity(sch,Cartservice.curbsndate)){
                    if(sch.conditional){
                          Cartservice.applyConditionalScheme(sch,cart,dataService,counter);            
                    }else{
                        for(var item of cart.lineitems){
                            if(Cartservice.checkItemSchemeApplicability(item,sch)){
                                 //Apply here
                                var discount = sch.discountvalue;
                                if(sch.discountinpercentage){
                                    discount = Utilfunctions.roundToTwoDecimal(item.amount * sch.discountvalue/ 100.0);
                                }

                                if(discount > item.discount){
                                    item.schcode = sch.schemecode;
                                    item.discount = discount;
                                    item.discval = sch.discountvalue;
                                    item.discinper = sch.discountinpercentage;
                                    var prod = dataService.getProductForId(item.productid);
                                    Cartservice.computeTaxesOnItem(item,prod,counter);
                                }

                            }
                        }
                    }

                }
            }
        
        }

    }


    static applyBillLevelSchemesOnCart(cart : Cart, curbsndate){
        Cartservice.clearBillLevelScheme(cart);
        for(var sch of Cartservice.schemelist){
            //We have a valid scheme!
            if(parseInt(sch.schemeapplicableon) === Constants.SCHEME_ON_ENTIREBILL){
                if(Utilfunctions.checkSchemeValidity(sch,Cartservice.curbsndate)){
                        if(Constants.DEBUG_MODE) console.log('|SCHEME|Valid scheme ' + sch.schemename);
                        if(cart.itemamount >= sch.minbillamount){
                            if(Constants.DEBUG_MODE) console.log('|SCHEME|Applying bill scheme');
                            Cartservice.applyBillDiscountScheme(cart,sch.schemecode, sch.discountvalue,sch.discountinpercentage);
                        }
                }
            }
        }
    }


    
}