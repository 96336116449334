import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Billingservice} from '../service/billingservice';
import {MessageService} from 'primeng/api';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Cartservice } from '../posservice/cartservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Kot } from '../domain/kot';
import { Appdataservice } from '../service/appdataservice';
import { Orderservice } from '../service/orderservice';
import { Order } from '../domain/order';
import { Userservice } from '../service/userservice';

@Component({
    selector: 'ordercancel-dlg',
    templateUrl: 'ordercancellationdialog.html',

})
export class Ordercancellationdialog {


    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private dataService : Appdataservice,
                private orderService : Orderservice,
                private userService : Userservice,
                private kitchenService : Kitchenservice,
                public counterService : Billingcounterservice) {}
    
    
    closeDialog(){
        this.billingService.makeorderDetailDialogVisible = false;
    }

    selectedOrder;

    onShow(){

        this.selectedOrder = this.orderService.getClone(this.billingService.orderForCancellation);
        this.cancellationreason = '';
        this.otherdetails = '';
        this.enableother = false;
        this.foodprepared = null; 
        this.customercancelled = null;
    }

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false; 
    }

    setReason(reason){
        this.cancellationreason = reason;
        if(reason && reason.toUpperCase().indexOf('OTHER') >= 0)
            this.enableother = true; 
        else
            this.enableother = false;
    }

    showspinner; 
    arrreasons;
    foodprepared;
    cancellationreason;
    otherdetails; 
    enableother;
    customercancelled;

    getKotfromList(kotlist : Kot[], tokennum){
        for(var kot of kotlist){
            if(kot.kottoken == tokennum){
                return kot;
            }
        }
        return null;
    }

    async cancelOrder(){
        
        if (!this.cancellationreason || this.cancellationreason.length == 0){
            this.showErrorToast("Please select cancellation reason");
            return;
        }
        
        if(this.enableother && (!this.otherdetails || this.otherdetails.length == 0)){
            this.showErrorToast("Please enter cancellation reason");
            return;
        }

        if(this.foodprepared == null){
            this.showErrorToast("Please choose whether food was prepared or not");
            return;
        }

        if(this.customercancelled == null){
            this.showErrorToast("Please choose whether order was cancelled by customer or by outlet");
            return;
        }

            
        this.showspinner = true; 

        this.selectedOrder.orderstatus = Constants.POS_ORDER_CANCELLED;
        this.selectedOrder.cancelreason = this.cancellationreason;
        this.selectedOrder.cancelremraks = this.otherdetails;
        this.selectedOrder.foodprep = this.foodprepared;
        this.selectedOrder.checkedout = true; 

        var cancelby = 'Outlet';
        if(this.customercancelled) 
            cancelby = 'Customer';
        
        this.selectedOrder.cancelby = cancelby;
        

        var curuser = this.userService.loggedinuser;
        this.selectedOrder.voidby = 0;
        if(curuser) this.selectedOrder.voidby = curuser.id;

    
        var ret = await this.orderService.updateOrderInDB(this.selectedOrder);
        if(!ret){
            this.showErrorToast('Error in cancellation, please try again!');
        }else{
            if(this.billingService.voidfromordermanagement)
                await this.billingService.ordermanagementInstance.setData(null);
        }
        
        //Now cancel the KOTs
        var kotlist =  <Kot[]> await this.kitchenService.getKotList(this.selectedOrder.businessdate,this.selectedOrder.businessdate);
        if( kotlist && kotlist.length >  0 && this.selectedOrder && this.selectedOrder.kotnum && this.selectedOrder.kotnum.length > 0){
            var arrkotnums = this.selectedOrder.kotnum.split(",");
            if(arrkotnums && arrkotnums.length > 0){
                for(var kotnum of arrkotnums){
                    var kot = this.getKotfromList(kotlist, parseInt(kotnum));
                    if(kot){
                        kot.iscancelled = true;
                        var cancellationreason = 'Order cancelled-' + this.selectedOrder.cancelreason + ' ' + this.selectedOrder.cancelremraks;
                        var counter = this.counterService.getBillingcounterForId(this.selectedOrder.counterid);
                        if(counter){
                            await this.kitchenService.cancelKot(kot,cancellationreason,'', counter,this.foodprepared,cancelby);
                        }
                    }
                }
            }

        }
        


        this.showspinner = false; 
        this.billingService.makeordercancelDialogVisible = false;

        if(this.billingService.voidfromaggregator){
            this.billingService.upmanagementinstance.markVoid(this.selectedOrder);
        }
    
        
    }

    




    ngOnInit(){
        this.selectedOrder = <Order>{};
        this.arrreasons = [];
        var strreasons = this.dataService.retailer.appconfig.posconfig.cancellationreasons;
        if(strreasons != null && strreasons.length > 0){
            this.arrreasons = strreasons.split(',');
        }
        
    }


    
}
