import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from './paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Printingservice } from '../service/printingservice';
import { Billingservice } from '../service/billingservice';
import { Cashdenomination } from '../domain/cashdenomination';
import { Shiftendrecord } from '../domain/shiftendrecord';
import { IoT1ClickDevicesService } from 'aws-sdk';
import { Userservice } from '../service/userservice';
import { Shiftendservice } from '../service/shiftendservice';
import { Payment } from '../domain/payment';
import { Summaryrecord } from '../domain/summaryrecord';


@Component({
    templateUrl: 'cashierchange.html',
    providers: [ConfirmationService]
})
export class Cashierchange implements OnInit 
{
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    
    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 

    arrdenominations : Cashdenomination[];
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private orderService  : Orderservice,
        private shiftEndService : Shiftendservice,
        private userService  : Userservice,
        private billingService : Billingservice,
        private deliveryBoyservice : Deliveryboyservice,
        private printingService : Printingservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;

    displayconfirmation;

    getDenominationObject(deno){
        var obj = <Cashdenomination>{};
        obj.denomination = deno;
        obj.quantity = null;
        obj.amount = 0;
        return obj;
    }

    cancel(){
        this.displayconfirmation = false; 
    }

    setNullToZero(){
        if(!this.ar) this.ar = 0;
        if(!this.coins) this.coins = 0;
        if(!this.forex) this.forex = 0;
        if(!this.pettycashexp) this.pettycashexp = 0;
        if(!this.floatcash) this.floatcash = 0;
        if(!this.lowerdenomination) this.lowerdenomination = 0;
        
        
    }

    addToExistingRecord(rec :Summaryrecord, pymt : Payment){
        rec.summaryval2 =  Utils.round(rec.summaryval2 + pymt.paidamount,2);
        if(pymt.paymentmode == Constants.PYMT_CARD) { 
            var strtip = "" + pymt.tip;
            if(strtip && strtip.length > 0){
                rec.summaryval2 = Utils.round(rec.summaryval2 + parseFloat(strtip),3);
            }
        }
    }
    
    createNewSummaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = "" + pymt.paymentmode;
        rec.description = pymt.paymentmodename;

        
        rec.summaryval2 = pymt.paidamount;

        if(pymt.paymentmode == Constants.PYMT_CARD) { 
            rec.description = rec.description + ' (Incl Tip)';

            var strtip = "" + pymt.tip;
            if(strtip && strtip.length > 0){
                rec.summaryval2 = Utils.round(rec.summaryval2 + parseFloat(strtip),3);
            }
        }


        return rec; 
    }

    addPaymentModeSale(pymt : Payment){
        if(!this.shiftendrecord.arrpymtmodewisesales) this.shiftendrecord.arrpymtmodewisesales = [];
        var keytocheck = "" + pymt.paymentmode;
        var currec = Utils.findInSummaryList(this.shiftendrecord.arrpymtmodewisesales,keytocheck);
        if(currec){
            this.addToExistingRecord(currec,pymt);
        }else{
            currec = this.createNewSummaryRecord(pymt);
            this.shiftendrecord.arrpymtmodewisesales.push(currec);    
        }
    }

    async process(){
        this.shiftendrecord = <Shiftendrecord>{};
        this.setNullToZero();
        this.showspinner = true;

        var bsndate = this.dataService.getCurrentBusinessDate();
        var salesrecords = <Order[]> await this.orderService.getOrderlist(bsndate,bsndate);

        var cashsales = 0;
        var pendingsales = 0;
        if(salesrecords){
            for(var ord of salesrecords){
                if(ord.orderstatus == Constants.POS_ORDER_PROCESSED &&  ord.cart.paidamount > 0){
                    for(var pymt of ord.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_CASH || pymt.paymentmode == Constants.PYMT_FOREX){
                            cashsales = cashsales + pymt.paidamount;
                        }else{
                            this.addPaymentModeSale(pymt);
                        }
                    }
                }
                if(ord.orderstatus == Constants.POS_ORDER_PROCESSED &&  ord.cart.pendingamount > 0){
                    pendingsales = Utils.round(pendingsales + ord.cart.pendingamount,2);
                }
            }
        }

        this.shiftendrecord.salesincash = Utils.round(cashsales,2);
        this.shiftendrecord.notesvalue = this.totaldenominationvalue;
        this.shiftendrecord.arrcashdenomination = this.arrdenominations;
        this.shiftendrecord.accountsreceivable = this.ar; 
        this.shiftendrecord.businessdate = this.dataService.getCurrentBusinessDate();
        this.shiftendrecord.outgoingcashier = this.userService.loggedinuser.name;
        this.shiftendrecord.forexinaed = this.forex;
        this.shiftendrecord.pettycashexpense = this.pettycashexp;
        this.shiftendrecord.coinsvalue = this.coins;
        this.shiftendrecord.floatcash = this.floatcash;
        this.shiftendrecord.lowerdenominationvalue = this.lowerdenomination;
        this.shiftendrecord.netcash = this.netcash;
        this.shiftendrecord.totalcashinhand = Utils.round(this.netcash + this.ar - this.floatcash - this.pettycashexp,2);
        this.shiftendrecord.orderpendingamount = pendingsales;
        this.shiftendrecord.bankdeposit = 0;
        

        var discrepancy =  Utils.round(this.shiftendrecord.totalcashinhand - cashsales,2);
        this.shiftendrecord.discrepancy = discrepancy;
        if(discrepancy > 0){
            this.shiftendrecord.excessshortfall = 'Excess';
        }else if(discrepancy < 0){
            this.shiftendrecord.excessshortfall = 'Shortfall';
        }else{
            this.shiftendrecord.excessshortfall = '';
        }

        this.showspinner = false; 
        this.displayconfirmation = true; 
        
    }

    async confirmProcess(){
        
        /*if(this.shiftendrecord.discrepancy != 0 ){
            if(!this.shiftendrecord.discrepancyreason || this.shiftendrecord.discrepancyreason.trim().length == 0 ){
                this.showErrorToast('Please provide reason for discrepancy!');
            }
        }*/

        
        if(!this.shiftendrecord.remarks || this.shiftendrecord.remarks.length == 0 ){
            this.showErrorToast('Please provide cashier change remarks!');
            return;
        }

        this.showspinner = true; 
        
        var lastShift = <Shiftendrecord> await this.shiftEndService.getLastShift();
        if(lastShift){
            console.log('Last shift no : ' + lastShift.shiftno);
            var changetime = this.dataService.retailer.appconfig.posconfig.shiftchangetime;
            if(!changetime) changetime = 0;
            if(new Date().getTime() - lastShift.createdon < (changetime*60*1000) ){
                this.showErrorToast('At least ' + changetime + ' minutes needed between two shift changes!');
                return;
            }
        }

        this.shiftendrecord.createdon = new Date().getTime();
        this.shiftendrecord.createdonstr = this.datePipe.transform(this.shiftendrecord.createdon,"yyyy-MM-dd HH:mm");
        
        
        var ret = await this.shiftEndService.endShift(this.shiftendrecord);
        this.showspinner = false; 
        if(ret){
            this.printTillReport();
            this.initialize();
           
        }else{
            this.showErrorToast('Error, please try again!');
        }

        
    }
        
    initialize(){
        this.arrdenominations = [];
        this.arrdenominations.push(this.getDenominationObject(1000));
        this.arrdenominations.push(this.getDenominationObject(500));
        this.arrdenominations.push(this.getDenominationObject(200));
        this.arrdenominations.push(this.getDenominationObject(100));
        this.arrdenominations.push(this.getDenominationObject(50));
        this.arrdenominations.push(this.getDenominationObject(20));
        this.arrdenominations.push(this.getDenominationObject(10));
        this.arrdenominations.push(this.getDenominationObject(5));
        
        this.coins = null;
        this.forex = null;
        this.lowerdenomination = null;
        this.ar = null;
        this.floatcash = null;
        this.pettycashexp = null;

        this.shiftendrecord = <Shiftendrecord>{};

    }
    ngOnInit(){
        this.initialize();

    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner=false;
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    async printTillReport()
    {
            document.getElementById("printable").innerHTML = this.printingService.getTillReportAsHTML(this.shiftendrecord);
            this.displayconfirmation  = false; 

            setTimeout(()=>{ window.print();}, 200);
            setTimeout(() => {this._router.navigate(['/billingscreen']);},500);
            
    }


    reportfrom; 
    reportto;

    showspinner; 

    selectedOrder : Order; 
    shiftendrecord : Shiftendrecord;
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        //ord.businessdatestr = this.datePipe.transform(Utils.getDateFromBusinessdate("" + ord.businessdate),"dd/MM/yy");
        
    }

    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
        }
    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }


    

    totaldenominationvalue = 0;
    coins;
    forex;
    floatcash; 
    pettycashexp;
    ar; 
    lowerdenomination;
     
    netcash=0;

    denoChange(denoRecord : Cashdenomination){
        if(denoRecord.quantity && !isNaN(denoRecord.quantity) && !isNaN(parseInt(denoRecord.quantity))){
            denoRecord.amount = parseInt(denoRecord.quantity) * denoRecord.denomination;
        }else{
            denoRecord.amount = 0;
        }

        this.setTotalDenoValue();
    
    }

    setTotalDenoValue(){
        var total=0;
        for(var deno of this.arrdenominations){
            total=total+deno.amount;
        }
        this.totaldenominationvalue =total;
        this.netcash = this.totaldenominationvalue;

        if(this.coins && !isNaN(this.coins) && !isNaN(parseFloat(this.coins))){
            this.netcash = Utils.round(this.netcash + parseFloat(this.coins),2);
        }
        if(this.forex && !isNaN(this.forex) && !isNaN(parseFloat(this.forex))){
            this.netcash = Utils.round(this.netcash + parseFloat(this.forex),2);
        }
        if(this.lowerdenomination && !isNaN(this.lowerdenomination) && !isNaN(parseFloat(this.lowerdenomination))){
            this.netcash = Utils.round(this.netcash + parseFloat(this.lowerdenomination),2);
        }

    }
    
    
    
    
}


