import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Billingservice} from '../service/billingservice';
import {MessageService} from 'primeng/api';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Constants } from '../domain/constants';
import { Cartservice } from '../posservice/cartservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Kot } from '../domain/kot';
import { Appdataservice } from './appdataservice';
import { Printingservice } from './printingservice';
import { Printerservice } from './printerservice';
import { Userservice } from './userservice';

@Component({
    selector: 'kotcancel-dlg',
    templateUrl: './kotcancellationdialog.html',

})
export class Kotcancellationdialog {


    constructor(public billingService : Billingservice,
                private messageService: MessageService,
                private dataService : Appdataservice,
                private printerService : Printerservice,
                private kitchenService : Kitchenservice,
                private userService  : Userservice,
                private printingService : Printingservice,
                public counterService : Billingcounterservice) {}
    
    
    closeDialog(){
        this.billingService.makekotCancelDialogVisible = false;
    }

    kotarray : Kot[];
    selectedKots : Kot[];

    onShow(){
        this.kotarray = this.kitchenService.getKOTArrayFromCart(this.counterService.selectedcounter.cart);    
        this.selectedKots = [];
        this.cancellationreason = '';
        this.otherdetails = '';
        this.enableother = false;
        this.foodprepared = null; 
        this.customercancelled = null;
    }

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false; 
    }

    setReason(reason){
        this.cancellationreason = reason;
        if(reason && reason.toUpperCase().indexOf('OTHER') >= 0)
            this.enableother = true; 
        else
            this.enableother = false;
    }

    showspinner; 
    arrreasons;
    foodprepared;
    cancellationreason;
    otherdetails; 
    enableother;
    customercancelled;

    async cancelKots(){
        


        if(this.selectedKots.length == 0){
            this.showErrorToast("Select atleast 1 KOT to cancel");
            return; 
        }

        if(this.counterService.selectedcounter.proformaid > 0 && this.selectedKots.length == this.kotarray.length){
            this.showErrorToast("Proforma is already generated, cannot cancel all items. Please void the bill instead.");
            return; 
        }

        if (!this.cancellationreason || this.cancellationreason.length == 0){
            this.showErrorToast("Please select cancellation reason");
            return;
        }
        
        if(this.enableother && (!this.otherdetails || this.otherdetails.length == 0)){
            this.showErrorToast("Please enter cancellation reason");
            return;
        }

        if(this.foodprepared == null){
            this.showErrorToast("Please choose whether food was prepared or not");
            return;
        }

         if(this.customercancelled == null){
            this.showErrorToast("Please choose whether KOT was cancelled by customer or by outlet");
            return;
        }
            
            
        this.showspinner = true; 
        if(!await this.counterService.checkAndGetLatest()){
            this.showErrorToast('You did not have the latest billing counter data or counter was locked for update, please try again!');
            this.showspinner = false; 
            return;
        }
        


        var cart = this.counterService.selectedcounter.cart;
        for(var kot of this.selectedKots){
            var retstatus = true; 

            var reason = this.cancellationreason;
            var remarks = this.otherdetails;

            var cancelby = 'Outlet';
            if(this.customercancelled) 
                cancelby = 'Customer';


            if(kot.kottoken > 0){
                retstatus = await this.kitchenService.cancelKot(kot,reason, remarks,this.counterService.selectedcounter,this.foodprepared,cancelby);
            }
            
            if(!retstatus){
                this.showErrorToast('Error in cancellation KOT for kot number: ' + kot.kottoken);
                return; 
            }

            for(var lineitem of kot.lineitems){
                var idx = cart.lineitems.indexOf(lineitem);
                if(idx >= 0){
                    cart.lineitems.splice(idx,1);
                }
            }

            cart.recomputeCart();
            cart.lineitems = [... cart.lineitems];

            
            //Set the unsent status 
            this.counterService.selectedcounter.unsentitems = this.kitchenService.checkUnsent(this.counterService.selectedcounter.cart);
            
            //Save the counter 
            await this.counterService.saveSelectedCounter();
        }
    
        this.showspinner = false; 
        this.billingService.makekotCancelDialogVisible = false;
    
        
    }

    
    async reprintKots(){
        if(this.selectedKots.length != 1){
            this.showErrorToast("Select only 1 KOT to reprint");
        }else{
            
            var kot = this.selectedKots[0];
            if(kot.kottoken > 0){
                this.billingService.reprinttype = 'kot';
                this.billingService.kotForReprint = kot;
                

                if(this.userService.checkBillingPriv(Constants.PRIV_DUPLICATE_KOT)){
                    this.billingService.makeReprintDialogVisible = true;
                }else{
                    this.billingService.reauthpurpose = Constants.PRIV_DUPLICATE_KOT;
                    this.billingService.makeReauthDialogVisible = true;
                }

            }

            /*for(var kot of this.selectedKots){
                if(kot.kottoken > 0){
                    var kit  = this.kitchenService.getKitchen(kot.kitchenname);
                    if(kit && kit.printerid > 0){
                        var printer = this.printerService.getPrinterForId(kit.printerid);
                        if(printer){
                            await this.printingService.generateAndPrintKOT(kot,printer,true,false);
                        }
                    }
                    
                    //await this.printingService.printKOT(null,kot,this.dataService.selectedcounter,false,'',false, true);
                }
                
            }*/
            
        }
    }




    ngOnInit(){
        this.arrreasons = [];
        var strreasons = this.dataService.retailer.appconfig.posconfig.cancellationreasons;
        if(strreasons != null && strreasons.length > 0){
            this.arrreasons = strreasons.split(',');
        }
        
        
    }


    
}
