import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import {DataService}  from '../../service/dataservice';
import {Constants}    from '../../domain/constants';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { Orderservice } from 'src/app/service/orderservice';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/service/utils';
import { Order } from 'src/app/domain/order';
import { Table } from 'primeng/table';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Userservice } from 'src/app/service/userservice';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Cartlineitem } from 'src/app/domain/cartlineitem';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Payment } from 'src/app/domain/payment';
import { Shiftendrecord } from 'src/app/domain/shiftendrecord';
import { Kot } from 'src/app/domain/kot';
import { Shiftendservice } from 'src/app/service/shiftendservice';
import { Printingservice } from 'src/app/service/printingservice';
import { Product } from 'src/app/domain/product';
import { Dayendsummary } from 'src/app/domain/dayendsummary';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Couchdbservice } from 'src/app/service/couchdbservice';

@Component({
    templateUrl: 'buildsummary.html',
    providers: [ConfirmationService]
})
export class Buildsummary implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    summarylist       : Summaryrecord[];
    
    categorywisesales : Summaryrecord[];
    countertypewisesales : Summaryrecord[];
    subcategorywisesales : Summaryrecord[];
    kitchenwisesales : Summaryrecord[];
    sectionwisesales : Summaryrecord[];
    guesttypewisesales : Summaryrecord[];
    hourofdaywisesales : Summaryrecord[];
    
    servedbywisesales : Summaryrecord[];
    
    
    modewisereceipts    : Summaryrecord[];
    productwisesales :  Summaryrecord[];
    discountsummary : Summaryrecord[];
    shiftlist       :   Shiftendrecord[];
    kotlist : Kot[];
    
    


    currentviewtype;

    showcategorywisesales; 
    showsubcategorywisesales; 
    showkitchenwisesales;
    showsectionwisesales;
    showcountertypewisesales;  


    isRunning = false; 

    getWidthClass(){
        if(window.innerWidth <= 600) return "Wid90Center";
        else if(window.innerWidth <= 1000) return "Wid80Center";
        else return "Wid60Center";
    }

    
    constructor(public dataService: Appdataservice,private _router : Router,
        private salesorderService: Orderservice,
        private datePipe : DatePipe,
        private counterService : Billingcounterservice,
        private userService : Userservice,
        private orderService : Orderservice,
        private shiftEndService : Shiftendservice,
        private dynamodbService : Dynamodbservice,
        private couchDBService : Couchdbservice,
        private kitchenService : Kitchenservice,
        private messageService : MessageService,
        public printingService : Printingservice,
        private dboyService : Deliveryboyservice
        ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());

        this.showcategorywisesales = true;
        this.showsubcategorywisesales = false;
        this.showcountertypewisesales = true;
        this.showkitchenwisesales = true;
        this.showsectionwisesales = true;
         

        this.cols = [
            { field: 'description', header: 'Parameter' },
            { field: 'summaryval1', header: 'Value' }
        ];
 
        this.summarylist = [];
        this.orderlist = [];

    }


    setDetails(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        var ctr = this.counterService.getBillingcounterForId(ord.counterid);
        if(ctr){
            ord.sectionnamestr = ctr.sectionname;
        }
        ord.servedbystr = this.userService.getUserNameForId(ord.servedby);
        ord.billedbystr = this.userService.getUserNameForId(ord.billedby);
        ord.weekdaystr = this.datePipe.transform (ord.createdon,"EEEE");
        ord.hourofdaystr  = Utils.getHourRangeFromHour(parseInt(this.datePipe.transform(ord.createdon,"HH")));
        ord.deliveredbystr = this.dboyService.getDeliveryboyNameForId(ord.deliveredby);

    }
    orderlist : Order[]; 


    cancelledordercount = 0;
    cancelledorderamount = 0;
    kotcancelcount = 0;
    kotcancelamount = 0;
    itemscancelledkot = 0;
    itemscancelledkotamt = 0;
    itemsreturnedcount =0;
    itemsreturnedamount =0;
    duplicatebillcount = 0;
    totaldiscountonbills =0;
    totalschemedifference= 0;
    totaldiscountonitems = 0;
    numberofbillswithdiscount =0;
    totalbills =0;
    totalitemssold =0;
    totalsalesvalue =0;
    totaltaxablesales = 0;
    totalnetsales = 0;
    totalcgst = 0;
    totalsgst = 0;
    totalvat = 0; 
    totaltaxes = 0;
    totalduepayments = 0;
    numreprints = 0;
    totalinwardcash =0;
    totaloutwardcash = 0;
    netcash = 0;
    totalreceipts =0;
    totalexpenses = 0;
    grossprofit= 0;
    numshifts= 0;
    totaldiscrepancy = 0;
    totalcharges = 0;
    vatoncharges = 0;
    taxonitems = 0;
    totaltips = 0;
    totalpax = 0;

    setTotalsToZero(){
        this.cancelledordercount = 0;
        this.cancelledorderamount = 0;
        this.itemscancelledkot = 0;
        this.itemscancelledkotamt = 0;
        this.itemsreturnedcount =0;
        this.itemsreturnedamount =0;
        this.duplicatebillcount = 0;
        this.totaldiscountonbills =0;
        this.totaldiscountonitems = 0;
        this.numberofbillswithdiscount =0;
        this.totalbills =0;
        this.totalitemssold =0;
        this.totalsalesvalue =0;
        this.totaltaxablesales = 0;
        this.totalnetsales = 0;
        this.totalcgst =0;
        this.totalsgst =0;
        this.totalvat =0;
        this.totaltaxes = 0;
        this.totalduepayments = 0;
        this.numreprints = 0;
        this.totalinwardcash =0;
        this.totaloutwardcash  =0;
        this.netcash =0;
        this.totalreceipts =0;
        this.totalexpenses = 0;
        this.grossprofit =0;
        this.numshifts =0;
        this.totaldiscrepancy=0;
        this.kotcancelamount = 0;
        this.kotcancelcount = 0;
        this.totalcharges = 0;
        this.vatoncharges = 0;
        this.taxonitems  = 0;
        this.totaltips  =0;
        this.totalpax = 0;
        this.totalschemedifference = 0;
        
    }

    incrementSchemeBenefit(ord : Order){

        for(var item of ord.cart.lineitems){
            if(item.schcode && item.schcode.length > 0){
                var prod = this.dataService.getProductForId(item.productid);
                var oldamount =  Utils.round((item.ograte  + item.ogcustrate) *  item.quantity, 3);       
                var oldgross = oldamount;

                //Compute the gross amount (amount before tax)
                if(item.revtax){
                    var taxpercent = prod.cgst + prod.sgst + prod.vat; 
                    oldgross = Utils.round(oldamount / (1 + (taxpercent/100)),3);
                }


                var newgross = item.grossamt;
                var difference = oldgross - newgross;

                this.totalschemedifference= Utils.round(this.totalschemedifference + difference,2);
            }
        
        }
        
    }


    buildstatus;

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    addHeaderRecord(headerstring){
        var rec = <Summaryrecord>{};
        rec.description = headerstring;
        rec.summaryval1 = "";
        this.summarylist.push(rec);
    }

    selectedRecord;
    reportfrom;
    reportto;

    async printReport()
    {
        if(this.summarylist && this.summarylist.length > 0){
            var strfromdate = this.datePipe.transform(this.fromdate,"dd/MM/yy");
            var strtodate = this.datePipe.transform(this.todate,"dd/MM/yy");
            var daterange = strfromdate + " - " + strtodate;
            
            document.getElementById("printable").innerHTML = this.printingService.getSummaryReportAsHTML("Business Summary Report", daterange, this.summarylist);
            setTimeout(()=>{ window.print();}, 200);

        }
    }


    curdate;

    async migrateByTableScan(tablename,posid){
        var retlist  = await this.couchDBService.getAllItems(tablename,posid);
        console.log('|MIGRATION|Num records fior table --> ' + tablename + retlist.docs.length + " POS ID " + posid);

        var i =0;
        for(var item of retlist.docs){
            delete item._id;
            delete item._rev;
            
            if(posid.length > 0 ) item.posid = posid; 
            var ret  = await this.dynamodbService.putItem(tablename, item);
            if(ret) i++;
            else return false; 
        }
        console.log('|MIGRATION|Num records migrated ' + i  );
        return true;


    }

    async migerateFromGlobal(tablename){
        var retlist  = await this.couchDBService.readGlobalStore(tablename);
        console.log('|MIGRATION|Num records fior table --> ' + tablename + retlist.docs.length );

        var i =0;
        for(var item of retlist.docs){
            delete item._id;
            delete item._rev;
            var ret  = await this.dynamodbService.putItem(tablename, item);
            if(ret) i++;
            else return false; 
        }
        console.log('|MIGRATION|Num records migrated ' + i  );
        return true;


    }

    async migrateCustomers(){
        var custjson = await this.dataService.getCustomerDataFile('ipmaz.json');
        for(var item of custjson.customers){
            item.id = parseInt(item.id);
            if(!item.alternateno || item.alternateno == null) item.alternateno = 0;
            if(!item.alternateno2 || item.alternateno2 == null) item.alternateno2 = 0;
            
            item.alternateno = parseInt(item.alternateno);
            item.alternateno2 = parseInt(item.alternateno2);
            item.createdate = parseInt(item.createdate);
            
            
            var ret  = await this.dynamodbService.putItem('customers', item);
            console.log('Cust imported --> ' + item.id + ' Pos ID ' + item.posid );
        }

    }

    async getOrders(posid,fromdate,todate){
        var selector =  {"businessdate": { "$gte": fromdate, "$lte" : todate}};
        var retlist = await this.couchDBService.getFromIndexPosID('orders',selector,posid);
        if(retlist && retlist.docs){
            //return retlist.docs;
            var i =0;
            
            for(var item of retlist.docs){
                delete item._id;
                delete item._rev;

                if(!item.customermobile || item.customermobile == null || item.customermobile.length < 5) item.customermobile = '-';
                item.customermobile = '' + item.customermobile;
                console.log('Putting order id  ' + ' business date ' + item.businessdate + ' cust mobile ' + item.customermobile);
                var ret  = await this.dynamodbService.putItem('orders', item);
                if(ret) i++;
                else return false; 
            }
            console.log('|MIGRATION|Num records migrated ' + i  );
            return true;

        }else 
            return null;
    }

    async getKOTs(posid,fromdate,todate){
        var selector =  {"bsndate": { "$gte": fromdate, "$lte" : todate}};
        var retlist = await this.couchDBService.getFromIndexPosID('kots',selector,posid);
        if(retlist && retlist.docs){
            //return retlist.docs;
            var i =0;

            var retlist = await this.couchDBService.getFromIndexPosID('kots',selector,posid);

            
            for(var item of retlist.docs){
                delete item._id;
                delete item._rev;
            
                console.log('Putting item id  ' + i + ' business date ' + item.bsndate );
                var ret  = await this.dynamodbService.putItem('kots', item);
                if(ret) i++;
                else return false; 
            }
            console.log('|MIGRATION|Num records migrated ' + i  );
            return true;

        }else 
            return null;
    }


    async migrateData(){
        this.isRunning = true; 

        await this.migrateCustomers();
        console.log('|MIGRATION|Migrating KOTS for May till NOV');

        /*var arrposid= ["1350","1329"];
        for(var posid of arrposid){
            console.log('|MIGRATION|**** MIGRATION STARTED FOR ' + posid);
            var ret = await this.getKOTs(posid,20200901,20200930);
            if(ret){console.log('|MIGRATION|ORDERS done');} 

        }*/

        //var ret = await this.migerateFromGlobal('aggregators');
        //var ret = await this.migerateFromGlobal('discountpartners');

        //console.log('|MIGRATION|Migrating AGGREGATORS');
        

        /*var arrposid= ["1329","1343", "1338|235", "1338|239", "1338|241", "1338|243", "1338|238", "1338|240","1338|313","1338|245","1350","1511|314","1511|315","1511|316"];
        for(var posid of arrposid){
            

            console.log('|MIGRATION|**** MIGRATION STARTED FOR ' + posid);
            var ret = await this.migrateByTableScan('shifts',posid);
            if(ret){console.log('|MIGRATION|Shifts done');} 

            ret = await this.migrateByTableScan('shiftnos',posid);
            if(ret){console.log('|MIGRATION|Shifts Nos done');} 

            ret = await this.migrateByTableScan('receiptnos',posid);
            if(ret){console.log('|MIGRATION|Receipt nos done');} 

            ret = await this.migrateByTableScan('printers',posid);
            if(ret){console.log('|MIGRATION|printers done');} 

            ret = await this.migrateByTableScan('billingcounters',posid);
            if(ret){console.log('|MIGRATION|billing counters done');} 


            ret = await this.migrateByTableScan('usergroups',posid);
            if(ret){console.log('|MIGRATION|user groups done');} 

            ret = await this.migrateByTableScan('users',posid);
            if(ret){console.log('|MIGRATION|users done');} 


            ret = await this.migrateByTableScan('tableorders',posid);
            if(ret){console.log('|MIGRATION|table orders done');} 

            ret = await this.migrateByTableScan('tablerequests',posid);
            if(ret){console.log('|MIGRATION|tablerequests done');} 

            ret = await this.migrateByTableScan('kottokens',posid);
            if(ret){console.log('|MIGRATION|kottokens done');} 

            ret = await this.migrateByTableScan('kitchens',posid);
            if(ret){console.log('|MIGRATION|kitchens done');} 

            ret = await this.migrateByTableScan('deliveryboys',posid);
            if(ret){console.log('|MIGRATION|deliveryboys done');} 

            ret = await this.migrateByTableScan('customers',posid);
            if(ret){console.log('|MIGRATION|customers done');} 

        }

        {console.log('|MIGRATION|ALL *** MIGRATIONS DONE *** ');} */

        

    }


    async generateAndPostSummary(){
        if(this.totalbills > 0){
            //Set category wise sales
            this.categorywisesales = this.getSummaryListItem(Constants.VW_CATEGORY,this.curdate);
            this.countertypewisesales =  this.getSummaryListOrder(Constants.VW_COUNTERTYPE,this.curdate);
            this.kitchenwisesales = this.getSummaryListItem(Constants.VW_KITCHEN,this.curdate);
            this.subcategorywisesales = this.getSummaryListItem(Constants.VW_SUBCATEGORY,this.curdate);
            this.guesttypewisesales = this.getSummaryListOrder(Constants.VW_GUEST_TYPE_WISE,this.curdate);
            this.hourofdaywisesales = this.getSummaryListOrder(Constants.VW_HOUR_OF_DAY,this.curdate);
            this.servedbywisesales= this.getSummaryListOrder(Constants.VW_SERVED_BY, this.curdate);
            this.productwisesales = this.getSummaryListItem(Constants.VW_PRODUCT,this.curdate);
            this.modewisereceipts =  this.getPaymentSummaryList(this.curdate);

            var sumrec = <Dayendsummary>{};
            sumrec.categorywisesales = this.categorywisesales;
            sumrec.subcategorywisesales = this.subcategorywisesales;
            sumrec.kitchenwisesales = this.kitchenwisesales;
            sumrec.pymtmodewiserevenue = this.modewisereceipts;
            sumrec.hourwisesales = this.hourofdaywisesales;
            sumrec.guesttypewisesales = this.guesttypewisesales;
            sumrec.countertypewisesales = this.countertypewisesales;
            sumrec.servedbywisesales = this.servedbywisesales;
            sumrec.productwisesales = this.productwisesales;
            
            //Summary vals
            sumrec.totalbills = this.totalbills;
            sumrec.totaldiscountonbills  = this.totaldiscountonbills;
            sumrec.totalpax = this.totalpax;
            sumrec.numberofbillswithdiscount = this.numberofbillswithdiscount;
            sumrec.totalitemssold = this.totalitemssold;
            sumrec.totaltaxablesales = this.totaltaxablesales;
            sumrec.posid  = this.dataService.getPosId();
            sumrec.businessdate = this.curdate; 

            console.log('|BSNSUM|Sending to Dynamo ' + JSON.stringify(sumrec));
            
            
            await this.dynamodbService.putItem('reportsummary',sumrec);
            this.setTotalsToZero();
        
        }   
    
    }
    async setData(){

        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.curdate = fromdate;

        this.isRunning = true; 
        this.setTotalsToZero();
        
        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.businessdate < b.businessdate  ? -1 : 1 );

        //this.shiftlist = await this.shiftEndService.getShiftendList(fromdate,todate);
        //this.kotlist = await this.kitchenService.getKotList(fromdate,todate);

        //this.reportfrom = Utils.getBusinessDateAsString("" + fromdate);
        //this.reportto = Utils.getBusinessDateAsString("" + todate);
        
        this.setTotalsToZero();

        
        for(var order of this.orderlist){


            console.log('|BSNSUM|ORD BSN '   + order.businessdate + ' CUR BSN ' + this.curdate);

            //business date is over
            if(order.businessdate != this.curdate){
                await this.generateAndPostSummary();                
                this.curdate = order.businessdate;
            }

            if(order.orderstatus === Constants.POS_ORDER_PROCESSED){
                this.totalbills = this.totalbills + 1;
                this.totalitemssold = Utils.round(this.totalitemssold + order.cart.sumitemcount,3);
                this.totalsalesvalue = Utils.round(this.totalsalesvalue + order.cart.sumitemgross,3);
                this.totalnetsales = Utils.round(this.totalnetsales + order.cart.netpayable,3);

                if(order.cart.totaldiscount > 0 ){
                    this.numberofbillswithdiscount = this.numberofbillswithdiscount + 1;
                    this.totaldiscountonbills = Utils.round(this.totaldiscountonbills + order.cart.totaldiscount,3);
                }

                this.taxonitems = Utils.round(this.taxonitems + order.cart.nettaxes,3);
                this.totalduepayments = Utils.round(this.totalduepayments + order.cart.pendingamount,3);
                this.totalreceipts = Utils.round(this.totalreceipts + order.cart.paidamount,3);
                
                var charges = order.cart.netcharges; 
                var taxablecharges =  Utils.round(charges / (1 + (5/100)),3);
                this.totalcharges = Utils.round(this.totalcharges + taxablecharges,3);
                var vatoncharges = Utils.round(taxablecharges * (5/100),3);
                this.vatoncharges = this.vatoncharges + vatoncharges;
                this.totaltaxes = this.taxonitems + this.vatoncharges;


                this.totaltaxablesales = Utils.round(this.totaltaxablesales + order.cart.sumitemtaxable + taxablecharges,3);

                if(order.cart.paymentlist && order.cart.paymentlist.length > 0){
                    for(var pymt of order.cart.paymentlist){
                        if(pymt.paymentmode == Constants.PYMT_CARD) { 
                            var strtip = "" + pymt.tip;
                            if(strtip && strtip.length > 0){
                                this.totaltips = Utils.round(this.totaltips + parseFloat (strtip),3);
                            }
                        }
                    }
                }
                if(order.countertype == Constants.NORMAL || order.countertype == Constants.AC_SPECIAL){
                    if(order.numguests && order.numguests > 0)
                        this.totalpax = this.totalpax + order.numguests;
                }

                //Add to scheme 
                this.incrementSchemeBenefit(order);
                 
            }else{
                this.cancelledordercount = this.cancelledordercount + 1;
                this.cancelledorderamount = Utils.round(this.cancelledorderamount + order.cart.netpayable,3);
            }

            if(order.reprintcount > 0){
                this.numreprints = this.numreprints + order.reprintcount;
            }
    
        }


        //Again post one summary for the remaining orders   
        await this.generateAndPostSummary();                

      
        
        this.isRunning = false; 
    }


    


    //****************** get summary lists */
    createNewSummaryRecordOrder(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheckOrder(ord);
        rec.description = this.getDescritpionOrder(ord);
        rec.summaryval1 =  1;
        rec.summaryval2 = ord.cart.sumitemtaxable;
        if(ord.numguests)
            rec.summaryval3 = ord.numguests;
        else
            rec.summaryval3 = 0;

        return rec; 
    }

    addToExistingRecordOrder(rec :Summaryrecord, ord : Order){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + 1,2);
        rec.summaryval2 =  Utils.round(rec.summaryval2 + ord.cart.sumitemtaxable,2);
        if(ord.numguests)
            rec.summaryval3 =  rec.summaryval3 + ord.numguests;
        else
            rec.summaryval3 = 0;
        
        
    }

    getKeytoCheckOrder(ord : Order){
        var key ="";
        
        switch(this.currentviewtype){
            case Constants.VW_COUNTER: return key +  ord.countername;
            case Constants.VW_COUNTERTYPE: return key +  ord.countertype;
            case Constants.VW_DELIVERY_BOY: return key +  ord.deliveredbystr;
            case Constants.VW_SERVED_BY: return key + ord.servedbystr;
            case Constants.VW_OPERATOR: return key + ord.billedbystr;
            case Constants.VW_GUEST_TYPE_WISE: return key +  ord.guesttype;
            case Constants.VW_SECTION: return key + ord.sectionnamestr; 
            case Constants.VW_WEEKDAY: return key + ord.weekdaystr;
            case Constants.VW_HOUR_OF_DAY: return key + ord.hourofdaystr;
            
        }
        
    }

    getDescritpionOrder(ord : Order){
        switch(this.currentviewtype){
            case Constants.VW_COUNTER: return ord.countername;
            case Constants.VW_COUNTERTYPE: return ord.countertypestr;
            case Constants.VW_DELIVERY_BOY: return ord.deliveredbystr;
            case Constants.VW_SERVED_BY: return ord.servedbystr;
            case Constants.VW_OPERATOR: return ord.billedbystr;
            case Constants.VW_GUEST_TYPE_WISE: return ord.guesttype;
            case Constants.VW_SECTION: return ord.sectionnamestr;
            case Constants.VW_WEEKDAY: return ord.weekdaystr;
            case Constants.VW_HOUR_OF_DAY: return ord.hourofdaystr;
        }
        
    }

    
    getSummaryListOrder(viewtype,bsndate){
        var filteredlist = [];
        this.currentviewtype = viewtype;
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED && item.businessdate == bsndate){
                var ord = this.orderService.getClone(item);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);

                this.setDetails(ord);
                var key = this.getKeytoCheckOrder(ord);
                var currec = Utils.findInSummaryList(filteredlist,key);
                if(currec){
                    this.addToExistingRecordOrder(currec,ord);
                }else{
                    currec = this.createNewSummaryRecordOrder(ord);
                    filteredlist.push(currec);    
                }

            }
                
                
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }


    createNewSummaryRecordItem(item : Cartlineitem){
        var rec = <Summaryrecord>{};
        rec.id = this.getKeytoCheckItem(item);
        rec.description = this.getDescritpionItem(item);
        rec.summaryval1 =  item.quantity;
        rec.summaryval2 = item.taxableamt;
        
        return rec; 
    }

    addToExistingRecordItem(rec :Summaryrecord, item : Cartlineitem){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + item.quantity,4);
        rec.summaryval2 =  Utils.round(rec.summaryval2 + item.taxableamt,4);
    }

    getKeytoCheckItem(item : Cartlineitem){
        var key ="";
        
        switch(this.currentviewtype){
            case Constants.VW_PRODUCT: return key +  item.productid;
            case Constants.VW_CATEGORY: return key +  item.catid;
            case Constants.VW_SUBCATEGORY: return key +  item.subcatid;
            case Constants.VW_KITCHEN: return key + item.kitchenid;
        }
    }

    getDescritpionItem(item : Cartlineitem){
        switch(this.currentviewtype){
            case Constants.VW_PRODUCT: return  item.productname;
            case Constants.VW_CATEGORY:
                var cat = this.dataService.getCategoryForId (item.catid);
                if(cat) return cat.name; return "";
            case Constants.VW_SUBCATEGORY: 
                var subcat = this.dataService.getSubcategoryForId (item.subcatid);
                if(subcat) return subcat.name; return "";
            case Constants.VW_KITCHEN: 
                return this.kitchenService.getKitchenNameForId( item.kitchenid);
            
            
        }
        
    }


    getSummaryListItem(viewtype, bsndate){
        var filteredlist = [];
        this.currentviewtype = viewtype;

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED && item.businessdate == bsndate){
        
                var ord = this.orderService.getClone(item);
                
                for(var lineitem of ord.cart.lineitems){
                    var key = this.getKeytoCheckItem(lineitem);
                    var currec = Utils.findInSummaryList(filteredlist,key);

                    if(currec){
                        this.addToExistingRecordItem(currec,lineitem);
                    }else{
                        currec = this.createNewSummaryRecordItem(lineitem);
                        filteredlist.push(currec);    
                    }
                }

            }
                
                
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;
    }

    getTip(pymt : Payment){
        var tip = 0;
        
        if(pymt.paymentmode == Constants.PYMT_CARD){
            var strtip = "" + pymt.tip;
            if(strtip && strtip.length > 0){
                try{
                    tip = parseFloat(strtip);
                }catch(err){}
            }
        }

        return tip;

    }

    //Payment summary 
    createNewPymtSummaryRecord(pymt : Payment){
        var rec = <Summaryrecord>{};
        rec.id = pymt.paymentmode;
        rec.description = pymt.paymentmodename;
        rec.summaryval1 = Utils.round(pymt.paidamount,2);
        rec.summaryval2 = 1;
        return rec; 
    }

    addToExistingPymtRecord(rec :Summaryrecord, pymt : Payment){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + pymt.paidamount,2);
        rec.summaryval2 = rec.summaryval2 + 1; 
    }



    getPaymentSummaryList(bsndate){
        var filteredlist = [];
        
        

        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED && item.businessdate == bsndate){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
                    for(var pymt of ord.cart.paymentlist){
                        var currec = Utils.findInSummaryList(filteredlist,pymt.paymentmode);
                        if(currec){
                            this.addToExistingPymtRecord(currec,pymt);
                        }else{
                            currec = this.createNewPymtSummaryRecord(pymt);
                            filteredlist.push(currec);    
                        }
                        
                    }
                }
            }
        }

        filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval1)  > (b.summaryval1)  ? -1 : 1 );
        return filteredlist;

    }


    getDiscountRange(ord : Order){
        var discval = ord.cart.totaldiscount / ord.cart.sumitemgross * 100;
        if(discval  <= 5) return "1-5";
        if(discval  <= 10) return "6-10";
        if(discval  <= 15) return "11-15";
        if(discval  <= 20) return "16-20";
        if(discval  <= 25) return "21-25";
        if(discval  <= 30) return "26-30";
        if(discval  <= 50) return "31-50";
        return "51-100";
        
        
    }

    getInitialDiscountSummaryRecord(range){
        var rec = <Summaryrecord>{};
        rec.id = range; 
        rec.description = range + "%";
        rec.summaryval1 = 0;
        rec.summaryval2 = 0;

        return rec;
    }
    

    //Payment summary 
    createNewDiscountSummaryRecord(ord : Order){
        var rec = <Summaryrecord>{};

        var range = this.getDiscountRange(ord);
        rec.id = range; 
        rec.description = range + "%";
        /*if(ord.cart.discountdetail.discounttype == "1"){
            rec.id = ord.cart.discountdetail.discountvalue;
             rec.description = ord.cart.discountdetail.discountvalue + "%";
        }else{
            var discval = Utils.round(ord.cart.totaldiscount/ord.cart.sumitemgross * 100,0);
            if(discval == 10 || discval == 15 || discval == 20 || discval == 25 || discval == 50){
                rec.id = discval;
                rec.description = discval + "%";
            }else{
                rec.id = 0;
                rec.description = 'Amount Discount';
            }
        }*/

        rec.summaryval1 = ord.cart.totaldiscount;
        rec.summaryval2 = rec.summaryval2 + 1; 
        return rec; 
    }

    addToExistingDiscountRecord(rec :Summaryrecord, ord : Order){
        rec.summaryval1 =  Utils.round(rec.summaryval1 + ord.cart.totaldiscount,2);
        rec.summaryval2 = rec.summaryval2 + 1;
    }


    getDiscountSummaryList(){
        var filteredlist = [];
        filteredlist.push(this.getInitialDiscountSummaryRecord("1-5"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("6-10"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("11-15"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("16-20"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("21-25"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("26-30"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("31-50"));
        filteredlist.push(this.getInitialDiscountSummaryRecord("51-100"));
        
        for (var item of this.orderlist){
            if(item.orderstatus != Constants.POS_ORDER_CANCELLED){
                var ord = this.orderService.getClone(item);
                
                if(ord.cart.discountdetail && ord.cart.totaldiscount > 0){
                    var key  = this.getDiscountRange(ord);
                    //if(ord.cart.discountdetail.discounttype != "1") key= 0;

                    var currec = Utils.findInSummaryList(filteredlist,key);
                    if(currec){
                        this.addToExistingDiscountRecord(currec,ord);
                    }else{
                        currec = this.createNewDiscountSummaryRecord(ord);
                        filteredlist.push(currec);    
                    }
                }
            }
        }

        //filteredlist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.description)  < (b.description)  ? -1 : 1 );
        return filteredlist;

    }
    

}


