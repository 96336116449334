import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { Couchdbservice } from '../service/couchdbservice';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Appdataservice } from '../service/appdataservice';
import { Summaryrecord } from '../domain/summaryrecord';
import { Billingcounter } from '../posdomain/billingcounter';
import { Kot } from '../domain/kot';
import { Orderservice } from '../service/orderservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Order } from '../domain/order';

@Component({
    templateUrl: 'syncfromcouch.html',
    providers: [ConfirmationService]
})
export class Syncfromcouch implements OnInit 
{
    isRunning = false; 

    summarydisplay : Summaryrecord[] = [];


    
    constructor(
        public dataService: Appdataservice,
        private _router : Router,
        private dynamodbService : Dynamodbservice,
        private couchDBService : Couchdbservice,
        private kitchenService : Kitchenservice,
        private orderService : Orderservice,
        private messageService : MessageService
        ) {

        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
    settopost; 
    ctrlist; 
    kotlist; 
    orderlist; 
    
    async initSummary(){

        this.summarydisplay = [];
        this.isRunning = true; 
        var retlist =  await this.couchDBService.getAllItems("billingcounters");
        
        if(retlist){
            this.ctrlist =  <Billingcounter[]> retlist.docs;
            console.log('|SYNCOFFLINE|NUM Counters: ' + this.ctrlist.length);
            var rec = <Summaryrecord>{};
            rec.description = 'Billing counters to sync';
            rec.summaryval1 = this.ctrlist.length;
            this.summarydisplay.push(rec);
        }else{
            this.showErrorToast('Error getting counter data, please check if offline server is up!');
            return;
        }


        this.kotlist = <Kot[]> await this.kitchenService.getUnsyncedKOTsFromCouch();
        if(this.kotlist){
            var rec = <Summaryrecord>{};
            rec.description = 'Kots to sync';
            rec.summaryval1 = this.kotlist.length;
            this.summarydisplay.push(rec);
        }else{
            this.showErrorToast('Error getting KOT data, please check if offline server is up!');
            return;
        }

        this.orderlist = <Order[]> await this.orderService.getUnsyncedordersFromCouch();
        if(this.orderlist){
            var rec = <Summaryrecord>{};
            rec.description = 'Orders to sync';
            rec.summaryval1 = this.orderlist.length;
            this.summarydisplay.push(rec);
        }else{
            this.showErrorToast('Error getting Orders data, please check if offline server is up!');
            return;
        }

        this.isRunning = false; 


    }
    ngOnInit(){
        this.summarydisplay = [];
        this.initSummary();
    }


    copystatus;
    
    showErrorToast(message){
        this.isRunning = false;
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    
    async migrateByTableScan(tablename,posid){

        var retlist  = await this.dynamodbService.getAllItemsForPosId(tablename);


        var i =0;
        for(var item of retlist){
            
            var couchitem = await this.couchDBService.getItem(tablename, item.id);
            if(couchitem){
                item._id = couchitem._id; 
                item._rev = couchitem._rev;
                console.log('|MIGRATION|Item found in table: ' + tablename  + ' Item ID ' + item.id );
            }
            
            var ret  = await this.couchDBService.putItem(tablename, item);
            if(ret) i++;
            else return false; 

            this.copystatus = 'Migrated ' + i + ' Records of table: ' + tablename;
        }

        console.log('|MIGRATION|Num records migrated ' + i  );
        return true;


    }

    async migerateFromGlobal(tablename){
        var retlist = await this.dynamodbService.scanTable(tablename);
        //var retlist  = await this.couchDBService.readGlobalStore(tablename);
        

        var i =0;
        for(var item of retlist){
            var couchitem = await this.couchDBService.getItem(tablename, item.id);
            if(couchitem){
                item._id = couchitem._id; 
                item._rev = couchitem._rev;
            }
            
            var ret  = await this.couchDBService.putItem(tablename, item);
            if(ret) i++;
            else return false; 
            this.copystatus = 'Migrated ' + i + ' Records of table: ' + tablename;
        }
        console.log('|MIGRATION|Num records migrated ' + i  );
        //this.copystatus = 'Migrated ' + i + ' Records of table: ' + tablename;
        return true;


    }

    migrationstatus; 
    async syncData(){
        var numkots = this.kotlist.length;
        var numorders = this.orderlist.length; 
        if(numkots ==0  && numorders == 0){
            this.showErrorToast('No KOTs/ Orders generated in Offline mode!');
            return; 
        }

        this.isRunning = true; 
        var ret = await this.sendDataToDynamo('billingcounters', this.ctrlist);
        if(!ret){
            this.showErrorToast('Error sending counter data to server, please try again!');
            return; 
        }


        var ret = await this.sendDataToDynamo('kots', this.kotlist);
        if(!ret){
            this.showErrorToast('Error sending KOT data to server, please try again!');
            return; 
        }

        var ret = await this.sendDataToDynamo('orders', this.orderlist);
        if(!ret){
            this.showErrorToast('Error sending Order data to server, please try again!');
            return; 
        }



        var ret = await this.markAsSentInCouch('kots', this.kotlist);
        if(!ret){
            this.showErrorToast('Error marking KOT data as sent to server, please try again!');
            return; 
        }

        var ret = await this.markAsSentInCouch('orders', this.orderlist);
        if(!ret){
            this.showErrorToast('Error marking order data as sent to server, please try again!');
            return; 
        }

        this.isRunning = false; 
        this.migrationstatus = 'All offline data migrated!';

        setTimeout(() => {this._router.navigate(['/']);},500);



    }

    async sendDataToDynamo(tablename, itemlist){

        var i =0;
        for(var item of itemlist){
            //delete item._id;
            //delete item._rev;

            if(tablename == 'kots' || tablename == 'orders')
                item.offline = true;
            
            var ret  = await this.dynamodbService.putItem(tablename, item);
            if(ret){ 
                i++;
                this.migrationstatus = 'Sent ' + i + '  ' + tablename + ' to server.';

            }else return false; 
        }

        return true;


    }

    async markAsSentInCouch(tablename,itemlist){

        
        var i =0;
        for(var item of itemlist){
            item.synced = true;
            
            var ret  = await this.couchDBService.putItem(tablename, item);
            if(ret) i++;
            else return false; 

            this.migrationstatus = 'Marked ' + i + ' ' + tablename + ' As sent in offline data!';
        }

        console.log('|MIGRATION|Num records migrated ' + i  );
        return true;


    }

    
    
    async migrateData(){
        this.isRunning = true; 
    
        var ret = await this.migerateFromGlobal('aggregators');
        var ret = await this.migerateFromGlobal('discountpartners');
        var posid = this.dataService.getPosId();

        console.log('|MIGRATION|**** MIGRATION STARTED FOR ' + posid);
        ret = await this.migrateByTableScan('printers',posid);
        if(ret){console.log('|MIGRATION|printers done');} 

        ret = await this.migrateByTableScan('billingcounters',posid);
        if(ret){console.log('|MIGRATION|billing counters done');} 

        ret = await this.migrateByTableScan('usergroups',posid);
        if(ret){console.log('|MIGRATION|user groups done');} 

        ret = await this.migrateByTableScan('users',posid);
        if(ret){console.log('|MIGRATION|users done');} 

        ret = await this.migrateByTableScan('kitchens',posid);
        if(ret){console.log('|MIGRATION|kitchens done');} 

        ret = await this.migrateByTableScan('deliveryboys',posid);
        if(ret){console.log('|MIGRATION|deliveryboys done');} 

        if(ret){
            this.copystatus = 'All Data Migrated to offline server!';
        }else{
            this.copystatus = 'Error in migration!';
        }
        
        this.isRunning = false;

    }


    

}


