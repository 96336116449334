import { Customeraddress } from "../domain/customeraddress";
import { Order } from "../domain/order";
import { Constants } from "../domain/constants";
import { Summaryrecord } from "../domain/summaryrecord";
import { Shiftendrecord } from "../domain/shiftendrecord";
import { Payment } from "../domain/payment";
import { Aggregatorservice } from "./aggregatorservice";
import { Retailerscheme } from "../domain/retailerscheme";
import { Product } from "../domain/product";
import { Monthendsummary } from "../domain/monthendsummary";
import { Itemcancellationdialog } from "../view/itemcancellationdialog";
import { Appdataservice } from "./appdataservice";
import { stringify } from "querystring";

export class Utils{
    public static round(input,decimals){
        switch(decimals){
            case 2: return Math.round(input*100) / 100;
            case 3: return Math.round(input*1000) / 1000;
            case 4:  return Math.round(input*10000) / 10000;
            default: return Math.round(input*100) / 100;

        }
    } 

    public static toNearest(num, frac) {
        return Math.ceil(num / frac) * frac;
    }


    public static roundTo25Fraction(number){
        return  parseFloat((Math.round(number * 4) / 4).toFixed(2));
    }

    public static generateOTP(){
        return Math.floor(100000 + Math.random() * 900000);
    
    }


    
    public static convertoMultilineFixedLengh(inputstring,numchars,padstring){
        if(Constants.DEBUG_MODE) console.log('PREPAD ' + inputstring );
        
        if(inputstring.length <= numchars){
            if(Constants.DEBUG_MODE) console.log('POSTPAD|' + Utils.rpad(padstring,inputstring) + '|CHARNUM' + numchars);
            return Utils.rpad(padstring,inputstring);
        }else{
            var strarray = [];
          switch(numchars){
                case 13:strarray = inputstring.match(/(.{1,13})/g);break;
                case 17:strarray = inputstring.match(/(.{1,17})/g);break;
                case 18:strarray = inputstring.match(/(.{1,18})/g);break;
                case 19:strarray = inputstring.match(/(.{1,19})/g);break;
                case 20:strarray = inputstring.match(/(.{1,20})/g);break;
                case 21:strarray = inputstring.match(/(.{1,21})/g);break;
                case 22:strarray = inputstring.match(/(.{1,22})/g);break;
                case 26:strarray = inputstring.match(/(.{1,26})/g);break;
                case 27:strarray = inputstring.match(/(.{1,27})/g);break;
                case 28:strarray = inputstring.match(/(.{1,28})/g);break;
                case 29:strarray = inputstring.match(/(.{1,29})/g);break;
                case 30:strarray = inputstring.match(/(.{1,30})/g);break;
                case 34:strarray = inputstring.match(/(.{1,34})/g);break;
                case 36:strarray = inputstring.match(/(.{1,36})/g);break;
                case 42:strarray = inputstring.match(/(.{1,42})/g);break;
            }

            var retstr = "";
            for(let i= 0; i < strarray.length;i++){
                var linestr = strarray[i];
                linestr = Utils.rpad(padstring,linestr.trim());
                retstr = retstr + linestr + "<BR>";
            }

            retstr = retstr.substring(0,retstr.length - 4);
            return retstr;
        }
       
    }

    
    public static lpad(pad, str){
        return String(pad + str).slice(-1 * pad.length);
    } 

    public static rpad(pad : string , str : string){
        var combinedstr = str + pad; 
        return combinedstr.substring(0,pad.length);
    }
    

    public static itemsAsString(order : Order){
        var itemstring = "";
        //console.log('Line items ' + order.cart.lineitems);
        for(var item of order.cart.lineitems){
                itemstring = itemstring  + item.quantity + ' x ' + item.productname;
                if(item.customization && item.customization.length > 0){
                    itemstring = itemstring  + ' [' + item.customization + '] ';
                }
                if(item.instructions && item.instructions.length > 0){
                    itemstring = itemstring  + ' {' + item.instructions + '} ';
                }

                itemstring = itemstring  + ', ';
                
        }



        if (itemstring.length > 3){
            itemstring  = itemstring.substring(0,itemstring.length - 2);
        }

        return itemstring;

    
    }

    public static itemsAsHTML(order : Order){
        var itemstring = "";
        for(var item of order.cart.lineitems){
                itemstring = itemstring  + "<b>"  + item.quantity + ' x </b>' + item.productname;
                if(item.customization && item.customization.length > 0){
                    itemstring = itemstring  + ' [' + item.customization + '] ';
                }
                if(item.instructions && item.instructions.length > 0){
                    itemstring = itemstring  + ' {' + item.instructions + '} ';
                }

                itemstring = itemstring  + '<br/>';
                
        }

        return itemstring;

    
    }

    public static getStatusColor(order : Order){
        switch(order.orderstatus){
            case Constants.NEW_ORDER: return "#ffbf00";
            case Constants.CONFIRMED_ORDER: return "#1e90ff";
            case Constants.FOOD_READY: return "#e9d66b";
            case Constants.OUT_FOR_DELIVERY: return "#a4c639";
            case Constants.ORDER_EXECUTED: return "#32CD32";
            
            
        }
    
    }

    public static getStatusStr(order : Order){
        switch(order.orderstatus){
            case Constants.POS_ORDER_PROCESSED: return "Processed";
            case Constants.POS_ORDER_CANCELLED: return "Cancelled";
            
        }
    
    }
    
    public static format(input,decimals){
        var factor = 100;
        switch(decimals){
            case 1: factor = 10; break;
            case 2: factor = 100; break;
            case 3: factor = 1000; break;
            case 4: factor = 10000; break;
            
        }
        var n = Math.round(input*factor) /factor;
        return n.toFixed(decimals);
    } 

 
    public static getDateFromBusinessdate(bsndate : string){
        if(bsndate){
          var strdate  = bsndate.substring(0,4) + "-" + bsndate.substring(4,6) + "-" + bsndate.substring(6,8);
          return new Date(strdate);
        }
    }

    public static getBusinessDateAsString(bsndate : string){
        if(bsndate){
          return bsndate.substring(6,8) + "/" + bsndate.substring(4,6) + "/" + bsndate.substring(2,4);
        }
    }

    public static getBusinessDateForSQL(bsndate : string){
        if(bsndate){
          var strdate  = bsndate.substring(0,4) + "-" + bsndate.substring(4,6) + "-" + bsndate.substring(6,8);
          return strdate;
        }
    }

    public static formatBusinessDateGraph(bsndate : string){
        var year = bsndate.substring(0,4);
        var month = bsndate.substring(4,6);
        var day = bsndate.substring(6,8);
        return day + "/" + month;
    }


    public static findInSummaryList(summarylist : Summaryrecord[], value) : Summaryrecord {
        for(var i = 0; i < summarylist.length; i++) {
            if(summarylist[i].id === value) {
                return summarylist[i];
            }
        }
        return null;
    }

    public static getHourRangeFromHour(hour : number){
        var curhour = "" + hour;
        if(curhour.length == 1) curhour = "0" + curhour; 

        var nexthour ="" +  (hour + 1);
        if(nexthour.length == 1) nexthour = "0" + nexthour;
        
        return curhour + "-" + nexthour; 
    }


    /****** FOCUS FUNCTION */   
    public static getCountertypeCode(countertype ){
        switch(countertype){
            case Constants.NORMAL: return 'G';
            case Constants.AC_SPECIAL: return 'G';
            case Constants.PARCEL: return 'H';
            case Constants.TAKEAWAY: return 'T';
            case Constants.ONLINE: return 'H';
            case Constants.SELFAPP: return 'H';
            default: return 'H';
            
        }
    }


    public static getCountertypeNameFromCode(countertypecode){
        switch(countertypecode){
            case 'G': return 'Dine In';
            case 'H': return 'Delivery';
            case 'T': return 'Takeaway';
            default: return 'Others';
        }
    }
    

    public static getEODCountetypearray ( arrcountertypewisesales : Summaryrecord[]){

        var arrret = [];
        if(arrcountertypewisesales && arrcountertypewisesales.length > 0){
            for(var rec of arrcountertypewisesales){
                var newrec = <Summaryrecord>{};
                newrec.id = rec.id;
                newrec.description = Utils.getCountertypeNameFromCode(rec.id);
                newrec.summaryval1 = rec.summaryval1;
                newrec.summaryval2 = rec.summaryval3;
                arrret.push(newrec);
            }
        }

        return arrret;

    }

    public static getCountertypeSummaryRecordForFocus(bsndate, posid,summaryrec : Summaryrecord ){
        var strinsert = "insert into export_datasales(Store_id,Trn_Date,Order_Type,Check_Count,Covers_Count,Net_Amount,"+
                        "Cash_Amt,CC_Amt,Tips_Amt,AR_Amt,Forex_Amt,Comp_Amt,Chq_Amt,Taxable_Amt,Act_Amt,Vat_Amt)values(" + posid + 
                         ",'" + bsndate + "','" + summaryrec.description + "'," + summaryrec.summaryval1 + "," + summaryrec.summaryval2 + ","
                         + summaryrec.summaryval3 + "," + summaryrec.summaryval4 + ","
                         + summaryrec.summaryval5 + "," + summaryrec.summaryval6 + ","
                         + summaryrec.summaryval7 + "," + summaryrec.summaryval8 + ","
                         + summaryrec.summaryval9 + "," + summaryrec.summaryval10 + ","
                         + summaryrec.summaryval11 + "," + summaryrec.summaryval12 + ","
                         + summaryrec.summaryval13 + ")";
        return strinsert; 

    }

    public static getShiftendFocusInsert(posid,begincheck,endcheck,begintill,endtill, rec : Shiftendrecord){
        var bsndate = Utils.getBusinessDateForSQL('' + rec.businessdate);
        
        
        var setrinsert = "insert into trans_dayend(HO_Store,Dayend_Storedate,Dayend_Systemdate,Dayend_Operator,Check_Begun," +
                         "Check_End,Till_Begun,Till_End,Deposit_Amt,Coins,PettyCash)values(" +
                         + posid + ",'" + bsndate + "','" + rec.createdonstr + "','" + rec.outgoingcashier + "','" +  begincheck + "','" 
                         + endcheck + "','" + begintill + "','" + endtill + "'," + rec.totalcashinhand + "," + rec.coinsvalue + "," + rec.pettycashexpense + ")" ;
                    
        return setrinsert; 

    }

    public static getRevCode(order : Order){
        switch(order.countertype){
            case Constants.PARCEL: return 'H';
            case Constants.NORMAL: return 'G'; 
            case Constants.AC_SPECIAL: return 'G';
            case Constants.TAKEAWAY: return 'T';
            default: return 'H';
        }
    }


    public static getSettlementCode(pymt : Payment){
        switch(pymt.paymentmode){
            case Constants.PYMT_CASH: return 'C';
            case Constants.PYMT_CARD: return 'B'; 
            case Constants.PYMT_OTHER_CREDIT: return 'P';
            case Constants.PYMT_COMPLIMENTARY: return 'O';
            case Constants.PYMT_FOREX: return 'F';
            default: return 'P';
        }
    }

    public static getSettlementName(pymt : Payment){
        switch(pymt.paymentmode){
            case Constants.PYMT_CASH: return 'Cash';
            case Constants.PYMT_CARD: return 'Card'; 
            case Constants.PYMT_OTHER_CREDIT: return 'Other Credit';
            case Constants.PYMT_COMPLIMENTARY: return 'Complimentary';
            case Constants.PYMT_FOREX: return 'Foreign Currency';
            default: return 'Other Credit';
        }
    }

    public static getSettlementSubCodeForOtherCredit(pymt : Payment){
        switch(pymt.othercredittype){
            case "Outlet Staff" : return "51";
            case "Office Staff" : return "52";
            case "Others" : return "53";
            default: return "51";
        }
    }

    public static getSettlementSubCodeForComplimentary(pymt : Payment){
        switch(pymt.complimentarytype){
            case "Staff Meal" : return "11";
            case "Manager Meal" : return "12";
            case "Group Meal" : return "13";
            case "HO Staff" : return "14";
            case "Management Staff" : return "15";
            case "Maintenance Staff" : return "16";
            case "Drivers" : return "17";
            case "Admin & General" : return "18";
            case "Business Promo" : return "19";
            case "Promotion" : return "20";
            case "Gift Voucher" : return "21";
            case "Guest Compliment" : return "22";
            
            default: return "11";
        }
    }
    

    public static getSettlementSubCodeForCard(pymt : Payment){
        switch(pymt.cardtype){
            case "Visa" : return "1";
            case "Master" : return "1";
            case "Touch Points" : return "2";
            case "Amex" : return "3";
            case "Diners" : return "4";
            default: return "5";
        }
    }

    public static getSettlementSubCode(pymt : Payment){
        switch(pymt.paymentmode){
            case Constants.PYMT_CASH: return '0';
            case Constants.PYMT_CARD: return Utils.getSettlementSubCodeForCard(pymt); 
            case Constants.PYMT_OTHER_CREDIT: return Utils.getSettlementSubCodeForOtherCredit(pymt);
            case Constants.PYMT_COMPLIMENTARY: return Utils.getSettlementSubCodeForComplimentary(pymt);
            case Constants.PYMT_FOREX: return '0';
            case Constants.PYMT_AGGREGATOR: return '53';
            default: return '0';
        }
    }

    public static getSettlementSubName(pymt : Payment){
        switch(pymt.paymentmode){
            case Constants.PYMT_CASH: return 'Cash';
            case Constants.PYMT_CARD: return pymt.cardtype; 
            case Constants.PYMT_OTHER_CREDIT: pymt.othercredittype;
            case Constants.PYMT_COMPLIMENTARY: pymt.complimentarytype;
            case Constants.PYMT_FOREX: return 'Foreign Currency';
            default: return 'Other Credit';
        }
    }


    public static getOrderFullDetails( ord : Order){
        var strret = '';
        if(ord.cart.discountdetail && ord.cart.discountdetail.remarks && ord.cart.discountdetail.remarks.length > 0){
            strret = strret + 'Discount Remarks -' + ord.cart.discountdetail.remarks + '. ';
        }

        if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
            for(var pymt of ord.cart.paymentlist){
                
                if(pymt.paymentmode == Constants.PYMT_AGGREGATOR && ord.countertype == Constants.ONLINE){
                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        strret = strret + 'Pymt Ref: ' +  ord.aggrname + '#' + pymt.pymtreference + '. ';
                    }

                }else{
                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        strret = strret + 'Pymt Ref: ' +  pymt.pymtreference + '. ';
                    }
                }

                if(pymt.addlinfo && pymt.addlinfo.length > 0){
                    strret = strret +  'Pymt AddlInfo: ' + pymt.addlinfo + '. ';
                }



                if(pymt.paymentmode == Constants.PYMT_AGGREGATOR && (ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL)){
                    
                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        strret = strret +  'Zomato Pro Ref#' + pymt.pymtreference + '. ';
                    }

                }

                if(pymt.remarks && pymt.remarks.length > 0){
                    strret = strret + 'Pymt Remarks: ' + pymt.remarks + '. ';
                }

            }
        }

        return strret;

    }

    public static getReferenceNo(ord : Order){
        var strret = '';

        if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
            for(var pymt of ord.cart.paymentlist){
                
                if(pymt.paymentmode == Constants.PYMT_AGGREGATOR && ord.countertype == Constants.ONLINE){
                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        strret = strret + pymt.pymtreference + '. ';
                    }
                }

                if(pymt.paymentmode == Constants.PYMT_AGGREGATOR && (ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL)){
                    if(pymt.addlinfo && pymt.addlinfo.length > 0){
                        strret = strret +  'AddlInfo: ' + pymt.addlinfo + '. ';
                    }

                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        ord.delsource = 'Zomato Pro';
                        ord.delreference = pymt.pymtreference;
                        strret = strret +  'Zomato Pro Ref#' + pymt.pymtreference + '. ';
                    }

                }

                if(pymt.paymentmode == Constants.PYMT_OTHER_CREDIT ){
                    if(pymt.remarks && pymt.remarks.length > 0){
                        strret = strret + 'Other credit: ' + pymt.remarks + '. ';
                    }
                }

                if(pymt.paymentmode == Constants.PYMT_CARD ){
                    if(pymt.remarks && pymt.remarks.length > 0){
                        strret = strret + 'Slip No: ' + pymt.pymtreference + '. ';
                    }
                }


            }
        }

        return strret;

    }

    public static getOrderDetails ( ord : Order){
        var strret = '';
        if(ord.cart.discountdetail && ord.cart.discountdetail.remarks && ord.cart.discountdetail.remarks.length > 0){
            strret = strret + 'Discount-' + ord.cart.discountdetail.remarks + '. ';
        }

        if(ord.cart.paymentlist && ord.cart.paymentlist.length > 0){
            for(var pymt of ord.cart.paymentlist){
                
                if(pymt.paymentmode == Constants.PYMT_AGGREGATOR && ord.countertype == Constants.ONLINE){
                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        strret = strret + ord.aggrname + '#' + pymt.pymtreference + '. ';
                    }

                }

                if(pymt.paymentmode == Constants.PYMT_AGGREGATOR && (ord.countertype == Constants.NORMAL || ord.countertype == Constants.AC_SPECIAL)){
                    if(pymt.addlinfo && pymt.addlinfo.length > 0){
                        strret = strret +  'AddlInfo: ' + pymt.addlinfo + '. ';
                    }

                    if(pymt.pymtreference && pymt.pymtreference.length > 0){
                        ord.delsource = 'Zomato Pro';
                        ord.delreference = pymt.pymtreference;
                        strret = strret +  'Zomato Pro Ref#' + pymt.pymtreference + '. ';
                    }

                }

                if(pymt.paymentmode == Constants.PYMT_OTHER_CREDIT ){
                    if(pymt.remarks && pymt.remarks.length > 0){
                        strret = strret + 'Other credit: ' + pymt.remarks + '. ';
                    }
                }

                if(pymt.paymentmode == Constants.PYMT_CARD ){
                    if(pymt.remarks && pymt.remarks.length > 0){
                        strret = strret + 'Slip No: ' + pymt.pymtreference + '. ';
                    }
                }

            }
        }

        return strret;

    }



    public static getFocusInsertHeader(order : Order,posid) : string[]
    {
      
      var arrret : string[] = [];
      var multisettlement = 'N';
      if(order.cart.paymentlist && order.cart.paymentlist.length > 1){
          multisettlement = 'Y';
      }

      
      if(!order.numguests) order.numguests = 0;
      var revcode = Utils.getRevCode(order);

      if(!order.deliveredby ) order.deliveredby = '';
      if(!order.customermobile){
            order.customermobile = '';
            order.customername = '';
      }

      if(order.cart.discountdetail && order.cart.discountdetail.discountcode == 83){
        order.customername = order.cart.discountdetail.guestname;
        order.customermobile = order.cart.discountdetail.guestdetails;
      }

      if(order.deladdrline1 && order.deladdrline1.length > 0){
        order.addressstr = order.deladdrline1;
      }

      if(order.deladdrline2 && order.deladdrline2.length > 0){
        order.addressstr = order.addressstr + "," + order.deladdrline2;
      }

       
       if(!order.addressstr) order.addressstr = '';

       if(order.addressstr && order.addressstr.length > 0){
         order.addressstr  = order.addressstr.replace(/'/g, ' ');
       }
       


       if(order.customername) order.customername = order.customername.replace(/'/g, ' ');


      for(var pymt of order.cart.paymentlist){
        
        
        //console.log('|ORDERINSERT|GENERATING FOR AMOUNT' + pymt.paidamount );
        
        //var pymt = order.cart.paymentlist[0];
        var setcode = Utils.getSettlementCode(pymt);
        var setname = Utils.getSettlementName(pymt);
        var subcode = Utils.getSettlementSubCode(pymt);
        var subname = '';
        
        var cardno = '';
        var slipno = '';
        var tips = '0';
        var pymtremarks = '';

        if(pymt.paymentmode == Constants.PYMT_CARD){
            cardno = pymt.cardno;
            

            if(!pymt.pymtreference) pymt.pymtreference = '';
            slipno = pymt.pymtreference;
            tips = '' + pymt.tip;
        }


        //SEt amounts for multi settlement 
        var netpay = order.cart.netpayable;
        var totaldisc = order.cart.totaldiscount;
        var delchargesincltax = order.cart.deliverycharges;
        var actamt  = (order.cart.netpayable - order.cart.roundingamount);
        
        var delcharges =  Utils.round(delchargesincltax / (1 + (5/100)),3);
        var taxableamt = order.cart.sumitemtaxable  + delcharges;

        var vatoncharges = Utils.round(delcharges * (5/100),3);
        var nettax = Utils.round(order.cart.nettaxes + vatoncharges,3);

        //For pos id 1554
        if(order.posid == "1554"){
            taxableamt = Utils.round(order.cart.sumitemtaxable + order.cart.sumsc,3);
            nettax = Utils.round(order.cart.sumvat,3);
        }
        //FOr 1554

        var fooddisc = order.fooddisc;
        var bevdisc = order.bevdisc;
        var miscdisc = order.misdisc;
        var tobdisc = order.tobdisc;

        var extdiscount = 0;
        if(order.externaldiscount && order.externaldiscount > 0 )    
            extdiscount = order.externaldiscount;

        /*if(order.id == 1677861149464){
            console.log('|EXT DISCOUNT ' + extdiscount);
        }*/

        if(multisettlement == 'Y'){
            var ratio = Utils.round(pymt.paidamount / order.cart.netpayable,2);
            netpay = netpay * ratio; 
            totaldisc = totaldisc  * ratio;
            delcharges = delcharges * ratio;
            nettax = nettax * ratio; 
            actamt = actamt * ratio; 
            taxableamt = taxableamt * ratio;
            fooddisc = fooddisc * ratio;
            bevdisc = bevdisc * ratio;
            miscdisc = miscdisc * ratio;
            tobdisc = tobdisc * ratio;
            extdiscount = extdiscount * ratio;
                
        }

        var disccode = '';
        if(order.cart.totaldiscount > 0 && order.cart.discountdetail && order.cart.discountdetail.discountcode ){
            disccode = order.cart.discountdetail.discountcode;
        }



        //if(pymt.paymentmode == Constants.PYMT_OTHER_CREDIT)
        //    pymtremarks = pymt.remarks;
        pymtremarks = Utils.getOrderDetails(order);

        if(pymtremarks && pymtremarks.length > 0){
            pymtremarks  = pymtremarks.replace(/'/g, ' ');
        }
          
   

        var fullremarks  = Utils.getOrderFullDetails(order);
    
        if(fullremarks && fullremarks.length > 0){
            fullremarks  = fullremarks.replace(/'/g, ' ');
        }
        
        var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
        var createdon = order.createdonstr;
        
        var optr = order.servedbystr;
        if(!optr || optr.length == 0) optr = order.billedbystr;

        var ccagent = '';

        /*var delby = order.deliveredby;
        if(order.ccordid && order.ccordid > 0){
            optr = 'CALL_CENTER';
            ccagent = order.billedbystr;
            delby = 0;
        }*/
        var delby = order.driverdetails;

        //** CHANGED BILLED BY  */
        if(order.ccordid && order.ccordid > 0){
            optr = 'CALL_CENTER';
            ccagent = order.ccagentstr;
            
        }

        var countername = order.countername;
        if (countername && countername.length >= 50){
            countername = countername.substring(0,49);
        }

        var strinsert = "insert into trans_transhd(ho_store,Transhd_Billno," + 
            "Transhd_Billdate,Transhd_Table_Token,Transhd_Covers,Transhd_Revcode,Transhd_Amt," + 
            "Transhd_Tax,Transhd_Discount,Transhd_Paid,Transhd_Setcode,Transhd_Setname," + 
            "Transhd_Setsubcode,Transhd_Setsubname,Transhd_Operator,Transhd_DtTime," + 
            "Transhd_Credit,Transhd_Slipno,Transhd_Currencycode,Transhd_Currencyname," + 
            "Transhd_Currencyrate,Transhd_Currencyamt,Transhd_Discountcode," + 
            "Transhd_Discountname,Transhd_Printed,Transhd_Multiple,Transhd_DBoy," + 
            "Transhd_SetTime,Transhd_SetOperator,Transhd_Telno,Transhd_Tips," + 
            "Transhd_Details,Transhd_System,Transhd_Nationality," +
            "CheqNo,Bank,AdvnceMode,Transhd_ActAmount,Transhd_TaxableAmt,Transhd_VAT," + 
            "Transhd_DelSource,Transhd_DelReferenceNo,Transhd_OperatorId,customer_name,customer_tel,customer_address," + 
            "Transhd_FullRemarks,Transhd_FoodDiscount,Transhd_BEVDiscount,Transhd_MiscDiscount,Transhd_TobcoDiscount,CC_Agent,Transhd_Extdiscount)values(" + posid + ",'"  + order.receiptno + "','" + bsndate  
            + "','" + countername + "'," + order.numguests + ",'" +  revcode + "'," +
            + netpay + "," + delcharges + "," 
            + totaldisc + "," + pymt.paidamount   + ",'" + setcode 
            + "','" + setname + "','" + subcode + "','" + subname + "','" 
            + optr + "','" + createdon + "','" + cardno + "','" + slipno + "','' ,'','','','" + disccode +"','','Y','"+ multisettlement + "','" 
            + delby + "','" + pymt.createdonstr + "','" + order.billedbystr + "','" + order.customermobile + "'," + tips + ",'" + pymtremarks + "','',"+ order.nationality +",'','','','" +  
            actamt  + "','" 
            + taxableamt + "','" + nettax + "','" + order.delsource  + "','" + order.delreference + "','" 
            + order.empcode + "','" + order.customername + "','" + order.customermobile  +  "','" + order.addressstr  + "','" + fullremarks + "'," + fooddisc + "," + bevdisc + "," + miscdisc + "," + tobdisc + ",'" + ccagent + "'," + extdiscount + ")"; 

            arrret.push(strinsert);
            
            /*if(order.id == 1679424598939){
                console.log('|CC ORDER|' + order.billedbystr + '|' +  order.ccordid + '|: ' + strinsert);
            }*/
        }
       
        return arrret;          
  
    }
  
    public static findMatchingPLU(comboplu){
        switch(comboplu){
            case '7008$9011$9001': return '13001';
            case '7008$9011$9002': return '13002';
            case '7008$9011$9003': return '13003';
            case '7008$9010$9001': return '13004';
            case '7008$9010$9002': return '13005';
            case '7008$9010$9003': return '13006';
            case '7009$9011$9001': return '13007';
            case '7009$9011$9002': return '13008';
            case '7009$9011$9003': return '13009';
            case '7009$9010$9001': return '13010';
            case '7009$9010$9002': return '13011';
            case '7009$9010$9003': return '13012';
            case '7010$9011$9001': return '13013';
            case '7010$9011$9002': return '13014';
            case '7010$9011$9003': return '13015';
            case '7010$9010$9001': return '13016';
            case '7010$9010$9002': return '13017';
            case '7010$9010$9003': return '13018';
            case '7011$9011$9001': return '13019';
            case '7011$9011$9002': return '13020';
            case '7011$9011$9003': return '13021';
            case '7011$9010$9001': return '13022';
            case '7011$9010$9002': return '13023';
            case '7011$9010$9003': return '13024';
            case '7012$9011$9001': return '13025';
            case '7012$9011$9002': return '13026';
            case '7012$9011$9003': return '13027';
            case '7012$9010$9001': return '13028';
            case '7012$9010$9002': return '13029';
            case '7012$9010$9003': return '13030';
            case '7013$9011$9001': return '13031';
            case '7013$9011$9002': return '13032';
            case '7013$9011$9003': return '13033';
            case '7013$9010$9001': return '13034';
            case '7013$9010$9002': return '13035';
            case '7013$9010$9003': return '13036';
            case '7014$9011$9001': return '13037';
            case '7014$9011$9002': return '13038';
            case '7014$9011$9003': return '13039';
            case '7014$9010$9001': return '13040';
            case '7014$9010$9002': return '13041';
            case '7014$9010$9003': return '13042';
            case '7015$9011$9001': return '13043';
            case '7015$9011$9002': return '13044';
            case '7015$9011$9003': return '13045';
            case '7015$9010$9001': return '13046';
            case '7015$9010$9002': return '13047';
            case '7015$9010$9003': return '13048';
            case '7016$9013$9011$9001': return '13049';
            case '7016$9013$9011$9002': return '13050';
            case '7016$9013$9011$9003': return '13051';
            case '7016$9013$9010$9001': return '13052';
            case '7016$9013$9010$9002': return '13053';
            case '7016$9013$9010$9003': return '13054';
            case '7016$9012$9011$9003': return '13055';
            case '7016$9012$9011$9001': return '13056';
            case '7016$9012$9011$9002': return '13057';
            case '7016$9012$9010$9001': return '13058';
            case '7016$9012$9010$9002': return '13059';
            case '7016$9012$9010$9003': return '13060';
            case '7017$9011$9007': return '13061';
            case '7017$9011$9008': return '13062';
            case '7017$9011$9009': return '13063';
            case '7017$9010$9007': return '13064';
            case '7017$9010$9008': return '13065';
            case '7017$9010$9009': return '13066';
            case '7018$9011$9007': return '13067';
            case '7018$9011$9008': return '13068';
            case '7018$9011$9009': return '13069';
            case '7018$9010$9007': return '13070';
            case '7018$9010$9008': return '13071';
            case '7018$9010$9009': return '13072';
            case '7019$9011$9007': return '13073';
            case '7019$9011$9008': return '13074';
            case '7019$9011$9009': return '13075';
            case '7019$9010$9007': return '13076';
            case '7019$9010$9008': return '13077';
            case '7019$9010$9009': return '13078';
            case '7020$9011$9007': return '13079';
            case '7020$9011$9008': return '13080';
            case '7020$9011$9009': return '13081';
            case '7020$9010$9007': return '13082';
            case '7020$9010$9008': return '13083';
            case '7020$9010$9009': return '13084';
            case '7021$9011$9007': return '13085';
            case '7021$9011$9008': return '13086';
            case '7021$9011$9009': return '13087';
            case '7021$9010$9007': return '13088';
            case '7021$9010$9008': return '13089';
            case '7021$9010$9009': return '13090';
            case '7022$9011$9004': return '13091';
            case '7022$9011$9005': return '13092';
            case '7022$9011$9006': return '13093';
            case '7022$9010$9004': return '13094';
            case '7022$9010$9005': return '13095';
            case '7022$9010$9006': return '13096';
            case '7023$9011$9004': return '13097';
            case '7023$9011$9005': return '13098';
            case '7023$9011$9006': return '13099';
            case '7023$9010$9004': return '13100';
            case '7023$9010$9005': return '13101';
            case '7023$9010$9006': return '13102';
            case '7024$9013$9011$9004': return '13103';
            case '7024$9013$9011$9005': return '13104';
            case '7024$9013$9011$9006': return '13105';
            case '7024$9013$9010$9004': return '13106';
            case '7024$9013$9010$9005': return '13107';
            case '7024$9013$9010$9006': return '13108';
            case '7024$9012$9011$9004': return '13109';
            case '7024$9012$9011$9005': return '13110';
            case '7024$9012$9011$9006': return '13111';
            case '7024$9012$9010$9004': return '13112';
            case '7024$9012$9010$9005': return '13113';
            case '7024$9012$9010$9006': return '13114';
            case '7025$9011$9004': return '13115';
            case '7025$9011$9005': return '13116';
            case '7025$9011$9006': return '13117';
            case '7025$9010$9004': return '13118';
            case '7025$9010$9005': return '13119';
            case '7025$9010$9006': return '13120';
            case '7026$9011$9004': return '13121';
            case '7026$9011$9005': return '13122';
            case '7026$9011$9006': return '13123';
            case '7026$9010$9004': return '13124';
            case '7026$9010$9005': return '13125';
            case '7026$9010$9006': return '13126';

            case '7027$9011$9003' : return '13249';
            case '7027$9010$9003' : return '13250';
            case '7027$9011$9002' : return '13251';
            case '7027$9010$9002' : return '13252';
            case '7028$9011$9003' : return '13253';
            case '7028$9010$9003' : return '13254';
            case '7028$9011$9002' : return '13255';
            case '7028$9010$9002' : return '13256';
            case '7029$9011$9003' : return '13257';
            case '7029$9010$9003' : return '13258';
            case '7029$9011$9002' : return '13259';
            case '7029$9010$9002' : return '13260';
            case '7030$9011$9003' : return '13261';
            case '7030$9010$9003' : return '13262';
            case '7030$9011$9002' : return '13263';
            case '7030$9010$9002' : return '13264';



        }

        return null;
    }

    public static getNonComboAmount(arrinv : string[]){
        var retamount = 0;

        for(var custitem of arrinv){
            if(custitem && custitem.indexOf("|") > 0){ 
                var arrcustitem = custitem.split("|");
                var custrate = parseFloat(arrcustitem[1]);
                if(custrate && custrate > 0){
                    retamount = retamount + custrate; 
                }
            }
        }

        return retamount;

    }

    public static getNonComboCustInvForPizzaPAN(arrinv : string[]){
        var arrnoncombo : string[] = [];
        for(var custitem of arrinv){
            if(custitem && custitem.indexOf("|") > 0){ 
                var arrcustitem = custitem.split("|");
                var plucode = arrcustitem[0];
                if(plucode){
                    plucode = plucode.trim();
                }
                var codeasnum = parseInt(plucode);
                
                //Push only within range..
                if(codeasnum && codeasnum >= 9000 && codeasnum <= 9100){
                        //tis is for combo
                }else{
                    arrnoncombo.push(custitem);
                }
            }
        }

        return arrnoncombo;

    }
    
    public static getCustInvForPizzaPAN(baseplu : string, arrinv : string[]){
        var arrcodes : string[] = [];
        for(var custitem of arrinv){
            if(custitem && custitem.indexOf("|") > 0){ 
                var arrcustitem = custitem.split("|");
                var plucode = arrcustitem[0];
                if(plucode){
                    plucode = plucode.trim();
                }
                var codeasnum = parseInt(plucode);
                //Push only within range..
                if(codeasnum && codeasnum >= 9000 && codeasnum <= 9100)
                    arrcodes.push(plucode);
            }
        }

        arrcodes.sort((a:string, b: string)=> a > b ? -1 : 1 );
        
        var resultproduct = baseplu + "$"; 
        for(var code of arrcodes ){
            resultproduct = resultproduct + code + "$"; 
        }


        resultproduct = resultproduct.substring(0,resultproduct.length - 1);
        var matchingplu =Utils.findMatchingPLU(resultproduct);
        //console.log('PIZZAPANORDER::|resultproduct|' + resultproduct + '|MATCHING PLU|' + matchingplu);
        
        return matchingplu;
    }


    public static getFocusInsertDetail(order : Order, posid) 
    {
      var arrreturn = [];
  
      var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
      var revcode = 'D';
      if(order.countertype == Constants.PARCEL) revcode = 'H';
         
        
      var i =0;

      
        for(var item of order.cart.lineitems){

            item.productname  = item.productname.replace(/'/g, ' ');

            if(item.customization && item.customization.length > 0) item.customization  = item.customization.replace(/'/g, ' ');

            var doIParse= false;
            var custinv = item.custinv;
            
            
            if(custinv && custinv.length > 2){
                custinv= custinv.trim();
                var lastchar= custinv.charAt(custinv.length-1);

            
                if(lastchar ==','){
                    doIParse = true;
                    custinv =custinv.substring(0,custinv.length -1);
                }

            }

            
            if(doIParse){
            

                var totalrate = item.rate + item.customizationrate;

                var ogtotalrate = 0;
                if(item.schcode && item.schcode.length > 1 ){
                    ogtotalrate = item.ograte + item.ogcustrate;
                }

                var arrinv = custinv.split(",");
                
                var pizzapandone= false; 
                if(order.ispizzapan ){
                    var comboplu =Utils.getCustInvForPizzaPAN(item.pcode, arrinv) 
                    if(comboplu){
            
                        //now check if non combo exists
                        var arrnoncombo = Utils.getNonComboCustInvForPizzaPAN(arrinv);
                        var ratio = 1;

                        if(arrnoncombo && arrnoncombo.length > 0){
                            arrinv = arrnoncombo;
                            var noncomboamount = Utils.getNonComboAmount(arrnoncombo);
                            if(totalrate > 0)
                                ratio  = 1 - Utils.round(noncomboamount/totalrate,3);
                        }else
                            pizzapandone = true;

                        var appramt = Utils.round(item.grossamt * ratio,3);
                        var apprdiscount = Utils.round(item.discount * ratio,3);
            
                        
                        var strinsert = "insert into trans_transdtl (HO_Store,Trans_Billno,Trans_Billdate," + 
                        "Trans_Plucode,Trans_Pluname,Trans_Qty,Trans_Amt,Trans_Stylecode," + 
                        "Discount_YN,Discount_Prcnt,POS_No,Trans_Ocode,Order_Time,Trans_Operator,Trans_Itemdiscount)values(" + posid + 
                        ",'" + order.receiptno + "','" + bsndate + "','" +  comboplu + "','" 
                        + item.productname  + "'," + item.quantity + "," + appramt  + ",'" + revcode 
                        +  "','N',0," + (i++)  + "," +  item.occode + ",'" + item.kottimestr + "','" + order.servedbystr + "',"+ apprdiscount +")";  
                
                        arrreturn.push(strinsert);
                     
                    }


                }

                if(!pizzapandone){
                    for (var custitem of arrinv){
                        
                        if(custitem && custitem.indexOf("|") > 0)
                        { 
                                var arrcustitem = custitem.split("|");
                                var plucode = arrcustitem[0];
                                if(plucode){
                                    plucode = plucode.trim();
                                }

                                var custrate = parseFloat(arrcustitem[1]);
                                console.log('CUSTINV|CUSTITEM|' + custitem + '|PLU#' + plucode +'#COST|' + custrate +'Float' + isNaN(custrate));

                                if(plucode && plucode.length > 0 && custrate != null && !isNaN(custrate)){

                                    var ratio = 1;

                                    if(totalrate > 0)
                                        ratio = Utils.round(custrate/totalrate,3);
                                    
                                    //If scheme code is there
                                    if(ogtotalrate > 0)
                                        ratio = Utils.round(custrate/ogtotalrate,3);

                                        
                                    var appramt = Utils.round(item.grossamt * ratio,3);
                                    var apprdiscount = Utils.round(item.discount * ratio,3);
            

                                    
                                    var prodname = item.productname + ' (' + item.customization + ')';      
                                    
                                    
                                    var strinsert = "insert into trans_transdtl (HO_Store,Trans_Billno,Trans_Billdate," + 
                                    "Trans_Plucode,Trans_Pluname,Trans_Qty,Trans_Amt,Trans_Stylecode," + 
                                    "Discount_YN,Discount_Prcnt,POS_No,Trans_Ocode,Order_Time,Trans_Operator,Trans_Itemdiscount)values(" + posid + 
                                    ",'" + order.receiptno + "','" + bsndate + "','" +  plucode + "','" 
                                    + prodname  + "'," + item.quantity + "," + appramt  + ",'" + revcode 
                                    +  "','N',0," + (i++)  + "," +  item.occode + ",'" + item.kottimestr + "','" + order.servedbystr + "',"+ apprdiscount +")";  
                            
                                    console.log('CUSTINV|CUSTITEM|FOCUSINSERT' + plucode);
                                    arrreturn.push(strinsert);
                                }
                        }

                    }

                    if(item.rate > 0){

                        var plucode =item.pcode;
                        var ratio = Utils.round(item.rate/totalrate,3);
                        var appramt = Utils.round(item.grossamt * ratio,3);
                        var apprdiscount = Utils.round(item.discount * ratio,3);
 
                        var strinsert = "insert into trans_transdtl (HO_Store,Trans_Billno,Trans_Billdate," + 
                             "Trans_Plucode,Trans_Pluname,Trans_Qty,Trans_Amt,Trans_Stylecode," + 
                             "Discount_YN,Discount_Prcnt,POS_No,Trans_Ocode,Order_Time,Trans_Operator,Trans_Itemdiscount)values(" + posid + 
                             ",'" + order.receiptno + "','" + bsndate + "','" +  plucode + "','" 
                             + item.productname  + "'," + item.quantity + "," + appramt  + ",'" + revcode 
                             +  "','N',0," + (i++)  + "," +  item.occode + ",'" + item.kottimestr + "','" + order.servedbystr + "',"+ apprdiscount +")";  
                     
                        console.log('CUSTINV|CUSTITEM|FOCUSINSERT' + plucode );
                        arrreturn.push(strinsert);
                   }
                }


            }else{
                
                var strinsert = "insert into trans_transdtl (HO_Store,Trans_Billno,Trans_Billdate," + 
                            "Trans_Plucode,Trans_Pluname,Trans_Qty,Trans_Amt,Trans_Stylecode," + 
                            "Discount_YN,Discount_Prcnt,POS_No,Trans_Ocode,Order_Time,Trans_Operator,Trans_Itemdiscount)values(" + posid + 
                ",'" + order.receiptno + "','" + bsndate + "','" +  item.pcode + "','" 
                + item.productname  + "'," + item.quantity + "," + item.grossamt  + ",'" + revcode 
                +  "','N',0," + (i++)  + "," +  item.occode + ",'" + item.kottimestr + "','" + order.servedbystr + "',"+ item.discount +")";  
        
                //console.log('|DETAIL INSERT-->-->|' + strinsert);
                arrreturn.push(strinsert);

            }
  
        
      }
  
      
      return arrreturn;
  
     
    }
  
  
    public static getFocusVoidHeader(order : Order,posid) : string
    {
  
      if(!order.numguests) order.numguests = 0;
      var revcode = 'D';
      if(order.countertype == Constants.PARCEL) revcode = 'H';
      if(!order.deliveredby ) order.deliveredby = '';
       
      var pymt = order.cart.paymentlist[0];
      var setcode = 'C';
      var setname = 'Cash';
      var subcode = '0';
      var cardno = '';
      var slipno = '';
      var subname = '';
      if(pymt && pymt.paymentmode == Constants.PYMT_CARD){
          setcode = 'B';
          setname = 'Credit Card';
          subcode  = '1';
          subname = 'Visa';
          cardno = pymt.cardno;
          slipno = pymt.pymtreference;
  
      }
  
      var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
      var createdon = order.createdonstr;
  
      var strinsert = "insert into trans_voidhd(HO_Store, voidhd_Billno,voidhd_Billdate," +
             "voidhd_DtTime,voidhd_voiddt,voidhd_Amt,voidhd_Reason,voidhd_Operator," +
             "voidhd_Revcode,voidhd_Type) values(" + posid + ",'"  + order.receiptno 
             + "','" + bsndate + "','" + createdon + "','" + order.updatedonstr + "'," + order.cart.sumitemtaxable + ",'" 
             + order.cancelreason + "','" + order.billedby + "','" +  revcode + "','Full Void')"
           
      return strinsert;          
  
    }
  
  
  
    public static getFocusVoidDetail(order : Order,posid) 
    {
      var arrreturn = [];
  
      var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
      for(var item of order.cart.lineitems){
        
        var strinsert = "insert into trans_voiddtl (HO_Store,void_Billno,void_date," + 
                    "void_Plucode,void_Pluname,void_Qty,void_Amt)values(" + posid + 
                    ",'" + order.receiptno + "','" + bsndate + "','" +  item.pcode + "','" 
                    + item.productname  + "'," + item.quantity + "," + item.taxableamt  + ")";  
  
        arrreturn.push(strinsert);
      }
  
      return arrreturn;
  
     
    }

    public static getFocusInsertTax(order : Order,posid,scper,tfeeper,mfeeper) 
    {
      var arrreturn = [];
  
      var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
      

      if(order.cart.sumsc && order.cart.sumsc > 0 ){
        var strsc = "insert into trans_transtax (HO_Store,Transhd_Billno,Transhd_Billdate," + 
                    "TaxName,TaxAmount,TaxPercentage)values(" + posid + 
                    ",'" + order.receiptno + "','" + bsndate + "','Service Charge'," 
                    + order.cart.sumsc  + "," + scper  + ")";  
  
        arrreturn.push(strsc);
      }
  
      if(order.cart.sumtfee && order.cart.sumtfee > 0 ){
        var strtfee = "insert into trans_transtax (HO_Store,Transhd_Billno,Transhd_Billdate," + 
                    "TaxName,TaxAmount,TaxPercentage)values(" + posid + 
                    ",'" + order.receiptno + "','" + bsndate + "','Tourism Fee'," 
                    + order.cart.sumtfee  + "," + tfeeper  + ")";  
  
        arrreturn.push(strtfee);
      }

      if(order.cart.summfee && order.cart.summfee > 0 ){
        var strmfee = "insert into trans_transtax (HO_Store,Transhd_Billno,Transhd_Billdate," + 
                    "TaxName,TaxAmount,TaxPercentage)values(" + posid + 
                    ",'" + order.receiptno + "','" + bsndate + "','Municipal Fee'," 
                    + order.cart.summfee  + "," + mfeeper  + ")";  
  
        arrreturn.push(strmfee);
      }

      return arrreturn;
      
  
     
    }



    public static getApplicableRate(prod : Product, countertype, locationid){
        var rate =0;
        
        switch(countertype){
            case Constants.NORMAL:
                rate = prod.sellingprice;
                break;
            case Constants.AC_SPECIAL:
                rate = prod.acprice;
                break;
            case Constants.PARCEL:
                rate = prod.parcelprice;
                break;
            case Constants.TAKEAWAY:
                rate = prod.takeawayprice;
                break;
            case Constants.ONLINE:
                rate = prod.onlineprice;
                break;
            case Constants.SELFAPP:
                rate = prod.onlineprice;
                break;
        }
        
        if(prod.customization && prod.customization.locationpricelist && prod.customization.locationpricelist.length > 0){
            var strlocation = "|" + locationid + "|";
            var strcountertype = "|" + countertype + "|";
            
            for(var locprice of prod.customization.locationpricelist){
                if(locprice.locationcodes && locprice.locationcodes.indexOf(strlocation) >= 0){
                    if(locprice.countertypes && locprice.countertypes.indexOf(strcountertype) >= 0){
                        return rate+locprice.difference;
                    }
                }
                    
            }
        }

        return rate; 


    }
  
  
    public static getFocusDeliveryInfo(order : Order,posid) 
    {
      if(!order.aggrref) order.aggrref = '';
      var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);

        if(order.deladdrline1) order.deladdrline1 = order.deladdrline1.replace(/'/g, ' ');
        if(order.deladdrline2) order.deladdrline2 = order.deladdrline2.replace(/'/g, ' ');
        if(order.customername) order.customername = order.customername.replace(/'/g, ' ');
        if(order.orderremarks) order.orderremarks = order.orderremarks.replace(/'/g, ' ');

        console.log('Address set to --> ' + order.deladdrline1 + ' -- ' + order.deladdrline2 )
        
        
        var strinsert = "insert into master_delivery(HO_Store,Del_Tel,Del_Name,Del_Addr1,Del_Addr2,Del_Addr3," + 
                        "Del_Mob,Del_Telno2,Del_Boy," + 
                        "Del_Shop,Del_Landmark,Del_Zone,Del_Date,Del_Remarks,Del_Value,Del_Count,Del_OrderId," + 
                        "Del_DeliveryDate,DeliverySource,Del_RefNo)values(" 
                        + posid + ",'" + order.customermobile + "','" + order.customername + "','" +  order.deladdrline1 + "','" 
                        + order.deladdrline2  + "',''," 
                        + order.customermobile + ",'','" 
                        + order.deliveredby  + "','','','','" + bsndate + "','" + order.orderremarks 
                        + "','" + order.cart.netpayable + "','" + order.cart.sumitemcount + "','"  + order.id + "','" + bsndate + "','','')";  
  
        return strinsert; 
  
     
    }
 
    //Get mode wise payment from order 
    public static getModeWisePayment(pymtmode, ord : Order){
        if(ord.cart.paymentlist){
            for(var pymt of ord.cart.paymentlist){
                if(pymt.paymentmode == pymtmode)
                    return pymt.paidamount;
            }
        }
        return 0;
    }

    public static checkSchemeValidity(sch : Retailerscheme, curbsndate) : boolean
    {
        //Check if dates match
        console.log('|SCHEME| Start ' + sch.startdate + ' End ' + sch.enddate + ' Cur ' + curbsndate + ' Days ' + sch.schemedays);
        if(sch.startdate <= curbsndate && sch.enddate >= curbsndate && (sch.forconsumerapp == null || sch.forconsumerapp == false)  ){
            var curdatetime = new Date(); 
            
            //Validate hours 
            if(sch.schemehours && sch.schemehours.length > 0){
                var arrhours = sch.schemehours.split("-");
                if(arrhours && arrhours.length == 2){
                    var starthour = parseInt(arrhours[0]);
                    var endhour = parseInt(arrhours[1]);
                    var curhour = curdatetime.getHours();


                    //if(Constants.DEBUG_MODE) console.log('|SCHEME| Cur day -->  ' + curdatetime.getDay() + ' Scheme days ' +  sch.schemedays + ' Start hours ' + starthour + ' End hour ' + endhour + ' Cur hour ' + curhour);
                    if(curhour < starthour || curhour >= endhour){
                        return false; 
                    }
                }
            }

            
            const currentDay = new Date().toLocaleString('en-US', { weekday: 'short' });
            if(sch.schemedays && sch.schemedays.length > 2 && !sch.schemedays.includes(currentDay)) return false; 

            /*//Vadlidate day of week
            var curdayofweek = Utilfunctions.getDayNameForDay(curdatetime.getDay());

            //if(Constants.DEBUG_MODE) console.log('|SCHEME|Curday of week: ' + curdayofweek);
            if(!sch.schemedays || !(sch.schemedays.indexOf(curdayofweek) >= 0)){
                return false;
            }*/
        }else{
            if(Constants.DEBUG_MODE) console.log('|SCHEME| Start end out of range OR SChemne only for conusmer app');
            return false; 
        }

        return true;
    }
 
    
    public static getBusinessDateFromString(strdate : string){
        if(strdate && strdate.length === 10){
            var day = strdate.substring(0,2);
            var month = strdate.substring(3,5);
            var year = strdate.substring(6,10);
            
            var subdatestr = year + month + day; 
            if(!isNaN(parseInt(subdatestr)))
                return parseInt(subdatestr)
            else
                return 0;

          }else{
              return 0;
          }
    }


    static checkItemSchemeApplicability(prod : Product, sch : Retailerscheme){
        switch(sch.schemetype){
            case Constants.SCHEME_ON_PRODUCT:
                if(sch.schemerunon === prod.id) return true;
                break;
            case Constants.SCHEME_ON_SUBCATEGORY:
                if(sch.schemerunon === prod.subcategoryid) return true;
                break;
            case Constants.SCHEME_ON_CATEGORY:
                if(sch.schemerunon === prod.categoryid) return true;
                break;
        }

        if(prod.customization && prod.customization.applicableschemes && prod.customization.applicableschemes.length > 0){
            var strschemes = prod.customization.applicableschemes;
            var arrsch = strschemes.split(",");
                
            if(arrsch && arrsch.length > 0){
                for(var str of arrsch){
                    if(str === sch.schemecode)
                        return true; 
                }
            }
        }

        return false;
    }

    public static getFocusInsertDetailForReport(order : Order, posid) 
    {
      var arrreturn = <Summaryrecord[]>[];
  
      var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
      var revcode = 'D';
      if(order.countertype == Constants.PARCEL) revcode = 'H';
         
        
      var i =0;

        for(var item of order.cart.lineitems){

            var doIParse= false;
            var custinv = item.custinv;
            
            console.log('|FCS|' + item.productname + '#Customization-->' + custinv +'#PCode-->' + item.pcode  + '#CustRate-->' + item.customizationrate);

                                

            if(item.custinv && item.custinv.length > 2){
                var lastchar= item.custinv.charAt(item.custinv.length-1);
                if(lastchar ==','){
                    doIParse = true;
                    custinv =custinv.substring(0,custinv.length -1);
                }

            }

            if(doIParse){
               
                var totalrate = item.rate + item.customizationrate;

                var ogtotalrate = 0;
                if(item.schcode && item.schcode.length > 1 ){
                    ogtotalrate = item.ograte + item.ogcustrate;
                }

                var arrinv = custinv.split(",");

                for (var custitem of arrinv){
                       
                       if(custitem && custitem.indexOf("|") > 0)
                       { 
                            var arrcustitem = custitem.split("|");
                            var plucode = arrcustitem[0];
                            if(plucode){
                                plucode = plucode.trim();
                            }

                            var custrate = parseFloat(arrcustitem[1]);
                            console.log('CUSTINV|CUSTITEM|' + custitem + '|PLU#' + plucode +'#COST|' + custrate +'Float' + isNaN(custrate));

                            if(plucode && plucode.length > 0 && custrate != null && !isNaN(custrate)){

                                
                                var ratio = Utils.round(custrate/totalrate,3);
                                
                                //If scheme code is there
                                if(ogtotalrate > 0)
                                    ratio = Utils.round(custrate/ogtotalrate,3);

                                var appramt = Utils.round(item.grossamt * ratio,3);
                                var apprdiscount = Utils.round(item.discount * ratio,3);
                          
                                var appritemamt = Utils.round(item.amount * ratio,3);
                                var appritemtax = Utils.round(item.itemtax * ratio,3);
                                var apprtaxableamt = Utils.round(item.taxableamt * ratio,3);
                                
                                

                                var rec = <Summaryrecord>{};
                                rec.id = order.id;
                                rec.summaryval1 = posid; 
                                rec.summaryval2 = bsndate; 
                                rec.summaryval3 = order.receiptno; 
                                rec.summaryval4 = plucode; 
                                rec.summaryval5 = item.productname + ' (' + item.customization + ')'; 
                                rec.summaryval6 = item.quantity; 
                                rec.summaryval7 = appramt; 
                                rec.summaryval8 = item.occode; 
                                rec.summaryval9 = revcode; 
                                rec.summaryval10 = apprdiscount;
                                rec.summaryval11 = item.subcatid; 
                                
                                console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|' + plucode);
                                //Sum new things 
                                rec.summaryval12 = appritemamt;
                                rec.summaryval13 = appritemtax;
                                rec.summaryval14 = apprtaxableamt;

                                
                                
                                
                                arrreturn.push(rec);
                            }
                       }

                }

                if(item.rate > 0){

                       var plucode =item.pcode;
                       var ratio = Utils.round(item.rate/totalrate,3);
                       var appramt = Utils.round(item.grossamt * ratio,3);
                       var apprdiscount = Utils.round(item.discount * ratio,3);

                       var appritemamt = Utils.round(item.amount * ratio,3);
                       var appritemtax = Utils.round(item.itemtax * ratio,3);
                       var apprtaxableamt = Utils.round(item.taxableamt * ratio,3);
                       
                  
                       var rec = <Summaryrecord>{};
                       rec.id = order.id;
                       rec.summaryval1 = posid; 
                       rec.summaryval2 = bsndate; 
                       rec.summaryval3 = order.receiptno; 
                       rec.summaryval4 = plucode; 
                       rec.summaryval5 = item.productname; 
                       rec.summaryval6 = item.quantity; 
                       rec.summaryval7 = appramt; 
                       rec.summaryval8 = item.occode; 
                       rec.summaryval9 = revcode; 
                       rec.summaryval10 = apprdiscount; 
                       rec.summaryval11 = item.subcatid;

                        //Sum new things 
                        rec.summaryval12 = appritemamt;
                        rec.summaryval13 = appritemtax;
                        rec.summaryval14 = apprtaxableamt;

                       console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|' + plucode);
                       arrreturn.push(rec);
                }

            }else{
                
                
                var rec = <Summaryrecord>{};
                rec.id = order.id;
                rec.summaryval1 = posid; 
                rec.summaryval2 = bsndate; 
                rec.summaryval3 = order.receiptno; 
                rec.summaryval4 = item.pcode; 
                rec.summaryval5 = item.productname; 
                rec.summaryval6 = item.quantity; 
                rec.summaryval7 = item.grossamt; 
                rec.summaryval8 = item.occode; 
                rec.summaryval9 = revcode; 
                rec.summaryval10 = item.discount; 
                rec.summaryval11 = item.subcatid;

                 //Sum new things 
                 rec.summaryval12 = item.amount;
                 rec.summaryval13 = item.itemtax;
                 rec.summaryval14 = item.taxableamt;

                console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|' + item.pcode);
                arrreturn.push(rec);
                
            }


            
        
        
      }
  
      return arrreturn;
  
     
    }



    public static getMonthJson(){
        var arrmonths = [
            {monthname: 'Oct 2021', startdate: 20211001,enddate: 20211031},
            {monthname: 'Nov 2021', startdate: 20211101,enddate: 20211130},
            {monthname: 'Dec 2021', startdate: 20211201,enddate: 20211231},
            {monthname: 'Jan 2022', startdate: 20220101,enddate: 20220131},
            {monthname: 'Feb 2022', startdate: 20220201,enddate: 20220228},
            {monthname: 'Mar 2022', startdate: 20220301,enddate: 20220331},
            {monthname: 'Apr 2022', startdate: 20220401,enddate: 20220430},
            {monthname: 'May 2022', startdate: 20220501,enddate: 20220531},
            {monthname: 'Jun 2022', startdate: 20220601,enddate: 20220630},
            {monthname: 'Jul 2022', startdate: 20220701,enddate: 20220731},
            {monthname: 'Aug 2022', startdate: 20220801,enddate: 20220831},
            {monthname: 'Sep 2022', startdate: 20220901,enddate: 20220930},
            {monthname: 'Oct 2022', startdate: 20221001,enddate: 20221031},
            {monthname: 'Nov 2022', startdate: 20221101,enddate: 20221130},
            {monthname: 'Dec 2022', startdate: 20221201,enddate: 20221231},

        ];

        return arrmonths;
    } 
 
    public static getMonthName(monthno){
        
        switch(monthno){

            case 1: return 'Jan';
            case 2: return 'Feb';
            case 3: return 'Mar';
            case 4: return 'Apr';
            case 5: return 'May';
            case 6: return 'Jun';
            case 7: return 'Jul';
            case 8: return 'Aug';
            case 9: return 'Sep';
            case 10: return 'Oct';
            case 11: return 'Nov';
            case 12: return 'Dec';
        }
        return "";
    }

    public static getMonthYearAsString(bsnmonth){
        var strmonth= "" + bsnmonth;
        if(bsnmonth){
            return Utils.getMonthName(parseInt(strmonth.substring(4,6))) + " " + strmonth.substring(2,4);
        }
        return "";
    }

    
    public static getExistingRecord(currecords : Summaryrecord[], newrec : Summaryrecord){
        for(var rec of currecords){
            if(rec.id == newrec.id) return rec; 
        }
        return null;
    }

    public static getNewRecord(existingrec : Summaryrecord){
        var newrec = <Summaryrecord>{};
        newrec.id = existingrec.id; 
        newrec.description = existingrec.description;
        newrec.summaryval1 = existingrec.summaryval1;
        newrec.summaryval2 = existingrec.summaryval2;
        newrec.summaryval3 = existingrec.summaryval3;
        newrec.summaryval4 = existingrec.summaryval4;
        newrec.summaryval5 = existingrec.summaryval5;
        newrec.summaryval11 = existingrec.summaryval11;
        newrec.summaryval12 = existingrec.summaryval12;
        
        return newrec;
    }

    public static addToExistingRecord(currec : Summaryrecord, newrec : Summaryrecord){
        currec.summaryval1= currec.summaryval1 + newrec.summaryval1;
        currec.summaryval2= currec.summaryval2 + newrec.summaryval2;
        currec.summaryval3= currec.summaryval3 + newrec.summaryval3;
        currec.summaryval4= currec.summaryval4 + newrec.summaryval4;
        currec.summaryval5= currec.summaryval5 + newrec.summaryval5;

        currec.summaryval11= currec.summaryval11 + newrec.summaryval11;
        currec.summaryval12= currec.summaryval12 + newrec.summaryval12;
        

    }
    
    public static getPrevMonth(bsndate){
        var strdate = "" + bsndate;
        var curmonth  = parseInt(strdate.substring(4,6));
        var curyear  = parseInt(strdate.substring(0,4));
        
        var lastmonth = curmonth - 1; 
        var lastyear = curyear; 
        if(lastmonth == 0){
            lastyear = curyear -1;
            lastmonth = 12; 
        }

        var strlastmonth = '' + lastmonth;
        if(lastmonth < 10) strlastmonth = '0' + lastmonth;
        return parseInt( "" + lastyear + strlastmonth);

          
    }


    public static getStartMonthForCharts(bsndate){
        var strdate = "" + bsndate;
        var curmonth  = parseInt(strdate.substring(4,6));
        var curyear  = parseInt(strdate.substring(0,4));
        var startmonth = curmonth; 
        var startyear = curyear; 
        
        if(curmonth == 12){
            startmonth = 1;
        }

        return parseInt( "" + startyear + startmonth);
          
    }

    public static getMonthlyValue(bsnmonth,summarydata : Monthendsummary[], valuetype, posid){
        var retval = 0;
            
        for(var rec of summarydata){
            if(rec.bsnmonth == bsnmonth && (posid == '0' || rec.posid == posid) ){
                switch(valuetype){
                    case Constants.ORDER_COUNT: retval = retval + rec.totalbills; break;
                    case Constants.GUEST_COUNT: retval = retval + rec.totalpax; break;
                    case Constants.ORDER_VALUE: retval = retval + rec.totaltaxablesales; break;
                    case Constants.DINEIN_ORDER_COUNT: retval = retval + Utils.getCounterTypeSale(rec,Constants.NORMAL,Constants.ORDER_COUNT) + Utils.getCounterTypeSale(rec,Constants.AC_SPECIAL,Constants.ORDER_COUNT); break;
                    case Constants.DINEIN_ORDER_VALUE: retval = retval + Utils.getCounterTypeSale(rec,Constants.NORMAL,Constants.ORDER_VALUE) + Utils.getCounterTypeSale(rec,Constants.AC_SPECIAL,Constants.ORDER_VALUE); break;
                    case Constants.TA_ORDER_COUNT: retval = retval + Utils.getCounterTypeSale(rec,Constants.TAKEAWAY,Constants.ORDER_COUNT); break;
                    case Constants.TA_ORDER_VALUE: retval = retval + Utils.getCounterTypeSale(rec,Constants.TAKEAWAY,Constants.ORDER_VALUE); break;
                    case Constants.DE_ORDER_COUNT: retval = retval + Utils.getCounterTypeSale(rec,Constants.PARCEL,Constants.ORDER_COUNT); break;
                    case Constants.DE_ORDER_VALUE: retval = retval + Utils.getCounterTypeSale(rec,Constants.PARCEL,Constants.ORDER_VALUE); break;
                    case Constants.ONLINE_ORDER_COUNT: retval = retval + Utils.getCounterTypeSale(rec,Constants.ONLINE,Constants.ORDER_COUNT); break;
                    case Constants.ONLINE_ORDER_VALUE: retval = retval + Utils.getCounterTypeSale(rec,Constants.ONLINE,Constants.ORDER_VALUE); break;
                    case Constants.MATAM_ORDER_COUNT: retval = retval + Utils.getCounterTypeSale(rec,Constants.SELFAPP,Constants.ORDER_COUNT) ; break;
                    case Constants.MATAM_ORDER_VALUE: retval = retval + Utils.getCounterTypeSale(rec,Constants.SELFAPP,Constants.ORDER_VALUE); break;
                    case Constants.VOID_COUNT: retval = retval + rec.cancelledordercount; break;
                    case Constants.VOID_VALUE: retval = retval + rec.cancelledorderamount; break;
                    
                }
            }
        }

        return retval;
    }

    public static getCounterTypeSale(rec : Monthendsummary, countertypeid, valuetype){
        for(var ctr of rec.countertypewisesales){
            if(ctr.id == countertypeid ){
                if(valuetype == Constants.ORDER_COUNT) return ctr.summaryval11;
                else return  ctr.summaryval2;
            }
        }
        return 0;
    }

    public static getSummarizedData(frommonth, summarydata : Monthendsummary[],viewtype, posid) {
        var retsummary  = [];

        for(var rec of summarydata){
            if(rec.bsnmonth >= frommonth && (posid == '0' || rec.posid == posid) ){
                var sumvalues  = <Summaryrecord[]>[];
                switch(viewtype){
                    case Constants.VW_CATEGORY: sumvalues = rec.categorywisesales; break;
                    case Constants.VW_SUBCATEGORY: sumvalues = rec.subcategorywisesales; break;
                    case Constants.VW_PRODUCT: sumvalues = rec.productwisesales;break;
                    case Constants.VW_AGGREGATOR: sumvalues = rec.aggregatorwisesales;break;
                    case Constants.VW_COUNTERTYPE: sumvalues = rec.countertypewisesales;break;
                    case Constants.VW_SERVED_BY: sumvalues = rec.servedbywisesales;break;
                    case Constants.VW_GUEST_TYPE_WISE: sumvalues = rec.guesttypewisesales;break;
                    
                    
                }

                for(var ctr of sumvalues){
                    if(ctr.id  != null && ctr.id != undefined){
                        var currec = Utils.getExistingRecord(retsummary,ctr);
                        if(currec){
                            Utils.addToExistingRecord(currec,ctr);
                        }else{
                            var newrec= Utils.getNewRecord(ctr);
                            retsummary.push(newrec);
                        }
                    }
                }

            }
        }

        return retsummary;

        
    }

    /****************
     * new function for menu analysis
     */

     public static getProductNameFromPLUCode(plujson,plucode){
        if(plujson && plujson.products && plujson.products.length > 0){
            var arrprods = plujson.products;
            for(var prod of arrprods){
                if(prod.code == plucode) return prod.name; 
            }
        }

        return "";
    }



     public static getForMenuanalysisReport(order : Order, posid,plucodejson, dataServie  : Appdataservice) 
     {
       var arrreturn = <Summaryrecord[]>[];
   
       var bsndate = Utils.getBusinessDateForSQL('' + order.businessdate);
       var revcode = 'D';
       if(order.countertype == Constants.PARCEL) revcode = 'H';
          
         
       var i =0;
 
         for(var item of order.cart.lineitems){
 
             var doIParse= false;
             var custinv = item.custinv;
             var idx = 0;
             
             var doILog = false; 

             if(item.pcode == "8600464"){   
                console.log('|FCS|LINE1|***ORDER ID ' + order.id  + item.productname + '|QTY|' + item.quantity + '|Customization-->' + item.customization +'$$' + item.custinv + '##' + idx + '#PCode-->' + item.pcode  + '#CustRate-->' + item.customizationrate);
                doILog = true;
             }
             
             
 
             if(item.custinv && item.custinv.length > 2){
                item.custinv = item.custinv.trim();
                var lastchar= item.custinv.charAt(item.custinv.length-1);
                if(doILog) console.log('|FCS|LINE1.5|***ORDER ID ' + order.id + ' LAST CHAR ' + lastchar);
                
                if(lastchar ==','){
                     doIParse = true;
                     custinv =custinv.substring(0,custinv.length -1);
                }
 
             }

             if(item.pcode == "8600464" && !doIParse){   
                console.log('|FCS|LINEMISSING|***ORDER ID ' + order.id  + item.productname + '|QTY|' + item.quantity + '|Customization-->' + item.customization +'$$inveNTORTY-->' + item.custinv + '<--##' + idx + '#PCode-->' + item.pcode  + '#CustRate-->' + item.customizationrate);
                doILog = true;
             }
             

             
 
             if(doIParse){

                 //if(doILog) console.log('|FCS|LINE1.5|***ORDER ID ' + order.id);
                 var totalrate = item.rate + item.customizationrate;
                 var amountadded = 0;
                 var rateadded = 0;
                 var ogtotalrate = 0;
                 if(item.schcode && item.schcode.length > 1 ){
                     ogtotalrate = item.ograte + item.ogcustrate;
                 }
 
                 var arrinv = custinv.split(",");
 
                 for (var custitem of arrinv){
                        // if(doILog) console.log('|FCS|LINE1.6|***ORDER ID ' + order.id);
             
                        if(custitem && custitem.indexOf("|") > 0)
                        { 

                             var arrcustitem = custitem.split("|");
                             var plucode = arrcustitem[0];
                             if(plucode){
                                 plucode = plucode.trim();
                             }
 
                             var custrate = parseFloat(arrcustitem[1]);
                             var curproduct = dataServie.getProductForCode(plucode);
                             var isaddon = false;
                             var subcatid = item.subcatid;
                             
                             var addbaserate = false; 
                             if(curproduct ){
                                isaddon = true; 
                                subcatid = curproduct.subcategoryid;
                                if(custrate < (0.5 * curproduct.sellingprice)) addbaserate = true; 
                             } 

                             if((!isaddon || addbaserate) && (rateadded + custrate + item.rate) <= totalrate){
                                 //Add the base rate if item has varient
                                 custrate = custrate + item.rate;
                             }else{
                                 plucode = plucode ;
                             }
                             
                             rateadded  = rateadded + custrate; 

                             if(plucode && plucode.length > 0 && custrate != null && !isNaN(custrate)){
                                
                                var ratio = Utils.round(custrate/totalrate,3);
                                 
                                 if(item.pcode == "8600464" ){   
                                    console.log('|FCS|RATIO|***ORDER ID ' + order.id  + item.productname + '|QTY|' + item.quantity + '|Customization-->' + item.customization +'$$inveNTORTY-->' + item.custinv + '<--##' + idx + '#PCode-->' + item.pcode  + '#CustRate-->' + item.customizationrate + ' Ratio ' + ratio);
                                    doILog = true;
                                 }
             
                                 
                                 //If scheme code is there
                                 if(ogtotalrate > 0)
                                     ratio = Utils.round(custrate/ogtotalrate,3);
 
                                 var appramt = Utils.round(item.grossamt * ratio,3);
                                 var apprdiscount = Utils.round(item.discount * ratio,3);
                           
                                 var appritemamt = Utils.round(item.amount * ratio,3);
                                 var appritemtax = Utils.round(item.itemtax * ratio,3);
                                 var apprtaxableamt = Utils.round(item.taxableamt * ratio,3);
                                 
 
                                 var rec = <Summaryrecord>{};
                                 rec.id = order.id;
                                 rec.summaryval1 = posid; 
                                 rec.summaryval2 = bsndate; 
                                 rec.summaryval3 = order.receiptno; 
                                 rec.summaryval4 = plucode; 
                                 if(isaddon) rec.summaryval4 = rec.summaryval4 +  ' (A)';
                                var pluname = Utils.getProductNameFromPLUCode(plucodejson, plucode);
                                  
                                if(pluname && pluname.length > 0)
                                    rec.summaryval5 = pluname;
                                else 
                                    rec.summaryval5 = item.productname + ' (' + item.customization + ')'; 
                                
                                if(isaddon) rec.summaryval5 = 'Add on: ' +  rec.summaryval5;
                                
                                rec.summaryval6 = item.quantity; 
                                 
                                 if(doILog) console.log('|FCS|LINE2|'+ order.id +'|PLU|'   + plucode  +'|SETTING QUATNTY|' +  item.quantity);
                                  
                                 amountadded = amountadded + appramt;
                                 rec.summaryval7 = appramt; 
                                 rec.summaryval8 = item.occode; 
                                 rec.summaryval9 = revcode; 
                                 rec.summaryval10 = apprdiscount;
                                 rec.summaryval11 = subcatid; 
                                 
                                 console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|' + plucode);
                                 //Sum new things 
                                 rec.summaryval12 = appritemamt;
                                 rec.summaryval13 = appritemtax;
                                 rec.summaryval14 = apprtaxableamt;
 
                                 arrreturn.push(rec);
                             }
                        }
 
                 }
 
                 //If it does not have a varient, base product also will get added 
                 if(item.rate > 0 && (!item.hasvarient || Math.round(amountadded) < Math.round(item.grossamt)) ){
                        var plucode = item.pcode;
                        var ratio = Utils.round(item.rate/totalrate,3);
                        var appramt = Utils.round(item.grossamt * ratio,3);
                        var apprdiscount = Utils.round(item.discount * ratio,3);
 
                        var appritemamt = Utils.round(item.amount * ratio,3);
                        var appritemtax = Utils.round(item.itemtax * ratio,3);
                        var apprtaxableamt = Utils.round(item.taxableamt * ratio,3);
                        
                   
                        var rec = <Summaryrecord>{};
                        rec.id = order.id;
                        rec.summaryval1 = posid; 
                        rec.summaryval2 = bsndate; 
                        rec.summaryval3 = order.receiptno; 
                        rec.summaryval4 = plucode; 
                        //rec.summaryval5 = item.productname; 
                        
                        console.log('|CUSTFIND|' +  plucode + '|' + pluname +'|BASE');
                                  

                        var pluname = Utils.getProductNameFromPLUCode(plucodejson, plucode);
                        if(pluname && pluname.length > 0)
                            rec.summaryval5 = pluname;
                        else
                            rec.summaryval5 = item.productname;
                        
                        rec.summaryval6 = item.quantity; 
                        rec.summaryval7 = item.grossamt - amountadded; 
                        rec.summaryval8 = item.occode; 
                        rec.summaryval9 = revcode; 
                        rec.summaryval10 = apprdiscount; 
                        rec.summaryval11 = item.subcatid;
 
                         //Sum new things 
                         rec.summaryval12 = appritemamt;
                         rec.summaryval13 = appritemtax;
                         rec.summaryval14 = apprtaxableamt;
 
                        console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|' + plucode);
                        arrreturn.push(rec);
                 }else{
                     if(amountadded < item.grossamt){
                        //console.log('|FCS|LESSAMTADDED|***ORDER ID ' + order.id  + item.productname + '|QTY|' + item.quantity + '|Customization-->' + item.customization +'$$inveNTORTY-->' + item.custinv + '<--###Amount added-->' + amountadded  + '#GROSS -->' + item.grossamt);
                     }
                 }

                 if(Math.round(amountadded) !=  Math.round(item.grossamt)){
                    console.log('|FCS|AMOUNTMISMATCH|***ORDER ID ' + order.id  + item.productname + '|QTY|' + item.quantity + '|Customization-->' + item.customization +'$$inveNTORTY-->' + item.custinv + '<--###Amount added-->' + amountadded  + '#GROSS -->' + item.grossamt +' ## ogtotalrate' + ogtotalrate + ' ## bSE RATE ' + item.rate);
                 }

 
             }else{
                 
                 
                 var rec = <Summaryrecord>{};
                 rec.id = order.id;
                 rec.summaryval1 = posid; 
                 rec.summaryval2 = bsndate; 
                 rec.summaryval3 = order.receiptno; 
                 rec.summaryval4 = item.pcode; 
                 rec.summaryval5 = item.productname; 
                 rec.summaryval6 = item.quantity; 
                 rec.summaryval7 = item.grossamt; 
                 rec.summaryval8 = item.occode; 
                 rec.summaryval9 = revcode; 
                 rec.summaryval10 = item.discount; 
                 rec.summaryval11 = item.subcatid;
 
                  //Sum new things 
                  rec.summaryval12 = item.amount;
                  rec.summaryval13 = item.itemtax;
                  rec.summaryval14 = item.taxableamt;
 
                 console.log('|CUSTINV|CUSTITEM|FOCUSINSERT|' + item.pcode);
                 arrreturn.push(rec);
                 
             }
 
 
             
         
         
       }
   
       return arrreturn;
   
      
     }


}