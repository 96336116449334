import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { Kot } from 'src/app/domain/kot';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/service/utils';
import { Constants } from 'src/app/domain/constants';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Reprintlog } from 'src/app/domain/reprintlog';
import { Fb } from 'src/app/domain/fb';
import { Order } from 'src/app/domain/order';
import { Summaryrecord } from 'src/app/domain/summaryrecord';
import { SwitchView } from '@angular/common/src/directives/ng_switch';
import { User } from 'src/app/domain/user';
import { Orderservice } from 'src/app/service/orderservice';
import { Usergroupservice } from 'src/app/service/usergroupservice';
import { Userservice } from 'src/app/service/userservice';


@Component({
    templateUrl: 'serverperformance.html',
    providers: [ConfirmationService]
})
export class Serverperformance implements OnInit 
{
    fromdate      : Date;
    todate        : Date;

    summarylist       : Summaryrecord[];
    orderlist     : Order[];
    userlist    : User[];

    isRunning = false; 
    statuses : SelectItem[];
    avgrating;
    avgscore;
                                

    totalamount = 0;
    totalcount = 0;
    totalguestcount = 0;
    selectedPayment;
    cols : any[];
    selectedposid; 

    arrselectedposids : SelectItem[];
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private datePipe : DatePipe,
        private orderService : Orderservice,
        private userService : Userservice,
        private userGroupService : Usergroupservice,
        private dynamodbService : Dynamodbservice,
        public messageService : MessageService

        ) {
        
    }
   
        
    ngOnInit(){
      
        this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getPreviousBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getPreviousBusinessDate());

        
        this.cols = [
            { field: 'description', header: 'Server name'},
            { field: 'summaryval1', header: 'Guest Count'},
            { field: 'summaryval2', header: 'Order Count'},
            { field: 'summaryval3', header: 'Order Value'},
            { field: 'summaryval4', header: 'Starters %'},
            { field: 'summaryval5', header: 'Soups %'},
            { field: 'summaryval6', header: 'Salad %'},
            { field: 'summaryval7', header: 'Beverage %'},
            { field: 'summaryval8', header: 'Desserts %'},
            { field: 'summaryval11', header: 'APC'},
            { field: 'summaryval9', header: 'MTD'}
            
            
        ];

        
        //this.selectedposid = this.dataService.outletlist[0].value;
        this.arrselectedposids = [];

    }

    isPosSelectd(posid){
        for(var pos of this.arrselectedposids){
            if(pos.value == posid)
                return true;
        }
    }
    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    selectedKOT; 

    
    

    doesItHaveStarters(ord){
        var sumquantity = 0;
        for(var item of ord.cart.lineitems){
            switch(this.dataService.retailer.id){
                case 1338:
                    if(item.subcatid == 26009){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
                case 1329:
                    if(item.subcatid == 25802 || item.subcatid == 25803 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;

                case 1350:
                    if(item.subcatid == 26254 || item.subcatid == 28617 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
            }
             
        }
        
        return sumquantity; 
        
    }
    
    doesItHaveSoups(ord){
        
        var sumquantity = 0;
        for(var item of ord.cart.lineitems){

            switch(this.dataService.retailer.id){
                case 1338:
                    if(item.subcatid == 26010){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
                case 1329:
                    if(item.subcatid == 25800 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;

                case 1350:
                    if(item.subcatid == 28868 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
            }
           
        }
        
        return sumquantity; 

    }
    
    doesItHaveDesserts(ord){
        
        var sumquantity = 0;
        for(var item of ord.cart.lineitems){

            
            switch(this.dataService.retailer.id){
                case 1338:
                    if(item.subcatid == 26066){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
                case 1329:
                    if(item.subcatid == 25810 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;

                case 1350:
                    if(item.subcatid == 26257 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
            }
           
        }
        
        return sumquantity; 

    }
    
    
    doesItHaveBeverages(ord){
        var sumquantity = 0;
        for(var item of ord.cart.lineitems){
            switch(this.dataService.retailer.id){
                case 1338:
                    if(item.catid == 3405){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
                case 1329:
                    if(item.catid == 3373 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;

                case 1350:
                    if(item.catid == 3441 ){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
            }
            
            
            
        }
        
        return sumquantity;
        
    }
    

    
    
    doesItHaveSalads(ord){
        var sumquantity = 0;
        for(var item of ord.cart.lineitems){

            switch(this.dataService.retailer.id){
                case 1338:
                    if(item.subcatid == 26025){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
                case 1329:
                    if(item.subcatid == 25801){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;

                case 1350:
                    if(item.subcatid == 26255){
                        sumquantity = sumquantity + item.quantity; 
                    }
                    break;
            }
             
        }
        
        return sumquantity;
        
        
    }

    addOrderToRecord(rec : Summaryrecord,ord){
        if(ord.orderstatus == 0 && (ord.countertype <=  2)){
            //If cur month order
            if(ord.businessdate >= this.bsnfromdate && ord.businessdate <= this.bsntodate){
                if(ord.numguests) rec.summaryval1 = rec.summaryval1 + ord.numguests; // No of guests
                rec.summaryval2= rec.summaryval2 + 1; //Order count
                rec.summaryval3 = Utils.round(rec.summaryval3 + ord.cart.sumitemtaxable,3); //Order Value 
                rec.summaryval4 = rec.summaryval4 + this.doesItHaveStarters(ord); // Starters 
                rec.summaryval5 =  rec.summaryval5 + this.doesItHaveSoups(ord);
                rec.summaryval6 = rec.summaryval6 + this.doesItHaveSalads(ord);
                rec.summaryval7 = rec.summaryval7 + this.doesItHaveBeverages(ord);
                rec.summaryval8 = rec.summaryval8 + this.doesItHaveDesserts(ord);

                this.totalamount= Utils.round(this.totalamount + ord.cart.sumitemtaxable,3); //Order Value 
                this.totalcount = this.totalcount + 1; 
                if(ord.numguests) this.totalguestcount = this.totalguestcount + ord.numguests;
                
            }
            if(ord.businessdate >= this.curmonthstart){
               rec.summaryval9 = Utils.round(rec.summaryval9 + ord.cart.sumitemtaxable,3); //MTD
               this.totalmtd = Utils.round(this.totalmtd + ord.cart.sumitemtaxable,3); //Order Value 
               
            }

            rec.summaryval10 = Utils.round(rec.summaryval10 + ord.cart.sumitemtaxable,3); //YTD
            
        }


    }


    calculatePercentages(){
        for(var rec of this.summarylist){
        
            if(rec.summaryval1 && rec.summaryval1 > 0){
                rec.summaryval4 = Math.round(rec.summaryval4/rec.summaryval1*100);
                rec.summaryval5 = Math.round(rec.summaryval5/rec.summaryval1*100);
                rec.summaryval6 = Math.round(rec.summaryval6/rec.summaryval1*100);
                rec.summaryval7 = Math.round(rec.summaryval7/rec.summaryval1*100);
                rec.summaryval8 = Math.round(rec.summaryval8/rec.summaryval1*100);
            }else{
                rec.summaryval4 = 0;
                rec.summaryval5 = 0;
                rec.summaryval6 = 0;
                rec.summaryval7= 0;
                rec.summaryval8 = 0;
                
            }
            //if(!rec.empcode) rec.empcode = '';
            if(rec.summaryval2 > 0)
                rec.summaryval11  =  Math.round(rec.summaryval3/rec.summaryval1);
            else
                rec.summaryval11 = 0;
                
        }
    }

    createNewRecord(ord : Order){
        var rec = <Summaryrecord>{};
        rec.id = ord.servedby;
        rec.description = ord.servedbystr;
        
        rec.summaryval1 = 0;
        rec.summaryval2 = 0;
        rec.summaryval3 = 0;
        rec.summaryval4 = 0;
        rec.summaryval5 = 0;
        rec.summaryval6 = 0;
        rec.summaryval7 = 0;
        rec.summaryval8 = 0;
        rec.summaryval9 = 0;
        rec.summaryval10 = 0;
        rec.summaryval11 = 0;
        
        return rec;
    }
    

    curmonthstart; 
    bsnfromdate; 
    bsntodate; 
    totalmtd = 0;
    async setData(){

        this.totalamount =0;
        this.totalcount  = 0;
        this.totalmtd = 0;
        
        this.avgrating = 0;
        this.avgscore = 0;
        var totalrating = 0;
        var totalscore = 0;
        var fbcount = 0;


        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));
        this.bsnfromdate = fromdate;
        this.bsntodate = todate; 


        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 

        var strtodate = "" + todate; 
        var strstartofmonth = strtodate.substring(0,6) + "01";
        this.curmonthstart = parseInt(strstartofmonth);

         var startdate = this.curmonthstart;

         if(fromdate < this.curmonthstart) startdate = fromdate
         
        
        this.orderlist = await this.orderService.getOrderlist(startdate,todate);

        

        if(Constants.DEBUG_MODE) console.log ('Date From ' + new Date(fromdate) +  ' Date to  ' + new Date(todate));
        this.summarylist = [];

        for(var order of this.orderlist){

            if(order.countertype <= 2 && order.orderstatus == Constants.POS_ORDER_PROCESSED){
                    var user  = this.userService.getUserForId(order.servedby);
                    if(user){
                        order.servedbystr = user.name;
                    }
                
                    var currec = Utils.findInSummaryList(this.summarylist,order.servedby);
                
                    console.log('REP|SERVEDBY ' + order.servedby);
                    if(!currec) {
                        console.log('REP|SERVEDBY NOTFOUND');
                        currec = this.createNewRecord(order);
                        this.summarylist.push(currec);
                    }
                    this.addOrderToRecord(currec, order);
                    
            }
        }

        this.calculatePercentages();
        this.summarylist.sort((a:Summaryrecord, b: Summaryrecord)=> (a.summaryval9)  > (b.summaryval9)  ? -1 : 1 );
        this.summarylist = [... this.summarylist];
    

        this.isRunning = false;

    }


    


}


