import {Deliveryboy} from '../domain/deliveryboy'
import {Injectable} from '@angular/core';

import * as AWS from 'aws-sdk';
import { Constants } from '../domain/constants';
import { DataService } from '../service/dataservice';
import { Appdataservice } from './appdataservice';
import { Dynamodbservice } from './dynamodbservice';
import { Couchdbservice } from './couchdbservice';

@Injectable()
export class Deliveryboyservice  {
     deliveryboylist : Deliveryboy[];
     
     constructor(
         private couchdbService : Couchdbservice,
         private dataService : Appdataservice,
         private dynamoDBService : Dynamodbservice,
     ){}

     public getNewId(){
         var maxid = 0;
         for(var ctr  of this.deliveryboylist){
             if(ctr.id > maxid)
                maxid = ctr.id;
         }

         return maxid + 1; 
     }

     
     addEditInList(boy : Deliveryboy){
       var found = false; 
       for(var i=0;i<this.deliveryboylist.length; i++){
           if(this.deliveryboylist[i].id === boy.id){
               this.deliveryboylist[i] = boy;
               found = true; 
               break;
           }
       }

       if(!found){
           this.deliveryboylist.push(boy);
       }

    }


     public checkDuplicate(dlvryboy : Deliveryboy){
        for(var ctr  of this.deliveryboylist){
            if(ctr.id != dlvryboy.id && ctr.mobilenumber == dlvryboy.mobilenumber)
               return true;
        }

        return false; 
    }


    getDeliveryboyNameForId(id){
        for(var boy of this.deliveryboylist){
            if(boy.id === id) return boy.name ; 
        }
        return "";
    }

    getDeliveryboyForId(id){
        for(var boy of this.deliveryboylist){
            if(boy.id === id) return boy; 
        }
        return null;
    }
    
    getDeliveryboyStringForId(id){
        for(var boy of this.deliveryboylist){
            if(boy.id === id) return boy.name + " [" + boy.mobilenumber + "]";; 
        }
        return "";
    }
    
    getDeliveryboyIdForString(name){
        for(var boy of this.deliveryboylist){
            if(this.getAsString(boy) === name) return boy.id; 
        }
        return 0;
    }
    getDeliveryboyIdForName(name){
        for(var boy of this.deliveryboylist){
            if(boy.name === name) return boy.id; 
        }
        return 0;
    }
    
    getAsString(dlvryboy : Deliveryboy){
        let name = dlvryboy.name;
        let mobilenum = "" +  dlvryboy.mobilenumber;
        return name  + " [" + mobilenum + "]";
    }


    getDriverEmpDetailsForId(id){
        for(var boy of this.deliveryboylist){
            if(boy.id === id) return  boy.name + " (" + boy.empcode + ")";; 
        }
        return "";
    }

    getDriverEmpDetailsForNameNumber(strdetails : string){
        if(strdetails && strdetails.length > 0){
            var mobno = strdetails.substring(strdetails.indexOf("(") +1 , strdetails.indexOf(")"));

            for(var boy of this.deliveryboylist){
                if(boy.mobilenumber == parseInt(mobno)) return  boy.name + " (" + boy.empcode + ")";; 
            }
        }
        return "";
    }

     
     lastupdatestamp = 0;

     async initDeliveryBoys(){
        this.deliveryboylist = [];
        //if(Constants.DEBUG_MODE) console.log('|DBOYSEVICE|Fetching from couch');
        //var retlist = await this.couchdbService.getAllItems('deliveryboys');

        
        
        var retlist;
        if(this.dataService.usedynamo){
            retlist = await this.dynamoDBService.getAllItemsForPosId('deliveryboys');
            if(retlist) this.deliveryboylist = <Deliveryboy[]> retlist;
        }else{
            retlist = await this.couchdbService.getAllItems('deliveryboys');
            if(retlist) this.deliveryboylist = <Deliveryboy[]> retlist.docs;
        }



        if(retlist){
            //if(Constants.DEBUG_MODE) console.log('|COUCH|Num boys-->' + JSON.stringify(retlist));
            //this.deliveryboylist = <Deliveryboy[]> retlist.docs;
            for(var dboy of this.deliveryboylist){
                console.log('Dboy found ' + dboy.mobilenumber );
            }

            return true;
        }else{
            return false;
        }
        
     }
     
     async save(dboy){
        if(Constants.DEBUG_MODE) console.log('|COUCH|DBOYSEVICE|Saving DBOY ' + JSON.stringify(dboy));
        //var status= await this.couchdbService.putItem('deliveryboys',dboy);
        
        var status;
        if(this.dataService.usedynamo){
            status = await this.dynamoDBService.putItem('deliveryboys',dboy);    
        }else{
            status = await this.couchdbService.putItem('deliveryboys',dboy);
        }


        if(status != "ERROR" && status != "CONFLICT"){
            this.addEditInList(dboy);
            this.deliveryboylist = [... this.deliveryboylist];
            return status;
        }else{
            return status;
        }
        
     }
   



    

}