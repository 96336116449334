import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { Couchdbservice } from '../service/couchdbservice';
import { Dynamodbservice } from '../service/dynamodbservice';
import { Appdataservice } from '../service/appdataservice';

@Component({
    templateUrl: 'migratetocouch.html',
    providers: [ConfirmationService]
})
export class Migratetocouch implements OnInit 
{
    isRunning = false; 


    
    constructor(
        public dataService: Appdataservice,
        private _router : Router,
        private dynamodbService : Dynamodbservice,
        private couchDBService : Couchdbservice,
        private messageService : MessageService
        ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];

   
    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        
    }


    copystatus;
    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }


    
    async migrateByTableScan(tablename,posid){

        var retlist  = await this.dynamodbService.getAllItemsForPosId(tablename);


        var i =0;
        for(var item of retlist){
            
            var couchitem = await this.couchDBService.getItem(tablename, item.id);
            if(couchitem){
                item._id = couchitem._id; 
                item._rev = couchitem._rev;
                console.log('|MIGRATION|Item found in table: ' + tablename  + ' Item ID ' + item.id );
            }else{
                delete item._id;
                delete item._rev;
                console.log('|MIGRATION|Item NOT found in table: ' + tablename  + ' Item ID ' + item.id );
            }

            
            var ret  = await this.couchDBService.putItem(tablename, item);
            if(ret) i++;
            else return false; 

            this.copystatus = 'Migrated ' + i + ' Records of table: ' + tablename;
        }

        console.log('|MIGRATION|Num records migrated ' + i  );
        return true;


    }

    async migerateFromGlobal(tablename){
        var retlist = await this.dynamodbService.scanTable(tablename);
        //var retlist  = await this.couchDBService.readGlobalStore(tablename);
        

        var i =0;
        for(var item of retlist){
            var couchitem = await this.couchDBService.getItem(tablename, item.id);
            if(couchitem){
                item._id = couchitem._id; 
                item._rev = couchitem._rev;
            }
            
            var ret  = await this.couchDBService.putItem(tablename, item);
            if(ret) i++;
            else return false; 
            this.copystatus = 'Migrated ' + i + ' Records of table: ' + tablename;
        }
        console.log('|MIGRATION|Num records migrated ' + i  );
        //this.copystatus = 'Migrated ' + i + ' Records of table: ' + tablename;
        return true;


    }

    
    async migrateData(){
        this.isRunning = true; 
    
        var ret = await this.migerateFromGlobal('aggregators');
        var ret = await this.migerateFromGlobal('discountpartners');
        var posid = this.dataService.getPosId();

        console.log('|MIGRATION|**** MIGRATION STARTED FOR ' + posid);
        ret = await this.migrateByTableScan('printers',posid);
        if(ret){console.log('|MIGRATION|printers done');} 

        ret = await this.migrateByTableScan('billingcounters',posid);
        if(ret){console.log('|MIGRATION|billing counters done');} 

        ret = await this.migrateByTableScan('usergroups',posid);
        if(ret){console.log('|MIGRATION|user groups done');} 

        ret = await this.migrateByTableScan('users',posid);
        if(ret){console.log('|MIGRATION|users done');} 

        ret = await this.migrateByTableScan('kitchens',posid);
        if(ret){console.log('|MIGRATION|kitchens done');} 

        ret = await this.migrateByTableScan('deliveryboys',posid);
        if(ret){console.log('|MIGRATION|deliveryboys done');} 

        if(ret){
            this.copystatus = 'All Data Migrated to offline server!';
        }else{
            this.copystatus = 'Error in migration!';
        }
        
        this.isRunning = false;

    }


    

}


