import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl,Column,Message,DataTable,Header,Dialog,InputText,Button, MessageService, SelectItem} from 'primeng/primeng';
import {TableModule} from 'primeng/table'
import {DataService} from '../service/dataservice';
import {Kitchenservice} from '../service/kitchenservice';

import {Kitchen} from '../domain/kitchen';
import {Router} from '@angular/router';	
import { Appdataservice } from '../service/appdataservice';
import { Printerservice } from '../service/printerservice';


@Component({
    templateUrl: 'kitchens.html'
})
export class Kitchens implements OnInit {
    kitchens : Kitchen[];
    msgs: Message[] = [];
    isRunning : boolean; 
    
    msgssummary : Message[] = [];    

    selectprinters : SelectItem[];
    
    constructor(public dataService : Appdataservice,
        public messageService : MessageService,
        public printerService : Printerservice,
        public kitchenService : Kitchenservice, private _router : Router) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }
    
    ngOnInit() {
        this.kitchens = this.kitchenService.kitchenlist;

        this.selectprinters = [];
        this.selectprinters.push({label:'No Printer', value:0});

        for(var printer of this.printerService.printerlist){
            this.selectprinters.push({label:printer.printername, value:printer.id});
        }

    }
    
    selectedCounter : Kitchen;
    kitchen : Kitchen;
    displayAddEditDialog : boolean = false;
    enableEditButton : boolean = false;
    isForEdit : boolean = true;
    
    
    showAddEditDialog(fIsforEdit : boolean){
        this.msgs = [];
        this.msgssummary = [];
        this.isForEdit = fIsforEdit;
        
        if(fIsforEdit){
            this.kitchen = this.clonekitchen(this.selectedCounter);
        }else
            this.kitchen = <Kitchen>{};
        
        this.displayAddEditDialog = true;
    }
    
    async printTestKOT(){
        var  ret = await this.kitchenService.printTestKOT(this.selectedCounter);
        if(ret){
            this.showSuccessToast('Test KOT sent to printer!');
        }else{
            this.showErrorToast('Error in sending to printer, please check printer and network connectivity!');
        }
    }
    
    clonekitchen(fkitchen: Kitchen): Kitchen
    {
        var kitchen = <Kitchen>{};
        for(let prop in fkitchen) {
            kitchen[prop] = fkitchen[prop];
        }
        return kitchen; 
     }
     
    onRowDoubleClick(event){
        this.showAddEditDialog(true);
    }
    
    onRowSelect(event) {
        this.enableEditButton =true;
    }
    
    onRowUnselect(event) {
        this.enableEditButton = false;
    }
    
    findselectedCounterIndex(): number {
        return this.kitchenService.kitchenlist.indexOf(this.selectedCounter);
    }
    
    getDialogWidth() : number{
       return 400;
    }
    
    getDialogHeight() : number{
        return 250;
    }

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    
    
    async refreshData(){
        this.isRunning = true;
        var ret = this.kitchenService.initKitchens();
        this.isRunning = false;
        if(ret){
            this.showSuccessToast('Data refreshed successfully!')
        }else{
            this.showSuccessToast('Error in refreshing data, please try again.')
        }
    }


    async save(dt : DataTable) 
    {
        this.msgs =[];
        this.isRunning = true;
        
        if(!this.kitchen.kitchenid  || this.kitchen.kitchenid.trim()  == ""){
            this.showErrorToast('Kitchen Id can not be blank.')
            this.isRunning = false;
            return;
        }
        
        if(isNaN(this.kitchen.kitchenid)){
            this.showErrorToast('Kitchen Id must be numeric.')
            this.isRunning = false;
            return;
        }
        
        if(!this.kitchen.name  || this.kitchen.name  == ""){
            this.showErrorToast('Kitchen name can not be blank.')
            this.isRunning = false;
            return;
        }
        
        
        
        if(this.kitchenService.checkDuplicate(this.kitchen)){
            this.showErrorToast('Kitchen Id already exists.')
            this.isRunning = false;
            return;
        }

        this.kitchen.printername = this.printerService.getPrinterNameForId(this.kitchen.printerid);
        
        if(!this.isForEdit) this.kitchen.id = this.kitchenService.getNewId();
        this.kitchen.posid = this.dataService.getPosId();
        if(this.dataService.retailer.multilocation) this.kitchen.posid = this.dataService.getPosWithLocationId();
        
        var retstatus = await this.kitchenService.save(this.kitchen);
        
        //If true --> Coutner Added 
        if(retstatus != "ERROR" && retstatus != "CONFLICT"){
            this.isRunning = false;

            this.displayAddEditDialog = false; 
            this.enableEditButton = false;
            this.isRunning = false;
            this.showSuccessToast('Kitchen saved successfully.'); 
        }else{
            if(retstatus == 'ERROR') this.showErrorToast('Error, please try again.'); 
            else this.showErrorToast('You seem to be having an old copy, please refresh data!'); 
            this.isRunning = false;
        }
    
            
    }
    
}


