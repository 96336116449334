import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { Kot } from 'src/app/domain/kot';
import { Appdataservice } from 'src/app/service/appdataservice';
import { DatePipe } from '@angular/common';
import { Kitchenservice } from 'src/app/service/kitchenservice';
import { Printingservice } from 'src/app/service/printingservice';
import { Billingcounterservice } from 'src/app/service/billingcounterservice';
import { Utils } from 'src/app/service/utils';
import { Constants } from 'src/app/domain/constants';
import { Userservice } from 'src/app/service/userservice';
import { Printerservice } from 'src/app/service/printerservice';
import { Billingservice } from 'src/app/service/billingservice';
import { Order } from 'src/app/domain/order';
import { Orderservice } from 'src/app/service/orderservice';

@Component({
    templateUrl: 'kotdetails.html',
    providers: [ConfirmationService]
})
export class Kotdetails implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    kotlist       : Kot[];
    orderlist : Order[];

    isRunning = false; 
    statuses : SelectItem[];

    totalamount = 0;

    selectedPayment;
    cols : any[];
    
    constructor(public dataService: Appdataservice,private _router : Router,
        private datePipe : DatePipe,
        public messageService : MessageService,
        public kitchenService : Kitchenservice,
        public counterService : Billingcounterservice,
        public orderService : Orderservice,
        public userService : Userservice,
        private billingService : Billingservice,
        public printingService : Printingservice,
        public printerService : Printerservice
        ) {
        
    }

    getOrderWithSessionId(sessionid){
        for(var order of this.orderlist){
            var ordersession = order.counterid + "|" + order.sessionid;
            if(sessionid == ordersession) return order;
        }
        return null;
    }
   
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));

        /*this.fromdate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());
        this.todate = Utils.getDateFromBusinessdate('' + this.dataService.getCurrentBusinessDate());*/


        this.cols = [
            { field: 'kottimestr', header: 'Created on '},
            { field: 'countername', header: 'Counter name' },
            { field: 'kitchenname', header: 'Kitchen name' },
            { field: 'kottoken', header: 'KOT Token' },
            { field: 'itemcount', header: 'Item count' },
            { field: 'optrname', header: 'KOT Punched by' },
            { field: 'lineitemsstr', header: 'Item list' },
            { field: 'cancelstr', header: 'Cancelled Items' },
            { field: 'cancellationreason', header: 'Cancel Reason' },
            { field: 'remarks', header: 'Cancel Remarks' },
            { field: 'iscancelled', header: 'KOT cancelled' },
            { field: 'voidoptrname', header: 'KOT cancelled by' },
            { field: 'receiptno', header: 'Receipt No' },
            { field: 'ordid', header: 'Order Id' },
            { field: 'ordstatus', header: 'Checkout status' },
            { field: 'proformatime', header: 'KOT to Proforma (Mins)' }

            
            
        ];

    }

    
    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    selectedKOT : Kot; 

    
    

    async setData(){

        this.totalamount =0;

        if(Constants.DEBUG_MODE) console.log('set data called');
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        
        }
        
        this.isRunning = true; 
        if(Constants.DEBUG_MODE) console.log ('Date From ' + new Date(fromdate) +  ' Date to  ' + new Date(todate));
        

        this.orderlist = await this.orderService.getOrderlist(fromdate,todate);
        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
            this.isRunning = false;
            return; 
        }

        //Get the KOTs from running counters FIRST 
        var kotlist =  await this.kitchenService.getKotList(fromdate,todate);
        if(kotlist){
           this.kotlist = kotlist;
            this.kotlist.sort((a:Kot,b:Kot)=> a.id < b.id  ? -1 : 1 );

            for(var kot of this.kotlist){
                this.kitchenService.setStringVarsinKot(kot);
                kot.optrname = this.userService.getUserNameForId(kot.optrid);
                kot.voidoptrname = this.userService.getUserNameForId(kot.voidoptrid);
                var order = this.getOrderWithSessionId(kot.sessionid);
                if(order){
                    kot.ordid = order.id; 
                    kot.receiptno = order.receiptno;
                    if(order.orderstatus == Constants.POS_ORDER_PROCESSED) kot.ordstatus = 'Processed' 
                    else kot.ordstatus = 'Void';
                    console.log('KOT ID ' + kot.id + ' ORDER ID ' + order.id);
                    var protime  = Math.round((order.id - kot.id) / (1000 * 60));
                    if(protime < 0 ) protime = 0;
                    kot.proformatime = protime; 

                }
            
            }   


            this.kotlist = [... this.kotlist];
        }else{
            this.kotlist = [];
        }
        this.isRunning = false;

    }


    async reprintKot(){

        this.billingService.reprinttype = 'kot';
        this.billingService.kotForReprint = this.selectedKOT;
        
        if(this.userService.checkBillingPriv(Constants.PRIV_DUPLICATE_KOT)){
            this.billingService.makeReprintDialogVisible = true;
        }else{
            this.billingService.reauthpurpose = Constants.PRIV_DUPLICATE_KOT;
            this.billingService.makeReauthDialogVisible = true;
        }

        /*if(this.selectedKOT.iscancelled){
            var counter = this.counterService.getBillingcounterForId(this.selectedKOT.counterid);
            if(counter)
                await this.kitchenService.printKOTOnPrintter(this.selectedKOT,counter.receipton,true);
        }

        var kitchen = this.kitchenService.getKitchenForId(this.selectedKOT.kitchenid);
        if(kitchen){
            var ret = await this.kitchenService.printKOTOnPrintter(this.selectedKOT, kitchen.printerid, true);
            if(!ret){
                this.showErrorToast('Error sending KOT to kitchen printer, please check the network');
            }
            
        }*/

        

    }



}


