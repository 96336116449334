import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {DataService} from '../service/dataservice';
import {MessageService, ConfirmationService, SelectItem} from 'primeng/api';
import { Billingcounterservice } from '../service/billingcounterservice';
import { Billingservice } from '../service/billingservice';
import { Kitchenservice } from '../service/kitchenservice';
import { Appdataservice } from '../service/appdataservice';
import { Billingcounter } from '../domain/billingcounter';
import { Kot } from '../domain/kot';
import { Constants } from '../domain/constants';
import { Cartlineitem } from '../domain/cartlineitem';
import { IcuType } from '@angular/core/src/render3/interfaces/i18n';

@Component({
    selector: 'transfer-dlg',
    templateUrl: './transferdialog.html',

})
export class Transferdialog 
{

    constructor(
                public counterService : Billingcounterservice,
                public billingService : Billingservice,
                public kitchenService : Kitchenservice,
                private confirmationService : ConfirmationService,
                private messageService: MessageService,
                private dataService : Appdataservice) {}

    
    ctr : Billingcounter;
    transferTo : Billingcounter;
    transfercounterlist : Billingcounter[];

    getName(ctr : Billingcounter){
        if(!ctr.tempshare){
            return ctr.countername + " [" + ctr.hotkey + "] ";
        }else{
            return ctr.countername;
        }
    }

  
    transfertocounters : SelectItem[];
    selectedItems : Cartlineitem[];
    transfertoctr : number;

    onShowDialog(){
        if(Constants.DEBUG_MODE) console.log('SElected counter ' + this.counterService.selectedcounter.countername);
        this.ctr = this.counterService.selectedcounter;
        
        this.transfercounterlist = [];
        this.transfertocounters =[];
        this.selectedItems  = [];
        
        this.transfertoctr =0;
        this.transfertocounters.push({label:'--Select Counter--', value:0});
        
        for(var counter of this.counterService.activelist){
            if(this.ctr.countertype === counter.countertype && counter.id != this.ctr.id){
                this.transfercounterlist.push(counter);
                this.transfertocounters.push({label:counter.countername, value:counter.id});
                
            }
        }

        /*var kots = this.kitchenService.getKOTArrayFromCart(this.ctr.cart);    
        for(var kot of kots) {
            this.kotarray.push(kot);
        }
        this.kotarray = [... this.kotarray];*/


    }

    showConfirmation : boolean = false;
    showspinner : boolean = false; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
        this.showspinner = false;
    }


    confirmTransfer(transferTo) {
    
        this.transferTo = transferTo;
        this.showConfirmation = true;
    }


    async processTransfer(){
        this.showConfirmation = false; 
        
        var transfertocounter = this.counterService.getBillingcounterForId(this.transfertoctr);

        this.showspinner = true;
        var origincheck = await this.counterService.checkAndGetLatest();
        if(origincheck == false){
            this.showErrorToast("You did not have latest copy of data or counter was locked for udpated. Please try again!");
            return; 
        }

        var destinationcheck = await this.counterService.checkLatestCounter(transfertocounter);
        if(destinationcheck == false){
            this.showErrorToast("You did not have latest copy of destination counter, please try again!");
            return; 
        }

        if(!transfertocounter.sessionid  || transfertocounter.sessionid == 0){
          var retsession =   await this.counterService.startCounterSession(transfertocounter);
          if(!retsession){
            this.showErrorToast("Error in opening destination counter session, please try again!");
            return; 
          }
        }

        for(var item of this.selectedItems){
            var itemid = item.kottoken + "|" + item.createdon;
            var retdoublexfer =    await  this.counterService.checkDoubleTransfer(itemid);
            if(retdoublexfer){
                this.showErrorToast("The item: " + item.productname + ", KOT Token: " + item.kottoken + " has already been transferred!");
                return; 
            }
        }

        console.log('SESSIONID|-->' + transfertocounter.sessionid);
        var ret = await this.counterService.transferItems(transfertocounter,this.selectedItems);            
        if(!ret){
            this.showErrorToast('Error in transfer, please check and try again!');
        }

        this.showspinner = false;
        this.billingService.makeTransferDialogVisible = false; 
    }


    async transferItems(){
        var transfertocounter = this.counterService.getBillingcounterForId(this.transfertoctr);

        if(!transfertocounter) {
            this.showErrorToast("Please select valid counter");
            return;
        }

       
        if(this.selectedItems.length == 0){
            this.showErrorToast("Select atleast 1 Item to transfer");
        }else{
            this.confirmTransfer(transfertocounter);
        
        }
    }


    cancelTransfer(){
        this.showConfirmation = false;
    }

    
  
    
}
