import {Component,OnInit,Input,ViewChild,ElementRef} from '@angular/core';
import {Messages,Growl, SelectItem, Column,AutoComplete,Message,DataTable,Header,Dialog,InputText,Button,ConfirmDialog,ConfirmDialogModule, ConfirmationService, MessageService} from 'primeng/primeng';
import {Router}       from '@angular/router';	
import { DatePipe } from '@angular/common';
import {TableModule, Table} from 'primeng/table';
import { Order } from 'src/app/domain/order';
import { Appdataservice } from 'src/app/service/appdataservice';
import { Dynamodbservice } from 'src/app/service/dynamodbservice';
import { Orderservice } from 'src/app/service/orderservice';
import { Constants } from 'src/app/domain/constants';
import { Utils } from 'src/app/service/utils';
import { Paymentdialog } from './paymentdialog';
import { Deliveryboyservice } from 'src/app/service/deliveryboyservice';
import { Printingservice } from '../service/printingservice';
import { Billingservice } from '../service/billingservice';
import { Userservice } from '../service/userservice';
import { Kot } from '../domain/kot';
import { Kitchenservice } from '../service/kitchenservice';
import { Billingcounterservice } from '../service/billingcounterservice';


@Component({
    templateUrl: 'ordermanagement.html',
    providers: [ConfirmationService]
})
export class Ordermanagement implements OnInit 
{
    fromdate          : Date;
    todate            : Date;
    orderlist       :   Order[];

    filteredlist : Order[];
    paymentmodes : SelectItem[];
    

    showpendingpayments : boolean = false;
    viewtypes : SelectItem[];
    currentviewtype;

    isRunning = false; 
    
    constructor(public dataService: Appdataservice,
        private _router : Router,
        private messageService : MessageService,
        private dynamoService  : Dynamodbservice,
        private orderService  : Orderservice,
        private kitchenService : Kitchenservice,
        private userService  : Userservice,
        private confirmationService : ConfirmationService,
        public billingService : Billingservice,
        private counterService : Billingcounterservice,
        private deliveryBoyservice : Deliveryboyservice,
        private printingService : Printingservice,
        private datePipe : DatePipe,
    ) {
        if(!dataService.isuserloggedin){
            this._router.navigate(['/']);
        }
    }

    cols: any[];
    

    displayDetailDialog : boolean = false;
    totalsummval1;
    totalsummval2;
        
    ngOnInit(){
        this.fromdate = new Date(new Date().setHours(0,0,0,0));
        this.todate = new Date(new Date().setHours(0,0,0,0));
        this.showpendingonly = false; 
        this.selectedRadio = 'itemdetails';
        
        this.cols = [
            { field: 'id', header: 'Id' },
            { field: 'businessdatestr', header: 'Business date' },
            { field: 'createdonstr', header: 'Created on' },
            { field: 'countertypestr', header: 'Counter type' },
            { field: 'countername', header: 'Counter name' },
            { field: 'customername', header: 'Customer name' },
            { field: 'customermobile', header: 'Customer mobile' },
            { field: 'orderitemsstr', header: 'Items' },
            { field: 'cart.sumitemcount', header: 'Item count' },
            { field: 'cart.sumitemamount', header: 'Item amount' },
            { field: 'cart.sumitemgross', header: 'Item gross' },
            { field: 'cart.totaldiscount', header: 'Discount' },
            { field: 'cart.sumitemtaxable', header: 'Item Taxable' },
            { field: 'cart.sumitemtax', header: 'Item Taxes' },
            { field: 'cart.sumitemnet', header: 'Item Total' },
            { field: 'cart.netcharges', header: 'Charges' },
            { field: 'cart.roundingamount', header: 'Rounding' },
            { field: 'cart.netpayable', header: 'Bill amount' },
            { field: 'cart.paidamount', header: 'Paid amount' },
            { field: 'cart.pendingamount', header: 'Pending amount' },
            { field: 'pymtpaymentstring', header: 'Payment details' },
            { field: 'deliveredbystr', header: 'Delivered by' },
            { field: 'statusstr', header: 'Status' }
        ];

        
        this.orderlist = [];

        

    }

    makereceiptsDialogVisible;
    refreshReport(viewtype, dt : Table){
        this.currentviewtype = viewtype;
        this.setData(dt);
    }

    setDeliveredBy(ord : Order){
        var dboy = this.deliveryBoyservice.getDeliveryboyStringForId(ord.deliveredby);
        ord.deliveredbystr = dboy;
    }

    
    first : number = 0; 

    showErrorToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'error',  key: 'billingtoast', closable: true, summary: 'Error', detail:message});
    }

    showSuccessToast(message){
        this.messageService.clear();
        this.messageService.add({severity:'success',  key: 'billingtoast', closable: true, summary: 'Success', detail:message});
    }

    totalitemcount = 0;
    totalitemamount = 0;
    totalitemgross = 0;
    totaldiscount = 0;
    totaltaxable = 0;
    totalitemnet =0;
    totalcharges = 0;
    totalcoupons = 0;
    totaltaxes = 0;
    totalrounding = 0;
    totalnetpay = 0;
    totalpaidamount = 0;
    totalreceivableamount = 0; 
    totalcash = 0;
    totalonline = 0;

    setTotalsToZero(){
        this.totalitemcount = 0;
        this.totalitemamount = 0;
        this.totalitemgross = 0;
        this.totaltaxable = 0;
        this.totalitemnet =0;
        this.totaldiscount = 0;
        this.totalcharges = 0;
        this.totalcoupons = 0;
        this.totalrounding = 0;
        this.totalnetpay= 0;
        this.totalpaidamount = 0;
        this.totalreceivableamount = 0;
        this.totaltaxes = 0;
    }


    incrementTotals(ord : Order){
        if(ord.orderstatus != Constants.POS_ORDER_CANCELLED){
            this.totalitemcount =this.totalitemcount + ord.cart.sumitemcount;
            this.totalitemamount =  this.totalitemamount + ord.cart.sumitemamount;
            this.totalitemgross =  this.totalitemgross + ord.cart.sumitemgross;
            this.totaltaxable =  this.totaltaxable + ord.cart.sumitemtaxable;
            this.totaltaxes=  this.totaltaxes + ord.cart.sumitemtax;
            this.totalitemnet=  this.totalitemnet + ord.cart.sumitemnet;
            this.totaldiscount =this.totaldiscount  + ord.cart.totaldiscount;
            this.totalnetpay=  this.totalnetpay + ord.cart.netpayable;
            this.totalrounding =  this.totalrounding + ord.cart.roundingamount;
            this.totalcharges = this.totalcharges + ord.cart.netcharges;
            this.totalcoupons = this.totalcoupons + ord.cart.couponvalue;
            this.totalpaidamount = this.totalpaidamount + ord.cart.paidamount;
            this.totalreceivableamount = this.totalreceivableamount + ord.cart.pendingamount;
        }
    }

    roundTotals(){
        
    }

    selectedRadio;
    showspinner;
    showpendingonly; 

    cancelOrder(){
        this.billingService.orderForCancellation = this.selectedOrder;
        this.billingService.ordermanagementInstance = this; 
        this.billingService.makeordercancelDialogVisible = true;
        this.billingService.voidfromaggregator = false; 
        
        this.billingService.voidfromordermanagement = true; 

    }

    async confirmCheckoutRepair(){
        this.selectedOrder.checkedout = true;
        var ret = await this.orderService.updateOrderInDB(this.selectedOrder);
        if(!ret){
            this.showErrorToast('Error in updating checkout header, please try again!');
        }else{
            await this.setData(null);
        }

    }

    markCheckout(){        
        this.confirmationService.confirm({
            message: `<br/>There is no checkout header on this order. Please ensure: <br/><br/> <b>1. The table is not running.</b> <br/><br/> <b> 2. This is not duplicate order. </b> <br/><br/>Confirm to proceed.  `,
            accept: () => {
                this.confirmCheckoutRepair();
            }
        });
    }

    showPaymentDialog(){
        if(this.selectedOrder && this.selectedOrder.cart){
            if(!this.selectedOrder.cart.paymentlist) this.selectedOrder.cart.paymentlist = [];
            this.billingService.orderForPayment = this.selectedOrder;
            console.log('Cart Net payable ' + this.selectedOrder.cart.netpayable);

            this.billingService.ordermanagementInstance = this; 
            this.billingService.pymtfromordermanagement = true; 
            this.billingService.pymtfromcallcenterorder = false; 
            this.billingService.makepaymentdialogvisible = true;
            this.billingService.pymtfromuporder = false;
        }
        
    }


    async reprintReceipt()
    {

        this.billingService.reprinttype = 'order';
        this.billingService.orderForReprint = this.selectedOrder;

        if(this.userService.checkBillingPriv(Constants.PRIV_DUPLICATE_PRINT)){
            this.billingService.makeReprintDialogVisible = true;
        }else{
            this.billingService.reauthpurpose = Constants.PRIV_DUPLICATE_PRINT;
            this.billingService.makeReauthDialogVisible = true;
        }

        /*this.showspinner = true; 

        if(!this.selectedOrder.reprintcount) this.selectedOrder.reprintcount = 1; 
        else this.selectedOrder.reprintcount = this.selectedOrder.reprintcount + 1;

        var ret = await this.orderService.updateOrderInDB(this.selectedOrder);
        this.showspinner = false; 

        if(ret){
            document.getElementById("printable").innerHTML = this.printingService.getReceiptAsHTML(this.selectedOrder,true);
            setTimeout(()=>{ window.print();}, 200);
        }else{
            this.showErrorToast('Error, please try again!');
        }*/
      
            
    }

    
    getKotfromList(kotlist : Kot[], tokennum){
        for(var kot of kotlist){
            if(kot.kottoken == tokennum){
                return kot;
            }
        }
        return null;
    }

    async printCancelledKOts(){
        var kotlist =  <Kot[]> await this.kitchenService.getKotList(this.selectedOrder.businessdate,this.selectedOrder.businessdate);

        if( kotlist && kotlist.length >  0 && this.selectedOrder && this.selectedOrder.kotnum && this.selectedOrder.kotnum.length > 0){
            var arrkotnums = this.selectedOrder.kotnum.split(",");
            if(arrkotnums && arrkotnums.length > 0){
                for(var kotnum of arrkotnums){
                    var kot = this.getKotfromList(kotlist, parseInt(kotnum));
                    if(kot){
                        kot.iscancelled = true;
                        kot.cancellationreason = 'Order cancelled-' + this.selectedOrder.cancelreason;
                        var counter = this.counterService.getBillingcounterForId(this.selectedOrder.counterid);
                        if(counter){
                            this.kitchenService.printKOTOnPrintter(kot,counter.receipton,true);
                        }
                    }
                }
            }

        }
    }

    
    async settlementAdvice()
    {


        
        if(this.selectedOrder.cart.paidamount == this.selectedOrder.cart.netpayable){
            document.getElementById("printable").innerHTML = this.printingService.printSettlementAdvice(this.selectedOrder);
            setTimeout(()=>{ window.print();}, 200);
        }else{
            this.showErrorToast('Order not settled yet!');
        }

        
      
            
    }

    
    reportfrom; 
    reportto;

    selectedOrder : Order; 
    
    setDateStrings(ord : Order){
        ord.createdonstr = this.datePipe.transform(ord.createdon, "dd/MM HH:mm");
        ord.businessdatestr = Utils.getBusinessDateAsString("" + ord.businessdate);
        
    }

    setPaymentString(ord : Order){
        if( ord.cart && ord.cart.paymentlist != null && ord.cart.paymentlist.length > 0){
            ord.paymentstring = '';
            for(var pymt of ord.cart.paymentlist){
                ord.paymentstring = ord.paymentstring + pymt.paymentmodename + ": " + this.dataService.currencysymbol  + pymt.paidamount + ", ";
            }

            ord.paymentstring = ord.paymentstring.substring(0,ord.paymentstring.length - 2);
        }

        if (ord.receiptno && ord.receiptno.length > 0){
            if(ord.receiptno.indexOf("/") > 0){
                ord.rcptnodisplay = ord.receiptno.substring(ord.receiptno.indexOf("/")+1, ord.receiptno.length);
            }else{
                ord.rcptnodisplay = ord.receiptno;
            }
        }

        if(ord.ccordid && ord.ccordid > 0){
           // ord.ccagentstr = ord.billedbystr;  
        }else{
            ord.billedbystr = this.userService.getUserNameForId(ord.billedby);
        }
        
        
        
    }

    getOrderFromList(id){
        for(var order of this.orderlist){
            if(order.id === id ) return order; 
        }
    }


    setFilterList(){
        this.filteredlist = [];
        for (var item of this.orderlist){
            if(!this.showpendingonly || item.cart.pendingamount > 0){
                var ord = this.orderService.getClone(item);
                
                //Get status 
                ord.statusstr = Utils.getStatusStr(ord);
                ord.orderitemsstr = Utils.itemsAsString(ord);
                ord.countertypestr = Constants.getCounterTypeName(ord.countertype);
                
                this.setDateStrings(ord);
                this.setPaymentString(ord);
                this.setDeliveredBy(ord);
                this.filteredlist.push(ord);
                this.incrementTotals(ord);
            }

        }
        this.filteredlist = [... this.filteredlist];
            

    }

    async setData(dt : Table){
        var fromdate =   parseInt(this.datePipe.transform(this.fromdate,"yyyyMMdd"));
        var todate =     parseInt(this.datePipe.transform(this.todate,"yyyyMMdd"));

        if(todate < fromdate){
            this.showErrorToast("To date can not be less than from date!");
            return;
        }

        if((this.todate.getTime() - this.fromdate.getTime()) > (31 * 24 * 60 * 60 * 1000)){
            this.showErrorToast("The data can be searched for maximum of 31 days!");
            return;
        }

        this.isRunning = true; 
        this.setTotalsToZero();
      
        this.orderlist = await this.orderService.getOrderlist(fromdate,todate) ;
        this.orderlist.sort((a: Order, b: Order)=> a.id < b.id  ? -1 : 1 );

        if(this.orderlist == null){
            this.showErrorToast("Error fetching orders, please try again!")
        }else{
            this.setFilterList();
        }
      
        if(dt) dt.first = 0;
        this.filteredlist = [... this.filteredlist];
        this.isRunning = false; 
    }


    
    
}


