import {Injectable} from '@angular/core';

import {DataService} from '../service/dataservice'
import * as AWS from 'aws-sdk';
import {Constants} from '../domain/constants';
import { Printer } from '../domain/printer';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Order } from '../domain/order';
import { Appdataservice } from './appdataservice';
import { Utils } from './utils';
import { Settingsservice } from './settingsservice';
import {Http, Response} from '@angular/http';
import {Headers, RequestOptions} from '@angular/http';
import { Billingcounter } from '../domain/billingcounter';
import { Kot } from '../domain/kot';
import { Shiftendrecord } from '../domain/shiftendrecord';
import { isUndefined } from 'util';
import { Summaryrecord } from '../domain/summaryrecord';
import { Cartlineitem } from '../domain/cartlineitem';


@Injectable()
export class Printingservice {
    
    constructor(
        private dataService : Appdataservice, 
        private http: Http,
        private settingService : Settingsservice,
        private datePipe : DatePipe){}
    printkot; 

    public  setPadding(receiptString : string) : string{
        receiptString = receiptString.replace(/<PAD1>/g, " ");
        receiptString = receiptString.replace(/<PAD2>/g, "  ");
        receiptString = receiptString.replace(/<PAD3>/g, "   ");
        receiptString = receiptString.replace(/<PAD4>/g, "    ");
        receiptString = receiptString.replace(/<PAD5>/g, "     ");
        receiptString = receiptString.replace(/<PAD6>/g, "      ");
        receiptString = receiptString.replace(/<PAD7>/g, "       ");
        receiptString = receiptString.replace(/<PAD8>/g, "        ");
        receiptString = receiptString.replace(/<PAD9>/g, "         ");
        receiptString = receiptString.replace(/<PAD10>/g, "          ");
        receiptString = receiptString.replace(/<PAD11>/g, "           ");
        receiptString = receiptString.replace(/<PAD12>/g, "            ");
        receiptString = receiptString.replace(/<PAD13>/g, "             ");
        receiptString = receiptString.replace(/<PAD14>/g, "              ");
        receiptString = receiptString.replace(/<PAD15>/g, "               ");
        receiptString = receiptString.replace(/<PAD16>/g, "                ");
        receiptString = receiptString.replace(/<PAD17>/g, "                 ");
        receiptString = receiptString.replace(/<PAD18>/g, "                  ");
        receiptString = receiptString.replace(/<PAD19>/g, "                   ");
        receiptString = receiptString.replace(/<PAD20>/g, "                    ");

        //receiptString = receiptString.replace(/<BR>/g,"<BR>");
        return receiptString;
    }

    public  getLine(paperwidth, islarge?){
        if(!islarge){
                return "- - - - - - - - - - - - - - -<BR>"; //Add a new line
        }else{
                return "- - - - - - - - - -<BR>"; //Add a new line
        }
    }

    getPadString(namelength){
        switch(namelength){
            case 26: return  "                          ";
            case 13: return  "             ";
            case 42: return  "                                          ";
            case 21: return  "                     ";
            case 34: return  "                                  ";
            case 17: return  "                 ";
            case 36: return  "                                    ";
            case 18: return  "                  ";
            

        }
    }


    getChargesLine(order : Order){
        if(order.cart.netcharges > 0)
        {
               var chargesstr =  "Delivery charges:";
               var padstring = "                                         ";

               chargesstr = Utils.lpad(padstring, chargesstr);
               chargesstr = chargesstr + Utils.lpad("       ", Utils.format(order.cart.netcharges,2));
                   
               return chargesstr + "<BR>";
        }

       return "";
   }

   getDiscountLine(order : Order){
    if(order.cart.totaldiscount > 0)
    {
        var discountstr=  "Discount: ";
        var padstring = "                                         ";

        discountstr = Utils.lpad(padstring, discountstr);
        discountstr = discountstr + Utils.lpad("       ", Utils.format(order.cart.totaldiscount,2));

        return discountstr + "<BR>";
    }

    return "";
}


   getCustomerSection(order : Order){
        var customersection = "Customer details: <BR>";
        customersection = customersection + order.customername + "<BR>";
        customersection = customersection + order.customermobile + "<BR>";
        customersection = customersection + order.addressstr + "<BR>";
        customersection = customersection + this.getLine(Constants.THREE_INCH_PAPER);
        return customersection;
    }


    getDuplicateLine(isduplicate){
        if(isduplicate){
            return " *** DUPLICATE ***<BR>";
        }else
            return "";
    }


    getDeliveryInstructions(order : Order){
        if(order.cart.splinstructions && order.cart.splinstructions.length > 0 ){
            return "Instructions:<BR> " + order.cart.splinstructions; 
        }else{
            return "";
        }
    }



    public  setTags(receiptString : string,order : Order , isduplicate) 
        {
           
            var retailer = this.dataService.retailer;
            var printer = this.settingService.printer;

            var orderdate =  new Date(order.createdon);
            var strDate  = this.datePipe.transform(orderdate, "dd/MM/yy");
            var strDateTime  = this.datePipe.transform(orderdate, "dd/MM/yy HH:mm");
            var strDateTime12Hour  = this.datePipe.transform(orderdate, "dd/MM/yy hh:mm");
            
            var strDateBillNo = strDateTime +  "  Bill No: <LARGE>" + order.receiptno + "<SMALL><BR><BR>";
            var strDateBillNoSmall = strDateTime +  "  Bill No: " + order.receiptno + "<BR><BR>";
            var strDateBillNo12Hour = strDateTime12Hour +  "  Bill No: <LARGE>" + order.receiptno + "<SMALL><BR><BR>";
            var strDateBillNo12HourSmall = strDateTime12Hour +  "  Bill No: " + order.receiptno + "<BR><BR>";

            receiptString = receiptString.replace(/<SHOWREVRATE>/g,"");
            receiptString = receiptString.replace(/<SHOWMRP>/g,"");
            receiptString = receiptString.replace(/<NOITEMDISC>/g,"");
            receiptString = receiptString.replace(/<NOEMPTYROWS>/g,"");
            receiptString = receiptString.replace(/<GSTINNO>/g, retailer.gstinno);
            receiptString = receiptString.replace(/<DUPLIATELINE>/g, this.getDuplicateLine(isduplicate));
            receiptString = receiptString.replace(/<VATTINNO>/g, retailer.vattinno);
            receiptString = receiptString.replace(/<RETAILERNAME>/g,retailer.name);  //.replace(/<BR>/g,"<BR>"));
            receiptString = receiptString.replace(/<ADDRESS>/g,retailer.address);    //.replace(/<BR>/g,"<BR>"));
            receiptString = receiptString.replace(/<COUNTERNAME>/g,  'Online');
            receiptString = receiptString.replace(/<SECTIONNAME>/g,  'Online');
            receiptString = receiptString.replace(/<COUNTERNAMEONLY>/g,'Online');
            receiptString = receiptString.replace(/<COUNTERSHORTNAME>/g,'Online');
            receiptString = receiptString.replace(/<KOTTOKEN>/g,"") ;
            receiptString = receiptString.replace(/<KOTTOKENLARGE>/g,"");
            receiptString = receiptString.replace(/<DATE>/g,strDate);
            receiptString = receiptString.replace(/<DATETIME>/g,strDateTime);
            receiptString = receiptString.replace(/<DATETIME12HOUR>/g,strDateTime12Hour);
            receiptString = receiptString.replace(/<BILLNO>/g,"" + order.receiptno);
            receiptString = receiptString.replace(/<ITEMSECTION>/g, this.addItemsToReceipt(order));
            receiptString = receiptString.replace(/<LOCATIONCODE>/g,retailer.locationcode);
            receiptString = receiptString.replace(/<LOCATIONNAME>/g,retailer.locationname);
            receiptString = receiptString.replace(/<DATEBILLNOLINE>/g,strDateBillNo);
            receiptString = receiptString.replace(/<DATEBILLNOLINE12HOUR>/g,strDateBillNo12Hour);
            receiptString = receiptString.replace(/<DATEBILLNOLINESMALL>/g,strDateBillNoSmall);
            receiptString = receiptString.replace(/<DATEBILLNOLINE12HOURSMALL>/g,strDateBillNo12HourSmall);
            receiptString = receiptString.replace(/<LINE>/g,this.getLine(printer.paperwidth));
            receiptString = receiptString.replace(/<TOTALTAXES>/g,Utils.lpad("       ",Utils.format(order.cart.nettaxes,2)));
            receiptString = receiptString.replace(/<SUBTOTAL>/g,Utils.lpad("       ", Utils.format(order.cart.sumitemgross,2)));
            receiptString = receiptString.replace(/<ROUNDING>/g,Utils.lpad("       ", Utils.format(order.cart.roundingamount,2)));
            receiptString = receiptString.replace(/<DISCOUNT>/g,Utils.lpad("       ", Utils.format(order.cart.totaldiscount,2)));
            receiptString = receiptString.replace(/<NET>/g,Utils.lpad("       ", order.cart.netpayable));
            receiptString = receiptString.replace(/<DELIVERYCHARGESLINE>/g,this.getChargesLine(order));
            receiptString = receiptString.replace(/<DISCOUNTLINE>/g,this.getDiscountLine(order));
            receiptString = receiptString.replace(/<CHARGESLINE>/g,this.getChargesLine(order));
            receiptString = receiptString.replace(/<ITEMSECTION>/g, this.addItemsToReceipt(order));
            receiptString = receiptString.replace(/<PAPERCUT>/g,"<BR><BR><BR><PAPERCUT>");
            receiptString = receiptString.replace(/<CUSTOMERSECTION>/g, this.getCustomerSection(order));
            receiptString = receiptString.replace(/<CUSTOMERSECTIONADDRALL>/g, this.getCustomerSection(order));
            receiptString = receiptString.replace(/<BOOKINGSECTION>/g ,"");
            receiptString = receiptString.replace(/<PAYMENTSECTION>/g ,this.getPaymentSection(order));
            receiptString = receiptString.replace(/<DELIVERYINSTRUCTIONS>/g,this.getDeliveryInstructions(order));
            
            return receiptString;

        }
        

        
        getPaymentSection(order : Order){
            var strpayment = "<BOLD>Payment details:<NORMAL> <BR>";
            strpayment  = strpayment + "Paid amount: " + Utils.format(order.cart.paidamount,2) + "<BR>";
            strpayment  = strpayment + "Pending amount: " + Utils.format(order.cart.netpayable- order.cart.paidamount,2) + "<BR>";
            return strpayment;
       }

    public addItemsToReceipt( order : Order){

            var itemsstring = "";

            //Add line for ITEM and QTY
            itemsstring = itemsstring  + this.getLine(Constants.THREE_INCH_PAPER);

            var namelength = 27;
            var padstring = "                           ";
            itemsstring = itemsstring + "ITEM" + Utils.lpad("                                            ","QTY  RATE  AMOUNT") + "<BR>";
            itemsstring = itemsstring  + this.getLine(Constants.THREE_INCH_PAPER);

            
            for(var detailitem of  order.cart.lineitems){
                
                if(Constants.DEBUG_MODE) console.log('addiing item ' + detailitem.productname + ' sch code' + detailitem.schcode);
                        
                        var productname =  detailitem.productname ;

                      

                        if(detailitem.customization && detailitem.customization.length > 0){
                            productname = productname  + ' [' + detailitem.customization + '] ';
                        }
                        if(detailitem.instructions && detailitem.instructions.length > 0){
                            productname = productname  + ' {' + detailitem.instructions + '} ';
                        }
            
                        itemsstring = itemsstring +  Utils.convertoMultilineFixedLengh(productname,namelength,padstring).toUpperCase();
                        var ratetobeshown = Utils.round(detailitem.rate,2);
                        var itemamt = detailitem.amount;   
                        
                        if (Math.floor(detailitem.quantity) == detailitem.quantity) {
                            itemsstring = itemsstring + Utils.lpad("      ","" + parseInt(detailitem.quantity));
                        } else if (Utils.round(detailitem.quantity,2) == detailitem.quantity) {
                            itemsstring = itemsstring + Utils.lpad("      ", Utils.format(detailitem.quantity,2));
                        } else
                            itemsstring = itemsstring + Utils.lpad("      ", Utils.format(detailitem.quantity,3));


                        if (Math.floor(ratetobeshown) == ratetobeshown) 
                            itemsstring = itemsstring + Utils.lpad("       ", "" + parseInt("" + ratetobeshown));
                        else
                            itemsstring = itemsstring + Utils.lpad("       ", "" +   Utils.format(ratetobeshown,2));
                        
                        itemsstring = itemsstring + Utils.lpad("        ",  ""  +  Utils.format(itemamt,2));
                        itemsstring = itemsstring +"<BR>";
                    
                
            }

            return itemsstring;
        }



        public addItemsToKOTNEW(kot : Kot,  paperwidth, islargefont?){
            var itemsstring = "";
    
            var    namelength = 25;
            itemsstring = itemsstring + "QTY    ITEM                     ";
            var padstring = "";
            
            if(Constants.DEBUG_MODE) console.log('|NAMELENGTH|' + namelength + ' LARGE FONT ' + islargefont) ;
            padstring =  this.getPadString(namelength) ;
            if(Constants.DEBUG_MODE) console.log('|PADSTRING|' + padstring + '|PADSTRING|');
            
            itemsstring = itemsstring  + "<BR>"
            itemsstring = itemsstring  + this.getLine(paperwidth,islargefont);
            
            //var revlineitems = kot.lineitems.reverse();
            kot.lineitems.sort((a:Cartlineitem, b: Cartlineitem)=> (a.createdon)  < (b.createdon)  ? -1 : 1 );
    
    
            for(var detailitem of   kot.lineitems){
                var productname =  detailitem.productname ;
    
                if(detailitem.customization && detailitem.customization.length > 0){
                    var customization = ' [' + detailitem.customization + '] ';
                    customization = customization.replace(/,/g, '/');

                    productname = productname  + customization;
                }
                if(detailitem.instructions && detailitem.instructions.length > 0){
                    productname = productname  + ' {' + detailitem.instructions + '} ';
                }
    
    
                var qtypad = "       ";
                var qty = detailitem.quantity;
                
                if(kot.iscancelled && detailitem.cancelqty  && detailitem.cancelqty > 0)
                    qty= detailitem.cancelqty;
    
    
                if (Math.floor(qty) == qty) {
                    itemsstring = itemsstring + Utils.rpad(qtypad,"" + parseInt(qty));
                }else
                    itemsstring = itemsstring + Utils.rpad(qtypad, Utils.format(qty,3));
    
    
                //itemsstring = itemsstring +  Utils.convertoMultilineFixedLengh(productname,namelength,padstring).toUpperCase();
                //itemsstring = itemsstring + productname.toUpperCase();
                var remaininglength = productname.length;
                var isfirst = true; 
                var curposition = 0;
                while(remaininglength > 25){
                    var strtobeadded = productname.substring(curposition, curposition + 25);
                    curposition = curposition + 25;
                    remaininglength = remaininglength - 25;
                    if(!isfirst){
                        strtobeadded = "       " + strtobeadded;
                    }
                    isfirst = false; 
                    itemsstring = itemsstring + strtobeadded + "<BR>";
                }
                
                if(remaininglength > 0){
                    var strtobeadded = productname.substring(curposition, productname.length);
                    if(!isfirst){
                        strtobeadded = "       " + strtobeadded;
                    }
                    itemsstring = itemsstring + strtobeadded + "<BR>";

                }


                //if(Constants.DEBUG_MODE) console.log('ITEMSTRING|'+ itemsstring  + '|ITEMSTRING');
                itemsstring = itemsstring +"<BR>";
                
            }
    
            return itemsstring;
    
        }
        

    public addItemsToKOT(kot : Kot,  paperwidth, islargefont?){
        var itemsstring = "";

        var    namelength = 34;
        if(islargefont){
            itemsstring = itemsstring + "<LARGE>";
            itemsstring = itemsstring + "QTY  ITEM             ";
        }else{
            itemsstring = itemsstring + "QTY   ITEM                              ";
        }   
    
        var padstring = "";
        if(islargefont) namelength = namelength / 2; 

        if(Constants.DEBUG_MODE) console.log('|NAMELENGTH|' + namelength + ' LARGE FONT ' + islargefont) ;
        padstring =  this.getPadString(namelength) ;
        if(Constants.DEBUG_MODE) console.log('|PADSTRING|' + padstring + '|PADSTRING|');
        
        itemsstring = itemsstring  + "<BR>"
        itemsstring = itemsstring  + this.getLine(paperwidth,islargefont);
        
        //var revlineitems = kot.lineitems.reverse();
        kot.lineitems.sort((a:Cartlineitem, b: Cartlineitem)=> (a.createdon)  < (b.createdon)  ? -1 : 1 );


        for(var detailitem of   kot.lineitems){
            var productname =  detailitem.productname ;

            if(detailitem.customization && detailitem.customization.length > 0){
                productname = productname  + ' [' + detailitem.customization + '] ';
            }
            if(detailitem.instructions && detailitem.instructions.length > 0){
                productname = productname  + ' {' + detailitem.instructions + '} ';
            }


            var qtypad = "     ";
            if(islargefont) qtypad =  "   ";
            var qty = detailitem.quantity;
            
            if(kot.iscancelled && detailitem.cancelqty  && detailitem.cancelqty > 0)
                qty= detailitem.cancelqty;


            if (Math.floor(qty) == qty) {
                itemsstring = itemsstring + Utils.rpad(qtypad,"" + parseInt(qty));
            }else
                itemsstring = itemsstring + Utils.rpad(qtypad, Utils.format(qty,3));


            //itemsstring = itemsstring +  Utils.convertoMultilineFixedLengh(productname,namelength,padstring).toUpperCase();
            itemsstring = itemsstring + productname.toUpperCase();

            //if(Constants.DEBUG_MODE) console.log('ITEMSTRING|'+ itemsstring  + '|ITEMSTRING');
            itemsstring = itemsstring +"<BR><BR>";
            
        }

        if(islargefont) itemsstring = itemsstring + "<SMALL>";
        return itemsstring;

    }
    
    


    public  setKOTTags(kotString : string, kot : Kot, order : Order,isduplicate,istest) 
    {
            
        var retailer = this.dataService.retailer;
        var paperwidth = Constants.THREE_INCH_PAPER;


        var kotDatetime =  this.datePipe.transform(new Date(kot.id), "dd/MM/yy HH:mm");
        var kotDate =  this.datePipe.transform(new Date(kot.id), "dd/MM/yy");
        var kotDatetime12Hour =  this.datePipe.transform(new Date(kot.id), "dd/MM/yy hh:mm");


        
        if(istest){
            kotString = kotString.replace(/<DUPLICATE>/g, '* TEST PRINT *');
        }else if(isduplicate){
            kotString = kotString.replace(/<DUPLICATE>/g, '* DUPLICATE *');
        }else{
            kotString = kotString.replace(/<DUPLICATE>/g, '');
        }


        if (kot.iscancelled){
            kotString = kotString.replace(/<CANCELLATION>/g, '** CANCELLATION **');
            kotString = kotString.replace(/<CANCELLATIONREASON>/g, 'Reason: ' + kot.cancellationreason);
        }else{
            kotString = kotString.replace(/<CANCELLATION>/g, '');
            kotString = kotString.replace(/<CANCELLATIONREASON>/g, '');
        }

        kotString = kotString.replace(/<KITCHENNAME>/g, kot.kitchenname);
        kotString = kotString.replace(/<RUNNING>/g, '');

        kotString = kotString.replace(/<GSTINNO>/g, retailer.gstinno);
        kotString = kotString.replace(/<VATTINNO>/g, retailer.vattinno);
        kotString = kotString.replace(/<RETAILERNAME>/g,retailer.name);  //.replace(/<BR>/g,"<BR>"));
        kotString = kotString.replace(/<ADDRESS>/g,retailer.address);    //.replace(/<BR>/g,"<BR>"));

        
        kotString = kotString.replace(/<COUNTERNAME>/g,  kot.countername);
        kotString = kotString.replace(/<COUNTERNAMEONLY>/g, kot.countername);
        kotString = kotString.replace(/<DATE>/g,kotDate);
        kotString = kotString.replace(/<DATETIME>/g,kotDatetime);
        kotString = kotString.replace(/<DATETIME12HOUR>/g,kotDatetime12Hour);


        if(kot.servedbystr && kot.servedbystr.length > 0 ){
            var strservedby = "Served by: " + kot.servedbystr + " <BR>";
            kotString = kotString.replace(/<SERVEDBY>/g,  strservedby);
        }else{
            kotString = kotString.replace(/<SERVEDBY>/g,  "");
        }

        var operatorname = "User: " + this.dataService.loggedinusername + " <BR>";
        if(kot.agentname && kot.agentname.length > 2){
            operatorname = "Agent: " + kot.agentname + " <BR>";
        }
        kotString = kotString.replace(/<OPERATORNAME>/g,  operatorname);
    
        var counterremarks ='';
        if(kot.ctrremarks && kot.ctrremarks.length > 0 ) counterremarks =  'Remarks: ' + kot.ctrremarks + '<BR><BR>';

        kotString = kotString.replace(/<REMARKS>/g,counterremarks);

        var aggrid ='';
        if(kot.aggrref && kot.aggrref.length > 2 ) aggrid =  'Aggr Id: ' + kot.aggrref + '<BR>';

        kotString = kotString.replace(/<AGGRID>/g,aggrid);


        var kottoken =  kot.kottoken;
        kotString = kotString.replace(/<KOTNUM>/g, kottoken);
        if(kotString.indexOf("<ITEMSECTION>") >= 0) kotString = kotString.replace(/<ITEMSECTION>/g, this.addItemsToKOT(kot,Constants.THREE_INCH_PAPER));
        if(kotString.indexOf("<ITEMSECTIONLARGE>") >= 0) kotString = kotString.replace(/<ITEMSECTIONLARGE>/g, this.addItemsToKOT(kot,paperwidth,true));
        if(kotString.indexOf("<ITEMSECTIONNEW>") >= 0) kotString = kotString.replace(/<ITEMSECTIONNEW>/g, this.addItemsToKOTNEW(kot,paperwidth,true));
        
        kotString = kotString.replace(/<PAPERCUT>/g,"<BR><BR><BR><BR><PAPERCUT>");
        kotString = kotString.replace(/<LINE>/g,this.getLine(Constants.THREE_INCH_PAPER));

        if(order != null && order.customername != null && order.customername.length > 0){
            kotString = kotString.replace("<CUSTNAME>",order.customername);
            kotString = kotString.replace("<CUSTMOBILE>",order.customermobile);
        }

        return kotString;

    }


    /*public generateKOTFormatForOrder(order : Order, isduplicate){
        var retailer = this.dataService.retailer;
        var kotstring  = retailer.kotformat;
        kotstring = this.setPadding(kotstring);
        kotstring = this.setKOTTags(kotstring,order,isduplicate);
        return kotstring;
    }*/


    public generateKOTFormat(kot : Kot, order : Order, isduplicate , istest){
        var retailer = this.dataService.retailer;
        var kotstring  = retailer.kotformat;
        kotstring = this.setPadding(kotstring);
        kotstring = this.setKOTTags(kotstring,kot, order,isduplicate,istest);
        return kotstring;
    }




    
    public generateReceiptFormat(order : Order, isduplicate){
        var retailer = this.dataService.retailer;
        var receiptstring  = retailer.gstreceiptformat;
        receiptstring = this.setPadding(receiptstring);
        receiptstring = this.setTags(receiptstring,order,isduplicate);
        return receiptstring;
    }


    public async generateAndPrintKOT(kot : Kot,printer : Printer, isduplicate,istest){
        console.log('|SERVEDBY|GENPRINT|kot.servedbystr-->' + kot.servedbystr);

        var kotbuffer = this.generateKOTFormat(kot,null,isduplicate,istest);
        var printeridentifier = printer.printername;
        if(printer.printertype == Constants.LAN) 
            printeridentifier = printer.deviceaddress;

        return await this.synchronousprint(kotbuffer, printer.printertype,printeridentifier);

    }


    public async openCashDrawer(printer : Printer){
        var openCommand = "<INIT><OPENDRAWER>";
        var printeridentifier = printer.printername;
        if(printer.printertype == Constants.LAN) 
            printeridentifier = printer.deviceaddress;

        return await this.synchronousprint(openCommand, printer.printertype,printeridentifier);

    }



    public async synchronousprint(printBuffer : string, printertype, printeridentifier)
    {
            var portno = "8080";

            if(localStorage.getItem("serviceport") && localStorage.getItem("serviceport").length > 0){
                portno = localStorage.getItem("serviceport");
            }
            
            var url = 'http://localhost:' + portno + '/FoodKartService/PrintServlet'; 


     
            if(localStorage.getItem("posip") && localStorage.getItem("posip").length > 0){
                var posip = localStorage.getItem("posip");
                url = "http://" + posip + ":" +  portno + "/FoodKartService/PrintServlet"; 
            }

            //var url = 'http://localhost:8080/FoodKartService/PrintServlet'; 

            let body = JSON.stringify({operationtype : 'print' , printbuffer : printBuffer,printeridentifier: printeridentifier,printertype : printertype});
            let headers = new Headers({ 'Content-Type': 'application/json;charset=utf-8' });
            let options = new RequestOptions({ headers: headers });
        
            if(Constants.DEBUG_MODE) console.log('SYNC PRINT Sending request URL --> ' + url + ' Data ' + body);
        
            try{
                const retpromise=  await this.http.post(url,body,options).toPromise();
                var retjson = retpromise.json();
                if(Constants.DEBUG_MODE) console.log('SYNC PRINT STatus returned with http request -> ' + JSON.stringify(retpromise.json())  + ' Status text ' + retjson.status );
                if(retjson.status === 0 )
                    return true;
                
            }catch(ex){
                if(Constants.DEBUG_MODE) console.log('Error in printing ---> ' + ex.toString());
            } 
            
            return false;
    }



    getNewReceiptNo() : string
    {
        var token  = localStorage.getItem('receiptno');
        if(Constants.DEBUG_MODE) console.log('*** Receipt found ' + token);

        var tokennum = 1;
        if(token){
            tokennum = parseInt(token) + 1;
        }
        
        localStorage.setItem('receiptno', ''+tokennum);
        
        return  this.settingService.printer.receiptprefix + tokennum;    

    }

    getNewKOTToken() : string
    {
        var token  = localStorage.getItem('kottoken');
        if(Constants.DEBUG_MODE) console.log('*** Token found ' + token);

        var tokennum = 1;
        if(token){
            tokennum = parseInt(token) + 1;
        }
        
        
        var lastkotbusinessdate = localStorage.getItem('lastkotbusinessdate');
        var currentbusinessdate = this.dataService.getCurrentBusinessDate();       

        if(lastkotbusinessdate){
            if(parseInt(lastkotbusinessdate) < currentbusinessdate){
                tokennum = 1;
            }
        }

        localStorage.setItem('lastkotbusinessdate', ''+ currentbusinessdate);
        localStorage.setItem('kottoken', ''+tokennum);
        return  "" + tokennum;    

    }



    getItemSectionKot(order : Order){

        var kotheader : string;

        kotheader = "<table class=\"KotTable \"><tr class=\"KotHeader  ThinBorder\" >" +
                            "<td width=10 align=center>Qty</td>" +                 
                            "<td width=40 align=center>Product</td>" +

                                            
                         "</tr>";

        var i = 1;                     


        for(var item of order.cart.lineitems){

            var itemstring = item.productname;
            if(item.customization && item.customization.length > 0){
                itemstring = itemstring  + ' [' + item.customization + '] ';
            }
            if(item.instructions && item.instructions.length > 0){
                itemstring = itemstring  + ' {' + item.instructions + '} ';
            }


             
            kotheader = kotheader + "<tr class=KotItem>" + 
                                "<td width=10 align=center>" + item.quantity + "</td>" +             
                                "<td width=90 align=left>" + itemstring + "</td>" +
                                "</tr>";
        }

        kotheader = kotheader + "</table>";

        return kotheader;
    }
    

    getDenominationsInTill(shiftrec : Shiftendrecord){

        var kotheader : string;

        kotheader = "<table class=\"KotTable ThinBorder \"><tr class=\"KotHeader\"  >" +
                            "<td width=60 align=center>Denomination</td>" +
                            "<td width=40 align=right>Amount</td>" +
                            
                         "</tr>";

        var i = 1;                     


        for(var item of shiftrec.arrcashdenomination){

            if(!item.quantity) item.quantity = 0;
            if(!item.amount) item.amount = 0;
            kotheader = kotheader + "<tr class=KotItem>" + 
                                "<td width=60 align=left>" + item.denomination + " x " + item.quantity  + "</td>" +             
                                "<td width=40 align=right>" + Utils.format(item.amount,2) + "</td>" +
                                "</tr>";
        }

        kotheader = kotheader + "<tr class=KotItem>" + 
                    "<td width=60 align=left> COINS </td>" +             
                    "<td width=40 align=right>" + Utils.format(shiftrec.coinsvalue,2) + "</td>" +
                    "</tr>";

        kotheader = kotheader + "<tr class=KotItem>" + 
                    "<td width=60 align=left> FOREIGN CURRENCY </td>" +             
                    "<td width=40 align=right>" + Utils.format(shiftrec.forexinaed,2) + "</td>" +
                    "</tr>";

        kotheader = kotheader + "<tr class=KotItem>" + 
                    "<td width=60 align=left> LOW DENOMINATION </td>" +             
                    "<td width=40 align=right>" + Utils.format(shiftrec.lowerdenominationvalue,2) + "</td>" +
                    "</tr>";

        kotheader = kotheader + "</table>";

        

        return kotheader;
    }



    getModeWiseReceipts(shiftrec : Shiftendrecord){
        
        var kotheader : string;
        kotheader = "<table class=\"KotTable ThinBorder \"><tr class=\"KotHeader\"  >" +
                            "<td width=60 align=center>Payment Mode</td>" +
                            "<td width=40 align=right>Amount</td>" +
                         "</tr>";

        var i = 1;                     


        for(var item of shiftrec.arrpymtmodewisesales){

            kotheader = kotheader + "<tr class=KotItem>" + 
                                "<td width=60 align=left>" + item.description + "</td>" +             
                                "<td width=40 align=right>" + Utils.format(item.summaryval2,2) + "</td>" +
                                "</tr>";
        }

        if(shiftrec.eoddate && shiftrec.eoddate > 0){
            kotheader = kotheader + "<tr class=KotItem>" + 
                "<td width=60 align=left>Total Tips</td>" +             
                "<td width=40 align=right>" + Utils.format(shiftrec.totaltips,2) + "</td>" +
                "</tr>";
        }


        kotheader = kotheader + "</table>";

        return kotheader;
    }


    getOrdertypeWiseSales(shiftrec : Shiftendrecord){
        
        var kotheader : string;
        kotheader = "<table class=\"KotTable ThinBorder \"><tr class=\"KotHeader\"  >" +
                            "<td width=60 align=center>Order type</td>" +
                            "<td width=40 align=right>Amount</td>" +
                         "</tr>";

        var i = 1;                     


        for(var item of shiftrec.arrcountertypewisesales){

            kotheader = kotheader + "<tr class=KotItem>" + 
                                "<td width=60 align=left>" + item.description + " ["  + item.summaryval1  +  "]</td>" +             
                                "<td width=40 align=right>" + Utils.format(item.summaryval2,2) + "</td>" +
                                "</tr>";
        }

        
        kotheader = kotheader + "</table>";

        return kotheader;
    }


    getItemSectionReceipt(order : Order){

        var kotheader : string;

        
        if(this.dataService.retailer.id  == 1553 || this.dataService.retailer.id  == 1511){
            kotheader = "<table class=\"KotTable ThinBorder \"><tr class=\"KotHeader\"  >" +
                                "<td width=6 align=center>Qty <br/> كمية </td>" +                 
                                "<td width=86 align=center>Product <br/> اسم المنتج " +
                                //"<td width=10 align=right>Rate</td>" +
                                "<td width=8 style='padding-right: 5px' align=right>Amt <br/> القيمة</td>" +
                            "</tr>";
        }else{
            kotheader = "<table class=\"KotTable ThinBorder \"><tr class=\"KotHeader\"  >" +
                                "<td width=6 align=center>Qty </td>" +                 
                                "<td width=86 align=center>Product" +
                                "<td width=8 style='padding-right: 5px' align=right>Amt </td>" +
                            "</tr>";
        }
        var i = 1;                     


        for(var item of order.cart.lineitems){

            var itemstring = item.productname;

            if(item.schcode && item.schcode.length > 0){
                if(item.schcode.indexOf('LN') > 0) {
                    itemstring = 'HH-L ' +  item.productname;
                }else{
                    itemstring = 'HH-D ' +  item.productname;
                }
            }

            if(item.customization && item.customization.length > 0){
                itemstring = itemstring  + ' [' + item.customization + '] ';
            }

            /*if(item.instructions && item.instructions.length > 0){
                itemstring = itemstring  + ' {' + item.instructions + '} ';
            }*/

            var prod = this.dataService.getProductForId(item.productid);
            console.log('LANG| PROD CODE : ' + prod.code + ' PRD LOCAL NAME ' + prod.localname)
            if(prod && prod.localname && prod.localname.length > 0){
                itemstring = itemstring + "<br/>" + prod.localname;
            }

            if(item.grossamt > 0){
                kotheader = kotheader + "<tr class=KotItem>" + 
                                    "<td width=10 align=center>" + item.quantity + "</td>" +             
                                    "<td width=80 align=left>" + itemstring + "</td>" +
                                    //"<td width=10 align=right>" + (item.rate + item.customizationrate) + "</td>" +
                                    "<td width=10 style='padding-right: 5px' align=right>" + item.amount + "</td>" +
                                    "</tr>";
            }
        }

        kotheader = kotheader + "</table>";

        

        return kotheader;
    }


    getKOTAsHMTL(order : Order){
        var str = "<HTML><BODY>";
        str  = str + "<div class=\"ThinBorder\" height=\"300px\" style=\"border-color: white !important; min-height: 300px !important; \">";
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 10px !important; \">KOT# " +  order.kotnum + "</div> <br/>";
        
        str= str+ "<div class=ui-g-12 style=\"line-height: 1.6\"><label class=SmallPadLeft>Order Time: <b>" + this.datePipe.transform(order.createdon, "dd/MM HH:mm") + "</b></label><br/>";
        str= str+ "<label class=SmallPadLeft>Cust Name: <b>" + order.customername + "</b></label><br/>";
        
        var custmobile = order.customermobile;
        if(order.customermobile && order.customermobile.length <= 9)
            custmobile = '0' + order.customermobile;

        
        str= str+ "<label class=SmallPadLeft>Cust#: <b>" + custmobile  + "</b></label><br/>";
        
        if(order.cart.splinstructions && order.cart.splinstructions.length > 0){
            str = str + "<label class=SmallPadLeft>"+  this.getDeliveryInstructions(order) + "</label><BR/>";
        }
        str = str + "</div>";
        
        str  = str + this.getItemSectionKot(order);

        str  = str + "</div>";
        
        
        
        str =  str + "</BODY></HTML>";
        return str;
    }


    getReceiptAsHTML(order : Order,isduplicate, isofficecopy?){
        var str = "<HTML><BODY>";
        str  = str + "<div class=\"ThinBorder NoPad\" height=\"100px\" style=\"border-color: white !important; min-height: 100px !important; \">";
        
        if(order.ispizzapan){
            str  = str + "<div class=\" aligncenter\">  <img  style=\"margin-top: 5px !important;margin-left: auto !important;width: 90% !important; margin-bottom: 5px !important;\"  src='assets/demo/images/pizzapan.png'  width='100%'  > </div> <br/>";
        }else if(this.dataService.retailer.id  == 1511 && order.uproderid && order.uproderid > 0 && !order.issfcplus){
            str  = str + "<div class=\" aligncenter\">  <img  style=\"margin-top: 5px !important;margin-left: auto !important;width: 90% !important; margin-bottom: 5px !important;\"  src='assets/demo/images/49ers_logo.jpg'  width='100%' > </div> <br/>";
        }else{
            str  = str + "<div class=\" aligncenter\">  <img  style=\"margin-top: 5px !important;margin-left: auto !important;width: 90% !important; margin-bottom: 5px !important;\"  src='https://s3.ap-south-1.amazonaws.com/foodkartiposimages/appassets/" + this.dataService.retailer.id + "_horizontallogo.png'  width='100%'  > </div> <br/>";
        }

        
        if(this.dataService.retailer.id == 1511) str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> دجاج جنوب فرايد</div> <br/>";

        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " +  this.dataService.retailer.name.toUpperCase() + "</div> <br/>";
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> " +  this.dataService.retailer.address + "</div> <br/>";
        
        
        if(this.dataService.retailer.id == 1511) str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> فاتورة ضريبية</div> <br/>";

        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> TAX INVOICE</div> <br/>";
        if(this.dataService.retailer.id == 1350){
            str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> Sthan Restaurant L.L.C <BR/>TRNNO:100049805300003 <HR></div> <br/>";
        }else if(this.dataService.retailer.id  == 1554){
            str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> AVENUE STEAK HOUSE<BR/>LE MERIDIEN<BR/> ABUDHABI <BR/> TL:02-6458000 <BR/> TRNNO:100479429100003 <HR></div> <br/>";
        }else if(this.dataService.retailer.id  == 1511){

            str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \">  مطعم الدجاج المقلي الجنوبي LLC </div> <br/>";
            str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \">  100241988300003:- رقم التسجيل الضريبي </div> <br/>";


            str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \">  Southern Fried Chicken Restaurant LLC <BR/>TRNNO:100241988300003 <HR></div> <br/>";
            
        }else
            str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> India Palace Restaurant L.L.C, ABUDHABI <BR/>TRNNO:100241988300003 <HR></div>";

        if(isduplicate){
            if(this.dataService.retailer.id == 1511)  str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 1px !important; \"> <b>ينسخ DUPLICATE </b></div>";
            else str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 1px !important; \"> <b> ** DUPLICATE ** </b></div> ";

        }
        var countertypestr = Constants.getCounterTypeNameForReceipt(order.countertype);    
        


        if(this.dataService.retailer.id  == 1553 || this.dataService.retailer.id  == 1511){
            str= str+ "<div class=ui-g-12 style=\"line-height: 1.3\">" + 
                        "<label class=SmallPadLeft>فاتورة Bill#: " + order.receiptno + "</b> </label><br/>" +
                        //"<label class=SmallPadLeft style='margin-top: 2px'>رقم التعريف الخاص بالطلب</label><br/>" +
                        "<label class=SmallPadLeft >Order Id: <b>" + order.id + "</b></label><br/>" +
                        "<label class=SmallPadLeft style='margin-top: 2px'>وقت الطلب Time: <b>" + this.datePipe.transform(order.createdon, "dd/MM HH:mm") + "</b></label><br/>" ;
                        
                if(order.kotnum && order.kotnum.length > 0){
                    str = str+ "<label class=SmallPadLeft style='margin-top: 2px'>(s) رمز لا Token No(s): <b> " + order.kotnum + "</b></label><br/>" ;
                }
                
            str = str+ "<label class=SmallPadLeft><b>" + countertypestr + " - "  + order.countername + "</b></label><br/>";
    
        }else{
            str= str+ "<div class=ui-g-12 style=\"line-height: 1.3\">" + 
                "<label class=SmallPadLeft>Bill No: <b>" + order.receiptno + "</b></label><br/>" +
                "<label class=SmallPadLeft >Order Id: <b>" + order.id + "</b></label><br/>" +
                "<label class=SmallPadLeft >Order Time: <b>" + this.datePipe.transform(order.createdon, "dd/MM HH:mm") + "</b></label><br/>" + 
                "<label class=SmallPadLeft>Token No(s): <b>" + order.kotnum + "</b></label><br/><br/>" + 
                "<label class=SmallPadLeft><b>" + countertypestr + " - "  + order.countername + "</b></label><br/>";

        }
        if((order.countertype == Constants.NORMAL ||  order.countertype == Constants.AC_SPECIAL)  && order.numguests && order.numguests > 0 ){
             str = str + "<label class=SmallPadLeft>Num Pax: <b>" +  order.numguests + "</b></label><br/>";
             str = str + "<label class=SmallPadLeft>Served By: <b>" +  order.servedbystr + "</b></label><br/>";
        }
                    
        
        if(order.customername && order.customername.length > 0 ){
            str= str+ "<label class=SmallPadLeft>Cust Name: <b>" + order.customername + "</b></label><br/>";

            var custmobile = order.customermobile;
            if(order.customermobile && order.customermobile.length <= 9)
                custmobile = '0' + order.customermobile;
        
            str= str+ "<label class=SmallPadLeft>Cust Mobile: <b>" + custmobile  + "</b></label><br/>";
            str= str+ "<label class=SmallPadLeft>Cust Address:</label><br/>";
            
            var addr = order.deladdrline1;
            if(order.deladdrline2 && order.deladdrline2.length > 0)
                addr = addr + "," + order.deladdrline2;
            
            if(order.deladdrline3 && order.deladdrline3.length > 0)
                addr = addr + "," + order.deladdrline3;
            
            if(order.landmark && order.landmark.length > 0)
                addr = addr + "  <b>Landmark: </b> " + order.landmark;
                

            str= str+ "<label class=\"SmallPadLeft \">" + addr  + "</label>";
        
        }

        /*if(order.cart.splinstructions && order.cart.splinstructions.length > 0){
            str= str+ "<label class=SmallPadLeft>Instructions:</label><br/>";
            str= str+ "<label class=\"SmallPadLeft \">" + order.cart.splinstructions  + "</label><br/>"
        }
        
        if(order.orderremarks && order.orderremarks.length > 0){
            str= str+ "<label class=SmallPadLeft>Remarks:</label><br/>";
            str= str+ "<label class=\"SmallPadLeft \">" + order.orderremarks  + "</label><br/>"
        }*/


        str = str + "</div>";
        
        str  = str + this.getItemSectionReceipt(order);
        str  = str + "</div>";
        
        var charges = order.cart.netcharges; 
        var taxablecharges =  Utils.round(charges / (1 + (5/100)),3);
        var vatoncharges = Utils.round(taxablecharges * (5/100),3);
        
        

                
        str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-12 RcptTotalsBlock\"> ";

        if(this.dataService.retailer.id == 1511){
            str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >مجموع الاشياء Item Total:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemamount,2) + "</div>" ;
        }else{
            str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >Item Total:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemamount,2) + "</div>" ;
        }

        
        if(this.dataService.retailer.id != 1554){
            if(this.dataService.retailer.id == 1511){
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >صافي باستثناء ضريبة القيمة المضافة:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ></div>" ;
            }
            
            str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >Net excluding VAT:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemgross,2) + "</div>" ;

            if(order.cart.totaldiscount > 0){
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Discount:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.totaldiscount,2) + "</div>" ;
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Taxable Amount:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.sumitemtaxable,2) + "</div>" ;
            }

            if(this.dataService.retailer.id == 1511)
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >رسوم Charges: </div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(taxablecharges,2) + "</div>" ;
            else str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Charges:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(taxablecharges,2) + "</div>" ;
            
            if(this.dataService.retailer.id == 1511)
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >ضريبة القيمة المضاف VAT(5%): </div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemtax + vatoncharges,2) + "</div>" ;
            else str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >VAT (5%):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemtax + vatoncharges,2) + "</div>" ;

    
        }else{
            if(isofficecopy){
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText  alignright \" >Net excl. taxes:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemgross,2) + "</div>" ;

                if(order.cart.totaldiscount > 0){
                    str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Discount:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.totaldiscount,2) + "</div>" ;
                    str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Taxable Amount:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.sumitemtaxable,2) + "</div>" ;
                }
    
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Service Charges:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.sumsc,2) + "</div>" ;
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Tourism Fee:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.sumtfee,2) + "</div>" ;
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Municiple Fee:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.summfee,2) + "</div>" ;
                str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >VAT:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.sumvat,2) + "</div>" ;
                
                if(taxablecharges > 0)
                    str= str+ "<div style='margin-top: 2px !important' class=\"ui-g-7 RcptTotalsText alignright \" >Charges:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(taxablecharges,2) + "</div>" ;
                
            }else{
                if(taxablecharges > 0) str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Charges:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(taxablecharges,2) + "</div>" ;
            }
        }

        
        if(order.cart.couponvalue > 0){
            str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Coupon used:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.couponvalue,2) + "</div>" ;
        }


        if(this.dataService.retailer.id == 1511){
            str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >التقريب Rounding:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.roundingamount,2) + "</div>" ;
        }else 

        str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Rounding:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.roundingamount,2) + "</div>" ;
        
        if(this.dataService.retailer.id == 1511){
            str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >إجمالي الفاتورة <b>Total:</b></div>" + "<div class=\"ui-g-4 RcptTotalsText alignright subcatDropdownFont\" >" +  Utils.format(order.cart.netpayable,2) + "</div>" ;
        }else
        str= str+ "<div class=\"ui-g-7 alignright RcptTotalsText subcatDropdownFont\" ><b>Bill Total: </b></div>" + "<div class=\"ui-g-4 RcptTotalsText alignright subcatDropdownFont\" >" +  Utils.format(order.cart.netpayable,2) + "</div>" ;
        
        if(this.dataService.retailer.id == 1554 && !isofficecopy){
            str= str+ "<div class=\"ui-g-12 RcptTotalsText aligncenter \" >=======</div>" ;
            
            if(order.cart.totaldiscount > 0){
                str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Discount:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.totaldiscount,2) + "</div>" ;
            }

            str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >VAT (5%):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.sumvat,2) + "</div>" ;

        }
        
        
        if(order.cart.paidamount > 0){
            if(this.dataService.retailer.id == 1511){
                str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >المبلغ المدفوع <b>Paid:</b></div>" + "<div class=\"ui-g-4 RcptTotalsText alignright subcatDropdownFont\" >" +  Utils.format(order.cart.paidamount,2) + "</div>" ;
            }else 
    
            str= str+ "<div class=\"ui-g-7 alignright RcptTotalsText subcatDropdownFont\" ><b>Paid amount:</b></div>" + "<div class=\"ui-g-4 RcptTotalsText alignright subcatDropdownFont\" >" +  Utils.format(order.cart.paidamount,2) + "</div>" ;

        }
        str= str+ "</div>";

        if(order.cart.paidamount > 0){
            
            str= str+ "<div class=\"ui-g-12 SettlmentBlock\"> ";

            if(this.dataService.retailer.id == 1511){
                str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12 alignleft RcptTotalsText subcatDropdownFont\" ><HR><b>تفاصيل التسوية Settlement</b></div><BR/>"  ; 
            }else 
              str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12 alignleft RcptTotalsText subcatDropdownFont\" ><HR><b>Settlement Details </b></div><BR/>"  ; 

            for(var pymt of order.cart.paymentlist){

             
                if(this.dataService.retailer.id == 1511) str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >طريقة الدفع Mode: " +  pymt.paymentmodename + "</div>" ;
                else str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >Payment mode: " +  pymt.paymentmodename + "</div>" ;
                
                if(this.dataService.retailer.id == 1511) 
                     str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >كمية Amount: " +  Utils.format(pymt.paidamount,2)  + "</div>" ;
                else str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >Amount: " +  Utils.format(pymt.paidamount,2)  + "</div>" ;

                
                if(pymt.cardno && pymt.cardno.length > 0){
                    if(this.dataService.retailer.id == 1511) str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >رقم البطاقة الائتمانية</div>" ;
                    str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >Card no: xxxx xxxx xxxx " +  pymt.cardno  + "</div>" ;

                }
                
                if(pymt.paymentmode == Constants.PYMT_CARD) { 
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        var tip = parseFloat(strtip);
                        if(tip > 0){
                            str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >Tip: " +  Utils.format(tip,2)  + "</div>" ;
                        }
                    }
                }    


                if(pymt.pymtreference && pymt.pymtreference.length > 0){
                    if(this.dataService.retailer.id == 1511) str= str+ "<div style='margin-top: 2px!important' class=\"ui-g-12\" >الرقم المرجعي الضريبي</div>" ;
                    str= str+ "<div class=\"ui-g-12\" >Txn Reference: " +  pymt.pymtreference  + "</div>" ;
                
                }
                if(pymt.remarks && pymt.remarks.length > 0)
                    str= str+ "<div class=\"ui-g-12\" >Remarks: " +  pymt.remarks  + "</div>" ;

                if(pymt.tenderamount && pymt.tenderamount > 0)
                    str= str+ "<div class=\"ui-g-12\" >Tender amount: " +  Utils.format(pymt.tenderamount,2) + "</div>" ;

                /*if(pymt.tendercurrency && pymt.tendercurrency > 0)
                    str= str+ "<div class=\"ui-g-12\" >Tender currency: " +  Utils.format(pymt.tendercurrency,2) + "</div>" ;*/

                if(pymt.returnamount && pymt.returnamount > 0)
                    str= str+ "<div class=\"ui-g-12\" >Return amount: " +  Utils.format(pymt.returnamount,2) + "</div>" ;

                //str= str+ "<HR>";
            }

            str= str+ "</div> ";
        }

        if(!isofficecopy){
            if(this.dataService.retailer.id == 1511){
                str= str+ "<div  class=\"ui-g-12 RcptTotalsText aligncenter \" style='margin-top:2px !important' >  جميع الأسعار بالدرهم الإماراتي وتشمل الرسوم المحلية المطبقة وضريبة القيمة المضافة. شكرًا لك. ارجوك عد مجددا.</div>" ;
            }
            if(this.dataService.retailer.id == 1554){
                str= str+ "<div class=\"ui-g-12 RcptTotalsText aligncenter \" style='margin-top:2px !important' >ALL PRICES ARE IN UAE DIRHAM AND ARE INCLUSIVE OF VAT. <br/> THANK YOU. PLEASE COME AGAIN.</div>" ;
            }
            else if(this.dataService.retailer.id  == 1553){
                str= str+ "<div class=\"ui-g-12 RcptTotalsText aligncenter \" style='margin-top:2px !important' >ALL PRICES ARE IN UAE DIRHAM AND ARE INCLUSIVE OF APPLICABLE LOCAL FEES & VAT. <br/> THANK YOU. PLEASE COME AGAIN. <br/> <br/> جميع الأسعار بالدرهم الإماراتي وتشمل الرسوم المحلية المعمول بها وضريبة القيمة المضافة ، شكرًا لك ، يرجى العودة مرة أخرى. </div>" ;
            }
            else{
                str= str+ "<div class=\"ui-g-12 RcptTotalsText aligncenter \" style='margin-top:2px !important' > ALL PRICES ARE IN UAE DIRHAM AND ARE INCLUSIVE OF APPLICABLE LOCAL FEES & VAT. <br/> THANK YOU. PLEASE COME AGAIN.</div>" ;
            }

           
        }    

        if(order.countertype == Constants.NORMAL && this.dataService.retailer.appconfig.posconfig.fbqronbill){
            str= str+ "<div class=\"ui-g-12 RcptTotalsText aligncenter \" > <br/>  We value your inputs. Please scan the QR Code below for a quick feedback! </div>" ;
            var feedbacklink = "https://feedback.matam.ae/?ord=" + this.dataService.getPosIdForFeedback() + "_1_" + order.id;
            //str  = str + "<div class=\" aligncenter\">  <img  style=\"margin-top: 5px !important;margin-left: auto !important;width: 70% !important; margin-bottom: 5px !important;\"  src='https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + feedbacklink + "&choe=UTF-8' > </div> <br/><br/><br/>";
            //str = str + "<qrcode [qrdata]='" + feedbacklink  + "'></qrcode>";
            
            //str= str + "<div class='Wid100 aligncenter'> <iframe class='Wid30' src='https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" + feedbacklink + "></iframe></div>";
            //str= str+ "<script type='text/javascript' src='runtime.js'></script><script type='text/javascript' src='polyfills.js'></script><script type='text/javascript' src='styles.js'></script><script type='text/javascript' src='scripts.js'></script><script type='text/javascript' src='vendor.js'></script><script type='text/javascript' src='main.js'></script>"; 
          
        }

        str =  str + "</BODY></HTML>";
        return str;
    }


    getTillReportAsHTML(shiftendRecord : Shiftendrecord){

        var bsndatestr =  Utils.getBusinessDateAsString("" + shiftendRecord.businessdate);
        var curdateTime = this.datePipe.transform(new Date(),"dd/MM/yy HH:mm");
        var isforeod = false;
        if(shiftendRecord.eoddate && shiftendRecord.eoddate > 0){
            isforeod = true; 
            shiftendRecord.eoddatestr = Utils.getBusinessDateAsString("" + shiftendRecord.eoddate);
        }
        

        var str = "<HTML><BODY>";
        str  = str + "<div class=\"ThinBorder NoPad\" height=\"100px\" style=\"border-color: white !important; min-height: 100px !important; \">";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " +  this.dataService.retailer.name.toUpperCase() + "</div> <br/>";
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> " +  this.dataService.retailer.address + "</div> <br/>";
        
        if(!isforeod)
            str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> Cashier Change Form</div> <br/>";
        else
            str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> End of day for  " + shiftendRecord.eoddatestr + "</div> <br/>";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 10px !important; \"> Till No: " + shiftendRecord.shiftno + "</div> <br/>";
        str= str+ "<div class=ui-g-12 style=\"line-height: 1.6\">" + 
                   "<label class=SmallPadLeft>Business date: <b>" + bsndatestr + "</b></label><br/>" +
                   "<label class=SmallPadLeft>Time: <b>" + this.datePipe.transform(shiftendRecord.createdon, "HH:mm") + "</b></label><br/>";
        
        str = str + "</div>";
        

        str  = str + this.getDenominationsInTill(shiftendRecord);
        str  = str + "</div>";
        

                
        str= str+ "<div class=\"ui-g-12 RcptTotalsBlock\"> ";

        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Net Cash (A):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.netcash,2) + "</b></div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Account Rcvbles (B):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.accountsreceivable,2) + "</b></div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Float Cash (C):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.floatcash,2) + "</b></div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Petty Cash Exp (D):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.pettycashexpense,2) + "</b></div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Total (A+B-C-D):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.totalcashinhand,2) + "</b></div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Pending bills:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.orderpendingamount,2) + "</b></div>" ;
        
        if(isforeod)
            str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Bank deposit:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.bankdeposit,2) + "</b></div>" ;
        
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Cash Sales:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.salesincash,2) + "</b></div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >Discrepancy <b>" + shiftendRecord.excessshortfall + "</b>:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(shiftendRecord.discrepancy,2) + "</b></div>" ;
        
        
        if(shiftendRecord.discrepancy != 0){
            str= str+ "<div class='ui-g-12'>";
            str= str+ "<HR>";
            str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Discrepancy Reason :</div>";
            str = str + "<div class=\"ui-g-12 alignleft \" >&nbsp;</div>" ;
            str = str + "<div class=\"ui-g-12 alignleft \" >&nbsp;</div>" ;
            str= str+ "<HR>";
            str= str+ "</div><br/>";
        }


        if(shiftendRecord.arrpymtmodewisesales && shiftendRecord.arrpymtmodewisesales.length > 0){
            str= str+ "<div class='ui-g-12'>";
            str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Payments by other mode:</div>";
            str= str+ "<HR>";
            str= str+ "</div><br/>";
            str  = str + this.getModeWiseReceipts(shiftendRecord);
        }


        if(isforeod && shiftendRecord.arrcountertypewisesales && shiftendRecord.arrcountertypewisesales.length > 0){
            str= str+ "<div class='ui-g-12'>";
            str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Order type wise breakup:</div>";
                str= str+ "<HR>";
            str= str+ "</div><br/>";
            str  = str + this.getOrdertypeWiseSales(shiftendRecord);
        }

        
        str= str+ "<div class='ui-g-12'>";
        str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Cashier Remarks:</div>";
        str = str + "<div class=\"ui-g-12 alignleft \" >" + shiftendRecord.remarks + "</div>" ;
        str= str+ "<HR>";
        str= str+ "</div><br/>";
        
          
    
        str= str+ "<div class='ui-g-12'>";
        str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Outgoing Cashier :</div>";
        str = str + "<div class=\"ui-g-12 alignleft \" >" + shiftendRecord.outgoingcashier + "</div>" ;
        str= str+ "<HR>";
        str= str+ "</div><br/>";
        
        str= str+ "<div class='ui-g-12'>";
        str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Incoming Cashier :</div>";
        str = str + "<div style='min-height: 40px !important'>&nbsp;</div>";
        str= str+ "<HR>";
        str= str+ "</div><br/>";
        
        str= str+ "<div class='ui-g-12'>";
       str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Restuarnat Manager :</div>";
        str = str + "<div style='min-height: 40px !important'>&nbsp;</div>";
        str= str+ "<HR>";
        str= str+ "</div><br/>";
 
        str= str+ "<BR/><BR/><div class='ui-g-12 aligncenter'>";
        str = str + "** Printed On:  " + curdateTime + " **";
        str= str+ "</div><br/><br/><br/>";
        
        

        /*str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >VAT (5%):</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" + Utils.format(order.cart.sumitemtax,2) + "</div>" ;
        str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Charges:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.netcharges,2) + "</div>" ;

        if(order.cart.couponvalue > 0){
            str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Coupon used:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright S\" >" +  Utils.format(order.cart.couponvalue,2) + "</div>" ;
        }

        str= str+ "<div class=\"ui-g-7 RcptTotalsText alignright \" >Rounding:</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" >" +  Utils.format(order.cart.roundingamount,2) + "</div>" ;
        str= str+ "<div class=\"ui-g-7 alignright RcptTotalsText subcatDropdownFont\" ><b>Bill Total: </b></div>" + "<div class=\"ui-g-4 RcptTotalsText alignright subcatDropdownFont\" >" +  Utils.format(order.cart.netpayable,2) + "</div>" ;
        if(order.cart.paidamount > 0)
            str= str+ "<div class=\"ui-g-7 alignright RcptTotalsText subcatDropdownFont\" ><b>Paid amount:</b></div>" + "<div class=\"ui-g-4 RcptTotalsText alignright subcatDropdownFont\" >" +  Utils.format(order.cart.paidamount,2) + "</div>" ;

        str= str+ "</div>";
        */
        
        str =  str + "</BODY></HTML>";
        return str;
    }

    

    getSummaryReportAsHTML(reporttitile, strdaterange, records : Summaryrecord[]){

        var curdateTime = this.datePipe.transform(new Date(),"dd/MM/yy HH:mm");
        var str = "<HTML><BODY>";
        str  = str + "<div class=\"ThinBorder NoPad\" height=\"100px\" style=\"border-color: white !important; min-height: 100px !important; \">";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " +  this.dataService.retailer.name.toUpperCase() + "</div> <br/>";
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> " +  this.dataService.retailer.address + "</div> <br/>";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " + reporttitile + "</div> <br/>";
        
        str= str+ "<div class=ui-g-12 style=\"line-height: 1.6\">" + 
            "<label class=SmallPadLeft><b>" + strdaterange + "</b></label><br/>";
        
        str = str + "</div>";
        
        
        for(var rec of records){
            var strval1 = "" + rec.summaryval1;
            if(strval1.length > 0){
                str= str+ "<div class=\"ui-g-7 RcptTotalsText  alignright \" >" + rec.description  + "</div>" + "<div class=\"ui-g-4 RcptTotalsText alignright \" ><b>" + Utils.format(rec.summaryval1,2) + "</b></div>" ;
            }else{
                str= str+ "<div class=\"ui-g-12 RcptTotalsText  aligncenter \" >" + rec.description  + "</div>" ;
            }
        }
       
        str= str+ "<div class='ui-g-12'>";
        str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Cashier/Mananger Remarks:</div>";
        str = str + "<div style='min-height: 40px !important'>&nbsp;</div>";
        str= str+ "<HR>";
        str= str+ "</div><br/>";
    
        str= str+ "<BR/><BR/><div class='ui-g-12 aligncenter'>";
        str = str + "** Printed On:  " + curdateTime + " **";
        str= str+ "</div><br/><br/><br/>";
            
        str =  str + "</BODY></HTML>";
        return str;
    }


    getMenuAnalysisAsHTML(reporttitile, strdaterange, records : Summaryrecord[]){

        var curdateTime = this.datePipe.transform(new Date(),"dd/MM/yy HH:mm");
        var str = "<HTML><BODY>";
        str  = str + "<div class=\"ThinBorder NoPad\" height=\"100px\" style=\"border-color: white !important; min-height: 100px !important; \">";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " +  this.dataService.retailer.name.toUpperCase() + "</div> <br/>";
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> " +  this.dataService.retailer.address + "</div> <br/>";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " + reporttitile + "</div> <br/>";
        
        str= str+ "<div class=ui-g-12 style=\"line-height: 1.6\">" + 
            "<label class=SmallPadLeft><b>" + strdaterange + "</b></label><br/>";
        
        str = str + "</div>";
        
        
        for(var rec of records){
        
            str= str+ "<div class='ui-g-12' style='padding-left: 5%; padding-right: 5%; padding-top: 0; padding-bottom:0;'>";
            if(rec.id == 'Totals'){
                str= str+ "<div class=\"ui-g-6 RcptTotalsText  alignright \" ><b>Totals</b></div>" + "<div class=\"ui-g-3 RcptTotalsText alignright \" ><b>" + rec.summaryval2 + "</b></div>"  + "<div class=\"ui-g-3 RcptTotalsText alignright \" ><b>" + Utils.format(rec.summaryval3,2) + "</b></div>" ;
            }else{
                str= str+ "<div class=\"ui-g-6 RcptTotalsText  alignleft \" >" + rec.description  + "</div>" + "<div class=\"ui-g-3 RcptTotalsText alignright \" >" + rec.summaryval2 + "</div>"  + "<div class=\"ui-g-3 RcptTotalsText alignright \" >" + Utils.format(rec.summaryval3,2) + "</b></div>" ;
            }
            str= str+ "</div>";
            
        }
       
        str= str+ "<div class='ui-g-12'>";
        str= str+ "<div class=\"ui-g-12 RcptTotalsText  alignleft \" >Cashier/Mananger Remarks:</div>";
        str = str + "<div style='min-height: 40px !important'>&nbsp;</div>";
        str= str+ "<HR>";
        str= str+ "</div><br/>";
    
        str= str+ "<BR/><BR/><div class='ui-g-12 aligncenter'>";
        str = str + "** Printed On:  " + curdateTime + " **";
        str= str+ "</div><br/><br/><br/>";
            
        str =  str + "</BODY></HTML>";
        return str;
    }


    printSettlementAdvice(order : Order){
        var str = "<HTML><BODY>";
        str  = str + "<div class=\"ThinBorder NoPad\" height=\"100px\" style=\"border-color: white !important; min-height: 100px !important; \">";
        //str  = str + "<div class=\" aligncenter\">  <img  style=\"margin-top: 5px !important;margin-left: auto !important;width: 90% !important; margin-bottom: 5px !important;\"  src='https://s3.ap-south-1.amazonaws.com/foodkartiposimages/appassets/" + this.dataService.retailer.id + "_horizontallogo.png'  width='100%'  > </div> <br/>";
        
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> " +  this.dataService.retailer.name.toUpperCase() + "</div> <br/>";
        //str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> " +  this.dataService.retailer.address + "</div> <br/>";
        str  = str + "<div class=\" aligncenter\" style=\"font-size: 16px; font-weight: bold; margin-top: 0px !important; \"> SETTLEMENT ADVICE</div> <br/>";
        //str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> India Palace Restaurant - Southern Franchise Company L.L.C <BR/>TRNNO:100241988300003 <HR></div> <br/>";

        //if(isduplicate)
        //    str  = str + "<div class=\" aligncenter\" style=\"font-size: 14px; font-weight: 400; margin-left: 5% !important; width: 90% !important; margin-top: 5px !important; \"> <b> ** DUPLICATE ** </b></div> <br/>";

        var countertypestr = Constants.getCounterTypeNameForReceipt(order.countertype);    
        //var bsndatestr = Utils.getBusinessDateAsString("" + order.businessdate);
        

        str= str+ "<div class=ui-g-12 style=\"line-height: 1.6\">" + 
                   "<label class=SmallPadLeft>Order Type        : <b>" + countertypestr + "</b></label><br/>" +
                   "<label class=SmallPadLeft>Bill Number       : <b>" + order.receiptno + "</b></label><br/>" +
                   "<label class=SmallPadLeft>Order Id          : <b>" + order.id + "</b></label><br/>" +
                   "<label class=SmallPadLeft>Business Date     : <b>" + order.businessdatestr + "</b></label><br/>" + 
                   "<label class=SmallPadLeft>Bill Date/Time    : <b>" + this.datePipe.transform(order.createdon, "dd/MM HH:mm") + "</b></label><br/>" + 
                   "<label class=SmallPadLeft>Cashier           : <b>" + order.billedbystr + "</b></label><br/>" + 
                   "<label class=SmallPadLeft>Discount          : <b>" + Utils.format(order.cart.totaldiscount,2) + "</b></label><br/>" + 
                   "<label class=SmallPadLeft>Payable Amount    : <b>" + Utils.format(order.cart.netpayable,2) + "</b></label><br/>" + 
                   "<label class=SmallPadLeft>Paid Amount       : <b>" + Utils.format(order.cart.paidamount,2) + "</b></label><br/>" + 
                   "<label class=SmallPadLeft>Pending Amount    : <b>" + Utils.format(order.cart.pendingamount,2) + "</b></label><br/>";
                   
        str = str + "</div>";
        str  = str + "</div>";
        

        
        str= str+ "<div class=\"ui-g-12 SettlmentBlock\"> ";
    
        if(order.cart.paymentlist && order.cart.paymentlist.length > 0){
            str= str+ "<div class=\"ui-g-12 alignleft RcptTotalsText subcatDropdownFont\" ><HR><BR/><b>Settlement Details </b></div><BR/>"  ; 

            for(var pymt of order.cart.paymentlist){

                str= str+ "<br/><div class=\"ui-g-12\" >Payment mode: " +  pymt.paymentmodename + "</div>" ;
                str= str+ "<div class=\"ui-g-12\" >Amount: " +  Utils.format(pymt.paidamount,2)  + "</div>" ;
                
                if(pymt.cardno && pymt.cardno.length > 0)
                    str= str+ "<div class=\"ui-g-12\" >Card no: xxxx xxxx xxxx " +  pymt.cardno  + "</div>" ;

                
                if(pymt.paymentmode == Constants.PYMT_CARD) { 
                    var strtip = "" + pymt.tip;
                    if(strtip && strtip.length > 0){
                        var tip = parseFloat(strtip);
                        if(tip > 0){
                            str= str+ "<div class=\"ui-g-12\" >Tip: " +  Utils.format(tip,2)  + "</div>" ;
                        }
                    }
                }       


                if(pymt.pymtreference && pymt.pymtreference.length > 0)
                    str= str+ "<div class=\"ui-g-12\" style='margin-top: 2px !important'>Txn Reference: " +  pymt.pymtreference  + "</div>" ;
                
                if(pymt.remarks && pymt.remarks.length > 0)
                    str= str+ "<div class=\"ui-g-12\" style='margin-top: 2px !important' >Remarks: " +  pymt.remarks  + "</div>" ;

                if(pymt.tenderamount && pymt.tenderamount > 0)
                    str= str+ "<div  class=\"ui-g-12\" style='margin-top: 2px !important' >Tender amount: " +  Utils.format(pymt.tenderamount,2) + "</div>" ;

                /*if(pymt.tendercurrency && pymt.tendercurrency > 0)
                    str= str+ "<div class=\"ui-g-12\" >Tender currency: " +  Utils.format(pymt.tendercurrency,2) + "</div>" ;*/

                if(pymt.returnamount && pymt.returnamount > 0)
                    str= str+ "<div class=\"ui-g-12\" style='margin-top: 2px !important' >Return amount: " +  Utils.format(pymt.returnamount,2) + "</div>" ;

                str= str+ "<HR>";
                str= str+ "</div> ";
            }
        }

        str= str+ "<BR/><BR/><div class=\"ui-g-12 SmallPadLeft RcptTotalsText subcatDropdownFont\" ><b>Employee Name   </b></div><BR/>"  ; 
        str= str+ "<BR/><BR/><div class=\"ui-g-12 SmallPadLeft RcptTotalsText subcatDropdownFont\" ><BR/><b>Employee Number </b></div><BR/>"  ; 
        str= str+ "<BR/><BR/><div class=\"ui-g-12 SmallPadLeft RcptTotalsText subcatDropdownFont\" ><BR/><b>Signature </b></div><BR/>"  ; 
        
        str =  str + "</BODY></HTML>";
        return str;
    }


    
    getSettlementAsString(order : Order){
        var str = "<BR>";
        
        str  = str +  this.dataService.retailer.name.toUpperCase() + "<BR>";
        str  = str + "<BR> SETTLEMENT ADVICE<BR>";

        var countertypestr = Constants.getCounterTypeNameForReceipt(order.countertype);    
        

        str= str+  "Order Type        : " + countertypestr + "<BR>" +
                   "Bill Number       : " + order.receiptno + "<BR>" +
                   "Order Id          : " + order.id + "<BR>" +
                   "Business Date     : " + order.businessdatestr + "<BR>" + 
                   "Bill Date/Time    : " + this.datePipe.transform(order.createdon, "dd/MM HH:mm") + "<BR>" + 
                   "Cashier           : " + order.billedbystr + "<BR>" + 
                   "Served By         : " + order.servedbystr + "<BR>" + 
                   "Discount          : " + Utils.format(order.cart.totaldiscount,2) + "<BR>" + 
                   "Payable Amount    : " + Utils.format(order.cart.netpayable,2) + "<BR>" + 
                   "Paid Amount       : " + Utils.format(order.cart.paidamount,2) + "<BR>";
                   
        
        
        str= str+ "<BR><BR>Settlement Details <BR>"  ; 

        for(var pymt of order.cart.paymentlist){

            str= str+ "Payment mode: " +  pymt.paymentmodename + "<BR>" ;
            str= str+ "Amount: " +  Utils.format(pymt.paidamount,2)  + "<BR>" ;
            
            if(pymt.cardno && pymt.cardno.length > 0)
                str= str+ "Card no: xxxx xxxx xxxx " +  pymt.cardno  + "<BR>" ;

            
            if(pymt.paymentmode == Constants.PYMT_CARD) { 
                var strtip = "" + pymt.tip;
                if(strtip && strtip.length > 0){
                    var tip = parseFloat(strtip);
                    if(tip > 0){
                        str= str+ "Tip: " +  Utils.format(tip,2)  + "<BR>" ;
                    }
                }
            }    


            if(pymt.pymtreference && pymt.pymtreference.length > 0)
                str= str+ "Txn Reference: " +  pymt.pymtreference  + "<BR>" ;
            
            if(pymt.remarks && pymt.remarks.length > 0)
                str= str+ "Remarks: " +  pymt.remarks  + "<BR>" ;

            if(pymt.tenderamount && pymt.tenderamount > 0)
                str= str+ "Tender amount: " +  Utils.format(pymt.tenderamount,2) + "<BR>" ;

            if(pymt.returnamount && pymt.returnamount > 0)
                str= str+ "Return amount: " +  Utils.format(pymt.returnamount,2) + "<BR>" ;

            str= str+ "<BR><BR><BR>";
        }

        return str;
    }

    

}